<template>
  <div class="pl-2" style="position: absolute; top: 360px;">
    <div class="d-flex">
      <span class="app-text__bold font-s-24 pr-3">{{ price }}</span><span class="app-text">{{ period }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    price: {
      type: String,
      required: true,
    },
    period: {
      type: String,
      required: true,
    }
  }
}
</script>