<template>
  <v-container>
    <div class="header">
      <div class="mb-4">
        <h3>Vermögensschaden-Haftpflichtversicherung</h3>
      </div>
      <div>
        <h1>Erstellen Sie ein neues Angebot</h1>
      </div>
    </div>


    <app-block class="pt-12 pb-6 justify-center align-center">
      <v-row>
        <v-col cols="5">
          <p class="app-text">
            Bitte melden Sie sich mit den Ihnen bekannten Zugangsdaten an.
          </p>
        </v-col>
        <v-col class="pl-10" cols="7">
          <v-form
              v-model="formValidation"
          >
            <v-row>
              <v-col cols="3"  class="pt-2">
                <span class="app-text">Benutzername</span>
              </v-col>
              <v-col cols="9">
                <text-field
                    name="email"
                    :validation="{required: true, email: true}"
                    v-model="email"
                />
              </v-col>
              <v-col cols="3" class="pt-2">
                <span class="app-text">Passwort</span>
              </v-col>
              <v-col cols="9">
                <password-field
                    name="email"
                    :validation="{required: true}"
                    v-model="password"
                />
              </v-col>
            </v-row>

            <div class="text-right">
              <orange-btn @click="loginClick">Anmelden</orange-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </app-block>
  </v-container>
</template>
<script>
import TextField from "../components/common/fields/TextField.vue";
import PasswordField from "../components/common/fields/PasswordField.vue";
import {routeNames} from "@/router/route-names.js";
import AppBlock from "../components/common/blocks/AppBlock.vue";
import OrangeBtn from "../components/common/buttons/OrangeBtn.vue";

export default {
  components: {AppBlock, PasswordField, TextField, OrangeBtn},
  data: () => ({
    password: '',
    email: '',

    formValidation: true,
  }),
  methods: {
    async loginClick() {
      if (this.formValidation) {
        const result = await this.loginUser(this.email, this.password);

        if (result) {
          await this.$router.push({name: routeNames.insuranceCreate});
        }
      }
    },

    loginUser(email, password) {
      return this.$store.dispatch('loginUser', {
        email,
        password,
      })
    }
  }
}
</script>
<style scoped>
.header {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 40px;
}
</style>