import {insuranceElementActionEnum} from "@/enums/insurance-element-relations-enum.js";
import {insuranceElementConditionEnum} from "@/enums/insurance-element-relations-enum.js";

export const insuranceElementRelationService = {
    actionsArray() {
        return Object.values(insuranceElementActionEnum);
    },

    staticActions() {
        return [
            insuranceElementActionEnum.USE_STATIC_VALUE,
        ]
    },

    builderElementActions() {
        return [
            insuranceElementActionEnum.USE_VALUE,
            insuranceElementActionEnum.USE_VISIBILITY,
        ];
    },

    insuranceElementsActions() {
        return [
            insuranceElementActionEnum.USE_STATIC_VALUE,
            insuranceElementActionEnum.USE_VALUE,
            insuranceElementActionEnum.USE_VISIBILITY,
        ];
    },

    conditionsArray() {
        return Object.values(insuranceElementConditionEnum);
    },

    staticConditions() {
        return [
            insuranceElementConditionEnum.IF_STATIC_VALUE,
            insuranceElementConditionEnum.IF_NOT_STATIC_VALUE,
            insuranceElementConditionEnum.IS_MORE_THAN,
            insuranceElementConditionEnum.IS_LESS_THAN,
            insuranceElementConditionEnum.VALUE_IS_IN_LIST,
        ]
    },

    builderElementConditions() {
        return [
            insuranceElementConditionEnum.IF_SELECTED,
            insuranceElementConditionEnum.IF_UNSELECTED,
            insuranceElementConditionEnum.IF_VALUE,
            insuranceElementConditionEnum.IF_VISIBLE,
            insuranceElementConditionEnum.IF_STATIC_VALUE,
            insuranceElementConditionEnum.IF_NOT_STATIC_VALUE,
            insuranceElementConditionEnum.IS_MORE_THAN,
            insuranceElementConditionEnum.IS_LESS_THAN,
            insuranceElementConditionEnum.VALUE_IS_IN_LIST,
        ];
    },

    insuranceElementConditions() {
        return [

        ];
    },
}