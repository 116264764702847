import {insurancePriceAddonActionEnum, insurancePriceAddonConditionEnum} from "@/enums/insurance-price-addon-relations-enum.js";

export const insurancePriceAddonRelationService = {
    actionsArray() {
        return Object.values(insurancePriceAddonActionEnum);
    },

    staticActions() {
        return [
            insurancePriceAddonActionEnum.USE_STATIC_VALUE,
        ]
    },

    builderElementActions() {
        return [
            insurancePriceAddonActionEnum.USE_VALUE,
            insurancePriceAddonActionEnum.USE_VISIBILITY,
        ];
    },

    insurancePriceAddonsActions() {
        return [
            insurancePriceAddonActionEnum.USE_STATIC_VALUE,
            insurancePriceAddonActionEnum.USE_VALUE,
            insurancePriceAddonActionEnum.USE_VISIBILITY,
        ];
    },

    conditionsArray() {
        return Object.values(insurancePriceAddonConditionEnum);
    },

    staticConditions() {
        return [
            insurancePriceAddonConditionEnum.IF_STATIC_VALUE
        ]
    },

    builderElementConditions() {
        return [
            insurancePriceAddonConditionEnum.IF_SELECTED,
            insurancePriceAddonConditionEnum.IF_UNSELECTED,
            insurancePriceAddonConditionEnum.IF_VALUE,
            insurancePriceAddonConditionEnum.IF_VISIBLE,
            insurancePriceAddonConditionEnum.IF_STATIC_VALUE
        ];
    },

    insurancePriceAddonConditions() {
        return [

        ];
    },
}