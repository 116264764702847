<template>
  <v-card
      width="256"
      class="mx-auto"
  >


    <slot/>

    <v-navigation-drawer permanent>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h4">
            Application
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
            v-for="item in items"
            :key="item.name"
            link
        >
          <v-list-item-icon>
            <v-icon @click="item.onClick()">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title @click="item.onClick()" v-html="item.name"></v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon v-if="item.id">
            <v-icon
                @click="editClick(item)"
                class="cursor-pointer rounded-circle"
                v-ripple="true"
                color="gray"
                size="24"
            >
              mdi-pencil
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <insurance-type-edit-popup
        v-if="showUpdatePopup"
        :insurance-type="insuranceType"
        @close="onCloseUpdatePopup"
        @updateType="updateType"
    />
  </v-card>
</template>
<script>
import {routeNames} from "@/router/route-names.js";
import {insuranceTypeEnum} from "@/enums/insurance-type-enum.js";
import InsuranceTypeEditPopup from "@/ui/components/admin/insurance-type/InsuranceTypeEditPopup";
import {notificationService} from "@/utils/services/notification-service";

export default {
  components: {InsuranceTypeEditPopup},
  computed: {
    insuranceTypeEnum() {
      return insuranceTypeEnum;
    },
    authUserIsAdmin() {
      return this.$store.getters.authUserIsAdmin
    },
  },
  data: function () {
    return {
      items: [],
      insuranceType: null,
      showUpdatePopup: false,
      loading: false,
    }
  },
  async created() {
    this.initItems();
    const insuranceTypes = await this.getInsuranceOriginalTypes();

    this.setInsuranceTypesToMenu(insuranceTypes);
    this.setXmlComponentToMenu();
  },
  methods: {
    initItems() {
      this.items = [
        {
          id: null,
          name: 'Dashboard',
          icon: 'mdi-view-dashboard',
          onClick: () => this.$router.push({name: routeNames.admin.dashboard}).catch(() => ({}))
        }
      ]
    },
    editClick(insuranceType) {
      this.insuranceType = insuranceType;

      this.showUpdatePopup = true;
    },
    onCloseUpdatePopup() {
      this.showUpdatePopup = false;
    },
    async updateType(insuranceType) {
      this.loading = true;

      const insuranceTypes = await this.updateInsuranceType(
          this.insuranceType,
          {
            name: insuranceType.name
          }
      );

      this.initItems();
      this.setInsuranceTypesToMenu(insuranceTypes);

      this.loading = false;

      notificationService.success('Element updated')
    },
    updateInsuranceType(insuranceType, elementData) {
      return this.$store.dispatch('admin/insuranceTypeModule/updateInsuranceType', {
        insuranceType,
        elementData,
      })
    },
    setInsuranceTypesToMenu(insuranceTypes) {
      if (this.authUserIsAdmin) {
        const insuranceTypesSidebar = insuranceTypes.map((insuranceType) => {
          return {
            id: insuranceType.id,
            name: insuranceType.name,
            icon: 'mdi-domain',
            onClick: () => this.onClickInsuranceType(insuranceType)
          }
        })

        this.items = [
          ...this.items,
          ...insuranceTypesSidebar
        ]
      }
    },

    onClickInsuranceType(insuranceType) {
      this.$router.push({
        name: routeNames.admin.insuranceType,
        params: {
          type: insuranceType.key,
        }
      }).catch(() => ({}))
    },

    getInsuranceOriginalTypes() {
      return this.$store.dispatch('admin/insuranceTypeModule/getOriginalTypes')
    },

    setXmlComponentToMenu() {
      if (this.authUserIsAdmin) {
        this.items.push({
          id: null,
          name: 'XML',
          icon: 'mdi-xml',
          onClick: () => this.onXmlMenuItem()
        })
      }
    },

    onXmlMenuItem() {
      this.$router.push({
        name: routeNames.admin.xml,
      }).catch(() => ({}))
    },
  }
}
</script>