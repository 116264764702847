<template>
  <div>
    <p class="app-text__bold">Id</p>

    <text-field
        name="id"
        :validation="{required: true}"
        :value="value"
        @saveField="saveId"
    />
  </div>
</template>
<script>

import TextField from "@/ui/components/common/fields/TextField.vue";

export default {
  components: {TextField},
  props: {
    value: {
      type: String,
      default: '',
    }
  },
  methods: {
    saveId(value) {
      this.$emit('input', value)
      this.$emit('saveId', value);
    }
  }
}
</script>