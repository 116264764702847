<template>
  <div>
    <v-form
        :ref="formName"
    >
      <div class="app-bg-white pt-5">
        <h3 class="app-text__bold mb-10 ml-5">Allgemeine Daten</h3>
        <v-row class="ml-5 mr-5">
          <v-col class="mt-2" cols="2">
            <div>Name des Vermittlers</div>
          </v-col>
          <v-col class="second-col">
            <text-field
                width="276px"
                placeholder="Bitte eintragen"
                :name="dataFieldKeys.managerName"
                :value="managerName"
                :validation="validation"
                :disabled="isDisableAllInputs"
                @saveField="saveField"
            />
          </v-col>
          <v-col cols="2" class="pt-2" >
            <span>Vermittlernummer</span>
          </v-col>
          <v-col>
            <text-field
                width="276px"
                placeholder="Bitte eintragen"
                :name="dataFieldKeys.managerNumber1"
                :value="managerNumber1"
                :validation="validationManagerNumber"
                :disabled="isDisableAllInputs || isTestEnvServer || isStagingEnvServer || isProdEnvServer"
                @saveField="saveField"
            />
          </v-col>
        </v-row>

        <v-row class="ml-5 mr-5 pb-10">
          <v-col class="pt-2" cols="2" v-if="!isEstateAgentAZAM">
            <div>B-Nr.-b</div>
          </v-col>
          <v-col class="second-col" v-if="!isEstateAgentAZAM">
            <text-field
                width="276px"
                placeholder="Bitte eintragen"
                :name="dataFieldKeys.managerNumber2"
                :value="managerNumber2"
                :validation="bnrbValidation"
                :disabled="isDisableAllInputs"
                @saveField="saveField"
            />
          </v-col>
          <v-col cols="2" class="pt-2" >
            <span>Fremdordnungsmerkmal</span>
          </v-col>
          <v-col >
            <text-field
                width="276px"
                placeholder="Bitte eintragen"
                :name="dataFieldKeys.foreignOrderFeature"
                :value="foreignOrderFeature"
                :validation="{isCustom: true, customValidationFunction: orderFeatureValidation}"
                :disabled="isDisableAllInputs"
                @saveField="saveField"
            />
          </v-col>
        </v-row>
      </div>

      <div class="app-bg-white mt-10">
        <h3 class="app-text__bold mb-10 ml-5 pt-5">Angaben zur Beratung</h3>

        <v-row class="ml-5 mr-5">
          <v-col class="pt-2" cols="2">
            <div>Name des Beraters</div>
          </v-col>
          <v-col class="second-col">
            <text-field
                width="276px"
                placeholder="Bitte eintragen"
                :name="dataFieldKeys.consultantName"
                :value="consultantName"
                :validation="validation"
                :disabled="isDisableAllInputs"
                @saveField="saveField"
            />
          </v-col>
          <v-col cols="2" class="pt-2">
            <span>Datum der Beratung</span>
          </v-col>
          <v-col>
            <datepicker-field
                width="276px"
                placeholder="TT.MM.JJ"
                :name="dataFieldKeys.consultantDate"
                :value="consultantDate"
                :validation="dateValidation"
                :allowed-dates="allowedDates"
                :is-disabled="isDisableAllInputs"
                @saveField="saveDateField"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="7">
            <p class="mb-2 ml-5 mt-10">Erfolgte der Verkauf innerhalb des Zielmarktes?</p>
          </v-col>
          <v-col class="mt-10 text-right tooltip-col" cols="5">
            <question-tooltip
                icon="mdi-help"
                icon-font-size="15"
                color="white"
                class="question-tooltip"
                align-self="end"
            >
              <div>
                <div class="pb-2">Der Verkauf erfolgte innerhalb des Zielmarkts.</div>

                <div v-for="(info, index) in selectedCheckboxesInfo" :key="index" v-html="info"></div>

                <div class="pt-2">Zudem werden die Interessen der Kunden, die Umwelt-, Sozial- und Arbeitnehmerbelange,
                  die Achtung der
                  Menschenrechte und die Bekämpfung von Korruption und Bestechung beachtet (Details finden Sie im
                  Informationsblatt nachhaltige Sachprodukte).
                </div>
              </div>
            </question-tooltip>
          </v-col>
        </v-row>


        <v-row class="ml-5 mr-5">
          <v-col cols="2">
            <radio-buttons-vertical
                :buttons="classicRadioButtons"
                :right="true"
                :value="wasSaleTargetMarket"
                :validation="validation"
                :name="dataFieldKeys.wasSaleTargetMarket"
                :disabled="isDisableAllInputs"
                @saveField="saveRadioField"
            />
          </v-col>
          <v-col class="pt-15 pb-15"  cols="5">
            <text-field
                v-if="showMarketComment"
                placeholder="Begründung"
                width="940px"
                :name="dataFieldKeys.wasSaleTargetMarketComment"
                :value="wasSaleTargetMarketComment"
                :disabled="isDisableAllInputs"
                @saveField="saveField"
            />
          </v-col>
        </v-row>
        <v-row v-if="isEstateAgentAZAM">
          <v-col cols="7">
            <p class="mb-2 ml-5 mt-2">Maklermandat vorhanden?</p>
          </v-col>
        </v-row>

        <v-row class="ml-5 mr-5 pb-5" v-if="isEstateAgentAZAM">
          <v-col cols="2">
            <radio-buttons-vertical
                :buttons="classicRadioButtons"
                :right="true"
                :value="isAvailableBrokerMandate"
                :validation="validation"
                :name="dataFieldKeys.isAvailableBrokerMandate"
                :disabled="isDisableAllInputs"
                @saveField="saveRadioField"
            />
          </v-col>
        </v-row>
        <v-alert type="error" v-if="isDisableNextBtn">
          Der Antragsabschluss ist nur mit einem Maklermandat möglich.
        </v-alert>
      </div>
    </v-form>

    <insurance-step-wrapper
        @prevButtonClick="onClickPrev"
        @nextButtonClick="onClickNext"
        next-button-text="Weiter"
        :show-next-button="!isDisableNextBtn"
    >
    </insurance-step-wrapper>
  </div>
</template>

<script>
import InsuranceStepWrapper from "@/ui/components/insurance/insurance-wrappers/InsuranceStepWrapper.vue"
import TextField from "@/ui/components/common/fields/TextField.vue"
import DatepickerField from "@/ui/components/common/fields/DatepickerField.vue"
import RadioButtonsVertical from "@/ui/components/common/fields/radio/RadioButtonsVertical.vue"
import QuestionTooltip from "@/ui/components/common/tooltips/QuestionTooltip.vue"
import {CLASSIC_RADIO_BUTTONS} from "@/utils/fields/insurance-police-step-fields.js"
import {DATA_FIELD_KEYS} from "@/utils/fields/insurance-police-field-keys.js"
import {dateService} from "@/utils/services/date-service.js"
import {isEnvProduction, isEnvStaging, isEnvTest} from "@/plugins/env";

export default {
  name: "Step1",
  components: {
    TextField,
    InsuranceStepWrapper,
    DatepickerField,
    RadioButtonsVertical,
    QuestionTooltip,
  },
  computed: {
    classicRadioButtons() {
      return CLASSIC_RADIO_BUTTONS
    },
    formName() {
      return 'policeStep1';
    },
    form() {
      return this.$refs[this.formName];
    },
    dataFieldKeys() {
      return DATA_FIELD_KEYS
    },
    managerName() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.managerName]
    },
    managerNumber1() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.managerNumber1]
    },
    managerNumber2() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.managerNumber2]
    },
    foreignOrderFeature() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.foreignOrderFeature]
    },
    consultantName() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.consultantName]
    },
    consultantDate() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.consultantDate]
    },
    wasSaleTargetMarket() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.wasSaleTargetMarket]
    },
    wasSaleTargetMarketComment() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.wasSaleTargetMarketComment]
    },
    insurancePolice() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getInsurancePolice"]
    },
    validation() {
      return {required: true}
    },
    validationManagerNumber() {
      return {required: false}
    },
    isEstateAgentAZAM() {
      return this.$store.getters.authUser.isEstateAgentAZAM();
    },
    selectedCheckboxesInfo() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getSelectedCheckboxesInfo"]
    },
    showMarketComment() {
      return this.wasSaleTargetMarket === 'Nein';
    },
    isAvailableBrokerMandate() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.isAvailableBrokerMandate]
    },
    isDisableNextBtn() {
      return this.isAvailableBrokerMandate === 'Nein';
    },
    isDisableAllInputs() {
      return Boolean(this.$store.getters["insuranceModule/getFinishedAt"])
    },
    dateValidation() {
      return {required: true, maxDate: {amount: 0,  period: "days", useCondition: true, type: "now", message: "Sie können nur ein Datum in der Vergangenheit oder heute auswählen", condition: "more"}}
    },
    isTestEnvServer() {
      return isEnvTest()
    },
    isStagingEnvServer() {
      return isEnvStaging()
    },
    isProdEnvServer() {
      return isEnvProduction()
    },
  },
  data: () => ({
    radioValue: 'Ja',
    formValidation: true,
    bnrbValidation: {
      maxLengthIncluding: 4,
      message: 'Bitte tragen Sie maximal 4 Ziffern ein'
    }
  }),
  methods: {
    async onClickPrev() {
      this.$store.commit('insuranceModule/showPreloader')
      await this.statusCustomer()
      this.$store.commit('insuranceModule/hidePreloader')

      this.$emit('clickPrev')
    },
    async onClickNext() {
      if (!this.form.validate() && !this.isDisableAllInputs) {
        return
      }

      await this.updatePolice(this.insurancePolice.id, this.insurancePolice)

      this.$emit('clickNext')
    },
    async statusCustomer() {
      await this.$store.dispatch('insuranceModule/statusCustomer')
    },
    saveField(val, key) {
      this.$store.commit('insuranceModule/insurancePoliceModule/setDataField', {key, val});
    },
    async updatePolice(insurancePoliceId, data) {
      await this.$store.dispatch('insuranceModule/insurancePoliceModule/updateInsurancePolice', {
            insurancePoliceId, data
          }
      );
    },
    saveRadioField(val, name) {
      this.$store.commit('insuranceModule/insurancePoliceModule/setDataField', {key: name, val: val});
    },
    saveDateField(val) {
      this.$store.commit('insuranceModule/insurancePoliceModule/setDataField', {key: this.dataFieldKeys.consultantDate, val: val});
    },
    allowedDates(date) {
      const dateTime = dateService.fromSQLToDateTime(date)
      const nowDate = dateService.getNowDate()

      return nowDate > dateTime
    },
    orderFeatureValidation(val, message = 'Der Wert muss 22 Zeichen lang sein') {
      const regexVariant1 = /^[0-9]\/031\/[2-9][0-9][0-9][0-9]$/;
      const regexVariant2 = /^[0-9]\/008\/[6-9][0-9][0-9][0-9]$/;
      const regexVariant3 = /^3\/003\/6[0-9][0-9][0-9]$/;
      const regexVariant4 = /^3\/003\/8[0-9][0-9][0-9]$/;

      if (regexVariant1.test(this.managerNumber1)) {
        return /^[a-zA-Z0-9_.-]{22}$/.test(val) || message;
      }

      if (regexVariant2.test(this.managerNumber1)) {
        return /^[a-zA-Z0-9_.-]{24}$/.test(val) || 'Der Wert muss 24 Zeichen lang sein';
      }

      if (
          regexVariant3.test(this.managerNumber1) ||
          regexVariant4.test(this.managerNumber1)
      ) {
        return /^[a-zA-Z0-9_.-]{20}$/.test(val) || 'Der Wert muss 20 Zeichen lang sein';
      }

      return true;
    }
  },
}
</script>

<style scoped>
.second-col {
  margin-right: 180px;
}
.tooltip-col {
  padding-right: 50px;
}
</style>