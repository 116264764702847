export const repeatByElementAction = async ({relation, activeElement, passiveElement, context}) => {
    activeElement.data.active_element_is_repeat = 1;

    activeElement.data.repeat_element_id = passiveElement.id;
    activeElement.data.relation_id = relation.id;

    context.dispatch('insuranceModule/builderElementsModule/saveElement', {
        builderElement: passiveElement
    }, {root: true});

    context.dispatch('insuranceModule/builderElementsModule/saveElement', {
        builderElement: activeElement
    }, {root: true});
}