<template>
  <simple-popup header="" @close="onClose">
    <center-block v-if="loading">
      <app-preloader/>
    </center-block>
    <div v-else>
      <div class="d-flex justify-space-between">
        <p class="mt-2 mr-4 app-text__bold">Paste Scheme</p>

        <div class="d-flex">
          <orange-btn @click="onClickExecute">Parse Scheme</orange-btn>
        </div>
      </div>

      <v-form :ref="formRef">
        <text-area-field
            name="scheme"
            :validation="{json: true}"
            :rows="18"
            v-model="scheme"
        />

      </v-form>
    </div>

  </simple-popup>
</template>
<script>
import SimplePopup from "@/ui/components/common/popup/SimplePopup.vue";
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue";
import CenterBlock from "@/ui/components/common/blocks/CenterBlock.vue";

export default {
  components: {CenterBlock, AppPreloader, OrangeBtn, TextAreaField, SimplePopup},
  props: {
    type: {
      type: String,
      required: true,
    }
  },

  computed: {
    formRef() {
      return 'scheme_form';
    },

    form() {
      return this.$refs[this.formRef];
    }
  },

  data: () => ({
    scheme: '',
    loading: true,
  }),

  mounted() {
    setTimeout(() => this.loading = false, 500);
  },

  methods: {
    onClickExecute() {
      if(this.form.validate()) {
        this.$emit('parseScheme', {
          scheme: this.scheme,
          type: this.type,
        });
      }
    },

    onClose() {
      this.$emit('close');
    }
  }
}
</script>