import Vue from 'vue'
import Vuex from 'vuex'
import {authModule} from "./modules/auth-module.js";
import {insuranceModule} from "@/store/modules/insurance-module.js";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    authModule,
    insuranceModule,
  },
})
