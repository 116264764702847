export const addIncorrectValueValidation = ({passiveElement, action_value, context}) => {
  passiveElement.validation = {
    ...passiveElement.validation,
    incorrectValueValidation: true,
    message: action_value
  }

  context.dispatch('insuranceModule/builderElementsModule/saveElement', {
    builderElement: passiveElement
  }, {root: true})
}