<template>
  <v-data-table
      :headers="headers"
      :items="files"
      :loading="loading"
  >

    <template v-slot:item.operation="{item}">
      <div class="d-flex justify-end">
        <blue-btn @click="onClickCopy(item)" class="mr-2">Copy Path</blue-btn>

        <show-icon @click="onShowClick(item)" class="mr-2" />

        <delete-icon @click="onClickDelete(item)" />
      </div>

    </template>
  </v-data-table>
</template>
<script>
import DeleteIcon from "@/ui/components/common/icons/DeleteIcon.vue";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import {getServerUrl} from "@/plugins/env.js";
import {notificationService} from "@/utils/services/notification-service.js";
import ShowIcon from "@/ui/components/common/icons/ShowIcon.vue";
export default {
  components: {ShowIcon, BlueBtn, DeleteIcon},
  props: {
    files: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id',
          align: 'left',
          sortable: true,
        },
        {
          align: 'center',
          text: 'Name',
          value: 'name',
        },
        {
          text: "Operations",
          align: 'right',
          value: "operation"
        }
      ]
    },

    serverUrl() {
      return getServerUrl()
    }
  },
  methods: {
    onClickDelete(file) {
      this.$emit('deleteClick', file);
    },

    onShowClick(file) {
      window.open(this.getFilePath(file), '_blank').focus()
    },

    onClickCopy(file) {
      navigator.clipboard.writeText(this.getFilePath(file));

      notificationService.success('File path is copied')
    },

    getFilePath(file) {
      return this.serverUrl + '/' + file.url;
    }
  }
}
</script>