export const insuranceTypeEnum = {
    BANKRUPTCY_RISK: 'bankruptcy-risk',
    FINANCIAL_INVESTMENTS: 'financial-investments',
    CORPORATE_GOVERNANCE: 'corporate-governance',
    SERVICE_COMPANY: 'service-company',
    OTHER_PROFESSIONS: 'other-professions',
    COMPANY_CLOSURE: 'company-closure',
    REAL_ESTATE_SERVICES: 'real-estate-services',
    NON_PROFIT_ORGANIZATIONS: 'non-profit-organizations',
    MEDIA_PROFESSIONS: 'media-professions',
}

export const financialInvestmentsPriceBlockName = 'Vermögensschaden-Haftpflichtversicherung'