<template>
  <div>
    <div class="text-center pb-2">
      <h3>Row</h3>
    </div>

    <v-row>
      <v-col cols="4">
        <name-editor
            v-model="builderElement.name"
        />
      </v-col>

      <v-col class="pl-4" cols="4">
        <priority-editor
            v-model.number="builderElement.priority"
        />
      </v-col>

      <v-col class="pl-4" cols="4">
        <background-editor
            v-model="builderElement.data.background"
        />
      </v-col>

      <v-col cols="6">
        <height-editor
            v-model="builderElement.data.height"
        />
      </v-col>

      <v-col cols="6" class="pl-4">
        <width-editor
            v-model="builderElement.data.width"
        />
      </v-col>
    </v-row>

    <margin-editor
        v-model="builderElement.data.margin"
    />

    <padding-editor
        class="mt-6"
        v-model="builderElement.data.padding"
    />
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import BackgroundEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/BackgroundEditor.vue";
import MarginEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MarginEditor.vue";
import PaddingEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PaddingEditor.vue";
import HeightEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/HeightEditor.vue";
import WidthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/WidthEditor.vue";

export default {
  components: {
    WidthEditor, HeightEditor, PaddingEditor, MarginEditor, BackgroundEditor, PriorityEditor, NameEditor
  },
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  },
}
</script>