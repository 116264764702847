<template>
<div>
  <div class="mt-2 pt-2 app-bg-aqua">
    <div class="mt-2 pb-2 pt-2 text-center header">
      <div class="mb-3">
        <h3>Vermögensschaden-Haftpflichtversicherung</h3>
      </div>
      <div class="pb-4">
        <h1>Antragsabschluss</h1>
      </div>
      <img class="bg-img" src="/img/man_sitting_on_vault_holding_money.png" alt="Allianz logo"/>
    </div>
  </div>
  <div class="app-container">
    <p>Der Vorgang ist nicht mehr gültig</p>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
.bg-img {
  max-height: 195px;
  margin-bottom: -20px;
}
</style>