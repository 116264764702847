<template>
  <div>
    <div class="app-bg-white pt-5 mb-12 pb-5">
      <h3 class="app-text__bold ml-5">Versand der Unterlagen</h3>
      <v-row class="ml-5 mr-5">
        <v-col class="mt-5" cols="3">
          <div class="radio-title">Dokumente</div>
          <radio-buttons-vertical
              :name="dataFieldKeys.sendDocPolice"
              :buttons="policeRadioButtons"
              :right="true"
              :value="sendDocPolice"
              :disabled="isDisableAllInputs"
              @saveField="saveRadioField"
          />
        </v-col>
        <v-col class="mt-5" cols="3">
          <div class="radio-title">Rechnung</div>
          <radio-buttons-vertical
              :name="dataFieldKeys.sendDocInvoice"
              :buttons="invoiceRadioButtons"
              :right="true"
              :value="sendDocInvoice"
              :disabled="isDisableAllInputs"
              @saveField="saveRadioField"
          />
        </v-col>
        <v-col class="mt-5" cols="3">
          <div class="radio-title">Versicherungsbestätigung</div>
          <radio-buttons-vertical
              :name="dataFieldKeys.sendDocInsuranceConfirmation"
              :buttons="insuranceConfirmation"
              :right="true"
              :value="sendDocInsuranceConfirmation"
              :disabled="isDisableAllInputs"
              @saveField="saveRadioField"
          />
        </v-col>
      </v-row>
    </div>

    <insurance-step-wrapper
        @prevButtonClick="onClickPrev"
        @nextButtonClick="onClickNext"
        next-button-text="Weiter"
    >
    </insurance-step-wrapper>
  </div>
</template>

<script>
import InsuranceStepWrapper from "@/ui/components/insurance/insurance-wrappers/InsuranceStepWrapper.vue"
import RadioButtonsVertical from "@/ui/components/common/fields/radio/RadioButtonsVertical.vue"
import {POLICE_RADIO_BUTTONS, INVOICE_RADIO_BUTTONS, INSURANCE_CONFIRMATION_BUTTONS} from "@/utils/fields/insurance-police-step-fields.js"
import {DATA_FIELD_KEYS} from "@/utils/fields/insurance-police-field-keys.js"
export default {
  name: "Step2",
  components: {
    InsuranceStepWrapper,
    RadioButtonsVertical,
  },
  computed: {
    insurancePolice() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getInsurancePolice"]
    },
    policeRadioButtons() {
      return POLICE_RADIO_BUTTONS
    },
    invoiceRadioButtons() {
      return INVOICE_RADIO_BUTTONS
    },
    insuranceConfirmation() {
      return INSURANCE_CONFIRMATION_BUTTONS
    },
    dataFieldKeys() {
      return DATA_FIELD_KEYS
    },
    sendDocPolice() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.sendDocPolice]
    },
    sendDocInvoice() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.sendDocInvoice]
    },
    sendDocInsuranceConfirmation() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.sendDocInsuranceConfirmation]
    },
    isDisableAllInputs() {
      return Boolean(this.$store.getters["insuranceModule/getFinishedAt"])
    },
  },
  data: () => ({
    policeValue: 'E-Mail',
    invoiceValue: 'E-Mail',
    insuranceConfirmationValue: 'E-Mail',
  }),
  methods: {
    async onClickPrev() {
      this.$emit('clickPrev')
    },
    async onClickNext() {
      await this.updatePolice(this.insurancePolice.id, this.insurancePolice)

      this.$emit('clickNext')
    },
    saveRadioField(val, key) {
      this.$store.commit('insuranceModule/insurancePoliceModule/setDataField', {key, val});
    },
    async updatePolice(insurancePoliceId, data) {
      await this.$store.dispatch('insuranceModule/insurancePoliceModule/updateInsurancePolice', {
            insurancePoliceId, data
          }
      );
    },
  },
}
</script>
