import axios from "axios";
import store from '@/store/app-store.js'
import router from "@/router";
import {routeNames} from "@/router/route-names.js";
import {notificationService} from "@/utils/services/notification-service.js";
import {getServerUrl} from "@/plugins/env.js";
import {objectService} from "@/utils/services/object-service.js";

const appAxios = axios.create({
    withCredentials: true,
    baseURL: getServerUrl(),
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    }
});

export const http = {
    setAuthToken(token) {
        appAxios.defaults.headers.Authorization = `Bearer ${token}`
    },

    get(url, data = {}, configs = {}) {
        return appAxios.get(url,{
            ...configs,
            params: data,
        })
    },

    post(url, data = {}, configs = {}) {
        return appAxios.post(url, data, configs)
    },

    formDataRequest(url, data, configs = {}) {
        const formData = objectService.toFormData(data);

        return appAxios.post(url, formData, configs)
    }
}

const onErrorResponse = async (error) => {
    if (error.response) {
        if (error.response.status === 401) {
            const isAuth = store.getters.isUserAuthorized

            store.commit('unsetAuthUser');
            store.commit('notAuthorized');

            if (router.history._startLocation.includes('/auth/ams-login') && !isAuth) {
                return null;
            }

            if (router.history._startLocation.includes('/' + routeNames.contractFinishPage)) {
                return null;
            }

            if (router.history._startLocation.includes('/' + routeNames.accessRestricted)) {
                return null;
            }

            else if (router.currentRoute.name !== routeNames.login ) {
                await router.push({name: routeNames.login});
            }

            return null;
        }

        if(error.response.status === 403) {
            notificationService.error("Don't have access")

            await router.push({name: routeNames.insuranceCreate})
        }

        if (error.response.status === 422) {
            notificationService.error("Invalid data")

            return null;
        }

        if (error.response.status === 500) {
            notificationService.error("Something went wrong, Server error")

            return null;
        }

        if (error.response.status === 404) {
            notificationService.error("Not found")

            return null;
        }
    }

    return error;
}

const onSuccessResponse = (response) => {
    return response;
}

appAxios.interceptors.response.use(
    onSuccessResponse,
    onErrorResponse
)