<template>
  <div>
    <h1 class="mb-4 text-center">Toolbar</h1>
    <v-card width="100%" height="100%">
      <v-tabs
          @change="onChangeSection"
          centered
      >
        <v-tab
            id="create"
        >
          Create Element
        </v-tab>
        <v-tab
            id="edit"
        >
          Edit Element
        </v-tab>
        <v-tab
          id="template"
        >
          Templates
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="section">
        <v-tab-item id="create">
          <builder-editor-create-section
              v-if="section === 'create'"
              class="element-info"
              :builder="builder"
              :builder-element="builderElement"
          />
        </v-tab-item>
        <v-tab-item id="edit">
          <builder-editor-edit-section
              v-if="section === 'edit'"
              class="element-info"
              :builder="builder"
              :builder-element="builderElement"
              :insurance-types="insuranceTypes"
          />
        </v-tab-item>
        <v-tab-item id="template">
          <builder-editor-template-section
              v-if="section === 'template'"
              class="element-info"
              :builder="builder"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import BuilderEditorEditSection from "@/ui/components/builder/use-cases/builder-editor/sections/BuilderEditorEditSection.vue";
import BuilderEditorCreateSection from "@/ui/components/builder/use-cases/builder-editor/sections/BuilderEditorCreateSection.vue";
import {Builder} from "@/models/builder.js";
import BuilderEditorTemplateSection
  from "@/ui/components/builder/use-cases/builder-editor/sections/BuilderEditorTemplateSection.vue";

export default {
  components: {BuilderEditorTemplateSection, BuilderEditorEditSection, BuilderEditorCreateSection},
  props: {
    builderElement: {
      type: BuilderElement,
      required: false,
    },
    builder: {
      type: Builder,
      required: true,
    },
    insuranceTypes: {
      type: Array
    }
  },
  data: () => ({
    section: 'create',
    sections: ['create', 'edit', 'template']
  }),
  methods: {
    onChangeSection(index) {
      this.section = this.sections[index];
      this.$emit('onChangeSection', this.section);
    },
  }
}

</script>
<style>
.element-info {
  position: relative;
  height: 700px;
}
</style>