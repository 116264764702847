<template>
  <div
      @mousemove="onMouseMoveOnElement($event)"
      @mouseleave="onMouseLeaveElement"
      @click.prevent="onSelectElement($event)"
      class="cursor-pointer"
  >
    <json-builder
        :builder="builder"
    />
  </div>
</template>
<script>
import {Builder} from "@/models/builder.js";
import JsonBuilder from "@/ui/components/json-builder/JsonBuilder.vue";
import {builderEditorEvents} from "@/utils/events/builder-editor-events.js";
import {builderQuerySelectors} from "@/utils/query-selectors/builder-query-selectors.js";
import {BuilderElement} from "@/models/builder-element.js";
import {jsonElementService} from "@/utils/services/json-element-service.js";
import {debounce} from "@/utils/functions.js";

export default {
  components: {JsonBuilder},
  props: {
    builder: Builder,
  },
  watch: {
    'selectedElement.priority': function () {
      this.builder.elements = jsonElementService.sortByPriority(this.builder.elements);
    }
  },
  data: () => ({
    selectedRef: null,
    selectedElement: BuilderElement.empty(),
    selectedId: null,
  }),
  created() {
    builderEditorEvents.elementSaved.on(this.whenElementSaved)
    builderEditorEvents.unselectElement.on(this.unselectSelectedRef)
    builderEditorEvents.selectElement.on(this.selectElementById)
  },
  destroyed() {
    builderEditorEvents.elementSaved.off(this.whenElementSaved);
    builderEditorEvents.unselectElement.off(this.unselectSelectedRef);
    builderEditorEvents.selectElement.off(this.selectElementById);
  },
  methods: {
    whenElementSaved() {
      this.unselectSelectedRef();
    },

    selectElementById(id) {
      const selectedId = this.selectedId;

      this.unselectSelectedRef();

      if (selectedId + "" !== id + "") {
        const {ref} = builderQuerySelectors.findByAttribute(id);

        if (ref) {
          this.setSelectedRef(ref, id)
        }
      } else {
        builderEditorEvents.unselectElement.emit();
      }
    },

    unselectSelectedRef() {
      if (this.selectedRef) {
        builderQuerySelectors.markEmpty(this.selectedRef);
      }

      this.selectedElement = BuilderElement.empty();
      this.selectedRef = null;
      this.selectedId = null;
    },

    setSelectedRef(ref, builderElementId) {
      this.selectedRef = ref;
      this.selectedId = builderElementId;

      const elem = jsonElementService.findElementById(this.builder.elements, builderElementId)

      this.selectedElement = elem || BuilderElement.empty();

      builderQuerySelectors.markSelected(ref);
    },

    onSelectElement($event) {
      const {builderElementId} = builderQuerySelectors.findClosestElement($event);

      //if we don't have selected id, trigger selectElement event
      if (!this.selectedId) {
        builderEditorEvents.selectElement.emit(builderElementId);
      } else {
        builderEditorEvents.unselectElement.emit();
        //if its not same element select the element
        if (builderElementId !== this.selectedId) {
          debounce(() => builderEditorEvents.selectElement.emit(builderElementId), 'select_other_element', 150);
        }
      }
    },

    onMouseLeaveElement() {
      builderQuerySelectors.emptyAllExcept(this.selectedId);
    },

    onMouseMoveOnElement($event) {
      const {ref, builderElementId} = builderQuerySelectors.findClosestElement($event)

      builderQuerySelectors.emptyAllExcept(this.selectedId);

      if (ref && builderElementId !== this.selectedId + '') {
        builderQuerySelectors.markHovered(ref);
      }
    },
  },
}
</script>
<style lang="scss">
.builder-elements {
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
  cursor: pointer;
}

.builder-element {
  .style-block {
    box-sizing: border-box;
    border: 1px solid transparent;
  }

  .element-hovered {
    border: 1px solid var(--light-blue) !important;
  }

  .element-selected {
    border: 1px solid red !important;
  }
}
</style>