
export class CreateElementEvent {
    _name = 'create-item'

    constructor(eventBus) {
        this.eventBus = eventBus
    }

    emit(newElement) {
        this.eventBus.$emit(this._name, newElement)
    }

    on(callback) {
        this.eventBus.$on(this._name, callback);
    }

    off(callback) {
        this.eventBus.$off(this._name, callback);
    }
}

