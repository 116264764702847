import {builderEditorApi} from "./builder-editor-api.js";
import {builderApi} from "./builder-api.js";
import {builderCreatorApi} from "@/api/server/admin/builder-creator-api.js";
import {insuranceTypeApi} from "@/api/server/admin/insurance-type-api.js";
import {insuranceElementRelationsApi} from "@/api/server/admin/insurance-element-relations-api.js";
import {builderRelationsApi} from "@/api/server/admin/builder-relations-api.js";
import {jsonBuilderEditorApi} from "@/api/server/admin/json-builder-editor-api.js";
import {insurancePriceAddonRelationsApi} from "@/api/server/admin/insurance-price-addon-relations-api.js";
import {statisticApi} from "@/api/server/admin/statistic-api";
import {insuranceApi} from "@/api/server/admin/insurance-api";
import {dashboardInfoApi} from "@/api/server/admin/dashboard-info-api";

export const adminApi = (http) => ({
    builder: builderApi(http),
    builderEditor: builderEditorApi(http),
    builderCreator: builderCreatorApi(http),
    builderRelations: builderRelationsApi(http),
    insuranceType: insuranceTypeApi(http),
    insuranceElementRelations: insuranceElementRelationsApi(http),
    jsonBuilderEditor: jsonBuilderEditorApi(http),
    insurancePriceAddonRelations: insurancePriceAddonRelationsApi(http),
    statisticApi: statisticApi(http),
    insuranceApi: insuranceApi(http),
    dashboardInfo: dashboardInfoApi(http),

    executeSql: (sql) => http.post(`/api/admin/execute-sql`, {
        sql,
    })
})