<template>
  <div :style="{padding, margin, width}">
      <text-area-field
          class="pr-1"
          :name="name"
          :width="width"
          :rows="rows"
          :value="value"
          :placeholder="placeholder"
          @saveField="saveField"
          :disabled="disabledBuilderElement"
          :validation="validation"
      />
  </div>
</template>
<script>
import DefaultElement from "./mixins/DefaultElement.js";
import Padding from "./mixins/Padding.js";
import Placeholder from "./mixins/Placeholder.js";
import Size from "./mixins/Size.js";
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";
import {builderElementsEvents} from "@/utils/events/builder-elements-events.js";
import Validation from "@/ui/components/builder/elements/mixins/Validation";

export default {
  components: {TextAreaField},
  mixins: [DefaultElement, Padding, Placeholder, Size, Validation],
  props: {
    disabledBuilderElement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    rows() {
      return this.builderElement.data?.rows || 3;
    }
  },
  methods: {
    saveField(value) {
      this.builderElement.value = value;

      builderElementsEvents.updateValue.emit(this.builderElement)
    }
  }
}
</script>