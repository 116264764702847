<template>
  <tooltip
      icon="mdi-exclamation"
      :icon-font-size="fontSize"
      class="question-tooltip"
      color="white"
      :isHidePopup="isHidePopup"
  >
    <slot/>
  </tooltip>
</template>
<script>
import Tooltip from "./Tooltip.vue";
export default {
  components: {Tooltip},
  props: {
    fontSize: {
      type: String,
      required: false,
      default: '22px'
    },
    isHidePopup: {
      type: Boolean,
      required: false,
      default: false,
    },
  }
}
</script>
<style scoped>
.question-tooltip {
  border-radius: 50%;
  background: var(--light-blue);
  display: inline-flex
}
</style>