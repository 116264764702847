<template>
  <div>
    <builder-elements
        :elements="builder.elements"
        :builder-element-id="builderElementId"
        :disabled-all-inputs="disabledAllInputs"
        :admin-edit-mode="adminEditMode"
    />
  </div>
</template>
<script>
import {Builder} from "@/models/builder.js";
import BuilderElements from "./BuilderElements.vue";

export default {
  components: {BuilderElements},
  props: {
    builder: {
      type: Builder,
      required: true,
    },
    builderElementId: {
      type: String,
      required: false,
    },
    disabledAllInputs: {
      type: Boolean,
      required: false,
      default: false,
    },
    adminEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>