<template>
  <div :style="{width}">
    <vuetify-money
      :value="value"
      :placeholder="placeholder"
      :options="moneyFieldOptions"
      outlined
      dense
      :class="{rounded: rounded}"
      @input="saveField"
      :rules="validationRules"
      :disabled="disabled"
    />
  </div>
</template>
<script>
import {equalToElementValue, required} from "@/utils/validation-rules.js";
import {moneyFieldOptions} from "@/utils/types/money-field-options";
import {validateBySumElements} from "@/utils/validation-rules.js";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      required: false,
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    width: {
      type: String,
      default: 'auto',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    validationRules() {
      const rules = [];

      if (this.validation.required && this.validation.required !== '0') {
        rules.push(required);
      }

      if (this.validation.bySum) {
        rules.push((val, message = this.validation.bySum.message || 'Pflichtfeld') =>
            validateBySumElements(
                val,
                this.validation.bySum.fieldNames,
                this.validation.bySum.operation,
                this.validation.bySum.targetValue,
                this.allBuilderElements,
                this.validation.bySum.passiveElement,
                message
            )
        )
      }

      if (this.validation.equalTo && this.validation.equalTo.elementId) {
        rules.push(
            (val, message = this.validation.equalTo.message || 'Error') =>
                equalToElementValue(val, this.validation.equalTo.elementId, this.allBuilderElements, message)
        );
      }

      return rules
    },
    allBuilderElements() {
      return this.$store.getters["insuranceModule/builderElementsModule/getAllBuilderElements"];
    },
  },
  data: () => ({
    moneyFieldOptions,
  }),

  methods: {
    saveField(value) {
      // Value is '' when we set 0 in this component
      if (value === '' && (parseInt(this.value) <= 0 || this.value === '')) {
        value = '0'
      }

      this.$emit('saveField', value, this.name)
      this.$emit('input', value);
    },
  }
}
</script>