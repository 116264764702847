<template>
  <div>
    <v-card
        class="card-table no-border-bottom"
    >
      <v-row class="insurance-table__headers">
        <v-col cols="1">Angebot Nr.</v-col>
        <v-col cols="2">Produkt</v-col>
        <v-col cols="2">Vorgang</v-col>
        <v-col cols="2">Stand vom</v-col>
        <v-col cols="2">Vermittler</v-col>
        <v-col cols="2">Status</v-col>
        <v-col cols="1"></v-col>
      </v-row>

      <div v-if="!loading" class="insurance-table__content">
        <v-row class="insurance-table-row" v-for="insurance in insurances" :key="insurance.id">
          <v-col cols="1" class="pl-8">
            {{ insurance.id }}
          </v-col>
          <v-col cols="2" class="pl-7">
            {{ insurance.type }}
          </v-col>
          <v-col cols="2" class="pl-7">
            {{ insurance.name }}
          </v-col>
          <v-col cols="2" class="pl-6">
            {{ dateService.fromISOToFormat(insurance.last_update) }}
          </v-col>
          <v-col cols="2" class="pl-6">
            {{ insurance.user_name }}
          </v-col>
          <v-col cols="2" class="pl-4">
            {{ insurance.formal_status_de }}
          </v-col>
          <v-col cols="1" class="pl-7">
            <div class="d-flex">
              <edit-icon
                v-if="isShowEditBtn(insurance)"
                  size="20"
                  class="ml-5"
                  color="var(--light-blue)"
                  @click="onShowClick(insurance.id)"
              />
              <stop-sign-icon
                  class="ml-1 delete-btn"
                  size="20"
                  color="grey"
                  v-if="authUserIsAdmin && isShowDeleteBtn(insurance)"
                  @click="onDeleteClick(insurance.id)"
              />
            </div>
          </v-col>
        </v-row>

        <div class="insurance-table__footer">
          <div class="navigation-block">
            <select-field
                :value="perPage"
                @saveField="onChangePerPage"
                name="per_page"
                width="140px"
                :items="perPageList"
            />

            <white-btn
                height="40px"
                class="ml-6 mr-3"
                :disabled="page===1"
                @click="onChangePage(page - 1)"
            >
              &lt;&lt;
            </white-btn>

            <number-field
                :value="page"
                @saveField="onChangePageByField"
                height="40px"
                width="60px"
                name="page"
            />

            <span class="total-pages-container">
              von {{ lastPage }}
            </span>

            <white-btn
                height="40px"
                class="ml-3"
                :disabled="page === lastPage"
                @click="onChangePage(page + 1)"
            >
              >>
            </white-btn>
          </div>

          <div class="mr-2">
            <white-btn width="180px" @click="toInsuranceCreate">
              Neues Angebot
            </white-btn>
          </div>
        </div>
      </div>

      <div v-else class="mt-10 pb-10">
        <app-preloader/>
      </div>
    </v-card>
  </div>

</template>
<script>

import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue";
import SelectField from "@/ui/components/common/fields/SelectField.vue";
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import {routeNames} from "@/router/route-names.js";
import NumberField from "@/ui/components/common/fields/NumberField.vue";
import StopSignIcon from "@/ui/components/common/icons/StopSignIcon.vue";
import {debounce} from "@/utils/functions.js";
import EditIcon from "@/ui/components/common/icons/EditIcon";
import {insuranceStatusEnum} from "@/enums/insurance-status-enum.js";
import {dateService} from "@/utils/services/date-service.js";
import {userRoleEnum} from "@/enums/user-role-enum.js";

export default {
  components: {EditIcon, StopSignIcon, NumberField, WhiteBtn, SelectField, AppPreloader},
  props: {
    insurances: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    perPage: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    lastPage: {
      type: Number,
      required: true,
    }
  },
  computed: {
    headers() {
      return [
        {text: 'Angebot Nr.', value: 'id',},
        {text: 'Produkt', value: 'type'},
        {text: 'Vorgang', value: 'name'},
        {text: 'Stand vom', value: 'last_update'},
        {text: 'Vermittler', value: 'user_name',},
        {text: 'Status', value: 'status'},
        {text: '', value: 'operation'}
      ]
    },

    perPageList() {
      return [
        {text: 'Zeige 10', value: 10},
        {text: 'Zeige 25', value: 25},
        {text: 'Zeige 50', value: 50},
        {text: 'Zeige 100', value: 100},
      ]
    },

    authUserIsAdmin() {
      return this.$store.getters.authUserIsAdmin
    },
    dateService() {
      return dateService
    }
  },
  methods: {
    toInsuranceCreate() {
      this.$router.push({name: routeNames.insuranceCreate})
    },

    onChangePerPage(value) {
      this.changeOptions(value, 1);
    },

    onChangePageByField(value) {
      debounce(() => this.onChangePage(value), 'change-page', 1000)
    },

    onChangePage(value) {
      this.changeOptions(this.perPage, value);
    },

    onDeleteClick(insuranceId) {
      this.$emit('deleteClick', insuranceId)
    },

    onShowClick(insuranceId) {
      this.$router.push({name: routeNames.insuranceShow, params: {insuranceId}})
    },

    changeOptions(perPage, page) {
      this.$emit('changeOptions', {
        perPage,
        page,
      })
    },

    isShowDeleteBtn(insurance) {
      return insurance.formal_status !== insuranceStatusEnum.APPLICATION_SUBMITTED
    },

    isShowEditBtn(insurance) {
      const currDate = dateService.getNowDate().minus({days: 90})

      if (insurance.formal_status !== insuranceStatusEnum.APPLICATION_SUBMITTED && insurance.user_role === userRoleEnum.VERMITTER) {
        return dateService.fromISOToDateTime(insurance.last_update) > currDate
      }

      return true
    },
  }
}
</script>
<style lang="scss">
.insurance-table-row {
  margin: 0 !important;
  padding-top: 12px;
}
.insurance-table-row:hover {
  background-color: #F7F7F7;
}
.card-table {
  margin-bottom: 10px;
}

.total-pages-container {
  display: inline-block;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-left: 10px;
}

.no-border-bottom {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.insurance-table__headers {
  font-weight: 500;
  font-size: 20px;
  padding-left: 15px;
  border-radius: 5px 5px 0 0 !important;
  text-align: left;
  border-bottom: 2px solid rgba(0, 0, 0, .2);

  .col {
    content: '|';
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: 21px;
    font-weight: 300;
    color: #404040;
    float: right;
    font-family: "AllianzNeo Bold", sans-serif;
  }

  .col:not(:last-child):after {
    content: '|';
    margin-top: -2px;
    font-size: 24px;
    font-weight: 400 !important;
    color: white;
    float: right;
  }
}

.insurance-table__content {
  .col {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-top: 5px;
    padding-bottom: 15px;
    font-size: 14px;
    color: var(--dark)
  }
}

.insurance-table__footer {
  width: 100%;
  padding-top: 50px;
  padding-left: 25px;
  height: 110px;
  display: flex;

  .navigation-block {
    display: flex;
    width: 85%;
  }
}

.delete-btn:hover {
  color: red;
}
</style>