<template>
  <v-data-table
      :headers="headers"
      :items="addons"
      class="elevation-10"
      loading-text="Loading... Please wait"
      :items-per-page="8"
      :loading="loading"
  >
    <template v-slot:item.operation="{item}">
      <div class="d-flex justify-lg-end">

        <div>
          <edit-icon @click="editClick(item)"/>
        </div>

        <div>
          <delete-icon @click="deleteClick(item)"/>
        </div>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import EditIcon from "@/ui/components/common/icons/EditIcon.vue";
import DeleteIcon from "@/ui/components/common/icons/DeleteIcon.vue";

export default {
  components: { DeleteIcon, EditIcon},
  props: {
    addons: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Key',
          value: 'key',
        },
        {
          text: "Name",
          value: 'name',
        },
        {
          text: "Price",
          value: 'price',
        },
        {
          text: "Code VSU",
          value: 'code_vsu',
        },
        {
          text: "Show",
          value: 'is_show',
        },
        {
          text: "Operations",
          align: 'right',
          value: "operation"
        }
      ]
    }
  },
  methods: {
    editClick(priceAddon) {
      this.$emit('editPriceAddonClick', priceAddon)
    },

    deleteClick(priceAddon) {
      this.$emit('deletePriceAddonClick', priceAddon)
    },
  }
}
</script>