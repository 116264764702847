<template>
  <app-block :width="width" class="validation-app-block" color="#FD7E02">
    <div class="d-flex">
      <div class="rounded-circle mr-4 app-bg-white icon-validation-block">
        <v-icon
            class=" cursor-pointer"
            color="black"
            :style="{ fontSize: '24px' }"
        >
          mdi-exclamation
        </v-icon>
      </div>
      <div :style="{ fontSize: '16px', fontWeight: '700', color: '#fff' }">
        {{ value }}
      </div>
    </div>
  </app-block>
</template>
<script>
import AppBlock from "../../common/blocks/AppBlock.vue";

export default {
  components: {
    AppBlock
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: 'auto'
    },
    width: {
      type: String,
      default: 'auto'
    },
    header: {
      type: String,
      required: false,
    }
  }
}
</script>

<style scoped>
.validation-app-block {
  padding: 15px;
}
.icon-validation-block {
  width: 24px;
  height: 24px;
}
</style>