<template>
  <div :style="{padding, margin, width}">
    <datepicker-field
        class="pr-1"
        :width="width"
        :value="value"
        :validation="validation"
        :placeholder="placeholder"
        :is-disabled="isDisabled || disabledBuilderElement"
        @saveField="saveField"
    />
    <simple-popup
        v-if="showPopup"
        :header="popupHeader"
        :is-show-close-button="false"
        @close="onCloseNoticeLimitationPopup">
      <v-row>
        <v-col cols="12">
          <p>{{ popupText }}</p>
        </v-col>
        <div class="d-flex">
          <white-btn class="mr-2" @click="onConfirmNoticeLimitationPopup">{{ popupButtonText}}</white-btn>
        </div>
      </v-row>
    </simple-popup>
  </div>
</template>
<script>
import DefaultElement from "./mixins/DefaultElement.js";
import Padding from "./mixins/Padding.js";
import Placeholder from "./mixins/Placeholder.js";
import Size from "./mixins/Size.js";
import DatepickerField from "@/ui/components/common/fields/DatepickerField.vue";
import {builderElementsEvents} from "@/utils/events/builder-elements-events.js";
import Validation from "@/ui/components/builder/elements/mixins/Validation.js";
import IsDisabled from "./mixins/IsDisabled.js";
import SimplePopup from "@/ui/components/common/popup/SimplePopup.vue";
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import {checkDateFieldPopupValidation} from "@/utils/validation-rules";

export default {
  components: {DatepickerField, SimplePopup, WhiteBtn},
  mixins: [DefaultElement, Padding, Placeholder, Size, Validation, IsDisabled],
  props: {
    disabledBuilderElement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    popupHeader() {
      return this.builderElement.data?.popupHeader;
    },
    popupText() {
      return this.builderElement.data?.popupText;
    },
    popupButtonText() {
      return this.builderElement.data?.popupButtonText;
    },
    usePopup() {
      return this.builderElement.data?.usePopup;
    },
    dateValidationResult() {
      if (this.usePopup && this.showNoticeLimitationPopup && this.builderElement.value) {
        return checkDateFieldPopupValidation(this.builderElement.value, this.builderElement.data.popupValidation);
      }

      return false;
    },
    showPopup() {
      return this.dateValidationResult;
    }
  },
  data() {
    return {
      showNoticeLimitationPopup: true
    }
  },
  methods: {
    saveField(value) {
      this.showNoticeLimitationPopup = this.usePopup;

      this.builderElement.value = value;

      builderElementsEvents.updateValue.emit(this.builderElement)
    },
    onConfirmNoticeLimitationPopup() {
      this.showNoticeLimitationPopup = false;
    },
    onCloseNoticeLimitationPopup() {
      this.showNoticeLimitationPopup = false;
    }
  }
}
</script>