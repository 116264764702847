<template>
  <v-app>
    <v-main class="app-bg-light-smoke">
      <app-header />
      <div>
          <router-view/>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import AppHeader from "./AppHeader.vue";
export default {
  components: {AppHeader}
}
</script>
<style>
.app-bg-light-smoke {
  height: 100%;
}
</style>