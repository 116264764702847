<template>
  <app-block class="pl-12 py-8 app-container">
    <h3 class="mt-4">{{header}}</h3>

    <div class="py-12 px-4">
      <div class="d-flex justify-lg-start">
        <slot/>
      </div>
    </div>
  </app-block>
</template>
<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
export default {
  components: {AppBlock},
  props: {
    header: {
      type: String,
      default: 'Wünschen Sie Zusatzoptionen?',
    }
  }
}
</script>