var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-5 "},[_c('select-field',{attrs:{"name":"selectDate","width":"300px","items":_vm.selectOptions,"value":_vm.selectValue},on:{"saveField":_vm.selectPeriodDate}}),_c('div',{staticClass:"row pl-0"},[_c('div',{staticClass:"col-6"},[_c('span',[_vm._v("Von")]),_c('datepicker-field',{attrs:{"width":"276px","placeholder":"TT.MM.JJ","name":"from","value":_vm.from},on:{"saveField":_vm.onSelectDate}})],1),_c('div',{staticClass:"col-6 pl-6"},[_c('span',[_vm._v("Bis")]),_c('datepicker-field',{attrs:{"width":"276px","placeholder":"TT.MM.JJ","name":"to","value":_vm.to},on:{"saveField":_vm.onSelectDate}})],1)]),_c('div',{staticClass:"col-12"},[_c('select-field',{attrs:{"name":"insuranceType","width":"300px","items":_vm.insuranceTypeOptions,"itemText":"name","itemValue":"key"},on:{"saveField":_vm.onSelectInsuranceType}})],1)],1),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"50%","type":"donut","options":{
                  labels: _vm.statusNames,
                  title: {
                      text: 'Alle Vorgänge: ' + _vm.moneyService.toMoneyFormat(this.total),
                      style: {
                          fontSize: '21px',
                          fontWeight: 400,
                      }
                  },
                  tooltip: {
                      enabled: true,
                      y:{
                        formatter: function (val) {
                          return _vm.moneyService.toMoneyFormat(val)
                        },
                      },
                    }
              },"series":_vm.countInsurances}})],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }