<template>
  <div>
    <h1 class="mb-4 text-center">Builder: {{ builder.name }}</h1>

    <div class="builder-elements pr-2">

      <div
          @mousemove="onMouseMoveOnElement($event)"
          @mouseleave="onMouseLeaveElement"
          @click.prevent="onSelectElement($event)"
          :class="{'no-events': !canSelectElement }"
      >
        <builder-component
            :builder="builder"
            :admin-edit-mode="true"
        />
      </div>
    </div>

  </div>
</template>
<script>
import BuilderComponent from "@/ui/components/builder/Builder.vue";
import {Builder} from "@/models/builder.js";
import {builderEditorEvents} from "@/utils/events/builder-editor-events.js";
import {builderQuerySelectors} from "@/utils/query-selectors/builder-query-selectors.js";

export default {
  components: {BuilderComponent},
  props: {
    builder: {
      type: Builder,
      required: true
    },
    canSelectElement: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
      selector() {
        return builderQuerySelectors;
      }
  },
  watch: {
    canSelectElement() {
      this.unselectSelectedRef();
    },
  },
  data: () => ({
    selectedRef: null,
    selectedId: null,
  }),
  created() {
    builderEditorEvents.elementSaved.on(this.whenElementSaved)
    builderEditorEvents.unselectElement.on(this.unselectSelectedRef)
    builderEditorEvents.selectElement.on(this.selectElementById)
  },
  destroyed() {
    builderEditorEvents.elementSaved.off(this.whenElementSaved);
    builderEditorEvents.unselectElement.off(this.unselectSelectedRef);
    builderEditorEvents.selectElement.off(this.selectElementById);
  },
  methods: {
    whenElementSaved() {
      this.unselectSelectedRef();
    },

    selectElementById(id) {
      const selectedId = this.selectedId;

      this.unselectSelectedRef();

      if (selectedId + "" !== id + "") {
        const {ref} = builderQuerySelectors.findByAttribute(id);

        if(ref){
          this.setSelectedRef(ref, id)
        }
      } else {
        builderEditorEvents.unselectElement.emit();
      }
    },

    onSelectElement($event) {
      const {builderElementId} = builderQuerySelectors.findClosestElement($event);

      //if we don't have selected it trigger selectElement event
      if(!this.selectedId) {
        builderEditorEvents.selectElement.emit(builderElementId);
      } else {
        builderEditorEvents.unselectElement.emit();
        //if its not same element select the element
        if(builderElementId !== this.selectedId) {
          setTimeout(() => builderEditorEvents.selectElement.emit(builderElementId), 150);
        }
      }
    },

    unselectSelectedRef() {
      if (this.selectedRef) {
        builderQuerySelectors.markEmpty(this.selectedRef);
      }

      this.selectedRef = null;
      this.selectedId = null;
    },

    setSelectedRef(ref, builderElementId) {
      this.selectedRef = ref;
      this.selectedId = builderElementId;

      builderQuerySelectors.markSelected(ref);

      this.$emit('onSelectElement', builderElementId);
    },

    onMouseLeaveElement() {
      builderQuerySelectors.emptyAllExcept(this.selectedId);
    },

    onMouseMoveOnElement($event) {
      const {ref, builderElementId} = builderQuerySelectors.findClosestElement($event)

      builderQuerySelectors.emptyAllExcept(this.selectedId);

      if(ref && builderElementId !== this.selectedId + '') {
        builderQuerySelectors.markHovered(ref);
      }
    },
  },
}
</script>
<style lang="scss">
.builder-elements {
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
  cursor: pointer;
}

.builder-element {
  .style-block {
    box-sizing: border-box;
    border: 1px solid transparent;
  }

  .element-hovered {
    border: 1px solid var(--light-blue) !important;
  }

  .element-selected {
    border: 1px solid red !important;
  }
}
</style>