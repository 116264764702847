export const addRequiredValidationAction = ({passiveElement, action_value, context}) => {
    passiveElement.validation = {
        ...passiveElement.validation,
        required: true,
        message: action_value
    }

    context.dispatch('insuranceModule/builderElementsModule/saveElement', {
        builderElement: passiveElement
    }, {root: true})
}