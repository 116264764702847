<template>
  <div class="py-4 font-s-20 app-text">
    {
    <json-builder-elements
        class="pl-6"
        :builder-elements="builder.elements"
    />
    }
  </div>
</template>
<script>
import JsonBuilderElements from "@/ui/components/json-builder/JsonBuilderElements.vue";
import {Builder} from "@/models/builder.js";

export default {
  components: {JsonBuilderElements},
  props: {
    builder: {
      type: Builder,
      required: true,
    }
  }
}
</script>