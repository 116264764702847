import {objectService} from "@/utils/services/object-service.js";

export class AppFile {
    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(AppFile.empty().toObject())
            : this.fillData(data);
    }

    fillData(data) {
        this.id = data.id;
        this.name = data.name;
        this.url = data.url;
    }

    static empty(data = {}) {
        return new AppFile({
            id: data.id || 0,
            name: data.name || '',
            url: data.url || '',
        })
    }

    toObject() {
        return {
            ...this,
        }
    }
}