<template>
  <div>
    <v-scroll-x-transition>
      <router-view />
    </v-scroll-x-transition>
    <notifications position="bottom right" classes="app-notification"/>
  </div>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    //
  }),
};
</script>
