import {serverApi} from "@/api/server/server-api.js";
import {InsuranceElement} from "@/models/insurance-element.js";
import {InsuranceBuilder} from "@/models/insurance-builder.js";
import {InsuranceType} from "@/models/insurance-type.js";
import {AppFile} from "@/models/app-file.js";
import {InsurancePriceAddon} from "@/models/insurance-price-addon";

export const insuranceTypeModule = {
    namespaced: true,
    actions: {
        getOriginalInsuranceType: async (context, {key}) => {
            const {data} = await serverApi.admin.insuranceType.originalTypeByKey(key)

            return new InsuranceType(data.insuranceType);
        },

        getOriginalTypes: async () => {
            const {data} = await serverApi.admin.insuranceType.originalTypes();

            return data.insuranceTypes.map((elem) => new InsuranceType(elem))
        },

        buildersByInsuranceType: async (context, {insuranceType}) => {
            const {data} = await serverApi.admin.insuranceType.insuranceBuilders(insuranceType.id);

            return data.builders.map((builder) => new InsuranceBuilder(builder))
        },

        riskBuilders: async (context, {insuranceType}) => {
            const {data} = await serverApi.admin.insuranceType.riskInsuranceBuilders(insuranceType.id);


            return data.builders.map((builder) => new InsuranceBuilder(builder))
        },

        priceBuilders: async (context, {insuranceType}) => {
            const {data} = await serverApi.admin.insuranceType.priceInsuranceBuilders(insuranceType.id);

            return data.builders.map((builder) => new InsuranceBuilder(builder))
        },

        customerBuilders: async (context, {insuranceType}) => {
            const {data} = await serverApi.admin.insuranceType.customerInsuranceBuilders(insuranceType.id);

            return data.builders.map((builder) => new InsuranceBuilder(builder))
        },

        policeBuilders: async (context, {insuranceType}) => {
            const {data} = await serverApi.admin.insuranceType.policeInsuranceBuilders(insuranceType.id);

            return data.builders.map((builder) => new InsuranceBuilder(builder))
        },

        createInsuranceBuilder: async (context, {insuranceType, builder, additionalData}) => {
            const {data} = await serverApi.admin.insuranceType.createInsuranceBuilder(
                insuranceType.id,
                builder.id,
                additionalData
            )

            return data.builders.map((builder) => new InsuranceBuilder(builder));
        },

        updateInsuranceBuilder: async (context, {insuranceBuilder, insuranceType, builderData}) => {
            const {data} = await serverApi.admin.insuranceType.updateInsuranceBuilder(
                insuranceBuilder.id,
                insuranceType.id,
                builderData
            );

            return data.builders.map((builder) => new InsuranceBuilder(builder))
        },

        deleteInsuranceBuilder: async (context, {insuranceBuilder, insuranceType}) => {
            const {data} = await serverApi.admin.insuranceType.deleteInsuranceBuilder(insuranceBuilder.id, insuranceType.id)

            return data.builders.map((builder) => new InsuranceBuilder(builder));
        },

        sqlInsuranceBuilder: async (context, {insuranceBuilder, insuranceType}) => {
            const {data} = await serverApi.admin.insuranceType.sqlInsuranceBuilder(insuranceBuilder.id, insuranceType.id)

            return data.sql;
        },

        sqlInsuranceBuilders: async (context, {insuranceType}) => {
            const {data} = await serverApi.admin.insuranceType.sqlInsuranceType(insuranceType.id)

            return data.sql;
        },

        elementsByInsuranceType: async (context, {insuranceType}) => {
            const {data} = await serverApi.admin.insuranceType.elementsByInsuranceType(insuranceType.id)

            return data.elements.map((elem) => new InsuranceElement(elem))
        },

        createInsuranceElement: async (context, {insuranceType, elementData}) => {
            const {data} = await serverApi.admin.insuranceType.createInsuranceElement(insuranceType.id, elementData)

            return data.elements.map((elem) => new InsuranceElement(elem))
        },

        updateInsuranceElement: async (context, {insuranceElement, insuranceType, elementData}) => {
            const {data} = await serverApi.admin.insuranceType.updateInsuranceElement(
                insuranceElement.id,
                insuranceType.id,
                elementData
            )

            return data.elements.map((elem) => new InsuranceElement(elem))
        },

        updateInsuranceType: async (context, {insuranceType, elementData}) => {
            const {data} = await serverApi.admin.insuranceType.updateInsuranceType(
                insuranceType.id,
                elementData
            )

            return data.insuranceTypes.map((elem) => new InsuranceType(elem))
        },

        deleteInsuranceElement: async (context, {insuranceElement, insuranceType}) => {
            const {data} = await serverApi.admin.insuranceType.deleteInsuranceElement(
                insuranceElement.id,
                insuranceType.id
            )

            return data.elements.map((elem) => new InsuranceElement(elem))
        },

        getFiles: async (context, {insuranceType}) => {
            const {data} = await serverApi.admin.insuranceType.getFiles(insuranceType.id)

            return data.files.map((file) => new AppFile(file));
        },

        createFile: async (context, {insuranceType, file}) => {
            const {data} = await serverApi.admin.insuranceType.createFile(insuranceType.id, file);

            return data.success;
        },

        deleteFile: async (context, {insuranceType, appFile}) => {
            const {data} = await serverApi.admin.insuranceType.deleteFile(insuranceType.id, appFile.id);

            return data.success;
        },

        addonsByInsuranceType: async (context, {insuranceType}) => {
            const {data} = await serverApi.admin.insuranceType.addonsByInsuranceType(insuranceType.id)

            return data.addons.map((elem) => new InsurancePriceAddon(elem))
        },

        createInsurancePriceAddon: async (context, {insuranceType, addonData}) => {
            const {data} = await serverApi.admin.insuranceType.createInsurancePriceAddon(insuranceType.id, addonData)

            return new InsurancePriceAddon(data.addon)
        },

        updateInsurancePriceAddon: async (context, {insuranceType, priceAddon , addonData}) => {
            const {data} = await serverApi.admin.insuranceType.updateInsurancePriceAddon(insuranceType.id, priceAddon.id, addonData)

            return new InsurancePriceAddon(data.addon)
        },

        deleteInsurancePriceAddon: async (context, {insuranceType, priceAddon}) => {
            await serverApi.admin.insuranceType.deleteInsurancePriceAddon(insuranceType.id, priceAddon.id)
        },

        deleteAllBuildersAndRelations: async (context, {insuranceTypeId}) => {
            await serverApi.admin.insuranceType.deleteAllBuildersAndRelations(insuranceTypeId)
        },
    }
}