<template>
  <div>
    <div class="d-flex justify-end">

      <blue-btn class="mr-2" @click="onClickCancel">
        Cancel Changes
      </blue-btn>

      <orange-btn class="mr-2" @click="onClickUpdate">
        Update Element
      </orange-btn>

      <red-btn @click="onClickDelete">
        Delete Element
      </red-btn>

    </div>

    <v-form ref="edit_json">
      <json-elements-editor-factory
          v-if="!builderElement.isEmpty()"
          :element="builderElement"
          :elements="elements"
      />
    </v-form>


    <!--    TODO: Relations-->
  </div>
</template>
<script>

import {BuilderElement} from "@/models/builder-element.js";
import JsonElementsEditorFactory
  from "@/ui/components/json-builder/use-cases/json-builder-editor/element-editor/JsonElementsEditorFactory.vue";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import RedBtn from "@/ui/components/common/buttons/RedBtn.vue";
import {builderEditorEvents} from "@/utils/events/builder-editor-events.js";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";

export default {
  components: {BlueBtn, RedBtn, OrangeBtn, JsonElementsEditorFactory},
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    },
    elements: {
      type: Array,
      required: true,
    }
  },

  computed: {
    form() {
      return this.$refs['edit_json']
    },
  },

  data: () => ({
      elementBeforeChanges: BuilderElement.empty(),
  }),

  created() {
      this.elementBeforeChanges = new BuilderElement(this.builderElement.toObject());
  },

  methods: {
    async onClickUpdate() {
      if (await this.form.validate()) {
        builderEditorEvents.updateElement.emit(this.builderElement.id, this.builderElement);
      }
    },

    async onClickDelete() {
      builderEditorEvents.deleteElement.emit(this.builderElement.id);
    },

    onClickCancel() {
      this.builderElement.fillData(this.elementBeforeChanges);
      builderEditorEvents.unselectElement.emit();
    }
  }
}
</script>