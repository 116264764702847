<template>
  <div>
    <div>
      <p class="app-text__bold">Min Value</p>

      <number-field
          name="name"
          :value="value"
          @saveField="saveMinValue"
      />
    </div>
  </div>
</template>
<script>
import NumberField from "@/ui/components/common/fields/NumberField.vue";
export default {
  components: {NumberField},
  props: {
    value: {
      type: String,
      default: 'var(--white-bg)',
    },
  },
  methods: {
    saveMinValue(value) {
      this.$emit('input', value)
      this.$emit('saveMinValue', value)
    }
  }
}
</script>