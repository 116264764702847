import AppLayout from "../../ui/layouts/app/AppLayout.vue";
import {authMiddleware} from "../middlewares/auth.js";
import {routeNames} from "../route-names.js";
import InsuranceShow from "../../ui/pages/insurance/InsuranceShow.vue";
import InsuranceCreate from "@/ui/pages/insurance/InsuranceCreate.vue";
import InsuranceList from "@/ui/pages/insurance/InsuranceList.vue";

export const appRoutes = [
    {
        path: '/',
        component: AppLayout,
        beforeEnter: authMiddleware,
        children: [
            {
                path: '/',
                name: 'test',
                component: InsuranceCreate,
            },
            {
                path: '/insurance/create',
                name: routeNames.insuranceCreate,
                component: InsuranceCreate,
            },
            {
                path: '/insurance/show/:insuranceId',
                name: routeNames.insuranceShow,
                props: route => ({ insuranceId: parseInt(route.params.insuranceId) }),
                component: InsuranceShow,
            },
            {
                path: '/insurance/list',
                name: routeNames.insuranceList,
                component: InsuranceList,
            }
        ]
    },
];