<template>
  <div :style="{margin, padding, height, width}">
    <radio-buttons-horizontal
        :value="value"
        :buttons="options"
        :columns="columns"
        :per-column="perColumn"
        :item-text-key="itemTextKey"
        :item-value-key="itemValueKey"
        :validation="validation"
        :disabled="disabledBuilderElement"
        :tooltips="tooltips"
        @saveField="saveField"
    />
  </div>
</template>
<script>
import Padding from "./mixins/Padding.js";
import DefaultElement from "./mixins/DefaultElement.js";
import RadioButtonsHorizontal from "../../common/fields/radio/RadioButtonsHorizontal.vue";
import Options from "./mixins/Options.js";
import Size from "./mixins/Size.js";
import {builderElementsEvents} from "@/utils/events/builder-elements-events.js";
import Validation from "@/ui/components/builder/elements/mixins/Validation";

export default {
  components: {RadioButtonsHorizontal},
  mixins: [DefaultElement, Padding, Options, Size, Validation],
  props: {
    disabledBuilderElement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
      columns() {
        return this.builderElement.data?.columns || 1;
      },

      tooltips() {
        return this.builderElement.data?.tooltips || [];
      },

      perColumn() {
        return this.builderElement.data?.per_column || null;
      }
  },
  methods: {
    saveField(value) {
      this.builderElement.value = value;

      builderElementsEvents.updateValue.emit(this.builderElement)
    }
  }
}
</script>