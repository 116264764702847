<template>
  <div>
    <v-row>
      <v-col cols="5"><div class="mt-3 small-text">Letztes Update Prod-Server</div></v-col>
      <v-col cols="7">
        <datepicker-field
          width="200px"
          placeholder="TT.MM.JJ"
          name="prod_updated_at"
          :value="prodUpdatedAt"
          min-width="100px"
          @saveField="saveDateField"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import DatepickerField from "@/ui/components/common/fields/DatepickerField.vue";

export default {
  components: {DatepickerField},
  computed: {
    prodUpdatedAtElement() {
      return this.$store.getters["insuranceModule/dashboardInfoModule/getProdUpdatedAtElement"]
    },
    prodUpdatedAt() {
      return this.prodUpdatedAtElement.value ?? ''
    },
  },
  async created() {
    await this.getProdUpdatedAt()
  },
  methods: {
    async getProdUpdatedAt() {
      return this.$store.dispatch('insuranceModule/dashboardInfoModule/getProdUpdatedAtElement')
    },
    saveDateField(val) {
      this.saveProdUpdatedAt(this.prodUpdatedAtElement.id, {value: val})
    },
    async saveProdUpdatedAt(id, requestData) {
      return this.$store.dispatch('insuranceModule/dashboardInfoModule/updateProdAtInfoData', {id, requestData})
    },
  },
}
</script>

<style scoped>
.small-text {
  font-size: 12px;
}
</style>