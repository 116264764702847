<template>
  <simple-popup header="" @close="onClose">
    <center-block v-if="loading">
      <app-preloader/>
    </center-block>
    <div v-else>
      <div class="d-flex justify-space-between">
        <p class="mt-2 mr-4 app-text__bold">Sql Query</p>

        <div class="d-flex">
          <blue-btn class="mr-2" @click="onClickCopy">Copy SQL</blue-btn>
          <orange-btn @click="onClickExecute">Execute SQL</orange-btn>
        </div>
      </div>

      <text-area-field
          name="query"
          :rows="18"
          v-model="sql"
      />
    </div>

  </simple-popup>
</template>
<script>
import SimplePopup from "@/ui/components/common/popup/SimplePopup.vue";
import CenterBlock from "@/ui/components/common/blocks/CenterBlock.vue";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";
import {notificationService} from "@/utils/services/notification-service.js";

export default {
  components: {TextAreaField, OrangeBtn, AppPreloader, BlueBtn, CenterBlock, SimplePopup},
  props: {
    sql: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    onClose() {
        this.$emit('close');
    },

    async onClickCopy() {
      this.copyText(this.sql)

      notificationService.success('Sql was copied')
    },

    async onClickExecute() {
      this.loading = true;

      if(await this.executeSql(this.sql)) {
        notificationService.success('Success')
      } else {
        notificationService.error('Something went wrong');
      }

      this.loading = false
    },

    copyText(text) {
      navigator.clipboard.writeText(text);
    },

    executeSql(sql) {
      return this.$store.dispatch('admin/executeSql', {sql})
    }
  }
}
</script>