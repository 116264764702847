export const insurancePricePackagesEnum = {
    basicCorporateGovernance: 'basicCorporateGovernance',
    smartCorporateGovernance: 'smartCorporateGovernance',
    comfortCorporateGovernance: 'comfortCorporateGovernance',
    premiumCorporateGovernance: 'premiumCorporateGovernance',
}

export const additionalDataEnum = {
    lateRegistrationPeriod: 'lateRegistrationPeriod',
    noOmissionLateRegistrationPeriod: 'noOmissionLateRegistrationPeriod',
    annualPeakPerformance: 'annualPeakPerformance',
    additionalDefenseCostLimit: 'additionalDefenseCostLimit',
    extrajudicialCosts: 'extrajudicialCosts',
    deductible: 'deductible',
    outsideDirectorateLiability: 'outsideDirectorateLiability',
}