<template>
  <div :style="{width: '100%'}">
    <v-row>
      <v-col cols="3">
        <p class="app-text__bold mt-2 pl-5">Element to compare</p>
        <div class="d-flex justify-space-between">
          <div class="pt-3 pr-2">IF</div>

          <select-builder-element-popup-field
              class="width-100"
              :builder-elements="builderElements"
              :validation="{required: true}"
              v-model="builderElementCondition.passive_element_id"
          />
        </div>

      </v-col>
      <v-col cols="3" class="pr-2 pl-4">
        <p class="app-text__bold mt-2">Condition</p>

        <searchable-select-field
            name="condition_type"
            :items="conditions"
            :validation="{required: true,}"
            v-model="builderElementCondition.condition"
            @saveField="onChangeCondition"
        />
      </v-col>
      <v-col cols="4">
        <div v-if="isPriceDataCondition">
          <div>
            <p class="app-text__bold mt-2">Price data</p>
            <searchable-select-field
                name="price_data_key"
                :items="priceDataKeys"
                :validation="{required: true,}"
                v-model="builderElementCondition.data.price_data_key"
            />
          </div>
          <div>
            <p class="app-text__bold mt-2">Condition</p>
            <searchable-select-field
                name="condition_action"
                :items="priceDataConditions"
                :validation="{required: true,}"
                v-model="builderElementCondition.data.condition_action"
            />
          </div>
        </div>

        <div v-if="isStaticCondition && !isDateRangeCondition">
          <p class="app-text__bold mt-2">Value</p>
          <text-field
              name="value"
              v-model="builderElementCondition.condition_value"
          />
          <p v-if="valueHint">Condition format:</p>
          <p class="value-hint">{{ valueHint}}</p>
        </div>

        <div v-if="isBuilderElementCondition && !isDateRangeCondition">
          <p class="app-text__bold mt-2">Element with compare</p>
          <select-builder-element-popup-field
              class="width-100"
              :builder-elements="builderElements"
              :validation="{required: true}"
              v-model="builderElementCondition.active_element_id"
          />
        </div>

        <div v-if="isDateRangeCondition">
          <h3>Range validation</h3>
          <date-field-validation-editor
              header="Start range"
              v-if="builderElementCondition.data.range.start"
              :validation="builderElementCondition.data.range.start"
              :has-validation-message="false"
              :edit-condition-type="true"
          />

          <date-field-validation-editor
              v-if="builderElementCondition.data.range.end"
              class="mt-4"
              header="End range"
              :validation="builderElementCondition.data.range.end"
              :has-validation-message="false"
              :edit-condition-type="true"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SearchableSelectField from "@/ui/components/common/fields/SearchableSelectField.vue";
import {BuilderElementRelationCondition} from "@/models/builder-element-relations/builder-element-relation-condition.js";
import {builderElementRelationsService} from "@/utils/services/builder-element-relations-service.js";
import TextField from "@/ui/components/common/fields/TextField.vue";
import SelectBuilderElementPopupField
  from "@/ui/components/builder/use-cases/builder-relations/SelectBuilderElementPopupField.vue";
import {builderElementRelationConditionEnum} from "@/enums/builder-element-relations-enum";
import DateFieldValidationEditor
  from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/DateFieldValidationEditor.vue";
import {DATE_CONDITION, DateFieldValidation} from "@/utils/types/date-field-validation";
import {INSURANCE_PRICE_LIST_FIELD_NAMES} from "@/utils/fields/insurance-price-list-field-names";
export default {
  components: {DateFieldValidationEditor, SelectBuilderElementPopupField, TextField, SearchableSelectField},
  props: {
    builderElementCondition: {
      type: BuilderElementRelationCondition,
      required: true,
    },
    conditions: {
      type: Array,
      required: true,
    },
    builderElements: {
      type: Array,
      required: true,
    }
  },
  computed: {
    valueHint() {
      switch (this.builderElementCondition.condition) {
        case builderElementRelationConditionEnum.SUM_BY_FIELDS_MORE_THAN : return 'nameA;nameB;nameC>5';
        case builderElementRelationConditionEnum.SUM_BY_FIELDS_CONDITION : return 'field1,field2,field3|operator|target value';
        case builderElementRelationConditionEnum.VALUE_IN_LIST :
        case builderElementRelationConditionEnum.VALUE_NOT_IN_LIST :
          return 'value1||value2||value3';
        default: return ''
      }
    }
  },
  data: () => ({
    isStaticCondition: false,
    isPriceDataCondition: false,
    isBuilderElementCondition: false,
    isDateRangeCondition: false,
    priceDataKeys: [
      INSURANCE_PRICE_LIST_FIELD_NAMES.sumInsured,
      INSURANCE_PRICE_LIST_FIELD_NAMES.deductible,
      INSURANCE_PRICE_LIST_FIELD_NAMES.duration,
      INSURANCE_PRICE_LIST_FIELD_NAMES.paymentPeriod,
      INSURANCE_PRICE_LIST_FIELD_NAMES.maxSumInsured,
    ],
    priceDataConditions: [
        '=',
        '>',
        '<',
        '>=',
        '<=',
    ],
  }),
  created() {
    this.onChangeCondition(this.builderElementCondition.condition)
  },
  methods: {
    onChangeCondition(conditionType) {
      this.isStaticCondition = this.checkOnStaticCondition(conditionType);
      this.isPriceDataCondition = this.checkOnPriceDataCondition(conditionType);
      this.isBuilderElementCondition = this.checkOnBuilderElementCondition(conditionType);
      this.isDateRangeCondition = conditionType === builderElementRelationConditionEnum.IS_IN_DATE_RANGE;

      if (this.isDateRangeCondition) {
        this.initDateRangeCondition()
      }

      if (this.isPriceDataCondition) {
        this.initPriceDataCondition()
      }
    },

    checkOnStaticCondition(conditionType) {
      return builderElementRelationsService.getStaticConditions().includes(conditionType);
    },

    checkOnPriceDataCondition(conditionType) {
      return builderElementRelationsService.getPriceDataConditions().includes(conditionType);
    },

    checkOnBuilderElementCondition(conditionType) {
      return builderElementRelationsService.getBuilderElementConditions().includes(conditionType);
    },

    initDateRangeCondition() {
      if (!this.builderElementCondition.data) {
        this.builderElementCondition.data = {};
      }

      if (!this.builderElementCondition.data.range) {
        this.builderElementCondition.data.range = {
            start: null,
            end: null
          }
      }

      if (this.builderElementCondition.data.range.start) {
        this.builderElementCondition.data.range.start = new DateFieldValidation(this.builderElementCondition.data.range.start);
      } else {
        this.builderElementCondition.data.range.start = DateFieldValidation.default(DATE_CONDITION.MORE);
      }

      if (this.builderElementCondition.data.range.end) {
        this.builderElementCondition.data.range.end = new DateFieldValidation(this.builderElementCondition.data.range.end);
      } else {
        this.builderElementCondition.data.range.end = DateFieldValidation.default(DATE_CONDITION.MORE);
      }
    },

    initPriceDataCondition() {
      if (!this.builderElementCondition.data) {
        this.builderElementCondition.data = {};
      }

      if (!this.builderElementCondition.data.price_data_key) {
        this.builderElementCondition.data.price_data_key = '';
      }

      if (!this.builderElementCondition.data.condition_action) {
        this.builderElementCondition.data.condition_action = '';
      }
    },
  },
}
</script>
<style scoped>
  .value-hint {
    color: #003787;
    margin-top: 10px;
  }
</style>