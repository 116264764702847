<template>
  <div>
    <p class="app-text__bold">Name</p>

    <text-field
      name="name"
      :validation="{required: true}"
      :value="value"
      @saveField="saveName"
    />
  </div>
</template>
<script>

import TextField from "@/ui/components/common/fields/TextField.vue";
export default {
  components: {TextField},
  props: {
    value: {
      type: String,
      default: '',
    }
  },
  methods: {
    saveName(value) {
      this.$emit('input', value)
      this.$emit('saveName', value);
    }
  }
}
</script>