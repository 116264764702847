<template>
  <div>
    <div class="text-center pb-2">
      <h3>Section</h3>
    </div>

    <v-row>
      <v-col cols="4">
        <name-editor
            v-model="builderElement.name"
        />
      </v-col>

      <v-col class="pl-4" cols="4">
        <priority-editor
            v-model.number="builderElement.priority"
        />
      </v-col>

      <v-col class="pl-4" cols="4">
          <background-editor
            v-model="builderElement.data.background"
          />
      </v-col>

      <v-col cols="6">
        <height-editor
            v-model="builderElement.data.height"
        />
      </v-col>

      <v-col class="pl-4" cols="6">
        <width-editor
            v-model="builderElement.data.width"
        />
      </v-col>

    </v-row>


    <margin-editor
        v-model="builderElement.data.margin"
    />

    <padding-editor
        class="mt-6"
        v-model="builderElement.data.padding"
    />

    <css-class-editor
        class="mt-6"
        v-model="builderElement.data.css_class"
    />

    <id-editor
        class="mt-6 mb-6"
        v-model="builderElement.data.id"
    />
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import MarginEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MarginEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import PaddingEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PaddingEditor.vue";
import BackgroundEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/BackgroundEditor.vue";
import HeightEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/HeightEditor.vue";
import WidthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/WidthEditor.vue";
import IdEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/IdEditor.vue";
import CssClassEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/CssClassEditor.vue";

export default {
  components: {
    WidthEditor, HeightEditor, BackgroundEditor, PaddingEditor, NameEditor, PriorityEditor, MarginEditor, IdEditor, CssClassEditor},
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  },
}
</script>