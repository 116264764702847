<template>
  <toggle-block
      header="Padding"
      opened
  >
    <v-row>
      <v-col cols="6" class="d-flex px-1">
        <div class="app-text__bold mr-2 pt-1 ml-3">
          Top:
        </div>

        <number-field
            name="PaddingTop"
            placeholder="Padding Top"
            v-model="topPadding"
        />
      </v-col>

      <v-col cols="6" class="d-flex px-1">
        <div class="app-text__bold mr-2 pt-1">
          Bottom:
        </div>

        <number-field
            name="PaddingBottom"
            placeholder="Padding Bottom"
            v-model="bottomPadding"
        />
      </v-col>

      <v-col cols="6" class="d-flex px-1">
        <div class="app-text__bold mr-2 pt-1">
          Right:
        </div>

        <number-field
            name="PaddingRight"
            placeholder="Padding Right"
            v-model="rightPadding"
        />
      </v-col>

      <v-col cols="6" class="d-flex px-1">
        <div class="app-text__bold mr-2 pt-1 ml-7">
          Left:
        </div>

        <number-field
            name="PaddingLeft"
            placeholder="Padding Left"
            v-model="leftPadding"
        />
      </v-col>
    </v-row>
  </toggle-block>
</template>
<script>
import ToggleBlock from "@/ui/components/common/blocks/ToggleBlock.vue";
import NumberField from "@/ui/components/common/fields/NumberField.vue";
import {textService} from "@/utils/services/text-service.js";

export default {
  components: {NumberField, ToggleBlock},
  props: {
    value: {
      type: String,
      default: '0 0 0 0',
      validation: (value) => {
        const arr = this.splitPadding(value);

        if(arr.length === 4) {
            return 'success';
        }

        return 'danger';
      }
    }
  },
  watch: {
      leftPadding() {
        this.buildPadding();
      },
      rightPadding() {
        this.buildPadding();
      },
      topPadding() {
        this.buildPadding();
      },
      bottomPadding() {
        this.buildPadding();
      }
  },
  data: () => ({
      topPadding: '0',
      rightPadding: '0',
      leftPadding: '0',
      bottomPadding: '0',
  }),
  created() {
      const arr = this.splitPadding(this.value);

      this.fillPadding(arr[0], arr[1], arr[2], arr[3]);
  },
  methods: {
    buildPadding() {
      const Padding = this.topPadding + 'px ' + this.rightPadding + 'px ' + this.bottomPadding + 'px ' + this.leftPadding + "px";

      this.$emit('input', Padding)
      this.$emit('setPadding', Padding);
    },
    splitPadding(value) {
      return value.split(' ');
    },
    fillPadding(top, right, bottom, left) {
      this.topPadding = textService.numberFromText(top);
      this.rightPadding = textService.numberFromText(right);
      this.bottomPadding = textService.numberFromText(bottom);
      this.leftPadding = textService.numberFromText(left);
    }
  }
}
</script>