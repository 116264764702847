<template>
  <simple-popup
    header="Edit Options"
    @close="$emit('close')"
  >
    <text-area-field
      name="query"
      :rows="18"
      :value="JSON.stringify(options)"
      @saveField="onChangeOptions"

    />
    <blue-btn
      @click="onUpdateOptions"
    >
      Update
    </blue-btn>
  </simple-popup>
</template>

<script>

import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";
import SimplePopup from "@/ui/components/common/popup/SimplePopup.vue";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import {notificationService} from "@/utils/services/notification-service.js";

export default {
  components: {BlueBtn, SimplePopup, TextAreaField},
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    jsonOptions: '',
  }),
  methods: {
    onChangeOptions(val) {
      this.jsonOptions = val
    },
    onUpdateOptions() {
      if (!this.jsonOptions.length) {
        this.$emit('updateOptions', [])
      }

      try {
        this.$emit('updateOptions', JSON.parse(this.jsonOptions))

        this.$emit('close')
      } catch (e) {
        notificationService.error('Incorrect JSON format')
      }
    },
  },
}
</script>
