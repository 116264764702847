<template>
  <v-row justify="center">
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        persistent
    >
      <v-toolbar
          absolute
          dark
          color="blue-grey darken-2"
      >
        <span class="text-h4 text--white">Insurance Relations</span>

        <v-spacer/>

        <white-btn height="80%" @click="onClickClose">Close</white-btn>
      </v-toolbar>
      <v-card
          color="var(--smoke)"
      >
        <v-row class="element-relations__section">
          <v-col cols="8" class="pl-8">
            <h1 class="mb-4 text-center">Relation</h1>

            <insurance-element-relation-operation-section
                :builder-elements="builderElements"
                :insurance-elements="insuranceElements"
                :insurance-type="insuranceType"
            />
          </v-col>
          <v-col cols="4" class="px-8 pb-8">
            <h1 class="mb-4 text-center">Fields</h1>

            <insurance-element-relation-elements-section
                :insurance-type="insuranceType"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import InsuranceElementRelationElementsSection
  from "@/ui/components/admin/insurance-element-relations/sections/InsuranceElementRelationElementsSection.vue";
import InsuranceElementRelationOperationSection
  from "@/ui/components/admin/insurance-element-relations/sections/InsuranceElementRelationOperationSection.vue";
import {InsuranceType} from "@/models/insurance-type.js";

export default {
  components: {
    InsuranceElementRelationOperationSection,
    InsuranceElementRelationElementsSection,
    WhiteBtn,
  },
  props: {
    insuranceElements: {
      type: Array,
      required: true,
    },
    builderElements: {
      type: Array,
      required: true,
    },
    insuranceType: {
      type: InsuranceType,
      required: true,
    }
  },
  data: () => ({
    show: true,
  }),

  methods: {
    onClickClose() {
      this.$emit('close')
    },
  }
}
</script>
<style scoped>
.element-relations__section {
  padding-top: 120px !important;
}
</style>