import {
    builderElementRelationActionsEnum,
    builderElementRelationConditionEnum,
    staticFieldTitles
} from "@/enums/builder-element-relations-enum.js";

export const builderElementRelationsService = {

    conditionsArray() {
        return Object.values(builderElementRelationConditionEnum)
    },

    getStaticConditions() {
        return [
            builderElementRelationConditionEnum.EQUAL_TO,
            builderElementRelationConditionEnum.NOT_EQUAL_TO,
            builderElementRelationConditionEnum.IS_MORE_THEN,
            builderElementRelationConditionEnum.IS_LESS_THEN,
            builderElementRelationConditionEnum.IS_MORE_THEN_YEARS_IN_THE_PAST,
            builderElementRelationConditionEnum.IS_LESS_THEN_YEARS_IN_THE_PAST,
            builderElementRelationConditionEnum.IS_MORE_OR_EQUAL_THEN_YEARS_IN_THE_PAST,
            builderElementRelationConditionEnum.IS_LESS_OR_EQUAL_THEN_YEARS_IN_THE_PAST,
            builderElementRelationConditionEnum.SUM_BY_FIELDS_MORE_THAN,
            builderElementRelationConditionEnum.SUM_BY_FIELDS_CONDITION,
            builderElementRelationConditionEnum.USER_API_ROLE_EQUAL_TO,
            builderElementRelationConditionEnum.COMPARE_PRICE_DATA,
            builderElementRelationConditionEnum.VALUE_IN_LIST,
            builderElementRelationConditionEnum.VALUE_NOT_IN_LIST,
        ]
    },
    getPriceDataConditions() {
        return [
            builderElementRelationConditionEnum.COMPARE_PRICE_DATA,
        ]
    },

    getBuilderElementConditions() {
        return [
            builderElementRelationConditionEnum.EQUAL_TO_BUILDER_ELEMENT
        ]
    },

    getStaticActions() {
        return [
            builderElementRelationActionsEnum.SET_STATIC_VALUE,
            builderElementRelationActionsEnum.SET_STATIC_VALUE_NOT_SKIP,
            builderElementRelationActionsEnum.SET_LABEL_TEXT,
            builderElementRelationActionsEnum.SUM,
            builderElementRelationActionsEnum.REQUIRED,
            builderElementRelationActionsEnum.MIN_VALUE_VALIDATION,
            builderElementRelationActionsEnum.MAX_VALUE_VALIDATION,
            builderElementRelationActionsEnum.SET_VALIDATION_BY_SUM,
            builderElementRelationActionsEnum.REPEAT_BY_CHECKBOXES_COUNT,
            builderElementRelationActionsEnum.SET_SUM_BY_FIELDS,
            builderElementRelationActionsEnum.SET_VALIDATION_BY_SUM_MORE,
            builderElementRelationActionsEnum.ADD_SPECIAL_SYMBOLS_VALIDATION,
            builderElementRelationActionsEnum.MULTIPLE_DUPLICATE,
            builderElementRelationActionsEnum.SET_VALIDATION_EQUAL_TO_ELEMENT_VALUE,
            builderElementRelationActionsEnum.ADD_INCORRECT_VALUE_VALIDATION,
            builderElementRelationActionsEnum.SET_IBAN_DATA,
        ]
    },

    getStaticFieldTitle(actionType) {
        if (staticFieldTitles[actionType]) {
            return staticFieldTitles[actionType]
        }

        return 'Value';
    },

    getBuilderElementActions() {
        return [
            builderElementRelationActionsEnum.SET_BUILDER_ELEMENT_VALUE,
            builderElementRelationActionsEnum.SET_BUILDER_ELEMENT_DATE_WITH_DATE_PARAMS,
            builderElementRelationActionsEnum.DATE_FIELD_VALIDATION_BY_FIELD,
            builderElementRelationActionsEnum.MULTIPLE_DUPLICATE,
            builderElementRelationActionsEnum.FIND_CITY,
            builderElementRelationActionsEnum.REPEAT_BY_ELEMENT,
            builderElementRelationActionsEnum.SET_REPEATED_VALUE_BY_ELEMENT,
            builderElementRelationActionsEnum.REPEAT_BY_CHECKBOXES_COUNT,
            builderElementRelationActionsEnum.PREFILL_DATE_FROM_DURATION_PRICE_TAB,
            builderElementRelationActionsEnum.LINK_FIELDS,
            builderElementRelationActionsEnum.SPECIAL_DUPLICATE_WITH_COPY,
            builderElementRelationActionsEnum.SPECIAL_COPY_DATA,
            builderElementRelationActionsEnum.REPEAT_BY_DUPLICATED_ELEMENT,
            builderElementRelationActionsEnum.SET_VALIDATION_EQUAL_TO_ELEMENT_VALUE,
        ]
    },

    getMultiBuilderElementActions() {
        return [
            builderElementRelationActionsEnum.REPEAT_BY_CHECKBOXES_COUNT,
        ]
    },

    getSpecialDuplicateWithCopyActions() {
        return [
            builderElementRelationActionsEnum.SPECIAL_DUPLICATE_WITH_COPY,
        ]
    },

    getRepeatByDuplicateActions() {
        return [
            builderElementRelationActionsEnum.REPEAT_BY_DUPLICATED_ELEMENT,
        ]
    },

    getSpecialCopyDataActions() {
        return [
            builderElementRelationActionsEnum.SPECIAL_COPY_DATA,
        ]
    },

    actionsArray() {
        return Object.values(builderElementRelationActionsEnum)
    },
}
