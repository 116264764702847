export const sumAction = ({passiveElement, action_value, context}) => {
    if (!isNaN(action_value)) {
        passiveElement.value = Number(passiveElement.value || 0) + Number(action_value)
        context.dispatch('insuranceModule/builderElementsModule/saveElement', {
            builderElement: passiveElement
        }, {root: true})

        context.dispatch('insuranceModule/builderElementRelationsModule/runRelationsByField', {
            builderElement: passiveElement,
        }, {root: true})
    }
}

