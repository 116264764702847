<template>
  <v-card
      :height="height"
      :width="width"
      class="app-block app-text"
      :color="color"
  >
    <v-row v-if="header">
      <v-col class="pt-4" cols="12">
        <h3>{{ header }}</h3>
      </v-col>
    </v-row>

    <slot />
  </v-card>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'var(--white-bg)',
    },
    height: {
      type: String,
      default: 'auto'
    },
    width: {
      type: String,
      default: 'auto'
    },
    header: {
      type: String,
      required: false,
    }
  }
}
</script>
<style scoped>
.app-block {
  padding: 15px 15px 0 35px;
  border-radius: 0;
  box-shadow: none !important;
}
</style>