<template>
  <div>
    <risk-step v-if="isRiskTab"/>

    <price-step v-else-if="isPriceTab"/>

    <customer-step v-else-if="isCustomerTab"/>

    <policy-step v-else-if="isPolicyStep"></policy-step>

    <finish-step v-else-if="isFinishStep"></finish-step>
  </div>
</template>
<script>
import PriceStep from "@/ui/components/insurance/insurance-steps/types/PriceStep.vue";
import RiskStep from "@/ui/components/insurance/insurance-steps/types/RiskStep.vue";
import CustomerStep from "@/ui/components/insurance/insurance-steps/types/CustomerStep.vue";
import PolicyStep from "@/ui/components/insurance/insurance-steps/types/PolicyStep.vue";
import FinishStep from "@/ui/components/insurance/insurance-steps/types/FinishStep.vue";

export default {
  components: {CustomerStep, RiskStep, PriceStep, PolicyStep, FinishStep},
  computed: {
    insurance() {
      return this.$store.getters["insuranceModule/getInsurance"]
    },

    isRiskTab() {
      return this.insurance.isDraft() || this.insurance.isRiskStarted();
    },

    isPriceTab() {
      return this.insurance.isEmptyPrice() || this.insurance.isRiskFinished();
    },

    isCustomerTab() {
      return this.insurance.isCustomerData();
    },

    isPolicyStep() {
        return this.insurance.isPolicyStep();
    },

    isFinishStep() {
        return this.insurance.isFinishStep();
    },
  },
}
</script>