<template>
  <div>
    <v-row>
      <v-col cols="12">
        <name-editor
            v-model="builderElement.name"
        />
      </v-col>

      <v-col cols="6">
        <priority-editor
            v-model.number="builderElement.priority"
        />
      </v-col>

      <v-col class="pl-4" cols="6">
        <width-editor
            v-model="builderElement.data.width"
        />
      </v-col>

      <v-col cols="12">
        <p class="app-text__bold">Popup Html</p>
        <text-area-field
          name="html"
          v-model="builderElement.data.html"
        />
      </v-col>

      <v-col cols="12">
        <p class="app-text__bold">Tooltip Label</p>
        <text-area-field
            name="value"
            v-model="builderElement.value"
        />
      </v-col>
      <v-col cols="12"><css-class-editor
          class="mt-4"
          v-model="builderElement.data.css_class"
      /></v-col>
      <v-col class="pl-4" cols="6">
        <is-exclamation-tooltip-editor
            v-model="builderElement.data.is_exclamation_tooltip"
        />
        <is-hide-popup-editor
            v-model="builderElement.data.is_hide_popup"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import WidthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/WidthEditor.vue";
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";
import CssClassEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/CssClassEditor.vue";
import IsExclamationTooltipEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/IsExclamationTooltipEditor.vue";
import IsHidePopupEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/IsHidePopupEditor.vue";

export default {
  components: {TextAreaField, WidthEditor, PriorityEditor, NameEditor, CssClassEditor, IsExclamationTooltipEditor, IsHidePopupEditor},
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  }
}
</script>