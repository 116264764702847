<template>
  <div :style="{padding, margin, width}">
    <searchable-min-symbols-to-filter-field
      :name="name"
      :value="value"
      :items="options"
      :placeholder="placeholder"
      :is-disabled="isDisabled || disabledBuilderElement"
      :validation="validation"
      :min-symbols-to-start-filter="minSymbolsToStartFilter"
      clearable
      @saveField="saveField"
    />
  </div>
</template>
<script>
import DefaultElement from "./mixins/DefaultElement.js";
import Padding from "./mixins/Padding.js";
import Placeholder from "./mixins/Placeholder.js";
import SearchableMinSymbolsToFilterField from "@/ui/components/common/fields/SearchableMinSymbolsToFilterField.vue";
import Size from "./mixins/Size.js";
import Options from "./mixins/Options.js";
import {builderElementsEvents} from "@/utils/events/builder-elements-events.js";
import IsDisabled from "./mixins/IsDisabled.js";
import Validation from "@/ui/components/builder/elements/mixins/Validation";
import MinSymbolsToStartFilter from "./mixins/MinSymbolsToStartFilter.js";

export default {
  components: {SearchableMinSymbolsToFilterField},
  mixins:[DefaultElement, Padding, Placeholder, Size, Options, IsDisabled, Validation, MinSymbolsToStartFilter],
  props: {
    disabledBuilderElement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    saveField(value) {
      this.builderElement.value = value;

      builderElementsEvents.updateValue.emit(this.builderElement)
    }
  }
}
</script>