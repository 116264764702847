<template>
  <div>
    <p class="app-text__bold">Mask</p>

    <text-field
      name="mask"
      :value="value"
      @saveField="saveMask"
    />
  </div>
</template>
<script>
import TextField from "@/ui/components/common/fields/TextField.vue";

export default {
  components: {TextField},
  props: {
    value: {
      type: String,
      default: '',
    }
  },
  methods: {
    saveMask(value) {
      this.$emit('input', value)
      this.$emit('saveMask', value);
    }
  }
}
</script>