export const setRepeatedValueByElementAction = async ({activeElement, passiveElement, context}) => {
    activeElement.data.is_repeat_value_active = 1;
    passiveElement.data.is_repeat_value_passive = 1;

    activeElement.data.set_value_repeat_element_id = passiveElement.id;

    context.dispatch('insuranceModule/builderElementsModule/saveElement', {
        builderElement: passiveElement
    }, {root: true});

    context.dispatch('insuranceModule/builderElementsModule/saveElement', {
        builderElement: activeElement
    }, {root: true});
}