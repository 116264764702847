<template>
  <div>
    <div class="text-center">
      <h3>Text Field</h3>
    </div>

    <v-row>
      <v-col cols="12">
        <name-editor
            v-model="builderElement.name"
        />
      </v-col>

      <v-col cols="12" class="mt-2">
        <disabled-editor
            v-model="builderElement.data.disabled"
        />
      </v-col>

      <v-col cols="6">
        <priority-editor
            v-model.number="builderElement.priority"
        />
      </v-col>

      <v-col class="pl-4" cols="6">
        <width-editor
            v-model="builderElement.data.width"
        />
      </v-col>

      <v-col cols="6">
        <p class="app-text__bold">Default Value</p>
        <text-field
            name="value"
            v-model="builderElement.value"
        />
      </v-col>

      <v-col cols="6" class="pl-4">
        <placeholder-editor
            v-model="builderElement.data.placeholder"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="pl-4">
        <is-required
            v-model="builderElement.validation.required"
        />
      </v-col>
      <v-col cols="8" class="pl-4">
        <validation-message-editor
            v-model="builderElement.validation.message"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2" class="pl-4">
        <min-length-editor
          v-model="builderElement.validation.minLength"
        />
      </v-col>

      <v-col cols="2" class="pl-4">
        <max-length-editor
          v-model="builderElement.validation.maxLength"
        />
      </v-col>

      <v-col cols="6" class="pl-4">
        <mask-editor
          v-model="builderElement.data.mask"
        />
      </v-col>
      <v-col cols="12" class="pl-4">
        <validation-message-editor
          title="Validation Message for min length validation"
          v-model="builderElement.validation.minLengthMessage"
        />
      </v-col>
    </v-row>

    <margin-editor
        v-model="builderElement.data.margin"
    />
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import WidthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/WidthEditor.vue";
import TextField from "@/ui/components/common/fields/TextField.vue";
import PlaceholderEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PlaceholderEditor.vue";
import MarginEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MarginEditor.vue";
import DisabledEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/DisabledEditor.vue";
import ValidationMessageEditor
  from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/ValidationMessageEditor.vue";
import IsRequired from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/isRequired.vue";
import MaskEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MaskEditor.vue";
import MinLengthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MinLengthEditor.vue";
import MaxLengthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MaxLengthEditor.vue";

export default {
  components: {
    IsRequired,
    DisabledEditor, MarginEditor, PlaceholderEditor, TextField, WidthEditor, PriorityEditor, NameEditor, ValidationMessageEditor, MaskEditor, MinLengthEditor, MaxLengthEditor},
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  }
}
</script>