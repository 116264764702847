var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-5"},[_c('select-field',{attrs:{"name":"selectDate","width":"300px","items":_vm.selectOptions,"value":_vm.selectValue},on:{"saveField":_vm.selectPeriodDate}}),_c('div',{staticClass:"row pl-0"},[_c('div',{staticClass:"col-6"},[_c('span',[_vm._v("Von")]),_c('datepicker-field',{attrs:{"width":"276px","placeholder":"TT.MM.JJ","name":"from","value":_vm.from},on:{"saveField":_vm.onSelectDate}})],1),_c('div',{staticClass:"col-6 pl-6"},[_c('span',[_vm._v("Bis")]),_c('datepicker-field',{attrs:{"width":"276px","placeholder":"TT.MM.JJ","name":"to","value":_vm.to},on:{"saveField":_vm.onSelectDate}})],1)])],1),_c('div',{staticClass:"col-12"},[_c('select-field',{attrs:{"name":"insuranceType","width":"300px","items":_vm.insuranceTypeOptions,"itemText":"name","itemValue":"key"},on:{"saveField":_vm.onSelectInsuranceType}})],1),(_vm.loaded && _vm.values.length)?_c('div',{staticClass:"col-12"},[_c('apexchart',{attrs:{"width":"100%","height":_vm.height,"type":"bar","options":{
        xaxis: {
          categories: _vm.labels,
        },
        yaxis: {
          labels: {
          maxWidth: 350
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        chart: {
          toolbar: {
            export: {
              csv: {
                filename: _vm.getFileName,
              },
              svg: {
                filename: _vm.getFileName,
              },
              png: {
                filename: _vm.getFileName,
              },
            },
            tools: {
              download: '📥',
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
      },"series":[{
          data:_vm.values,
      }]}})],1):(_vm.loaded && !_vm.values.length)?_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"empty-data__string"},[_vm._v(" Empty Data ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }