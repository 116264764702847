<template>
  <div>
    <p class="app-text__bold">{{ label }}</p>

    <text-field
        name="height"
        :validation="{required: true}"
        :value="value"
        @saveField="saveHeight"
    />
  </div>
</template>
<script>

import TextField from "@/ui/components/common/fields/TextField.vue";
export default {
  components: {TextField},
  props: {
    value: {
      type: String,
      default: 'auto',
    },
    label: {
      type: String,
      default: "Height",
    }
  },
  methods: {
    saveHeight(value) {
      this.$emit('input', value)
      this.$emit('saveHeight', value);
    }
  }
}
</script>