<template>
  <v-row justify="center">
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        persistent
    >
      <v-toolbar
          absolute
          dark
          color="var(--dark)"
      >
        <span class="text-h4 text--white">Builder Editor</span>

        <v-spacer/>

        <white-btn height="80%" @click="onClose">Close</white-btn>
      </v-toolbar>

      <v-card
          color="var(--smoke)"
      >
        <v-row class="builder-editor__section">
          <v-col cols="8 pl-8">
            <builder-editor-elements
                :builder="builder"
                :can-select-element="isElementsSelectable"
                @onSelectElement="onSelectElement"
            />
          </v-col>

          <v-col cols="4" class="px-8 pb-8">
            <builder-editor-toolbar
                :builder="builder"
                :builder-element="selectedElement"
                :insurance-types="insuranceTypes"
                @onChangeSection="changeSelectedSection"
            />
          </v-col>
        </v-row>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {Builder} from "@/models/builder.js";
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import BuilderEditorToolbar from "@/ui/components/builder/use-cases/builder-editor/BuilderEditorToolbar.vue";
import BuilderEditorElements from "@/ui/components/builder/use-cases/builder-editor/BuilderEditorBuilder.vue";
import {builderElementService} from "@/utils/services/builder-element-service.js";
import {builderEditorEvents} from "@/utils/events/builder-editor-events.js";

export default {
  components: {BuilderEditorElements, BuilderEditorToolbar, WhiteBtn},
  props: {
    builder: {
      type: Builder,
      required: true,
    },
    insuranceTypes: {
      type: Array
    }
  },
  computed: {
    isElementsSelectable() {
      return this.selectedSection === 'edit'
    },
  },
  watch: {
    'selectedElement.priority': function () {
      this.builder.elements = builderElementService.sortByPriority(this.builder.elements);

      if (this.selectedParent) {
        this.selectedParent.children = builderElementService.sortByPriority(this.selectedParent.children);
      }

      if (this.selectedElement) {
        this.scrollToElement(this.selectedElement);
      }
    },
  },
  data: () => ({
    show: true,
    selectedElement: null,
    selectedParent: null,
    selectedSection: null,
    movedElementId: null,
    oldMovedElementParentId: null,
  }),
  created() {
    builderEditorEvents.addElementToList.on(this.onAddElement);
    builderEditorEvents.removeElementFromList.on(this.onRemoveFromList);
    builderEditorEvents.changedParentElement.on(this.whenParentElementChanged);
    builderEditorEvents.unselectElement.on(this.onUnselectElement);

    this.changeEditMode(true);
  },
  destroyed() {
    builderEditorEvents.addElementToList.off(this.onAddElement);
    builderEditorEvents.removeElementFromList.off(this.onRemoveFromList);
    builderEditorEvents.changedParentElement.off(this.whenParentElementChanged);
    builderEditorEvents.unselectElement.off(this.onUnselectElement)

    this.changeEditMode(false)
  },
  methods: {
    whenParentElementChanged(element) {
      if (!this.movedElementId) {
        this.movedElementId = element.builderElement.id;
        this.oldMovedElementParentId = element.oldParentId;
      }

      this.onRemoveFromList(element.builderElement.id);
      this.onAddElement(element.builderElement);
    },
    onUnselectElement() {
      if (this.movedElementId) {
        const movedElement = this.getBuilderElement(this.movedElementId);
        movedElement.parent_id = this.oldMovedElementParentId

        this.onRemoveFromList(this.movedElementId);
        this.onAddElement(movedElement);
        this.movedElementId = null;
        this.oldMovedElementParentId = null;
      }
    },
    changeEditMode(editMode) {
      this.$store.dispatch('admin/builderElementModeModule/changeEditMode', {
        editMode
      })
    },
    onClose() {
      this.$emit('close');
    },

    scrollToElement(element) {
      const ref = document.querySelector(`[data-id="${element.id}"]`)

      if(ref) {
        ref.scrollIntoView()
      }
    },

    changeSelectedSection(section) {
      this.selectedElement = null;
      this.selectedSection = section;
    },

    getBuilderElement(builderElementId) {
      return builderElementService.findElementById(this.builder.elements, builderElementId)
    },

    async onSelectElement(id) {
      this.selectedElement = id
          ? await this.getBuilderElement(id)
          : null;

      if (this.selectedElement && this.selectedElement.parent_id) {
        this.selectedParent = this.selectedElement.parent_id
            ? await this.getBuilderElement(this.selectedElement.parent_id)
            : null;
      }
    },

    onAddElement(newElement) {
      const parent = builderElementService.findElementById(this.builder.elements, newElement.parent_id);

      //check if parent field exist, if yes we add newElement to his children, if no add newElement to main list
      if (parent) {
        parent.children = builderElementService.sortByPriority(
            builderElementService.addElement(parent.children, newElement)
        )
      } else {
        this.builder.elements = builderElementService.sortByPriority(
            builderElementService.addElement(this.builder.elements, newElement)
        );
      }
    },

    onRemoveFromList(id) {
      this.builder.elements = builderElementService.removeElementById(this.builder.elements, id);
    },
  }
}
</script>
<style scoped>

.builder-editor__section {
  padding-top: 100px;
  padding-left: 15px;
}
</style>