<template>
  <div>
    <div class="mt-2 pt-3 app-bg-aqua">
      <app-block class="py-2 mb-10 app-container">
        <progress-bar
            :name="progressBarNames.APPLICATION_COMPLETED"
            :selected-item="5"
            :disabled-items="disabledItems"
            :is-empty-price="!!isEmptyPrice"
        />
      </app-block>

      <div class="mt-2 pb-16 pt-2 text-center header">
        <div class="mb-3">
          <h3>Vermögensschaden-Haftpflichtversicherung</h3>
        </div>
        <div class="pb-4">
          <h1>Status Antragsabschluss</h1>
        </div>
      </div>
    </div>

    <app-preloader v-if="loading"/>
    <template v-else>
      <npopt-finish-contract v-if="isNpoptFinishContract" />
      <default-finish-contract v-else @updateInsurancePolice="init" />
    </template>
  </div>
</template>

<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue"
import ProgressBar from "@/ui/components/insurance/ProgressBar.vue"
import {DATA_FIELD_KEYS} from "@/utils/fields/insurance-police-field-keys.js"
import {
  CONFIRMATION_CLICK,
  ELECTRONIC_SIGNATURE,
  ESTATE_AGENT,
} from "@/utils/fields/insurance-police-step-fields.js"
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue"
import DefaultFinishContract from "@/ui/components/insurance/finish-contract-step/DefaultFinishContract.vue"
import NpoptFinishContract from "@/ui/components/insurance/finish-contract-step/NpoptFinishContract.vue"
import {progressBarNames} from "@/utils/data/progress-bar-names"

export default {
  components: {
    AppBlock,
    ProgressBar,
    AppPreloader,
    DefaultFinishContract,
    NpoptFinishContract,
  },
  computed: {
    expiringToken() {
      return this.$store.getters["insuranceModule/expiringTokenModule/getExpiringToken"]
    },
    insurancePolice() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getInsurancePolice"]
    },
    insuranceId() {
      return this.$store.getters["insuranceModule/getInsuranceId"]
    },
    paymentOption() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.paymentOption]
    },
    isConfirmationClickActive() {
      return this.paymentOption === CONFIRMATION_CLICK
    },
    isElectronicSignatureActive() {
      return this.paymentOption === ELECTRONIC_SIGNATURE
    },
    confirmationClickIconColor() {
      return this.isConfirmationClickActive ? this.activeColor : this.disabledColor
    },
    electronicSignatureIconColor() {
      return this.isElectronicSignatureActive ? this.activeColor : this.disabledColor
    },
    estateAgentOptionIconColor() {
      return this.isEstateAgentOptionActive ? this.activeColor : this.disabledColor
    },
    dataFieldKeys() {
      return DATA_FIELD_KEYS
    },
    disabledColor() {
      return '#DDDDDD'
    },
    activeColor() {
      return 'var(--light-blue)'
    },
    customerEmail() {
      return this.insurancePolice.data[this.dataFieldKeys.customerEmail]
    },
    documents() {
      return this.$store.getters["insuranceModule/insuranceFinishedModule/getFiles"]
    },
    insuranceType() {
      return this.$store.getters["insuranceModule/getInsuranceType"]
    },
    isShowChangeOption() {
      return this.isConfirmationClickActive
    },
    isEstateAgentOptionActive() {
      return this.paymentOption === ESTATE_AGENT
    },
    isEstateAgent() {
      return this.$store.getters.authUser.isEstateAgent();
    },
    insurancePrices() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePrices"]
    },
    isEmptyPrice() {
      return this.insurancePrice1 && this.insurancePrice1.isEmpty()
    },
    isNpoptFinishContract() {
      return this.insurancePrice1.getIsNpopt() || this.insurancePrice2?.getIsNpopt() || this.insurancePrice3?.getIsNpopt() || this.isEmptyPrice
    },
    progressBarNames() {
      return progressBarNames
    },
    insurancePrice1() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePriceById"](this.insurancePrices[0]?.id);
    },
    insurancePrice2() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePriceById"](this.insurancePrices[1]?.id);
    },
    insurancePrice3() {
      if (this.insurancePrices.length > 2) {
        return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePriceById"](this.insurancePrices[2].id);
      }

      return null
    },
    disabledItems() {
      if (this.isEmptyPrice) {
        return [3, 4]
      }

      if (this.insurancePrice1 && this.insurancePrice1.getIsNpopt()) {
        return [4]
      }

      return []
    },
  },
  data: () => ({
    loading: true,
  }),
  async created() {
    await this.init()
  },
  methods: {
    async init() {
      await this.getInsurancePolice(this.insuranceId)

      await this.getDocuments(this.insuranceType.id)

      await this.getExpiringToken(this.insurancePolice.id)

      await this.getInsurancePrices(this.insuranceId)

      this.loading = false
    },
    async getInsurancePolice(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePoliceModule/getInsurancePoliceByInsuranceId', {
        insuranceId,
      })
    },
    async getExpiringToken(policeId) {
      return await this.$store.dispatch('insuranceModule/expiringTokenModule/getExpiringTokenByPoliceId', {
        policeId,
      })
    },
    async onChangeOption() {
      this.$store.commit('insuranceModule/showPreloader');

      await this.statusPolice();

      this.$store.commit('insuranceModule/hidePreloader')
    },
    async statusPolice() {
      await this.$store.dispatch('insuranceModule/statusPolice')
    },
    async updateInsurancePolice() {
      await this.init()
    },
    async getDocuments(insuranceTypeId) {
      await this.$store.dispatch('insuranceModule/insuranceFinishedModule/getFiles', {
        insuranceTypeId,
        insuranceId: this.insuranceId
      })
    },
    async getInsurancePrice(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/getInsurancePriceByInsuranceId', {
        insuranceId,
      })
    },
    async getInsurancePrices(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/getInsurancePrices', {
        insuranceId,
      })
    },
  },
}
</script>

<style scoped>
.app-bg-aqua {
  padding-bottom: 80px;
}

.steps {
  margin-top: -100px;
}
</style>