<template>
  <div class="app-bg-white">
    <div class="mr-5 pb-5 px-5">
      <h5 class="finish-step-heading mt-10 mb-5 pl-2 pt-5">Status:</h5>
      <v-row>
        <v-col class="mt-2">
          <v-row>
            <v-col class="icon-block">
              <square-check-icon :color="activeColor"/>
            </v-col>
            <v-col cols="7">
              Die E-Mail zum Abschluss des Antrags wurde inklusive Kundeninformationspaket und Antragspaket an die
              E-Mailadresse {{ email }} gesendet
            </v-col>
          </v-row>
        </v-col>
        <v-col class="mt-2 text-right" cols="1">
          {{ expiringToken.created_at | toDateTimeFormatFromISO}} Uhr
        </v-col>
      </v-row>
      <hr class="finish-step-hr my-5 ml-2">
      <v-row>
        <v-col class="mt-2">
          <v-row>
            <v-col class="icon-block">
              <square-check-icon v-if="isValidToken" :color="activeColor" />
              <close-box-icon v-else />
            </v-col>
            <v-col cols="7" class="mt-2">
              <span v-if="isValidToken">Der Link zum Antragsabschluss ist gültig bis:</span>
              <span v-else>Der Link zum Antragsabschluss ist abgelaufen am:</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="mt-2 text-right" cols="1">
          {{ expiringToken.expires_at | toDateTimeFormatFromSQL }} Uhr
        </v-col>
      </v-row>
      <hr class="finish-step-hr my-5 ml-2">
      <v-row>
        <v-col class="mt-2">
          <v-row>
            <v-col class="icon-block">
              <square-check-icon v-if="hasExpiringTokenActivatedAt" :color="activeColor"/>
              <square-check-icon v-else color="#DDDDDD" :checked="false"/>
            </v-col>
            <v-col cols="7" class="mt-2">
              Der Kunde hat den Antrag erfolgreich online abgeschlossen
            </v-col>
          </v-row>
        </v-col>
        <v-col class="mt-2 text-right" cols="1" v-if="hasExpiringTokenActivatedAt">
          <span >{{ expiringToken.activated_at | toDateTimeFormatFromSQL}} Uhr</span>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col ></v-col>
        <v-col cols="3">
          <white-btn v-if="isShowRepeatSendEmail" @click="onClickRepeatSendEmail">
            Kunden-E-Mail erneut senden
          </white-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import SquareCheckIcon from "@/ui/components/common/icons/SquareCheckIcon.vue"
import CloseBoxIcon from "@/ui/components/common/icons/CloseBoxIcon.vue"
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue"
import {notificationService} from "@/utils/services/notification-service.js"

export default {
  components: {SquareCheckIcon, WhiteBtn, CloseBoxIcon},
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasExpiringTokenActivatedAt() {
      return Boolean(this.expiringToken.activated_at)
    },
    isValidToken() {
      return this.expiringToken.isValid()
    },
    isShowRepeatSendEmail() {
      return !this.isValidToken
    },
    insurancePolice() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getInsurancePolice"]
    },
    activeColor() {
      return 'var(--light-blue)'
    },
    expiringToken() {
      return this.$store.getters["insuranceModule/expiringTokenModule/getExpiringToken"]
    },
  },
  async created() {
    if (this.hasExpiringTokenActivatedAt) {
      await this.sendXml();
    }
  },
  methods: {
    async onClickRepeatSendEmail() {
      try {
        await this.sendProposalEmail(this.insurancePolice.id, this.email)

        this.$emit('updateInsurancePolice')

        notificationService.success('Email sent successfully')
      } catch (err) {
        notificationService.error('Error with send email')
      }
    },
    async sendProposalEmail(insurancePoliceId, email) {
      await this.$store.dispatch('insuranceModule/insurancePoliceModule/sendProposalEmail', {
            insurancePoliceId, email
          }
      );
    },
    sendXml() {
      this.$store.dispatch('insuranceModule/sendXml')
    },
  },
}
</script>

<style scoped>
.icon-block {
  max-width: 4%;
}
</style>
