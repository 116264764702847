<template>
  <div>
      <p class="app-text__bold">Name</p>

      <text-field
          name="name"
          :value="value"
          @saveField="onSaveField"
      />
    </div>
</template>
<script>
import TextField from "@/ui/components/common/fields/TextField.vue";
export default {
  components: {TextField},
  props: {
    value: {
      required: true,
    },
  },
  methods: {
    onSaveField(value) {
      this.$emit('input', value);
    }
  }
}
</script>