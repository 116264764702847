<template>
  <div>
    {{ isArray ? '' : '"' +builderElement.name + '":' }} "{{builderElement.value}}" {{comma ? ',' : ''}}
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";

export default {
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    },
    comma: {
      type: Boolean,
      default: false,
    },
    isArray: {
      type: Boolean,
      default: false,
    }
  }
}
</script>