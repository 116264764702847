<template>
  <div>
    <v-icon
        class="cursor-pointer"
        :color="color"
        :style="{ fontSize: iconFontSize }"
        @click.stop="togglePopup"
    >
      {{ icon }}
    </v-icon>
    <simple-popup
        v-if="popup && !isHidePopup"
        header=""
        @close="togglePopup"
    >
      <slot/>
    </simple-popup>
  </div>
</template>
<script>
import SimplePopup from "@/ui/components/common/popup/SimplePopup.vue";

export default {
  components: {SimplePopup},
  props: {
    icon: {
      type: String
    },
    iconFontSize: {
      type: String,
      default: '18px',
    },
    color: {
      type: String,
      default: 'var(--light-gray)',
    },
    isHidePopup: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    popup: false,
  }),
  methods: {
    togglePopup() {
      this.popup = !this.popup;
    }
  }
}
</script>
<style scoped>
.close-icon__animation:hover {
  border-radius: 50%;
  background: var(--hover-bg);
}
</style>