export const setValidationIsEqualToElementAction = ({passiveElement, activeElement, action_value, context}) => {
    passiveElement.validation = {
        ...passiveElement.validation,
        equalTo: {
            elementId: activeElement.id,
            message: action_value
        }
    }

    context.dispatch('insuranceModule/builderElementsModule/saveElement', {
        builderElement: passiveElement
    }, {root: true})
}