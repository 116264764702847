import {serverApi} from "@/api/server/server-api.js";
import {Builder} from "@/models/builder.js";
import {builderEditorModule} from "@/store/admin-modules/builder-editor-module.js";
import {builderCreatorModule} from "@/store/admin-modules/builder-creator-module.js";
import {insuranceTypeModule} from "@/store/admin-modules/insurance-type-module.js";
import {insuranceElementRelationsModule} from "@/store/admin-modules/insurance-element-relations-module.js";
import {builderRelationsModule} from "@/store/admin-modules/builder-relations-module.js";
import {insuranceApiModule} from "@/store/admin-modules/insurance-api-module.js";
import {jsonBuilderEditorModule} from "@/store/admin-modules/json-builder-editor-module.js";
import {insurancePriceAddonRelationsModule} from "@/store/admin-modules/insurance-price-addon-relations-module.js";
import {builderElementModeModule} from "@/store/admin-modules/builder-element-mode-module";
import {statisticModule} from "@/store/admin-modules/statistic-module";

export default {
    namespaced: true,
    modules: {
        builderEditorModule,
        builderCreatorModule,
        builderRelationsModule,
        insuranceTypeModule,
        insuranceElementRelationsModule,
        insuranceApiModule,
        jsonBuilderEditorModule,
        insurancePriceAddonRelationsModule,
        builderElementModeModule,
        statisticModule,
    },
    actions: {
        getBuilder: async (context, {builderId}) => {
            const {data} = await serverApi.admin.builder.getBuilder(builderId)

            return new Builder(data.builder);
        },

        parseScheme: async (context, {scheme, type}) => {
            const {data} = await serverApi.admin.builder.parseScheme(scheme,type);

            return new Builder(data.builder);
        },

        executeSql: async (context, {sql}) => {
            const {data} = await serverApi.admin.executeSql(sql);

            if(!data.success) {
                alert('Error: ' + data.message);
            }

            return data.success;
        }
    }
}