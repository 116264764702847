import {insuranceStepEnum} from "@/enums/insurance-step-enum.js";
import {InsuranceBuilder} from "@/models/insurance-builder.js";
import {insuranceBuilderStatusEnum} from "@/enums/insurance-builder-status-enum.js";

export const insuranceStepService = {

    typesToArray() {
        return Object.values(insuranceStepEnum);
    },

    hasEmptyPriceStep(insuranceBuilders) {
        return insuranceBuilders.some((insuranceBuilder) => insuranceBuilder.isEmptyPrice());
    },

    getEmptyPriceBuilder(insuranceBuilders) {
        return insuranceBuilders.find((insuranceBuilder) => insuranceBuilder.isEmptyPrice());
    },

    getSelectedPriceBuilder(insuranceBuilders) {
        return insuranceBuilders.find((insuranceBuilder) => insuranceBuilder.isSelectedPrice());
    },

    checkOnEmptyStep(insuranceBuilders) {
        const activeBuilderExist = insuranceBuilders.some((item) => item.isActiveBuilder())

        //if don't have any active statuses mark first builder as active
        if(!activeBuilderExist && insuranceBuilders[0] instanceof InsuranceBuilder) {
            insuranceBuilders[0].status = insuranceBuilderStatusEnum.ACTIVE;
        }

        return insuranceBuilders;
    },

    getActiveInsuranceBuilder(insuranceBuilders) {
        const newInsuranceBuilders = this.checkOnEmptyStep(insuranceBuilders);

        return newInsuranceBuilders.find((insuranceBuilder) => insuranceBuilder.isActiveBuilder());
    },

    getFirstInsuranceBuilder(insuranceBuilders) {
        //insuranceBuilders already sorted by priority so we can return first element;
        return insuranceBuilders[0];
    },
    
    findMaxPriority(stepType, insuranceBuilders) {
        let elem = InsuranceBuilder.empty({
            priority: 0
        });

        insuranceBuilders.forEach((insuranceBuilder) => {
            elem = elem.priority < insuranceBuilder.priority
                ? insuranceBuilder
                : elem;
        })
        
        return elem;
    },

    hasNextBuilder(insuranceBuilder, insuranceBuilders) {
        return insuranceBuilders.some((item) => item.priority > insuranceBuilder.priority);
    },

    findNextBuilder(insuranceBuilder, insuranceBuilders) {
        return insuranceBuilders.find((item) => item.priority > insuranceBuilder.priority);
    },

    hasPrevBuilder(insuranceBuilder, insuranceBuilders) {
        return insuranceBuilders.some((item) => item.priority < insuranceBuilder.priority);
    },

    findPrevBuilder(insuranceBuilder, insuranceBuilders) {
        return insuranceBuilders
            .sort((first, second) => second.priority - first.priority)
            .find((item) => item.priority < insuranceBuilder.priority);
    },
}