<template>
  <app-block
      class="white--text py-8"
      color="var(--light-blue)"
  >

    <div class="d-flex justify-space-between">
      <h3 class="white--text font-s-24 font-weight-black">{{ capitalizeFirstLetter(period) }}er Beitrag netto</h3>
      <h3 class="white--text font-s-24 font-weight-black">{{ totalPrice | toMoneyFormat }}</h3>
    </div>

    <div class="d-flex justify-space-between pt-4" v-if="!isOtherSpecificTaxes">
      <h3 class="white--text font-s-20 font-weight-black">Zuzüglich 19 % Versicherungssteuer</h3>
      <h3 class="white--text font-s-20 font-weight-black">{{ taxPrice | toMoneyFormat }}</h3>
    </div>

    <div class="d-flex justify-space-between pt-4" v-if="!isOtherSpecificTaxes">
      <h3 class="white--text font-s-24 font-weight-black">{{ capitalizeFirstLetter(period) }}er Beitrag brutto</h3>
      <h3 class="white--text font-s-24 font-weight-black">{{ finalPrice | toMoneyFormat }}</h3>
    </div>

    <div class="d-flex justify-space-between pt-4" v-if="isOtherSpecificTaxes">
      <h3 class="white--text font-s-24 font-weight-black">Zuzüglich länderspezifischer sonstiger Abgaben und lokaler Versicherungssteuer.</h3>
    </div>

    <div class="d-flex justify-end mt-8">
      <white-btn class="mr-2"
        @click="onClickOfferAsPdf"
      >
        Vorschlag anfordern
      </white-btn>

      <orange-btn
          @click="onClickAcceptSuggestion"
      >
        Vorschlag annehmen
      </orange-btn>
    </div>
  </app-block>
</template>
<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import {capitalizeFirstLetter} from "@/utils/helpers";
import {serverApi} from "@/api/server/server-api";
import {generateDownloadLink} from "@/utils/services/file-service";

export default {
  components: {OrangeBtn, WhiteBtn, AppBlock},
  props: {
    totalPrice: {
      type: Number,
      required: true,
    },
    taxPrice: {
      type: Number,
      required: true,
    },
    finalPrice: {
      type: Number,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
    isOtherSpecificTaxes: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onClickAcceptSuggestion() {
      this.$emit('acceptSuggestion')
    },
    capitalizeFirstLetter(str) {
      return capitalizeFirstLetter(str);
    },
    async onClickOfferAsPdf() {
      await this.runGeneratePdf('offer')
      await this.sendXml()
    },
    async runGeneratePdf(pdf) {
      const nameData = await serverApi.insurance.getPdfName(this.$route.params.insuranceId, 'Vorschlag');
      const {data} = await serverApi.insurance.runGeneratePdf(this.$route.params.insuranceId, pdf);

      generateDownloadLink(data, nameData?.data?.name || 'Vorschlag.pdf');
    },
    sendXml() {
      this.$store.dispatch('insuranceModule/sendXml')
    },
  },
}
</script>