import {builderElementsEvents} from "@/utils/events/builder-elements-events";

export const multipleDuplicateAction = async ({passiveElement, activeElement, action_value, context}) => {
    if (activeElement.value && !isNaN(activeElement.value)) {
        await context.dispatch('insuranceModule/builderElementsModule/multipleDuplicate', {
            builderElement: passiveElement,
            amount: activeElement.value,
            titleBuilderElementName: action_value
        }, {root: true})

        builderElementsEvents.reloadBuilders.emit();
    }
}
