<template>
  <v-btn
      @click="onClick"
      class="app-btn"
      :height="height"
      :width="width"
      :class="{'selected': selected}"
      :disabled="disabled"
      outlined
      rounded
  >
    <slot/>
    <span class="radioboxes">
        <v-icon class="blank" v-if="!selected">mdi-radiobox-blank</v-icon>
        <v-icon class="selected" v-if="selected">mdi-radiobox-marked</v-icon>
    </span>
    <div v-if="tooltipText" class="tooltip-wrap">
      <exclamation-tooltip :font-size="'20px'">
        <div v-html="tooltipText"></div>
      </exclamation-tooltip>
    </div>
  </v-btn>
</template>
<script>
import ExclamationTooltip from "@/ui/components/common/tooltips/ExclamationTooltip.vue";

export default {
  components: {ExclamationTooltip},
  props: {
    height: {
      type: String,
      default: '35px',
    },
    width: {
      type: String,
      default: 'auto'
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      default: null,
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
}
</script>
<style scoped>
.app-btn {
  background: var(--white);
  color: var(--dark);
  border: var(--default-border);
  box-shadow: none;
  width: auto;
}

.app-btn:hover {
  background: var(--white-hover);
}

.app-btn.selected {
  box-shadow: none;
}

.radioboxes {
  display: none;
}
.tooltip-wrap {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 8px;
  width: 20px;
  height: 20px;
}

</style>