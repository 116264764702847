import {builderElementsEvents} from "@/utils/events/builder-elements-events";

export const linkFieldsAction = ({activeElement, passiveElement, changedBuilderElement, context}) => {
    let isElementValueChanged = false;
    
    if (changedBuilderElement) {
        passiveElement.value = changedBuilderElement.value;
        activeElement.value = changedBuilderElement.value;
        isElementValueChanged = true;

    } else if (activeElement.value && !passiveElement.value) {
        passiveElement.value = activeElement.value;
        isElementValueChanged = true;

    } else if (passiveElement.value && !activeElement.value) {
        activeElement.value = passiveElement.value;
        isElementValueChanged = true;
    }
    
    if (isElementValueChanged) {
        context.dispatch('insuranceModule/builderElementsModule/saveElement', {
            builderElement: passiveElement
        }, {root: true});

        context.dispatch('insuranceModule/builderElementsModule/saveElement', {
            builderElement: activeElement
        }, {root: true});

        builderElementsEvents.linkFields.emit();    
    }
}