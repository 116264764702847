import {serverApi} from "@/api/server/server-api.js";
import {cookieService} from "@/utils/services/cookie-service.js";
import {AuthUser} from "@/models/auth-user.js";
import {localStorageService} from "@/utils/services/local-storage-service.js";

export const authModule = {
    state: {
        authUser: AuthUser.empty(),
        isAuthorized: false,
    },

    getters: {
        authUserFullName: (state) => state.authUser.getFullName(),

        authUserFirstName: (state) => state.authUser.getFirstName(),

        authUserRoleLabel: (state) => state.authUser.getRoleLabel(),

        authUserIsAdmin: (state) => state.authUser.isAdmin(),

        authUserIsStatistic: (state) => state.authUser.isStatisticUser(),

        isUserAuthorized: (state) => state.isAuthorized,

        authUser: (state) => state.authUser,

        authUserApiRole: (state) => state.authUser.getApiRole(),

        authUserId: (state) => state.authUser.getId(),

        isTestProcess: (state) => state.authUser.isTestProcess(),
    },

    mutations: {
        setAuthUser: (state, data) => state.authUser = new AuthUser(data),

        unsetAuthUser: (state) => state.authUser = AuthUser.empty(),

        authorized: (state) => state.isAuthorized = true,

        notAuthorized: (state) => state.isAuthorized = false,
    },

    actions: {
        async loginUser({dispatch}, {email, password}) {
            try {
                const {data} = await serverApi.auth.login(email, password);

                if (data.success) {
                    cookieService.setAuthToken(data.token);
                }

                return await dispatch('checkUser');
            } catch (error) {
                return false;
            }
        },

        async checkUser({commit}) {
            try {
                const {data} = await serverApi.auth.check(cookieService.getAuthToken());

                if (data.success) {
                    commit('setAuthUser', data.authUser);
                    commit('authorized');

                    return true;
                }

                throw new Error('User unauthorized')
            } catch (error) {
                commit('unsetAuthUser');
                commit('notAuthorized');

                return false;
            }
        },

        async logout({dispatch}) {
            try {
                await serverApi.auth.logout();

                cookieService.removeAuthToken();

                localStorageService.amsAuth.setIsUserAms(false)

                return await dispatch('checkUser');
            }
            catch (error) {
                return false;
            }
        },

        async setAuthToken({dispatch}, {token}) {
            try {
                cookieService.setAuthToken(token);

                localStorageService.amsAuth.setIsUserAms(true)

                return await dispatch('checkUser');
            } catch (error) {
                return false;
            }
        },

        async update({commit}, requestData) {
            const {data} = await serverApi.auth.update(requestData);

            if (data.success) {
                commit('setAuthUser', data.authUser);
            }
        },
    }
}