import {jsonElementEnum} from "@/enums/json-element-enum.js";
import {builderElementService} from "@/utils/services/builder-element-service.js";

export const jsonElementService = {

    findElementById(elements, id) {
        return builderElementService.findElementById(elements, id);
    },

    getParentList(elements, element) {
        if(element.parent_id) {
           const parent = builderElementService.findElementById(elements, element.parent_id);

           if(parent) {
               return parent.children;
           }
        }

        return elements;
    },

    sortByPriority(elements) {
        return builderElementService.sortByPriority(elements)
    },

    toSingleArray(elements) {
        let array = [];

        elements.forEach((elem) => {
            array.push(elem);

            if(elem.children.length) {
                array = [...array, ...this.toSingleArray(elem.children)];
            }
        })

        return array;
    },

    removeElementById(elements, id) {
        return builderElementService.removeElementById(elements, id);
    },

    addElement(elements, element) {
        return builderElementService.addElement(elements, element)
    },

    onlyParents(elements) {
        return this.toSingleArray(elements)
            .filter((elem) => elem.type === jsonElementEnum.ARRAY || elem.type === jsonElementEnum.OBJECT)
    }
}