<template>
  <div
      v-if="showLabelBlock"
      class="label-block"
      v-html="labelHtml"
      :class="cssClasses"
      :style="{padding, margin, width, height}"
      :id="id"
  >
  </div>
</template>
<script>
import Padding from "./mixins/Padding.js";
import DefaultElement from "./mixins/DefaultElement.js";
import Size from "@/ui/components/builder/elements/mixins/Size.js";
import ToRight from "@/ui/components/builder/elements/mixins/ToRight.js";
import Id from "./mixins/Id.js";
import {textService} from "@/utils/services/text-service.js";
import CssClass from "./mixins/CssClass.js";
import {isEnvProduction} from "@/plugins/env";

export default {
  mixins: [DefaultElement, Padding, Size, ToRight, Id, CssClass],
  computed: {
    labelHtml() {
      let html = this.builderElement.data?.html || '';

      const htmlContent = textService.replaceHtmlTags(html).trim();

      if (htmlContent !== this.value) {
        html = html.replace(htmlContent, this.value)
      }

      return html.replaceAll('`', "'")
    },
    cssClasses() {
      const toRight = this.toRight ? 'text-right' : '';
      const cssClass = this.cssClass || '';

      return cssClass + toRight;
    },
    showLabelBlock() {
      const isBlockIdLabel = this.builderElement.data?.isBlockIdLabel || false;

      return !isBlockIdLabel || this.authUserIsAdmin || !isEnvProduction();
    },
    authUserIsAdmin() {
      return this.$store.getters.authUserIsAdmin
    },
  }
}
</script>
<style lang="scss">
  .label-block h3 {
    line-height: 1.2em;
  }
  .label-block p {
    line-height: 1.2em;
  }
  .white-font {
    color: white;
  }
</style>