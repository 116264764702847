<template>
  <toggle-block
      header="Margin"
      opened
  >
    <v-row>
      <v-col cols="6" class="d-flex px-1">
        <div class="app-text__bold ml-3 mr-2 pt-1">
          Top:
        </div>

        <number-field
            name="marginTop"
            placeholder="Margin Top"
            v-model="topMargin"
        />
      </v-col>

      <v-col cols="6" class="d-flex px-1">
        <div class="app-text__bold mr-2 pt-1">
          Bottom:
        </div>

        <number-field
            name="marginBottom"
            placeholder="Margin Bottom"
            v-model="bottomMargin"
        />
      </v-col>

      <v-col cols="6" class="d-flex px-1">
        <div class="app-text__bold mr-2 pt-1">
          Right:
        </div>

        <number-field
            name="marginRight"
            placeholder="Margin Right"
            v-model="rightMargin"
        />
      </v-col>

      <v-col cols="6" class="d-flex px-1">
        <div class="app-text__bold mr-2 pt-1 ml-7">
          Left:
        </div>

        <number-field
            name="marginLeft"
            placeholder="Margin Left"
            v-model="leftMargin"
        />
      </v-col>
    </v-row>
  </toggle-block>
</template>
<script>
import ToggleBlock from "@/ui/components/common/blocks/ToggleBlock.vue";
import NumberField from "@/ui/components/common/fields/NumberField.vue";
import {textService} from "@/utils/services/text-service.js";

export default {
  components: {NumberField, ToggleBlock},
  props: {
    value: {
      type: String,
      default: '0 0 0 0',
      validation: (value) => {
        const arr = this.splitMargin(value);

        if(arr.length === 4) {
            return 'success';
        }

        return 'danger';
      }
    }
  },
  watch: {
      leftMargin() {
        this.buildMargin();
      },
      rightMargin() {
        this.buildMargin();
      },
      topMargin() {
        this.buildMargin();
      },
      bottomMargin() {
        this.buildMargin();
      }
  },
  data: () => ({
      topMargin: '0',
      rightMargin: '0',
      leftMargin: '0',
      bottomMargin: '0',
  }),
  created() {
      const arr = this.splitMargin(this.value);

      this.fillMargin(arr[0], arr[1], arr[2], arr[3]);
  },
  methods: {
    buildMargin() {
      const margin = this.topMargin + 'px ' + this.rightMargin + 'px ' + this.bottomMargin + 'px ' + this.leftMargin + "px";

      this.$emit('input', margin)
      this.$emit('setMargin', margin);
    },
    splitMargin(value) {
      return value.split(' ');
    },
    fillMargin(top, right, bottom, left) {
      this.topMargin = textService.numberFromText(top);
      this.rightMargin = textService.numberFromText(right);
      this.bottomMargin = textService.numberFromText(bottom);
      this.leftMargin = textService.numberFromText(left);
    }
  }
}
</script>