<template>
  <v-icon
      @click="$emit('click')"
      :size="size"
      color="cyan lighten-1"
      v-ripple="false"
  >
    mdi-database-search
  </v-icon>
</template>
<script>
export default {
  props: {
    size: {
      type: [String, Number],
      default: '20',
    },
  },
}
</script>