<template>
  <div>
    <p class="app-text__bold">Priority</p>
    <number-field
      name="priority"
      :value="value"
      @saveField="savePriority"
    />
  </div>
</template>
<script>
import NumberField from "@/ui/components/common/fields/NumberField.vue";
export default {
  components: {NumberField},
  props: {
      value: {
        type: Number,
        required: true,
      }
  },
  methods: {
    savePriority(value) {
      this.$emit('input', parseInt(value) || 0)
      this.$emit('savePriority', parseInt(value) || 0);
    }
  }
}
</script>