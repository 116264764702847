<template>
  <div :style="{width: '100%'}">
    <v-row>
      <v-col cols="3">
        <p class="app-text__bold mt-2">Action</p>
        <searchable-select-field
            name="condition_type"
            :items="actions"
            :validation="{required: true}"
            v-model="insuranceElementAction.action"
            @saveField="onChangeAction"
        />
      </v-col>

      <v-col v-if="isBuilderElementAction" cols="3" class="pl-2">
        <p class="app-text__bold builder-element mt-2">Builder Element</p>

        <select-builder-element-popup-field
            :validation="{required: true}"
            v-model="insuranceElementAction.builder_element_id"
            :builder-elements="builderElements"
        />
      </v-col>
      <v-col v-if="isInsuranceElementAction" cols="3" class="pl-2">
        <p class="app-text__bold builder-element mt-2">Insurance Element</p>

        <searchable-select-field
            name="insurance_element_id"
            placeholder="Select Element"
            item-value="id"
            item-text="name"
            :validation="{required: true}"
            v-model.number="insuranceElementAction.insurance_element_id"
            :items="insuranceElements"
        />
      </v-col>
      <v-col v-if="isStaticAction" cols="3" class="pl-2">
        <p class="app-text__bold mt-2">Value</p>
        <text-field
            name="value"
            v-model="insuranceElementAction.action_value"
        />
      </v-col>

      <v-col cols="3" class="pl-4">
        <checkbox
            class="mt-11"
            text="RUN ON TRUE"
            :value="insuranceElementAction.run_on+''"
            @saveField="onChangeRunOn"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SearchableSelectField from "@/ui/components/common/fields/SearchableSelectField.vue";
import {insuranceElementRelationService} from "@/utils/services/insurance-element-relation-service.js";
import {InsuranceElementAction} from "@/models/insurance-element-relations/insurance-element-action.js";
import TextField from "@/ui/components/common/fields/TextField.vue";
import SelectBuilderElementPopupField from "@/ui/components/builder/use-cases/builder-relations/SelectBuilderElementPopupField.vue";
import Checkbox from "@/ui/components/common/fields/Checkbox.vue";

export default {
  components: {Checkbox, SelectBuilderElementPopupField, TextField, SearchableSelectField},
  props: {
    actions: {
      type: Array,
      required: true,
    },
    insuranceElementAction: {
      type: InsuranceElementAction,
      required: true,
    },
    builderElements: {
      type: Array,
      required: true,
    },
    insuranceElements: {
      type: Array,
      required: true,
    }
  },
  data: () => ({
    isStaticAction: false,
    isBuilderElementAction: false,
    isInsuranceElementAction: false,
  }),
  created() {
    this.onChangeAction(this.insuranceElementAction.action);
  },
  methods: {
    onChangeAction(actionType) {
      this.isStaticAction = insuranceElementRelationService.staticActions().includes(actionType)
      this.isBuilderElementAction = insuranceElementRelationService.builderElementActions().includes(actionType)
      this.isInsuranceElementAction = insuranceElementRelationService.insuranceElementsActions().includes(actionType)
    },

    onChangeRunOn(value) {
      this.insuranceElementAction.run_on = value;
    }
  }
}
</script>