<template>
  <app-block class="pb-4 overflow-y-auto" height="728px" >
    <div>
      <p class="app-text__bold font-s-24">Relations</p>
      <div v-if="!loading">
        <builder-element-relations-table
            :selected-element="selectedElement"
            :builder-element-relations="builderElementRelations"
            @clickEdit="onClickEdit"
            @clickDelete="onClickDelete"
        />
      </div>

      <app-preloader class="mt-16" v-else />
    </div>
  </app-block>
</template>
<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import {builderRelationsEvents} from "@/utils/events/builder-relations-events.js";
import BuilderElementRelationsTable
  from "@/ui/components/builder/use-cases/builder-relations/tables/BuilderElementRelationsTable.vue";
import {BuilderElementRelation} from "@/models/builder-element-relations/builder-element-relation.js";
import {objectService} from "@/utils/services/object-service.js";
import {notificationService} from "@/utils/services/notification-service.js";
import {confirmService} from "@/utils/services/confirm-service.js";
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue";

export default {
  components: {
    AppPreloader,
    BuilderElementRelationsTable,
    AppBlock
  },
  props: {
    builderElements: {
      type: Array,
      required: true,
    },
    builders: {
      type: Array,
      required: true,
    }
  },
  computed: {
    buildersId() {
      return this.builders.map((builder) => builder.id);
    }
  },
  data: () => ({
    builderElementRelations: [],

    selectedElement: BuilderElementRelation.empty(),

    loading: false,
  }),
  created() {
    builderRelationsEvents.relationSaved.on(this.reloadRelationsList)

    this.reloadRelationsList();
  },
  destroyed() {
    builderRelationsEvents.relationSaved.off(this.reloadRelationsList);
  },
  methods: {
    onClickEdit(builderElementRelation) {
      this.selectedElement = objectService.copyClass(builderElementRelation);

      builderRelationsEvents.selectRelation.emit(this.selectedElement);
    },

    async onClickDelete(builderElementRelation) {
      if (!confirmService.simpleConfirm('Are you really want to delete relation?')) {
        return;
      }

      await this.deleteBuilderElementRelation(builderElementRelation);

      this.resetRelationsData();

      notificationService.success('Relation deleted');

      if (this.selectedElement.id === builderElementRelation.id) {
        this.selectedElement = BuilderElementRelation.empty();
      }
    },

    resetRelationsData() {
      this.reloadRelationsList();
    },

    reloadRelationsList() {
      this.loading = true;
      this.selectedElement = BuilderElementRelation.empty();

      setTimeout(async () => {
        this.builderElementRelations = await this.getBuilderElementRelations(this.buildersId)
        this.loading = false;
      }, 600)
    },

    getBuilderElementRelations(buildersId) {
      return this.$store.dispatch('admin/builderRelationsModule/getBuilderElementRelations', {
        buildersId
      })
    },

    deleteBuilderElementRelation(builderElementRelation) {
      return this.$store.dispatch('admin/builderRelationsModule/deleteBuilderElementRelation', {
        builderElementRelation
      })
    }
  },

}
</script>