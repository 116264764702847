export const defaultInsuranceValueTooltip = `
<p>
            <b>Hinweise zur Wahl der Versicherungssumme</b>
          </p>
          <p>
            Bei der Wahl der richtigen Versicherungssumme ist folgendes zu beachten:
          </p>
          <p>
            <b>I. Mindestversicherungssumme / Pflichtversicherungssumme</b>
          </p>
          <p>
            Rechtsanwälte, Steuerberater und Patentanwälte, die nicht in einer Berufsausübungsgesellschaft zusammengeschlossen sind, müssen eine gesetzlich vorgeschriebene Mindestversicherungssumme i.H.v. 250.000 EUR verpflichten vorhalten (= Pflichtversicherungssumme).
          </p>
          <p>
            Diese Mindestversicherungssumme stellt allerdings nur die Minimalanforderung an den Versicherungsschutz dar.
          </p>
          <p>
            <b>II. Wahl der richtigen Versicherungssumme</b>
          </p>
          <p>
            Die im Einzelfall richtige Versicherungssumme richtet sich nach den individuellen Schadenrisiken, mit denen der Kunden in seinem individuellen Arbeitsumfeld rechnen muss. Sie dürfte oft höher liegen als das gesetzlich vorgesehene Minimum. Seinen individuellen Bedarf kann nur der Kunde selbst einschätzen. Es empfiehlt sich, dies in einem Beratungsgespräch mit dem Kunden gemeinsam zu erörtern.
          </p>
          <p>
            <b>III. Wichtige Besonderheit: Verwendung vorformulierter Vertragsbedingungen über eine Haftungsbegrenzung</b>
          </p>
          <p>
            Oft verwenden Rechtsanwälte, Patentanwälte und Steuerberater vorformulierte Vertragsbedingungen (= AGB), mit denen sie ihre Haftung begrenzen können. In diesem Fall benötigen sie eine Versicherungssumme in Höhe eines Betrages der 4-fachen Mindestversicherungssumme.
          </p>
          <p>
            <i>
              Beispiel:<br>
              Die gesetzliche Mindestversicherungssumme eines Einzelanwalts beträgt 250.000 EUR. Bei der Verwendung vorformulierter Haftungsbegrenzungsvereinbarungen benötigt er eine Versicherung mit einer Versicherungssumme iHv. 1 Mio. EUR.
            </i>
          </p>
`;

export const corporateGovernanceInsuranceValueTooltip = 'Achtung! Die gewünschte Versicherungssumme darf nicht höher sein als die Versicherungssumme der Unternehmens-D&O.';