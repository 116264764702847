import {serverApi} from "@/api/server/server-api.js";
import {InsuranceBuilder} from "@/models/insurance-builder.js";

export const insuranceBuildersModule = {
    namespaced: true,

    state: {
        insuranceBuilders: [],
    },

    getters: {
        getInsuranceBuilders: (state) => {
            return state.insuranceBuilders;
        },

        getInsuranceStepBuilders: (state) => (stepType) => {
            return state.insuranceBuilders
                .filter((item) => item.step_type === stepType)
                .sort((first, second) => first.priority - second.priority);
        }
    },

    mutations: {
        setInsuranceBuilders(state, insuranceBuilders) {
            state.insuranceBuilders = insuranceBuilders.map((elem) => new InsuranceBuilder(elem))
        },

        setInsuranceBuilder(state, insuranceBuilder) {
            const insuranceBuilders = [... state.insuranceBuilders]
            const foundInsuranceBuilder = insuranceBuilders.findIndex(el => el.id === insuranceBuilder.id);

            insuranceBuilders[foundInsuranceBuilder] = new InsuranceBuilder(insuranceBuilder)

            state.insuranceBuilders = insuranceBuilders
        },
    },

    actions: {
        changeActiveBuilder: async (context, {nextActiveBuilder}) => {
            const {data} = await serverApi.insurance.changeActiveBuilder(nextActiveBuilder.id);

            await context.dispatch('resetInsuranceBuildersData', {
                insuranceBuilders: data.insuranceBuilders
            });
        },

        resetInsuranceBuilders: async (context, {insuranceBuilder}) => {
            const {data} = await serverApi.insurance.resetInsuranceBuilders(insuranceBuilder.id)

            await context.dispatch('resetInsuranceBuildersData', {
                insuranceBuilders: data.insuranceBuilders
            })
        },

        resetInsuranceBuilder: async (context, {insuranceBuilder}) => {
            const {data} = await serverApi.insurance.resetInsuranceBuilder(insuranceBuilder.id)

            await context.dispatch('resetInsuranceBuilderData', {
                insuranceBuilder: data.insuranceBuilder
            })
        },

        resetInsuranceBuildersData: async (context, {insuranceBuilders}) => {
            context.commit('setInsuranceBuilders', insuranceBuilders)

            let relations = [];
            let builderElements = [];

            context.getters.getInsuranceBuilders.forEach((insuranceBuilder) => {
                relations = [...relations, ...insuranceBuilder.builder.relations];
                builderElements = [...builderElements, ...insuranceBuilder.builder.elements];
            })

            context.commit('insuranceModule/builderElementsModule/setBuilderElements',
                builderElements,
                {root: true}
            );

            context.commit('insuranceModule/builderElementRelationsModule/setBuilderElementRelations',
                relations,
                {root: true}
            );
        },

        resetInsuranceBuilderData: async (context, {insuranceBuilder}) => {
            context.commit('setInsuranceBuilder', insuranceBuilder)

            let relations = [];
            let builderElements = [];

            context.getters.getInsuranceBuilders.forEach((insuranceBuilder) => {
                relations = [...relations, ...insuranceBuilder.builder.relations];
                builderElements = [...builderElements, ...insuranceBuilder.builder.elements];
            })

            context.commit('insuranceModule/builderElementsModule/setBuilderElements',
                builderElements,
                {root: true}
            );

            context.commit('insuranceModule/builderElementRelationsModule/setBuilderElementRelations',
                relations,
                {root: true}
            );
        },
    }
}