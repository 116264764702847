<template>
  <v-app-bar
      class="header-border"
      color="var(--white-bg)"
      height="74px"
      outlined
  >
    <div class="ml-4">
      <img width="170" src="/img/Allianz.svg" alt="Allianz Logo" title="Allianz logo">
    </div>


    <v-spacer/>

    <div v-if="isShowProdUpdatedAt" class="mr-5">Prod stand vom:{{ prodUpdatedAt }}</div>

    <v-icon
      v-ripple="true"
      size="34px"
      class="rounded-circle app-bg-smoke cursor-pointer"
      title="To App"
      @click="toApp"
    >
      mdi-arrow-right
    </v-icon>

  </v-app-bar>
</template>
<script>
import {routeNames} from "@/router/route-names";
import {isEnvProduction} from "@/plugins/env";

export default {
  computed: {
    prodUpdatedAt() {
      return this.$store.getters["insuranceModule/dashboardInfoModule/getProdUpdatedAtElement"].value ?? ''
    },
    isShowProdUpdatedAt() {
      return isEnvProduction()
    },
  },
  async created() {
    await this.getProdUpdatedAt()
  },
  methods: {
    toApp() {
      return this.$router.push({name: routeNames.insuranceCreate})
    },
    getProdUpdatedAt() {
      if (isEnvProduction()) {
        this.$store.dispatch('insuranceModule/dashboardInfoModule/getProdUpdatedAtElement')
      }
    },
  }
}
</script>
<style scoped>
.header-border {
  border-bottom: 1px solid var(--light-gray) !important;
  box-shadow: none !important;
}
</style>