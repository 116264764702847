export const builderEditorApi = (http) => ({
    getBuilderElement: (id) => http.get(`/api/admin/builder-editor/builder-element/${id}`),

    getBuilderElementSql: (builderElementId, newBuilderId) => http.get(`/api/admin/builder-editor/builder-element/${builderElementId}/${newBuilderId}/copy-sql`),

    createBuilderElement: (builderId, data) => http.post(`/api/admin/builder-editor/builder-element/create `, {
        ...data,
        builder_id : builderId,
    }),

    deleteBuilderElement: (builderElementId) => http.post(`/api/admin/builder-editor/builder-element/${builderElementId}/delete`),

    updateBuilderElement: (builderElementId, data) => http.post(`/api/admin/builder-editor/builder-element/${builderElementId}/update`, data),

    copyBuilderElement: (builderElementId, name, priority, data) => http.post(`/api/admin/builder-editor/builder-element/${builderElementId}/copy`, {
        name,
        priority,
        data,
    }),

    getBuilderElementsTemplates: () => http.get(`/api/admin/builder-editor/builder-element/templates`),

    copyBuilderElementTemplate: (builderId, builderElementId, additionalData) => http.post(`/api/admin/builder-editor/builder-element/${builderElementId}/copy-template/${builderId}`, {
        ...additionalData,
    }),

    uploadFile: (builderElementId, file) =>  http.formDataRequest(`/api/admin/builder-editor/${builderElementId}/upload-file`, {
        file
    }),
})