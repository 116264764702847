<template>
  <div>
    <div class="price-items__wrapper" v-for="(priceItem, index) in priceItems" :key="index">
      <insurance-price-item
          class="mx-8 pt-8 price-item"
          :price-item="priceItem"
      />
    </div>
  </div>
</template>
<script>
import InsurancePriceItem from "@/ui/components/insurance/insurance-blocks/insurance-price/InsurancePriceItem.vue";
export default {
  components: {InsurancePriceItem},
  props: {
    priceItems: {
      type: Array,
    }
  }
}
</script>
<style scoped>
.price-items__wrapper .price-item {
  padding-top: 20px;
  padding-bottom: 15px;
  border-top: var(--default-border);
}

.price-items__wrapper:first-of-type .price-item {
  border-top: 1px solid transparent;
}
</style>