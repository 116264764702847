<template>
  <v-row justify="center">
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        persistent
    >
      <v-toolbar
          absolute
          dark
          color="var(--dark)"
      >
        <span class="text-h4 text--white">Builder Creator</span>

        <v-spacer/>

        <blue-btn height="80%" class="mr-2" @click="onSave">Save</blue-btn>
        <orange-btn height="80%" class="mr-2" @click="onSaveAndGoToEditor">Save and go to Builder Editor</orange-btn>
        <white-btn height="80%" @click="onClose">Close</white-btn>
      </v-toolbar>

      <v-card
          color="var(--smoke)"
      >
        <v-row class="builder-creator__section">
          <v-col cols="8" class="pl-8">
            <builder-creator-elements
                v-if="selectedBuilder"
                :builder="selectedBuilder"
            />
          </v-col>

          <v-col cols="4" class="px-8 pb-8">
            <builder-creator-toolbar
                :builders="builderTemplates"
                @onChangeBuilder="onChangeBuilder"
            />
          </v-col>
        </v-row>

      </v-card>
    </v-dialog>
  </v-row>

</template>
<script>
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import BuilderCreatorElements from "@/ui/components/builder/use-cases/builder-creator/BuilderCreatorElements.vue";
import BuilderCreatorToolbar from "@/ui/components/builder/use-cases/builder-creator/BuilderCreatorToolbar.vue";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";

export default {
  components: {OrangeBtn, BuilderCreatorToolbar, BuilderCreatorElements, WhiteBtn, BlueBtn},
  data: () => ({
    show: true,
    builderTemplates: [],

    selectedBuilder: null,
    additionalData: {}
  }),
  async created() {
    this.builderTemplates = await this.selectBuilderTemplates();
  },
  methods: {
    onClose() {
      this.$emit('close');
    },

    async onSave() {
      if (!this.selectedBuilder) {
        throw new Error("Invalid 'selectedBuilder' value");
      }

      const builder = await this.createByTemplate(
          this.selectedBuilder.id,
          this.selectedBuilder.toObject()
      );

      this.$emit('onCreateBuilder', {
        builder,
        additionalData: this.additionalData
      });
    },

    async onSaveAndGoToEditor() {
      if (!this.selectedBuilder) {
        throw new Error("Invalid 'selectedBuilder' value");
      }

      const builder = await this.createByTemplate(
          this.selectedBuilder.id,
          this.selectedBuilder.toObject(),
      );

      this.$emit('onCreateBuilderAndGoToEditor', {
        builder,
        additionalData: this.additionalData
      })
    },

    onChangeBuilder({builder, additionalData}) {
      this.selectedBuilder = builder;
      this.additionalData = additionalData;
    },

    selectBuilderTemplates() {
      return this.$store.dispatch('admin/builderCreatorModule/builderTemplates')
    },

    createByTemplate(id, builderData) {
      return this.$store.dispatch('admin/builderCreatorModule/createBuilderByTemplate', {
        id,
        builderData,
      })
    }
  }
}
</script>

<style scoped>
.builder-creator__section {
  padding-top: 100px;
  padding-left: 15px;
}
</style>