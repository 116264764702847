<template>
  <v-radio-group
      class="app-radio__group"
      background-color="transparent"
      :value="value"
      :column="true"
      @change="saveField"
  >
    <div
        v-for="(btn, index) in buttons"
        :key="btn[itemTextKey]"
        class="d-flex"
        :class="{'pt-6': index}"
    >
      <div v-if="!right" class="radio-text">
        <span class="app-text">{{ btn[itemTextKey] || '' }}</span>

        <v-icon
            v-if="clearable"
            @click="deleteOption(btn)"
            class="cursor-pointer rounded-circle"
            v-ripple
        >
          mdi-close
        </v-icon>
      </div>

      <div>
        <v-radio
            color="var(--light-blue)"
            :disabled="btn.disabled || disabled || false"
            :name="btn[itemValueKey]"
            :value="btn[itemValueKey]"
            :ripple="false"
        />
      </div>

      <div v-if="right" class="radio-text">
        <span class="app-text">{{ btn.text || '' }}</span>

        <v-icon
            v-if="clearable"
            @click="deleteOption(btn)"
            class="cursor-pointer rounded-circle"
            v-ripple
        >
          mdi-close
        </v-icon>
      </div>


    </div>
  </v-radio-group>
</template>
<script>
export default {
  props: {
    buttons: {
      required: true,
      type: Array,
    },
    value: {
      default: null,
    },
    right: {
      type: Boolean,
      default: false,
    },
    itemTextKey: {
      type: String,
      default: 'text',
    },
    itemValueKey: {
      type: String,
      default: 'value',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'name',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    saveField(value) {
      this.$emit('saveField', value, this.name);
      this.$emit('input', value);
    },

    deleteOption(option) {
      const index = this.buttons.findIndex((elem) => elem[this.itemTextKey] === option[this.itemTextKey])

      if(index !== -1) {
        this.buttons.splice(index, 1);

        this.$emit('deleteOption', index);
      }
    }
  }
}
</script>
<style scoped>
.radio-text {
  width: 100%;
  padding-top: 3px;
}
</style>