<template>
  <div class="d-flex justify-space-between flex-wrap button-selector">
    <div
        v-for="staticInsuranceType in staticInsuranceTypes"
        :key="staticInsuranceType.key"
    >
      <white-btn
          class="ml-auto mr-auto mt-4"
          width="295px"
          height="72px"
          :disabled="getDisabled(staticInsuranceType.key)"
          @click="onSelectInsuranceType(staticInsuranceType)"
          :selected="insuranceType.key === staticInsuranceType.key"
      >
        <span class="text-element">{{ staticInsuranceType.name }}</span>
      </white-btn>
    </div>
  </div>
</template>
<script>
import {InsuranceType} from "@/models/insurance-type.js";
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import {isEnvProduction} from "@/plugins/env";
import {insuranceTypeEnum} from "@/enums/insurance-type-enum";

export default {
  components: {WhiteBtn},
  props: {
    staticInsuranceTypes: {
      type: Array,
      required: true,
    },
    insuranceType: {
      type: InsuranceType,
      required: true,
    }
  },
  mounted() {
    //select first insurance type by default
    this.setInsuranceType(this.staticInsuranceTypes[0]);
  },
  methods: {
    onSelectInsuranceType(insuranceType) {
      this.setInsuranceType(
          new InsuranceType({...insuranceType.toObject()})
      );
    },

    setInsuranceType(insuranceType) {
      this.$emit('setInsuranceType', insuranceType);
    },
    getDisabled(insuranceKey) {
      const disabledInsuranceTypes = [
        insuranceTypeEnum.OTHER_PROFESSIONS,
      ];

      return !!(isEnvProduction() && disabledInsuranceTypes.includes(insuranceKey));
    },
  }
}
</script>