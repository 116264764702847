<template>
  <div>
    <p>Selbstbehalt</p>
    <select-field
        :value="value"
        :items="items"
        :is-disabled="isDisabled"
        name="duration"
        max-width="278px"
        @saveField="saveField"
    />
    <span v-if="isShowTooltip">
      <span class="font-s-12">{{ tooltip }}</span>
    </span>
  </div>
</template>
<script>
import SelectField from "@/ui/components/common/fields/SelectField.vue";
export default {
  components: {SelectField},
  props: {
    items: {
      required: true,
      type: Array,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: [Boolean, String],
      default: '',
    },
    isShowTooltip: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    saveField(val) {
      this.$emit('changeDeductible', val)
    }
  },
}
</script>