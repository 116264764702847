<template>
  <div :style="{padding, margin, width}">
    <select-field
        :name="name"
        :value="value"
        :items="selectOptions"
        :placeholder="placeholder"
        :is-disabled="isDisabled || disabledBuilderElement"
        :validation="validation"
        clearable
        @saveField="saveField"
    />
  </div>
</template>
<script>
import DefaultElement from "./mixins/DefaultElement.js";
import Padding from "./mixins/Padding.js";
import Placeholder from "./mixins/Placeholder.js";
import SelectField from "../../common/fields/SelectField.vue";
import Size from "./mixins/Size.js";
import Options from "./mixins/Options.js";
import {builderElementsEvents} from "@/utils/events/builder-elements-events.js";
import IsDisabled from "./mixins/IsDisabled.js";
import Validation from "@/ui/components/builder/elements/mixins/Validation";
import {
  selectFieldStartYearTypes,
  selectFieldYearsDirections,
  setSelectFieldMethods
} from "@/enums/builder-element-enum";

export default {
  components: {SelectField},
  mixins:[DefaultElement, Padding, Placeholder, Size, Options, IsDisabled, Validation],
  props: {
    disabledBuilderElement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    selectOptions() {
      if (this.setMethod === setSelectFieldMethods.YEARS) {
        const options = [];
        let year = this.startYear;

        options.push(year);

        for(let i = 0; i < this.yearsAmount; i++) {
          if (this.yearsDirection === selectFieldYearsDirections.TO_THE_PAST) {
            options.push(--year);
          } else {
            options.push(++year);
          }
        }

        return options;
      }

      return this.options;
    },
    setMethod() {
      return this.builderElement.data?.setMethod || setSelectFieldMethods.MANUALLY;
    },
    startYearType() {
      return this.builderElement.data?.startYearType || selectFieldStartYearTypes.CURRENT;
    },
    startYear() {
      return this.builderElement.data?.startYear || (new Date()).getFullYear();
    },
    yearsDirection() {
      return this.builderElement.data?.yearsDirection || selectFieldYearsDirections.TO_THE_PAST;
    },
    yearsAmount() {
      return this.builderElement.data?.yearsAmount || 1
    }
  },
  methods: {
    saveField(value) {
      this.builderElement.value = value;

      builderElementsEvents.updateValue.emit(this.builderElement)
    }
  }
}
</script>