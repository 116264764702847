import Vue from "vue";
import {UpdateElementValueEvent} from "@/utils/events/events/update-element-value-event.js";
import {ElementLoadFileEvent} from "@/utils/events/events/element-load-file-event.js";
import {DuplicateAddEvent} from "@/utils/events/events/duplicate-add-event.js";
import {DuplicateRemoveEvent} from "@/utils/events/events/duplicate-remove-event.js";
import {EditorLoadFileEvent} from "@/utils/events/events/editor-load-file-event";
import {RepeatByElementEvent} from "@/utils/events/events/repeat-by-element-event";
import {SetRepeatedValueEvent} from "@/utils/events/events/set-repeated-value-event";
import {RepeatByCheckboxesEvent} from "@/utils/events/events/repeat-by-checkboxes-event";
import {LinkFieldsEvent} from "@/utils/events/events/link-fields-event";
import {ReloadBuildersEvent} from "@/utils/events/events/reload-builders-event";
import {ChangedPopupBlockStateEvent} from "@/utils/events/events/changed-popup-block-state-event";
import {ElementDeleteFileEvent} from "@/utils/events/events/element-delete-file-event";

const eventBus = new Vue;

export const builderElementsEvents = {
    updateValue: new UpdateElementValueEvent(eventBus),
    loadFile: new ElementLoadFileEvent(eventBus),
    deleteFile: new ElementDeleteFileEvent(eventBus),
    editorLoadFile: new EditorLoadFileEvent(eventBus),
    clickDuplicate: new DuplicateAddEvent(eventBus),
    removeDuplicate: new DuplicateRemoveEvent(eventBus),
    repeatByElement: new RepeatByElementEvent(eventBus),
    setRepeatedValue: new SetRepeatedValueEvent(eventBus),
    repeatByCheckboxes: new RepeatByCheckboxesEvent(eventBus),
    linkFields: new LinkFieldsEvent(eventBus),
    reloadBuilders: new ReloadBuildersEvent(eventBus),
    changedPopupBlockState: new ChangedPopupBlockStateEvent(eventBus)
}