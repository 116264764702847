
export const builderElementModeModule = {
    namespaced: true,
    state: {
        isEditMode: false
    },
    getters: {
        getEditMode: (state) => state.isEditMode,
    },
    mutations: {
        setEditMode: (state, editMode) => {
            state.isEditMode = editMode
        },
    },
    actions: {
        changeEditMode: (context, {editMode}) => {
            context.commit('setEditMode', editMode)
        },
    }
}