const BUILDER_ELEMENTS = 'builder-elements';
const BUILDER_ELEMENT_IDS_TO_HIDE = 'builder-element-ids-to-hide';
const INSURANCE_PRICE_IS_NPOPT = 'insurance-price-is-npopt';
const COPIED_BUILDER_ELEMENTS_NPOPT = 'copied-builder-elements-npopt';
const BUILDER_ELEMENT_IDS_TO_SHOW = 'builder-element-ids-to-show';

const IS_AMS_USER = 'is-ams-user';

const IBAN = 'iban';

export const localStorageService = {
    builderElement: {
        saveElement: (element) => {
            let currentValues = localStorageService.builderElement.getElements();

            currentValues = currentValues.filter((item) => element.id !== item.id);

            currentValues.push({
                id: element.id,
                value: element.value,
                is_show: element.is_show,
            })

            localStorage.setItem(BUILDER_ELEMENTS, JSON.stringify(currentValues));
        },
        getElements: () => {
            return JSON.parse(localStorage.getItem(BUILDER_ELEMENTS) ?? JSON.stringify([]))
        },
        clearElements: () => {
            localStorage.removeItem(BUILDER_ELEMENTS)
        },
        setElementIdsToHide: ids => {
            localStorage.setItem(BUILDER_ELEMENT_IDS_TO_HIDE, JSON.stringify(ids ?? []));
        },
        getElementIdsToHide: () => {
            return JSON.parse(localStorage.getItem(BUILDER_ELEMENT_IDS_TO_HIDE) ?? JSON.stringify([]));
        },
        setCopiedElementIdsNpopt: ids => {
            localStorage.setItem(COPIED_BUILDER_ELEMENTS_NPOPT, JSON.stringify(ids));
        },
        getCopiedElementIdsNpopt: () => {
            return JSON.parse(localStorage.getItem(COPIED_BUILDER_ELEMENTS_NPOPT) ?? JSON.stringify([]));
        },
        isEmptyCopiedElementIdsNpopt: () => {
            return localStorageService.builderElement.getCopiedElementIdsNpopt().length === 0;
        },
        clearCopiedElementIdsNpopt: () => {
            localStorage.removeItem(COPIED_BUILDER_ELEMENTS_NPOPT)
        },
        setElementIdsToShow: ids => {
            localStorage.setItem(BUILDER_ELEMENT_IDS_TO_SHOW, JSON.stringify(ids ?? []));
        },
        getElementIdsToShow: () => {
            return JSON.parse(localStorage.getItem(BUILDER_ELEMENT_IDS_TO_SHOW) ?? JSON.stringify([]));
        },
    },
    insurancePrice: {
      setIsNpopt : isNpopt => {
          localStorage.setItem(INSURANCE_PRICE_IS_NPOPT, isNpopt);
      },
      getIsNpopt : () => {
          return JSON.parse(localStorage.getItem(INSURANCE_PRICE_IS_NPOPT)) ?? false
      },
    },

    amsAuth: {
        setIsUserAms : val => {
            localStorage.setItem(IS_AMS_USER, val);
        },
        getIsUserAms : () => {
            return JSON.parse(localStorage.getItem(IS_AMS_USER));
        },
    },

    iban: {
        setIban : (key, val) => {
            localStorage.setItem(IBAN+'-'+key, JSON.stringify(val ?? ''));
        },
        getIban : key => {
            return JSON.parse(localStorage.getItem(IBAN+'-'+key));
        },
    },

}