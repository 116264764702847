<template>
  <div class="mt-3 ml-3 mr-3 mb-3">
    <v-row>
      <v-col cols="12"><h3 class="pt-2 font-s-24 font-weight-black">Schließungsrisiko</h3></v-col>
      <v-col cols="12" class="text-right mt-2"><h3 class="font-s-18 font-weight-black pb-2" >Versicherte Tagesentschädigung je<br> Betriebsart für 30 Schließungstage</h3></v-col>
    </v-row>

    <div class="price-items__wrapper mt-4" v-for="(priceItem, index) in priceItems.data" :key="index">
      <div class="font-s-18 font-weight-black pb-2">{{ index }}</div>
      <div v-for="(elements, index) in priceItem" :key="index">
        <div v-for="(element, index) in elements" :key="index">
          <div class="d-flex justify-space-between">
            <p>{{ index }}</p>
            <p>{{ element | toMoneyFormat }}</p>
          </div>
        </div>
      </div>
    </div>
    <hr v-if="isShowDamageRisk" class="mt-5 mb-5">
    <h3 v-if="isShowDamageRisk" class="pt-2 font-s-24 font-weight-black pb-3">Warenschadenrisiko</h3>
    <div v-if="isShowDamageRisk" class="d-flex justify-space-between">
      <p>Versicherungssumme</p>
      <p>{{ priceItems.sum | toMoneyFormat }}</p>
    </div>
  </div>
</template>
<script>
import {BS_40_RADIO_BUTTON} from "@/enums/fields/bs/field-names";
import {BS_40_CLOSURE_RIRIKO} from "@/enums/fields/bs/field-values";
import {builderElementService} from "@/utils/services/builder-element-service";

export default {
  props: {
    priceItems: {
      type: [Object, Array],
    }
  },
  computed: {
    allBuilderElements() {
      return this.$store.getters["insuranceModule/builderElementsModule/getAllBuilderElements"];
    },
    isShowDamageRisk() {
      const [field] = builderElementService.findAllElementsByParameters(this.allBuilderElements, 'name', BS_40_RADIO_BUTTON);

      return field?.value !== BS_40_CLOSURE_RIRIKO;
    }
  }
}
</script>
<style scoped>
.price-items__wrapper .price-item {
  padding-top: 20px;
  padding-bottom: 15px;
  border-top: var(--default-border);
}

.price-items__wrapper:first-of-type .price-item {
  border-top: 1px solid transparent;
}
</style>