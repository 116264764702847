import {serverApi} from "@/api/server/server-api.js";
import {builderElementService} from "@/utils/services/builder-element-service";
import {localStorageService} from "@/utils/services/local-storage-service";

export const setIbanDataAction = async ({passiveElement, action_value, allBuilderElements}) => {
  const elementNames = action_value.split(';').map(itm => itm.trim());

  const iban = passiveElement.value.split(' ').join('');
  const swiftCodeName = elementNames[0]
  const bankName = elementNames[1]
  const ibanValidationName = elementNames[2]

  const foundBuilderElements = builderElementService.findAllElementsByParameters(allBuilderElements, 'name', elementNames)

  const swiftCodeField = foundBuilderElements.find(itm => itm.name === swiftCodeName);
  const bankField = foundBuilderElements.find(itm => itm.name === bankName);
  const ibanValidationField = foundBuilderElements.find(itm => itm.name === ibanValidationName);

  if (iban.length < 20 || localStorageService.iban.getIban(passiveElement.id) === iban) {
    return
  }

  const {data} = await serverApi.insurance.checkIban(iban)

  if (!data.success) {
    return
  }

  if ((!data.bic || !data.bank) && ibanValidationField) {
    ibanValidationField.is_show = true
    localStorageService.builderElement.saveElement(ibanValidationField)
    localStorageService.iban.setIban(passiveElement.id, '')

    return
  }

  if (swiftCodeField && data.bic) {
    swiftCodeField.value = data.bic
    localStorageService.builderElement.saveElement(swiftCodeField)
  }

  if (bankField && data.bank) {
    bankField.value = data.bank
    localStorageService.builderElement.saveElement(bankField)
  }

  if (ibanValidationField) {
    ibanValidationField.is_show = false
    localStorageService.builderElement.saveElement(ibanValidationField)

    return
  }

  localStorageService.iban.setIban(passiveElement.id, iban)
}