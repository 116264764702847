<template>
  <div class="px-4 pt-8">
    <span class="grey--text">Id: {{ builderElement.id }}</span>
    <p class="app-text__bold">Select item</p>

    <div class="inputs-section">
      <searchable-select-field
          name="parentElements"
          :items="elements"
          :value="selected ? builderElement.id : ''"
          @saveField="selectElement"
      />

      <div class="pt-2" v-if="selected">
        <p class="app-text__bold">Select Type</p>

        <searchable-select-field
            name="parentsTypes"
            :items="elementTypes"
            :value="builderElement.type"
            @saveField="onSelectElementType"
        />

        <p class="app-text__bold">Parent Block</p>

        <searchable-select-field
            name="parentElement"
            :items="parentElements"
            :value="builderElement.parent_id"
            @saveField="onSelectParentElement"
        />

        <builder-element-editor-factory
            :builder-element="builderElement"
        />
      </div>
    </div>

    <div v-if="selected">

      <div class="button-section">
        <blue-btn
            class="float-right"
            @click="onClickSave"
        >
          Save
        </blue-btn>

        <white-btn
            class="float-right mr-2"
            @click="onClickCopy"
        >
          Copy
        </white-btn>
        <white-btn
            class="float-right mr-2"
            @click="onOpenCopySqlBox"
        >
          SQL
        </white-btn>

        <red-btn
            class="float-right mr-2"
            @click="onClickDelete"
        >
          Delete
        </red-btn>

        <orange-btn
            class="float-right mr-2"
            @click="onCancelClick"
        >
          Cancel
        </orange-btn>
      </div>

    </div>

    <div v-else class="not-selected__text">
      in edit mode you can select elements<br>
      for editing by mouse cursor
    </div>
    <builder-element-sql
        v-if="showSql"
        :builder-element="builderElement"
        :insurance-types="insuranceTypes"
        @close="onCloseSqlPopup"
    />
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import BuilderElementEditorFactory from "@/ui/components/builder/use-cases/builder-editor/BuilderElementEditorFactory.vue";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import {builderEditorEvents} from "@/utils/events/builder-editor-events.js";
import {Builder} from "@/models/builder.js";
import RedBtn from "@/ui/components/common/buttons/RedBtn.vue";
import {builderElementService} from "@/utils/services/builder-element-service.js";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import {notificationService} from "@/utils/services/notification-service.js";
import SearchableSelectField from "@/ui/components/common/fields/SearchableSelectField.vue";
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import {confirmService} from "@/utils/services/confirm-service.js";
import {builderElementsEvents} from "@/utils/events/builder-elements-events";
import BuilderElementSql from "@/ui/components/admin/popups/BuilderElementSql";
import {builderElementsLabels} from "@/enums/builder-element-enum";

export default {
  components: {
    BuilderElementSql,
    WhiteBtn, SearchableSelectField, OrangeBtn, RedBtn, BlueBtn, BuilderElementEditorFactory},
  props: {
    builder: {
      type: Builder,
      required: true,
    },
    insuranceTypes: {
      type: Array
    }
  },
  computed: {
    elements() {
      return builderElementService.elementsToSingleArray(this.builder.elements).map((element) => ({
        text: element.name,
        value: element.id,
      }))
    },
    parentElements() {
      return builderElementService.findOnlyParents(this.builder.elements).map((elem) => ({
        text: elem.name,
        value: elem.id
      }))
    },
    elementTypes() {
      return builderElementsLabels
    },
  },
  data: () => ({
    oldBuilderElement: BuilderElement.empty(),
    builderElement: BuilderElement.empty(),

    showSql: false,
    selected: false,
  }),
  mounted() {
    builderEditorEvents.selectElement.on(this.onSelectElement)
    builderEditorEvents.unselectElement.on(this.onUnselectElement)
    builderElementsEvents.editorLoadFile.on(this.onLoadFile)
  },
  destroyed() {
    builderEditorEvents.selectElement.off(this.onSelectElement);
    builderEditorEvents.unselectElement.off(this.onUnselectElement);

    this.onCancelClick();
  },
  beforeDestroy() {
    this.onCancelClick();

    builderElementsEvents.editorLoadFile.off(this.onLoadFile)
  },
  methods: {
    onSelectParentElement(parentId) {
      const oldParentId = this.builderElement.parent_id;
      this.builderElement.parent_id = parentId;

      builderEditorEvents.changedParentElement.emit({
        builderElement: this.builderElement,
        oldParentId: oldParentId
      });
    },
    onSelectElementType(type) {
      this.builderElement.type = type;
    },
    onSelectElement(id) {
      this.builderElement = builderElementService.findElementById(this.builder.elements, id);
      this.oldBuilderElement = builderElementService.copyElement(this.builderElement);

      this.selected = true;
    },

    onUnselectElement() {
      this.builderElement.fillData(this.oldBuilderElement.toObject());

      this.selected = false;
    },

    selectElement(id) {
      this.onSelectElement(id);

      builderEditorEvents.selectElement.emit(id);
    },

    onCancelClick() {
      builderEditorEvents.unselectElement.emit()
    },

    async onClickCopy() {
      await this.performCopy(this.builderElement)

      notificationService.success("Element was copied");
    },

    onCloseSqlPopup() {
      this.showSql = false;
    },

    onOpenCopySqlBox() {
      this.showSql = true;
    },

    async onClickSave() {
      await this.performUpdate(this.builderElement.id, this.builderElement.toObject());

      notificationService.success("Element updated")
    },

    async onClickDelete() {
      if (!confirmService.simpleConfirm("Are you really want to delete this builderElement?")) {
        return;
      }

      await this.performDelete(this.builderElement.id);

      notificationService.success("Element deleted")
    },

    async performCopy(builderElement) {
      const newElement = await this.copyBuilderElement(
          builderElement.id,
          builderElement.name + " COPY",
          builderElement.priority + 1,
          builderElement.data,
      )

      builderEditorEvents.addElementToList.emit(newElement)
      setTimeout(() => builderEditorEvents.selectElement.emit(newElement.id), 150);
    },

    async performDelete(id) {
      builderEditorEvents.unselectElement.emit();

      builderEditorEvents.removeElementFromList.emit(id);

      await this.deleteElement(id);
    },

    async performUpdate(id, data) {
      const builderElement = await this.updateElement(id, data);

      builderEditorEvents.unselectElement.emit();

      builderEditorEvents.removeElementFromList.emit(id);
      builderEditorEvents.addElementToList.emit(builderElement);

      builderEditorEvents.elementSaved.emit(builderElement);

      return builderElement;
    },

    copyBuilderElement(id, name, priority, data) {
      return this.$store.dispatch('admin/builderEditorModule/copyBuilderElement', {
        id,
        name,
        priority,
        data,
      })
    },

    updateElement(id, data) {
      return this.$store.dispatch('admin/builderEditorModule/updateBuilderElement', {
        id,
        data
      })
    },

    async deleteElement(id) {
      await this.$store.dispatch('admin/builderEditorModule/deleteBuilderElement', {
        id
      })
    },

    async onLoadFile({file}) {
      if (!this.builderElement.isEmpty()) {
        const {url} = await this.$store.dispatch('admin/builderEditorModule/uploadFile', {
          builderElementId: this.builderElement.id,
          file,
        });
        this.builderElement.data.link = url;

        notificationService.success("File uploaded")
      }
    },
  }
}
</script>
<style scoped>
.not-selected__text {
  padding-top: 225px;
  text-align: center;
  text-transform: uppercase;
  color: var(--gray);
}

.inputs-section {
  overflow-y: auto;
  height: 550px;
}

.button-section {
  position: absolute;
  right: 2rem;
  bottom: 25px;
  background: white;
}
</style>