import {dateService} from "@/utils/services/date-service.js";
import {DateFieldValidation} from "@/utils/types/date-field-validation.js";

export const dateFieldValidationByField = async ({activeElement, passiveElement, context}) => {

    if(activeElement.value && dateService.checkDate(activeElement.value)) {
        try {
            const maxDateFieldValidation = new DateFieldValidation(activeElement.validation.maxDate)
            const minDateFieldValidation = new DateFieldValidation(activeElement.validation.minDate)

            maxDateFieldValidation.value = activeElement.value;
            minDateFieldValidation.value = activeElement.value;

            passiveElement.validation.maxDate = maxDateFieldValidation.toObject();
            passiveElement.validation.minDate = minDateFieldValidation.toObject();

            if (activeElement.validation?.range?.isRangeValidation) {
                passiveElement.validation = {
                    ...passiveElement.validation,
                    range: {
                        ...activeElement.validation.range,
                        start: (new DateFieldValidation(activeElement.validation.range.start)).toObject(),
                        end: (new DateFieldValidation(activeElement.validation.range.end)).toObject(),
                    }
                }
            }

            await context.dispatch('insuranceModule/builderElementsModule/saveElement', {
                builderElement: passiveElement,
            }, {root: true})
        } catch(e) {
            console.error("Can't run DATE_FIELD_VALIDATION_BY_FIELD, probably validation for " + passiveElement.name + " does not exist", e);
        }
    }
}
