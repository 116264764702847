<template>
  <v-btn
      @click="onClick"
      class="app-btn white--text"
      :height="height"
      :width="width"
      outlined
      rounded
      :disabled="disabled"
  >
    <slot />
  </v-btn>
</template>
<script>
export default {
  props: {
    height: {
      type: String,
      default: '35px',
    },
    width: {
      type: String,
      default: 'auto'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
}
</script>
<style scoped>
.app-btn {
  background: var(--orange) !important;
  border: var(--white-border) !important;
}

.app-btn:hover {
  background: var(--orange-hover) !important;
}
</style>