<template>
<div v-if="isLoaded">
  <div class="mt-2 pt-3 app-bg-aqua">
    <app-block class="py-2 mb-4 app-container">
      <progress-bar
          name="Vorschlag"
          :selected-item="2"
      />
    </app-block>

    <div class="mt-2 mb-4 pt-2 text-center header">
      <div class="mb-3">
        <h3>Vermögensschadenhaftpflicht</h3>
      </div>
      <div class="pb-4">
        <h1>Erstellen Sie ein neues Angebot</h1>
      </div>
      <img class="bg-img" src="/img/man_sitting_on_vault_holding_money.png" alt="Allianz logo"/>
    </div>
  </div>
  <div class="">
    <insurance-step-wrapper
        :show-next-button="isShowNextBtn"
        @prevButtonClick="onClickPrev"
        @nextButtonClick="onClickNext"
    >
      <price-data-block-corporate-d-o
        :insurance-price="insurancePrice"
        :is-disable-all-inputs="isDisableAllInputs"
        @changeInsurancePriceElement="changeInsurancePriceElement"
      />

      <document-applications-block
        class="mt-4"
        :documents="documentApplications"
      />
    </insurance-step-wrapper>
  </div>
</div>
</template>

<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import ProgressBar from "@/ui/components/insurance/ProgressBar.vue";
import InsuranceStepWrapper from "@/ui/components/insurance/insurance-wrappers/InsuranceStepWrapper.vue";
import PriceDataBlockCorporateDO from "@/ui/components/insurance/insurance-prices/blocks/PriceDataBlockCorporateDO.vue";
import DocumentApplicationsBlock from "@/ui/components/insurance/insurance-prices/blocks/DocumentApplicationsBlock.vue";

export default {
  components: {
    DocumentApplicationsBlock,
    AppBlock,
    ProgressBar,
    InsuranceStepWrapper,
    PriceDataBlockCorporateDO,
  },
  props: {
    isDisableAllInputs: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    insurance() {
      return this.$store.getters["insuranceModule/getInsurance"];
    },
    insurancePrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePrice"];
    },
    isShowNextBtn() {
      return this.insurancePrice.getIsNpopt()
    },
  },
  data: () => ({
    paymentPeriod: "Jährlich",
    isLoaded: false,
    documentApplications: [],
  }),
  async created() {
    this.showProcessPreloader();
    await this.calculatePrices()

    await this.getDocumentApplicationsByType(this.insurance.type.id)

    this.isLoaded = true
    this.hideProcessPreloader();
  },
  methods: {
    showProcessPreloader() {
      this.$store.commit('insuranceModule/showProcessPreloader')
    },
    hideProcessPreloader() {
      this.$store.commit('insuranceModule/hideProcessPreloader')
    },
    async onClickPrev() {
      this.$store.commit('insuranceModule/showPreloader')
      await this.prevStep();
      this.$store.commit('insuranceModule/hidePreloader')
    },

    async onClickNext() {
      this.$store.commit('insuranceModule/showPreloader')
      await this.statusCustomer();
      await this.sendXml();
      this.$store.commit('insuranceModule/hidePreloader')
    },

    async statusCustomer() {
      await this.$store.dispatch('insuranceModule/statusCustomer')
    },

    sendXml() {
      this.$store.dispatch('insuranceModule/sendXml')
    },

    async calculatePrices() {
      await this.calculatePrice(this.insurance.id)
    },
    async calculatePrice(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/calculatePrice', {
        insuranceId,
      })
    },
    async prevStep() {
      await this.statusDraft();
    },

    async statusDraft() {
      await this.$store.dispatch('insuranceModule/statusDraft')
    },

    async getDocumentApplications(insuranceTypeId) {
      return await this.$store.dispatch('insuranceModule/insurancePriceModule/getDocumentApplications', {
        insuranceTypeId,
      })
    },

    async getDocumentApplicationsByType() {
      this.documentApplications = await this.getDocumentApplications(this.insurance.type.id);
    },

    async changeInsurancePriceElement() {
      await this.getDocumentApplicationsByType(this.insurance.type.id)
    },
  },
}
</script>

<style scoped>
.bg-img {
  max-height: 195px;
  margin-bottom: -12px;
}
</style>