<template>
  <div class="app-default-border">
    <app-block
        class="px-4 pb-6"
        width="300"
        height="450px"
    >
       <slot />
    </app-block>
  </div>
</template>
<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
export default {
  components: {AppBlock}
}
</script>