<template>
  <orange-btn
      :width="width"
      @click="runNextStep"
  >
    {{ text }}
  </orange-btn>
</template>
<script>
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
export default {
  components: {OrangeBtn},
  props: {
    text: {
      type: String,
      default: 'Speichern und weiter'
    },
    width: {
      type: String,
      default: '250px',
    },
  },
  methods: {
    runNextStep() {
      const validationMessage = document.getElementById('validation-message')

      if (validationMessage) return

      this.$emit('click');
    },
  }
}
</script>