<template>
  <app-block :height="height" :width="width" :style="{margin}" :color="background" :class="cssClass">
    <div :style="{padding, width}" :id="id">
      <builder-elements
          :elements="children"
          :builder-element-id="builderElementId"
          :disabled-all-inputs="disabledAllInputs"
          :admin-edit-mode="adminEditMode"
      />
    </div>
  </app-block>
</template>
<script>
import AppBlock from "../../common/blocks/AppBlock.vue";
import Padding from "./mixins/Padding.js"
import ParentElement from "./mixins/ParentElement.js";
import DefaultElement from "./mixins/DefaultElement.js";
import Size from "./mixins/Size.js";
import Id from "./mixins/Id.js";
import Background from "@/ui/components/builder/elements/mixins/Background.js";
import CssClass from "./mixins/CssClass.js";

export default {
  components: {
    AppBlock
  },
  mixins: [DefaultElement, Padding, ParentElement, Size, Background, Id, CssClass],
  props: {
    builderElementId: {
      type: String,
      required: false,
    },
    disabledAllInputs: {
      type: Boolean,
      required: false,
      default: false,
    },
    adminEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>