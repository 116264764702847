import {objectService} from "@/utils/services/object-service.js";

export class InsurancePriceAddonCondition {

    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(InsurancePriceAddonCondition.empty().toObject())
            : this.fillData(data);
    }

    fillData(data) {
        this.id = data.id;
        this.relation_id = data.relation_id;
        this.builder_element_id = data.builder_element_id
        this.condition = data.condition;
        this.condition_value = data.condition_value;
        this.group_code = data.group_code ? data.group_code : null
    }

    isEmpty() {
        return this.id === 0;
    }

    static empty(data = {}) {
        return new InsurancePriceAddonCondition({
            id: data.id || 0,
            relation_id: data.relation_id || '',
            builder_element_id: data.builder_element_id || '',
            condition: data.condition || '',
            condition_value: data.condition_value || '',
            group_code: data.group_code ? data.group_code : null
        })
    }

    toObject() {
        return {
            ...this
        }
    }

}