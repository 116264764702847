export const insurancePriceApi = (http) => ({
    checkOnEmptyPrice: (insuranceId) => http.get(`/api/insurance/check-empty-price/${insuranceId}`),
    insurancePriceByInsuranceId: (insuranceId) => http.get(`/api/insurance/price/${insuranceId}`),
    calculatePrice: (insuranceId) => http.post(`/api/insurance/calculate-price/${insuranceId}`),
    update: (insurancePriceId, newData) => http.post(`/api/insurance-prices/${insurancePriceId}/update`, {data: newData}),
    determineInsurancePriceView: (insuranceId) => http.post(`/api/insurance-prices/determine-price-view/${insuranceId}`),
    builderElementsForNpopt: (insuranceId) => http.get(`/api/insurance-prices/builder-elements-for-npopt/${insuranceId}`),
    insurancePriceById: (id) => http.get(`/api/insurance/price/${id}`),
    insurancePricesByInsuranceId: (insuranceId) => http.get(`/api/insurance/prices/${insuranceId}`),
    updatePrices: (insuranceId, data) => http.post(`/api/insurance-prices/${insuranceId}/update-by-ids`, {data}),
    sendEmptyPriceEmail: (insuranceId) => http.post(`/api/insurance-prices/${insuranceId}/send-empty-price-email`),
    sendNpoptEmail: (insuranceId) => http.post(`/api/insurance-prices/${insuranceId}/send-npopt-email`),
    configureEmptyPrice: (insuranceId) => http.post(`/api/insurance-prices/${insuranceId}/configure-empty-price`),
    documentApplications: (insuranceId) => http.get(`/api/insurance-prices/${insuranceId}/document-applications`),
})