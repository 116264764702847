import {parentBuilderElements} from "@/enums/builder-element-enum.js";
import {objectService} from "@/utils/services/object-service.js";

const toBuilderElements = (elements) => {
    return elements.map((elem) => {
        if(elem.children.length) {
            elem.children = toBuilderElements(elem.children);
        }

        return new BuilderElement(elem)
    })
}

export class BuilderElement {
    constructor(data) {
        this.fillData(data);
    }

    isVisible() {
        return this.is_show;
    }

    fillData(data) {
        this.id = data.id;
        this.name = data.name;
        this.type = data.type;
        this.value = data.value;
        this.data = objectService.shouldBeObject(data.data);
        this.validation = objectService.shouldBeObject(data.validation);
        this.is_show = data.is_show;
        this.priority = data.priority;
        this.children = toBuilderElements(data.children);
        this.parent_id = data.parent_id;
        this.builder_id = data.builder_id;
        this.css_class = data.css_class;
    }

    static empty(data = {}) {
        return new BuilderElement({
            id: data.id || 'random',
            name: data.name || '',
            type: data.type || '',
            value: data.value || '',
            validation: objectService.shouldBeObject(data.validation),
            data: objectService.shouldBeObject(data.data),
            is_show: data.is_show || 1,
            priority: data.priority || 0,
            children: data.children || [],
            builder_id: data.builder_id || '',
            parent_id: data.parent_id || '',
            css_class: data.css_class || '',
        })
    }

    isEmpty() {
        return this.id === 'random';
    }

    setDataItem(key, value) {
        this.data = {...this.data, [key]: value}
    }

    isParentElement() {
        return parentBuilderElements.includes(this.type)
    }

    toObject() {
        return {
            ...this,
            data: {...this.data},
            validation: {...this.validation},
        }
    }

    isDisabled() {
        return this.data?.isDisabled;
    }
}