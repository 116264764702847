<template>
  <div>
    <div class="text-center pb-2">
      <h3>Validation Section</h3>
    </div>

    <v-row>
      <v-col cols="4">
        <name-editor
          v-model="builderElement.name"
        />
      </v-col>

      <v-col cols="12">
        <p class="app-text__bold">Default Value</p>
        <text-area-field
          name="value"
          v-model="builderElement.value"
        />
      </v-col>

      <v-col class="pl-4" cols="4">
        <priority-editor
          v-model.number="builderElement.priority"
        />
      </v-col>

      <v-col class="pl-4 pt-10" cols="4">
        <can-go-next-editor
            v-model="builderElement.data.can_go_next"
        />
      </v-col>

      <v-col cols="6">
        <height-editor
          v-model="builderElement.data.height"
        />
      </v-col>

      <v-col class="pl-4" cols="6">
        <width-editor
          v-model="builderElement.data.width"
        />
      </v-col>

    </v-row>


    <margin-editor
      v-model="builderElement.data.margin"
    />

    <padding-editor
      class="mt-6"
      v-model="builderElement.data.padding"
    />
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import MarginEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MarginEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import PaddingEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PaddingEditor.vue";
import HeightEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/HeightEditor.vue";
import WidthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/WidthEditor.vue";
import CanGoNextEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/CanGoNextEditor.vue";
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";

export default {
  components: {
    TextAreaField,
    WidthEditor, HeightEditor, PaddingEditor, NameEditor, PriorityEditor, MarginEditor, CanGoNextEditor
  },
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  },
}
</script>