import {dateService} from "@/utils/services/date-service.js";

export const setCurrentDateAction = ({passiveElement, context, data}) => {
    if(!passiveElement.value) {
        let date = dateService.getCurrentDate();
        const years = data.dateParams?.years || 0;
        const months = data.dateParams?.months || 0;
        const days = data.dateParams?.days || 0;

        date = dateService.addPeriod(date, 'years', years);
        date = dateService.addPeriod(date, 'months', months)
        date = dateService.addPeriod(date, 'days', days)

        passiveElement.value = date;

        context.dispatch('insuranceModule/builderElementsModule/saveElement', {
            builderElement: passiveElement
        }, {root: true})
    }
}