export const textService = {

    replaceHtmlTags(text) {
        return text.replaceAll(/<.*?>/g, " ");
    },

    replaceTextInsideHtml(html, text) {
        return html.replace(/>(.*?)<\//, ">"+text+"</");
    },

    numberFromText(text) {
        return text.toString().match(/\d+/)[0]
    },
}