import {objectService} from "@/utils/services/object-service.js";

export class InsurancePriceItem {
    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(InsurancePriceItem.empty().toObject())
            : this.fillData(data);
    }

    fillData(data) {
        this.key = data?.key || null;
        this.name = data.name;
        this.price = data.price;
        this.additionalText = data.additionalText;
    }

    static empty(data = {}) {
        return new InsurancePriceItem({
            key: data?.key || null,
            name: data.name || '',
            price: data.price || '',
            additionalText: data.additionalText || null,
        })
    }

    toObject() {
        return {
            ...this
        }
    }
}