<template>
  <v-card outlined>
    <div :class="headerCss">
      <div class="toggle-header__text">
          <span class="app-text__bold">
            {{header}}
            <slot name="header"/>
          </span>
      </div>

      <div>
        <v-icon
            class="mt-2"
            size="32"
            @click="onClick">
          {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </div>
    </div>


    <transition type="slide-up">
      <div v-if="show">
        <slot/>
      </div>

    </transition>
  </v-card>
</template>
<script>
export default {
  props: {
    header: {
      type: String,
      required: false,
    },
    opened: {
      type: Boolean,
      default: false,
    },
    headerCssClasses: {
      type: String,
      default: '',
    },
  },
  computed: {
    headerCss() {
      return 'toggle-header px-4 ' + this.headerCssClasses
    },
  },
  data: () => ({
    show: false,
  }),
  created() {
      this.show = this.opened;
  },

  methods: {
    onClick() {
      this.show = !this.show

      this.$emit('click', this.show)
    }
  }
}
</script>
<style scoped>
.toggle-header {
  display: flex;
  justify-content: space-between;
}

.toggle-header__text {
  padding-top: 15px;
  width: 90%;
  height: 50px;
}
</style>