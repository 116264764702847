<template>
  <tooltip
      icon="mdi-help"
      :icon-font-size="size"
      :color="color"
      class="question-tooltip"
      :isHidePopup="isHidePopup"
  >
    <slot/>
  </tooltip>
</template>
<script>
import Tooltip from "./Tooltip.vue";

export default {
  components: {Tooltip},
  props: {
    size: {
      type: String,
      default: '16px',
    },
    color: {
      type: String,
      default: 'white',
    },
    isHidePopup: {
      type: Boolean,
      required: false,
      default: false,
    },
  }
}
</script>
<style scoped>
.question-tooltip {
  border-radius: 50%;
  background: var(--light-blue);
  border: 1px solid var(--light-blue);
  padding: 2px;
  display: inline-flex
}
</style>