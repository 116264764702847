import {
    builderElementRelationActionsEnum,
    builderElementRelationConditionEnum
} from "@/enums/builder-element-relations-enum.js";
import {hideAction} from "@/utils/factories/builder-relation/actions/hide-action.js";
import {showAction} from "@/utils/factories/builder-relation/actions/show-action.js";
import {setStaticValueAction} from "@/utils/factories/builder-relation/actions/set-static-value-action.js";
import {equalToCondition} from "@/utils/factories/builder-relation/conditions/equal-to-condition.js";
import {elementSelectedCondition} from "@/utils/factories/builder-relation/conditions/element-selected-condition.js";
import {elementUnselectedCondition} from "@/utils/factories/builder-relation/conditions/element-unselected-condition.js";
import {markSelectedAction} from "@/utils/factories/builder-relation/actions/mark-selected-action.js";
import {markUnselectedAction} from "@/utils/factories/builder-relation/actions/mark-unselected-action.js";
import {alwaysCondition} from "@/utils/factories/builder-relation/conditions/always-condition.js";
import {setCurrentDateAction} from "@/utils/factories/builder-relation/actions/set-current-date-action.js";
import {setBuilderElementValueAction} from "@/utils/factories/builder-relation/actions/set-builder-element-value-action.js";
import {addEmailValidationAction} from "@/utils/factories/builder-relation/actions/add-email-validation-action";
import {equalToBuilderElement} from "@/utils/factories/builder-relation/conditions/equal-to-builder-element.js";
import {minValueValidationAction} from "@/utils/factories/builder-relation/actions/min-value-validation-action.js";
import {haveAnyValueCondition} from "@/utils/factories/builder-relation/conditions/have-any-value-condition.js";
import {isEmptyCondition} from "@/utils/factories/builder-relation/conditions/is-empty-condition.js";
import {multipleDuplicateAction} from "@/utils/factories/builder-relation/actions/multiple-duplicate-action.js";
import {dateFieldValidationByField} from "@/utils/factories/builder-relation/actions/date-field-validation-by-field.js";
import {notEqualToCondition} from "@/utils/factories/builder-relation/conditions/not-equal-to-condition.js";
import {sumAction} from "@/utils/factories/builder-relation/actions/sum-action.js";
import {isMoreThan} from "@/utils/factories/builder-relation/conditions/is-more-than.js";
import {addRequiredValidationAction} from "@/utils/factories/builder-relation/actions/set-required-validation";
import {findCityAction} from "@/utils/factories/builder-relation/actions/find-city-action";
import {repeatByElementAction} from "@/utils/factories/builder-relation/actions/repeat-by-element-action";
import {setRepeatedValueByElementAction} from "@/utils/factories/builder-relation/actions/set-repeated-value-by-element-action";
import {isSumByFieldsMoreThan} from "@/utils/factories/builder-relation/conditions/is-sum-by-fields-more-than";
import {setSelectOptionsAction} from "@/utils/factories/builder-relation/actions/set-select-options-action";
import {userApiRoleEqualToCondition} from "@/utils/factories/builder-relation/conditions/user-api-role-equal-to-condition.js";
import {addPhoneValidationAction} from "@/utils/factories/builder-relation/actions/add-phone-validation-action";
import {isInDateRangeCondition} from "@/utils/factories/builder-relation/conditions/is-in-date-range-condition";
import {setValidationBySumAction} from "@/utils/factories/builder-relation/actions/set-validation-by-sum-action";
import {setEmptyValidationAction} from "@/utils/factories/builder-relation/actions/set-empty-validation-action";
import {isShownCondition} from "@/utils/factories/builder-relation/conditions/is-shown-condition";
import {isNotShownCondition} from "@/utils/factories/builder-relation/conditions/is-not-shown-condition";
import {showPopupAction} from "@/utils/factories/builder-relation/actions/show-popup-action";
import {hidePopupAction} from "@/utils/factories/builder-relation/actions/hide-popup-action";
import {repeatBySelectedCheckboxesCountAction} from "@/utils/factories/builder-relation/actions/repeat-by-selected-checkboxes-count-action";
import {comparePriceDataCondition} from "@/utils/factories/builder-relation/conditions/compare-price-data-condition";
import {setPriceTabValue} from "@/utils/factories/builder-relation/actions/set-price-tab-value-action";
import {prefillDateFromDurationPriceTabAction} from "@/utils/factories/builder-relation/actions/prefill-date-from-duration-price-tab-action";
import {isLessThanCondition} from "@/utils/factories/builder-relation/conditions/is-less-than-condition";
import {isMoreThanYearsInThePastCondition} from "@/utils/factories/builder-relation/conditions/is-more-than-years-in-the-past-condition";
import {isLessThenYearsInThePastCondition} from "@/utils/factories/builder-relation/conditions/is-less-then-years-in-the-past-condition";
import {isMoreOrEqualThanYearsInThePastCondition} from "@/utils/factories/builder-relation/conditions/is-more-or-equal-than-years-in-the-past-condition";
import {isLessOrEqualThanYearsInThePastCondition} from "@/utils/factories/builder-relation/conditions/is-less-or-equal-than-years-in-the-past-condition";
import {valueInListCondition} from "@/utils/factories/builder-relation/conditions/value-in-list-condition";
import {valueNotInListCondition} from "@/utils/factories/builder-relation/conditions/value-not-in-list-condition";
import {linkFieldsAction} from "@/utils/factories/builder-relation/actions/link-fields-action";
import {sumByFieldsAction} from "@/utils/factories/builder-relation/actions/sum-by-fields-action";
import {sumByFieldsCondition} from "@/utils/factories/builder-relation/conditions/sum-by-fields-condition";
import {setLabelTextAction} from "@/utils/factories/builder-relation/actions/set-label-text-action";
import {setBuilderElementDateWithDateParamsAction} from "@/utils/factories/builder-relation/actions/set-builder-element-date-with-date-params-action";
import {setValidationBySumMoreAction} from "@/utils/factories/builder-relation/actions/set-validation-by-sum-more-action";
import {specialDuplicateAction} from "@/utils/factories/builder-relation/actions/special-duplicate-action";
import {specialCopyDataAction} from "@/utils/factories/builder-relation/actions/special-copy-data-action";
import {
    deleteDuplicatedElementsAction
} from "@/utils/factories/builder-relation/actions/delete-duplicated-elements-action";
import {maxValueValidationAction} from "@/utils/factories/builder-relation/actions/max-value-validation-action";
import {
    repeatByDuplicatedItemAction
} from "@/utils/factories/builder-relation/actions/repeat-by-duplicated-item-action";
import {
    setValidationIsEqualToElementAction
} from "@/utils/factories/builder-relation/actions/set-validation-is-equal-to-element-action";
import {
    addIncorrectValueValidation
} from "@/utils/factories/builder-relation/actions/add-incorrect-value-validation";
import {setEnabledAction} from "@/utils/factories/builder-relation/actions/set-enabled-action";
import {setDisabledAction} from "@/utils/factories/builder-relation/actions/set-disabled-action";
import {setIbanDataAction} from "@/utils/factories/builder-relation/actions/set-iban-data-action";
import {addSpecialSymbolsValidationAction} from "@/utils/factories/builder-relation/actions/add-special-symbols-validation-action";
import {addSpecificEmailValidationAction} from "@/utils/factories/builder-relation/actions/add-specific-email-validation-action";

export const builderElementRelationFactory = {
    getAction: (actionType) => {
        const factory = {
            [builderElementRelationActionsEnum.HIDE]: hideAction,
            [builderElementRelationActionsEnum.SHOW]: showAction,
            [builderElementRelationActionsEnum.SET_DISABLED]: setDisabledAction,
            [builderElementRelationActionsEnum.SET_ENABLED]: setEnabledAction,
            [builderElementRelationActionsEnum.SET_STATIC_VALUE_NOT_SKIP]: setStaticValueAction,
            [builderElementRelationActionsEnum.SET_STATIC_VALUE]: setStaticValueAction,
            [builderElementRelationActionsEnum.SET_LABEL_TEXT]: setLabelTextAction,
            [builderElementRelationActionsEnum.REQUIRED]: addRequiredValidationAction,
            [builderElementRelationActionsEnum.MARK_SELECTED]: markSelectedAction,
            [builderElementRelationActionsEnum.MARK_UNSELECTED]: markUnselectedAction,
            [builderElementRelationActionsEnum.SET_CURRENT_DATE]: setCurrentDateAction,
            [builderElementRelationActionsEnum.SET_BUILDER_ELEMENT_VALUE]: setBuilderElementValueAction,
            [builderElementRelationActionsEnum.SET_BUILDER_ELEMENT_DATE_WITH_DATE_PARAMS]: setBuilderElementDateWithDateParamsAction,
            [builderElementRelationActionsEnum.ADD_EMAIL_VALIDATION]: addEmailValidationAction,
            [builderElementRelationActionsEnum.ADD_SPECIFIC_EMAIL_VALIDATION]: addSpecificEmailValidationAction,
            [builderElementRelationActionsEnum.MIN_VALUE_VALIDATION]: minValueValidationAction,
            [builderElementRelationActionsEnum.MAX_VALUE_VALIDATION]: maxValueValidationAction,
            [builderElementRelationActionsEnum.MULTIPLE_DUPLICATE]: multipleDuplicateAction,
            [builderElementRelationActionsEnum.DATE_FIELD_VALIDATION_BY_FIELD]: dateFieldValidationByField,
            [builderElementRelationActionsEnum.SUM]: sumAction,
            [builderElementRelationActionsEnum.FIND_CITY]: findCityAction,
            [builderElementRelationActionsEnum.REPEAT_BY_ELEMENT]: repeatByElementAction,
            [builderElementRelationActionsEnum.SET_REPEATED_VALUE_BY_ELEMENT]: setRepeatedValueByElementAction,
            [builderElementRelationActionsEnum.SET_SELECT_OPTIONS]: setSelectOptionsAction,
            [builderElementRelationActionsEnum.ADD_PHONE_VALIDATION]: addPhoneValidationAction,
            [builderElementRelationActionsEnum.SET_VALIDATION_BY_SUM]: setValidationBySumAction,
            [builderElementRelationActionsEnum.SET_EMPTY_VALIDATION]: setEmptyValidationAction,
            [builderElementRelationActionsEnum.SHOW_POPUP]: showPopupAction,
            [builderElementRelationActionsEnum.HIDE_POPUP]: hidePopupAction,
            [builderElementRelationActionsEnum.REPEAT_BY_CHECKBOXES_COUNT]: repeatBySelectedCheckboxesCountAction,
            [builderElementRelationActionsEnum.SET_PRICE_TAB_VALUE]: setPriceTabValue,
            [builderElementRelationActionsEnum.PREFILL_DATE_FROM_DURATION_PRICE_TAB]: prefillDateFromDurationPriceTabAction,
            [builderElementRelationActionsEnum.LINK_FIELDS]: linkFieldsAction,
            [builderElementRelationActionsEnum.SET_SUM_BY_FIELDS]: sumByFieldsAction,
            [builderElementRelationActionsEnum.SET_VALIDATION_BY_SUM_MORE]: setValidationBySumMoreAction,
            [builderElementRelationActionsEnum.SPECIAL_DUPLICATE_WITH_COPY]: specialDuplicateAction,
            [builderElementRelationActionsEnum.SPECIAL_COPY_DATA]: specialCopyDataAction,
            [builderElementRelationActionsEnum.DELETE_DUPLICATED]: deleteDuplicatedElementsAction,
            [builderElementRelationActionsEnum.REPEAT_BY_DUPLICATED_ELEMENT]: repeatByDuplicatedItemAction,
            [builderElementRelationActionsEnum.SET_VALIDATION_EQUAL_TO_ELEMENT_VALUE]: setValidationIsEqualToElementAction,
            [builderElementRelationActionsEnum.ADD_INCORRECT_VALUE_VALIDATION]: addIncorrectValueValidation,
            [builderElementRelationActionsEnum.SET_IBAN_DATA]: setIbanDataAction,
            [builderElementRelationActionsEnum.ADD_SPECIAL_SYMBOLS_VALIDATION]: addSpecialSymbolsValidationAction,
        }

        if (factory[actionType]) {
            return factory[actionType];
        }

        throw new Error('Undefined action');
    },
    getCondition: (conditionType) => {
        const factory = {
            [builderElementRelationConditionEnum.ALWAYS]: alwaysCondition,
            [builderElementRelationConditionEnum.EQUAL_TO]: equalToCondition,
            [builderElementRelationConditionEnum.NOT_EQUAL_TO]: notEqualToCondition,
            [builderElementRelationConditionEnum.EQUAL_TO_BUILDER_ELEMENT]: equalToBuilderElement,
            [builderElementRelationConditionEnum.ELEMENT_SELECTED]: elementSelectedCondition,
            [builderElementRelationConditionEnum.ELEMENT_UNSELECTED]: elementUnselectedCondition,
            [builderElementRelationConditionEnum.HAVE_ANY_VALUE]: haveAnyValueCondition,
            [builderElementRelationConditionEnum.IS_EMPTY]: isEmptyCondition,
            [builderElementRelationConditionEnum.IS_MORE_THEN]: isMoreThan,
            [builderElementRelationConditionEnum.IS_LESS_THEN]: isLessThanCondition,
            [builderElementRelationConditionEnum.IS_MORE_THEN_YEARS_IN_THE_PAST]: isMoreThanYearsInThePastCondition,
            [builderElementRelationConditionEnum.IS_MORE_OR_EQUAL_THEN_YEARS_IN_THE_PAST]: isMoreOrEqualThanYearsInThePastCondition,
            [builderElementRelationConditionEnum.IS_LESS_OR_EQUAL_THEN_YEARS_IN_THE_PAST]: isLessOrEqualThanYearsInThePastCondition,
            [builderElementRelationConditionEnum.IS_LESS_THEN_YEARS_IN_THE_PAST]: isLessThenYearsInThePastCondition,
            [builderElementRelationConditionEnum.SUM_BY_FIELDS_MORE_THAN]: isSumByFieldsMoreThan,
            [builderElementRelationConditionEnum.SUM_BY_FIELDS_CONDITION]: sumByFieldsCondition,
            [builderElementRelationConditionEnum.USER_API_ROLE_EQUAL_TO]: userApiRoleEqualToCondition,
            [builderElementRelationConditionEnum.IS_IN_DATE_RANGE]: isInDateRangeCondition,
            [builderElementRelationConditionEnum.IS_SHOWN]: isShownCondition,
            [builderElementRelationConditionEnum.IS_NOT_SHOWN]: isNotShownCondition,
            [builderElementRelationConditionEnum.COMPARE_PRICE_DATA]: comparePriceDataCondition,
            [builderElementRelationConditionEnum.VALUE_IN_LIST]: valueInListCondition,
            [builderElementRelationConditionEnum.VALUE_NOT_IN_LIST]: valueNotInListCondition,
        }

        if (factory[conditionType]) {
            return factory[conditionType];
        }

        throw new Error("Undefined condition");
    }
}

