<template>
  <div :style="{width}">
    <v-text-field
        v-model="activeValue"
        type="number"
        @input="saveField"
        :rules="validationRules"
        :placeholder="placeholder"
        :disabled="disabled"
        outlined
        dense
        :hide-spin-buttons="isHideSpinButtons"
        :class="{rounded: rounded}"
    />
  </div>
</template>
<script>
import {
  equalToElementValue,
  maxValue,
  minValue,
  required,
  validateBySumElements,
  validateBySumMoreElements
} from "@/utils/validation-rules.js";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      required: false,
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    width: {
      type: String,
      default: 'auto',
    },
    isInteger: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isHideSpinButtons: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    allBuilderElements() {
      return this.$store.getters["insuranceModule/builderElementsModule/getAllBuilderElements"];
    },
    validationRules() {
      const rules = [];

      if (this.validation.required && this.validation.required !== '0') {
        rules.push((val) => required(val, this.validation.message), );
      }

      if(this.validation.minValue !== undefined && this.validation.minValue !== null) {
        rules.push((val) => minValue(val, this.validation.minValue, this.validation.message))
      }

      if(this.validation.maxValue !== undefined && this.validation.maxValue !== null) {
        rules.push((val) => maxValue(val, this.validation.maxValue, this.validation.message))
      }

      if (this.validation.bySum) {
        rules.push((val, message = this.validation.bySum.message || 'Pflichtfeld') =>
            validateBySumElements(
                val,
                this.validation.bySum.fieldNames,
                this.validation.bySum.operation,
                this.validation.bySum.targetValue,
                this.allBuilderElements,
                this.validation.bySum.passiveElement,
                message
            )
        )
      }

      if (this.validation.bySumMore) {
        rules.push((val, message = this.validation.bySumMore.message || 'Pflichtfeld') =>
          validateBySumMoreElements(
                val,
                this.validation.bySumMore.fieldNames,
                this.validation.bySumMore.operation,
                this.validation.bySumMore.targetValue,
                this.allBuilderElements,
                this.validation.bySumMore.passiveElement,
                message
            )
        )
      }

      if (this.validation.equalTo && this.validation.equalTo.elementId) {
        rules.push(
            (val, message = this.validation.equalTo.message || 'Error') =>
                equalToElementValue(val, this.validation.equalTo.elementId, this.allBuilderElements, message)
        );
      }

      return rules;
    },
  },
  watch: {
    value(newVal) {
      if (this.isInteger && newVal) {
        this.activeValue = parseInt(newVal);
      }
    },
  },
  data: () => ({
    activeValue: ''
  }),
  created() {
    this.fillData()
  },
  methods: {
    saveField(value) {
      this.$emit('saveField', value, this.name)
      this.$emit('input', value);
    },
    fillData() {
      if (this.isInteger) {
        this.activeValue = parseInt(this.value)

        if (isNaN(this.activeValue)) {
          this.activeValue = null;
        }

        return;
      }

      this.activeValue = this.value
    },
  }
}
</script>