<template>
  <v-app>
    <v-main class="app-bg-light-smoke">
      <admin-sidebar class="sidebar-pos" />
      <admin-header class="header-pos"/>
      <div class="content-pos">
        <router-view :key="$route.fullPath"/>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import AdminSidebar from "./AdminSidebar.vue";
import AdminHeader from "./AdminHeader.vue";
export default {
  components: {AdminHeader, AdminSidebar},
}
</script>
<style scoped>
.app-bg-light-smoke {
  height: 100%;
}

.sidebar-pos {
  position: absolute;
  height: 100%;
}

.header-pos {
  z-index: 2;
}

.content-pos {
  padding-left: 350px;
  padding-right: 100px;
  width: 100%;
  padding-top: 50px;
}
</style>