<template>
  <div>
    <app-preloader v-if="loaded" class="mt-lg-12"/>
  </div>
</template>

<script>
import {routeNames} from "@/router/route-names.js";
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue";

export default {
  components: {AppPreloader},
  data: () => ({
    loaded: true,
  }),
  async created() {
    await this.setAuthToken(this.$route.query.auth_token)
    await this.redirect()
  },
  methods: {
    setAuthToken(token) {
      return this.$store.dispatch('setAuthToken', {
        token,
      })
    },
    async redirect() {
      const routeType = this.$route.query.route_type

      if (routeType === routeNames.insuranceShow) {
        let insuranceId = this.$route.query.insurance_id

        if (insuranceId) {
          await this.$router.push({name: routeNames.insuranceShow, params: {insuranceId: insuranceId.toString()}})

          return
        }

        await this.$router.push({name: routeNames.insuranceList});
      }
      else if (routeType === routeNames.insuranceCreate) {
        await this.$router.push({name: routeNames.insuranceCreate});
      }
      else if (routeType === routeNames.insuranceList) {
        await this.$router.push({name: routeNames.insuranceList});
      }
    }
  },
}
</script>
