<template>
  <div :style="{padding, margin, width}">
    <file-field
        :name="name"
        :placeholder="placeholder"
        :width="width"
        :value="value"
        :validation="fileValidation"
        :is-show-deleting-file-btn="isShowDeletingFileBtn"
        :is-document="true"
        @saveField="saveField"
        @saveFile="saveFile"
        @deleteFile="deleteFile"
        :disabled="disabledBuilderElement"
    />
  </div>
</template>
<script>
import DefaultElement from "./mixins/DefaultElement.js";
import Padding from "./mixins/Padding.js";
import Placeholder from "./mixins/Placeholder.js";
import Size from "./mixins/Size.js";
import FileField from "@/ui/components/common/fields/FileField.vue";
import {builderElementsEvents} from "@/utils/events/builder-elements-events.js";
import Validation from "@/ui/components/builder/elements/mixins/Validation";
import IsShowDeletingFileBtn from "@/ui/components/builder/elements/mixins/IsShowDeletingFileBtn";
import {maxFileSize} from "@/utils/data/file-data";

export default {
  components: {FileField},
  mixins: [DefaultElement, Padding, Placeholder, Size, Validation, IsShowDeletingFileBtn],
  props: {
    disabledBuilderElement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    fileValidation() {
      return {...this.validation, maxSize: maxFileSize}
    }
  },
  methods: {
    saveField(value) {
      this.builderElement.value = value;

      builderElementsEvents.updateValue.emit(this.builderElement);
    },

    saveFile(file) {
      builderElementsEvents.loadFile.emit(this.builderElement, file)
    },

    deleteFile() {
      builderElementsEvents.deleteFile.emit(this.builderElement)
    }
  }
}
</script>