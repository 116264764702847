<template>
  <insurance-packages-wrapper>
    <template slot="left-side">
      <insurance-value-field
          :value="sumInsured"
          :items="sumInsuredLabelList"
          :is-disabled="isDisableInsuranceValueField"
          :is-show-question-tooltip="isShowInsuranceValueQuestionTooltip"
          labelName="Versicherungssumme"
          @changeSumInsured="changeSumInsured"
      />
      <span v-if="isShowNoticeLimitationLiability" class="white--text app-text font-s-12">
        <check-icon v-if="isNoticeLimitationLiability" size="15"/>
        <close-icon v-if="isNotNoticeLimitationLiability" size="15"/>
        Hinweis Haftungsbeschränkung
      </span>

      <insurance-max-value-field
          v-if="!isBabyContract"
          class="pb-6"
          :value="maxSumInsured"
          :items="maxSumInsuredLabelList"
          :is-disabled-max-sum-insured="isDisableMaxSumInsuranceField"
          @changeMaxSumInsured="changeMaxSumInsured"
      />

      <is-higher-maximization-field
          v-if="isHigherMaximization.isShow"
          :value="isHigherMaximization.value"
          @changeIsHigherMaximization="changeIsHigherMaximization"
      />

      <higher-maximization-field
          v-if="higherMaximization.isShow"
          :value="higherMaximization.value"
          @changeHigherMaximization="changeHigherMaximization"
      />
    </template>

    <template slot="center">
      <single-package
          per-year="2.200,50"
          :per-period-price="perPeriodPrice"
          :period="paymentPeriod"
          :insurance-price="insurancePrice.getPrice()"
          :is-npopt="insurancePrice.getIsNpopt()"
          :insurance-full-name="insuranceFullName"
      />
    </template>

    <template slot="right-side">
      <deductible-field
          class="pb-6"
          :value="deductible"
          :items="deductibleLabelList"
          :is-disabled="isDisableDeductibleField"
          @changeDeductible="changeDeductible"
      />

      <duration-field
          class="pb-6"
          :value="duration"
          :items="durationLabelList"
          :is-disabled="isDisableDurationField"
          @changeDuration="changeDuration"
      />

      <payment-period-field
          :value="paymentPeriod"
          :items="paymentPeriodLabelList"
          :is-disabled="isDisablePaymentPeriodField"
          :tooltip="tooltipPaymentPeriod"
          @changePaymentPeriod="changePaymentPeriod"
      />
      <notice-limitation-liability-editor
          v-if="showNoticeLimitationPopup"
          @confirmNoticeLimitation="onConfirmNoticeLimitationPopup"
          @close="onCloseNoticeLimitationPopup"
      />
    </template>
  </insurance-packages-wrapper>
</template>

<script>
import InsurancePackagesWrapper from "@/ui/components/insurance/insurance-wrappers/InsurancePackagesWrapper.vue";
import InsuranceValueField from "@/ui/components/insurance/insurance-fields/InsuranceValueField.vue";
import CheckIcon from "@/ui/components/common/icons/CheckIcon.vue";
import InsuranceMaxValueField from "@/ui/components/insurance/insurance-fields/InsuranceMaxValueField.vue";
import SinglePackage from "@/ui/components/insurance/insurance-packages/SinglePackage.vue";
import DeductibleField from "@/ui/components/insurance/insurance-fields/DeductibleField.vue";
import DurationField from "@/ui/components/insurance/insurance-fields/DurationField.vue";
import PaymentPeriodField from "@/ui/components/insurance/insurance-fields/PaymentPeriodField.vue";
import {arrayService} from "@/utils/services/array-service.js";
import {INSURANCE_PRICE_LIST_FIELD_NAMES, INSURANCE_PRICE_DATA_KEYS, INSURANCE_PRICE_PAYMENT_PERIOD_LABELS, PERIOD_VALUES} from "@/utils/fields/insurance-price-list-field-names.js";
import CloseIcon from "@/ui/components/common/icons/CloseIcon.vue";
import NoticeLimitationLiabilityEditor from "@/ui/components/insurance/insurance-prices/blocks/NoticeLimitationLiabilityEditor.vue";
import HigherMaximizationField from "@/ui/components/insurance/insurance-fields/HigherMaximizationField.vue";
import IsHigherMaximizationField from "@/ui/components/insurance/insurance-fields/IsHigherMaximizationField.vue";
import {insuranceStepService} from "@/utils/services/insurance-step-service.js";
import {insuranceStepEnum} from "@/enums/insurance-step-enum.js";
import {maxValPaymentPeriod} from "@/utils/data/insurance-price.js";
import {capitalizeFirstLetter} from "@/utils/helpers.js";
import {moneyService} from "@/utils/services/money-service.js";
import {InsurancePrice} from "@/models/insurance-price.js"
import {insuranceTypeEnum, financialInvestmentsPriceBlockName} from "@/enums/insurance-type-enum";

export default {
  components: {
    InsurancePackagesWrapper,
    InsuranceValueField,
    CheckIcon,
    InsuranceMaxValueField,
    SinglePackage,
    DeductibleField,
    DurationField,
    PaymentPeriodField,
    CloseIcon,
    NoticeLimitationLiabilityEditor,
    HigherMaximizationField,
    IsHigherMaximizationField,
  },
  props: {
    insurancePrice: {
      type: InsurancePrice,
      required: true,
    },
    insuranceId: {
      type: Number,
      required: true,
    },
    disableEditRightSide: {
      type: Boolean,
      required: false,
      default: false
    },
    isDisableAllInputs: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisableMaxSumInsuredByCondition: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isShowInsuranceValueQuestionTooltip() {
      return this.insuranceType.key !== insuranceTypeEnum.FINANCIAL_INVESTMENTS;
    },
    insuranceType() {
      return this.$store.getters['insuranceModule/getInsuranceType'];
    },
    duration() {
      return this.insurancePrice.getDurationLabel();
    },
    paymentPeriod() {
      return this.insurancePrice.getPaymentPeriodLabel();
    },
    deductible() {
      return this.insurancePrice.getDeductibleLabel();
    },
    durationList() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getPriceListsByKeyWithId"](this.insurancePrice.id, INSURANCE_PRICE_LIST_FIELD_NAMES.duration);
    },
    deductibleList() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getPriceListsByKeyWithId"](this.insurancePrice.id, INSURANCE_PRICE_LIST_FIELD_NAMES.deductible);
    },
    sumInsuredList() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getPriceListsByKeyWithId"](this.insurancePrice.id, INSURANCE_PRICE_LIST_FIELD_NAMES.sumInsured);
    },
    maxSumInsuredList() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getPriceListsByKeyWithId"](this.insurancePrice.id, INSURANCE_PRICE_LIST_FIELD_NAMES.maxSumInsured);
    },
    paymentPeriodList() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getPriceListsByKeyWithId"](this.insurancePrice.id, INSURANCE_PRICE_LIST_FIELD_NAMES.paymentPeriod);
    },
    durationLabelList() {
      return this.durationList;
    },
    deductibleLabelList() {
      return moneyService.optionsToIntegerMoneyFormat(this.deductibleList);
    },
    sumInsuredLabelList() {
      return moneyService.optionsToMoneyFormat(this.sumInsuredList);
    },
    maxSumInsuredLabelList() {
      return this.maxSumInsuredList;
    },
    paymentPeriodLabelList() {
      return this.getOptionsForPaymentPeriod()
    },
    isNotNoticeLimitationLiability() {
      return this.isNoticeLimitationLiability === false
    },
    perPeriodPrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getPerPeriodPriceByPriceId"](this.insurancePrice.getId());
    },
    higherMaximizationList() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getPriceListsByKey"](INSURANCE_PRICE_LIST_FIELD_NAMES.higherMaximization);
    },
    stepType() {
      return insuranceStepEnum.CUSTOMER
    },
    insuranceBuilders() {
      return this.$store.getters["insuranceModule/insuranceBuildersModule/getInsuranceStepBuilders"](this.stepType);
    },
    activeInsuranceBuilder() {
      return insuranceStepService.getActiveInsuranceBuilder(this.insuranceBuilders)
    },
    isBabyContract() {
      return Boolean(this.$store.getters["insuranceModule/insurancePriceModule/getIsBabyContract"]);
    },
    insuranceFullName() {
      return `${this.insuranceTypeName}<br> ${this.insurancePriceName}`;
    },
    isDisableInsuranceValueField() {
      return this.isDisableAllInputs || this.isBabyContract
    },
    isDisableMaxSumInsuranceField() {
      return this.isDisableAllInputs || this.maxSumInsuredDisabled || this.isDisableMaxSumInsuredByCondition
    },
    isDisableDeductibleField() {
      return this.isDisableAllInputs || this.isBabyContract || this.disableEditRightSide
    },
    isDisableDurationField() {
      return this.isDisableAllInputs || this.isBabyContract || this.disableEditRightSide
    },
    isDisablePaymentPeriodField() {
      return this.isDisableAllInputs || this.isBabyContract || this.disableEditRightSide
    },
    insuranceTypeName() {
      if (this.insuranceType.key === insuranceTypeEnum.FINANCIAL_INVESTMENTS || this.insuranceType.key === insuranceTypeEnum.REAL_ESTATE_SERVICES) {
        return financialInvestmentsPriceBlockName
      }

      return this.insuranceType.name
    },
    insurancePriceName() {
      if (this.insuranceType.key === insuranceTypeEnum.REAL_ESTATE_SERVICES) {
        return this.insurancePrice.getName().replaceAll(', ', ',<br>')
      }

      return this.insurancePrice.getName()

    },
    isShowNoticeLimitationLiability() {
      return !this.isBabyContract &&
        this.insuranceType.key !== insuranceTypeEnum.REAL_ESTATE_SERVICES
    },
  },
  watch: {
    insurancePrice(newValue) {
      if (this.compareNewInsurancePriceValue(newValue)) {
        this.fillData();
      }
    }
  },
  data: () => ({
    sumInsured: '',
    priceData: {},
    maxSumInsured: '',
    isNoticeLimitationLiability: null,
    showNoticeLimitationPopup: false,
    higherMaximization: {},
    isHigherMaximization: {},
    maxSumInsuredDisabled: false,
    tooltipPaymentPeriod: 'Im Falle einer gewünschten unterjährigen Zahlungsweise kann nur das SEPA-Lastschriftmandat als Zahlungsart angeboten werden.',
  }),
  async created() {
    this.fillData()
  },
  methods: {
    fillData() {
      this.sumInsured = this.insurancePrice.getSumInsuredLabel()
      this.maxSumInsured = this.insurancePrice.getMaxSumInsuredLabel()
      this.priceData = this.insurancePrice.getData()

      this.isNoticeLimitationLiability = this.priceData.isNoticeLimitationLiability

      this.higherMaximization = this.insurancePrice.getHigherMaximization()

      this.isHigherMaximization = this.insurancePrice.getIsHigherMaximization()
      this.maxSumInsuredDisabled = !this.insurancePrice.getEditableByFieldKey(INSURANCE_PRICE_LIST_FIELD_NAMES.maxSumInsured)

      this.fillPaymentPeriod()
    },
    compareNewInsurancePriceValue(newInsurancePrice) {
      return newInsurancePrice.getSumInsuredLabel() !== this.sumInsured ||
          newInsurancePrice.getMaxSumInsuredLabel() !== this.maxSumInsured
    },
    async getInsurancePrice(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/getInsurancePriceByInsuranceId', {
        insuranceId,
      })
    },
    async changeSumInsured(val) {
      this.setPriceDataElem(INSURANCE_PRICE_LIST_FIELD_NAMES.sumInsured, val)

      this.$emit('setPriceData', this.priceData)

      if (this.isNoticeLimitationLiability === null) {
        this.showNoticeLimitationPopup = true
      }

      this.maxSumInsured = this.insurancePrice.getMaxSumInsuredLabel()
    },
    async changeDuration(val) {
      this.setPriceDataElem(INSURANCE_PRICE_LIST_FIELD_NAMES.duration, val)

      this.$emit('setPriceData', this.priceData)

      this.fillPaymentPeriod()
    },
    async changeDeductible(val) {
      this.setPriceDataElem(INSURANCE_PRICE_LIST_FIELD_NAMES.deductible, val)

      this.$emit('setPriceData', this.priceData)
    },
    async changePaymentPeriod(val) {
      this.setPriceDataElem(INSURANCE_PRICE_LIST_FIELD_NAMES.paymentPeriod, val)

      this.$emit('setPriceData', this.priceData)

      await this.updatePaymentPeriodBuilderElement(this.insuranceId, val)
    },
    async changeMaxSumInsured(val) {
      this.setPriceDataElem(INSURANCE_PRICE_LIST_FIELD_NAMES.maxSumInsured, val)

      this.$emit('setPriceData', this.priceData)
    },
    async changeHigherMaximization(val) {
      this.setPriceDataElem(INSURANCE_PRICE_LIST_FIELD_NAMES.higherMaximization, {value: val, isShow: true})

      this.$emit('setPriceData', this.priceData)
    },
    async changeIsHigherMaximization(val) {
      this.setPriceDataElem(INSURANCE_PRICE_LIST_FIELD_NAMES.isHigherMaximization, {value: val, isShow: true})
      this.$emit('setPriceData', this.priceData)
    },
    findFieldByValue(arr, val) {
      return arrayService.findObjectByLabel(arr, val)
    },
    async updateInsurancePrice(insurancePriceId, requestData){
      await this.$store.dispatch('insuranceModule/insurancePriceModule/updatePrice', {
        insurancePriceId,
        requestData
      })
    },
    setPriceDataElem(key, val) {
      this.priceData[key] = val
    },
    onCloseNoticeLimitationPopup() {
      this.setPriceDataElem(INSURANCE_PRICE_DATA_KEYS.isNoticeLimitationLiability, false)

      this.$emit('setPriceData', this.priceData)

      this.showNoticeLimitationPopup = false

      this.isNoticeLimitationLiability = false
    },
    onConfirmNoticeLimitationPopup() {
      this.setPriceDataElem(INSURANCE_PRICE_DATA_KEYS.isNoticeLimitationLiability, true)

      this.$emit('setPriceData', this.priceData)

      this.showNoticeLimitationPopup = false

      this.isNoticeLimitationLiability = true
    },
    async calculatePrice(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/calculatePrice', {
        insuranceId,
      })
    },
    async updatePaymentPeriodBuilderElement(insuranceId, value) {
      await this.$store.dispatch('insuranceModule/builderElementsModule/updatePaymentPeriodBuilderElement', {
        insuranceId,
        value,
      })

      await this.resetBuilder()
    },
    async resetBuilder() {
      await this.$store.dispatch('insuranceModule/insuranceBuildersModule/resetInsuranceBuilders', {
        insuranceBuilder: this.activeInsuranceBuilder,
      })
    },
    getOptionsForPaymentPeriod() {
      const price = this.insurancePrice.getPrice()

      const periods = []

      this.paymentPeriodList.forEach(period => {
        const newPeriodObj = {text: capitalizeFirstLetter(period), value: period, disabled: false}

        const periodVal = PERIOD_VALUES[period]

        if (price / periodVal < maxValPaymentPeriod) {
          newPeriodObj.disabled = true
        }

        periods.push(newPeriodObj)
      })

      return periods
    },
    fillPaymentPeriod() {
      if (!this.insurancePrice.getPrice()) {
        return
      }

      if (this.perPeriodPrice < maxValPaymentPeriod) {
        this.changePaymentPeriod(INSURANCE_PRICE_PAYMENT_PERIOD_LABELS.year)
      }
    },
  },
}
</script>