import {objectService} from "@/utils/services/object-service.js";
import {Builder} from "@/models/builder.js";
import {insuranceBuilderStatusEnum} from "@/enums/insurance-builder-status-enum.js";
import {insuranceStepEnum} from "@/enums/insurance-step-enum.js";

export class InsuranceBuilder {
    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(InsuranceBuilder.empty().toObject())
            : this.fillData(data)
    }

    fillData(data) {
        this.id = data.id;
        this.status = data.status;
        this.name = data.name;
        this.priority = data.priority;
        this.step_type = data.step_type
        this.builder = new Builder(data.builder)
    }

    isActiveBuilder() {
        return this.status === insuranceBuilderStatusEnum.ACTIVE
    }

    isEmptyPrice() {
        return this.step_type === insuranceStepEnum.EMPTY_PRICE
    }

    isSelectedPrice() {
        return this.step_type === insuranceStepEnum.PRICE
    }

    static empty(data = {}) {
        return new InsuranceBuilder({
            id: data.id || 0,
            status: data.status || '',
            name: data.name || '',
            step_type: data.step_type || '',
            priority: data.priority || 0,
            builder: data.builder ? data.builder : Builder.empty(data.builder).toObject()
        })
    }

    toObject() {
        return {
            ...this
        }
    }
}