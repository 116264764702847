<template>
  <v-dialog
      :width="width"
      v-model="popup"
      @click:outside="close"
  >
    <v-card
        :min-height="minHeight"
        :color="background"
        class="pb-4"
    >
      <v-card-title>
        <span class="date-popup-header">{{ header }}</span>
        <v-spacer></v-spacer>

        <v-icon v-if="isShowCloseButton"
            class="cursor-pointer close-icon__animation"
            @click="close"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="pl-6">
        <slot/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: '650px',
    },
    header: {
      type: String,
      default: 'Popup'
    },
    background: {
      type: String,
      default: 'white',
    },
    minHeight: {
      type: String,
      default: 'auto'
    },
    isShowCloseButton: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    popup: true,
  }),
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
<style scoped>
.date-popup-header {
  word-break: break-word;
}
</style>