<template>
  <div>
    <checkbox
        text="Is Disabled"
        class="app-text__bold"
        :value="value"
        @saveField="saveIsDisabled"
    />
  </div>
</template>

<script>
import Checkbox from "@/ui/components/common/fields/Checkbox.vue";
import {fieldService} from "@/utils/services/field-service.js";

export default {
  components: {Checkbox},
  props: {
    value: {
      type: String,
      default: '0',
    }
  },
  methods: {
    saveIsDisabled(value) {
      this.$emit('input', fieldService.selectedFieldValue(value))
      this.$emit('saveName', fieldService.selectedFieldValue(value));
    }
  }
}
</script>