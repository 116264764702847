<template>
  <div>
    <div
        id="is-higher-maximization-checkbox"
        class="d-flex"
    >
      <v-checkbox
          background-color="transparent"
          class="app-checkbox"
          :ripple="false"
          color="#fff"
          off-icon="mdi-checkbox-blank-outline"
          on-icon="mdi-checkbox-intermediate"
          :value="value"
          :input-value="value"
          @change="saveField"
      />
      <div class="mt-1" v-html="text"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'Höhere Maximierung gewünscht?',
    },
  },
  methods: {
    saveField(val) {
      this.$emit('changeIsHigherMaximization', val)
    }
  },
}
</script>
