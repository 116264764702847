import {ExpiringToken} from "@/models/expiring-token.js";
import {serverApi} from "@/api/server/server-api.js";

export const expiringTokenModule = {
    namespaced: true,
    state: {
        expiringToken: ExpiringToken.createEmpty()
    },
    getters: {
        getExpiringToken: (state) => state.expiringToken,
    },
    mutations: {
        setExpiringToken: (state, data) => {
            state.expiringToken = new ExpiringToken(data)
        },
    },
    actions: {
        getExpiringTokenByPoliceId: async (context, {policeId}) => {
            const {data} = await serverApi.expiringToken.getByPoliceId(policeId)

            if (data.success) {
                context.commit('setExpiringToken', data.expiringToken)

                return new ExpiringToken(data.expiringToken);
            }

            return ExpiringToken.createEmpty();
        },
        checkExpiringDate: async (context, {token}) => {
            const {data} = await serverApi.expiringToken.checkExpiringDate(token)

            if (data.success) {
                return data.isTokenValid;
            }

            return false;
        },
    }
}