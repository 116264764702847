<template>
  <div>
    <builder-component
        :disabled-all-inputs="disabledAllInputs"
        :builder="builderComputed"
    />
  </div>
</template>
<script>
import {Builder} from "@/models/builder.js";
import {builderElementsEvents} from "@/utils/events/builder-elements-events.js";
import {builderElementService} from "@/utils/services/builder-element-service.js";
import {notificationService} from "@/utils/services/notification-service.js";
import BuilderComponent from "@/ui/components/builder/Builder.vue";
import {debounce} from "@/utils/functions.js";
import {noTimeoutBuilderElements} from "@/enums/builder-element-enum.js";
import {insuranceStepService} from "@/utils/services/insurance-step-service";
import {localStorageService} from "@/utils/services/local-storage-service";
import {insuranceStepEnum} from "@/enums/insurance-step-enum";

export default {
  components: {BuilderComponent},
  props: {
    builder: {
      type: Builder,
      required: true,
    },
    stepType: {
      type: String,
      required: false,
    },
    disabledAllInputs: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    builderComputed() {
      return this.builderData
    }
  },
  watch: {
    builder(value) {
      this.builderData = value;
    }
  },
  data() {
    return {
      builderData: this.builder
    }
  },
  async created() {
    if (this.stepType === insuranceStepEnum.CUSTOMER) {
      await this.resetInsuranceBuilders(this.activeInsuranceBuilder(this.stepType));
      await this.runBuilderRelations();

      this.builderData = this.activeInsuranceBuilder(this.stepType).builder;
    }
  },
  async mounted() {
    await this.resetBuilderElementsData();

    await this.runBuilderRelations();

    builderElementsEvents.updateValue.on(this.onElementValueUpdate)
    builderElementsEvents.loadFile.on(this.onLoadFile)
    builderElementsEvents.deleteFile.on(this.onDeleteFile)
    builderElementsEvents.clickDuplicate.on(this.onClickDuplicate)
    builderElementsEvents.removeDuplicate.on(this.onClickRemoveDuplicate)
    builderElementsEvents.repeatByElement.on(this.onRepeatByElement);
    builderElementsEvents.setRepeatedValue.on(this.onSetRepeatedValue);
    builderElementsEvents.repeatByCheckboxes.on(this.onRepeatByCheckboxes);
    builderElementsEvents.linkFields.on(this.onLinkFields);
    builderElementsEvents.reloadBuilders.on(this.onReloadBuilders);
  },
  async beforeDestroy() {
    await Promise.all([
      this.updateElementValues(),
      this.updateElementVisibility(),
    ]);

    builderElementsEvents.updateValue.off(this.onElementValueUpdate)
    builderElementsEvents.loadFile.off(this.onLoadFile)
    builderElementsEvents.deleteFile.off(this.onDeleteFile)
    builderElementsEvents.clickDuplicate.off(this.onClickDuplicate)
    builderElementsEvents.removeDuplicate.off(this.onClickRemoveDuplicate);
    builderElementsEvents.repeatByElement.off(this.onRepeatByElement);
    builderElementsEvents.setRepeatedValue.off(this.onSetRepeatedValue);
    builderElementsEvents.repeatByCheckboxes.off(this.onRepeatByCheckboxes);
    builderElementsEvents.linkFields.off(this.onLinkFields);
    builderElementsEvents.reloadBuilders.off(this.onReloadBuilders);
  },
  methods: {
    showProcessPreloader() {
      this.$store.commit('insuranceModule/showProcessPreloader');
    },
    hideProcessPreloader() {
      this.$store.commit('insuranceModule/hideProcessPreloader');
    },
    getRelationTimeoutTime(elementType) {
      return noTimeoutBuilderElements.includes(elementType) ? 0 : 1200
    },

    async onElementValueUpdate(element) {
      this.showProcessPreloader();

      await this.saveElement(element);

      //reduce javascript execution
      debounce(
          async () => {
            // todo: test, temporary commented
            // this.showProcessPreloader();

            await this.runRelationsByField(element)
            this.emitRepeatValue(element)

            // this.hideProcessPreloader();
          },
          element.id,
          this.getRelationTimeoutTime(element.type), //if given element type inside "no timeout list" we immediately run relations
      )

      this.updateDuplicateElementValue(element)

      this.hideProcessPreloader();
    },

    async onClickDuplicate(id) {
      this.showProcessPreloader();

      const element = builderElementService.findElementById(this.builder.elements, id);

      if (element) {
        await this.duplicate(id);

        await Promise.all([
          this.updateElementValues(),
          this.updateElementVisibility(),
        ]);

        // todo: test, temporary replaced
        //this.$emit('resetBuilder');
        this.$emit('resetBuilderWithoutRelations');
      } else {
        notificationService.error("Can't find Element for duplication")
      }
    },

    async onClickRemoveDuplicate(id) {
      this.showProcessPreloader();

      const element = builderElementService.findElementById(this.builder.elements, id);

      if (element) {
        await this.removeDuplicate(id)

        // todo: test, temporary replaced
        //this.$emit('resetBuilder');
        this.$emit('resetBuilderWithoutRelations');
      } else {
        notificationService.error("Can't find element for deletion")
      }
    },

    emitRepeatValue(element) {
      if (element.data.is_repeat_value_active) {
        builderElementsEvents.setRepeatedValue.emit(element);
      }
    },

    async onRepeatByElement(element) {
      if (element.data.repeat_element_id && element.value && !isNaN(element.value)) {
        this.showProcessPreloader();

        await this.$store.dispatch('insuranceModule/builderElementsModule/repeatByBlock', {
          relationId: element.data.relation_id,
          activeBuilderElementId: element.id,
          passiveBuilderElementId: element.data.repeat_element_id,
          amount: element.value
        }, {root: true})

        await Promise.all([
          this.updateElementValues(),
          this.updateElementVisibility(),
        ]);

        this.hideProcessPreloader();
        // Commented because when we have 2 or more duplicate_block and change example number field duplicate_block1,
        // and then we change number_field duplicate_block2 calling this function happen after some time(~12sec),
        // so if user go next tab he will not see generated repeated block
        //this.$emit('resetBuilders');
      }
    },

    async onLinkFields() {
      await this.updateElementValues();
    },

    async onReloadBuilders() {
      this.showProcessPreloader();

      await Promise.all([
        this.updateElementValues(),
        this.updateElementVisibility(),
      ]);

      this.$emit('resetBuilderWithoutRelations');
    },

    async onRepeatByCheckboxes(element) {
      if (
          element.data.repeat_by_checkboxes &&
          element.data.repeat_by_checkboxes_count > 0 &&
          !element.data.is_repeated_by_checkboxes
      ) {
        this.showProcessPreloader();

        await this.$store.dispatch('insuranceModule/builderElementsModule/repeatByCheckboxes', {
          repeatElementId: element.id,
          changeTitleElementId: element.data.repeat_by_checkbox_label_id,
          checkboxElementIds: element.data.repeat_by_checkbox_ids,
          originalTitle: element.data.original_title,
          maxSelectedCheckboxesCount: element.data.max_selected_checkboxes_count
        }, {root: true});

        await Promise.all([
          this.updateElementValues(),
          this.updateElementVisibility(),
        ]);

        this.$emit('resetBuilders');
      }
    },

    async onSetRepeatedValue(element) {
      if (element.data.set_value_repeat_element_id !== 'random') {
        this.showProcessPreloader();

        await this.$store.dispatch('insuranceModule/builderElementsModule/repeatValue', {
          activeBuilderElementId: element.id,
          passiveBuilderElementId: element.data.set_value_repeat_element_id,
          elementValue: element.value,
        }, {root: true})

        await Promise.all([
          this.updateElementValues(),
          this.updateElementVisibility(),
        ]);

        // todo: test, temporary uncommented
        this.$emit('resetBuilders');

        this.hideProcessPreloader();
      }
    },

    async onLoadFile({element, file}) {
      await this.uploadFile(element.id, file);
    },

    async onDeleteFile({element}) {
      await this.deleteFile(element.id);
    },

    async resetBuilderElementsData() {
      await this.$store.dispatch('insuranceModule/builderElementsModule/resetBuilderElementsData');
    },

    async saveElement(builderElement) {
      await this.$store.dispatch('insuranceModule/builderElementsModule/saveElement', {builderElement})
    },

    async updateElementValues() {
      await this.$store.dispatch('insuranceModule/builderElementsModule/updateValues')
    },

    async updateElementVisibility() {
      await this.$store.dispatch('insuranceModule/builderElementsModule/updateVisibility')
    },

    async uploadFile(builderElementId, file) {
      await this.$store.dispatch('insuranceModule/builderElementsModule/uploadFile', {
        builderElementId,
        file,
      })
    },

    async deleteFile(builderElementId) {
      await this.$store.dispatch('insuranceModule/builderElementsModule/deleteFile', {
        builderElementId,
      })
    },

    async runBuilderRelations(definedActions = null) {
      await this.$store.dispatch('insuranceModule/builderElementRelationsModule/runRelations', {
        definedActions
      })
    },

    async runRelationsByField(builderElement) {
      await this.$store.dispatch('insuranceModule/builderElementRelationsModule/runRelationsByField', {
        builderElement,
      })
    },

    duplicate(builderElementId) {
      return this.$store.dispatch('insuranceModule/builderElementsModule/duplicate', {
        builderElementId
      })
    },

    removeDuplicate(builderElementId) {
      return this.$store.dispatch('insuranceModule/builderElementsModule/removeDuplicate', {
        builderElementId
      })
    },

    updateDuplicateElementValue(builderElement) {
      const htmlElement = document.querySelector(`[data-type-builder='duplicate'] [data-id='${builderElement.id}']`)

      if (!htmlElement) {
        return
      }

      const builderId = builderElement.id;
      const builderData = {value: builderElement.value};

      this.$store.dispatch('insuranceModule/builderElementsModule/updateValue', {builderId, builderData})
    },

    insuranceBuilders(stepType) {
      return this.$store.getters["insuranceModule/insuranceBuildersModule/getInsuranceStepBuilders"](stepType);
    },

    activeInsuranceBuilder(stepType) {
      const insuranceBuilders = this.insuranceBuilders(stepType);
      const insuranceBuilder = insuranceStepService.getActiveInsuranceBuilder(insuranceBuilders)

      return this.getInsuranceBuilderWithElementsToHide(insuranceBuilder)
    },

    async resetInsuranceBuilders(insuranceBuilder) {
      await this.$store.dispatch('insuranceModule/insuranceBuildersModule/resetInsuranceBuilders', {
        insuranceBuilder,
      })
    },

    getInsuranceBuilderWithElementsToHide(insuranceBuilder) {
      const elementIdsToHide = localStorageService.builderElement.getElementIdsToHide();
      let copiedElements = [];

      if (!localStorageService.builderElement.isEmptyCopiedElementIdsNpopt()) {
        const copiedElementIds = localStorageService.builderElement.getCopiedElementIdsNpopt();

        copiedElements = this.$store.getters['insuranceModule/builderElementsModule/getCopiedElements'](copiedElementIds);
      } else {
        copiedElements = insuranceBuilder.builder.elements

        localStorageService.builderElement.setCopiedElementIdsNpopt(copiedElements.map(itm => itm.id));
      }

      insuranceBuilder.builder.elements = copiedElements.filter(el => {
        return !elementIdsToHide.includes(el.id)
      })

      return insuranceBuilder;
    },
  }
}
</script>