<template>
  <v-btn
      @click="onClick"
      :height="height"
      :width="width"
      class="app-btn white--text"
      outlined
      rounded
  >
    <slot/>
  </v-btn>
</template>
<script>
export default {
  props: {
    height: {
      type: String,
      default: '35px',
    },
    width: {
      type: String,
      default: 'auto'
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
}
</script>
<style scoped>

.app-btn {
  background: var(--red);
  border: none;
  box-shadow: none;
}
</style>