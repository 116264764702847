<template>
  <v-icon
      @click="$emit('click')"
      class="cursor-pointer rounded-circle px-1 py-1"
      color="grey darken-1"
      :disabled="disabled"
      :size="size"
      v-ripple="false"
  >
    mdi-plus-circle
  </v-icon>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: '30px'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  }
}
</script>