import { render, staticRenderFns } from "./SingleObjectInsurancePrice.vue?vue&type=template&id=939a4a32&scoped=true&"
import script from "./SingleObjectInsurancePrice.vue?vue&type=script&lang=js&"
export * from "./SingleObjectInsurancePrice.vue?vue&type=script&lang=js&"
import style0 from "./SingleObjectInsurancePrice.vue?vue&type=style&index=0&id=939a4a32&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "939a4a32",
  null
  
)

export default component.exports