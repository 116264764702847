const SELECTED = '1';
const UNSELECTED = '0';

export const fieldService = {
    isFieldSelected(value) {
        return value === SELECTED || value === true;
    },

    selectedFieldValue(value) {
        return this.isFieldSelected(value) ? SELECTED : UNSELECTED
    }
}