const selected = 'element-selected';
const hover = 'element-hovered';

const borderClasses = [selected, hover];

const markWith = (ref, selector, className)  => {
    const styleBlock = ref.querySelector(selector)

    if(styleBlock) {
        const classes = (styleBlock.getAttribute('class') || '').split(' ')
            .filter((e) => !borderClasses.includes(e))

        classes.push(className);

        styleBlock.setAttribute('class', classes.join(' '));
    }
}

export const builderQuerySelectors = {
    styleBlock: 'style-block',
    elementBlock: 'builder-element',

    styleBlockClass: '.style-block',
    elementBlockClass: '.builder-element',

    idAttribute: 'data-id',

    findClosestElement(event) {
        const ref = event.target.closest(this.elementBlockClass);

        if(ref) {
            const builderElementId = ref.getAttribute(this.idAttribute);

            return {ref, builderElementId}
        }

        return {ref, builderElementId: ''}
    },

    findByAttribute(id) {
        let matchedRef = null;

        document.querySelectorAll(this.elementBlockClass ).forEach((ref) => {
            if (ref.getAttribute(this.idAttribute) === id+"") {
                matchedRef = ref;
            }
        })

        return {ref: matchedRef};
    },

    emptyAllExcept(selectedId) {
        let selectedRef = null;

        document.querySelectorAll(this.elementBlockClass).forEach((ref) => {
            if (selectedId+"" === ref.getAttribute(this.idAttribute)) {
                selectedRef = ref;
            } else {
                this.markEmpty(ref);
            }
        })

        return {selectedRef, selectedId}
    },

    markSelected(ref) {
        markWith(ref, this.styleBlockClass, selected);
    },

    markEmpty(ref) {
        markWith(ref, this.styleBlockClass, '');
    },

    markHovered(ref) {
        markWith(ref, this.styleBlockClass, hover);
    },
    scrollToValidation() {
        const errMsgSelector = document.querySelector(".v-messages.error--text:first-of-type")

        if (!errMsgSelector) {
            document.querySelector(".v-messages.error--text").closest(".app-block").scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })
        } else {
            errMsgSelector.closest(".app-block").scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })
        }
    },
}