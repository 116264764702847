<template>
  <div>
    <div class="text-center">
      <h3>Repeated block</h3>
    </div>

    <v-row>

      <v-col cols="4">
        <name-editor
            v-model="builderElement.name"
        />
      </v-col>

      <v-col class="pl-4" cols="4">
        <priority-editor
            v-model.number="builderElement.priority"
        />
      </v-col>

      <v-col class="pl-4" cols="4">
        <background-editor
            v-model="builderElement.data.background"
        />
      </v-col>

      <v-col cols="6">
        <height-editor
            v-model="builderElement.data.height"
        />
      </v-col>

      <v-col class="pl-4">
        <width-editor
            v-model="builderElement.data.width"
        />
      </v-col>
    </v-row>

    <margin-editor
        v-model="builderElement.data.margin"
    />

    <padding-editor
        class="mt-6"
        v-model="builderElement.data.padding"
    />

    <css-class-editor
        class="mt-6"
        v-model="builderElement.data.css_class"
    />

    <v-col>
      <is-repeat-generator-editor
          v-model="builderElement.data.is_repeat"
      />
    </v-col>
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import PaddingEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PaddingEditor.vue";
import MarginEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MarginEditor.vue";
import BackgroundEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/BackgroundEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import HeightEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/HeightEditor.vue";
import WidthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/WidthEditor.vue";
import CssClassEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/CssClassEditor";
import IsRepeatGeneratorEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/IsRepeatGeneratorEditor";

export default {
  components: {WidthEditor, HeightEditor, NameEditor, PriorityEditor, BackgroundEditor, MarginEditor, PaddingEditor, CssClassEditor, IsRepeatGeneratorEditor},
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  }
}
</script>