<template>
  <div>
    <div class="text-center">
      <h3>Number Field</h3>
    </div>

    <v-row>
      <v-col cols="12">
        <name-editor
            v-model="builderElement.name"
        />
      </v-col>

      <v-col cols="6">
        <priority-editor
            v-model.number="builderElement.priority"
        />
      </v-col>

      <v-col class="pl-4" cols="6">
        <width-editor
            v-model="builderElement.data.width"
        />
      </v-col>

      <v-col cols="6">
        <p class="app-text__bold">Default Value</p>
        <number-field
            name="value"
            v-model="builderElement.value"
        />
      </v-col>

      <v-col cols="6" class="pl-4">
        <placeholder-editor
            v-model="builderElement.data.placeholder"
        />
      </v-col>
      <v-col class="pl-4" cols="6">
        <is-integer-editor
            v-model="builderElement.data.isInteger"
        />
      </v-col>
      <v-col class="pl-4" cols="6">
        <is-hide-spin-buttons-editor
          v-model="builderElement.data.isHideSpinButtons"
        />
      </v-col>
      <v-col cols="12">
        <toggle-block
            class="my-2 pb-2"
            header="Validation"
            opened
        >
          <v-row>
            <v-col cols="12" class="pl-4">
              <is-required
                  v-model="builderElement.validation.required"
              />
            </v-col>
            <v-col cols="6">
              <min-value-editor
                  v-model="builderElement.validation.minValue"
              />
            </v-col>

            <v-col cols="12">
              <validation-message-editor
                  v-model="builderElement.validation.message"
              />
            </v-col>
          </v-row>
        </toggle-block>
      </v-col>
    </v-row>

    <margin-editor
        v-model="builderElement.data.margin"
    />

    <is-repeat-generator-editor
        v-model="builderElement.data.isRepeatGenerator"
    />
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import WidthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/WidthEditor.vue";
import PlaceholderEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PlaceholderEditor.vue";
import MarginEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MarginEditor.vue";
import NumberField from "@/ui/components/common/fields/NumberField.vue";
import ToggleBlock from "@/ui/components/common/blocks/ToggleBlock.vue";
import MinValueEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MinValueEdtior.vue";
import ValidationMessageEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/ValidationMessageEditor.vue";
import IsIntegerEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/IsIntegerEditor.vue";
import IsRepeatGeneratorEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/IsRepeatGeneratorEditor.vue";
import IsRequired from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/isRequired.vue";
import IsHideSpinButtonsEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/IsHideSpinButtonsEditor.vue";

export default {
  components: {
    IsRequired,
    ValidationMessageEditor,
    MinValueEditor,
    ToggleBlock, NumberField, MarginEditor, PlaceholderEditor, WidthEditor, PriorityEditor, NameEditor,
    IsIntegerEditor,
    IsRepeatGeneratorEditor,
    IsHideSpinButtonsEditor,
  },
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  }
}
</script>