<template>
  <div>
    <div class="d-flex justify-end">
      <blue-btn v-if="builderElement.type" @click="onClickCreate">
        Create Element
      </blue-btn>
    </div>

    <v-form ref="create_json" class="mt-4">
      <p class="app-text__bold">Select Element Type To Add New Element</p>

      <searchable-select-field
          name="type"
          :items="jsonElementTypes"
          :validation="{required: true}"
          v-model="builderElement.type"
      />

      <p class="app-text__bold">Parent</p>

      <searchable-select-field
          name="parent_id"
          v-model="builderElement.parent_id"
          :items="onlyParentElements"
          clearable
      />

    </v-form>

  </div>
</template>
<script>
import {jsonElementEnum} from "@/enums/json-element-enum.js";
import {BuilderElement} from "@/models/builder-element.js";
import SearchableSelectField from "@/ui/components/common/fields/SearchableSelectField.vue";
import {builderEditorEvents} from "@/utils/events/builder-editor-events.js";
import {jsonElementService} from "@/utils/services/json-element-service.js";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";

export default {
  components: {BlueBtn, SearchableSelectField},
  props: {
    elements: {
      type: Array,
      required: true,
    }
  },
  computed: {
    form() {
      return this.$refs['create_json'];
    },

    jsonElementTypes() {
      return Object.values(jsonElementEnum);
    },

    onlyParentElements() {
      return jsonElementService.onlyParents(this.elements).map((elem) => ({
        text: elem.name,
        value: elem.id,
      }))
    }
  },

  data: () => ({
    builderElement: BuilderElement.empty({
      id: Math.random(),
      name: 'NEW_ELEMENT',
      priority: 1,
    }),
  }),

  methods: {
    onClickCreate() {
      builderEditorEvents.createElement.emit(this.builderElement)
    },
  }
}
</script>