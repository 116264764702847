import {objectService} from "@/utils/services/object-service.js";
import {Builder} from "@/models/builder.js";

export class InsuranceApi {
    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(InsuranceApi.empty().toObject())
            : this.fillData(data)
    }

    fillData(data) {
        this.id = data.id;
        this.key = data.key;
        this.builder = new Builder(data.builder);
        this.insurance_type_id = data.insurance_type_id;
    }

    static empty(data = {}) {
        return new InsuranceApi({
            id: data.id || 0,
            builder: data.builder ? data.builder : Builder.empty(data.builder),
            insurance_type_id: data.insurance_type_id || 0,
            key: data.key || '',
        })
    }

    toObject() {
        return {
            ...this
        }
    }
}