<template>
  <v-row justify="center">
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        persistent
    >
      <v-toolbar
          absolute
          dark
          color="blue-grey darken-2"
      >
        <span class="text-h4 text--white">Price Addon Relations</span>

        <v-spacer/>

        <white-btn height="80%" @click="onClickClose">Close</white-btn>
      </v-toolbar>
      <v-card
          color="var(--smoke)"
      >
        <v-row class="element-relations__section">
          <v-col cols="8" class="pl-8">
            <h1 class="mb-4 text-center">Relation</h1>

            <insurance-price-addon-relation-operation-section
                :builder-elements="builderElements"
                :addons="addons"
                :insurance-type="insuranceType"
            />
          </v-col>
          <v-col cols="4" class="px-8 pb-8">
            <h1 class="mb-4 text-center">Fields</h1>

            <insurance-price-addon-relation-addons-section
                :insurance-type="insuranceType"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import {InsuranceType} from "@/models/insurance-type.js";
import InsurancePriceAddonRelationOperationSection
  from "@/ui/components/admin/insurance-price-addon-relations/sections/InsurancePriceAddonRelationOperationSection.vue";
import InsurancePriceAddonRelationAddonsSection
  from "@/ui/components/admin/insurance-price-addon-relations/sections/InsurancePriceAddonRelationAddonsSection.vue";

export default {
  components: {
    WhiteBtn,
    InsurancePriceAddonRelationOperationSection,
    InsurancePriceAddonRelationAddonsSection,
  },

  props: {
    addons: {
      type: Array,
      required: true,
    },
    builderElements: {
      type: Array,
      required: true,
    },
    insuranceType: {
      type: InsuranceType,
      required: true,
    }
  },

  data: () => ({
    show: true,
  }),

  methods: {
    onClickClose() {
      this.$emit('close')
    },
  }
}
</script>
