<template>
  <div class="app-switch">
    <v-switch
        :ripple="false"
        dense
        inset
        :input-value="value"
        @change="saveField"
    />
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, Boolean],
      default: 0,
    }
  },
  methods: {
    saveField(value, name) {
      this.$emit('saveField', value || 0, name);
      this.$emit('input', value || 0)
    }
  }
}
</script>
<style>
.app-switch {
  width: 50px;
}

.app-switch .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

.app-switch .v-input--switch__track.theme--light.primary--text {
  color: var(--switch-bg) !important;
  opacity: 1 !important;
}
.app-switch .v-input--switch__thumb.theme--light.primary--text {
  color: var(--switch-color) !important;
}
.app-switch .v-input--switch__track.theme--light {
  color: var(--gray) !important;
  opacity: 1 !important;
}
</style>