
export class ElementSavedEvent {
    _name = 'element-saved'

    constructor(eventBus) {
        this.eventBus = eventBus
    }

    emit(element) {
        this.eventBus.$emit(this._name, element)
    }

    on(callback) {
        this.eventBus.$on(this._name, callback);
    }

    off(callback) {
        this.eventBus.$off(this._name, callback);
    }
}

