<template>
  <div class="row">
    <div class="col-5">
      <select-field
          name="selectDate"
          width="300px"
          :items="selectOptions"
          :value="selectValue"
          @saveField="selectPeriodDate"
      />

      <div class="row pl-0">
        <div class="col-6">
          <span>Von</span>
          <datepicker-field
              width="276px"
              placeholder="TT.MM.JJ"
              name="from"
              :value="from"
              @saveField="onSelectDate"
          />
        </div>
        <div class="col-6 pl-6">
          <span>Bis</span>
          <datepicker-field
              width="276px"
              placeholder="TT.MM.JJ"
              name="to"
              :value="to"
              @saveField="onSelectDate"
          />
        </div>
      </div>
    </div>
    <div class="col-12">
      <select-field
          name="insuranceType"
          width="300px"
          :items="insuranceTypeOptions"
          itemText="name"
          itemValue="key"
          @saveField="onSelectInsuranceType"
      />
    </div>

    <div class="col-12" v-if="loaded && values.length">
      <apexchart
          width="100%"
          :height="height"
          type="bar"
          :options="{
          xaxis: {
            categories: labels,
          },
          yaxis: {
            labels: {
            maxWidth: 350
            }
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          chart: {
            toolbar: {
              export: {
                csv: {
                  filename: getFileName,
                },
                svg: {
                  filename: getFileName,
                },
                png: {
                  filename: getFileName,
                },
              },
              tools: {
                download: '📥',
              },
            },
          },
          dataLabels: {
            enabled: true,
          },
        }"
          :series="[{
            data:values,
        }]"
      />
    </div>

    <div class="col-12" v-else-if="loaded && !values.length">
      <p class="empty-data__string">
        Empty Data
      </p>
    </div>
  </div>
</template>

<script>
import {insuranceTypeEnum} from "@/enums/insurance-type-enum";
import SelectField from "@/ui/components/common/fields/SelectField.vue";
import DatepickerField from "@/ui/components/common/fields/DatepickerField.vue";
import {dateService} from "@/utils/services/date-service.js";

export default {
  components: {DatepickerField, SelectField},
  props: {
    insuranceTypeOptions: {
      type: [],
      required: true,
      default: () => ([]),
    },
  },
  computed: {
    getFileName() {
      if (this.selectedInsuranceType) {
        for (let enumKey in insuranceTypeEnum) {
          if (this.selectedInsuranceType === insuranceTypeEnum[enumKey]) {
            return this.selectedInsuranceType;
          }
        }
      }

      return this.selectOptions.find((item) => item.value === this.selectValue)?.text
          || this.selectOptions[0].text
    },
    selectOptions() {
      return [
        {text: 'Gesamte Zeit', value: 'all'},
        {text: 'Dieses Jahr', value: 'year'},
        {text: 'Dieser Monat', value: 'month'},
        {text: 'Diese Woche', value: 'week'},
        {text: 'Heute', value: 'day'},
      ]
    },
    preselectDates() {
      return {
        'all': {
          from: '01.01.2023',
          to: dateService.getCurrentDate(),
        },
        'year': {
          from: dateService.getStartOfYearDate(),
          to: dateService.getEndOfYearDate()
        },
        'month': {
          from: dateService.getStartOfMonthDate(),
          to: dateService.getEndOfMonthDate(),
        },
        'week': {
          from: dateService.getStartOfWeekDate(),
          to: dateService.getEndOfWeekDate(),
        },
        'day': {
          from: dateService.getStartOfDayDate(),
          to: dateService.getEndOfDayDate(),
        },
      }
    },
  },
  data: () => ({
    loaded: false,
    labels: [],
    values: [],
    total: 0,
    from: null,
    to: null,
    selectedInsuranceType: '',
    height:'100px',
    selectValue: 'all',
  }),
  async created() {
    await this.selectPeriodDate(this.selectOptions[0].value)
    this.loaded = true
  },
  methods: {
    async getData() {
      const {items} = await this.$store.dispatch('admin/statisticModule/statsInsuranceNpoptReasons', {
        insuranceType: this.selectedInsuranceType,
        from: this.from,
        to: this.to
      })

      this.labels = [];
      this.values = [];

      this.height = ((items.length * 65) + 100) + 'px';

      items.forEach((item) => {
        this.values.push(parseInt(item['count']));
        this.labels.push(item['text']);
      })
    },
    getInsuranceOriginalTypes() {
      return this.$store.dispatch('admin/insuranceTypeModule/getOriginalTypes')
    },
    async onSelectInsuranceType(val) {
      this.selectedInsuranceType = val

      await this.getData()
    },
    async selectPeriodDate(value) {
      this.selectValue = value;

      const {from, to} = this.getFromToPreselect(value);

      this.from = from;
      this.to = to;

      await this.getData();
    },
    async onSelectDate(value, key) {
      this[key] = value;

      if (value !== this.preselectDates[this.selectValue][key]) {
        this.selectValue = this.selectOptions[0].value;
      }

      await this.getData();
    },
    getFromToPreselect(key) {
      return this.preselectDates[key];
    },
  }
}

</script>

<style scoped>
.empty-data__string {
  margin-top: 100px;
  margin-bottom: 100px;
  color: #6c757d;
  font-size: 28px;
  font-weight: 700;
}

.row {
  background-color: var(--white-bg);
  padding: 20px;
}
</style>
