export const insuranceElementActionEnum = {
    USE_VALUE: 'Use value',
    USE_VISIBILITY: 'Use visibility',
    USE_DRAFT_STATUS: 'Use Draft status',
    USE_STATIC_VALUE: 'Use custom value',
    USE_EMPTY_PRICE: 'Use empty price',
    EMPTY_ACTION: 'Do nothing',
}

export const insuranceElementConditionEnum = {
    IF_VISIBLE: 'If visible',
    IF_VALUE: 'If have value',
    IF_SELECTED: 'If selected',
    IF_UNSELECTED: 'If unselected',
    IF_STATIC_VALUE: 'If equal to custom value',
    IF_NOT_STATIC_VALUE: 'If not equal to custom value',
    ALWAYS: 'Always',
    IS_MORE_THAN: 'Is more than',
    IS_LESS_THAN: 'Is less than',
    VALUE_IS_IN_LIST: 'Value is in the list',
}