<template>
  <app-block height="750px" class="pr-9">
    <div class="">
      <app-preloader class="mt-16" v-if="loading"/>

      <div v-else class="mt-4">
        <p class="app-text__bold font-s-24">Relations </p>
        <insurance-element-relations-table
            :insurance-element-relations="insuranceElementRelations"
            :selected-element="selectedElement"
            @clickDelete="onClickDelete"
            @clickEdit="onClickEdit"
        />
      </div>
    </div>

  </app-block>
</template>
<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue";
import InsuranceElementRelationsTable
  from "@/ui/components/admin/insurance-element-relations/tables/InsuranceElementRelationsTable.vue";
import {insuranceElementRelationEvents} from "@/utils/events/insurance-element-relation-events.js";
import {InsuranceElementRelation} from "@/models/insurance-element-relations/insurance-element-relation.js";
import {notificationService} from "@/utils/services/notification-service.js";
import {confirmService} from "@/utils/services/confirm-service.js";
import {InsuranceType} from "@/models/insurance-type.js";

export default {
  components: {InsuranceElementRelationsTable, AppPreloader, AppBlock},
  props: {
    insuranceType: {
      type: InsuranceType,
      required: true,
    }
  },
  data: () => ({
    insuranceElementRelations: [],
    selectedElement: InsuranceElementRelation.empty(),

    loading: false,
  }),
  async created() {
    await this.reloadRelations();

    insuranceElementRelationEvents.relationSaved.on(this.onRelationSaved);
  },
  beforeDestroy() {
    insuranceElementRelationEvents.relationSaved.off(this.onRelationSaved);
  },
  methods: {
    async onClickDelete(insuranceElementRelation) {
      if (!confirmService.simpleConfirm("Are you really want to delete relation?")) {
        return;
      }

      this.loading = true;
      const result = await this.deleteRelation(this.insuranceType, insuranceElementRelation);

      if (result) {
        await this.reloadRelations();

        this.onSelectInsuranceElementRelation(InsuranceElementRelation.empty());

        notificationService.success('Relation was deleted')
      }
    },

    onClickEdit(insuranceElementRelation) {
      this.onSelectInsuranceElementRelation(insuranceElementRelation);

      this.selectedElement = insuranceElementRelation;
    },

    onSelectInsuranceElementRelation(elementRelation) {
      insuranceElementRelationEvents.selectRelation.emit(elementRelation);
    },

    onRelationSaved() {
      this.selectedElement = InsuranceElementRelation.empty();
      this.onSelectInsuranceElementRelation(InsuranceElementRelation.empty())

      this.reloadRelations();
    },

    async reloadRelations() {
      this.loading = true;
      this.insuranceElementRelations = await this.getInsuranceElementRelations(this.insuranceType);
      this.loading = false;
    },

    deleteRelation(insuranceElement, insuranceElementRelation) {
      return this.$store.dispatch('admin/insuranceElementRelationsModule/deleteInsuranceElementRelation', {
        insuranceElement,
        insuranceElementRelation,
      })
    },

    getInsuranceElementRelations(insuranceType) {
      return this.$store.dispatch('admin/insuranceElementRelationsModule/getInsuranceElementRelations', {
        insuranceType,
      })
    },
  }
}
</script>
