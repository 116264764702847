<template>
  <div id="single-object-insurance-price">
    <div class="mt-2 pt-3 pb-3 app-bg-aqua">
      <app-block class="py-2 mb-4 app-container">
        <progress-bar
            name="Vorschlag"
            :selected-item="2"
        />
      </app-block>
    </div>
    <insurance-step-wrapper
        @prevButtonClick="onClickPrev"
        @nextButtonClick="onClickNext"
    >
      <single-object-price-data-block
          :insurance-id="insurance.id"
          :insurance-full-name="insuranceFullName"
      />

    </insurance-step-wrapper>
  </div>
</template>

<script>
import ProgressBar from "@/ui/components/insurance/ProgressBar.vue";
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import InsuranceStepWrapper from "@/ui/components/insurance/insurance-wrappers/InsuranceStepWrapper.vue";
import SingleObjectPriceDataBlock from "@/ui/components/insurance/insurance-prices/blocks/SingleObjectPriceDataBlock.vue";
import {prepareInsuranceFullName} from "@/utils/helpers";

export default {
  components: {
    SingleObjectPriceDataBlock,
    InsuranceStepWrapper,
    AppBlock,
    ProgressBar,
  },
  computed: {
    insurance() {
      return this.$store.getters["insuranceModule/getInsurance"];
    },
    insuranceFullName() {
      return prepareInsuranceFullName(this.$store.getters["insuranceModule/insurancePriceModule/getInsuranceFullName"]) || '';
    },
  },
  async created() {
    this.showProcessPreloader();
    this.hideProcessPreloader();
  },
  methods: {
    showProcessPreloader() {
      this.$store.commit('insuranceModule/showProcessPreloader')
    },
    hideProcessPreloader() {
      this.$store.commit('insuranceModule/hideProcessPreloader')
    },
    async onClickPrev() {
      this.$store.commit('insuranceModule/showPreloader')
      await this.prevStep();
      this.$store.commit('insuranceModule/hidePreloader')
    },

    async onClickNext() {
      this.$store.commit('insuranceModule/showPreloader')
      await this.statusCustomer();
      this.$store.commit('insuranceModule/hidePreloader')
    },

    async prevStep() {
      await this.statusDraft();
    },

    async statusDraft() {
      await this.$store.dispatch('insuranceModule/statusDraft')
    },

    async statusCustomer() {
      await this.$store.dispatch('insuranceModule/statusCustomer')
    },
  },
}
</script>

<style scoped>
.app-block {
  height: 500px;
}
</style>