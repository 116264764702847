
export const insuranceTypeApi = (http) => ({
    originalTypeByKey: (key) => http.get(`/api/admin/insurance-type/original/${key}`),

    originalTypes: () => http.get(`/api/admin/insurance-type/original`),

    insuranceBuilders: (insuranceTypeId) => http.get(`/api/admin/insurance-type/${insuranceTypeId}/builders`),

    riskInsuranceBuilders: (insuranceTypeId) => http.get(`/api/admin/insurance-type/${insuranceTypeId}/builders/risk`),

    priceInsuranceBuilders: (insuranceTypeId) => http.get(`/api/admin/insurance-type/${insuranceTypeId}/builders/price`),

    customerInsuranceBuilders: (insuranceTypeId) => http.get(`/api/admin/insurance-type/${insuranceTypeId}/builders/customer`),

    policeInsuranceBuilders: (insuranceTypeId) => http.get(`/api/admin/insurance-type/${insuranceTypeId}/builders/policy`),

    createInsuranceBuilder: (insuranceTypeId, builderId, data) => http.post(`/api/admin/insurance-type/${insuranceTypeId}/builders/${builderId}/add`, data),

    updateInsuranceBuilder: (insuranceBuilderId, insuranceTypeId, data) => http.post(`/api/admin/insurance-type/${insuranceTypeId}/builders/${insuranceBuilderId}/update`, data),

    deleteInsuranceBuilder: (insuranceBuilderId, insuranceTypeId) => http.post(`/api/admin/insurance-type/${insuranceTypeId}/builders/${insuranceBuilderId}/delete`),

    sqlInsuranceBuilder: (insuranceBuilderId, insuranceTypeId) => http.get(`/api/admin/insurance-type/sql/${insuranceTypeId}/builders/${insuranceBuilderId}`),

    sqlInsuranceType: (insuranceTypeId) => http.get(`/api/admin/insurance-type/sql/${insuranceTypeId}`),

    sqlInsuranceBuilders: (insuranceTypeId) => http.get(`/api/admin/insurance-type/sql/${insuranceTypeId}/builders`),

    elementsByInsuranceType: (insuranceTypeId) => http.get(`/api/admin/insurance-type/${insuranceTypeId}/elements`),

    createInsuranceElement: (insuranceTypeId, data) => http.post(`/api/admin/insurance-type/${insuranceTypeId}/elements/create`, data),

    updateInsuranceElement: (insuranceElementId, insuranceTypeId, data) => http.post(`/api/admin/insurance-type/${insuranceTypeId}/elements/${insuranceElementId}/update`, data),

    updateInsuranceType: (insuranceTypeId, data) => http.post(`/api/admin/insurance-type/${insuranceTypeId}/update`, data),

    deleteInsuranceElement: (insuranceElementId, insuranceTypeId) => http.post(`/api/admin/insurance-type/${insuranceTypeId}/elements/${insuranceElementId}/delete`),

    getInsuranceApis: (insuranceTypeId) => http.get(`/api/admin/insurance-type/${insuranceTypeId}/insurance-api`),

    assignPriceRequest: (insuranceTypeId, builderId) => http.post(`/api/admin/insurance-type/${insuranceTypeId}/insurance-api/price-request/${builderId}/attach`),

    assignPriceResponse: (insuranceTypeId, builderId) => http.post(`/api/admin/insurance-type/${insuranceTypeId}/insurance-api/price-response/${builderId}/attach`),

    getFiles: (insuranceTypeId) => http.get(`/api/admin/insurance-type/${insuranceTypeId}/files`),

    createFile: (insuranceTypeId, file) => http.formDataRequest(`/api/admin/insurance-type/${insuranceTypeId}/files/create`, {
        file
    }),

    deleteFile: (insuranceTypeId, fileId) => http.post(`/api/admin/insurance-type/${insuranceTypeId}/files/${fileId}/delete`),

    addonsByInsuranceType: (insuranceTypeId) => http.get(`/api/admin/insurance-type/${insuranceTypeId}/addons`),

    createInsurancePriceAddon: (insuranceTypeId, data) => http.post(`/api/admin/insurance-type/${insuranceTypeId}/addons`, data),

    updateInsurancePriceAddon: (insuranceTypeId, priceAddonId, data) => http.post(`/api/admin/insurance-type/${insuranceTypeId}/addons/${priceAddonId}`, data),

    deleteInsurancePriceAddon: (insuranceTypeId, priceAddonId) => http.post(`/api/admin/insurance-type/${insuranceTypeId}/addons/${priceAddonId}/delete`),

    deleteAllBuildersAndRelations: (insuranceTypeId) => http.post(`/api/admin/insurance-type/${insuranceTypeId}/builders/delete-all-builders-and-relations`),
})