import {builderElementsEvents} from "@/utils/events/builder-elements-events";

export const showPopupAction = ({passiveElement, context}) => {
    passiveElement.is_show = 1;

    context.dispatch('insuranceModule/builderElementsModule/saveElement', {
        builderElement: passiveElement
    }, {root: true,})

    builderElementsEvents.changedPopupBlockState.emit();
}