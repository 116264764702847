<template>
  <div class="d-flex" :style="{height, width, padding, margin, background}">
    <builder-elements
        class="d-flex"
        :class="classes"
        :style="{width}"
        :elements="children"
        :builder-element-id="builderElementId"
        :disabled-all-inputs="disabledAllInputs"
        :admin-edit-mode="adminEditMode"
    />
  </div>
</template>
<script>
import DefaultElement from "./mixins/DefaultElement.js";
import ParentElement from "./mixins/ParentElement.js";
import Size from './mixins/Size.js'
import Padding from "./mixins/Padding.js";
import Background from "@/ui/components/builder/elements/mixins/Background.js";

export default {
  components: {},
  mixins: [DefaultElement, ParentElement, Size, Background, Padding],
  props: {
    builderElementId: {
      type: String,
      required: false,
    },
    disabledAllInputs: {
      type: Boolean,
      required: false,
      default: false,
    },
    adminEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    classes() {
      return this.builderElement.data?.class || '';
    }
  },
}
</script>