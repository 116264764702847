<template>
  <div>
    <v-row class="templates-list" v-if="builderElements.length">
      <v-col class="pa-4" cols="6" v-for="element in builderElements" :key="element.id">
        <white-btn
            height="125px"
            width="100%"
            :selected="selectedElement.id === element.id"
            @click="selectElement(element)"
        >
          {{ element.name }}
        </white-btn>
      </v-col>
    </v-row>

    <v-row class="px-8" v-if="!selectedElement.isEmpty()">
      <v-col cols="12">
        <name-editor
            v-model="selectedElement.name"
        />
      </v-col>

      <v-col cols="12">
        <priority-editor
            v-model="selectedElement.priority"
        />
      </v-col>
    </v-row>

    <div class="button-section" v-if="!selectedElement.isEmpty()">
      <blue-btn
          class="float-right"
          @click="onClickSave"
      >
        Save
      </blue-btn>

      <orange-btn
          class="float-right mr-2"
          @click="onCancelClick"
      >
        Cancel
      </orange-btn>
    </div>
  </div>
</template>
<script>
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import {BuilderElement} from "@/models/builder-element.js";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import {Builder} from "@/models/builder.js";
import {builderEditorEvents} from "@/utils/events/builder-editor-events.js";
import {builderElementService} from "@/utils/services/builder-element-service.js";
import {notificationService} from "@/utils/services/notification-service.js";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";

export default {
  components: {OrangeBtn, PriorityEditor, NameEditor, BlueBtn, WhiteBtn},
  props: {
    builder: {
      type: Builder,
      required: true,
    }
  },
  data: () => ({
    builderElements: [],
    selectedElement: BuilderElement.empty(),
  }),
  async created() {
    this.builderElements = await this.selectTemplates();
  },
  beforeDestroy() {
      this.onCancelClick();
  },
  methods: {
    selectElement(element) {
      this.onCancelClick();

      this.selectedElement = builderElementService.copyElement(element);
      this.selectedElement.parent_id = this.builder.id;
      this.selectedElement.priority = 1;

      this.selectedElement.data.margin = this.presetMargin(this.selectedElement);

      this.addToList(this.selectedElement);
    },

    presetMargin(element) {
      const marginArray = element.data.margin
          ? element.data.margin.split(' ')
          : ['0', '0', '0', '0'];
      //preset margin top
      marginArray[0] = '15px';

      return marginArray.join(' ');
    },

    addToList(element) {
      builderEditorEvents.addElementToList.emit(element)

      //set timeout is required
      setTimeout(() => {
        builderEditorEvents.selectElement.emit(element.id)
      }, 150);
    },

    onCancelClick() {
        builderEditorEvents.removeElementFromList.emit(this.selectedElement.id);

        this.selectedElement = BuilderElement.empty();
    },

    async onClickSave() {
      await this.performCopy(
          this.builder,
          this.selectedElement,
          this.selectedElement.toObject()
      )

      this.selectedElement = BuilderElement.empty();

      notificationService.success("Element saved")
    },

    async performCopy(builder, builderElement, additionalData) {
      builderEditorEvents.unselectElement.emit();

      builderEditorEvents.removeElementFromList.emit(builderElement.id);

      const newElement = await this.copyElement(
          builder.id,
          builderElement.id,
          additionalData
      );

      builderEditorEvents.addElementToList.emit(newElement);

      builderEditorEvents.elementSaved.emit(newElement);
    },

    copyElement(builderId, builderElementId, additionalData) {
      return this.$store.dispatch('admin/builderEditorModule/copyBuilderElementTemplate', {
        builderId,
        builderElementId,
        additionalData,
      })
    },

    selectTemplates() {
      return this.$store.dispatch('admin/builderEditorModule/getBuilderElementTemplates')
    }
  }
}
</script>
<style scoped>
.button-section {
  position: absolute;
  right: 2rem;
  bottom: 25px;
  background: white;
}

.templates-list {
  overflow-y: auto;
  height: 350px;
}

</style>