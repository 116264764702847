<template>
  <div v-if="isLoaded">
    <div class="mt-2 pt-3 pb-3 app-bg-aqua">
      <app-block class="py-2 mb-4 app-container">
        <progress-bar
            name="Vorschlag"
            :selected-item="2"
        />
      </app-block>
      <insurance-step-wrapper
          :show-next-button="isShowNextBtn || isSpecialDoppelbandCombination"
          @prevButtonClick="onClickPrev"
          @nextButtonClick="onClickNext"
      >

        <doppelband-price-data-block
            :insurance-price="insurancePrice1"
            :insurance-id="insurance.id"
            :is-disable-all-inputs="isDisableAllInputs"
            @setPriceData="setPriceData1"
        />

        <doppelband-price-data-block
            :disable-edit-right-side="true"
            :insurance-price="insurancePrice2"
            :insurance-id="insurance.id"
            :is-disable-all-inputs="isDisableAllInputs"
            :is-disable-max-sum-insured-by-condition="!isSelectedSpecialCheckboxes"
            @setPriceData="setPriceData2"
        />

        <template v-if="insurancePrice3">
          <doppelband-price-data-block
              :disable-edit-right-side="true"
              :insurance-price="insurancePrice3"
              :insurance-id="insurance.id"
              :is-disable-all-inputs="isDisableAllInputs"
              @setPriceData="setPriceData3"
          />
        </template>

        <addon-blocks
            v-if="isShowAddons1"
            :addons="addonsInsurancePrice1"
            :show-price="!insurancePrice1.getIsNpopt()"
            :insurance-type-id="insurance.type.id"
            :period="insurancePrice1.getPaymentPeriodLabel()"
            :header="addonHeader1"
            :is-disable-all-inputs="isDisableAllInputs"
            @changeAddon="onChangeAddon"
        />

        <addon-blocks
            v-if="isShowAddons2"
            :existing-addons="addonsInsurancePrice1"
            :addons="addonsInsurancePrice2"
            :show-price="!insurancePrice2.getIsNpopt()"
            :insurance-type-id="insurance.type.id"
            :period="insurancePrice2.getPaymentPeriodLabel()"
            :header="addonHeader2"
            :is-disable-all-inputs="isDisableAllInputs"
            @changeAddon="onChangeAddon"
        />

        <insurance-price-block
            v-if="isShowInsurancePriceBlock"
            class="mt-4"
            :price-items="priceItems"
            :total-price="totalPrice"
            :period="insurancePrice1.getPaymentPeriodLabel()"
            :tax-price="taxPrice"
            :final-price="finalPrice"
            :discount="insurancePrice.discount"
            :is-disable-all-inputs="isDisableAllInputs"
            :surcharge="insurancePrice.getSurcharge()"
            @changedDiscount="onChangedDiscount"
            @acceptSuggestion="acceptSuggestion"
            @changedSurcharge="changedSurcharge"
        />

        <document-applications-block
            class="mt-4"
            v-if="!insurancePrice.getIsNpopt()"
            :documents="documentApplications"
        />

        <warning-block
            class="mt-15"
            v-if="isSelectedSpecialCheckboxes && areSumInsuredOrMaxSumInsuredDifference"
            :value=sumInsuredOrMaxSumInsuredDifferenceMsg
        />

      </insurance-step-wrapper>
    </div>
  </div>
</template>

<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue"
import ProgressBar from "@/ui/components/insurance/ProgressBar.vue"
import InsuranceStepWrapper from "@/ui/components/insurance/insurance-wrappers/InsuranceStepWrapper.vue"
import DoppelbandPriceDataBlock from "@/ui/components/insurance/insurance-prices/blocks/DoppelbandPriceDataBlock.vue"
import AddonBlocks from "@/ui/components/insurance/insurance-prices/blocks/AddonBlocks.vue"
import {InsurancePriceItem} from "@/models/insurance-price-item.js"
import InsurancePriceBlock from "@/ui/components/insurance/insurance-blocks/insurance-price/InsurancePriceBlock.vue"
import {SPECIAL_DOPPELBAND_COMBINATION} from "@/enums/fields/bankruptcy-risk/field-names";
import {builderElementService} from "@/utils/services/builder-element-service";
import {getBooleanValue} from "@/utils/helpers";
import {insuranceTypeEnum, financialInvestmentsPriceBlockName} from "@/enums/insurance-type-enum";
import DocumentApplicationsBlock from "@/ui/components/insurance/insurance-prices/blocks/DocumentApplicationsBlock.vue";
import WarningBlock from "@/ui/components/common/blocks/WarningBlock.vue"
import {sumInsuredOrMaxSumInsuredDifferenceWarningText} from "@/utils/data/insurance-price.js";

export default {
  components: {
    AppBlock,
    ProgressBar,
    InsuranceStepWrapper,
    DoppelbandPriceDataBlock,
    AddonBlocks,
    InsurancePriceBlock,
    DocumentApplicationsBlock,
    WarningBlock,
  },
  props: {
    isDisableAllInputs: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isSelectedSpecialCheckboxes() {
      const checkboxes = builderElementService.findAllElementsByParameters(this.allBuilderElements, 'name', SPECIAL_DOPPELBAND_COMBINATION)
      const selectedCheckboxes = checkboxes.filter(item => getBooleanValue(item.value));

      return selectedCheckboxes.length > 1;
    },
    allBuilderElements() {
      return this.$store.getters["insuranceModule/builderElementsModule/getAllBuilderElements"];
    },
    isSpecialDoppelbandCombination() {
      return this.isSelectedSpecialCheckboxes && this.areSumInsuredOrMaxSumInsuredDifference;
    },
    insuranceType() {
      return this.$store.getters['insuranceModule/getInsuranceType'];
    },
    isShowNextBtn() {
      return this.insurancePrice1.getIsNpopt() || this.insurancePrice2.getIsNpopt() || this.insurancePrice3?.getIsNpopt()
    },
    insurancePrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePrice"];
    },
    insurance() {
      return this.$store.getters["insuranceModule/getInsurance"];
    },
    insurancePrices() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePrices"];
    },
    periodPrices() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getPerPeriodPrices"];
    },
    periodPrice1() {
      return this.periodPrices[0]?.getPerPeriodPrice();
    },
    periodPrice2() {
      return this.periodPrices[1]?.getPerPeriodPrice();
    },
    periodPrice3() {
      return this.periodPrices[2]?.getPerPeriodPrice();
    },
    insuranceTypeName() {
      if (this.insuranceType.key === insuranceTypeEnum.FINANCIAL_INVESTMENTS) {
        return financialInvestmentsPriceBlockName
      }

      return this.insuranceType.name
    },
    insurancePrice1() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePriceById"](this.insurancePrices[0].id);
    },
    insurancePriceName1() {
      return `${this.insuranceTypeName} ${this.insurancePrice1.name}`;
    },
    insurancePriceName2() {
      return `${this.insuranceTypeName} ${this.insurancePrice2.name}`;
    },
    insurancePriceName3() {
      return `${this.insuranceTypeName} ${this.insurancePrice3?.name}`;
    },
    insurancePrice2() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePriceById"](this.insurancePrices[1].id);
    },
    insurancePrice3() {
      if (this.insurancePrices.length > 2) {
        return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePriceById"](this.insurancePrices[2].id);
      }

      return null
    },
    additionalPriceItems() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getAdditionalPriceItems"];
    },
    isBabyContract() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getIsBabyContract"];
    },
    isShowAddons1() {
      return !this.isBabyContract && !this.insurancePrice1.getIsNpopt()
    },
    isShowAddons2() {
      return !this.isBabyContract && !this.insurancePrice2.getIsNpopt()
    },
    priceItems() {
      const items = []

      items.push(
          new InsurancePriceItem.empty({
            name: this.insurancePriceName1,
            price: this.periodPrice1,
          })
      )

      items.push(
          new InsurancePriceItem.empty({
            name: this.insurancePriceName2,
            price: this.periodPrice2,
          })
      )

      if (this.insurancePrice3) {
        items.push(
            new InsurancePriceItem.empty({
              name: this.insurancePriceName3,
              price: this.periodPrice3,
            })
        )
      }

      if (this.additionalPriceItems.length) {
        this.additionalPriceItems.forEach((component) => {
          items.push(
              new InsurancePriceItem.empty({
                name: component.name,
                price: component.price,
              })
          )
        })
      }

      if (!this.isBabyContract) {
        this.selectedAddons.forEach(addon => {
          let checkboxName = ''

          if (addon.price_id === this.insurancePrice1.getId()) {
            checkboxName = this.insurancePrice1.getName()
          } else {
            checkboxName = this.insurancePrice2.getName()
          }

          const existedItem = items.find(itm => itm.key === addon.key);

          if (!existedItem) {
              let addonName = addon.name + `(${checkboxName})`
              if (this.insuranceType.key === insuranceTypeEnum.REAL_ESTATE_SERVICES){
                  addonName = addon.name
              }

            items.push(
                new InsurancePriceItem.empty({
                  key: addon.key,
                  name: addonName,
                  price: addon.price,
                  additionalText: addon.details.info,
                })
            );
          } else {
            existedItem.name = addon.name;
          }
        })
      }

      return items
    },
    totalPrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getTotalPrice"];
    },
    taxPrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getTaxPrice"];
    },
    finalPrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getFinalPrice"];
    },
    selectedAddons() {
      return this.$store.getters["insuranceModule/insurancePriceAddonModule/getSelectedAddons"];
    },
    addons() {
      return this.$store.getters["insuranceModule/insurancePriceAddonModule/getAddons"];
    },
    addonsInsurancePrice1() {
      return this.addons.filter((addon) => {
        return addon.price_id === this.insurancePrice1.getId()
      });
    },
    addonsInsurancePrice2() {
      return this.addons.filter((addon) => {
        return addon.price_id === this.insurancePrice2.getId()
      });
    },
    addonHeader1() {
      return this.getAddonHeader(this.insurancePrice1.getName())
    },
    addonHeader2() {
      return this.getAddonHeader(this.insurancePrice2.getName())
    },
    isShowInsurancePriceBlock() {
      return !this.insurancePrice1.getIsNpopt() && !this.insurancePrice2.getIsNpopt() && !this.insurancePrice3?.getIsNpopt() && !this.isSpecialDoppelbandCombination
    },
    areSumInsuredOrMaxSumInsuredDifference() {
      return this.insurancePrice1.getSumInsuredLabel() !== this.insurancePrice2.getSumInsuredLabel() ||
        this.insurancePrice1.getMaxSumInsuredLabel() !== this.insurancePrice2.getMaxSumInsuredLabel()
    },
    sumInsuredOrMaxSumInsuredDifferenceMsg() {
      return sumInsuredOrMaxSumInsuredDifferenceWarningText
    },
  },
  data: () => ({
    isLoaded: false,
    priceData1: {},
    priceData2: {},
    priceData3: {},
    documentApplications: [],
  }),
  async created() {
    this.showProcessPreloader();
    await this.getInsurancePrices(this.insurance.id)

    await this.calculatePrices()

    this.documentApplications = await this.getDocumentApplications(this.insurance.type.id)

    this.initPriceData()

    this.isLoaded = true
    this.hideProcessPreloader();
  },
  methods: {
    showProcessPreloader() {
      this.$store.commit('insuranceModule/showProcessPreloader')
    },
    hideProcessPreloader() {
      this.$store.commit('insuranceModule/hideProcessPreloader')
    },
    getAddonHeader(insurancePriceName = '') {
      const priceName = 'Wünschen Sie Zusatzoptionen?'

      if (
          this.insuranceType.key === insuranceTypeEnum.FINANCIAL_INVESTMENTS ||
          this.insuranceType.key === insuranceTypeEnum.REAL_ESTATE_SERVICES
      ) {
        return priceName
      }

      return `${priceName} (${insurancePriceName})`
    },
    async onClickPrev() {
      this.$store.commit('insuranceModule/showPreloader')
      await this.prevStep();
      this.$store.commit('insuranceModule/hidePreloader')
    },

    async prevStep() {
      await this.statusDraft();
    },

    async onClickNext() {
      this.$store.commit('insuranceModule/showPreloader')
      await this.statusCustomer();
      await this.sendXml();
      this.$store.commit('insuranceModule/hidePreloader')
    },

    async statusCustomer() {
      await this.$store.dispatch('insuranceModule/statusCustomer')
    },

    sendXml() {
      this.$store.dispatch('insuranceModule/sendXml')
    },

    initPriceData() {
      this.priceData1 = this.insurancePrice1.data
      this.priceData2 = this.insurancePrice2.data
      this.priceData3 = this.insurancePrice3?.data
    },

    async calculatePrices() {
      await this.calculatePrice(this.insurance['id'])
    },
    async calculatePrice(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/calculatePrice', {
        insuranceId,
      })
    },
    async getInsurancePrices(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/getInsurancePrices', {
        insuranceId,
      })
    },
    async setPriceData1(data) {
      this.showProcessPreloader();
      this.priceData1 = data;
      this.copyRightFieldsToSecondBlock(data);

      await this.updateInsurancePriceData();

      await this.calculatePrices()
      this.initPriceData();
      this.hideProcessPreloader();
    },
    async setPriceData2(data) {
      this.showProcessPreloader();
      this.priceData2 = data

      await this.updateInsurancePriceData();

      await this.calculatePrices();
      this.initPriceData();
      this.hideProcessPreloader();
    },

    async setPriceData3(data) {
      this.showProcessPreloader();
      this.priceData3 = data

      await this.updateInsurancePriceData();

      await this.calculatePrices();
      this.initPriceData();
      this.hideProcessPreloader();
    },

    copyRightFieldsToSecondBlock(data) {
      this.priceData2.deductible = data.deductible;
      this.priceData2.duration = data.duration;
      this.priceData2.paymentPeriod = data.paymentPeriod;

      if (this.insurancePrice3) {
        this.priceData3.deductible = data.deductible;
        this.priceData3.duration = data.duration;
        this.priceData3.paymentPeriod = data.paymentPeriod;
      }

      if (this.isSelectedSpecialCheckboxes) {
        this.priceData2.maxSumInsured = data.maxSumInsured
      }
    },

    async updateInsurancePriceData(){
      const requestData = [
        {id: this.insurancePrice1.getId(), data: this.priceData1},
        {id: this.insurancePrice2.getId(), data: this.priceData2},
      ]

      if (this.insurancePrice3) {
        requestData.push({id: this.insurancePrice3.getId(), data: this.priceData3})
      }

      await this.$store.dispatch('insuranceModule/insurancePriceModule/updatePrices',
          {
            insuranceId: this.insurance.id,
            requestData: requestData
          }
      )
    },
    async onChangeAddon() {
      await this.calculatePrice(this.insurance.id)

      this.documentApplications = await this.getDocumentApplications(this.insurance.type.id)
    },
    acceptSuggestion() {
      this.onClickNext()
    },
    // TODO update discounts by one request
    async onChangedDiscount(discount) {
      this.showProcessPreloader();

      await this.$store.dispatch('insuranceModule/insurancePriceModule/saveDiscount', {
        discount,
        insurancePriceId: this.insurancePrice1.id
      });

      await this.$store.dispatch('insuranceModule/insurancePriceModule/saveDiscount', {
        discount,
        insurancePriceId: this.insurancePrice2.id
      });

      if (this.insurancePrice3) {
        await this.$store.dispatch('insuranceModule/insurancePriceModule/saveDiscount', {
          discount,
          insurancePriceId: this.insurancePrice3.id
        });
      }

      await this.calculatePrices()
      this.initPriceData();

      this.hideProcessPreloader();
    },
    async statusDraft() {
      await this.$store.dispatch('insuranceModule/statusDraft')
    },
    async changedSurcharge(surcharge) {
      this.showProcessPreloader();

      await this.$store.dispatch('insuranceModule/insurancePriceModule/saveSurcharge', {
        surcharge,
        insurancePriceId: this.insurancePrice1.id
      });

      await this.$store.dispatch('insuranceModule/insurancePriceModule/saveSurcharge', {
        surcharge,
        insurancePriceId: this.insurancePrice2.id
      });

      if (this.insurancePrice3) {
        await this.$store.dispatch('insuranceModule/insurancePriceModule/saveSurcharge', {
          surcharge,
          insurancePriceId: this.insurancePrice3.id
        });
      }

      await this.calculatePrices()
      this.initPriceData();

      this.hideProcessPreloader();
    },
    async getDocumentApplications(insuranceTypeId) {
      return await this.$store.dispatch('insuranceModule/insurancePriceModule/getDocumentApplications', {
        insuranceTypeId,
      })
    },
  },

}
</script>

<style scoped>

</style>