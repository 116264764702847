import {ApiData} from "@/models/api-data.js";
import {serverApi} from "@/api/server/server-api.js";

export const apiDataModule = {
    namespaced: true,
    actions: {
        getApiDataByUser: async (context, {userId}) => {
            const {data} = await serverApi.apiData.getByUser(userId)

            if (data.success) {
                return new ApiData(data.apiData);
            }

            return ApiData.createEmpty();
        }
    }
}