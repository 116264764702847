import {objectService} from "@/utils/services/object-service.js";

export class InsurancePricePackage {
    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(InsurancePricePackage.empty().toObject())
            : this.fillData(data);
    }

    fillData(data) {
        this.key = data.key;
        this.name = data.name;
        this.price = data.price;
        this.additionalData = data.additionalData;
        this.disabled = data.disabled;
    }

    static empty(data = {}) {
        return new InsurancePricePackage({
            key: data.key || '',
            name: data.name || '',
            price: data.price || '',
            additionalData: data.additionalData || [],
            disabled: data.disabled || false,
        })
    }

    toObject() {
        return {
            ...this
        }
    }

    getKey() {
        return this.key;
    }

    getName() {
        return this.name;
    }

    getPrice() {
        return this.price;
    }

    getAdditionalData() {
        return this.additionalData;
    }

    getDisabled() {
        return this.disabled;
    }
}