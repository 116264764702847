export const jsonBuilderEditorApi = (http) => ({
    getJsonElement: (id) => http.get(`/api/admin/json-builder-editor/json-element/${id}`),

    createJsonElement: (builderId, data) => http.post(`/api/admin/json-builder-editor/json-element/create `, {
        ...data,
        builder_id : builderId,
    }),

    deleteJsonElement: (builderElementId) => http.post(`/api/admin/json-builder-editor/json-element/${builderElementId}/delete`),

    updateJsonElement: (builderElementId, data) => http.post(`/api/admin/json-builder-editor/json-element/${builderElementId}/update`, data),
})