<template>
  <div class="text-center" style="position: relative; top: 40%;">
    <v-progress-circular
        indeterminate
        :size="size"
        :color="color"
    />
  </div>

</template>
<script>
export default {
  props:{
    size: {
      type: String,
      default: '50'
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>