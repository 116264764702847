<template>
  <div>
    <div
        v-for="element in builderElements"
        :key="element.id"
        :class="selector.elementBlock"
        v-bind="dynamicAttributes(element.id)"
        class="pl-6 d-flex"
    >
      <json-element-factory
          :class="selector.styleBlock"
          :builder-element="element"
          :comma="isNeedComma(element)"
          :is-array="isArray"
      />
    </div>
  </div>
</template>
<script>

import JsonElementFactory from "@/ui/components/json-builder/JsonElementFactory.vue";
import {builderQuerySelectors} from "@/utils/query-selectors/builder-query-selectors.js";

export default {
  components: {JsonElementFactory},
  props: {
    builderElements: {
      type: Array,
      required: true,
    },
    isArray: {
      type: Boolean,
      required: false,
    }
  },
  computed: {
    selector() {
      return builderQuerySelectors;
    },
  },
  methods: {
    dynamicAttributes(builderElementId) {
      return {
        [builderQuerySelectors.idAttribute]: builderElementId
      }
    },

    isNeedComma(element) {
      const index = this.builderElements.findIndex((elem) => elem.id === element.id)

      return index !== this.builderElements.length - 1;
    }
  },

}
</script>