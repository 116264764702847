<template>
  <div class="packages-wrapper">
    <div class="single-package__wrapper">
      <app-block
          width="100%"
          class="package-text px-0"
      >
        <div class="pl-4 price-info">
          <p class="pb-2 price-info-title insurance-type" v-html="insuranceFullName || 'Versicherung Einzelanwalt'"></p>
          <div v-if="!isNpopt">
            <p class="pb-2 font-weight-bold">{{ annualFee }} netto: {{ insurancePrice | toMoneyFormat}}</p>

            <p v-if="isShowPeriodPrice" class="pb-2 font-weight-bold">
              {{ rateNetText }} {{ perPeriodPrice | toMoneyFormat }}
            </p>
          </div>
          <div v-else>
            <p class="pb-2 font-weight-bold font-s-22 line-height-27">
              Herzlichen Dank für Ihre Eingaben!<br>
              Der Vorgang erfordert eine <span class="part-text">individuelle Risikoprüfung durch Underwriting.</span> Bitte klicken Sie auf „Speichern und weiter“,
              sofern sich unsere Kolleg:innen mit Ihnen in Verbindung setzen und ein Angebot erstellen sollen.
            </p>
          </div>
        </div>

        <div class="price-img-block">
          <img height="250px" width="100%" src="/img/men-shaking-hands.png" alt="test">
        </div>

      </app-block>
    </div>
  </div>
</template>
<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue"
import {INSURANCE_PRICE_PAYMENT_PERIOD_LABELS} from "@/utils/fields/insurance-price-list-field-names.js"
import {capitalizeFirstLetter} from "@/utils/helpers"
import {insuranceTypeEnum} from "@/enums/insurance-type-enum";
export default {
  components: {AppBlock},
  props: {
    perYear: {
      type: String,
      default: '0,00'
    },
    perPeriodPrice: {
      type: Number,
      default: 0,
    },
    period: {
      type: String,
      default: 'Halbjahr'
    },
    insurancePrice: {
      type: Number,
      default: 0,
    },
    periodPrice: {
      type: Number,
      default: 0,
    },
    isNpopt: {
      type: Boolean,
      default: false,
    },
    insuranceFullName: {
      type: String,
      default: 'Versicherung Einzelanwalt',
    },
  },
  computed: {
    isShowPeriodPrice() {
      return this.periodVal !== INSURANCE_PRICE_PAYMENT_PERIOD_LABELS.year && this.insurancePrice
    },
    insuranceType() {
      return this.$store.getters['insuranceModule/getInsuranceType'];
    },
    annualFee() {
      if (this.insuranceType.key === insuranceTypeEnum.FINANCIAL_INVESTMENTS) {
        return 'Grundbeitrag'
      }

      return 'Jahresbeitrag'
    },
  },
  watch: {
    period(newValue) {
      this.periodVal = newValue;

      this.changeRateNetText(newValue)
    }
  },
  data: () => ({
    periodVal: '',
    rateNetText: '',
  }),
  created() {
    this.fillData()
    this.changeRateNetText(this.period)
  },
  methods: {
    fillData() {
      this.periodVal = this.period
    },
    changeRateNetText(period) {
      const priceText = 'Beitrag netto:'

      if (period === INSURANCE_PRICE_PAYMENT_PERIOD_LABELS.year) {
        this.rateNetText = ''
      } else if (period === INSURANCE_PRICE_PAYMENT_PERIOD_LABELS.halfYear) {
        this.rateNetText = `${capitalizeFirstLetter(INSURANCE_PRICE_PAYMENT_PERIOD_LABELS.halfYear)}er ${priceText}`
      }
      else if (period === INSURANCE_PRICE_PAYMENT_PERIOD_LABELS.quart) {
        this.rateNetText = `${capitalizeFirstLetter(INSURANCE_PRICE_PAYMENT_PERIOD_LABELS.quart)}er ${priceText}`
      }
    },
  },
}
</script>
<style scoped>
.package-text {
  font-size: 24px;
  line-height: 1.2em;
  letter-spacing: 1.2px;
  color: #404040;
}

.single-package__wrapper {
  position: absolute;
  top: -65px;
  width: 100%;
  border: var(--default-border)
}

.packages-wrapper {
  position: relative;
}

.price-info {
  letter-spacing: 0.5px;
}

.price-img-block {
  padding-top: 10px;
}

.price-info-title {
  line-height: 1.2em;
}

.insurance-type {
  font-size: 24px;
}

.part-text {
  color: var(--orange);
}

</style>