<template>
  <div>
    <div class="text-center">
      <h3>Tooltip Block</h3>
    </div>

    <v-row>

      <v-col cols="6">
        <name-editor
            v-model="builderElement.name"
        />
      </v-col>

      <v-col class="pl-4" cols="6">
        <priority-editor
            v-model.number="builderElement.priority"
        />
      </v-col>

      <v-col cols="6">
        <height-editor
            v-model="builderElement.data.height"
        />
      </v-col>

      <v-col class="pl-4">
        <width-editor
            v-model="builderElement.data.width"
        />
      </v-col>

      <v-col cols="12">
        <p class="app-text__bold">Tooltip HTML</p>

        <text-area-field
            name="value"
            :rows="12"
            v-model="builderElement.value"
        />
      </v-col>

    </v-row>

    <margin-editor
        class="mt-2"
        v-model="builderElement.data.margin"
    />

    <padding-editor
        class="mt-6"
        v-model="builderElement.data.padding"
    />
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import PaddingEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PaddingEditor.vue";
import MarginEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MarginEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import HeightEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/HeightEditor.vue";
import WidthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/WidthEditor.vue";
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";

export default {
  components: {
    TextAreaField,
    WidthEditor, HeightEditor, NameEditor, PriorityEditor, MarginEditor, PaddingEditor
  },
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  }
}
</script>