<template>
  <white-btn
      width="200px"
      @click="$emit('click')"
  >
    {{ text }}
  </white-btn>
</template>
<script>
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
export default {
  components: {WhiteBtn},
  props: {
    text: {
      type: String,
    }
  }
}
</script>