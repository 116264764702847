<template>
  <insurance-packages-wrapper height="800px">
    <template slot="left-side">
      <p>Versicherungssumme</p>
      <money-field
          class="pr-1"
          name="sumInsuredNumber"
          :value="sumInsuredNumber"
          placeholder="bitte eintragen"
          @saveField="onSaveInsuredNumber"
      />
    </template>

    <template slot="center">
      <div class="packages-wrapper">
        <div class="single-package__wrapper">
          <app-block
              height="474px"
              width="100%"
              class="package-text px-0"
          >
            <div class="pl-4 price-info">
              <p class="pb-2 price-title" v-html="insuranceFullName"></p>
              <p class="pb-2 font-weight-bold">Es konnte leider kein Preis ermittelt werden</p>
            </div>

            <div class="price-img-block">
              <img height="250px" width="100%" src="/img/men-shaking-hands.png" alt="test">
            </div>
          </app-block>
        </div>
      </div>
    </template>
    <template slot="right-side"/>
  </insurance-packages-wrapper>
</template>

<script>
import InsurancePackagesWrapper from "@/ui/components/insurance/insurance-wrappers/InsurancePackagesWrapper.vue";
import {INSURANCE_PRICE_LIST_FIELD_NAMES} from "@/utils/fields/insurance-price-list-field-names.js";
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import MoneyField from "@/ui/components/common/fields/MoneyField.vue";
import {debounce} from "@/utils/functions";
export default {
  components: {
    MoneyField,
    InsurancePackagesWrapper,
    AppBlock,
  },
  props: {
    insuranceId: {
      type: Number,
      required: true,
    },
    insuranceFullName: {
      type: String,
      default: 'Versicherung Einzelanwalt',
    },
  },
  computed: {
    insurancePrice() {
      return this.$store.getters['insuranceModule/insurancePriceModule/getInsurancePrice'];
    },
  },
  data: () => ({
    sumInsuredNumber: '',
    priceData: {
      data: {},
    },
    maxSumInsured: '',
    isNoticeLimitationLiability: null,
    showNoticeLimitationPopup: false,
    higherMaximization: {},
    isHigherMaximization: {},
  }),
  async created() {
    await this.getInsurancePrice(this.insuranceId)
    this.fillData()
  },
  methods: {
    fillData() {
      this.sumInsuredNumber = this.insurancePrice.getSumInsuredNumber() || ''
      this.priceData.data = this.insurancePrice.getData()
    },

    async onSaveInsuredNumber(val) {
      debounce(async () =>  await this.saveSumInsuredNumber(val), 'change-insurance-price', 1000);
    },

    async saveSumInsuredNumber(val) {
      let result = val;
      const thousands = Math.floor(+val / 1000);
      const rest = +val % 1000;

      if (rest > 0 && thousands >= 1) {
        result = thousands * 1000;
      }

      if (thousands >= 1 && rest >= 500) {
        result = thousands * 1000 + 1000;
      }

      this.sumInsuredNumber = result;

      this.setPriceDataElem(INSURANCE_PRICE_LIST_FIELD_NAMES.sumInsuredNumber, result)

      await this.updateInsurancePrice(this.insurancePrice.getId(), this.priceData)
    },
    setPriceDataElem(key, val) {
      this.priceData.data[key] = val
    },
    async updateInsurancePrice(insurancePriceId, requestData){
      await this.$store.dispatch('insuranceModule/insurancePriceModule/updatePrice', {
        insurancePriceId,
        requestData
      })
    },
    async getInsurancePrice(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/getInsurancePriceByInsuranceId', {
        insuranceId,
      })
    },
  },
}
</script>

<style scoped>
.package-text {
  font-size: 24px;
  letter-spacing: 1.2px;
  color: #404040;
}
.price-title {
  line-height: 1.2em;
}

.single-package__wrapper {
  position: absolute;
  top: -65px;
  width: 100%;
  border: var(--default-border)
}

.packages-wrapper {
  position: relative;
}

.price-info {
  letter-spacing: 0.5px;
}

.price-img-block {
  padding-top: 90px;
}

</style>