<template>
  <div>
    <div class="d-flex justify-space-between">
      <h2>Builders</h2>

      <div class="d-flex">
        <white-btn
            @click="onBuildersSqlClick"
        >
          Builders SQL
        </white-btn>

        <orange-btn
            class="ml-2"
            @click="onRelationsClick"
        >
          Builder Relations
        </orange-btn>

        <blue-btn
            class="ml-2"
            @click="onCreateClick"
        >
          Create New Builder
        </blue-btn>
        <red-btn
            class="ml-2"
            @click="onDeleteAllBuildersAndRelationsClick"
        >
          Delete All Builders And Relations
        </red-btn>
      </div>
    </div>

    <v-row>
      <v-col cols="2">
        <div class="mt-12">
          <step-button
              @click="onClickStepBtn(stepBtn.key)"
              v-for="stepBtn in stepsButton"
              :key="stepBtn.key"
              :selected="stepBtn.key === stepButton"
          >
            {{ stepBtn.text }}
          </step-button>
        </div>

      </v-col>
      <v-col cols="10">
        <builder-table
            class="my-12"
            :loading="loading"
            :insurance-builders="insuranceBuilders"
            @builderEditorClick="onBuilderEditorClick"
            @updateClick="onUpdateBuilderClick"
            @deleteClick="onDeleteBuilderClick"
            @sqlClick="onBuilderSqlClick"
        />
      </v-col>
    </v-row>


    <builder-creator-popup
        v-if="showCreator"
        @onCreateBuilder="onCreateBuilder"
        @onCreateBuilderAndGoToEditor="onCreateBuilderAndGoToEditor"
        @close="onCloseCreator"
    />

    <builder-editor-popup
        v-if="showEditor"
        :builder="builder"
        :insurance-types="insuranceTypes"
        @close="onCloseEditor"
    />

    <builder-relations-popup
        v-if="showRelations"
        :builders="getBuildersFormInsuranceBuilders(insuranceBuilders)"
        :builder-elements="getBuilderElementsFromBuilders(insuranceBuilders)"
        @close="onCloseRelations"
    />

    <sql-popup
        v-if="showSql"
        :sql="sql"
        @close="onCloseSqlPopup"
    />
  </div>
</template>
<script>
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import BuilderTable from "@/ui/components/admin/insurance-type/builders-section/BuilderTable.vue";
import BuilderCreatorPopup from "@/ui/components/builder/use-cases/builder-creator/BuilderCreatorPopup.vue";
import BuilderEditorPopup from "@/ui/components/builder/use-cases/builder-editor/BuilderEditorPopup.vue";
import {Builder} from "@/models/builder.js";
import {InsuranceType} from "@/models/insurance-type.js";
import {notificationService} from "@/utils/services/notification-service.js";
import {InsuranceBuilder} from "@/models/insurance-builder.js";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import BuilderRelationsPopup from "@/ui/components/builder/use-cases/builder-relations/BuilderRelationsPopup.vue";
import {builderElementService} from "@/utils/services/builder-element-service.js";
import SqlPopup from "@/ui/components/admin/popups/SqlPopup.vue";
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import {confirmService} from "@/utils/services/confirm-service.js";
import StepButton from "@/ui/components/admin/insurance-type/components/StepButton.vue";
import RedBtn from "@/ui/components/common/buttons/RedBtn.vue";
import {dateService} from "@/utils/services/date-service.js";

export default {
  components: {
    BuilderTable,
    StepButton,
    SqlPopup,
    WhiteBtn,
    BuilderRelationsPopup, OrangeBtn, BuilderEditorPopup, BuilderCreatorPopup, BlueBtn, RedBtn
  },
  props: {
    insuranceType: {
      type: InsuranceType,
      required: true,
    }
  },
  data: () => ({
    showCreator: false,
    showEditor: false,
    showRelations: false,
    showSql: false,

    sql: '',

    stepButton: 'all',

    stepsButton: [
      {text: 'All', key: 'all'},
      {text: 'Risk Tab', key: 'risk'},
      {text: 'Price Tab', key: 'price'},
      {text: 'Customer Tab', key: 'customer'},
      {text: 'Police Tab', key: 'policy'},
    ],

    loading: false,
    insuranceBuilders: [],
    insuranceTypes: [],
    insuranceBuilder: InsuranceBuilder.empty(),

    builder: Builder.empty(),
  }),
  async created() {
    this.loading = true;
    await this.onClickStepBtn(this.stepButton)
    await this.getInsuranceTypes();
    this.loading = false;
  },
  methods: {
    onRelationsClick() {
      this.showRelations = true;
    },

    onCreateClick() {
      this.showCreator = true;
    },

    async getInsuranceTypes() {
      this.insuranceTypes = await this.$store.dispatch('admin/insuranceTypeModule/getOriginalTypes');
    },

    async onClickStepBtn(key) {
      const keyToQuery = {
        'all': this.selectBuilders,
        'risk': this.selectRiskBuilders,
        'price': this.selectPriceBuilders,
        'customer': this.selectCustomerBuilders,
        'policy': this.selectPolicyBuilders,
      }

      this.stepButton = key;

      this.loading = true;

      if (key in keyToQuery) {
        this.insuranceBuilders = await keyToQuery[key](this.insuranceType);
      } else {
        this.insuranceBuilders = [];
      }

      this.loading = false;
    },

    async onBuilderSqlClick(insuranceBuilder) {
      this.insuranceBuilder = insuranceBuilder;

      this.sql = await this.getInsuranceBuilderSql(insuranceBuilder, this.insuranceType)
      this.showSql = true;
    },

    async onBuildersSqlClick() {
      this.sql = await this.getInsuranceBuildersSql(this.insuranceType);
      this.showSql = true;
    },

    async onCloseSqlPopup() {
      this.insuranceBuilder = InsuranceBuilder.empty();
      this.showSql = false;

      this.loading = true;
      await this.onClickStepBtn(this.stepButton);
      this.loading = false;
    },

    async onCloseRelations() {
      this.showRelations = false;

      this.loading = true;
      await this.onClickStepBtn(this.stepButton);
      this.loading = false;
    },

    onCloseCreator() {
      this.showCreator = false;
    },

    async onCloseEditor() {
      this.showEditor = false;

      this.loading = true;
      await this.onClickStepBtn(this.stepButton);
      this.loading = false;
    },

    getBuilderElementsFromBuilders(insuranceBuilders) {
      let tempArray = [];

      insuranceBuilders.forEach((insuranceBuilder) => {
        tempArray = [...tempArray, ...insuranceBuilder.builder.elements];
      })

      return builderElementService.elementsToSingleArray(tempArray);
    },

    getBuildersFormInsuranceBuilders(insuranceBuilders) {
      return insuranceBuilders.map((insuranceBuilder) => insuranceBuilder.builder)
    },

    async onCreateBuilder({builder, additionalData}) {
      this.showCreator = false;

      this.loading = true;
      this.insuranceBuilders = await this.addInsuranceBuilder(this.insuranceType, builder, additionalData);
      await this.onClickStepBtn(this.stepButton);
      this.loading = false;

      notificationService.success("Builder created")
    },

    async onCreateBuilderAndGoToEditor({builder, additionalData}) {
      this.builder = builder;

      this.showCreator = false;
      await this.addInsuranceBuilder(this.insuranceType, builder, additionalData);
      await this.onClickStepBtn(this.stepButton);
      this.showEditor = true;

      notificationService.success("Builder created")
    },

    async onBuilderEditorClick(insuranceBuilder) {
      this.builder = await this.getBuilder(insuranceBuilder.builder.id)

      this.showEditor = true;
    },

    async onDeleteBuilderClick(insuranceBuilder) {
      if (!confirmService.simpleConfirm("Are you really want to delete this Builder?")) {
        return;
      }

      this.loading = true;
      await this.deleteInsuranceBuilder(insuranceBuilder, this.insuranceType)
      await this.onClickStepBtn(this.stepButton);
      this.loading = false;

      notificationService.success("Builder deleted")
    },

    async onUpdateBuilderClick(insuranceBuilder) {
      this.loading = true;

      await this.updateInsuranceBuilder(
          insuranceBuilder,
          this.insuranceType,
          insuranceBuilder.toObject(),
      );
      await this.onClickStepBtn(this.stepButton);

      notificationService.success("Builder info updated")

      this.loading = false;
    },

    getBuilder(builderId) {
      return this.$store.dispatch('admin/getBuilder', {
        builderId,
      })
    },

    addInsuranceBuilder(insuranceType, builder, additionalData) {
      return this.$store.dispatch('admin/insuranceTypeModule/createInsuranceBuilder', {
        insuranceType,
        builder,
        additionalData,
      })
    },

    selectRiskBuilders(insuranceType) {
      return this.$store.dispatch('admin/insuranceTypeModule/riskBuilders', {
        insuranceType
      })
    },

    selectPriceBuilders(insuranceType) {
      return this.$store.dispatch('admin/insuranceTypeModule/priceBuilders', {
        insuranceType
      })
    },

    selectCustomerBuilders(insuranceType) {
      return this.$store.dispatch('admin/insuranceTypeModule/customerBuilders', {
        insuranceType
      })
    },

    selectPolicyBuilders(insuranceType) {
      return this.$store.dispatch('admin/insuranceTypeModule/policeBuilders', {
        insuranceType
      })
    },

    selectBuilders(insuranceType) {
      return this.$store.dispatch('admin/insuranceTypeModule/buildersByInsuranceType', {
        insuranceType
      })
    },

    updateInsuranceBuilder(insuranceBuilder, insuranceType, builderData) {
      return this.$store.dispatch('admin/insuranceTypeModule/updateInsuranceBuilder', {
        insuranceBuilder,
        insuranceType,
        builderData
      })
    },

    deleteInsuranceBuilder(insuranceBuilder, insuranceType) {
      return this.$store.dispatch('admin/insuranceTypeModule/deleteInsuranceBuilder', {
        insuranceBuilder,
        insuranceType,
      })
    },

    getInsuranceBuilderSql(insuranceBuilder, insuranceType) {
      return this.$store.dispatch('admin/insuranceTypeModule/sqlInsuranceBuilder', {
        insuranceBuilder,
        insuranceType,
      })
    },

    getInsuranceBuildersSql(insuranceType) {
      return this.$store.dispatch('admin/insuranceTypeModule/sqlInsuranceBuilders', {
        insuranceType,
      })
    },

    async onDeleteAllBuildersAndRelationsClick() {
      if (!confirmService.simpleConfirm("Are you really want to delete all Builders, Builder Elements, Insurance Elements, Addons and Their Relations?")) {
        return;
      }

      this.loading = true;

      if (await this.exportSqlToStaticFiles()) {

        await this.deleteAllBuildersAndRelations(this.insuranceType.id)
        await this.onClickStepBtn(this.stepButton);

        notificationService.success("All Builders and Their Relations deleted")
      } else {
        notificationService.error("Something went wrong")
      }

      this.loading = false;
    },

    async exportSqlToStaticFiles() {
      try {
        const sql = await this.getInsuranceBuildersSql(this.insuranceType);

        const file = new File([sql], `${this.insuranceType.key}-${dateService.getCurrentDate()}.txt`)
        await this.addFile(this.insuranceType, file)

        return true
      } catch (error) {
        notificationService.error("Error with save file")
      }
    },

    addFile(insuranceType, file) {
      return this.$store.dispatch('admin/insuranceTypeModule/createFile', {
        insuranceType,
        file,
      })
    },

    deleteAllBuildersAndRelations(insuranceTypeId) {
      return this.$store.dispatch('admin/insuranceTypeModule/deleteAllBuildersAndRelations', {
        insuranceTypeId,
      })
    },
  }
}
</script>