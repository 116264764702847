<template>
  <div :style="{padding, margin, width}">
    <money-field
        class="pr-1"
        :name="name"
        :width="width"
        :value="value"
        :placeholder="placeholder"
        :validation="validation"
        @saveField="saveField"
        :disabled="disabled"
    />
  </div>
</template>
<script>
import DefaultElement from "./mixins/DefaultElement.js";
import Padding from "./mixins/Padding.js";
import Placeholder from "./mixins/Placeholder.js";
import Size from "./mixins/Size.js";
import MoneyField from "@/ui/components/common/fields/MoneyField.vue";
import {builderElementsEvents} from "@/utils/events/builder-elements-events.js";
import Validation from "@/ui/components/builder/elements/mixins/Validation";
import {fieldService} from "@/utils/services/field-service";

export default {
  components: {MoneyField},
  mixins: [DefaultElement, Padding, Placeholder, Size, Validation],
  props: {
    disabledBuilderElement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    disabled() {
      return fieldService.isFieldSelected(this.builderElement.data.disabled) || this.disabledBuilderElement
    },
    value() {
      return this.builderElement.value ?? ''
    }
  },
  methods: {
    saveField(value) {
      this.builderElement.value = value;

      builderElementsEvents.updateValue.emit(this.builderElement)
    }
  }
}
</script>