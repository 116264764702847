import {arrayService} from "@/utils/services/array-service.js";
import {
    INSURANCE_PRICE_DATA_KEYS
} from "@/utils/fields/insurance-price-list-field-names.js";

export class InsurancePrice {
    constructor({id, price, data, lists, type_view, is_npopt, packages, selected_package, name, discount, is_empty, insurance_id}) {
        this.id = id;
        this.price = price;
        this.data = data;
        this.lists = lists;
        this.type_view = type_view;
        this.is_npopt = is_npopt;
        this.packages = packages;
        this.selected_package = selected_package;
        this.name = name;
        this.is_empty = is_empty;
        this.insurance_id = insurance_id;

        this.setDiscount(discount)
    }

    static createEmpty() {
        return new InsurancePrice({
            price: 0,
            data: {
                deductible: '',
                duration: '',
                paymentPeriod: '',
                sumInsured: '',
                maxSumInsured: '',
                isNoticeLimitationLiability: null,
                isHigherMaximization: '',
                higherMaximization: '',
                sumInsuredNumber: '',
                fieldsEditability: null,
                is58RadioButton: null,
            },
            lists: {
                deductible: '',
                duration: '',
                paymentPeriod: '',
                sumInsured: '',
                maxSumInsured: '',
                higherMaximization: '',
            },
            type_view: '',
            is_npopt: 0,
            discount: 0,
            packages: [],
            selected_package: '',
            name: '',
            is_empty: 0,
            insurance_id: null,
        })
    }

    getId() {
        return this.id;
    }

    getPrice() {
        return this.price;
    }

    getPaymentPeriod() {
        return this.data?.paymentPeriod;
    }

    getDuration() {
        return this.data?.duration;
    }

    getDeductible() {
        return this.data?.deductible;
    }

    getSumInsured() {
        return this.data?.sumInsured;
    }

    getMaxSumInsured() {
        return this.data?.maxSumInsured;
    }

    getIsHigherMaximization() {
        return this.data?.isHigherMaximization;
    }

    getHigherMaximization() {
        return this.data?.higherMaximization;
    }

    getIsNoticeLimitationLiability() {
        return this.data?.isNoticeLimitationLiability;
    }

    getDeductibleTooltip() {
        return this.data?.deductibleTooltip;
    }

    getLists() {
        return this.lists;
    }

    getDurationLabel() {
        return this.getDuration()
    }

    getDeductibleLabel() {
        return this.getDeductible()
    }

    getSumInsuredLabel() {
        return this.getSumInsured()
    }

    getMaxSumInsuredLabel() {
        return this.getMaxSumInsured()
    }

    getPaymentPeriodLabel() {
        return this.getPaymentPeriod()
    }

    getEditableByFieldKey(fieldKey) {
        const editableFieldsData = this.data?.[INSURANCE_PRICE_DATA_KEYS.fieldsEditability]?.[fieldKey];

        return editableFieldsData !== false;
    }

    getLabelFromLists(key, nameList)
    {
        const lists = this.getLists()

        if (!nameList || !lists) {
            return
        }

        return  arrayService.findObjectByKey(lists[nameList], key)['label']
    }

    getDurationValue() {
        const durationKey = this.getDuration()
        const lists = this.getLists()

        if (!durationKey || !lists) {
            return
        }

        return  arrayService.findObjectByKey(lists.duration, durationKey)['label']
    }

    getDataItem(key) {
        if(this.data) {
            return this.data[key];
        }

        return null;
    }

    getData() {
        return this.data
    }

    getSumInsuredNumber() {
        return this.data?.sumInsuredNumber;
    }

    getTypeView() {
        return this.type_view
    }

    getIsNpopt() {
        return Boolean(this.is_npopt)
    }

    getPackages() {
        return this.packages
    }

    getSelectedPackage() {
        return this.selected_package
    }

    getName() {
        return this.name;
    }

    isEmpty() {
        return this.is_empty
    }

    getInsuranceId() {
        return this.insurance_id;
    }

    getSurcharge() {
        return this.data?.surcharge || 0;
    }

    // Used toFixed(2) because when we get for example -0.05 js converted it to -0.050000000000000044
    setDiscount(discount) {
        if (discount === 0) {
            this.discount = 0
        } else {
            this.discount = parseFloat(discount.toFixed(2))
        }
    }
}