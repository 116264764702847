<template>
  <div>
    <div class="app-bg-white pt-5 mb-10 pb-5">
      <div class="ml-5 mr-5">
        <h3 class="app-text__bold">Vertragsschlussverfahren</h3>
        <div class="mt-5">
          <p>Der Versicherungsvertrag kommt zustande, wenn der Antrag des Kunden vom Versicherer angenommen wird.</p>
          <p>Der Versicherer erklärt seine Annahme, in dem er dem Kunden den Versicherungsschein zusendet.</p>
          <p>Möglichkeiten des Kunden, seinen Antrag zu stellen, durch:</p>
        </div>
        <v-row class="mr-5">
          <v-col class="mt-5" cols="4">
            <radio-buttons-vertical
                :name="dataFieldKeys.paymentOption"
                :buttons="contractFormationRadioButtons"
                :value="paymentOption"
                :right="true"
                :disabled="isDisableAllInputs"
                @saveField="changeContractFormation"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <option1 v-if="isShowOption1" @clickNext="clickNext" @clickPrev="clickPrev"/>
    <option2 v-else-if="isShowOption2" @clickNext="clickNext" @clickPrev="clickPrev"/>
    <estate-agent-azam-option v-else-if="isShowEstateAgentOption" @clickNext="clickNext" @clickPrev="clickPrev"/>
  </div>
</template>

<script>
import RadioButtonsVertical from "@/ui/components/common/fields/radio/RadioButtonsVertical.vue"
import Option1 from "@/ui/components/insurance/police-tab/step3/Option1.vue"
import Option2 from "@/ui/components/insurance/police-tab/step3/Option2.vue"
import {
  CONTRACT_FORMATION,
  CONFIRMATION_CLICK,
  ELECTRONIC_SIGNATURE,
  STEP3_OPTIONS_BUTTON_NAMES,
  ESTATE_AGENT,
} from "@/utils/fields/insurance-police-step-fields.js"
import {DATA_FIELD_KEYS} from "@/utils/fields/insurance-police-field-keys.js"
import EstateAgentAzamOption from "@/ui/components/insurance/police-tab/step3/EstateAgentAzamOption.vue"

export default {
  name: "Step3",
  components: {
    RadioButtonsVertical,
    Option1,
    Option2,
    EstateAgentAzamOption,
  },
  computed: {
    confirmationClick() {
      return CONFIRMATION_CLICK
    },
    isShowOption1() {
      return this.paymentOption === this.confirmationClick
    },
    isShowOption2() {
      return this.paymentOption === this.electronicSignature
    },
    electronicSignature() {
      return ELECTRONIC_SIGNATURE
    },
    insurancePolice() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getInsurancePolice"]
    },
    dataFieldKeys() {
      return DATA_FIELD_KEYS
    },
    customerEmailValue() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.customerEmail]
    },
    paymentOption() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.paymentOption]
    },
    isEstateAgentAZAM() {
      return this.$store.getters.authUser.isEstateAgentAZAM();
    },
    isShowEstateAgentOption() {
      return this.paymentOption === ESTATE_AGENT
    },
    isDisableAllInputs() {
      return Boolean(this.$store.getters["insuranceModule/getFinishedAt"])
    },
  },
  data: () => ({
    nextButtonText: STEP3_OPTIONS_BUTTON_NAMES.deliveredToCustomers,
    contractFormationRadioButtons: CONTRACT_FORMATION,
  }),
  created() {
    this.init()
  },
  methods: {
    init() {
      if (this.isEstateAgentAZAM) {
        const index = this.contractFormationRadioButtons.findIndex(obj => obj.value === ESTATE_AGENT)
        index === -1 ? this.contractFormationRadioButtons.push({text: ESTATE_AGENT, value: ESTATE_AGENT}) : null
      }

    },
    async clickPrev() {
      this.$emit('clickPrev')
    },
    async clickNext() {
      this.$emit('clickNext')
    },
    async changeContractFormation(val, key) {
      this.changeNextButtonText(val)

      this.$store.commit('insuranceModule/insurancePoliceModule/setDataField', {key, val})

      await this.updatePolice(this.insurancePolice.id, this.insurancePolice)
    },
    changeNextButtonText(val) {
      if (val === this.confirmationClick) {
        this.nextButtonText = STEP3_OPTIONS_BUTTON_NAMES.deliveredToCustomers
      } else {
        this.nextButtonText = STEP3_OPTIONS_BUTTON_NAMES.lock
      }
    },
    saveRadioField(val, key) {
      this.$store.commit('insuranceModule/insurancePoliceModule/setDataField', {key, val});
    },
    async updatePolice(insurancePoliceId, data) {
      await this.$store.dispatch('insuranceModule/insurancePoliceModule/updateInsurancePolice', {
            insurancePoliceId, data
          }
      );
    },
  },
}
</script>
