<template>
  <div>
    <div class="d-flex justify-space-between">
      <div>
        <h3 class="font-weight-black price-item-title" v-html="priceName"></h3>
      </div>
      <div>
        <h3 class="font-weight-black price-item-title">{{priceItem.price | toMoneyFormat}}</h3>
      </div>
    </div>
    <div class="additional-text app-text mt-4 " v-if="priceItem.additionalText">
      <span v-for="(text, index) in priceItem.additionalText" :key="index">
        <addon-checked-text>{{text}}</addon-checked-text>
        <br>
      </span>
    </div>

  </div>
</template>
<script>
import {InsurancePriceItem} from "@/models/insurance-price-item.js";
import AddonCheckedText from "@/ui/components/insurance/insurance-addons/elements/AddonCheckedText.vue";

export default {
  components: {
    AddonCheckedText,
  },
  props: {
    priceItem: {
      type: InsurancePriceItem,
      required: true,
    }
  },
  computed: {
    priceName() {
      return this.priceItem.name.replace('gf.Ges.', '');
    }
  }
}
</script>
<style lang="scss" scoped>
.additional-text {
  width: 80%;
}
.price-item-title {
  line-height: 1.2em;
}
</style>