import {builderElementService} from "@/utils/services/builder-element-service";

export const isSumByFieldsMoreThan = ({condition_value, allBuilderElements}) => {
    const [elementNamesStr, compareValue] = condition_value.split('>');
    const targetSum = Number(compareValue);

    const elementNames = elementNamesStr.split(';').map(itm => itm.trim());
    const builderElements = builderElementService.findAllElementsByParameters(allBuilderElements, 'name', elementNames)

    const sum = builderElements.reduce(
        (total, currentValue) => total + Number(currentValue.value),
        0
    );

    return sum > targetSum;
}