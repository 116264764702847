<template>
  <div>
    <span class="font-s-16 letter-spacing-1 font-bold-blue user-name-block">
      {{ authUserFirstName }}
    </span>
    <span class="d-block">
      {{ authUserRoleLabel }}
    </span>
  </div>
</template>
<script>
export default {
  computed: {
      authUserFullName() {
        return this.$store.getters.authUserFullName;
      },

      authUserFirstName() {
        return this.$store.getters.authUserFirstName;
      },

      authUserRoleLabel() {
        return this.$store.getters.authUserRoleLabel;
      }
  }
}
</script>
