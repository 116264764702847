export const INSURANCE_PRICE_LIST_FIELD_NAMES = {
    duration: 'duration',
    deductible: 'deductible',
    sumInsured: 'sumInsured',
    paymentPeriod: 'paymentPeriod',
    maxSumInsured: 'maxSumInsured',
    isHigherMaximization: 'isHigherMaximization',
    higherMaximization: 'higherMaximization',
    sumInsuredNumber: 'sumInsuredNumber',
};

export const INSURANCE_PRICE_DATA_KEYS = {
    isNoticeLimitationLiability: 'isNoticeLimitationLiability',
    fieldsEditability: 'fieldsEditability',
    isOtherSpecificTaxes: 'isOtherSpecificTaxes',
}

export const INSURANCE_PRICE_PAYMENT_PERIOD_LABELS = {
    year: 'jährlich',
    halfYear: 'halbjährlich',
    quart: 'vierteljährlich',
}

export const PERIOD_VALUES = {
    [INSURANCE_PRICE_PAYMENT_PERIOD_LABELS.year]: 1,
    [INSURANCE_PRICE_PAYMENT_PERIOD_LABELS.halfYear]: 2,
    [INSURANCE_PRICE_PAYMENT_PERIOD_LABELS.quart]: 4,
}
