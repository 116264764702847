export const authApi = (http) => ({
    login: (email, password) => http.post('/api/auth/login', {
        email,
        password
    }),

    check: (token) => {
        http.setAuthToken(token)

        return http.post('/api/auth/check')
    },

    logout: () => http.post('/api/auth/logout'),

    update: (data) => http.post('/api/auth/update', data),
})