import AdminLayout from "../../ui/layouts/admin/AdminLayout.vue";
import {isAdminMiddleware} from "../middlewares/isAdmin.js";
import {routeNames} from "../route-names.js";
import Dashboard from "../../ui/pages/admin/Dashboard.vue";
import DefaultType from "@/ui/pages/admin/InsuranceType.vue";
import Xml from "@/ui/pages/admin/Xml.vue";

export const adminRoutes = [
    {
        path: '/admin',
        component: AdminLayout,
        beforeEnter: isAdminMiddleware,
        children: [
            {
                path: '/admin',
                name: routeNames.admin.dashboard,
                component: Dashboard,
            },
            {
                path: '/admin/insurance-type/:type',
                name: routeNames.admin.insuranceType,
                component: DefaultType,
                props: route => ({ type: route.params.type }),
            },
            {
                path: '/admin/xml',
                name: routeNames.admin.xml,
                component: Xml,
                props: route => ({ type: route.params.xml }),
            },
        ],
    },
];