<template>
  <div>
    <div class="text-center">
      <h3>Date Field</h3>
    </div>

    <v-row>
      <v-col cols="12">
        <name-editor
            v-model="builderElement.name"
        />
      </v-col>

      <v-col cols="6">
        <priority-editor
            v-model.number="builderElement.priority"
        />
      </v-col>

      <v-col class="pl-4" cols="6">
        <width-editor
            v-model="builderElement.data.width"
        />
      </v-col>

      <v-col cols="6">
        <p class="app-text__bold">Default Value</p>

        <div class="d-flex">
          <datepicker-field
              name="value"
              v-model="builderElement.value"
          />

          <close-icon @click="onClearValue" />
        </div>

      </v-col>

      <v-col cols="6" class="pl-4">
        <placeholder-editor
            v-model="builderElement.data.placeholder"
        />
      </v-col>
    </v-row>

    <toggle-block
        header="Validation"
        :opened="isValidationVisible"
        @click="onValidationToggle"
        class="my-2 pb-2"
    >
      <v-col cols="6">
        <p class="app-text__bold">Range validation</p>
        <checkbox
            text="Use date range"
            :value="builderElement.validation.range.isRangeValidation ? '1' : '0'"
            @input="changeIsRangeValidation"
        />
      </v-col>
      <h3>Range validation</h3>
      <date-field-validation-editor
          v-if="builderElement.validation.range.start"
          header="Start range"
          :validation="builderElement.validation.range.start"
          :has-validation-message="false"
          :edit-condition-type="true"
      />

      <date-field-validation-editor
          v-if="builderElement.validation.range.end"
          class="mt-4"
          header="End range"
          :validation="builderElement.validation.range.end"
          :has-validation-message="false"
          :edit-condition-type="true"
      />

      <v-col cols="12" class="pt-2">
        <p class="app-text__bold">Validation Message</p>

        <text-area-field
            name="message"
            v-model="builderElement.validation.range.message"
        />

      </v-col>
      <date-field-validation-editor
          v-if="builderElement.validation.maxDate"
          header="Max Date"
          :validation="builderElement.validation.maxDate"
      />

      <date-field-validation-editor
          class="mt-4"
          v-if="builderElement.validation.minDate"
          header="Min Date"
          :validation="builderElement.validation.minDate"
      />
    </toggle-block>

    <v-col>
      <is-disabled
          v-model="builderElement.data.isDisabled"
      />
    </v-col>

    <margin-editor
        v-model="builderElement.data.margin"
    />

    <h3 >Popup</h3>
    <v-col cols="6">
      <checkbox
          text="Use popup"
          :value="usePopup"
          @input="changeUsePopup"
      />
    </v-col>
    <v-col cols="12" class="pt-2">
      <p class="app-text__bold">Popup title</p>
      <text-field
          name="popup_header"
          :validation="{required: true}"
          :value="popupHeader"
          @saveField="savePopupTitle"
      />
    </v-col>
    <v-col cols="12" class="pt-2">
      <p class="app-text__bold">Popup button text</p>
      <text-field
          name="popup_button_text"
          :validation="{required: true}"
          :value="popupButtonText"
          @saveField="savePopupButtonText"
      />
    </v-col>
    <v-col cols="12" class="pt-2">
      <p class="app-text__bold">Popup text</p>
      <text-area-field
          name="popup_text"
          :value="popupText"
          @saveField="savePopupText"
      />
    </v-col>
    <h3>Show popup date range condition</h3>
    <date-field-validation-editor
        v-if="builderElement.data.popupValidation.start"
        header="Start range"
        :validation="builderElement.data.popupValidation.start"
        :has-validation-message="false"
        :edit-condition-type="true"
    />

    <date-field-validation-editor
        v-if="builderElement.data.popupValidation.end"
        class="mt-4"
        header="End range"
        :validation="builderElement.data.popupValidation.end"
        :has-validation-message="false"
        :edit-condition-type="true"
    />
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import WidthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/WidthEditor.vue";
import PlaceholderEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PlaceholderEditor.vue";
import MarginEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MarginEditor.vue";
import DatepickerField from "@/ui/components/common/fields/DatepickerField.vue";
import ToggleBlock from "@/ui/components/common/blocks/ToggleBlock.vue";
import {DATE_CONDITION, DateFieldValidation} from "@/utils/types/date-field-validation.js";
import DateFieldValidationEditor
  from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/DateFieldValidationEditor.vue";
import {confirmService} from "@/utils/services/confirm-service.js";
import CloseIcon from "@/ui/components/common/icons/CloseIcon.vue";
import Checkbox from "@/ui/components/common/fields/Checkbox.vue";
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";
import IsDisabled from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/IsDisabled.vue";
import TextField from "@/ui/components/common/fields/TextField.vue";

export default {
  components: {
    CloseIcon,
    DateFieldValidationEditor,
    ToggleBlock, DatepickerField, MarginEditor, PlaceholderEditor, WidthEditor, PriorityEditor, NameEditor,
    Checkbox, TextAreaField,
    IsDisabled,
    TextField,
  },
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  },

  computed: {
    popupHeader() {
      return this.builderElement.data?.popupHeader || '';
    },

    popupButtonText() {
      return this.builderElement.data?.popupButtonText || '';
    },

    popupText() {
      return this.builderElement.data?.popupText || '';
    },

    usePopup() {
      return this.builderElement.data.usePopup ? '1' : '0';
    }
  },

  data: () => ({
    isValidationVisible: false,
  }),

  created() {
    this.isValidationVisible = this.checkValidation();

    try {
      this.prepareValidation();
      this.initPopupValidation();
    } catch {
      this.builderElement.validation = {};
      this.isValidationVisible = false;
    }
  },

  methods: {
    onClearValue() {
      this.builderElement.value = '';
    },

    prepareValidation() {
      if(this.builderElement.validation.maxDate) {
          this.builderElement.validation.maxDate = new DateFieldValidation(this.builderElement.validation.maxDate);
      }

      if(this.builderElement.validation.minDate) {
        this.builderElement.validation.minDate = new DateFieldValidation(this.builderElement.validation.minDate);
      }

      this.initRangeValidation()
    },

    onValidationToggle(isVisible) {
      if (isVisible) {
        this.builderElement.validation.maxDate = this.builderElement.validation.maxDate || DateFieldValidation.default(DATE_CONDITION.MORE);
        this.builderElement.validation.minDate = this.builderElement.validation.minDate || DateFieldValidation.default(DATE_CONDITION.LESS);

        this. initRangeValidation();
      } else {
        const result = confirmService.simpleConfirm("Are you want to remove validation?")

        if(result) {
          this.builderElement.validation = {};
        }
      }

      this.isValidationVisible = isVisible;
    },

    initPopupValidation() {
      if (!this.builderElement.data.popupValidation) {
        this.builderElement.data.popupValidation = {
          start: null,
          end: null,
        };
      }

      if (this.builderElement.data.popupValidation.start) {
        this.builderElement.data.popupValidation.start = new DateFieldValidation(this.builderElement.data.popupValidation.start);
      } else {
        this.builderElement.data.popupValidation.start = DateFieldValidation.default(DATE_CONDITION.MORE);
      }

      if (this.builderElement.data.popupValidation.end) {
        this.builderElement.data.popupValidation.end = new DateFieldValidation(this.builderElement.data.popupValidation.end);
      } else {
        this.builderElement.data.popupValidation.end = new DateFieldValidation.default(DATE_CONDITION.MORE);
      }
    },

    checkValidation() {
      for (let key in this.builderElement.validation) {
        if (this.builderElement.validation[key]) {
          return true;
        }
      }

      return false;
    },

    initRangeValidation() {
      this.builderElement.validation.range = {
        start: this.initValidationObject('start') || DateFieldValidation.default(DATE_CONDITION.LESS),
        end:   this.initValidationObject('end') || DateFieldValidation.default(DATE_CONDITION.LESS),
        message: this.builderElement.validation.range && this.builderElement.validation.range.message || '',
        isRangeValidation: this.builderElement.validation.range && this.builderElement.validation.range.isRangeValidation || false,
      }
    },

    initValidationObject(rangeType) {
      if (this.builderElement.validation.range && this.builderElement.validation.range[rangeType]) {
        if (this.builderElement.validation.range[rangeType] instanceof DateFieldValidation) {
          return this.builderElement.validation.range[rangeType];
        }

        return new DateFieldValidation(this.builderElement.validation.range[rangeType]);
      }

      return null
    },

    changeIsRangeValidation(value) {
      this.builderElement.validation.range.isRangeValidation = value === '1'
    },

    changeUsePopup(value) {
      this.builderElement.data.usePopup = value === '1'
    },

    savePopupTitle(value) {
      this.builderElement.data.popupHeader = value;
    },

    savePopupButtonText(value) {
      this.builderElement.data.popupButtonText = value;
    },

    savePopupText(value) {
      this.builderElement.data.popupText = value;
    }
  }
}
</script>