<template>
  <div>
    <h1 class="mb-4 text-center">{{ builder.name || 'Builder Elements' }}</h1>

    <div class="builder-elements pr-2">
      <div class="no-events">
        <builder-component
            v-if="builder"
            :builder="builder"
            :admin-edit-mode="true"
        />
      </div>
    </div>

  </div>
</template>
<script>
import BuilderComponent from '@/ui/components/builder/Builder.vue'
import {Builder} from "@/models/builder.js";

export default {
  components: {BuilderComponent},
  props: {
    builder: {
      type: Builder,
      required: true,
    }
  },
}
</script>