<template>
  <v-row justify="center">
    <v-dialog
        v-model="show"
        persistent
        max-width="400px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Insurance type</span>
        </v-card-title>
        <v-card-text class="mt-8">
          <v-form
              :ref="formName"
          >
            <v-row>
              <v-col cols="12">
                <div>
                  <p class="app-text__bold">Name</p>

                  <text-field
                      name="name"
                      v-model="insuranceType.name"
                      :validation="{required: true}"
                  />
                </div>

              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <orange-btn @click="onClickClose">
            Close
          </orange-btn>

          <blue-btn @click="onClickSave">
            Save
          </blue-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import TextField from "@/ui/components/common/fields/TextField.vue";

export default {
  components: {TextField, OrangeBtn, BlueBtn},
  props: {
    insuranceType: {
      type: Object,
      required: true,
    }
  },
  computed: {
    formName() {
      return 'InsuranceType'
    },

    form() {
      return this.$refs[this.formName];
    }
  },
  data: () => ({
    show: true,
  }),
  methods: {
    onClickSave() {
      if (this.form.validate()) {
        this.$emit('updateType', this.insuranceType)
        this.onClickClose();
      }
    },

    onClickClose() {
      this.$emit('close')
    }
  }
}
</script>