import {serverApi} from "@/api/server/server-api.js";
import {InsurancePriceAddonRelation} from "@/models/insurance-price-addon-relations/insurance-price-addon-relation.js";

export const insurancePriceAddonRelationsModule = {
    namespaced: true,
    actions: {
        getInsurancePriceAddonRelations: async (context, {insuranceType}) => {
            const {data} = await serverApi
                .admin
                .insurancePriceAddonRelations
                .getInsurancePriceAddonRelations(insuranceType.id);

            if (!data.success) return
            return data.insurancePriceAddonRelations
                .map((item) => new InsurancePriceAddonRelation(item));
        },

        createInsurancePriceAddonRelation: async(context, {insuranceType, insurancePriceAddonRelation}) => {
            const {data} = await serverApi
                .admin
                .insurancePriceAddonRelations
                .createInsurancePriceAddonRelation(insuranceType.id, insurancePriceAddonRelation.toObject())

            return data.success;
        },

        updateInsurancePriceAddonRelation: async (context, {insurancePriceAddonRelation}) => {
            const {data} = await serverApi
                .admin
                .insurancePriceAddonRelations
                .updateInsurancePriceAddonRelation(insurancePriceAddonRelation.id, insurancePriceAddonRelation.toObject())

            return data.success;
        },

        deleteInsurancePriceAddonRelation: async(context, {insurancePriceAddonRelation}) => {
            const {data} = await serverApi
                .admin
                .insurancePriceAddonRelations
                .deleteInsurancePriceAddonRelation(insurancePriceAddonRelation.id)

            return data.success;
        },
    }
}