import Vue from 'vue'
import {objectService} from "@/utils/services/object-service.js";

export const notificationService = {

    success(options) {
        if (objectService.isObject(options)) {
            Vue.notify({
                ...options,
                type: 'primary',
            })
        } else {
            Vue.notify({
                text: options,
                type: 'primary',
            });
        }
    },

    error(options) {
        if (objectService.isObject(options)) {
            Vue.notify({
                ...options,
                type: 'error',
            })
        } else {
            Vue.notify({
                text: options,
                type: 'error',
            });
        }
    },

}