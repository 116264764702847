export const insuranceElementRelationsApi= (http) => ({

    getInsuranceElementRelations: (insuranceTypeId) => http.get(`/api/admin/insurance-relations/${insuranceTypeId}`),

    createInsuranceElementRelation: (insuranceTypeId, data) => http.post(`/api/admin/insurance-relations/${insuranceTypeId}/create`, {
        description: data.description,
        actions: data.insuranceElementActions,
        conditions: data.insuranceElementConditions,
        conditionGroups: data.insuranceElementConditionGroups,
    }),

    updateInsuranceElementRelation: (relationId, data) => http.post(`/api/admin/insurance-relations/${relationId}/update`, {
        description: data.description,
        actions: data.insuranceElementActions,
        conditions: data.insuranceElementConditions,
        conditionGroups: data.insuranceElementConditionGroups,
    }),

    deleteInsuranceElementRelation: (relationId) => http.post(`/api/admin/insurance-relations/${relationId}/delete`)
});