<template>
  <div class="d-flex">
    <v-checkbox
        background-color="transparent"
        class="app-checkbox"
        :ripple="false"
        color="var(--light-blue)"
        off-icon="mdi-checkbox-blank-outline"
        on-icon="mdi-checkbox-intermediate"
        :input-value="value"
        :value="value"
        :true-value="'1'"
        :false-value="'0'"
        :disabled="disabled"
        :rules="validationRules"
        @change="saveField"
    />
    <div class="mt-1" :class="labelClass" v-html="text"></div>
  </div>
</template>
<script>
import {equalToElementValue, requiredCheckbox} from "@/utils/validation-rules";

export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [String],
      default: '0',
    },
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    allBuilderElements() {
      return this.$store.getters["insuranceModule/builderElementsModule/getAllBuilderElements"];
    },
    validationRules() {
      const rules = [];

      if (this.validation.required && this.validation.required !== '0') {
        rules.push((val, message = this.validation.message || 'Pflichtfeld') => requiredCheckbox(val, message));
      }

      if (this.validation.equalTo && this.validation.equalTo.elementId) {
        rules.push(
            (val, message = this.validation.equalTo.message || 'Error') =>
                equalToElementValue(val, this.validation.equalTo.elementId, this.allBuilderElements, message)
        );
      }

      return rules;
    },
    labelClass() {
      return this.disabled ? 'disabled-checkbox-label' : '';
    }
  },
  methods: {
    saveField(value) {
      this.$emit('saveField', value, this.name)
      this.$emit('input', value);
    }
  }
}
</script>
<style lang="scss">
.app-checkbox .v-input--selection-controls__input {
  i {
    font-size: 26px;
  }
}

.app-checkbox .v-input--selection-controls__input:hover {

  i:before {
    content: "\F0856";
  }
}
.disabled-checkbox-label {
  color: gray;
}
</style>