export const insurancePriceAddonRelationsApi = (http) => ({

    getInsurancePriceAddonRelations: (insuranceTypeId) => http.get(`/api/admin/insurance-price-addon-relations/${insuranceTypeId}`),

    createInsurancePriceAddonRelation: (insuranceTypeId, data) => http.post(`/api/admin/insurance-price-addon-relations/${insuranceTypeId}/create`, {
        description: data.description,
        actions: data.insurancePriceAddonActions,
        conditions: data.insurancePriceAddonConditions,
        conditionGroups: data.insurancePriceAddonConditionGroups,
    }),

    updateInsurancePriceAddonRelation: (relationId, data) => http.post(`/api/admin/insurance-price-addon-relations/${relationId}/update`, {
        description: data.description,
        actions: data.insurancePriceAddonActions,
        conditions: data.insurancePriceAddonConditions,
        conditionGroups: data.insurancePriceAddonConditionGroups,
    }),

    deleteInsurancePriceAddonRelation: (relationId) => http.post(`/api/admin/insurance-price-addon-relations/${relationId}/delete`)
});