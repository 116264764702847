<template>
  <div class="pb-4 pt-2">
    <h3 class="text-center">Price Api Request</h3>

    <hr class="my-3">
    <center-block class="mb-12 mt-16" v-if="loading">
      <app-preloader/>
    </center-block>
    <v-row class="mt-4" v-else>
      <v-col cols="6" class="pr-2">
        <json-preview
            :insurance-api="requestApi"
            header="Request"
            @parseScheme="onParseScheme"
        />
      </v-col>
      <v-col cols="6" class="pl-2">
        <json-preview
            :insurance-api="responseApi"
            header="Response"
            @parseScheme="onParseScheme"
        />
      </v-col>
    </v-row>

  </div>
</template>
<script>
import {notificationService} from "@/utils/services/notification-service.js";
import {InsuranceType} from "@/models/insurance-type.js";
import {InsuranceApi} from "@/models/insurance-api.js";
import CenterBlock from "@/ui/components/common/blocks/CenterBlock.vue";
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue";
import JsonPreview from "@/ui/components/admin/insurance-type/api-section/JsonPreview.vue";
import {insuranceApiEnum} from "@/enums/insurance-api-enum.js";

export default {
  components: {
    JsonPreview,
    AppPreloader,
    CenterBlock,
  },

  props: {
    insuranceType: {
      type: InsuranceType,
      required: true,
    }
  },

  data: () => ({
    showParsePopup: false,

    loading: true,

    responseApi: InsuranceApi.empty(),
    requestApi: InsuranceApi.empty(),
  }),

  async created() {
    setTimeout(async () => {
      await this.fillApiData();
      this.loading = false;
    }, 1000)
  },

  methods: {
    async onParseScheme({scheme, type, apiType}) {
      this.loading = true;

      this.builder = await this.parseScheme(scheme, type);

      const success = apiType === insuranceApiEnum.PRICE_API_REQUEST
          ? await this.assignPriceRequest(this.insuranceType, this.builder)
          : await this.assignPriceResponse(this.insuranceType, this.builder);

      if (success) {
        await this.fillApiData();

        this.loading = false;

        notificationService.success('Json was parsed successfully')
      }
    },

    async fillApiData() {
      const {responseApi, requestApi} = await this.getInsuranceTypeApi(this.insuranceType);

      this.responseApi = responseApi;
      this.requestApi = requestApi;
    },

    parseScheme(scheme, type) {
      return this.$store.dispatch('admin/parseScheme', {
        scheme,
        type,
      })
    },

    assignPriceRequest(insuranceType, builder) {
      return this.$store.dispatch('admin/insuranceApiModule/assignPriceRequest', {
        insuranceType,
        builder,
      })
    },

    assignPriceResponse(insuranceType, builder) {
      return this.$store.dispatch('admin/insuranceApiModule/assignPriceResponse', {
        insuranceType,
        builder,
      })
    },

    getInsuranceTypeApi(insuranceType) {
      return this.$store.dispatch('admin/insuranceApiModule/getApiByInsuranceType', {
        insuranceType,
      })
    }
  }
}
</script>
