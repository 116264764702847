<template>
  <v-btn
      @click="onClick"
      :height="height"
      :width="width"
      class="app-btn white--text"
      :disabled="disabled"
      outlined
      rounded
  >
    <slot v-if="!loading"/>

    <app-preloader class="pb-1" size="20" color="white" v-else />
  </v-btn>
</template>
<script>
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue";
export default {
  components: {AppPreloader},
  props: {
    height: {
      type: String,
      default: '35px',
    },
    width: {
      type: String,
      default: 'auto'
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
}
</script>
<style scoped>
.app-btn {
  background: var(--light-blue);
  border: none;
  box-shadow: none;
}
</style>