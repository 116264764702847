import Vue from 'vue'
import VueRouter from 'vue-router'
import {routeNames} from "./route-names.js";
import {adminRoutes} from "./routes/admin-routes.js";
import {authRoutes} from "./routes/auth-routes.js";
import {appRoutes} from "./routes/app-routes.js";
import {infoRoutes} from "./routes/info-routes.js";

Vue.use(VueRouter)

const routes = [
    ...adminRoutes,

    ...authRoutes,

    ...appRoutes,

    ...infoRoutes,

    {
        path: "*",
        redirect: {name: routeNames.insuranceCreate}
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
