import {objectService} from "@/utils/services/object-service.js";
import {BuilderElementRelationCondition} from "@/models/builder-element-relations/builder-element-relation-condition.js";
import {BuilderElementRelationAction} from "@/models/builder-element-relations/builder-element-relation-action.js";
import {BuilderElementRelationConditionGroup} from "@/models/builder-element-relations/builder-element-relation-condition-group";

export class BuilderElementRelation {

    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(BuilderElementRelation.empty().toObject())
            : this.fillData(data);
    }
//todo finish add run_on_test for run relations only test server
    fillData(data) {
        this.id = data.id
        this.builder_id = data.builder_id;
        this.position = data.position;
        this.description = data.description;
        this.is_validation = data.is_validation;
        this.show_validation_on = data.show_validation_on;
        this.run_on_test = data.run_on_test;
        this.builderElementActions = data.builderElementActions.map((action) => new BuilderElementRelationAction(action));
        this.builderElementConditions = data.builderElementConditions.map((cond) => new BuilderElementRelationCondition(cond));
        this.builderElementConditionGroups = data.builderElementConditionGroups.map((element) => new BuilderElementRelationConditionGroup(element));
    }

    isEmpty() {
        return this.id === '';
    }

    static empty(data = {}) {
        return new BuilderElementRelation({
            id: data.id || '',
            builder_id: data.builder_id || null,
            description: data.description || '',
            position: data.position || 0,
            is_validation: data.is_validation || 0,
            show_validation_on: data.show_validation_on || 0,
            run_on_test: data.run_on_test || 0,
            builderElementActions: Array.isArray(data.builderElementActions) ? data.builderElementActions : [],
            builderElementConditions: Array.isArray(data.builderElementConditions) ? data.builderElementConditions: [],
            builderElementConditionGroups: Array.isArray(data.builderElementConditionGroups) ? data.builderElementConditionGroups: [],
        })
    }

    toObject() {
        return {
            ...this
        }
    }

}