<template>
  <v-row justify="center">
    <v-dialog
        v-model="show"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">New Element</span>
        </v-card-title>
        <v-card-text class="mt-8">
          <v-form
              :ref="formName"
          >
            <v-row>
              <v-col cols="6">
                <div>
                  <p class="app-text__bold">Name</p>

                  <text-field
                      name="name"
                      v-model="insuranceElement.name"
                      :validation="{required: true}"
                  />
                </div>

              </v-col>
              <v-col cols="6" class="pl-12">
                <div>
                  <p class="app-text__bold">Priority</p>

                  <number-field
                      name="priority"
                      v-model="insuranceElement.priority"
                      :validation="{required: true}"
                  />
                </div>

                <div>
                  <p class="app-text__bold">Is Visible</p>

                  <switch-field
                      name="is_show"
                      v-model="insuranceElement.is_show"
                  />
                </div>

              </v-col>
            </v-row>


          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <orange-btn @click="onClickClose">
            Close
          </orange-btn>

          <blue-btn @click="onClickSave">
            Save
          </blue-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import TextField from "@/ui/components/common/fields/TextField.vue";
import NumberField from "@/ui/components/common/fields/NumberField.vue";
import SwitchField from "@/ui/components/common/fields/SwitchField.vue";
import {InsuranceElement} from "@/models/insurance-element.js";

export default {
  components: {SwitchField, NumberField, TextField, OrangeBtn, BlueBtn},
  props: {
    insuranceElement: {
      type: InsuranceElement,
      required: true,
    }
  },
  computed: {
    formName() {
      return 'InsuranceElement'
    },

    form() {
      return this.$refs[this.formName];
    }
  },
  data: () => ({
    show: true,
  }),
  methods: {
    onClickSave() {
      if (this.form.validate()) {
        this.$emit('updateElement', this.insuranceElement)
        this.onClickClose();
      }
    },

    onClickClose() {
      this.$emit('close')
    }
  }
}
</script>