import {serverApi} from "@/api/server/server-api.js";
import {dashboardInfoEnum} from "@/enums/dashboard-info-enum";
import {DashboardInfo} from "@/models/dashboard-info.js";

export const dashboardInfoModule = {
  namespaced: true,
  state: {
    stagingUpdatedAtElement: DashboardInfo.createEmpty(),
    prodUpdatedAtElement: DashboardInfo.createEmpty(),
  },
  getters: {
    getStagingUpdatedAtElement: (state) => state.stagingUpdatedAtElement,
    getProdUpdatedAtElement: (state) => state.prodUpdatedAtElement,
  },
  mutations: {
    setStagingUpdatedAtElement: (state, element) => {
      state.stagingUpdatedAtElement = element
    },
    setProdUpdatedAtElement: (state, element) => {
      state.prodUpdatedAtElement = element
    },
  },
  actions: {
    getDashboardInfoElement: async (context, name) => {
      const {data} = await serverApi.admin.dashboardInfo.getDashboardInfoElement(name)

      if (data.success) {
        return data.element ? new DashboardInfo(data.element) : DashboardInfo.createEmpty();
      }
    },
    getStagingUpdatedAtElement: async (context) => {
      const element = await context.dispatch('getDashboardInfoElement', dashboardInfoEnum.STAGING_LAST_UPDATED)
      context.commit('setStagingUpdatedAtElement', element)
    },
    getProdUpdatedAtElement: async (context) => {
      const element = await context.dispatch('getDashboardInfoElement', dashboardInfoEnum.PROD_LAST_UPDATED)
      context.commit('setProdUpdatedAtElement', element)
    },
    updateStagingAtInfoData: async (context, {id, requestData}) => {
      const data = await context.dispatch('updateDashboardInfoData', {id, requestData})

      context.commit('setStagingUpdatedAtElement', new DashboardInfo(data.element))
    },
    updateProdAtInfoData: async (context, {id, requestData}) => {
      const data = await context.dispatch('updateDashboardInfoData', {id, requestData})

      context.commit('setProdUpdatedAtElement', new DashboardInfo(data.element))

    },
    updateDashboardInfoData: async (context, {id, requestData}) => {
      const {data} = await serverApi.admin.dashboardInfo.saveDashboardInfoData(id, requestData)

      if (data.success) {
        return data
      }
    },
  },
}