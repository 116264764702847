<template>
  <div>
    <div class="text-center">
      <h3>Label</h3>
    </div>

    <v-row>
      <v-col cols="12">
        <name-editor
            v-model="builderElement.name"
        />
      </v-col>

      <v-col cols="4">
        <priority-editor
            v-model.number="builderElement.priority"
        />
      </v-col>

      <v-col class="pl-4" cols="4">
        <width-editor
            v-model="builderElement.data.width"
        />
      </v-col>

      <v-col cols="6">
        <to-right-editor
            v-model="builderElement.data.to_right"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <checkbox
            text="Block-id label (invisible on production server)"
            :value="builderElement.data.isBlockIdLabel ? '1' : '0'"
            @input="changeIsBlockIdLabel"
        />
      </v-col>
    </v-row>

    <div>
      <p class="app-text__bold">Label Html</p>
      <text-area-field
          name="html"
          :value="builderElement.data.html"
          @saveField="saveHtml"
      />
    </div>

    <margin-editor
        v-model="builderElement.data.margin"
    />

    <padding-editor
        class="mt-4"
        v-model="builderElement.data.padding"
    />

    <id-editor
        class="mt-6"
        v-model="builderElement.data.id"
    />

    <css-class-editor
        class="mt-6"
        v-model="builderElement.data.css_class"
    />

  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";
import {textService} from "@/utils/services/text-service.js";
import WidthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/WidthEditor.vue";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import MarginEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MarginEditor.vue";
import PaddingEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PaddingEditor.vue";
import ToRightEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/ToRightEditor.vue";
import IdEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/IdEditor.vue";
import CssClassEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/CssClassEditor.vue";
import Checkbox from "@/ui/components/common/fields/Checkbox.vue";

export default {
  components: {ToRightEditor, PaddingEditor, MarginEditor, PriorityEditor, NameEditor, WidthEditor, TextAreaField, IdEditor, CssClassEditor, Checkbox},
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  },
  methods: {
    saveHtml(htmlValue) {
      this.builderElement.setDataItem('html', htmlValue);
      this.builderElement.value = textService.replaceHtmlTags(htmlValue)
    },
    changeIsBlockIdLabel(value) {
      this.builderElement.data.isBlockIdLabel = value === '1'
    }
  },
}
</script>