<template>
  <div :style="{padding, margin, width}">
    <v-row class="d-flex justify-space-between button-selector">
      <div class="button-selector-item" v-for="(item, itmIndex) in options" :key="item[itemValueKey]">
        <v-col cols="3">
          <white-btn
              :selected="isSelected(item[itemValueKey])"
              :width="elementWidth"
              :height="elementHeight"
              :disabled="disabledBuilderElement"
              @click="onClick(item[itemValueKey])"
              :tooltip-text="getTooltipText(itmIndex)"
          >
            <span class="text-element">{{ item[itemTextKey] }}</span>
          </white-btn>
        </v-col>
      </div>
    </v-row>
  </div>
</template>
<script>

import DefaultElement from "./mixins/DefaultElement.js";
import Size from "./mixins/Size.js";
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import Padding from "@/ui/components/builder/elements/mixins/Padding.js";
import Options from "@/ui/components/builder/elements/mixins/Options.js";
import {builderElementsEvents} from "@/utils/events/builder-elements-events.js";

export default {
  components: {WhiteBtn},
  mixins: [DefaultElement, Size, Padding, Options],
  props: {
    disabledBuilderElement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    elementHeight() {
      return this.builderElement.data.element_height || 'auto';
    },

    elementWidth() {
      return this.builderElement.data?.element_width || 'auto';
    },
    tooltips() {
      return this.builderElement.data?.tooltips || [];
    },
  },
  methods: {
    isSelected(itemValue) {
      return this.value === itemValue;
    },

    getTooltipText(index) {
      const tooltipItem = this.tooltips.find((itm) => itm.optionIndex === index);

      return tooltipItem?.text || null
    },

    onClick(value) {
      this.builderElement.value = value
      builderElementsEvents.updateValue.emit(this.builderElement)
    }
  }
}
</script>

<style scoped>
#section-disable-button-select button.selected {
  pointer-events: none;
}
</style>