<template>
  <v-icon
      v-if="checked"
      @click="$emit('click')"
      class="cursor-pointer rounded-circle px-1 py-1"
      :color="color"
      :size="size"
      v-ripple="true"
  >
    mdi-checkbox-marked
  </v-icon>
  <v-icon
      v-else
      @click="$emit('click')"
      class="cursor-pointer rounded-circle px-1 py-1"
      :color="color"
      :size="size"
      v-ripple="true"
  >
    mdi-checkbox-blank
  </v-icon>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '30px'
    },
    color: {
      type: String,
      default: 'light-blue'
    },
    checked: {
      type: Boolean,
      default: true
    },
  }
}
</script>
