export const moneyService = {
    toMoneyFormat(value, maximumFractionDigits = 2) {
        const options = {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'code',
            maximumFractionDigits,
        }

        if (!value) {
            return '0 EUR';
        }

        if (value >= 1000) {
            options['minimumFractionDigits'] = 0
        }

        return value.toLocaleString('de-DE', options)
    },

    optionsToMoneyFormat(list) {
        const data = []

        list.forEach(val => {
            const newOptionObj = {text: this.toMoneyFormat(val), value: val}

            data.push(newOptionObj)
        })

        return data
    },

    optionsToIntegerMoneyFormat(list) {
        const data = []

        list.forEach(val => {
            const optionObj = {text: this.toMoneyFormat(val, 0), value: val}

            data.push(optionObj)
        })

        return data
    },
}
