<template>
  <div style="position: absolute; right: 15px; top: 380px;">
    <div class="d-flex justify-end">
      <orange-btn v-if="selected" width="175px" :disabled="disabled" @click="clickSelected">
        Ausgewählt
      </orange-btn>

      <white-btn v-else width="175px" :disabled="disabled" @click="clickUnselected">
        Auswählen
      </white-btn>
    </div>
  </div>
</template>
<script>
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";

export default {
  components: {WhiteBtn, OrangeBtn},
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    addonId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  methods: {
    clickUnselected() {
      this.$emit('clickUnselectedBtn', this.addonId)
    },
    clickSelected() {
      this.$emit('clickSelectedBtn', this.addonId)
    },
  },
}
</script>