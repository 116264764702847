import AuthLayout from "../../ui/layouts/auth/AuthLayout.vue";
import {guestMiddleware} from "../middlewares/guest.js";
import {routeNames} from "../route-names.js";
import Login from "../../ui/pages/Login.vue";
import AmsLogin from "../../ui/pages/AmsLogin.vue";

export const authRoutes = [
    {
        path: '/auth',
        component: AuthLayout,
        beforeEnter: guestMiddleware,
        children: [
            {
                path: '/auth/login',
                name: routeNames.login,
                component: Login,
            },
            {
                path: '/auth/ams-login',
                name: routeNames.amsLogin,
                component: AmsLogin,
            },
        ]
    },
];