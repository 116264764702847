import {builderElementService} from "@/utils/services/builder-element-service";

export const sumByFieldsAction = ({action_value, passiveElement, context, allBuilderElements}) => {
    const elementNames = action_value.split(';').map(itm => itm.trim());
    let builderElements = builderElementService.findAllElementsByParameters(allBuilderElements, 'name', elementNames);

    const actionTag = passiveElement.data.action_tag;

    if (actionTag) {
        builderElements = builderElementService.findAllElementsByDataParameters(builderElements, 'action_tag', [actionTag + ""]);
    }

    passiveElement.value = builderElements.reduce(
        (total, currentValue) => total + Number(currentValue.value),
        0
    );

    context.dispatch('insuranceModule/builderElementsModule/saveElement', {
        builderElement: passiveElement
    }, {root: true})
}