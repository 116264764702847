import { serialize} from "object-to-formdata";

export const objectService = {
    copyClass(elem) {
        return Object.assign(Object.create(Object.getPrototypeOf(elem)), elem)
    },

    copyObject(object) {
        return JSON.parse(JSON.stringify(object));
    },

    isObject(variable) {
        return typeof variable === 'object' &&
            !Array.isArray(variable) &&
            variable !== null
    },

    isEmptyObject(obj) {
        return obj === null || obj === undefined || (Object.keys(obj).length === 0 && obj.constructor === Object);
    },

    toFormData(object) {
        return serialize(object);
    },

    toArray(object) {
        return Object.values(object);
    },

    shouldBeObject(variable) {
        if (!this.isObject(variable)) {
            return {}
        }

        return variable;
    }
}