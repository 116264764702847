import {objectService} from "@/utils/services/object-service.js";
import {InsuranceElementRelation} from "@/models/insurance-element-relations/insurance-element-relation.js";

const IS_VISIBLE = 1;
const NOT_VISIBLE = 0;

export class InsuranceElement {
    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(InsuranceElement.empty().toObject())
            : this.fillData(data);
    }

    fillData(data) {
        this.id = data.id
        this.name = data.name;
        this.key = data.key;
        this.is_show = data.is_show;
        this.priority = data.priority;
        this.value = data.value;
        this.builderRelations =data.builderRelations
            ? data.builderRelations.map((item) => new InsuranceElementRelation(item))
            : [];
    }
    
    isVisible() {
        return this.is_show === IS_VISIBLE || this.is_show === true;
    }

    isEmpty() {
        return this.id === 0;
    }

    static empty(data = {}) {
        return new InsuranceElement({
            id: 0,
            name: data.name || '',
            key: data.key || '',
            is_show: data.is_show === IS_VISIBLE || data.is_show === true ? IS_VISIBLE : NOT_VISIBLE,
            priority: data.priority || 0,
            value: data.value || '',
            builderRelations: data.builderRelations ? data.builderRelations : [],
        })
    }

    toObject() {
        return {
            ...this
        }
    }
}