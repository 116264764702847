import { render, staticRenderFns } from "./AddonWrapper.vue?vue&type=template&id=5f35e962&"
import script from "./AddonWrapper.vue?vue&type=script&lang=js&"
export * from "./AddonWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports