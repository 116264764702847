<template>
  <div :style="{width}">
    <v-textarea
        :rows="rows"
        :value="value"
        @input="saveField"
        :rules="validationRules"
        :readonly="readonly"
        :placeholder="placeholder"
        outlined
        dense
        :class="{rounded: rounded}"
        :disabled="disabled"
    />
  </div>
</template>
<script>
import {required, maxLength, isJson, equalToElementValue} from "@/utils/validation-rules.js";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      required: false,
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    rules: {
      type: Array,
      default: () => []
    },
    width: {
      default: 'auto',
    },
    rows: {
      type: Number,
      default: 3,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    allBuilderElements() {
      return this.$store.getters["insuranceModule/builderElementsModule/getAllBuilderElements"];
    },
    validationRules() {
      const rules = [];

      if (this.validation.required && this.validation.required !== '0') {
        rules.push(required);
      }

      if(this.validation.maxLength) {
        rules.push((val) => maxLength(val, {length: this.validation.maxLength}))
      }

      if(this.validation.json) {
        rules.push((val) => isJson(val))
      }

      if (this.validation.equalTo && this.validation.equalTo.elementId) {
        rules.push(
            (val, message = this.validation.equalTo.message || 'Error') =>
                equalToElementValue(val, this.validation.equalTo.elementId, this.allBuilderElements, message)
        );
      }

      this.rules.forEach((rule) => {
        rules.push(rule);
      })

      return rules;
    }
  },
  methods: {
    saveField(value) {
      this.$emit('saveField', value, this.name)
      this.$emit('input', value);
    },
  }
}
</script>