import {objectService} from "@/utils/services/object-service.js";

export class SimpleInsurance {

    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(SimpleInsurance.empty().toObject())
            : this.fillData(data);
    }

    fillData(data) {
        this.id = data.id
        this.name = data.name
        this.type = data.type
        this.last_update = data.last_update
        this.status = data.status
        this.formal_status_de = data?.formal_status_de || ''
        this.user_name = data.user_name
        this.police_data = JSON.parse(data.police_data)
        this.expiring_token_activated_at = data.expiring_token_activated_at
        this.formal_status = data?.formal_status || ''
        this.user_role = data.user_role
    }


    static empty(data = {}) {
        return new SimpleInsurance({
            id: data.id || 0,
            name: data.name || '',
            type: data.type || '',
            last_update: data.last_update || '',
            status: data.status || '',
            formal_status_de: data.formal_status_de || '',
            formal_status: data.formal_status || '',
            user_name: data.user || '',
            police_data: JSON.parse(data.police_data) || {},
            expiring_token_activated_at: data.expiring_token_activated_at || null,
            user_role: data.user_role || null,
        })
    }

    toObject() {
        return {
            ...this
        }
    }
}