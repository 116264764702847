import {serverApi} from "@/api/server/server-api.js";
import {InsurancePriceAddon} from "@/models/insurance-price-addon.js";

export const insurancePriceAddonModule = {
    namespaced: true,

    state: {
      addons: [],
    },

    getters: {
        getAddons: (state) => state.addons,
        getSelectedAddons: (state) => state.addons.filter((addon) => addon.is_active),
    },

    mutations: {
        setAddons: (state, addons) => {
            state.addons = addons.map((elem) => new InsurancePriceAddon(elem));
        },
        setAddon: (state, newAddon) => {
            state.addons = state.addons.map((addon) => {
                if (addon.id === newAddon.id) {
                    return new InsurancePriceAddon(newAddon)
                }

                return addon;
            })
        },
    },

    actions: {
        addonsByInsuranceType: async (context, {insuranceTypeId}) => {
            const {data} = await serverApi.insurancePriceAddon.addonsByInsuranceType(insuranceTypeId)

            context.commit('setAddons', data.addons)
        },

        selectAddon: async (context, {insurancePriceAddonId}) => {
            const {data} = await serverApi.insurancePriceAddon.selectAddon(insurancePriceAddonId)

            context.commit('setAddon', data.addon)
        },
        unselectAddon: async (context, {insurancePriceAddonId}) => {
            const {data} = await serverApi.insurancePriceAddon.unselectAddon(insurancePriceAddonId)

            context.commit('setAddon', data.addon)
        },
        runInsurancePriceAddonRelations: async (context) => {
            const insuranceId = context.getters.getInsuranceId;
            const {data} = await serverApi.insurance.runInsuranceRelations(insuranceId);

            return data.success
        },
    },
}