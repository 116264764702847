<template>
  <div>
    <div v-if="insurance">
      <template v-if="isRedirectToInfoPage">
        <process-no-longer-valid/>
      </template>
      <template v-else>
        <insurance-flow :insurance="insurance"/>
      </template>
    </div>
  </div>
</template>
<script>
import InsuranceFlow from "@/ui/components/insurance/InsuranceFlow.vue";
import {localStorageService} from "@/utils/services/local-storage-service";
import {dateService} from "@/utils/services/date-service";
import {insuranceStatusEnum} from "@/enums/insurance-status-enum";
import {userRoleEnum} from "@/enums/user-role-enum";
import ProcessNoLongerValid from "@/ui/components/info/ProcessNoLongerValid.vue";

export default {
  components: {InsuranceFlow, ProcessNoLongerValid},
  props: {
    insuranceId: {
      type: Number,
      required: true,
    }
  },
  computed: {
    isRedirectToInfoPage() {
      const currDate = dateService.getNowDate().minus({days: 90})

      return (this.insurance.formal_status !== insuranceStatusEnum.APPLICATION_SUBMITTED &&
        this.insurance.user.role === userRoleEnum.VERMITTER &&
        dateService.fromISOToDateTime(this.insurance.updated_at) < currDate
      )
    }
  },
  data: () => ({
    insurance: null,
  }),
  async created() {
    this.clearCopiedElementsNpopt();
    await this.setInsurance();
  },
  methods: {
    async setInsurance() {
      this.insurance = await this.getInsurance(this.insuranceId);
    },
    clearCopiedElementsNpopt() {
      localStorageService.builderElement.clearCopiedElementIdsNpopt();
    },

    getInsurance(insuranceId) {
      return this.$store.dispatch('insuranceModule/showInsurance', {
        insuranceId,
      });
    },
  }
}
</script>