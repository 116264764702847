<template>
  <div>
    <json-text-element-editor
        v-if="jsonElementEnum.TEXT === element.type"
        :parent-is-array="isElementInsideArray"
        :element="element"
    />

    <json-number-element-editor
        v-else-if="jsonElementEnum.NUMBER === element.type"
        :parent-is-array="isElementInsideArray"
        :element="element"
    />

    <json-array-element-editor
        v-else-if="jsonElementEnum.ARRAY === element.type"
        :parent-is-array="isElementInsideArray"
        :element="element"
    />

    <json-object-element-editor
        v-else-if="jsonElementEnum.OBJECT === element.type"
        :parent-is-array="isElementInsideArray"
        :element="element"
    />
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import JsonTextElementEditor
  from "@/ui/components/json-builder/use-cases/json-builder-editor/element-editor/JsonTextElementEditor.vue";
import {jsonElementEnum} from "@/enums/json-element-enum.js";
import JsonNumberElementEditor
  from "@/ui/components/json-builder/use-cases/json-builder-editor/element-editor/JsonNumberElementEditor.vue";
import JsonArrayElementEditor
  from "@/ui/components/json-builder/use-cases/json-builder-editor/element-editor/JsonArrayElementEditor.vue";
import JsonObjectElementEditor
  from "@/ui/components/json-builder/use-cases/json-builder-editor/element-editor/JsonObjectElementEditor.vue";
import {builderElementService} from "@/utils/services/builder-element-service.js";

export default {
  components: {JsonObjectElementEditor, JsonArrayElementEditor, JsonNumberElementEditor, JsonTextElementEditor},
  props: {
    element: {
      type: BuilderElement,
      required: true,
    },
    elements: {
      type: Array,
      required: true,
    }
  },

  computed: {
    jsonElementEnum() {
      return jsonElementEnum;
    },

    isElementInsideArray() {
        const parentElement = builderElementService.findElementById(this.elements, this.element.parent_id);

        if(parentElement && parentElement.type === jsonElementEnum.ARRAY) {
          if(!this.element.name) {
            this.changeElementName(parentElement.name + ' ' + parentElement.children.length);
          }

          return true;
        }

        return false;
    }
  },

  methods: {
    changeElementName(newName) {
        this.element.name = newName;
    }
  }
}
</script>
