<template>
  <div class="builder-elements__wrapper">
    <div
        v-for="(element) in elements"
        :key="element.id"
        :class="selector.elementBlock"
        v-bind="dynamicAttributes(element.id)"
        :style="{ border: element.id === builderElementId ? 'solid darkviolet 1px' : 'none' }"
    >
      <builder-element-factory
          :class="selector.styleBlock"
          v-if="element.isVisible()"
          :builder-element="element"
          :builder-element-id="builderElementId"
          :disabled-all-inputs="disabledAllInputs"
          :admin-edit-mode="adminEditMode"
      />
    </div>
  </div>
</template>
<script>
import BuilderElementFactory from "./BuilderElementFactory.vue";
import {builderQuerySelectors} from "@/utils/query-selectors/builder-query-selectors.js";

export default {
  components: {BuilderElementFactory},
  props: {
    elements: {
      type: Array,
      required: true,
    },
    builderElementId: {
      type: String,
      required: false,
    },
    disabledAllInputs: {
      type: Boolean,
      required: false,
      default: false,
    },
    adminEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    selector() {
      return builderQuerySelectors;
    },
  },
  methods: {
    dynamicAttributes(builderElementId) {
      return {
        [builderQuerySelectors.idAttribute]: builderElementId
      }
    }
  }
}
</script>