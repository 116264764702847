<template>
  <app-block height="750px" class="overflow-y-auto">
    <v-form :ref="formName">
      <div class="d-flex justify-space-between">
        <div>
          <p class="app-text__bold font-s-24">Description</p>

          <text-area-field
              name="Test"
              width="600px"
              v-model="insuranceElementRelation.description"
              :validation="{required: true}"
          />
        </div>
        <div class="pt-16">
          <orange-btn class="mt-8" @click="onClickSaveRelation">
            {{saveBtnText()}}
          </orange-btn>
        </div>
      </div>

      <div class="mt-6">
        <p class="app-text__bold font-s-24">Conditions</p>

        <app-block
            class="pb-2"
            color="var(--smoke)"
        >
          <div
              v-for="(condition, index) in conditionsWithoutGroup"
              :key="condition.id"
          >
            <div class="d-flex justify-lg-end">
              <insurance-element-relation-condition
                  :conditions="conditionsToSelectField"
                  :insurance-element-condition="condition"
                  :builder-elements="builderElements"
                  :insurance-elements="insuranceElements"
              />

              <div v-show="index">
                <close-icon @click="onClickDeleteCondition(condition)"/>
              </div>
            </div>

            <hr class="my-4">
          </div>


          <div class="mt-4 d-flex justify-lg-end">
            <blue-btn @click="onClickAddCondition">Add Condition</blue-btn>
          </div>

          <div
              v-for="(conditionGroup, groupIndex) in conditionGroups"
              :key="conditionGroup.id"
          >
            <p class="app-text__bold mt-2">Group name</p>
            <div class="d-flex justify-lg-space-between">
              <text-field
                  class="mt-4 col-6"
                  name="name"
                  placeholder="Group name"
                  :validation="{required: true}"
                  v-model="insuranceElementRelation.insuranceElementConditionGroups[groupIndex].name"
              />
              <div>
                <close-icon @click="onClickDeleteConditionGroup(conditionGroup)"/>
              </div>
            </div>
            <div
                v-for="(condition, index) in conditionGroup.conditions"
                :key="condition.id"
                class="d-flex justify-lg-end">
              <insurance-element-relation-condition
                  :conditions="conditionsToSelectField"
                  :insurance-element-condition="condition"
                  :builder-elements="builderElements"
                  :insurance-elements="insuranceElements"
              />

              <div v-show="index">
                <close-icon @click="onClickDeleteCondition(condition)"/>
              </div>
            </div>
            <div class="mt-4 d-flex justify-lg-start">
              <blue-btn @click="onClickAddCondition(conditionGroup.code)">Add Condition to group</blue-btn>
            </div>
            <hr class="my-4">
          </div>

          <div class="mt-4 d-flex justify-lg-end">
            <blue-btn @click="onClickAddGroup">Add group</blue-btn>
          </div>
        </app-block>

      </div>

      <div class="mt-6">
        <p class="app-text__bold font-s-24">Actions</p>
        <app-block
            class="pb-2"
            color="var(--smoke)"
        >
          <div
              v-for="(action, index) in insuranceElementRelation.insuranceElementActions"
              :key="action.id"
          >
            <div class="d-flex justify-lg-end">
              <insurance-element-relation-action
                  :actions="actionsToSelectField"
                  :insurance-element-action="action"
                  :builder-elements="builderElements"
                  :insurance-elements="insuranceElements"
              />

              <div v-show="index">
                <close-icon @click="onClickDeleteAction(action)"/>
              </div>
            </div>

            <hr class="my-4">
          </div>

          <div class="mt-4 d-flex justify-lg-end">
            <blue-btn @click="onClickAddAction">Add Action</blue-btn>
          </div>
        </app-block>
      </div>
    </v-form>
  </app-block>
</template>
<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";
import {insuranceElementRelationEvents} from "@/utils/events/insurance-element-relation-events.js";
import {InsuranceElementRelation} from "@/models/insurance-element-relations/insurance-element-relation.js";
import {InsuranceElementAction} from "@/models/insurance-element-relations/insurance-element-action.js";
import {InsuranceElementCondition} from "@/models/insurance-element-relations/insurance-element-condition.js";
import InsuranceElementRelationAction
  from "@/ui/components/admin/insurance-element-relations/fields/InsuranceElementRelationAction.vue";
import CloseIcon from "@/ui/components/common/icons/CloseIcon.vue";
import {insuranceElementRelationService} from "@/utils/services/insurance-element-relation-service.js";
import InsuranceElementRelationCondition
  from "@/ui/components/admin/insurance-element-relations/fields/InsuranceElementRelationCondition.vue";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import {notificationService} from "@/utils/services/notification-service.js";
import {InsuranceType} from "@/models/insurance-type.js";
import TextField from "@/ui/components/common/fields/TextField";
import {InsuranceElementRelationConditionGroup} from "@/models/insurance-element-relations/insurance-element-relation-condition-group.js";

export default {
  components: {
    OrangeBtn,
    BlueBtn,
    InsuranceElementRelationCondition, CloseIcon, InsuranceElementRelationAction, TextAreaField, AppBlock,
    TextField,
  },
  props: {
    builderElements: {
      type: Array,
      required: true,
    },
    insuranceElements: {
      type: Array,
      required: true,
    },
    insuranceType: {
      type: InsuranceType,
      required: true,
    }
  },
  computed: {
    formName() {
      return 'form';
    },

    form() {
      return this.$refs[this.formName];
    },

    conditionsToSelectField() {
      return insuranceElementRelationService.conditionsArray()
    },

    actionsToSelectField() {
      return insuranceElementRelationService.actionsArray();
    },

    conditionsWithoutGroup() {
      return this.insuranceElementRelation.insuranceElementConditions.filter(itm => itm.group_code === null);
    },

    conditionGroups() {
      return this.insuranceElementRelation.insuranceElementConditionGroups.map(item => {
        return {
          ...item,
          conditions: this.insuranceElementRelation.insuranceElementConditions.filter(itm => itm.group_code === item.code)
        }
      })
    }
  },
  data: () => ({
    insuranceElementRelation: InsuranceElementRelation.empty(),

    operationType: 'create',
  }),
  created() {
    insuranceElementRelationEvents.selectRelation.on(this.onSelectInsuranceElementRelation)

    this.onSelectInsuranceElementRelation(this.insuranceElementRelation);
  },
  destroyed() {
    insuranceElementRelationEvents.selectRelation.off(this.onSelectInsuranceElementRelation)
  },
  methods: {
    async onClickSaveRelation() {

      if (this.form.validate()) {
        const result = this.operationType === 'create'
            ? await this.saveRelation(this.insuranceType, this.insuranceElementRelation)
            : await this.updateRelation(this.insuranceElementRelation)

        if (result) {
          notificationService.success('Relation saved');

          this.resetRelationsData()

          insuranceElementRelationEvents.relationSaved.emit(this.insuranceElementRelation);
        }
      }
    },

    onClickAddAction() {
      this.insuranceElementRelation.insuranceElementActions.push(this.defaultAction());
    },

    onClickAddCondition(groupCode = null) {
      this.insuranceElementRelation.insuranceElementConditions.push(this.defaultCondition(groupCode));
    },

    onClickDeleteAction(actionElement) {
      this.insuranceElementRelation.insuranceElementActions = this.insuranceElementRelation
          .insuranceElementActions
          .filter((action) => action.id !== actionElement.id);
    },

    onClickDeleteCondition(conditionElement) {
      this.insuranceElementRelation.insuranceElementConditions = this.insuranceElementRelation
          .insuranceElementConditions
          .filter((condition) => condition.id !== conditionElement.id);
    },

    onSelectInsuranceElementRelation(elementRelation) {
      this.insuranceElementRelation = elementRelation;

      if(elementRelation.isEmpty()) {
        this.operationType = 'create';

        this.insuranceElementRelation.insuranceElementActions.push(this.defaultAction());
        this.insuranceElementRelation.insuranceElementConditions.push(this.defaultCondition());
      } else {
        this.operationType = 'edit';
      }
    },

    saveBtnText() {
      return this.operationType === 'create' ? 'Save Relation' : 'Update Relation'
    },

    defaultAction() {
      return InsuranceElementAction.empty({
        id: Math.random(),
        action: '',
        action_value: '',
      })
    },

    defaultCondition(groupCode) {
      return InsuranceElementCondition.empty({
        id: Math.random(),
        condition: '',
        condition_value: '',
        group_code: groupCode,
      })
    },

    resetRelationsData() {
      this.setDefaultData();
    },

    setDefaultData() {
      this.insuranceElementRelation = InsuranceElementRelation.empty();

      this.insuranceElementRelation.insuranceElementConditions.push(this.defaultCondition());
      this.insuranceElementRelation.insuranceElementActions.push(this.defaultAction());
    },

    saveRelation(insuranceType, insuranceElementRelation) {
      return this.$store.dispatch('admin/insuranceElementRelationsModule/createInsuranceElementRelation', {
        insuranceType,
        insuranceElementRelation,
      })
    },

    updateRelation(insuranceElementRelation) {
      return this.$store.dispatch('admin/insuranceElementRelationsModule/updateInsuranceElementRelation', {
        insuranceElementRelation,
      })
    },

    onClickAddGroup() {
      const newEmptyGroup = this.addNewEmptyGroup();

      this.onClickAddCondition(newEmptyGroup.code);

      this.insuranceElementRelation.insuranceElementConditionGroups.push(newEmptyGroup)
    },

    onClickDeleteConditionGroup(conditionGroupElement) {
      this.insuranceElementRelation.insuranceElementConditionGroups = this.insuranceElementRelation.insuranceElementConditionGroups
          .filter((item) => item.id !== conditionGroupElement.id)

      this.insuranceElementRelation.insuranceElementConditions = this.insuranceElementRelation.insuranceElementConditions
          .filter((item) => item.group_code !== conditionGroupElement.code)
    },

    addNewEmptyGroup() {
      return InsuranceElementRelationConditionGroup.empty({
        id: Math.random() + "",
        code: 'group' + Math.random(),
        name: ''
      })
    },
  }
}
</script>