<template>
  <v-icon
      @click="$emit('click')"
      class="cursor-pointer rounded-circle"
      color="red darken-4"
      :size="size"
      v-ripple="true"
  >
    mdi-trash-can-outline
  </v-icon>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: '24px',
    },
  }

}
</script>