<template>
  <div>
    <money-field
        name="higherMaximization"
        v-model="val"
        v-custom-blur="onBlur"
        :disabled="disabled"
    />
  </div>
</template>
<script>
import MoneyField from "@/ui/components/common/fields/MoneyField.vue"
export default {
  components: {MoneyField},
  props: {
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    val: '',
  }),
  created() {
    this.init()
  },
  methods: {
    init() {
      this.val = this.value || ''
    },
    onBlur() {
      this.$emit('changeHigherMaximization', Number(this.val))
    },
  },
}
</script>