<template>
  <div :style="{width}">
    <div class="d-flex justify-start">
      <div class="field-width">
        <text-field
            :class="{'no-events' : noEvents}"
            :name="name"
            :value="activeValue"
            :placeholder="placeholder"
            :validation="validation"
            :readonly="isReadonly"
            icon="mdi-magnify"
            @click="onButtonClick"
            :disabled="disabled"
        />
      </div>

      <div class="pl-1">
        <red-btn
          v-if="activeValue && isShowDeletingFileBtn"
          height="40px"
          width="140px"
          class="pa-0"
          :disabled="disabled"
          :loading="loading"
          @click="onButtonDelete"
        >
          Löchen
        </red-btn>
      </div>
      <div class="pl-1">

        <blue-btn
            @click="onButtonClick"
            height="40px"
            width="140px"
            class="pa-0"
            :disabled="disabled"
            :loading="loading"
        >
          Upload
        </blue-btn>
        <div class="hidden-file__field">
          <input
              width="30px"
              @change="loadFile"
              type="file"
              :ref="refName"
              :accept="acceptFiles"
          />
        </div>
      </div>
    </div>
    <v-progress-linear
        v-if="isShowLoadingProgress"
        v-model="loadingProgress"
        striped
        height="10"
        :active="isActiveProgressBar"
        :indeterminate="queryProgressBar"
        :query="true"
    ></v-progress-linear>
  </div>
</template>
<script>
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import RedBtn from "@/ui/components/common/buttons/RedBtn.vue";
import TextField from "@/ui/components/common/fields/TextField.vue";
import {notificationService} from "@/utils/services/notification-service.js";
import {allowedFileExtensions} from "@/utils/data/file-data";

export default {
  components: {TextField, BlueBtn, RedBtn},
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Nur Dateien PDF, XLS/XLSX',
    },
    value: {
      required: false,
    },
    width: {
      default: 'auto',
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    noEvents: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isShowDeletingFileBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDocument: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    refName() {
      return 'file-field' + this.name;
    },

    ref() {
      return this.$refs[this.refName];
    },

    isShowLoadingProgress() {
      return this.loadingProgress !== 0
    },

    acceptFiles() {
      return this.isDocument ? '.pdf, .xls, .xlsx' : '*'
    },
  },
  watch: {
    value(newValue) {
      this.activeValue = newValue;
    }
  },
  data: () => ({
    activeValue: '',
    loading: false,
    loadingProgress: 0,
    queryProgressBar: false,
    isActiveProgressBar: false,
    intervalProgressBar: 0,
  }),
  created() {
    this.activeValue = this.value
  },
  methods: {
    saveField(value) {
      this.$emit('saveField', value, this.name)
      this.$emit('input', value);
    },

    saveFile(file) {
      this.$emit('saveFile', file)
    },

    onButtonClick() {
      if (!this.loading) {
        this.ref.click();
      }
    },

    loadFile(elem) {
      this.loading = true;
      this.queryAndIndeterminate()
      const file = elem.target.files[0];

      const fileSize = file.size / 1024 / 1024; // in MiB

      if (this.validation.maxSize && this.validation.maxSize < fileSize)
      {
        this.loading = false;
        this.loadingProgress = 100
        notificationService.error(`File size should be less or equal than ${this.validation.maxSize} MiB`)

        return;
      }

      if (this.isDocument && !allowedFileExtensions.includes(file.type)) {
        this.loading = false;
        this.loadingProgress = 100
        this.activeValue = ''
        notificationService.error('Die Datei entspricht nicht den Vorgaben zum Dokumentenformat')

        return;
      }


      this.saveField(file.name)
      this.saveFile(file);

      this.loading = false;
      this.activeValue = file.name;

      this.loadingProgress = 100
    },

    queryAndIndeterminate () {
      this.queryProgressBar = true
      this.isActiveProgressBar = true
      this.loadingProgress = 0

      setTimeout(() => {
        this.queryProgressBar = false

        this.intervalProgressBar = setInterval(() => {
          if (this.loadingProgress === 100) {
            clearInterval(this.intervalProgressBar)
            this.isActiveProgressBar = false
          }
          this.loadingProgress += 25
        }, 1000)
      }, 2500)
    },
    onButtonDelete () {
      this.saveField('')
      this.$emit('deleteFile')
    },
  }
}
</script>
<style scoped>
.hidden-file__field {
  position: relative;
  top: -30px;
  display: none;
}

.field-width {
  width: calc(100% - 150px);
}
</style>