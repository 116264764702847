<template>
  <div>
    <div v-if="processPreloader" class="mt-12 price-tab-preloader" >
      <app-preloader />
    </div>
    <div v-if="isLoaded">
      <div class="mt-2 pt-3 app-bg-aqua">
        <app-block class="py-2 mb-4 app-container">
          <progress-bar
              name="Antragsdatenerfassung"
              :selected-item="3"
          />
        </app-block>

        <div class="mt-2 mb-4 pt-2 text-center header">
          <div class="mb-3">
            <h3>Vermögensschaden-Haftpflichtversicherung</h3>
          </div>
          <div class="pb-4">
            <h1>Antrag</h1>
          </div>
          <img class="bg-img" src="/img/man_sitting_on_vault_holding_money.png" alt="Allianz logo"/>
        </div>
      </div>

      <div class="app-container">
        <insurance-step-wrapper
            :prev-button-text="prevButtonText"
            :show-next-button="true"
            :next-button-text="nextBtnText"
            :next-button-width="nextBtnWidth"
            @nextButtonClick="onClickNext"
            @prevButtonClick="onClickPrev"
        >

          <v-form :ref="formName">
            <insurance-builder
                v-if="activeInsuranceBuilder"
                :builder="activeInsuranceBuilder.builder"
                :step-type="stepType"
                :disabled-all-inputs="isDisableAllInputs"
                @resetBuilder="onResetBuilder"
                @resetBuilderWithoutRelations="onResetBuilderWithoutRelations"
                @resetBuilders="onResetBuilder"
            />
          </v-form>

        </insurance-step-wrapper>
      </div>
    </div>
  </div>

</template>
<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import ProgressBar from "@/ui/components/insurance/ProgressBar.vue";
import {insuranceStepEnum} from "@/enums/insurance-step-enum.js";
import InsuranceStepWrapper from "@/ui/components/insurance/insurance-wrappers/InsuranceStepWrapper.vue";
import {insuranceStepService} from "@/utils/services/insurance-step-service.js";
import InsuranceBuilder from "@/ui/components/insurance/InsuranceBuilder.vue";
import {localStorageService} from "@/utils/services/local-storage-service.js";
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue";
import {debounce} from "@/utils/functions.js";
import {builderElementRelationActionsEnum} from "@/enums/builder-element-relations-enum";

export default {
  components: {InsuranceBuilder, InsuranceStepWrapper, ProgressBar, AppBlock, AppPreloader},

  computed: {
    processPreloader() {
      return this.$store.getters["insuranceModule/getProcessPreloader"]
    },
    stepType() {
      return insuranceStepEnum.CUSTOMER
    },

    formName() {
      return 'kunde-form';
    },

    form() {
      return this.$refs[this.formName];
    },

    insuranceId() {
      return this.$store.getters["insuranceModule/getInsuranceId"];
    },

    insuranceBuilders() {
      return this.$store.getters["insuranceModule/insuranceBuildersModule/getInsuranceStepBuilders"](this.stepType);
    },

    activeInsuranceBuilder() {
      const insuranceBuilder = insuranceStepService.getActiveInsuranceBuilder(this.insuranceBuilders)

      return this.getInsuranceBuilderWithElementsToHide(insuranceBuilder)
    },

    firstInsuranceBuilder() {
      return insuranceStepService.getFirstInsuranceBuilder(this.insuranceBuilders)
    },

    prevButtonText() {
      return 'Zurück'
    },

    hasPrevBuilder() {
      return insuranceStepService.hasPrevBuilder(this.activeInsuranceBuilder, this.insuranceBuilders)
    },

    isDisableAllInputs() {
      return Boolean(this.$store.getters["insuranceModule/getFinishedAt"])
    },

    insurancePrices() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePrices"]
    },

    insurancePrice1() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePriceById"](this.insurancePrices[0].id);
    },

    insurancePrice2() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePriceById"](this.insurancePrices[1]?.id);
    },

    insurancePrice3() {
      if (this.insurancePrices.length > 2) {
        return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePriceById"](this.insurancePrices[2].id);
      }

      return null
    },

    nextBtnText () {
      if (this.insurancePrice1.getIsNpopt()) {
        return 'Anfrage an Underwriting senden'
      }

      return 'Speichern und weiter'
    },

    nextBtnWidth () {
      if (this.insurancePrice1.getIsNpopt()) {
        return '350px'
      }

      return '250px'
    },

    isInsuranceFinishedAt() {
      return Boolean(this.$store.getters["insuranceModule/getFinishedAt"])
    },
  },
  data: () => ({
    isLoaded: false,
  }),
  async created() {
    await this.init()
  },
  methods: {
    showProcessPreloader() {
      this.$store.commit('insuranceModule/showProcessPreloader');
    },
    hideProcessPreloader() {
      this.$store.commit('insuranceModule/hideProcessPreloader');
    },
    async init() {
      await this.determineInsurancePriceNpopt(this.insuranceId);
      await this.getInsurancePrices(this.insuranceId)

      if (this.insurancePrice1.getIsNpopt() ) {
        await this.dataAcquisitionForModulation()
      }

      this.isLoaded = true
    },
    async onClickPrev() {
      if (!await this.form.validate() && !this.isDisableAllInputs) {
        this.scrollToValidation();

        return;
      }

      this.$store.commit('insuranceModule/showPreloader');

      await this.runInsuranceRelations();

      if (this.hasPrevBuilder) {
        await this.prevBuilder();
      } else {
        await this.prevStep();
      }

      this.$store.commit('insuranceModule/hidePreloader')
    },

    async prevStep() {
      if (!await this.checkOnEmptyPrice(this.insuranceId)) {
        await this.riskFinished();
      }
    },

    async prevBuilder() {
      const prevBuilder = insuranceStepService.findPrevBuilder(this.activeInsuranceBuilder, this.insuranceBuilders);

      await this.changeActiveBuilder(prevBuilder);
    },

    async onClickNext() {
      //check validation
      if (!await this.form.validate() && !this.isDisableAllInputs) {
        this.scrollToValidation();

        return;
      }

      this.$store.commit('insuranceModule/showPreloader');

      if (this.insurancePrice1.getIsNpopt() || this.insurancePrice2?.getIsNpopt() || this.insurancePrice3?.getIsNpopt()) {
        debounce(
            async () => {
              await this.sendNpoptEmail(this.insuranceId)
            },
            'send-npopt-email',
            1000,
        )

        await this.statusFinished();

        if (this.insurancePrice1.getIsNpopt()) {
          await this.insuranceFormalStatusToEmptyPrice()
        }



        await this.sendBigXml()
      } else {
        await this.statusPolice();
      }

      await this.setCustomerData(this.insuranceId)

      this.$store.commit('insuranceModule/hidePreloader')
    },

    async onResetBuilder() {
      //reset same builder
      this.showProcessPreloader();

      await this.resetInsuranceBuilders(this.activeInsuranceBuilder);
     // await this.runBuilderRelations();

      this.hideProcessPreloader();
    },

    async onResetBuilderWithoutRelations() {
      //reset same builder
      this.showProcessPreloader();

      await this.resetInsuranceBuilders(this.activeInsuranceBuilder);

      await this.runBuilderRelations([
        builderElementRelationActionsEnum.REQUIRED,
      ]);

      this.hideProcessPreloader();
    },

    async runBuilderRelations(definedActions = null) {
      await this.$store.dispatch('insuranceModule/builderElementRelationsModule/runRelations', {
        definedActions
      })
    },

    scrollToValidation() {
      const errMsgSelector = document.querySelector(".v-messages.error--text:first-of-type")

      if (!errMsgSelector) {
        document.querySelector(".v-messages.error--text").closest(".app-block").scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })

        return
      }

      errMsgSelector.closest(".app-block").scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    },

    async nextStep() {
    //  TODO: complete next step
    },

    async resetInsuranceBuilders(insuranceBuilder) {
      await this.$store.dispatch('insuranceModule/insuranceBuildersModule/resetInsuranceBuilders', {
        insuranceBuilder,
      })
    },

    async riskFinished() {
      await this.$store.dispatch('insuranceModule/statusRiskFinished')
    },

    async runInsuranceRelations() {
      await this.$store.dispatch('insuranceModule/runInsuranceRelations');
    },

    async runPolicyStep() {
        await this.$store.dispatch('insuranceModule/runPolicyStep');
    },

    checkOnEmptyPrice(insuranceId) {
      return this.$store.dispatch('insuranceModule/insurancePriceModule/checkEmptyPrice', {
        insuranceId,
      })
    },

    async resetBuilderElementsData() {
      await this.$store.dispatch('insuranceModule/builderElementsModule/resetBuilderElementsData');
    },

    getInsuranceBuilderWithElementsToHide(insuranceBuilder) {
      const elementIdsToHide = localStorageService.builderElement.getElementIdsToHide();
      const elementIdsToShow = localStorageService.builderElement.getElementIdsToShow();
      let copiedElements = [];

      if (!localStorageService.builderElement.isEmptyCopiedElementIdsNpopt()) {
        const copiedElementIds = localStorageService.builderElement.getCopiedElementIdsNpopt();

        copiedElements = this.$store.getters['insuranceModule/builderElementsModule/getCopiedElements'](copiedElementIds);
      } else {
        copiedElements = insuranceBuilder.builder.elements

        localStorageService.builderElement.setCopiedElementIdsNpopt(copiedElements.map(itm => itm.id));
      }

      insuranceBuilder.builder.elements = copiedElements.filter(el => {
        return !elementIdsToHide.includes(el.id)
      })

      insuranceBuilder.builder.elements.forEach(el => {
         if (elementIdsToShow.includes(el.id)) {
           el['is_show'] = true
           return  {...el}
         }
      })

      return insuranceBuilder;
    },
    async statusPolice() {
      await this.$store.dispatch('insuranceModule/statusPolice')
    },
    async statusFinished() {
      await this.$store.dispatch('insuranceModule/statusInsuranceFinished')
    },
    async determineInsurancePriceNpopt(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/getBuilderElementsForNpopt', {
        insuranceId,
      });
    },
    async getInsurancePrices(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/getInsurancePricesAndSetInsurancePrice', {
        insuranceId,
      })
    },
    async sendNpoptEmail(insuranceId) {
      return await this.$store.dispatch('insuranceModule/insurancePriceModule/sendNpoptEmail', {
            insuranceId
          }
      );
    },
    sendBigXml() {
      this.$store.dispatch('insuranceModule/sendBigXml')
    },
    async dataAcquisitionForModulation() {
      await this.$store.dispatch('insuranceModule/dataAcquisitionForModulation')
    },
    async insuranceFormalStatusToEmptyPrice() {
      await this.$store.dispatch('insuranceModule/emptyPrice')
    },
    async setCustomerData(insuranceId) {
      await this.$store.dispatch('insuranceModule/setCustomerData', {
        insuranceId,
      })
    },
  }
}
</script>
<style scoped>
.bg-img {
  max-height: 195px;
  margin-bottom: -12px;
}
.price-tab-preloader {
  z-index: 1000;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5)
}
</style>
