import {objectService} from "@/utils/services/object-service.js";
import {RUN_ON_ENUM} from "@/enums/builder-element-relations-enum.js";

export class BuilderElementRelationAction {

    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(BuilderElementRelationAction.empty().toObject())
            : this.fillData(data);
    }

    fillData(data) {
        this.id = data.id;
        this.run_on = data.run_on;
        this.relation_id = data.relation_id;
        this.active_element_id = data.active_element_id;
        this.passive_element_id = data.passive_element_id;
        this.action = data.action;
        this.action_value = data.action_value;
        this.data = objectService.shouldBeObject(data.data);
    }

    static empty(data = {}) {
        return new BuilderElementRelationAction({
            id: data.id || 0,
            relation_id: data.relation_id || 0,
            active_element_id: data.active_element_id || null,
            passive_element_id: data.passive_element_id || null,
            action: data.action || '',
            run_on: (data.run_on === RUN_ON_ENUM.TRUE || data.run_on === RUN_ON_ENUM.FALSE) ? data.run_on : RUN_ON_ENUM.TRUE,
            action_value: data.action_value || '',
            data: objectService.shouldBeObject(data.data)
        })
    }

    toObject() {
        return {
            ...this
        }
    }

}