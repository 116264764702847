//Need run blur for vuetify-money component
export const customBlur = (el, binding) => {
    const input = el.getElementsByTagName('input')[0];
    if (!input) {
        return
    }

    input.addEventListener('blur', function () {
        binding.value()
    });
}
