<template>
  <v-row justify="center">
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        persistent
    >
      <v-toolbar
          absolute
          dark
          color="var(--light-blue)"
      >
        <span class="text-h4 text--white">Builder Relations</span>

        <v-spacer/>

        <white-btn height="80%" @click="onClose">Close</white-btn>
      </v-toolbar>

      <v-card
          color="var(--smoke)"
      >
        <v-row class="builder-relations__section">
          <v-col cols="8" class="pl-8">
            <h1 class="mb-4 text-center">Relation</h1>

            <builder-relations-operation-section
                :builder-elements="builderElements"
                :builders="builders"
            />
          </v-col>
          <v-col cols="4" class="px-8 pb-8">
            <h1 class="mb-4 text-center">Fields</h1>

            <builder-relations-select-element-section
                :builder-elements="builderElements"
                :builders="builders"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>

</template>
<script>
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import BuilderRelationsSelectElementSection
  from "@/ui/components/builder/use-cases/builder-relations/sections/BuilderRelationsSelectElementSection.vue";
import BuilderRelationsOperationSection
  from "@/ui/components/builder/use-cases/builder-relations/sections/BuilderRelationsOperationSection.vue";

export default {
  components: {BuilderRelationsOperationSection, BuilderRelationsSelectElementSection, WhiteBtn},
  props: {
    builderElements: {
      type: Array,
      required: true,
    },
    builders: {
      type: Array,
      required: true,
    }
  },
  data: () => ({
    show: true,
  }),
  methods: {
    onClose() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped>
.builder-relations__section {
  padding-top: 100px;
  padding-left: 15px;
}
</style>