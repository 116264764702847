<template>
  <div :style="{width, maxWidth}">
    <v-select
        :items="items"
        :item-text="itemText"
        :menu-props="{ bottom: true, offsetY: true }"
        :item-value="itemValue"
        :value="value"
        append-icon="mdi-chevron-down"
        :placeholder="placeholder"
        @input="saveField"
        :clearable="clearable"
        outlined
        dense
        :class="{rounded: rounded}"
        :disabled="isDisabled"
        :rules="validationRules"
        :multiple="multiple"
    />
  </div>
</template>
<script>
import {equalToElementValue, required} from "@/utils/validation-rules";

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true,
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Array],
      default: '',
      required: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'auto',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    maxWidth: {
      type: String,
      default: 'auto',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    allBuilderElements() {
      return this.$store.getters["insuranceModule/builderElementsModule/getAllBuilderElements"];
    },
    validationRules() {
      const rules = [];

      if (this.validation.required && this.validation.required !== '0') {
        rules.push((val, message = this.validation.message || 'Pflichtfeld') => required(val, message));
      }

      if (this.validation.equalTo && this.validation.equalTo.elementId) {
        rules.push(
            (val, message = this.validation.equalTo.message || 'Error') =>
                equalToElementValue(val, this.validation.equalTo.elementId, this.allBuilderElements, message)
        );
      }

      if (this.validation.incorrectValueValidation) {
        rules.push(this.validation.message || 'falsche Daten');
      }

      return rules;
    }
  },
  methods: {
    saveField(value) {
      this.$emit('saveField', value, this.name);
      this.$emit('input', value);
    }
  }
}
</script>