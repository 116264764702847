<template>
  <v-btn
      :id="id"
      :class="cssClasses"
      outlined
      rounded
      :height="height"
      :width="width"
      @click="onClick"
      :disabled="disabledBuilderElement"
  >
    {{ buttonText }}
  </v-btn>
</template>

<script>
import DefaultElement from "./mixins/DefaultElement.js";
import Padding from "./mixins/Padding";
import Placeholder from "./mixins/Placeholder";
import Size from "./mixins/Size";
import CssClass from "./mixins/CssClass.js";
import Id from "./mixins/Id.js";
import Title from "./mixins/Title.js";

export default {
  name: "BuilderButton",
  components: {},
  mixins: [DefaultElement, Padding, Placeholder, Size, CssClass, Id, Title],
  props: {
    disabledBuilderElement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    buttonText() {
      return this.title || 'Button'
    },
    cssClasses() {
      let classes = ['app-btn'];
      classes.push(this.cssClass || '');

      return classes.join(' ');
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    },

  }
}
</script>