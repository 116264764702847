<template>
  <text-field
      name="Search"
      class="insurance-search text"
      placeholder="Gerechnete Angebote durchsuchen"
      icon="mdi-magnify"
      height="50"
      width="550px"
      :value="value"
      @saveField="saveField"
  />
</template>
<script>
import TextField from "@/ui/components/common/fields/TextField.vue";
export default {
  components: {TextField},
  props: {
    value: {
      type: String,
      required: true,
    }
  },
  methods: {
    saveField(value) {
      this.$emit('saveField', value);
    }
  }
}
</script>
<style>
.insurance-search .v-input__append-inner {
  margin-top: 14px !important;
}
.insurance-search .v-input{
  border-radius: 5px;
}
.insurance-search {
  padding-bottom: 12px;
}
</style>