import { render, staticRenderFns } from "./InsuranceStatusSumPriceChart.vue?vue&type=template&id=7019a779&scoped=true&"
import script from "./InsuranceStatusSumPriceChart.vue?vue&type=script&lang=js&"
export * from "./InsuranceStatusSumPriceChart.vue?vue&type=script&lang=js&"
import style0 from "./InsuranceStatusSumPriceChart.vue?vue&type=style&index=0&id=7019a779&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7019a779",
  null
  
)

export default component.exports