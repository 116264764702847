export const insurancePriceAddonActionEnum = {
    HIDE: 'Hide addon',
    SHOW: 'Show addon',
    DISABLE: 'Disable addon',
    ENABLE: 'Enable addon',
}

export const insurancePriceAddonConditionEnum = {
    IF_VISIBLE: 'If visible',
    IF_VALUE: 'If have value',
    IF_SELECTED: 'If selected',
    IF_UNSELECTED: 'If unselected',
    IF_STATIC_VALUE: 'If equal to custom value',
    ALWAYS: 'Always'
}