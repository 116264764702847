<template>
  <div class="d-flex">
    <span><check-icon size="18"/></span>
    <span class="app-text"><slot /></span>
  </div>
</template>
<script>
import CheckIcon from "@/ui/components/common/icons/CheckIcon.vue";
export default {
  components: {CheckIcon}
}
</script>