<template>
  <div>
    <app-block
        class="px-4 pb-6"
        width="300"
        height="180px"
        :color="color"
    >
      <div style="position: absolute; top:-34px; left:0; width:100%;" class="text-center app-bg-aqua"><slot name="bestseller"/></div>
      <slot />
    </app-block>
  </div>
</template>

<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
export default {
  components: {AppBlock},
  props: {
    color: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>

</style>