<template>
  <div>
    <p class="app-text__bold">Priority</p>

    <number-field
        name="priority"
        :value="value"
        :validation="{required: true}"
        @saveField="onSaveField"
    />
  </div>
</template>
<script>
import NumberField from "@/ui/components/common/fields/NumberField.vue";
export default {
  components: {NumberField},
  props: {
    value: {
      required: true,
    }
  },
  methods: {
    onSaveField(value) {
      this.$emit('input', value);
    }
  }
}
</script>