<template>
  <div>
    <p>Laufzeit</p>
    <select-field
        name="duration"
        max-width="278px"
        :value="value"
        :items="items"
        :is-disabled="isDisabled"
        @saveField="saveField"
    />
  </div>
</template>
<script>
import SelectField from "@/ui/components/common/fields/SelectField.vue";
export default {
  components: {SelectField},
  props: {
    items: {
      required: true,
      type: Array,
    },
    value: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    saveField(val) {
      this.$emit('changeDuration', val)
    }
  },
}
</script>