<template>
  <app-block height="728px" class="overflow-y-auto pb-4">

    <div>
      <v-form ref="form">
        <v-row>
          <v-col cols="6">
            <p class="app-text__bold font-s-24">Description</p>

            <text-area-field
                name="relation"
                width="100%"
                :validation="{required: true, maxLength: 255,}"
                v-model="builderElementRelation.description"
            />
          </v-col>

          <v-col cols="6">
            <div class="pl-8">
              <p class="app-text__bold font-s-24">Builder</p>

              <searchable-select-field
                  name="builder_id"
                  width="100%"
                  v-model="builderElementRelation.builder_id"
                  :validation="{required: true}"
                  :items="buildersToSelectField"
                  clearable
              />

            </div>
            <div class="pl-8">
              <p class="app-text__bold font-s-24">Priority</p>
<!--              <input
                type="number"
                name="position"
                v-model="builderElementRelation.position"
              />-->
              <number-field
                  name="position"
                  :value="builderElementRelation.position"
                  @saveField="savePriority"
                  :is-integer="true"
              />
            </div>
            <div class="pl-8">
              <checkbox
                  class="mt-11"
                  text="Validation relation"
                  :value="builderElementRelation.is_validation + ''"
                  @saveField="onChangeIsValidation"
              />
            </div>
            <div class="pl-8" v-if="builderElementRelation.is_validation">
              <checkbox
                  class="mt-11"
                  text="Show validation section on true"
                  :value="builderElementRelation.show_validation_on + ''"
                  @saveField="onChangeShowValidationOn"
              />
            </div>
            <div class="pl-8">
              <checkbox
                class="mt-11"
                text="Run this relation only test server"
                :value="builderElementRelation.run_on_test + ''"
                @saveField="onChangeRunOnTest"
              />
            </div>
            <div class="pl-8">
              <orange-btn
                  class="mt-4 float-right"
                  @click="onSave"
              >
                {{ this.operationType === 'create' ? 'Save Relation' : 'Update Relation' }}
              </orange-btn>
            </div>
          </v-col>
        </v-row>

        <div class="mt-6">
          <p class="app-text__bold font-s-24">Conditions</p>

          <app-block
              class="pb-2"
              color="var(--smoke)"
          >
            <div
                v-for="(builderElementCondition, index) in conditionsWithoutGroup"
                :key="builderElementCondition.id"
            >
              <div class="d-flex justify-lg-end">
                <builder-element-condition-fields
                    :builder-element-condition="builderElementCondition"
                    :conditions="conditionsToSelectField"
                    :builder-elements="builderElements"
                />

                <div v-show="index">
                  <close-icon @click="onClickDeleteCondition(builderElementCondition)"/>
                </div>
              </div>

              <hr class="my-4">
            </div>

            <div class="mt-4 d-flex justify-lg-end">
              <blue-btn @click="onClickAddCondition">Add Condition</blue-btn>
            </div>

            <div
                v-for="(conditionGroup, groupIndex) in conditionGroups"
                :key="conditionGroup.id"
            >
              <p class="app-text__bold mt-2">Group name</p>
              <div class="d-flex justify-lg-space-between">
                <text-field
                    class="mt-4 col-6"
                    name="name"
                    placeholder="Group name"
                    :validation="{required: true}"
                    v-model="builderElementRelation.builderElementConditionGroups[groupIndex].name"
                />
                <div>
                  <close-icon @click="onClickDeleteConditionGroup(conditionGroup)"/>
                </div>
              </div>
              <div
                  v-for="(condition, index) in conditionGroup.conditions"
                  :key="condition.id"
                  class="d-flex justify-lg-end">
                <builder-element-condition-fields
                    :builder-element-condition="condition"
                    :conditions="conditionsToSelectField"
                    :builder-elements="builderElements"
                />

                <div v-show="index">
                  <close-icon @click="onClickDeleteCondition(condition)"/>
                </div>
              </div>
              <div class="mt-4 d-flex justify-lg-start">
                <blue-btn @click="onClickAddCondition(conditionGroup.code)">Add Condition to group</blue-btn>
              </div>
              <hr class="my-4">
            </div>
            <div class="mt-4 d-flex justify-lg-end">
              <blue-btn @click="onClickAddGroup">Add group</blue-btn>
            </div>
          </app-block>

        </div>

        <div class="mt-6">
          <p class="app-text__bold font-s-24">Actions</p>
          <app-block
              class="pb-2"
              color="var(--smoke)"
          >
            <div
                v-for="(builderElementAction, index) in builderElementRelation.builderElementActions"
                :key="builderElementAction.id"
            >
              <div class="d-flex justify-lg-end">
                <builder-element-action-fields
                    :builder-element-action="builderElementAction"
                    :actions="actionsToSelectField"
                    :builder-elements="builderElements"
                />

                <div v-show="index">
                  <close-icon @click="onClickDeleteAction(builderElementAction)"/>
                </div>
              </div>

              <hr class="my-4">
            </div>


            <div class="mt-4 d-flex justify-lg-end">
              <blue-btn @click="onClickAddAction">Add Action</blue-btn>
            </div>
          </app-block>
        </div>
      </v-form>
    </div>

  </app-block>
</template>
<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import {builderRelationsEvents} from "@/utils/events/builder-relations-events.js";
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";
import {builderElementRelationsService} from "@/utils/services/builder-element-relations-service.js";
import BuilderElementConditionFields
  from "@/ui/components/builder/use-cases/builder-relations/fields/BuilderElementConditionFields.vue";
import {
  BuilderElementRelationCondition
} from "@/models/builder-element-relations/builder-element-relation-condition.js";
import {BuilderElementRelationAction} from "@/models/builder-element-relations/builder-element-relation-action.js";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import {BuilderElementRelation} from "@/models/builder-element-relations/builder-element-relation.js";
import CloseIcon from "@/ui/components/common/icons/CloseIcon.vue";
import BuilderElementActionFields
  from "@/ui/components/builder/use-cases/builder-relations/fields/BuilderElementActionFields.vue";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import {notificationService} from "@/utils/services/notification-service.js";
import SearchableSelectField from "@/ui/components/common/fields/SearchableSelectField.vue";
import TextField from "@/ui/components/common/fields/TextField";
import {BuilderElementRelationConditionGroup} from "@/models/builder-element-relations/builder-element-relation-condition-group";
import Checkbox from "@/ui/components/common/fields/Checkbox.vue";
import NumberField from "@/ui/components/common/fields/NumberField.vue";

export default {
  components: {
    NumberField,
    SearchableSelectField,
    OrangeBtn,
    BuilderElementActionFields,
    TextField,
    CloseIcon, BlueBtn, BuilderElementConditionFields, TextAreaField, AppBlock, Checkbox
  },
  props: {
    builderElements: {
      type: Array,
      required: true,
    },
    builders: {
      type: Array,
      required: true,
    }
  },
  computed: {
    formName() {
      return 'form';
    },

    form() {
      return this.$refs[this.formName];
    },

    conditionsToSelectField() {
      return builderElementRelationsService.conditionsArray()
    },

    actionsToSelectField() {
      return builderElementRelationsService.actionsArray();
    },

    buildersToSelectField() {
      return this.builders.map((builder) => ({
        text: builder.name,
        value: builder.id,
      }))
    },

    conditionsWithoutGroup() {
      return this.builderElementRelation.builderElementConditions.filter(itm => itm.group_code === null);
    },

    conditionGroups() {
      return this.builderElementRelation.builderElementConditionGroups.map(item => {
        return {
          ...item,
          conditions: this.builderElementRelation.builderElementConditions.filter(itm => itm.group_code === item.code)
        }
      })
    }
  },
  data: () => ({
    builderElementRelation: BuilderElementRelation.empty(),

    operationType: 'create',
  }),
  created() {
    builderRelationsEvents.selectRelation.on(this.onSelectBuilderRelation)

    this.setDefaultData();
  },
  destroyed() {
    builderRelationsEvents.selectRelation.off(this.onSelectBuilderRelation)
  },
  methods: {
    savePriority(value) {
      this.builderElementRelation.position = value;
    },
    onChangeIsValidation(value) {
      this.builderElementRelation.is_validation = +value;

      if (!this.builderElementRelation.is_validation) {
        this.builderElementRelation.show_validation_on = 0;
      }
    },
    onChangeShowValidationOn(value) {
      this.builderElementRelation.show_validation_on = +value;
    },
    onSelectBuilderRelation(builderElementRelation) {
      if (builderElementRelation.isEmpty()) {
        this.operationType = 'create';

        this.setDefaultData();
      } else {
        this.operationType = 'update';
        this.builderElementRelation = builderElementRelation
      }
    },

    onClickAddCondition(groupCode = null) {
      this.builderElementRelation.builderElementConditions.push(this.defaultCondition(groupCode))
    },

    onClickAddGroup() {
      const newEmptyGroup = this.addNewEmptyGroup();

      this.onClickAddCondition(newEmptyGroup.code);

      this.builderElementRelation.builderElementConditionGroups.push(newEmptyGroup)
    },

    onClickDeleteCondition(conditionElement) {
      this.builderElementRelation.builderElementConditions = this.builderElementRelation.builderElementConditions
          .filter((item) => item.id !== conditionElement.id)
    },

    onClickDeleteConditionGroup(conditionGroupElement) {
      this.builderElementRelation.builderElementConditionGroups = this.builderElementRelation.builderElementConditionGroups
          .filter((item) => item.id !== conditionGroupElement.id)

      this.builderElementRelation.builderElementConditions = this.builderElementRelation.builderElementConditions
          .filter((item) => item.group_code !== conditionGroupElement.code)
    },

    onClickAddAction() {
      this.builderElementRelation.builderElementActions.push(this.defaultAction())
    },

    onClickDeleteAction(actionElement) {
      this.builderElementRelation.builderElementActions = this.builderElementRelation.builderElementActions
          .filter((item) => item.id !== actionElement.id)
    },

    async onSave() {
      if (this.form.validate()) {
        const result = this.operationType === 'create'
            ? await this.saveRelation(this.builderElementRelation)
            : await this.updateRelation(this.builderElementRelation);

        if (result) {
          notificationService.success('Relation saved');

          this.resetRelationsData()

          builderRelationsEvents.relationSaved.emit(this.builderElementRelation);
        }
      }
    },

    defaultCondition(groupCode) {
      return BuilderElementRelationCondition.empty({
        group_code: groupCode,
        id: Math.random() + "",
      });
    },

    addNewEmptyGroup() {
      return BuilderElementRelationConditionGroup.empty({
        id: Math.random() + "",
        code: 'group' + Math.random(),
        name: ''
      })
    },

    defaultAction() {
      return BuilderElementRelationAction.empty({
        id: Math.random() + "",
      })
    },

    setDefaultData() {
      this.builderElementRelation = BuilderElementRelation.empty({
        builder_id: null,
      });

      this.builderElementRelation.builderElementConditions.push(this.defaultCondition());
      this.builderElementRelation.builderElementActions.push(this.defaultAction());
    },

    resetRelationsData() {
      this.operationType = 'create';

      this.onSelectBuilderRelation(BuilderElementRelation.empty())
    },

    saveRelation(builderElementRelation) {
      return this.$store.dispatch('admin/builderRelationsModule/saveBuilderElementRelation', {
        builderElementRelation
      })
    },

    updateRelation(builderElementRelation) {
      return this.$store.dispatch('admin/builderRelationsModule/updateBuilderElementRelation', {
        builderElementRelation
      })
    },

    onChangeRunOnTest(value) {
      this.builderElementRelation.run_on_test = +value;
    },
  }
}
</script>