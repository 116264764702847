<template>
  <div>
    <div class="d-flex justify-space-between">
      <h4 class="mt-3">{{ header }}</h4>

      <div class="d-flex">
        <orange-btn class="mr-2" @click="onClickEditPopup">
          Edit Mode
        </orange-btn>

        <blue-btn @click="onClickChange">
          Change
        </blue-btn>
      </div>
    </div>
    <app-block color="blue lighten-5" class="rounded">
      <json-builder-selector
          :builder="insuranceApi.builder"
      />
    </app-block>

    <parse-popup
        v-if="showParsePopup"
        type="json"
        @close="onCloseParsePopup"
        @parseScheme="onParseScheme"
    />

    <json-builder-editor
        v-if="showEditPopup"
        :builder="insuranceApi.builder"
        @close="onCloseEditPopup"
    />
  </div>
</template>
<script>
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import JsonBuilderSelector from "@/ui/components/json-builder/use-cases/json-builder-selector/JsonBuilderSelector.vue";
import {InsuranceApi} from "@/models/insurance-api.js";
import ParsePopup from "@/ui/components/admin/popups/ParsePopup.vue";
import JsonBuilderEditor from "@/ui/components/json-builder/use-cases/json-builder-editor/JsonBuilderEditor.vue";

export default {
  components: {JsonBuilderEditor, ParsePopup, JsonBuilderSelector, AppBlock, BlueBtn, OrangeBtn},
  props: {
    insuranceApi: {
      type: InsuranceApi,
      required: true,
    },
    header: {
      type: String,
      required: true
    }
  },

  data: () => ({
    showParsePopup: false,
    showEditPopup: false,
    showRelationsPopup: false,
  }),

  methods: {
    onClickChange() {
      this.showParsePopup = true;
    },

    onClickEditPopup() {
      this.showEditPopup = true;
    },

    onCloseEditPopup() {
      this.showEditPopup = false;
    },

    onSelectField() {
      this.showRelationsPopup = true;
    },

    onCloseParsePopup() {
      this.showParsePopup = false;
    },

    onParseScheme({scheme, type}) {
      this.$emit('parseScheme', {scheme, type, apiType: this.insuranceApi.key});

      this.onCloseParsePopup();
    }
  }
}
</script>