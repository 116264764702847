export const builderElementsApi = (http) => ({
    updateValues: (newElementsData) => http.post('/api/builder-elements/update/values', {
        data: newElementsData,
    }),

    updateVisibility: (newElementsData) => http.post('/api/builder-elements/update/visibility', {
        data: newElementsData,
    }),

    uploadFile: (builderElementId, file) =>  http.formDataRequest(`/api/builder-elements/${builderElementId}/upload-file`, {
        file
    }),

    deleteFile: (builderElementId, file) =>  http.post(`/api/builder-elements/${builderElementId}/delete-file`, {
        file
    }),

    duplicate: (builderElementId) => http.post(`/api/builder-elements/${builderElementId}/duplicate`),

    removeDuplicate: (builderElementId) => http.post(`/api/builder-elements/${builderElementId}/remove-duplicate`),

    multipleDuplicate: (builderElementId, amount, titleBuilderElementName) => http.post(
        `/api/builder-elements/${builderElementId}/multiple-duplicate/${amount}`,
        {
            title_field_name: titleBuilderElementName
        }
    ),

    deleteDuplicated: (builderElementId) => http.post(`/api/builder-elements/${builderElementId}/delete-multiple-duplicate`),

    specialDuplicate: (builderElementFromId, builderElementToId, actionId) => http.post(
        `/api/builder-elements/${builderElementFromId}/special-duplicate/${builderElementToId}/${actionId}`
    ),

    repeatByDuplicated: (builderElementFromId, builderElementToId, actionId) => http.post(
        `/api/builder-elements/${builderElementFromId}/repeat-by-duplicated/${builderElementToId}/${actionId}`
    ),

    specialCopyData: (builderElementFromId, builderElementToId, actionId) => http.post(
        `/api/builder-elements/${builderElementFromId}/special-copy-data/${builderElementToId}/${actionId}`
    ),

    repeatByBlock: (relationId, activeBuilderElementId, passiveBuilderElementId, amount) =>
        http.post(`/api/builder-elements/${relationId}/${passiveBuilderElementId}/repeat-by-block/${activeBuilderElementId}/${amount}`),

    repeatByCheckboxes: ({
        repeatElementId,
        changeTitleElementId,
        checkboxElementIds,
        originalTitle,
        maxSelectedCheckboxesCount
    }) => http.post(`/api/builder-elements/${repeatElementId}/repeat-by-checkboxes/${changeTitleElementId}`, {
        checkbox_element_ids: checkboxElementIds,
        original_title: originalTitle,
        max_selected_checkboxes_count: maxSelectedCheckboxesCount
    }),

    repeatValue: (activeBuilderElementId, passiveBuilderElementId, elementValue) => http.post(`/api/builder-elements/${passiveBuilderElementId}/repeat-value-from/${activeBuilderElementId}`, {
        value: elementValue
    }),

    updateValue: (builderElementId, data) => http.post(`/api/builder-elements/${builderElementId}/update/value`, {
        data,
    }),

    updatePaymentPeriodBuilderElement: (insuranceId, value) => http.post(`/api/builder-elements/update/payment-period-builder-element/${insuranceId}`, {
        value
    }),

    updateDurationBuilderElement: (insuranceId, value) => http.post(`/api/builder-elements/update/duration-builder-element/${insuranceId}`, {
        value
    }),
})