<template>
  <div>
    <v-row class="pb-2">
      <v-col class="pt-2">
        <toggle-block
          header="Notizen für den Innendienst"
          headerCssClasses="app-text__bold"
        >
          <div class="px-4 pb-4">
            Sie können dieses Feld nutzen, sofern Sie noch Anmerkungen für den Innendienst zum Antrag haben.<br><br>
            <b>Bitte beachten Sie:</b><br>
            Abweichungen in der Deckung oder dem Preis der Produkte sind nicht möglich.<br>
            Die individuelle manuelle Bearbeitung Ihres Anliegens kann einige Zeit in Anspruch nehmen.
            <v-row>
              <v-col class="pt-5 pb-5">
                <text-area-field
                  placeholder="Bitte eintragen"
                  :name="dataFieldKeys.notes"
                  :value="notes"
                  :disabled="isDisableAllInputs"
                  @saveField="saveField"
                />
              </v-col>
            </v-row>
          </div>
        </toggle-block>
      </v-col>
    </v-row>

    <insurance-step-wrapper
        @prevButtonClick="onClickPrev"
        @nextButtonClick="onClickNext"
        :next-button-text="nextBtnName"
    >
    </insurance-step-wrapper>
  </div>
</template>

<script>
import InsuranceStepWrapper from "@/ui/components/insurance/insurance-wrappers/InsuranceStepWrapper.vue"
import {STEP3_OPTIONS_BUTTON_NAMES} from "@/utils/fields/insurance-police-step-fields.js"
import {DATA_FIELD_KEYS} from "@/utils/fields/insurance-police-field-keys.js"
import ToggleBlock from "@/ui/components/common/blocks/ToggleBlock.vue";
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";

export default {
  components: {
    TextAreaField, ToggleBlock,
    InsuranceStepWrapper,
  },
  computed: {
    nextBtnName() {
      return STEP3_OPTIONS_BUTTON_NAMES.lock
    },
    dataFieldKeys() {
      return DATA_FIELD_KEYS
    },
    insurancePolice() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getInsurancePolice"]
    },
    isInsuranceFinishedAt() {
      return Boolean(this.$store.getters["insuranceModule/getFinishedAt"])
    },
    isDisableAllInputs() {
      return this.isInsuranceFinishedAt
    },
    notes() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.notes]
    },
  },
  methods: {
    onClickPrev() {
      this.$emit('clickPrev')
    },
    async onClickNext() {
      await this.sendElectronicSignatureEmail(this.insurancePolice.id)
      await this.updatePolice(this.insurancePolice.id, this.insurancePolice)

      this.$emit('clickNext')
    },
    async sendElectronicSignatureEmail(insurancePoliceId) {
      return await this.$store.dispatch('insuranceModule/insurancePoliceModule/sendElectronicSignatureEmail', {
            insurancePoliceId
          }
      );
    },
    saveField(val, key) {
      this.$store.commit('insuranceModule/insurancePoliceModule/setDataField', {key, val});
    },
    async updatePolice(insurancePoliceId, data) {
      await this.$store.dispatch('insuranceModule/insurancePoliceModule/updateInsurancePolice', {
          insurancePoliceId, data
        }
      );
    },
  },
}
</script>
