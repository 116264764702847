<template>
  <div>
    <v-row>
      <v-col cols="5"><div class="mt-3 small-text">Letztes Update Staging-Server</div></v-col>
      <v-col cols="7">
        <datepicker-field
        width="200px"
        placeholder="TT.MM.JJ"
        name="staging_updated_at"
        :value="stagingUpdatedAt"
        min-width="100px"
        @saveField="saveDateField"
      />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import DatepickerField from "@/ui/components/common/fields/DatepickerField.vue";

export default {
  components: {DatepickerField},
  computed: {
    stagingUpdatedAtElement() {
      return this.$store.getters["insuranceModule/dashboardInfoModule/getStagingUpdatedAtElement"]
    },
    stagingUpdatedAt() {
      return this.stagingUpdatedAtElement.value ?? ''
    },
  },
  async created() {
    await this.getStagingUpdatedAt()
  },
  methods: {
    async getStagingUpdatedAt() {
      return this.$store.dispatch('insuranceModule/dashboardInfoModule/getStagingUpdatedAtElement')
    },
    saveDateField(val) {
      this.saveStagingUpdatedAt(this.stagingUpdatedAtElement.id, {value: val})
    },
    async saveStagingUpdatedAt(id, requestData) {
      return this.$store.dispatch('insuranceModule/dashboardInfoModule/updateStagingAtInfoData', {id, requestData})
    },
  },
}
</script>

<style scoped>
.small-text {
  font-size: 12px;
}
</style>