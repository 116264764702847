<template>
  <div :style="{width: '100%'}">
    <v-row>
      <v-col cols="3">
        <p class="app-text__bold mt-2">Condition</p>

        <searchable-select-field
            name="condition_type"
            :items="conditions"
            :validation="{required: true,}"
            v-model="insuranceElementCondition.condition"
            @saveField="onChangeCondition"
        />
      </v-col>
      <v-col v-if="isBuilderElementCondition" class="pl-2" cols="3">
        <p class="app-text__bold builder-element mt-2">Builder Element</p>

        <select-builder-element-popup-field
            :validation="{required: true}"
            v-model="insuranceElementCondition.builder_element_id"
            :builder-elements="builderElements"
        />
      </v-col>
      <v-col v-if="isInsuranceElementCondition" cols="3" class="pl-2">
        <p class="app-text__bold builder-element mt-2">Insurance Element</p>

        <searchable-select-field
            name="insurance_element_id"
            placeholder="Select Element"
            item-value="id"
            item-text="name"
            :validation="{required: true}"
            v-model="insuranceElementCondition.insurance_element_id"
            :items="insuranceElements"
        />
      </v-col>
      <v-col cols="3" class="pl-2">
        <div v-if="isStaticCondition">
          <p class="app-text__bold mt-2">Value</p>
          <text-field
              name="value"
              v-model="insuranceElementCondition.condition_value"
          />
          <p v-if="valueHint">Condition format:</p>
          <p class="value-hint">{{ valueHint}}</p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SearchableSelectField from "@/ui/components/common/fields/SearchableSelectField.vue";
import {insuranceElementRelationService} from "@/utils/services/insurance-element-relation-service.js";
import TextField from "@/ui/components/common/fields/TextField.vue";
import {InsuranceElementCondition} from "@/models/insurance-element-relations/insurance-element-condition.js";
import SelectBuilderElementPopupField from "@/ui/components/builder/use-cases/builder-relations/SelectBuilderElementPopupField.vue";
import {insuranceElementConditionEnum} from "@/enums/insurance-element-relations-enum.js";

export default {
  components: {SelectBuilderElementPopupField, TextField, SearchableSelectField},
  props: {
    insuranceElementCondition: {
      type: InsuranceElementCondition,
      required: true,
    },
    conditions: {
      type: Array,
      required: true,
    },
    builderElements: {
      type: Array,
      required: true,
    },
    insuranceElements: {
      type: Array,
      required: true,
    }
  },
  computed: {
    valueHint() {
      if (this.insuranceElementCondition.condition === insuranceElementConditionEnum.VALUE_IS_IN_LIST) {
        return 'value1||value2||value3';
      }

      return ''
    }
  },
  data: () => ({
    isStaticCondition: false,
    isBuilderElementCondition: false,
    isInsuranceElementCondition: false,
  }),
  created() {
    this.onChangeCondition(this.insuranceElementCondition.condition)
  },
  methods: {
    onChangeCondition(conditionType) {
      this.isStaticCondition = insuranceElementRelationService.staticConditions().includes(conditionType)
      this.isInsuranceElementCondition = insuranceElementRelationService.insuranceElementConditions().includes(conditionType)
      this.isBuilderElementCondition = insuranceElementRelationService.builderElementConditions().includes(conditionType)
    },
  },
}
</script>