import {serverApi} from "@/api/server/server-api.js";

export const statisticModule = {
    namespaced: true,
    actions: {
        async statsInsuranceStatuses(context, {insuranceType, from, to, statuses = [], apiRoles= [], cities = []}) {
            const {data} = await serverApi.admin.statisticApi.insuranceStatuses({insuranceType, from, to, statuses, apiRoles, cities});

            if (data.success) {
                return data;
            }
        },

        async statsInsuranceEmptyPriceReasons(context, {insuranceType, from, to}) {
            const {data} = await serverApi.admin.statisticApi.insuranceEmptyPriceReasons({insuranceType, from, to});

            if (data.success) {
                return data;
            }
        },

        async statsInsuranceNpoptReasons(context, {insuranceType, from, to}) {
            const {data} = await serverApi.admin.statisticApi.insuranceNpoptReasons({insuranceType, from, to});

            if (data.success) {
                return data;
            }
        },
    },
};
