import {cities} from "@/utils/data/select-options";

export function findCityByPostalCode(postalCode) {
    return cities
        .find((item) => item.zip === postalCode);
}
export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function prepareInsuranceFullName(str) {
    if (str) {
        const parts = str.split(' ');

        return (parts.shift()) + '<br>' + (parts.join(' '))
    }

    return '';
}

export function getBooleanValue(value) {
    return value === true ||
        value === 1 ||
        value === 'Ja' ||
        (typeof value === 'string' && value.trim() === '1');
}