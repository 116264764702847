import {dateService} from "@/utils/services/date-service";
import {durationYearValue} from "@/enums/prise-tab-enum";

export const prefillDateFromDurationPriceTabAction = ({activeElement, passiveElement, context}) => {
    const duration = context.rootGetters["insuranceModule/insurancePriceModule/getPriceDataByKey"]('duration');
    const years = durationYearValue[duration] || 0;

    if (years > 0) {
        passiveElement.value =  dateService.addPeriod(activeElement.value, 'years', years);

        context.dispatch('insuranceModule/builderElementsModule/saveElement', {
            builderElement: passiveElement
        }, {root: true})
    }
}