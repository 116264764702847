import {serverApi} from "@/api/server/server-api.js";
import {Builder} from "@/models/builder.js";

export const builderCreatorModule = {
    namespaced: true,
    actions: {
        builderTemplates: async () => {
            const {data} = await serverApi.admin.builderCreator.getBuilderTemplates()

            return data.builders.map((builder) => new Builder(builder))
        },

        createBuilderByTemplate: async (context, {id, builderData}) => {
            const {data} = await serverApi.admin.builderCreator.createByTemplate(id, builderData)

            return new Builder(data.builder);
        }
    }
}