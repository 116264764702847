import {objectService} from "@/utils/services/object-service.js";
import {InsurancePriceAddonAction} from "@/models/insurance-price-addon-relations/insurance-price-addon-action.js";
import {InsurancePriceAddonCondition} from "@/models/insurance-price-addon-relations/insurance-price-addon-condition.js";
import {InsurancePriceAddonRelationConditionGroup} from "@/models/insurance-price-addon-relations/insurance-price-addon-relation-condition-group.js";

export class InsurancePriceAddonRelation {
    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(InsurancePriceAddonRelation.empty().toObject())
            : this.fillData(data);
    }

    fillData(data) {
        this.id = data.id;
        this.description = data.description;
        this.insurance_type_id = data.insurance_type_id;
        this.insurancePriceAddonActions = data.insurancePriceAddonActions.map((item) => new InsurancePriceAddonAction(item));
        this.insurancePriceAddonConditions = data.insurancePriceAddonConditions.map((item) => new InsurancePriceAddonCondition(item));
        this.insurancePriceAddonConditionGroups = data.insurancePriceAddonConditionGroups.map((item) => new InsurancePriceAddonRelationConditionGroup(item));
    }

    isEmpty() {
        return this.id === 0;
    }

    static empty(data = {}) {
        return new InsurancePriceAddonRelation({
            id: data.id || 0,
            description: data.description || '',
            insurance_type_id: data.insurance_type_id || 0,
            insurancePriceAddonActions: Array.isArray(data.insurancePriceAddonActions) ? data.insurancePriceAddonActions : [],
            insurancePriceAddonConditions: Array.isArray(data.insurancePriceAddonConditions) ? data.insurancePriceAddonConditions: [],
            insurancePriceAddonConditionGroups: Array.isArray(data.insurancePriceAddonConditionGroups) ? data.insurancePriceAddonConditionGroups: [],
        })
    }

    toObject() {
        return {
            ...this
        }
    }
}