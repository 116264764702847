export class ApiData {
    constructor({id, user_id, price_data, customer_data, created_at}) {
        this.id = id;
        this.user_id = user_id;
        this.price_data = price_data;
        this.customer_data = customer_data;
        this.created_at = created_at;
    }

    static createEmpty() {
        return new ApiData({
            id: null,
            user_id: '',
            price_data: null,
            customer_data: {
                birth_date: null,
                company_name: null,
                country: null,
                email: null,
                fax: null,
                gender: null,
                house_number: null,
                legal_form: null,
                manager_name: null,
                manager_number_1: null,
                mobile_number: null,
                name_1: null,
                name_2: null,
                name_3: null,
                number: null,
                phone: null,
                phone2: null,
                place: null,
                postal_code: null,
                s22_f2: null,
                street: null,
                user_city: null,
                user_email: null,
                user_phone: null,
                user_postcode: null,
                user_street: null,
                vn_email: null,
                vn_email2: null,
            },
            created_at: null,
        })
    }
}