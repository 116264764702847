import {objectService} from "@/utils/services/object-service.js";
import {InsuranceElementAction} from "@/models/insurance-element-relations/insurance-element-action.js";
import {InsuranceElementCondition} from "@/models/insurance-element-relations/insurance-element-condition.js";
import {InsuranceElementRelationConditionGroup} from "@/models/insurance-element-relations/insurance-element-relation-condition-group.js";

export class InsuranceElementRelation {

    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(InsuranceElementRelation.empty().toObject())
            : this.fillData(data);
    }

    fillData(data) {
        this.id = data.id;
        this.description = data.description;
        this.insurance_type_id = data.insurance_type_id;
        this.insuranceElementActions = data.insuranceElementActions.map((item) => new InsuranceElementAction(item));
        this.insuranceElementConditions = data.insuranceElementConditions.map((item) => new InsuranceElementCondition(item));
        this.insuranceElementConditionGroups = data.insuranceElementConditionGroups.map((item) => new InsuranceElementRelationConditionGroup(item));
    }

    isEmpty() {
        return this.id === 0;
    }

    static empty(data = {}) {
        return new InsuranceElementRelation({
            id: data.id || 0,
            description: data.description || '',
            insurance_type_id: data.insurance_type_id || 0,
            insuranceElementActions: Array.isArray(data.insuranceElementActions) ? data.insuranceElementActions : [],
            insuranceElementConditions: Array.isArray(data.insuranceElementConditions) ? data.insuranceElementConditions: [],
            insuranceElementConditionGroups: Array.isArray(data.insuranceElementConditionGroups) ? data.insuranceElementConditionGroups: [],
        })
    }

    toObject() {
        return {
            ...this
        }
    }

}