<template>
    <div class="app-container app-bg-light-smoke steps pb-10">
      <div class="app-bg-white py-5 px-5">
        <h5 class="finish-step-heading mb-5 pl-2">Gewähltes Antragsabschlussverfahren:</h5>
        <p>
          <square-check-icon :color="confirmationClickIconColor" :checked="isConfirmationClickActive"/>
          <span class="ml-1">Bestätigungsklick durch Kunden (Click-2-Contract)</span></p>
        <v-row>
          <v-col class="mt-2">
            <p>
              <square-check-icon :color="electronicSignatureIconColor" :checked="isElectronicSignatureActive" />
              <span class="ml-1">Unterschrift auf Papier</span></p>
          </v-col>
          <v-col class="mt-2" cols="3">
            <white-btn
                v-if="isShowChangeOption"
                width="265px"
                :disabled="isDisableAllInputs"
                @click="onChangeOption"
            >
              Abschlussverfahren ändern
            </white-btn>
            <div class="pt-2">
              <white-btn
                  v-if="isShowChangeOption"
                  width="265px"
                  :disabled="isDisableAllInputs"
                  @click="onRepeatSendProposalEmail"
              >
                E-Mail erneut versenden
              </white-btn>
            </div>

          </v-col>
        </v-row>
        <v-row v-if="isEstateAgentAzam">
          <v-col class="mt-2">
            <p>
              <square-check-icon :color="estateAgentAzamOptionIconColor" :checked="isEstateAgentOptionActive" />
              <span class="ml-1">{{ estateAgentLabel }}</span></p>
          </v-col>
        </v-row>
      </div>
      <confirmation-click-status v-if="isConfirmationClickActive"
                                 :email="customerEmail"
                                 @updateInsurancePolice="updateInsurancePolice"
      />
      <electronic-signature-status v-if="isElectronicSignatureActive"/>

      <document-application :documents="documents" class="mt-10" />
    </div>
</template>

<script>
import SquareCheckIcon from "@/ui/components/common/icons/SquareCheckIcon.vue"
import {DATA_FIELD_KEYS} from "@/utils/fields/insurance-police-field-keys.js"
import {
  CONFIRMATION_CLICK,
  ELECTRONIC_SIGNATURE,
  ESTATE_AGENT,
} from "@/utils/fields/insurance-police-step-fields.js"
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue"
import ConfirmationClickStatus from "@/ui/components/insurance/finish-contract-step/blocks/ConfirmationClickStatus.vue"
import DocumentApplication from "@/ui/components/insurance/finish-contract-step/blocks/DocumentApplication.vue"
import ElectronicSignatureStatus from "@/ui/components/insurance/finish-contract-step/blocks/ElectronicSignatureStatus.vue"
import {notificationService} from "@/utils/services/notification-service.js"

export default {
  components: {
    SquareCheckIcon,
    WhiteBtn,
    ConfirmationClickStatus,
    DocumentApplication,
    ElectronicSignatureStatus,
  },
  computed: {
    expiringToken() {
      return this.$store.getters["insuranceModule/expiringTokenModule/getExpiringToken"]
    },
    insurancePolice() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getInsurancePolice"]
    },
    insuranceId() {
      return this.$store.getters["insuranceModule/getInsuranceId"]
    },
    paymentOption() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.paymentOption]
    },
    isConfirmationClickActive() {
      return this.paymentOption === CONFIRMATION_CLICK
    },
    isElectronicSignatureActive() {
      return this.paymentOption === ELECTRONIC_SIGNATURE
    },
    isEstateAgentAzamActive() {
      return this.paymentOption === ESTATE_AGENT
    },
    confirmationClickIconColor() {
      return this.isConfirmationClickActive ? this.activeColor : this.disabledColor
    },
    electronicSignatureIconColor() {
      return this.isElectronicSignatureActive ? this.activeColor : this.disabledColor
    },
    estateAgentOptionIconColor() {
      return this.isEstateAgentOptionActive ? this.activeColor : this.disabledColor
    },
    estateAgentAzamOptionIconColor() {
      return this.isEstateAgentAzamActive ? this.activeColor : this.disabledColor
    },
    dataFieldKeys() {
      return DATA_FIELD_KEYS
    },
    disabledColor() {
      return '#DDDDDD'
    },
    activeColor() {
      return 'var(--light-blue)'
    },
    customerEmail() {
      return this.insurancePolice.data[this.dataFieldKeys.customerEmail]
    },
    documents() {
      return this.$store.getters["insuranceModule/insuranceFinishedModule/getFiles"]
    },
    insuranceType() {
      return this.$store.getters["insuranceModule/getInsuranceType"]
    },
    isShowChangeOption() {
      return this.isConfirmationClickActive
    },
    isEstateAgentOptionActive() {
      return this.paymentOption === ESTATE_AGENT
    },
    isEstateAgentAzam() {
      return this.$store.getters.authUser.isEstateAgentAZAM();
    },
    estateAgentLabel() {
      return ESTATE_AGENT;
    },
    isDisableAllInputs() {
      return Boolean(this.$store.getters["insuranceModule/getIsCustomerConfirmed"])
    },
  },
  data: () => ({
    loading: true,
  }),
  methods: {
    async getExpiringToken(policeId) {
      return await this.$store.dispatch('insuranceModule/expiringTokenModule/getExpiringTokenByPoliceId', {
        policeId,
      })
    },
    async onChangeOption() {
      await this.getExpiringToken(this.insurancePolice.id)

      if (this.expiringToken.activated_at) {
        notificationService.success('You cannot change insurance after activated Proposal email')

        return
      }

      this.$store.commit('insuranceModule/showPreloader');

      await this.statusFromFinishToPolice();

      this.$store.commit('insuranceModule/hidePreloader')
    },
    async statusFromFinishToPolice() {
      await this.$store.dispatch('insuranceModule/statusFromFinishToPolice')
    },
    async updateInsurancePolice() {
      this.$emit('updateInsurancePolice')
    },
    async onRepeatSendProposalEmail() {
      await this.getExpiringToken(this.insurancePolice.id)

      if (!this.expiringToken.activated_at) {
        await this.sendProposalEmail(this.insurancePolice.id, this.customerEmail)

        return
      }

      notificationService.success('Proposal email was activated')
    },
    async sendProposalEmail(insurancePoliceId, email) {
      const response = await this.$store.dispatch('insuranceModule/insurancePoliceModule/sendProposalEmail', {
            insurancePoliceId, email
          }
      );

      if (response.success) {
        notificationService.success('Email sent successfully')
      } else {
        notificationService.error(response.message)
      }
    },
  },
}
</script>

<style scoped>
.app-bg-aqua {
  padding-bottom: 80px;
}

.steps {
  margin-top: -100px;
}
</style>