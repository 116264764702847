export const addSpecialSymbolsValidationAction = ({passiveElement, action_value, context}) => {
  if (action_value) {
    const symbols = action_value.split(';');

    passiveElement.validation = {
      ...passiveElement.validation,
      bySpecialSymbols: {
        symbols,
        passiveElement
      }
    }

    context.dispatch('insuranceModule/builderElementsModule/saveElement', {
      builderElement: passiveElement
    }, {root: true})

  }
}