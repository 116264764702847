<template>
  <div>
    <v-row>
      <v-col cols="5" class="d-flex px-1">
        <checkbox
            text="Do we need start repeat?"
            class="app-text__bold"
            :value="value"
            @saveField="saveIsRepeat"
        />
      </v-col>
      <v-col cols="1" class="d-flex ">
        <div><question-tooltip>If we have default value and we need to repeat block. We need to run repeatByElement manually.</question-tooltip></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Checkbox from "@/ui/components/common/fields/Checkbox.vue";
import {fieldService} from "@/utils/services/field-service.js";
import QuestionTooltip from "@/ui/components/common/tooltips/QuestionTooltip.vue";

export default {
  components: {Checkbox, QuestionTooltip},
  props: {
    value: {
      type: String,
      default: '0',
    }
  },
  methods: {
    saveIsRepeat(value) {
      this.$emit('input', fieldService.selectedFieldValue(value))
      this.$emit('saveName', fieldService.selectedFieldValue(value));
    }
  }
}
</script>