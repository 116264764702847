export const builderElementEnum = {
    SECTION: 'section',
    BLOCK: 'block',
    ROW: 'row',
    LABEL: 'label',
    TEXT_FIELD: 'text_field',
    NUMBER_FIELD: 'number_field',
    RADIO_BUTTON: 'radio_button',
    MONEY_FIELD: 'money_field',
    TEXT_AREA_FIELD: 'textarea_field',
    FILE_FIELD: 'file_field',
    SELECT_FIELD: 'select_field',
    TOOLTIP: 'tooltip',
    TOOLTIP_BLOCK: 'tooltip_block',
    CHECKBOX: 'checkbox',
    BUTTON_SELECTOR: 'button_selector',
    DUPLICATE_BLOCK : 'duplicate_block',
    DATE_FIELD: 'date_field',
    VALIDATION_SECTION: 'validation_section',
    BUTTON: 'button',
    LINK_BUTTON: 'link_button',
    REPEATED_BLOCK: 'repeated_block',
    SEARCHABLE_SELECT: 'searchable_select',
    SIMPLE_POPUP: 'simple_popup',
    SEARCHABLE_MIN_SYMBOLS_FILTER: 'searchable_min_symbols_filter',
    IBAN_FIELD: 'iban_field',
}

export const builderElementsLabels = Object.values(builderElementEnum);

//TODO: replace
export const parentBuilderElements = [
    builderElementEnum.SECTION,
    builderElementEnum.ROW,
    builderElementEnum.BLOCK,
    builderElementEnum.DUPLICATE_BLOCK,
    builderElementEnum.TOOLTIP_BLOCK,
    builderElementEnum.REPEATED_BLOCK,
];

export const noTimeoutBuilderElements = [
    builderElementEnum.DATE_FIELD,
    builderElementEnum.FILE_FIELD,
    builderElementEnum.SELECT_FIELD,
    builderElementEnum.CHECKBOX,
    builderElementEnum.RADIO_BUTTON,
    builderElementEnum.BUTTON_SELECTOR,
    builderElementEnum.SEARCHABLE_SELECT,
    builderElementEnum.SIMPLE_POPUP,
    builderElementEnum.SEARCHABLE_MIN_SYMBOLS_FILTER,
];

export const setSelectFieldMethods = {
    MANUALLY: 'Manually',
    YEARS: 'Years',
}

export const selectFieldYearsDirections = {
    TO_THE_PAST: 'to_the_past',
    TO_THE_FUTURE: 'to_the_future',
}

export const selectFieldStartYearTypes = {
    MANUAL: 'Manual',
    CURRENT: 'Current',
}