import {builderElementsEvents} from "@/utils/events/builder-elements-events";

export const deleteDuplicatedElementsAction = async ({passiveElement, context}) => {
    const deletedRepeatedBlocks = await context.dispatch('insuranceModule/builderElementsModule/deleteDuplicated', {
        builderElement: passiveElement
    }, {root: true})

    if (deletedRepeatedBlocks) {
        builderElementsEvents.reloadBuilders.emit();
    }
}