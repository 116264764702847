export default {
    computed: {
        options() {
            return this.builderElement.data?.options || [];
        },

        itemTextKey() {
            return this.builderElement.data?.itemTextKey || 'text';
        },

        itemValueKey() {
            return this.builderElement.data?.itemValueKey || 'value';
        }
    }
}