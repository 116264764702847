<template>
  <div>
    <div class="text-center">
      <h3>Simple popup</h3>
    </div>

    <v-row>
      <v-col cols="12">
        <name-editor
            v-model="builderElement.name"
        />
      </v-col>

      <v-col cols="4">
        <priority-editor
            v-model.number="builderElement.priority"
        />
      </v-col>
    </v-row>

    <div>
      <p class="app-text__bold">Popup header</p>
      <text-field
          name="popupHeader"
          :value="builderElement.data.popupHeader"
          @saveField="saveHeader"
      />
    </div>

    <div>
      <p class="app-text__bold">Popup text</p>
      <text-area-field
          name="popupText"
          :value="builderElement.data.popupText"
          @saveField="saveText"
      />
    </div>

    <div>
      <p class="app-text__bold">Popup button text</p>
      <text-field
          name="popupButtonText"
          :value="builderElement.data.popupButtonText"
          @saveField="saveButtonText"
      />
    </div>

    <id-editor
        class="mt-6"
        v-model="builderElement.data.id"
    />

    <css-class-editor
        class="mt-6"
        v-model="builderElement.data.css_class"
    />

  </div>
</template>

<script>
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor";
import TextAreaField from "@/ui/components/common/fields/TextAreaField";
import IdEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/IdEditor";
import CssClassEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/CssClassEditor";
import {BuilderElement} from "@/models/builder-element";
import {textService} from "@/utils/services/text-service";
import TextField from "@/ui/components/common/fields/TextField";

export default {
  components: {TextField, PriorityEditor, NameEditor, TextAreaField, IdEditor, CssClassEditor},
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  },
  methods: {
    saveText(value) {
      this.builderElement.setDataItem('popupText', value);
      this.builderElement.value = textService.replaceHtmlTags(value);
    },
    saveHeader(value) {
      this.builderElement.setDataItem('popupHeader', value);
    },
    saveButtonText(value) {
      this.builderElement.setDataItem('popupButtonText', value);
    },
  }
}
</script>