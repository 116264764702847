<template>
  <div>
    <text-field
        class="cursor-pointer"
        name="builder_element"
        @click.native="onClickField"
        :placeholder="placeholder"
        :value="selectedElement.name"
        :validation="validation"
        icon="mdi-magnify"
        readonly
    />

    <simple-popup
        width="1200px"
        min-height="750px"
        header=""
        :background="'var(--smoke)'"
        v-if="showPopup"
        @close="onClosePopup"
    >
      <div>
        <searchable-select-field
            name="builderElement"
            :items="builderElementsToSelectField"
            @saveField="onSelectViaSelectField"
        />
      </div>

      <div v-if="!loading">
        <div
            class="cursor-pointer__important overflow-y-auto"
            @mousemove="onMouseMoveOnElement($event)"
            @mouseleave="onMouseLeaveElement"
            @click.prevent="onSelectElement($event)"
        >
          <builder-component
              :builder="builder"
              :builder-element-id="builderElementId"
              :admin-edit-mode="true"
          />
        </div>
        <div class="mt-8 d-flex justify-lg-end" v-if="buildersId.length > 1">
          <orange-btn @click="onClickNextBuilder">
            Next Builder
          </orange-btn>
        </div>
      </div>

      <div :style="{marginTop: '275px'}" v-else>
        <app-preloader></app-preloader>
      </div>

    </simple-popup>
  </div>
</template>
<script>
import TextField from "@/ui/components/common/fields/TextField.vue";
import {Builder} from "@/models/builder.js";
import SimplePopup from "@/ui/components/common/popup/SimplePopup.vue";
import BuilderComponent from "@/ui/components/builder/Builder.vue";
import {builderQuerySelectors} from "@/utils/query-selectors/builder-query-selectors.js";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue";
import {BuilderElement} from "@/models/builder-element.js";
import SearchableSelectField from "@/ui/components/common/fields/SearchableSelectField.vue";

export default {
  components: {SearchableSelectField, AppPreloader, OrangeBtn, SimplePopup, TextField, BuilderComponent},
  model: {
    prop: 'builderElementId',
    event: 'selectElement',
  },
  props: {
    builderElements: {
      type: Array,
      required: true,
    },
    builderElementId: {
      type: String,
      required: false,
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    placeholder: {
      type: String,
      default: 'Select Element',
    }
  },
  computed: {
    builderElementsToSelectField() {
      return this.builderElements.map((element) => ({
        text: element.name,
        value: element.id,
      }))
    }
  },
  data: () => ({
    buildersId: [],
    currentBuilderId: null,

    builder: Builder.empty(),
    selectedElement: BuilderElement.empty(),

    showPopup: false,
    loading: false,
  }),
  async created() {
    this.fillBuildersId(this.builderElements);

    this.fillTextField(this.builderElementId)

    this.changeEditMode(true);
  },
  methods: {
    changeEditMode(editMode) {
      this.$store.dispatch('admin/builderElementModeModule/changeEditMode', {
        editMode
      })
    },
    async onClickField() {
      this.showPopup = true;
      this.loading = true;
      this.builder = await this.getBuilder(this.currentBuilderId);
      this.loading = false;
    },

    onClosePopup() {
      this.showPopup = false;
    },

    async onClickNextBuilder() {
      const index = this.buildersId.findIndex((builderId) => builderId === this.currentBuilderId);

      this.loading = true;

      //if next builderId exist we use it, if no we use first builder id
      if (index !== -1 && this.buildersId[index + 1] !== undefined) {
        this.currentBuilderId = this.buildersId[index + 1]
      } else {
        this.currentBuilderId = this.buildersId[0];
      }

      this.builder = await this.getBuilder(this.currentBuilderId);

      setTimeout(() => this.loading = false, 800);
    },

    onMouseLeaveElement() {
      builderQuerySelectors.emptyAllExcept('');
    },

    onMouseMoveOnElement($event) {
      const {ref, builderElementId} = builderQuerySelectors.findClosestElement($event)

      builderQuerySelectors.emptyAllExcept('');

      if (ref && builderElementId) {
        builderQuerySelectors.markHovered(ref);
      }
    },

    onSelectViaSelectField(elementId) {
      this.fillTextField(elementId)
      this.$emit('selectElement', elementId)
      this.onClosePopup();
    },

    onSelectElement($event) {
      const {builderElementId} = builderQuerySelectors.findClosestElement($event);

      this.fillTextField(builderElementId);

      this.$emit('selectElement', builderElementId);
      this.onClosePopup();
    },

    fillTextField(id) {
      const element = this.builderElements.find((elem) => elem.id === id);

      this.selectedElement = element || BuilderElement.empty();
    },

    fillBuildersId(elements) {
      elements.forEach((element) => {
        if (!this.buildersId.includes(element.builder_id)) {
          this.buildersId.push(element.builder_id);
        }

        if (!this.currentBuilderId) {
          this.currentBuilderId = element.builder_id;
        }
      })
    },

    getBuilder(builderId) {
      return this.$store.dispatch('admin/getBuilder', {
        builderId,
      });
    }
  }
}
</script>
<style lang="scss">
.builder-element {
  .style-block {
    box-sizing: border-box;
    border: 1px solid transparent;
  }

  .element-hovered {
    border: 1px solid var(--light-blue) !important;
  }

  .element-selected {
    border: 1px solid red !important;
  }
}
</style>