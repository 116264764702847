export default {
    computed: {
        width() {
            return this.builderElement.data?.width || 'auto';
        },

        height() {
            return this.builderElement.data?.height || 'auto';
        }
    }
}