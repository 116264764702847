<template>
  <div :style="{padding, margin, width, height}">

    <div style="position: relative">
      <question-tooltip
          class="tooltip-block"
          size="20px"
      >
        <div v-html="value"></div>
      </question-tooltip>
    </div>


      <builder-elements
          class="pt-2"
          :elements="children"
          :builder-element-id="builderElementId"
          :admin-edit-mode="adminEditMode"
      />
  </div>
</template>
<script>
import Padding from "./mixins/Padding.js";
import ParentElement from "./mixins/ParentElement.js";
import DefaultElement from "./mixins/DefaultElement.js";
import Size from "@/ui/components/builder/elements/mixins/Size.js";
import QuestionTooltip from "@/ui/components/common/tooltips/QuestionTooltip.vue";

export default {
  components: {QuestionTooltip},
  mixins: [DefaultElement, Size, Padding, ParentElement],
  props: {
    builderElementId: {
      type: String,
      required: false,
    },
    adminEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  }
}
</script>
<style scoped>
.tooltip-block {
  position: absolute;
  right: 0;
  top: 0;
}
</style>