<template>
<div>
  <app-block height="750px" class="pr-9">
    <div class="">
      <app-preloader class="mt-16" v-if="loading"/>

      <div v-else class="mt-4">
        <p class="app-text__bold font-s-24">Relations </p>
        <insurance-price-addon-relations-table
            :insurance-price-addon-relations="insurancePriceAddonRelations"
            :selected-addon="selectedAddon"
            @clickDelete="onClickDelete"
            @clickEdit="onClickEdit"
        />
      </div>
    </div>

  </app-block>
</div>
</template>

<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue";
import InsurancePriceAddonRelationsTable
  from "@/ui/components/admin/insurance-price-addon-relations/tables/InsurancePriceAddonRelationsTable.vue";
import {insurancePriceAddonRelationEvents} from "@/utils/events/insurance-price-addon-relation-events.js";
import {InsurancePriceAddonRelation} from "@/models/insurance-price-addon-relations/insurance-price-addon-relation.js";
import {notificationService} from "@/utils/services/notification-service.js";
import {confirmService} from "@/utils/services/confirm-service.js";
import {InsuranceType} from "@/models/insurance-type.js";

export default {
  components: {
    AppBlock,
    InsurancePriceAddonRelationsTable,
    AppPreloader,
  },
  props: {
    insuranceType: {
      type: InsuranceType,
      required: true,
    }
  },
  data: () => ({
    insurancePriceAddonRelations: [],
    selectedAddon: InsurancePriceAddonRelation.empty(),

    loading: false,
  }),
  async created() {
    await this.reloadRelations();

    insurancePriceAddonRelationEvents.relationSaved.on(this.onRelationSaved);
  },
  beforeDestroy() {
    insurancePriceAddonRelationEvents.relationSaved.off(this.onRelationSaved);
  },
  methods: {
    async onClickDelete(insurancePriceAddonRelation) {
      if (!confirmService.simpleConfirm("Are you really want to delete relation?")) {
        return;
      }

      this.loading = true;
      const result = await this.deleteRelation(this.insuranceType, insurancePriceAddonRelation);

      if (result) {
        await this.reloadRelations();

        this.onSelectInsurancePriceAddonRelation(InsurancePriceAddonRelation.empty());

        notificationService.success('Relation was deleted')
      }
    },

    onClickEdit(insurancePriceAddonRelation) {
      this.onSelectInsurancePriceAddonRelation(insurancePriceAddonRelation);

      this.selectedElement = insurancePriceAddonRelation;
    },

    onSelectInsurancePriceAddonRelation(elementRelation) {
      insurancePriceAddonRelationEvents.selectRelation.emit(elementRelation);
    },

    onRelationSaved() {
      this.selectedAddon = InsurancePriceAddonRelation.empty();
      this.onSelectInsurancePriceAddonRelation(InsurancePriceAddonRelation.empty())

      this.reloadRelations();
    },

    async reloadRelations() {
      this.loading = true;
      this.insurancePriceAddonRelations = await this.getInsurancePriceAddonRelations(this.insuranceType);
      this.loading = false;
    },

    deleteRelation(insurancePriceAddon, insurancePriceAddonRelation) {
      return this.$store.dispatch('admin/insurancePriceAddonRelationsModule/deleteInsurancePriceAddonRelation', {
        insurancePriceAddon,
        insurancePriceAddonRelation,
      })
    },

    getInsurancePriceAddonRelations(insuranceType) {
      return this.$store.dispatch('admin/insurancePriceAddonRelationsModule/getInsurancePriceAddonRelations', {
        insuranceType,
      })
    },
  },
}
</script>
