import {serverApi} from "@/api/server/server-api.js";
import {InsuranceApi} from "@/models/insurance-api.js";
import {insuranceApiEnum} from "@/enums/insurance-api-enum.js";

export const insuranceApiModule = {
    namespaced: true,
    actions: {
        async assignPriceRequest(context, {insuranceType, builder}) {
            const {data} = await serverApi.admin.insuranceType.assignPriceRequest(insuranceType.id, builder.id)

            return data.success;
        },

        async assignPriceResponse(context, {insuranceType, builder}) {
            const {data} = await serverApi.admin.insuranceType.assignPriceResponse(insuranceType.id, builder.id)

            return data.success;
        },

        async getApiByInsuranceType(context, {insuranceType}) {
            const {data} = await serverApi.admin.insuranceType.getInsuranceApis(insuranceType.id)

            return {
                responseApi: data.responseApi ? new InsuranceApi(data.responseApi) : InsuranceApi.empty({
                    key: insuranceApiEnum.PRICE_API_RESPONSE
                }),
                requestApi: data.requestApi ? new InsuranceApi(data.requestApi) : InsuranceApi.empty({
                    key: insuranceApiEnum.PRICE_API_REQUEST
                }),
            }
        },
        async adminAmsGetInsurance(context, {insuranceId}) {
            const {data} = await serverApi.admin.insuranceApi.amsGetInsurance(insuranceId)

            if (data.success) {
                return data
            }
        },

        async adminAmsConnectAmsXml(context, {insuranceId}) {
            const {data} = await serverApi.admin.insuranceApi.amsConnect(insuranceId)

            if (data.success) {
                return data
            }
        },

        async adminAmsConnectAmsBigXml(context, {insuranceId}) {
            const {data} = await serverApi.admin.insuranceApi.amsConnectBigXml(insuranceId)

            if (data.success) {
                return data
            }
        },
    }
}