<template>
  <div>
    <div class="text-center">
      <h3>File Field</h3>
    </div>

    <v-row>
      <v-col cols="12">
        <name-editor
            v-model="builderElement.name"
        />
      </v-col>

      <v-col cols="6">
        <priority-editor
            v-model.number="builderElement.priority"
        />
      </v-col>

      <v-col class="pl-4" cols="6">
        <width-editor
            v-model="builderElement.data.width"
        />
      </v-col>

      <v-col cols="6">
        <placeholder-editor
          v-model="builderElement.data.placeholder"
        />
      </v-col>
    </v-row>

    <margin-editor class="mt-2"
        v-model="builderElement.data.margin"
    />

    <v-row>
      <v-col  class="pl-4">
        <is-required
          v-model="builderElement.validation.required"
        />
      </v-col>
      <v-col>
        <is-show-deleting-file-btn-editor class=" mb-10" v-model="builderElement.data.isShowDeletingFileBtn"/>
      </v-col>
    </v-row>

  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import WidthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/WidthEditor.vue";
import PlaceholderEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PlaceholderEditor.vue";
import MarginEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MarginEditor.vue";
import IsShowDeletingFileBtnEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/IsShowDeletingFileBtnEditor.vue";
import IsRequired from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/isRequired.vue";

export default {
  components: {MarginEditor, PlaceholderEditor, WidthEditor, PriorityEditor, NameEditor, IsShowDeletingFileBtnEditor, IsRequired},
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  }
}
</script>