<template>
  <v-icon
      @click="$emit('click')"
      class="cursor-pointer rounded-circle px-1 py-1"
      v-ripple="true"
      :size="size"
      :color="color"
  >
    mdi-close
  </v-icon>
</template>
<script>
export default {
    props: {
      size: {
        type: String,
        default: '30px',
      },
      color: {
        type: String,
        default: 'red',
      },
    }
}
</script>