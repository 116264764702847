<template>
  <div v-if="loaded">
    <div class="mb-8">
      <h2>{{ insuranceType.name }}</h2>
    </div>

    <v-tabs
        class="app-text__bold"
        background-color="var(--light-blue)"
        color="white"
        @change="onChangeTab"
        v-model="tab"
    >
      <v-tab key="builders" href="#builders">Builders</v-tab>
      <v-tab key="elements" href="#elements">Insurance Elements</v-tab>
      <v-tab key="price-addons" href="#price-addons">Price Addons</v-tab>
      <v-tab key="static-files" href="#static-files">Static Files</v-tab>
      <v-tab key="api" href="#api">API</v-tab>
      <v-tab>Mails</v-tab>
      <v-tab>PDF</v-tab>
    </v-tabs>
    <v-tabs-items
        v-model="tab"
    >
      <v-tab-item value="builders">
        <app-block>
          <builders-section
              class="py-2"
              :insurance-type="insuranceType"
          />
        </app-block>
      </v-tab-item>
      <v-tab-item value="elements">
        <app-block>
          <elements-section
              class="py-2"
              :insurance-type="insuranceType"
          />
        </app-block>
      </v-tab-item>
      <v-tab-item value="price-addons">
        <app-block>
          <price-addons-section
              class="py-2"
              :insurance-type="insuranceType"
          />
        </app-block>
      </v-tab-item>
      <v-tab-item value="static-files">
        <app-block>
          <static-files-section
              :insurance-type="insuranceType"
          />
        </app-block>
      </v-tab-item>
      <v-tab-item value="api">
        <app-block>
            <api-section
              :insurance-type="insuranceType"
            />
        </app-block>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import BuildersSection from "@/ui/components/admin/insurance-type/BuildersSection.vue";
import ElementsSection from "@/ui/components/admin/insurance-type/ElementsSection.vue";
import StaticFilesSection from "@/ui/components/admin/insurance-type/StaticFilesSection.vue";
import ApiSection from "@/ui/components/admin/insurance-type/ApiSection.vue";
import PriceAddonsSection from "@/ui/components/admin/insurance-type/PriceAddonsSection.vue";

export default {
  components: {ApiSection, StaticFilesSection, ElementsSection, BuildersSection, AppBlock, PriceAddonsSection},
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    insuranceType: null,
    loaded: false,

    tab: 0,
  }),
  async created() {
    this.insuranceType = await this.getInsuranceType(this.type);

    this.fillTabFromUrl();

    this.loaded = true;
  },
  methods: {
    onChangeTab() {
      //use history for skip page reloading
      history.pushState(
          {},
          null,
          this.$route.path + '?' + `tab=${this.tab}`
      )
    },

    fillTabFromUrl() {
      this.tab = this.$route.query.tab || 0
    },

    getInsuranceType(key) {
      return this.$store.dispatch('admin/insuranceTypeModule/getOriginalInsuranceType', {
        key
      })
    },
  }
}
</script>