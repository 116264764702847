import {objectService} from "@/utils/services/object-service.js";
import {RUN_ON_ENUM} from "@/enums/builder-element-relations-enum.js";

export class InsurancePriceAddonAction {

    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(InsurancePriceAddonAction.empty().toObject())
            : this.fillData(data);
    }

    fillData(data) {
        this.id = data.id;
        this.relation_id = data.relation_id;
        this.addon_id = data.addon_id;
        this.action = data.action;
        this.action_value = data.action_value;
        this.run_on = data.run_on;
    }

    isEmpty() {
        return this.id === 0;
    }

    static empty(data = {}) {
        return new InsurancePriceAddonAction({
            id: data.id || 0,
            relation_id: data.relation_id || '',
            addon_id: data.addon_id || '',
            action: data.action || '',
            action_value: data.action_value || '',
            run_on: (data.run_on === RUN_ON_ENUM.TRUE || data.run_on === RUN_ON_ENUM.FALSE) ? data.run_on : RUN_ON_ENUM.TRUE,
        })
    }

    toObject() {
        return {
            ...this
        }
    }

}