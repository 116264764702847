export const DO_20_PRICE_TAB_CONDITION_VALUES = [
    'Sonstige Bank',
    'Sparkasse oder Genossenschaftbank',
    'Privatbank',
    'Investmentbank',
    'Treuhand- und sonstige Fonds und ähnliche Finanzinstitutionen',
    'Sonstige Finanzdienstleistungen, ausgenommen Versicherungs- und Pensionsvorsorgedienstleistungen n.a.g.',
    'Versicherung',
    'Pensionsvorsorge, -kasse',
    'Börsenmaklerei',
    'Treuhänder',
    'Vermittlung von Versicherungs- und Finanzdienstleistungen auf Provisionsbasis (GewO)',
    'Vermittlung von Versicherungs- und Finanzdienstleistungen auf Honorarbasis (GewO)',
    'Tätigkeiten Kreditwesengesetz (KWG)',
    'Fondsverwaltung',
    'Krypto-Währungen, -börsen, Block Chain Technologie, Verwahrung digitaler Vermögenswerte',
    'Immobilienmakler, -verwalter, -sachverständiger',
    'Verwaltungsbeirat',
    'Rechts- und Patentanwälte',
    'Notare',
    'Sonstige Rechtsdienstleistungen, auch gem. § 10 RDG:Rentenberatung, Rechtsdienstleistungen im ausl. Recht',
    'Steuerberater, -bevollmächtigter, Wirtschaftprüfer, Buchhalter',
    'Unternehmensberatung',
    'Personalberatung, -training',
    'Architekt, Ingenieur',
    'Werbung, Werbeagentur',
    'Web- und Grafikdesigner, Mediengestalter, Multimedia Producer, Mediendesigner',
    'Fotograf',
    'Sachverständiger, Gutachter, Zertifizierer',
    'Dolmetscher, Übersetzer',
    'Personal-, Arbeitskräftevermittlung',
    'Detektei, Auskunftei',
    'Büro-Service-Unternehmen',
    'Callcenter',
    'Inkassounternehmen, -büro',
    'Lohnsteuerhilfeverein',
    'Betreuungsverein',
    'Bestattungsinstitut',
];