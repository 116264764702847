<template>
  <div>
    <simple-popup
        v-if="!adminEditMode && showPopupByUser && showPopupByCondition"
        :header=popupHeader
        @close="hidePopupByUser">
      <v-row>
        <v-col cols="12">
          <p>{{ popupText }}</p>
        </v-col>
        <div class="d-flex">
          <white-btn class="mr-2" @click="hidePopupByUser">{{ popupButtonText }}</white-btn>
        </div>
      </v-row>
    </simple-popup>
    <div v-if="adminEditMode" class="admin-simple-popup-block">
      <h4>{{ popupHeader }}</h4>
      <div><p>{{ popupText}}</p></div>
      <div>
        <white-btn class="mr-2" @click="() => false">{{ popupButtonText }}</white-btn>
      </div>
    </div>
  </div>
</template>

<script>
import SimplePopup from "@/ui/components/common/popup/SimplePopup.vue";
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import DefaultElement from "@/ui/components/builder/elements/mixins/DefaultElement";
import {builderElementsEvents} from "@/utils/events/builder-elements-events";

export default {
  components: {
    SimplePopup,
    WhiteBtn,
  },
  mixins: [DefaultElement],
  props: {
    adminEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    popupHeader() {
      return this.builderElement.data?.popupHeader
    },
    popupButtonText() {
      return this.builderElement.data?.popupButtonText
    },
    popupText() {
      return this.builderElement.data?.popupText
    },
    showPopupByCondition() {
      return this.builderElement.isVisible();
    },
  },
  data() {
    return {
      showPopupByUser: true,
    }
  },
  mounted() {
    builderElementsEvents.changedPopupBlockState.on(this.onChangePopupState);
  },
  beforeDestroy() {
    builderElementsEvents.changedPopupBlockState.off(this.onChangePopupState);
  },
  methods: {
    hidePopupByUser() {
      this.showPopupByUser = false;
    },
    onChangePopupState() {
      this.showPopupByUser = true;
    }
  }
}
</script>

<style scoped>
.admin-simple-popup-block {
  width: 100%;
  padding: 20px;
  background: white;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>