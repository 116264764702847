<template>
  <div>
    <insurance-step-factory
        v-if="!preloader"
        :insurance="insurance"
    />
    <div class="mt-12" v-else>
      <app-preloader />
    </div>
  </div>
</template>
<script>
import {Insurance} from "@/models/insurance.js";
import InsuranceStepFactory from "@/ui/components/insurance/insurance-steps/InsuranceStepFactory.vue";
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue";

export default {
  components: {AppPreloader, InsuranceStepFactory},
  props: {
    insurance: {
      type: Insurance,
      required: true,
    }
  },
  computed: {
      preloader() {
        return this.$store.getters["insuranceModule/getPreloader"]
      }
  },
  async created() {
    await this.setInsuranceToStore(this.insurance);
  },
  methods: {
    async setInsuranceToStore(insurance) {
      this.$store.commit('insuranceModule/setInsurance', insurance);

      await this.$store.dispatch('insuranceModule/resetInsuranceData', {insurance})
    },
  }
}
</script>