<template>
  <div>
    <div v-if="processPreloader" class="mt-12 price-tab-preloader" >
      <app-preloader />
    </div>

    <empty-insurance-price
        v-if="isEmptyPrice"
    />

    <corporate-d-o-insurance-price
        v-else-if="isShowCorporateDO"
        :is-disable-all-inputs="isDisableAllInputs"
    />

    <doppelband-insurance-price
        v-else-if="isShowDoppelband"
        :is-disable-all-inputs="isDisableAllInputs"
    />

    <default-insurance-price
        v-else-if="isShowDefaultInsurePrice"
        :is-disable-all-inputs="isDisableAllInputs"
    />

    <single-object-insurance-price
        v-else-if="isSingleObjectInsurancePrice"
    />

  </div>
</template>
<script>
import DefaultInsurancePrice from "@/ui/components/insurance/insurance-prices/DefaultInsurancePrice.vue";
import EmptyInsurancePrice from "@/ui/components/insurance/insurance-prices/EmptyInsurancePrice.vue";
import SingleObjectInsurancePrice from "@/ui/components/insurance/insurance-prices/SingleObjectInsurancePrice.vue";
import {insurancePriceTypeViewEnum} from "@/enums/insurance-price-type-view-enum.js";
import CorporateDOInsurancePrice from "@/ui/components/insurance/insurance-prices/CorporateDOInsurancePrice.vue";
import DoppelbandInsurancePrice from "@/ui/components/insurance/insurance-prices/DoppelbandInsurancePrice.vue";
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue";

export default {
  components: {
    CorporateDOInsurancePrice,
    EmptyInsurancePrice,
    DefaultInsurancePrice,
    SingleObjectInsurancePrice,
    DoppelbandInsurancePrice,
    AppPreloader
  },
  computed: {
    processPreloader() {
      return this.$store.getters["insuranceModule/getProcessPreloader"]
    },

    insuranceId() {
      return this.$store.getters["insuranceModule/getInsuranceId"]
    },

    insurancePrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePrice"]
    },

    isEmptyPrice() {
      return this.$store.getters["insuranceModule/getInsurance"].isEmptyPrice();
    },

    isShowDefaultInsurePrice() {
      return !this.isEmptyPrice
          && this.insurancePrice.getTypeView() !== insurancePriceTypeViewEnum.SINGLE_OBJECT
          && this.isLoaded
    },

    isSingleObjectInsurancePrice() {
      return this.insurancePrice.getTypeView() === insurancePriceTypeViewEnum.SINGLE_OBJECT && this.isLoaded
    },

    isShowCorporateDO() {
      return this.insurancePrice.getTypeView() === insurancePriceTypeViewEnum.CORPORATE_DO && this.isLoaded
    },

    isShowDoppelband() {
      return this.insurancePrice.getTypeView() === insurancePriceTypeViewEnum.DOPPELBAND && this.isLoaded
    },

    isDisableAllInputs() {
      return Boolean(this.$store.getters["insuranceModule/getFinishedAt"])
    },
  },
  data: () => ({
    isLoaded: false,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      await this.getInsurancePrice(this.insuranceId)

      await this.determineInsurancePriceView(this.insuranceId)

      this.isLoaded = true
    },
    async getInsurancePrice(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/getInsurancePriceByInsuranceId', {
        insuranceId,
      })
    },
    async determineInsurancePriceView(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/determineInsurancePriceView', {
        insuranceId,
      });
    },
  },
}
</script>
<style scoped>
.price-tab-preloader {
  z-index: 1000;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5)
}
</style>