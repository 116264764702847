<template>
  <v-row justify="center">
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        persistent
    >
      <v-toolbar
          absolute
          dark
          color="var(--dark)"
      >
        <span class="text-h4 text--white">Price addon Creator</span>

        <v-spacer/>

        <white-btn height="80%" @click="onClose">Close</white-btn>
      </v-toolbar>
      <v-card>
        <price-addon-form
            :price-addon="priceAddon"
            @save="onSave"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import {InsurancePriceAddon} from "@/models/insurance-price-addon";
import PriceAddonForm from "@/ui/components/builder/use-cases/components/PriceAddonForm";

export default {
  components: {WhiteBtn, PriceAddonForm},
  props: {
    priceAddon: {
      type: InsurancePriceAddon,
      required: true,
    }
  },
  data: () => ({
    show: true,
  }),
  methods: {
    onClose() {
      this.$emit('close');
    },
    onSave(priceAddon) {
      this.$emit('onCreatePriceAddon', priceAddon);
    }
  }
}
</script>
<style scoped>
.builder-editor__section {
  padding-top: 100px;
  padding-left: 15px;
}
</style>