import {BuilderElement} from "@/models/builder-element.js";

export default {
    props: {
        builderElement: {
            type: BuilderElement,
            required: true,
        }
    },
    computed: {
        value() {
            return this.builderElement.value;
        },

        name() {
            return this.builderElement.name;
        }
    }
}