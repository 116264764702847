import {objectService} from "@/utils/services/object-service.js";
import {InsuranceBuilder} from "@/models/insurance-builder.js";
import {InsuranceElement} from "@/models/insurance-element.js";

export class InsuranceType {
    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(InsuranceType.empty().toObject())
            : this.fillData(data);
    }

    fillData(data) {
        this.id = data.id
        this.name = data.name;
        this.key = data.key;
        this.insuranceBuilders = data.insuranceBuilders.map((elem) => new InsuranceBuilder(elem));
        this.insuranceElements = data.insuranceElements.map((elem) => new InsuranceElement(elem));
    }

    static empty(data = {}) {
        return new InsuranceType({
            id: 0,
            name: data.name || '',
            key: data.key || '',
            insurance_id: data.insurance_id || 0,
            insuranceBuilders: data.insuranceBuilders ? data.insuranceBuilders : [],
            insuranceElements: data.insuranceElements ? data.insuranceElements : [],
        })
    }

    toObject() {
        return {
            ...this
        }
    }
}