export const comparePriceDataCondition = ({condition_value, context, data}) => {
    const priceDataKey = data.price_data_key;
    const conditionAction = data.condition_action
    const compareValue = context.rootGetters["insuranceModule/insurancePriceModule/getPriceDataByKey"](priceDataKey);

    switch (conditionAction) {
        case '>' : return +compareValue > +condition_value;
        case '<' : return +compareValue < +condition_value;
        case '>=' : return +compareValue >= +condition_value;
        case '<=' : return +compareValue <= +condition_value;
        case '=' : return condition_value === compareValue;
        default: return false;
    }
}