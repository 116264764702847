import {builderElementService} from "@/utils/services/builder-element-service";
import {fieldService} from "@/utils/services/field-service";
import {builderElementsEvents} from "@/utils/events/builder-elements-events";

export const repeatBySelectedCheckboxesCountAction = async ({action_value, passiveElement, activeElement, context, allBuilderElements, data}) => {
    const elementNames = data?.checkboxes?.map(itm => itm.name) || [];
    const builderElements = elementNames.length > 0 ?
        builderElementService.findAllElementsByParameters(allBuilderElements, 'name', elementNames) :
        [];

    const selectedCheckboxes = builderElements.filter(itm => fieldService.isFieldSelected(itm.value))

    if (!passiveElement.data.original_title) {
        passiveElement.data.original_title = activeElement.value
    }

    passiveElement.data.repeat_by_checkboxes = 1;
    passiveElement.data.repeat_by_checkboxes_count = selectedCheckboxes.length;
    passiveElement.data.repeat_by_checkbox_ids = selectedCheckboxes.map(itm => itm.id);
    passiveElement.data.repeat_by_checkbox_label_id = activeElement.id;
    passiveElement.data.max_selected_checkboxes_count = action_value;

    await context.dispatch('insuranceModule/builderElementsModule/saveElement', {
        builderElement: passiveElement
    }, {root: true});

    allBuilderElements = context.rootGetters["insuranceModule/builderElementsModule/getAllBuilderElements"];

    const repeatedElements = builderElementService.findAllElementsByDataParameters(allBuilderElements, 'is_repeated_by_checkboxes', ['1']);

    if ( repeatedElements.length < selectedCheckboxes.length - 1 ) {
        builderElementsEvents.repeatByCheckboxes.emit(passiveElement);
    }
}