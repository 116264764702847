<template>
  <div>

    <div>
      <p class="app-text__bold">Builder Name</p>

      <text-field
          name="name"
          v-model="additionalData.name"
      />
    </div>

    <div>
      <p class="app-text__bold">Priority</p>

      <number-field
          name="priority"
          v-model="additionalData.priority"
      />
    </div>

    <div>
      <p class="app-text__bold">Step Type</p>

      <select-field
          name="step_type"
          :items="insuranceStatuses"
          v-model="additionalData.step_type"
      />
    </div>

  </div>
</template>
<script>
import TextField from "@/ui/components/common/fields/TextField.vue";
import {Builder} from "@/models/builder.js";
import SelectField from "@/ui/components/common/fields/SelectField.vue";
import NumberField from "@/ui/components/common/fields/NumberField.vue";
import {insuranceStepService} from "@/utils/services/insurance-step-service.js";

export default {
  components: {NumberField, SelectField, TextField},
  props: {
    builder: {
      type: Builder,
      required: true,
    },
    additionalData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    insuranceStatuses() {
      return insuranceStepService.typesToArray();
    }
  },
}
</script>