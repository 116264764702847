import {serverApi} from "@/api/server/server-api.js";
import {InsuranceStatus} from "@/models/insurance-status.js";
import {InsurancePrice} from "@/models/insurance-price.js";
import {notificationService} from "@/utils/services/notification-service.js";
import {localStorageService} from "@/utils/services/local-storage-service.js";
import {PerPeriodPrice} from "@/models/per-period-price";

export const insurancePriceModule = {
    namespaced: true,

    state: {
        insurancePrice: InsurancePrice.createEmpty(),
        lists: {},
        perPeriodPrice: 0,
        totalPrice: 0,
        taxPrice: 0,
        finalPrice: 0,
        isBabyContract: false,
        insuranceFullName: '',
        insurancePrices: [],
        perPeriodPrices: [],
        additionalPriceItems: [],
    },

    getters: {
        getInsurancePrice: (state) => state.insurancePrice,
        getPriceListsByKey: (state) => (key) => state.lists[key],
        getPriceListsByKeyWithId: (state) => (id, key) => {
            const price = state.insurancePrices.find(itm => itm.id === id);

            if (price) {
                return price.lists[key]
            }

            return []
        },
        getPriceDataByKey: (state) => (key) => state.insurancePrice.data[key],
        getPerPeriodPrice: (state) => state.perPeriodPrice,
        getPerPeriodPriceByPriceId: (state) => (priceId) => {
            const index = state.perPeriodPrices.findIndex(itm => itm.getInsurancePriceId() === priceId);

            if (index !== -1) {
                return state.perPeriodPrices[index].getPerPeriodPrice()
            }

            return 0;
        },
        getTotalPrice: (state) => state.totalPrice,
        getTaxPrice: (state) => state.taxPrice,
        getFinalPrice: (state) => state.finalPrice,
        getIsBabyContract: (state) => state.isBabyContract,
        getInsuranceFullName: (state) => state.insuranceFullName,
        getInsurancePrices: (state) => state.insurancePrices,
        getPerPeriodPrices: (state) => state.perPeriodPrices,
        getInsurancePriceById: (state) => (priceId) => state.insurancePrices.find(itm => itm.id === priceId),
        getAdditionalPriceItems: (state) => state.additionalPriceItems,
    },

    mutations: {
        setInsurancePrice: (state, data) => {
            state.insurancePrice = new InsurancePrice(data)

            //state.discounts = data.discounts;
            state.lists = data.lists;
            state.insuranceFullName = data.name;

            localStorageService.insurancePrice.setIsNpopt(state.insurancePrice.is_npopt);
        },
        setInsurancePrices: (state, prices) => {
            let isPriceNpopt = false;

            state.insurancePrices = []
            prices.forEach((price) => {
                state.insurancePrices.push(new InsurancePrice(price));

                if (price.is_npopt) {
                    isPriceNpopt = true;
                }
            })

            if (isPriceNpopt) {
                state.insurancePrices.forEach((price) => {
                    price.is_npopt = true
                });
            }
        },
        setInsurancePriceById: (state, price) => {
            const priceIndex = state.insurancePrices.findIndex(priceItem => priceItem.id === price.id);

            if (priceIndex !== -1) {
                state.insurancePrices[priceIndex] = new InsurancePrice(price);
            }
        },
        setPerPeriodPriceByInsurancePrice: (state, {insurancePriceId, perPeriodPrice}) => {
            const index = state.perPeriodPrices.findIndex(item => item.getInsurancePriceId() === insurancePriceId);

            if (index !== -1) {
                state.perPeriodPrices[index].setPerPeriodPrice(perPeriodPrice);
            } else {
                state.perPeriodPrices.push(new PerPeriodPrice({
                    insurancePriceId,
                    perPeriodPrice
                }))
            }
        },
        setPerPeriodPrice: (state, price) => {
            state.perPeriodPrice = price
        },
        setTotalPrice: (state, price) => {
            state.totalPrice = price
        },
        setTaxPrice: (state, price) => {
            state.taxPrice = price
        },
        setFinalPrice: (state, price) => {
            state.finalPrice = price
        },
        setIsBabyContract: (state, isBabyContract) => {
            state.isBabyContract = isBabyContract
        },
        setInsuranceFullName: (state, name) => {
            state.insuranceFullName = name
        },
        setAdditionalPriceItems: (state, items) => {
            state.additionalPriceItems = items
        },
        clearPerPeriodPriceByInsurancePrice: (state) => {
            state.perPeriodPrices = []
        }
    },

    actions: {
        async checkEmptyPrice(context, {insuranceId}) {
            const {data} = await serverApi.insurancePrice.checkOnEmptyPrice(insuranceId);

            //change status if its EmptyPrice
            if(data.result) {
                context.commit(
                    'insuranceModule/setInsuranceStatus',
                    new InsuranceStatus(data.status),
                    {root: true}
                )
            }

            return data.result;
        },
        getInsurancePriceByInsuranceId: async (context, {insuranceId}) => {
            if (!insuranceId) {
                return
            }

            const {data} = await serverApi.insurancePrice.insurancePriceByInsuranceId(insuranceId);

            context.commit('setInsurancePrice', data.insurancePrice)
        },
        getInsurancePrice: async (context, {insurancePriceId}) => {
            if (!insurancePriceId) {
                return
            }

            const {data} = await serverApi.insurancePrice.insurancePriceById(insurancePriceId);

            context.commit('setInsurancePrice', data.insurancePrice)
        },
        getInsurancePrices: async (context, {insuranceId}) => {
            if (!insuranceId) {
                return
            }

            const {data} = await serverApi.insurancePrice.insurancePricesByInsuranceId(insuranceId);

            context.commit('setInsurancePrices', data.insurancePrices)
        },
        getInsurancePricesAndSetInsurancePrice: async (context, {insuranceId}) => {
            if (!insuranceId) {
                return
            }

            const {data} = await serverApi.insurancePrice.insurancePricesByInsuranceId(insuranceId);

            context.commit('setInsurancePrices', data.insurancePrices)
            context.commit('setInsurancePrice', data.insurancePrices[0])
        },
        calculatePrice: async (context, {insuranceId}) => {
            if (!insuranceId) {
                return
            }

            const {data} = await serverApi.insurancePrice.calculatePrice(insuranceId);

            if (data.success) {
                if (data.priceItems.length > 1) {
                    context.commit('clearPerPeriodPriceByInsurancePrice' );
                    data.priceItems.forEach(itm => {
                        context.commit('setPerPeriodPriceByInsurancePrice', {
                            insurancePriceId: itm.price.id,
                            perPeriodPrice: itm.perPeriodPrice
                        } );
                    })
                    context.commit('setInsurancePrices', data.priceItems.map(itm => itm.price));
                } else {
                    context.commit('setInsurancePrice', data.priceItems[0].price);
                    context.commit('setPerPeriodPrice', data.priceItems[0].perPeriodPrice)

                    context.commit('setIsBabyContract', data.isBabyContract)
                }

                context.commit('insuranceModule/insurancePriceAddonModule/setAddons', data.addons, {root: true})
                context.commit('insuranceModule/setIsPriceGot', true, {root: true})

                context.commit('setTotalPrice', data.totalPrice)
                context.commit('setTaxPrice', data.taxPrice)
                context.commit('setFinalPrice', data.finalPrice)

                context.commit('setAdditionalPriceItems', data.additionalPriceItems)

                console.log('request data:', data)

                return
            } else {
                context.commit('setPerPeriodPrice', 0)
                context.commit('setTotalPrice', 0)
                context.commit('setTaxPrice', 0)
                context.commit('setFinalPrice', 0)
                context.commit('insuranceModule/insurancePriceAddonModule/setAddons', [], {root: true})
            }

            notificationService.error(data.message)
        },
        saveDiscount: async (context, {discount, insurancePriceId}) => {
            const insurancePrice = insurancePriceId ?
                context.getters.getInsurancePrices.find(itm => itm.id === insurancePriceId) :
                context.getters.getInsurancePrice;

            insurancePrice.data['surcharge'] = 0
            await context.dispatch('updatePrice', {
                insurancePriceId: insurancePrice.id,
                requestData: {
                    data: insurancePrice.data,
                    discount: discount
                }
            });
        },
        saveSurcharge: async (context, {surcharge, insurancePriceId}) => {
            const insurancePrice = insurancePriceId ?
              context.getters.getInsurancePrices.find(itm => itm.id === insurancePriceId) :
              context.getters.getInsurancePrice;

            insurancePrice.data['surcharge'] = surcharge

            await context.dispatch('updatePrice', {
                insurancePriceId: insurancePrice.id,
                requestData: {
                    data: insurancePrice.data,
                    discount: 0
                }
            });
        },
        updatePrice: async (context, {insurancePriceId, requestData, changedElement}) => {
            const sentData = {
                ...requestData,
                changedElement
            };
            const {data} = await serverApi.insurancePrice.update(insurancePriceId, sentData);

            if (data.builderElementIdsToHide) {
                await context.dispatch('insuranceModule/builderElementsModule/setElementIdsToHide',
                    {ids: data.builderElementIdsToHide},
                    {root: true}
                );
            }

            context.commit('setInsurancePrice', data.insurancePrice)
        },
        updatePrices: async (context, {insuranceId, requestData}) => {
            const {data} = await serverApi.insurancePrice.updatePrices(insuranceId, requestData);

            context.commit('setInsurancePrices', data.insurancePrices)
        },
        setIsNpopt: (context, {isNpopt}) => {
            localStorageService.insurancePrice.setIsNpopt(isNpopt);
        },
        determineInsurancePriceView: async (context, {insuranceId}) => {
            const {data} = await serverApi.insurancePrice.determineInsurancePriceView(insuranceId);

            if (data.success) {
                context.commit('setInsurancePrice', data.insurancePrice)
            }

            return data.success
        },

        getBuilderElementsForNpopt: async (context, {insuranceId}) => {
            const {data} = await serverApi.insurancePrice.builderElementsForNpopt(insuranceId);

            await context.dispatch('insuranceModule/builderElementsModule/setElementIdsToHide',
                {ids: data.builderElementIdsToHide},
                {root: true}
            );

            await context.dispatch('insuranceModule/builderElementsModule/setElementIdsToShow',
                {ids: data.builderElementIdsToShow},
                {root: true}
            );

            return data.success
        },

        sendEmptyPriceEmail: async (context, {insuranceId}) => {
            const {data} = await serverApi.insurancePrice.sendEmptyPriceEmail(insuranceId);

            return data.success ? true : notificationService.error(data.message)
        },
        sendNpoptEmail: async (context, {insuranceId}) => {
            const {data} = await serverApi.insurancePrice.sendNpoptEmail(insuranceId);

            return data.success ? true : notificationService.error(data.message)
        },
        configureEmptyPrice: async (context, {insuranceId}) => {
            await serverApi.insurancePrice.configureEmptyPrice(insuranceId);
        },
        getDocumentApplications: async (context, {insuranceTypeId}) => {
            const {data} = await serverApi.insurancePrice.documentApplications(insuranceTypeId);

            return data.success ? data.documents : []
        },
    }
}