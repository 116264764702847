import {BuilderElementRelation} from "@/models/builder-element-relations/builder-element-relation.js";
import {builderElementService} from "@/utils/services/builder-element-service.js";

export class Builder {
    constructor(data) {
        this.fillData(data)
    }

    set elements(value) {
        this._elements = value;
    }

    get elements() {
        return this._elements;
    }

    isEmpty() {
        return !this.id;
    }

    static empty(data = {}) {
        return new Builder({
            id: data.id || 0,
            name: data.name || '',
            key: data.key || '',
            elements: data.elements || [],
            relations: data.relations || [],
        })
    }

    fillData(data) {
        this.id = data.id
        this.name = data.name;
        this.key = data.key;
        this.elements = data.elements ? builderElementService.elementsToBuilderElements(data.elements) : [];
        this.relations = data.relations ? data.relations.map((item) => new BuilderElementRelation(item)) : [];
    }

    toObject() {
        return {
            ...this,
            elements: this.elements
        }
    }
}