import {serverApi} from "@/api/server/server-api.js";
import {BuilderElement} from "@/models/builder-element.js";

export const jsonBuilderEditorModule = {
    namespaced: true,

    actions: {
        async getElement(context, {id}) {
            const {data} = await serverApi.admin.jsonBuilderEditor.getJsonElement(id)

            return data.element ? new BuilderElement(data.element) : null
        },

        async createElement(context, {builderId, data}) {
            const response = await serverApi.admin.jsonBuilderEditor.createJsonElement(builderId, data)

            return new BuilderElement(response.data.element);
        },

        async updateElement(context, {builderElementId, data}) {
            const response = await serverApi.admin.jsonBuilderEditor.updateJsonElement(builderElementId, data)

            return new BuilderElement(response.data.element);
        },

        async deleteElement(context, {builderElementId}) {
            const {data} = await serverApi.admin.jsonBuilderEditor.deleteJsonElement(builderElementId);

            return data.success;
        }
    }

}