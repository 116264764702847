<template>
  <div>
    <div class="text-center">
      <h3>Money Field</h3>
    </div>

    <v-row>
      <v-col cols="12">
        <name-editor
            v-model="builderElement.name"
        />
      </v-col>

      <v-col cols="12" class="mt-2">
        <disabled-editor
            v-model="builderElement.data.disabled"
        />
      </v-col>

      <v-col cols="6">
        <priority-editor
            v-model.number="builderElement.priority"
        />
      </v-col>

      <v-col class="pl-4" cols="6">
        <width-editor
            v-model="builderElement.data.width"
        />
      </v-col>

      <v-col cols="6">
        <p class="app-text__bold">Default Value</p>
        <number-field
            name="value"
            v-model="builderElement.value"
        />
      </v-col>

      <v-col cols="6" class="pl-4">
        <placeholder-editor
          v-model="builderElement.data.placeholder"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="pl-4">
        <is-required
            v-model="builderElement.validation.required"
        />
      </v-col>
      <v-col cols="8" class="pl-4">
        <validation-message-editor
            v-model="builderElement.validation.message"
        />
      </v-col>
    </v-row>

    <margin-editor
        v-model="builderElement.data.margin"
    />

  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import WidthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/WidthEditor.vue";
import PlaceholderEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PlaceholderEditor.vue";
import MarginEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MarginEditor.vue";
import NumberField from "@/ui/components/common/fields/NumberField.vue";
import DisabledEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/DisabledEditor";
import IsRequired from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/isRequired.vue";
import ValidationMessageEditor
  from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/ValidationMessageEditor.vue";

export default {
  components: {
    ValidationMessageEditor,
    IsRequired,
    NumberField, MarginEditor, PlaceholderEditor, WidthEditor, PriorityEditor, NameEditor, DisabledEditor},
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  }
}
</script>