import {AppFile} from '@/models/app-file'

export class InsurancePolice {
    constructor({id, data, lists, files}) {
        this.id = id;
        this.data = data;
        this.lists = lists;
        this.files = files.map((file) => new AppFile(file));
    }

    static createEmpty() {
        return new InsurancePolice({
            lists: {
                sendDocPolice: {},
                sendDocInvoice: {},
                sendDocInsuranceConfirmation: {},
            },
            data: {
                managerName: '',
                managerEmail: '',
                customerEmail: '',
                phoneSaleDate: '',
                sendDocPolice: '',
                consultantDate: '',
                consultantName: '',
                managerNumber1: '',
                sendDocInvoice: '',
                infoPackageEmail: '',
                phoneSaleLastName: '',
                phoneSaleFirstName: '',
                foreignOrderFeature: '',
                isDeliveryAgreement: '',
                wasSaleTargetMarket: '',
                wasSaleTargetMarketComment: '',
                sendDocInsuranceConfirmation: '',
                electronicSignatureSubmittedAt: '',
                notes: '',
            },
            files: [],
        })
    }
}