<template>
  <div>
    <div v-if="isNpopt" class="app-bg-white px-7 py-5">
      <div class="app-text__bold">Herzlichen Dank für Ihre Eingaben!</div>
      <div class="app-text__bold orange--text pt-3">Der Vorgang erfordert eine individuelle Risikoprüfung durch Underwriting.</div>
      <div class="app-text__bold pt-3">Bitte treffen Sie ihre Auswahl und klicken auf „Speichern und weiter“, sofern sich unsere<br> Kolleg:innen mit Ihnen in Verbindung setzen und ein Angebot erstellen sollen.</div>
    </div>

    <div class="app-bg-white px-7 mt-5">
      <div>
        <v-row class="pt-3">
          <v-col cols="2" class="pt-2">Versicherungssumme</v-col>
          <v-col cols="4" class="pl-16">
            <select-field
                :is-disabled="isDisableAllInputs"
                name="sumInsured"
                :items="sumInsuredList"
                :value="selectedSumInsured"
                @saveField="changeSumInsured"
            />
          </v-col>
        </v-row>
      </div>
      <div>
        <v-row class="pt-3">
          <v-col cols="2" class="pt-2">Generelle Laufzeit</v-col>
          <v-col cols="4" class="pl-16">
            <select-field
                :is-disabled="isDisableAllInputs"
                name="duration"
                :items="durations"
                :value="selectedDuration"
                @saveField="changeDuration"
          />
          </v-col>
        </v-row>
      </div>
      <div>
        <v-row class="py-3">
          <v-col cols="2" class="pt-2">Generelle Zahlungsweise</v-col>
          <v-col cols="4" class="pl-16">
            <select-field
                :is-disabled="isDisableAllInputs"
                name="paymentPeriod"
                :items="periodList"
                :value="selectedPeriod"
                @saveField="changePaymentPeriod"
            />
          </v-col>
          <v-col cols="1" class="d-flex flex-column justify-center ml-2">
            <div>
              <question-tooltip v-if="tooltip" color="white">
                <div>
                  {{ tooltip }}
                </div>
              </question-tooltip>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="app-bg-white mt-5 px-7 pt-4" v-if="!isNpopt">
      <div>
        <v-row class="pt-3" id="packs">
          <v-col cols="3" class="pt-5 py-3 pl-2">
            <span class="app-text__bold">Bitte wählen Sie</span>
          </v-col>
          <v-col cols="2" v-for="(pack, index) in packages" :key="index" class="custom-col">
            <div class="app-left-border pt-0">
              <package
                  :pack="pack"
                  :period="capitalizeFirstLetter(selectedPeriod)"
                  :is-selected="checkSelectedPackage(pack.key)"
                  :is-npopt="isNpopt"
                  :disabled="pack.disabled || isDisableAllInputs"
                  @selectPackage="selectPackage"
              />
            </div>
          </v-col>
        </v-row>
        <div>
          <v-row class="mt-0" >
            <v-col cols="3" class="app-top-border pr-10 py-3 pl-2 app-bg-azure">
              <div class="flex_space_between">
                <span>Folgende Leistungen sind in allen Angeboten enthalten.</span>
                <div>
                  <question-tooltip color="white">
                    <div>
                      <b>Leitungs- und Kontrollorgane :</b><br>
                      Leitungs- und Kontrollorgane sind z.B. Vorstand, Geschäftsführung, Aufsichts-, Verwaltungs-, und Beirat der versicherten Gesellschaften inkl. deren Vertreter und faktische Mitglieder.
                      <br>
                      <br>

                      <b>Leitende Angestellte, Prokuristen, angestellte Sonderfunktionsinhaber:</b><br>
                      Sonderfunktionsinhaber sind z.B. Compliance Beauftragte (Compliance Officer), Datenschutz-, Geldwäsche-, Arbeitsschutz-, Sicherheits- oder Zollbeauftragte.
                      <br>
                      <br>

                      <b>Tochter-/Enkelgesellschaften mit Sitz innerhalb der EU:</b><br>
                      Eine Tochtergesellschaft ist ein Unternehmen, das sich entweder vollständig im Besitz eines anderen Unternehmens befindet oder teilweise von einem anderen Unternehmen, der Muttergesellschaft, kontrolliert wird.
                      Eine Enkelgesellschaft ist ein Unternehmen, zu dem eine Tochtergesellschaft in dem beschriebenen Verhältnis steht.
                      <br>
                      <br>

                      <b>Operative Tätigkeit der versicherten Personen:</b><br>
                      Mitversichert sind auch Haftpflichtansprüche gegenüber den versicherten Personen im Zusammenhang mit Dienstleistungen gegenüber Dritten. Bitte Dienstleistungs-Ausschluss für bestimmte Branchen beachten, z.B. Finanzdienstleister, Architekten/Ingenieure, IT-Dienstleister.
                      <br>
                      <br>

                      <b>Abwehr unberechtigter Ansprüche:</b><br>
                      Der Versicherungsschutz umfasst die Prüfung der Haftpflichtfrage, die Abwehr unberechtigter Schadenersatzansprüche, sowie die Übernahme von berechtigten Schadenersatzverpflichtungen.
                      <br>
                      <br>

                      <b>Ansprüche wegen Zahlungen nach Insolvenzreife (§ 15b Abs. 4 InsO):</b><br>
                      Der Versicherungsschutz umfasst auch Ansprüche wegen Zahlungen bei Zahlungsunfähigkeit oder Überschuldung.
                      <br>
                      <br>

                      <b>Rückwärtsdeckung:</b><br>
                      Der Versicherungsschutz umfasst auch vor Vertragsbeginn begangene Pflichtverletzungen, welche in der Vertragslaufzeit oder innerhalb der vereinbarten Nachmeldefrist gemeldet werden können.
                      Dies gilt nur, wenn die Pflichtverletzung der betroffenen Person oder der versicherten Gesellschaft bei Vertragsschluss bzw. Vertragsanpassung nicht bekannt war. Einschränkungen gelten bei Start-Ups (Unternehmen, deren Gründung noch nicht länger als drei Jahre zurückliegt) gemäß der Besonderen Bedingungen VH 4245.
                      <br>
                      <br>

                      <b>AGG-Baustein (Sublimit 100.000 EUR):</b><br>
                      Dieser Baustein schützt die versicherten Personen sowie die versicherten Gesellschaften bei Ansprüchen Dritter wegen des Vorwurfs der Diskriminierung nach dem AGG (Allgemeines Gleichbehandlungsgesetz).
                      <br>
                    </div>
                  </question-tooltip>
                </div>
              </div>
            </v-col>
            <v-col cols="9" class="pt-3 pb-3 app-left-border app-top-border app-right-border app-bg-azure">
              <div class="ml-10">
                <div><check-icon size="18"/>Leitungs- und Kontrollorgane</div>
                <div><check-icon size="18"/>Leitende Angestellte, Prokuristen, angestellte Sonderfunktionsinhaber</div>
                <div><check-icon size="18"/>Tochter- /Enkelgesellschaften mit Sitz innerhalb der EU</div>
                <div v-if="operationalActivitiesPersons"><check-icon size="18"/>Operative Tätigkeit der versicherten Personen</div>
                <div><check-icon size="18"/>Abwehr unberechtigter Ansprüche</div>
                <div><check-icon size="18"/>Freistellung von berechtigten Ansprüchen</div>
                <div><check-icon size="18"/>Ansprüche wegen Zahlungen nach Insolvenzreife (§ 15 b Abs. 4 InsO)</div>
                <div v-if="showReverseCoverage"><check-icon size="18"/>Rückwärtsdeckung</div>
                <div><check-icon size="18"/>AGG-Baustein (Sublimit 100.000 EUR)</div>
              </div>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="3" class="app-top-border pr-10 py-6 pl-2 app-bg-azure">
              <div class="flex_space_between">
                <span>Jahreshöchstleistung</span>
                <div>
                  <question-tooltip color="white">
                    <div>
                      <b>Jahreshöchstleistung:</b><br>
                      Die im Versicherungsschein bezifferte Versicherungssumme stellt die Höchstleistung des Versicherers pro Versicherungsfall und für alle Versicherungsfälle innerhalb eines Versicherungsjahres insgesamt dar.
                    </div>
                  </question-tooltip>
                </div>
              </div>
            </v-col>
            <v-col cols="auto" class="pt-2 app-left-border app-top-border custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}"
            >
              <div class="text-center py-5">1-fach</div>
            </v-col>
            <v-col cols="auto" class="pt-2 app-left-border app-top-border custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
            >
              <div class="px-3 py-2">
                <select-field
                    :is-disabled="isDisableAllInputs"
                    :name="insurancePricePackagesEnum.smartCorporateGovernance"
                    :items="getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.annualPeakPerformance, insurancePricePackagesEnum.smartCorporateGovernance).lists"
                    :value="getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.annualPeakPerformance, insurancePricePackagesEnum.smartCorporateGovernance).value"
                    @saveField="updateAnnualPeakPerformance"
                />
              </div>
            </v-col>
            <v-col cols="auto" class="pt-2 app-left-border app-top-border custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
            >
              <div class="px-3 py-2">
                <select-field
                    :is-disabled="isDisableAllInputs"
                    :name="insurancePricePackagesEnum.comfortCorporateGovernance"
                    :items="getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.annualPeakPerformance, insurancePricePackagesEnum.comfortCorporateGovernance).lists"
                    :value="getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.annualPeakPerformance, insurancePricePackagesEnum.comfortCorporateGovernance).value"
                    @saveField="updateAnnualPeakPerformance"
                />
              </div>
            </v-col>
            <v-col cols="auto" class="pt-2 app-left-border app-right-border app-top-border custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
            >
              <div class="px-3 py-2">
                <select-field
                    :is-disabled="isDisableAllInputs"
                    :name="insurancePricePackagesEnum.premiumCorporateGovernance"
                    :items="getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.annualPeakPerformance, insurancePricePackagesEnum.premiumCorporateGovernance).lists"
                    :value="getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.annualPeakPerformance, insurancePricePackagesEnum.premiumCorporateGovernance).value"
                    @saveField="updateAnnualPeakPerformance"
                />
              </div>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="3" class="app-top-border pr-10 py-6 pl-2 app-bg-azure">
              <div class="flex_space_between">
                <span>Selbstbehalt</span>
                <div>
                  <question-tooltip color="white">
                    <div>
                      <b>Selbstbehalt:</b><br>
                      Der Selbstbehalt definiert den Betrag, bis zu dem eine versicherte Person selbst für einen Schaden aufkommen muss.
                    </div>
                  </question-tooltip>
                </div>
              </div>
            </v-col>
            <v-col  class="pt-2 app-left-border app-top-border custom-col"
                    :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}"
            >
              <div class="text-center py-5">
                {{ getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.deductible, insurancePricePackagesEnum.basicCorporateGovernance).value | toMoneyFormat }}
              </div>
            </v-col>
            <v-col class="pt-2 app-left-border app-top-border custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
            >
              <div class="text-center py-5">
                {{ getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.deductible, insurancePricePackagesEnum.smartCorporateGovernance).value | toMoneyFormat }}
              </div>
            </v-col>
            <v-col class="pt-2 app-left-border app-top-border custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
            >
              <div class="text-center py-5">
                {{ getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.deductible, insurancePricePackagesEnum.comfortCorporateGovernance).value | toMoneyFormat }}
              </div>
            </v-col>
            <v-col class="pt-2 app-left-border app-right-border app-top-border custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
            >
              <div class="text-center py-5">
                {{ getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.deductible, insurancePricePackagesEnum.premiumCorporateGovernance).value | toMoneyFormat }}
              </div>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="3" class="app-top-border pr-10 py-6 pl-2 app-bg-azure">
              <div class="flex_space_between">
                <span>Nachmeldefrist</span>
                <div>
                  <question-tooltip color="white">
                    <div>
                      <p>Mitversichert sind Ansprüche, die nach Ende der Vertragslaufzeit erhoben werden. Die Pflichtverletzung muss während der Vertragslaufzeit oder im Zeitraum der Rückwärtsdeckung begangen worden sein.</p>
                      <p v-if="showReverseCoverage">Für Start-Up-Unternehmen gilt eine feste Nachmeldefrist von 36 Monaten.</p>
                    </div>
                  </question-tooltip>
                </div>
              </div>
            </v-col>
            <v-col class="pt-2 app-left-border app-top-border custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}"
            >
              <div class="text-center py-5">{{ getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.lateRegistrationPeriod, insurancePricePackagesEnum.basicCorporateGovernance).value }}</div>
            </v-col>
            <v-col class="pt-2 app-left-border app-top-border custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
            >
              <div class="px-3 py-2">
                <select-field
                    :is-disabled="isDisableAllInputs"
                    :name="insurancePricePackagesEnum.smartCorporateGovernance"
                    :items="getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.lateRegistrationPeriod, insurancePricePackagesEnum.smartCorporateGovernance).lists"
                    :value="getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.lateRegistrationPeriod, insurancePricePackagesEnum.smartCorporateGovernance).value"
                    @saveField="updateLateRegistrationPeriod"
                />
              </div>
            </v-col>
            <v-col class="pt-2 app-left-border app-top-border custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
            >
              <div class="px-3 py-2">
                <select-field
                    :is-disabled="isDisableAllInputs"
                    :name="insurancePricePackagesEnum.comfortCorporateGovernance"
                    :items="getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.lateRegistrationPeriod, insurancePricePackagesEnum.comfortCorporateGovernance).lists"
                    :value="getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.lateRegistrationPeriod, insurancePricePackagesEnum.comfortCorporateGovernance).value"
                    @saveField="updateLateRegistrationPeriod"
                />
              </div>
            </v-col>
            <v-col class="pt-2 app-left-border app-right-border app-top-border custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
            >
              <div class="px-3 py-2">
                <select-field
                    :is-disabled="isDisableAllInputs"
                    :name="insurancePricePackagesEnum.premiumCorporateGovernance"
                    :items="getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.lateRegistrationPeriod, insurancePricePackagesEnum.premiumCorporateGovernance).lists"
                    :value="getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.lateRegistrationPeriod, insurancePricePackagesEnum.premiumCorporateGovernance).value"
                    @saveField="updateLateRegistrationPeriod"
                />
              </div>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="3" class="app-top-border pr-10 py-3 pl-2 app-bg-azure">
              <div class="flex_space_between">
                <span>Kein Wegfall der Nachmeldefrist (Unverfallbarkeit)</span>
                <div>
                  <question-tooltip color="white">
                    <div>
                      <b>Kein Wegfall der Nachmeldefrist (Unverfallbarkeit):</b><br>
                      Die Nachmeldefrist endet spätestens zu dem Zeitpunkt, ab dem anderweitiger Versicherungsschutz besteht. Dies gilt jedoch nicht für den vereinbarten Zeitraum.
                    </div>
                  </question-tooltip>
                </div>
              </div>
            </v-col>
            <v-col class="pt-1 app-left-border app-top-border custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}"
            >
              <div class="text-center py-5">{{ getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.noOmissionLateRegistrationPeriod, insurancePricePackagesEnum.basicCorporateGovernance).value }}</div>
            </v-col>
            <v-col class="pt-1 app-left-border app-top-border custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
            >
              <div class="text-center py-5">{{ getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.noOmissionLateRegistrationPeriod, insurancePricePackagesEnum.smartCorporateGovernance).value }}</div>
            </v-col>
            <v-col class="pt-1 app-left-border app-top-border custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
            >
              <div class="text-center py-5">{{ getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.noOmissionLateRegistrationPeriod, insurancePricePackagesEnum.comfortCorporateGovernance).value }}</div>
            </v-col>
            <v-col class="pt-1 app-left-border app-right-border app-top-border custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
            >
              <div class="text-center py-5">{{ getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.noOmissionLateRegistrationPeriod, insurancePricePackagesEnum.premiumCorporateGovernance).value }}</div>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="3" class="app-top-border pr-10 py-6 pl-2 app-bg-azure">
              <div class="flex_space_between">
                <span>Abwehrkostenzusatzlimit</span>
                <div>
                  <question-tooltip color="white">
                    <div>
                      Die zusätzliche Versicherungssumme wird einmalig je Versicherungsjahr für Kosten gewährt, wenn die vereinbarte Versicherungssumme des Versicherungsvertrages sowie aller sich daran anschließenden Excedentenverträge durch Zahlungen vollständig verbraucht wurde.
                    </div>
                  </question-tooltip>
                </div>
              </div>
            </v-col>
            <v-col class="app-left-border app-top-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}"
            >
              <div class="text-center py-5"><close-box-icon/></div>
            </v-col>
            <v-col class="pt-2 app-left-border app-top-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
            >
              <div class="px-3 py-2">
                <select-field
                    :is-disabled="isDisableAllInputs"
                    :name="insurancePricePackagesEnum.smartCorporateGovernance"
                    :items="getItemsForAdditionalCostLimit(pricePackageAdditionalDataEnum.additionalDefenseCostLimit, insurancePricePackagesEnum.smartCorporateGovernance)"
                    :value="getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.additionalDefenseCostLimit, insurancePricePackagesEnum.smartCorporateGovernance).value"
                    @saveField="updateAdditionalDefenseCostLimit"
                />
              </div>
            </v-col>
            <v-col class="pt-2 app-left-border app-top-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
            >
              <div class="px-3 py-2">
                <select-field
                    :is-disabled="isDisableAllInputs"
                    :name="insurancePricePackagesEnum.comfortCorporateGovernance"
                    :items="getItemsForAdditionalCostLimit(pricePackageAdditionalDataEnum.additionalDefenseCostLimit, insurancePricePackagesEnum.comfortCorporateGovernance)"
                    :value="getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.additionalDefenseCostLimit, insurancePricePackagesEnum.comfortCorporateGovernance).value"
                    @saveField="updateAdditionalDefenseCostLimit"
                />
              </div>
            </v-col>
            <v-col class="pt-2 app-left-border app-right-border app-top-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
            >
              <div class="px-3 py-2">
                <select-field
                    :is-disabled="isDisableAllInputs"
                    :name="insurancePricePackagesEnum.premiumCorporateGovernance"
                    :items="getItemsForAdditionalCostLimit(pricePackageAdditionalDataEnum.additionalDefenseCostLimit, insurancePricePackagesEnum.premiumCorporateGovernance)"
                    :value="getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.additionalDefenseCostLimit, insurancePricePackagesEnum.premiumCorporateGovernance).value"
                    @saveField="updateAdditionalDefenseCostLimit"
                />
              </div>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="3" class="app-top-border pr-10 py-6 pl-2 app-bg-azure">
              <div class="flex_space_between">
                <span>Außergerichtliche Kosten</span>
                <div>
                  <question-tooltip color="white">
                    <div>
                      Mitversichert ist das Recht der versicherten Person, noch vor Anhängigkeit eines Haftpflichtprozesses einen Rechtsanwalt zur Wahrnehmung ihrer Interessen zu beauftragen.
                    </div>
                  </question-tooltip>
                </div>
              </div>
            </v-col>
            <v-col class="app-left-border app-top-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}"
            >
              <div class="text-center py-4"><close-box-icon/></div>
            </v-col>
            <v-col class="pt-2 app-left-border app-top-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
            >
              <div class="text-center py-4">{{ getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.extrajudicialCosts, insurancePricePackagesEnum.smartCorporateGovernance).value | toMoneyFormat}}</div>
            </v-col>
            <v-col class="pt-2 app-left-border app-top-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
            >
              <div class="text-center py-4">{{ getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.extrajudicialCosts, insurancePricePackagesEnum.comfortCorporateGovernance).value | toMoneyFormat}}</div>
            </v-col>
            <v-col class="pt-2 app-left-border app-right-border app-top-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
            >
              <div class="text-center py-4">{{ getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.extrajudicialCosts, insurancePricePackagesEnum.premiumCorporateGovernance).value | toMoneyFormat }} </div>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="3" class="app-top-border pr-10 py-3 pl-2 app-bg-azure">
              <div class="flex_space_between">
                <span>Gehaltsfortzahlung im Falle der Aufrechnung</span>
                <div>
                  <question-tooltip color="white">
                    <div>
                      <b>Gehaltsfortzahlung im Falle der Aufrechnung:</b><br>
                      Wird das Gehalt einer versicherten Person vom Unternehmen einbehalten, übernimmt der Versicherer die monatlichen Zahlungen in der vereinbarten Höhe für den vereinbarten Zeitraum. Das Sublimit gilt für alle versicherten Personen gemeinsam.
                    </div>
                  </question-tooltip>
                </div>
              </div>
            </v-col>
            <v-col class="app-left-border app-top-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}"
            >
              <div class="text-center py-5"><close-box-icon/></div>
            </v-col>
            <v-col class="pt-2 app-left-border app-top-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
            >
              <div class="text-center py-5">50% des Gehaltes der vP</div>
            </v-col>
            <v-col class="pt-2 app-left-border app-top-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
            >
              <div class="text-center py-5">50% des Gehaltes der vP</div>
            </v-col>
            <v-col class="pt-2 app-left-border app-right-border app-top-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
            >
              <div class="text-center py-5">50% des Gehaltes der vP</div>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="3" class="pr-10 py-6 pl-2 app-bg-azure">
              <span>Dauer</span>
            </v-col>
            <v-col class="app-left-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}"
            >
              <div class="text-center py-4">
                <close-box-icon/>
              </div>
            </v-col>
            <v-col class="pt-2 app-left-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
            >
              <div class="text-center py-4">6 Monate</div>
            </v-col>
            <v-col class="pt-2 app-left-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
            >
              <div class="text-center py-4">12 Monate</div>
            </v-col>
            <v-col class="pt-2 app-left-border app-right-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
            >
              <div class="text-center py-4">18 Monate</div>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="3" class="pr-10 py-6 pl-2 app-bg-azure">
              <span>Sublimit für alle versicherten Personen (vP) gemeinsam</span>
            </v-col>
            <v-col class="app-left-border height-100 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}"
            >
              <div class="text-center py-5"><close-box-icon/></div>
            </v-col>
            <v-col class="pt-2 app-left-border height-100 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
            >
              <div class="text-center py-5" v-html="getSublimitAllInsurances(250000)"></div>
            </v-col>
            <v-col class="pt-2 app-left-border height-100 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
            >
              <div class="text-center py-5" v-html="getSublimitAllInsurances(500000)"></div>
            </v-col>
            <v-col class="pt-2 app-left-border app-right-border height-100 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
            >
              <div class="text-center py-5" v-html="getSublimitAllInsurances(1000000, '1 Mio. EUR')"></div>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="3" class="app-top-border pr-10 py-6 pl-2 app-bg-azure">
              <div class="flex_space_between">
                <span>Mediation</span>
                <div>
                  <question-tooltip color="white">
                    <div>
                      Mitversichert ist die Kostenübernahme für einen Mediator, der zwischen zwei oder mehr Parteien vermittelt und mit Ihnen eine interessengerechte Lösung erarbeitet.
                    </div>
                  </question-tooltip>
                </div>
              </div>
            </v-col>
            <v-col class="app-left-border app-top-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}"
            >
              <div class="text-center py-4"><close-box-icon/></div>
            </v-col>
            <v-col class="app-left-border app-top-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
            >
              <div class="text-center py-4"><close-box-icon/></div>
            </v-col>
            <v-col class="pt-2 app-left-border app-top-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
            >
              <div class="text-center py-4">150.000 EUR</div>
            </v-col>
            <v-col class="pt-2 app-left-border app-right-border app-top-border height-70 custom-col"
                   :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
            >
              <div class="text-center py-4">200.000 EUR</div>
            </v-col>
          </v-row>

          <div class="pb-7">
            <toggle-block
              header="Weitere Leistungen"
              headerCssClasses="py-2 app-bg-azure"

          >
              <v-row class="mt-0">
                <v-col cols="3" class="app-top-border pr-10 py-2 pl-2 app-bg-azure">
                  <div class="flex_space_between">
                    <span>Fremdmandate (Outside Directorship Liability)</span>
                    <div>
                      <question-tooltip color="white">
                        <div>
                          <b>Fremdmandate (Outside Directorship Liability):</b><br>
                          Mitversichert ist die Wahrnehmung von Organtätigkeiten (z.B. Aufsichtsrats- oder Beiratsmandaten) durch versicherte Personen in anderen Unternehmen auf Weisung oder im Interesse der Versicherungsnehmerin.
                        </div>
                      </question-tooltip>
                    </div>
                  </div>
                </v-col>
                <v-col class="app-left-border app-top-border height-65 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}"
                >
                  <div class="text-center py-3"><close-box-icon/></div>
                </v-col>
                <v-col class="pt-2 app-left-border app-top-border height-65 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
                >
                  <div class="text-center py-3">{{ getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.outsideDirectorateLiability, insurancePricePackagesEnum.smartCorporateGovernance).value | toMoneyFormat}}</div>
                </v-col>
                <v-col class="pt-2 app-left-border app-top-border height-65 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
                >
                  <div class="text-center py-3">{{ getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.outsideDirectorateLiability, insurancePricePackagesEnum.comfortCorporateGovernance).value | toMoneyFormat}}</div>
                </v-col>
                <v-col class="pt-2 app-left-border app-right-border app-top-border height-65 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
                >
                  <div class="text-center py-3">{{ getPackItemsByKeyItemAndPackKey(pricePackageAdditionalDataEnum.outsideDirectorateLiability, insurancePricePackagesEnum.premiumCorporateGovernance).value | toMoneyFormat}}</div>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="3" class="app-top-border pr-10 py-6 pl-2 app-bg-azure">
                  <div class="flex_space_between">
                    <span>Strafverteidigungskosten</span>
                    <div>
                      <question-tooltip color="white">
                        <div>
                          Mitversichert sind Verteidigungskosten in Straf- oder Ordnungswidrigkeitenverfahren.
                        </div>
                      </question-tooltip>
                    </div>
                  </div>
                </v-col>
                <v-col class="app-left-border app-top-border height-100 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}"
                >
                  <div class="text-center py-5"><close-box-icon/></div>
                </v-col>
                <v-col class="pt-2 app-left-border app-top-border height-100 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
                >
                  <div class="text-center py-5" v-html="getCriminalDefenseCosts(250000)"></div>
                </v-col>
                <v-col class="pt-2 app-left-border app-top-border height-100 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
                >
                  <div class="text-center py-5" v-html="getCriminalDefenseCosts(500000)"></div>
                </v-col>
                <v-col class="pt-2 app-left-border app-top-border app-right-border height-100 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
                >
                  <div class="text-center py-5" v-html="getCriminalDefenseCosts(1000000)"></div>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="3" class="app-top-border pr-10 py-3 pl-2 app-bg-azure">
                  <div class="flex_space_between">
                    <span>Reputationsschäden/<br> PR-Beratung</span>
                    <div>
                      <question-tooltip color="white">
                        <div>
                          <b>Reputationsschäden / PR-Beratung:</b>
                          Mitversichert sind die Kosten für einen PR-Berater, wenn der "gute Ruf“ einer versicherten Person durch einen Versicherungsfall in Gefahr gerät und dieser Person ein Reputationsschaden droht.
                        </div>
                      </question-tooltip>
                    </div>
                  </div>
                </v-col>
                <v-col class="app-left-border app-top-border height-70 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}"
                >
                  <div class="text-center py-4"><close-box-icon/></div>
                </v-col>
                <v-col class="pt-2 app-left-border app-top-border height-70 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
                >
                  <div class="text-center py-4">50.000 EUR</div>
                </v-col>
                <v-col class="pt-2 app-left-border app-top-border height-70 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
                >
                  <div class="text-center py-4">75.000 EUR</div>
                </v-col>
                <v-col class="pt-2 app-left-border app-right-border app-top-border height-70 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
                >
                  <div class="text-center py-4">100.000 EUR</div>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="3" class="app-top-border pr-10 py-6 pl-2 app-bg-azure">
                  <div class="flex_space_between">
                    <span>Psychologische Betreuung</span>
                    <div>
                      <question-tooltip color="white">
                        <div>
                          <b>Psychologische Betreuung:</b><br>
                          Mitversichert sind Kosten für die psychologische Betreuung von versicherten Personen.
                        </div>
                      </question-tooltip>
                    </div>
                  </div>
                </v-col>
                <v-col class="app-left-border app-top-border height-70 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}"
                >
                  <div class="text-center py-5"><close-box-icon/></div>
                </v-col>
                <v-col class="pt-2 app-left-border app-top-border height-70 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
                >
                  <div class="text-center py-5">10.000 EUR</div>
                </v-col>
                <v-col class="pt-2 app-left-border app-top-border height-70 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
                >
                  <div class="text-center py-5">15.000 EUR</div>
                </v-col>
                <v-col class="pt-2 app-left-border app-right-border app-top-border height-70 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
                >
                  <div class="text-center py-5">20.000 EUR</div>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="3" class="app-top-border pr-10 py-6 pl-2 app-bg-azure">
                  <div class="flex_space_between">
                    <span>Sofortkosten / Notfallkosten</span>
                    <div>
                      <question-tooltip color="white">
                        <div>
                          <b>Sofortkosten / Notfallkosten:</b><br>
                          Mitversichert sind die Kosten für Maßnahmen, die unverzüglich und ohne Verzögerung durch einen Rechtsanwalt eingeleitet werden müssen.
                        </div>
                      </question-tooltip>
                    </div>
                  </div>
                </v-col>
                <v-col class="app-left-border app-top-border height-70 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}">
                  <div class="text-center py-5"><close-box-icon/></div>
                </v-col>
                <v-col class="pt-2 app-left-border app-top-border height-70 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
                >
                  <div class="text-center py-5">10.000 EUR</div>
                </v-col>
                <v-col class="pt-2 app-left-border app-top-border height-70 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
                >
                  <div class="text-center py-5">15.000 EUR</div>
                </v-col>
                <v-col class="pt-2 app-left-border app-right-border app-top-border height-70 custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
                >
                  <div class="text-center py-5">20.000 EUR</div>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="3" class="app-top-border pr-10 pt-3 pl-2 app-bg-azure">
                  <div class="flex_space_between">
                    <span>Sekretariatsservice & Dienstwagenersatz</span>
                    <div>
                      <question-tooltip color="white">
                        <div>
                          <b>Sekretariatsservice & Dienstwagenersatz:</b><br>
                          Mitversichert sind die Kostenübernahme für die Anmietung eines Dienstwagenersatzes oder die Inanspruchnahme eines Sekretariatsservices.
                        </div>
                      </question-tooltip>
                    </div>
                  </div>
                </v-col>
                <v-col class="app-left-border app-top-border  custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}"
                >
                  <div class="text-center py-4"><close-box-icon/></div>
                </v-col>
                <v-col class="app-left-border app-top-border  custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
                >
                  <div class="text-center py-4"><close-box-icon/></div>
                </v-col>
                <v-col class="app-left-border app-top-border  custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
                >
                  <div class="text-center py-4"><close-box-icon/></div>
                </v-col>
                <v-col class="pt-2 app-left-border app-right-border app-top-border  custom-col"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
                >
                  <div class="text-center py-4">25.000 EUR</div>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="3" class="app-top-border pr-10 pt-3 pl-2 app-bg-azure">
                  <span><b>Assistance Leistungen</b></span>
                </v-col>
                <v-col class="app-left-border app-top-border custom-col height-65"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}"
                >
                  <div class="text-center py-4"></div>
                </v-col>
                <v-col class="app-left-border app-top-border custom-col height-65"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
                >
                  <div class="text-center py-4"></div>
                </v-col>
                <v-col class="app-left-border app-top-border custom-col height-65"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
                >
                  <div class="text-center py-4"></div>
                </v-col>
                <v-col class="pt-2 app-left-border app-right-border app-top-border custom-col height-65"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
                >
                  <div class="text-center py-4"></div>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="3" class="app-top-border pr-10 pt-5 pl-2 app-bg-azure">
                  <div class="flex_space_between">
                    <span>Compliance Webcast</span>
                    <div>
                      <question-tooltip color="white">
                        <div>
                          Als besondere Assistance-Leistung können Kunden an digitalen Veranstaltungen rund um das Thema Compliance teilnehmen. Themen sind u.a. Scheinselbstständigkeit, Datenschutz u.v.m.
                        </div>
                      </question-tooltip>
                    </div>
                  </div>
                </v-col>
                <v-col class="app-left-border app-top-border  custom-col height-65"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.basicCorporateGovernance)}"
                >
                  <div class="text-center py-4"><close-box-icon/></div>
                </v-col>
                <v-col class="app-left-border app-top-border  custom-col height-65"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.smartCorporateGovernance)}"
                >
                  <div class="text-center py-4"><close-box-icon/></div>
                </v-col>
                <v-col class="app-left-border app-top-border  custom-col height-65"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.comfortCorporateGovernance)}"
                >
                  <div class="text-center py-4"><check-icon size="24"/>Inklusive</div>
                </v-col>
                <v-col class="app-left-border app-right-border app-top-border custom-col height-65"
                       :class="{'app-bg-light-smoke': checkSelectedPackage(insurancePricePackagesEnum.premiumCorporateGovernance)}"
                >
                  <div class="text-center py-4"><check-icon size="24"/>Inklusive</div>
                </v-col>
              </v-row>
          </toggle-block>
          </div>

        </div>
      </div>
    </div>
    <insurance-price-block
        v-if="!isNpopt"
        class="mt-4"
        :price-items="priceItems"
        :total-price="totalPrice"
        :period="selectedPeriod"
        :discount="insurancePrice.discount"
        :tax-price="taxPrice"
        :final-price="finalPrice"
        :is-other-specific-taxes="isOtherSpecificTaxes"
        :is-disable-all-inputs="isDisableAllInputs"
        :surcharge="insurancePrice.getSurcharge()"
        @acceptSuggestion="acceptSuggestion"
        @changedDiscount="onChangedDiscount"
        @changedSurcharge="changedSurcharge"
    />
  </div>
</template>
<script>
import SelectField from "@/ui/components/common/fields/SelectField.vue";
//import InsurancePricePackagesWrapper from "@/ui/components/insurance/insurance-wrappers/InsurancePricePackagesWrapper.vue";
import Package from "@/ui/components/insurance/insurance-packages/Package.vue";
import CheckIcon from "@/ui/components/common/icons/CheckIcon.vue";
import CloseBoxIcon from "@/ui/components/common/icons/CloseBoxIcon.vue";
import InsurancePriceBlock from "@/ui/components/insurance/insurance-blocks/insurance-price/InsurancePriceBlock.vue";
import {InsurancePriceItem} from "@/models/insurance-price-item.js";
import {InsurancePrice} from "@/models/insurance-price.js";
import {insurancePricePackagesEnum} from "@/enums/insurance-price-packages-enum.js";
import {additionalDataEnum} from "@/enums/insurance-price-packages-enum.js";
import {InsurancePricePackage} from "@/models/insurance-price-package.js";
import {INSURANCE_PRICE_LIST_FIELD_NAMES} from "@/utils/fields/insurance-price-list-field-names.js"
import {capitalizeFirstLetter} from "@/utils/helpers";
import {moneyService} from "@/utils/services/money-service";
import ToggleBlock from "@/ui/components/common/blocks/ToggleBlock.vue";
import {INSURANCE_PRICE_DATA_KEYS} from "@/utils/fields/insurance-price-list-field-names.js";
import QuestionTooltip from "@/ui/components/common/tooltips/QuestionTooltip";
import {builderElementService} from "@/utils/services/builder-element-service";
import {DATE_FIELD_DO_60, SELECT_FIELD_DO_20} from "@/enums/fields/corporate-governance/field-names";
import {DateTime} from "luxon";
import {dateService} from "@/utils/services/date-service";
import {DO_20_PRICE_TAB_CONDITION_VALUES} from "@/enums/fields/corporate-governance/field-values";
import {insuranceStepService} from "@/utils/services/insurance-step-service";
import {insuranceStepEnum} from "@/enums/insurance-step-enum";

export default {
  components: {
    SelectField,
    //InsurancePricePackagesWrapper,
    Package,
    CheckIcon,
    CloseBoxIcon,
    InsurancePriceBlock,
    ToggleBlock,
    QuestionTooltip,
  },
  props: {
    insurancePrice: {
      type: InsurancePrice,
      required: true,
    },
    isDisableAllInputs: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    dateFormat() {
      return 'dd.LL.yyyy';
    },
    allBuilderElements() {
      return this.$store.getters["insuranceModule/builderElementsModule/getAllBuilderElements"];
    },
    showReverseCoverage() {
      const [targetElement] = builderElementService.findAllElementsByParameters(
          this.allBuilderElements,
          'name',
          [DATE_FIELD_DO_60]);

      if (targetElement) {
        const targetDate = DateTime.fromFormat(dateService.getCurrentDate(), this.dateFormat).minus({year: 3}).toFormat(this.dateFormat);

        return !dateService.isBefore(targetElement.value, targetDate);
      }

      return false;
    },
    operationalActivitiesPersons() {
      const [targetElement] = builderElementService.findAllElementsByParameters(
          this.allBuilderElements,
          'name',
          [SELECT_FIELD_DO_20]
      );

      if (targetElement) {
        return !DO_20_PRICE_TAB_CONDITION_VALUES.includes(targetElement.value)
      }

      return false;
    },
    priceItems() {
      const items = []

      //const packName = this.insurancePrice.this.selectedPackage

      items.push(
          new InsurancePriceItem.empty({
            name: `Unternehmens D&O - ${this.selectedPackName}`,
            price: this.selectedPackPrice,
          })
      )

      return items
    },
    insurancePricePackagesEnum() {
      return insurancePricePackagesEnum
    },
    pricePackageAdditionalDataEnum() {
      return additionalDataEnum
    },
    selectedPackage() {
      return this.insurancePrice.getSelectedPackage()
    },
    packages() {
      const items = []

      const packages = this.insurancePrice.getPackages()

      items.push(
          new InsurancePricePackage.empty({
            key: packages[insurancePricePackagesEnum.basicCorporateGovernance].key,
            name: packages[insurancePricePackagesEnum.basicCorporateGovernance].name,
            price: packages[insurancePricePackagesEnum.basicCorporateGovernance].price,
            disabled: packages[insurancePricePackagesEnum.basicCorporateGovernance].disabled,
          }),
          new InsurancePricePackage.empty({
            key: packages[insurancePricePackagesEnum.smartCorporateGovernance].key,
            name: packages[insurancePricePackagesEnum.smartCorporateGovernance].name,
            price: packages[insurancePricePackagesEnum.smartCorporateGovernance].price,
            disabled: packages[insurancePricePackagesEnum.smartCorporateGovernance].disabled,
          }),
          new InsurancePricePackage.empty({
            key: packages[insurancePricePackagesEnum.comfortCorporateGovernance].key,
            name: packages[insurancePricePackagesEnum.comfortCorporateGovernance].name,
            price: packages[insurancePricePackagesEnum.comfortCorporateGovernance].price,
            disabled: packages[insurancePricePackagesEnum.comfortCorporateGovernance].disabled,
          }),
          new InsurancePricePackage.empty({
            key: packages[insurancePricePackagesEnum.premiumCorporateGovernance].key,
            name: packages[insurancePricePackagesEnum.premiumCorporateGovernance].name,
            price: packages[insurancePricePackagesEnum.premiumCorporateGovernance].price,
            disabled: packages[insurancePricePackagesEnum.premiumCorporateGovernance].disabled,
          }),
      )

      return items
    },

    durations() {
      return this.insurancePrice.getLists()[INSURANCE_PRICE_LIST_FIELD_NAMES.duration]
    },
    selectedDuration() {
      return this.insurancePrice.getData()[INSURANCE_PRICE_LIST_FIELD_NAMES.duration]
    },
    periods() {
      return this.insurancePrice.getLists()[INSURANCE_PRICE_LIST_FIELD_NAMES.paymentPeriod]
    },
    selectedPeriod() {
      return this.insurancePrice.getData()[INSURANCE_PRICE_LIST_FIELD_NAMES.paymentPeriod]
    },
    periodList() {
      const periods = []

      this.periods.forEach(period => {
        periods.push({text: capitalizeFirstLetter(period), value: period})
      })

      return periods
    },
    capitalizeFirstLetter() {
      return capitalizeFirstLetter
    },
    selectedColor() {
      return 'var(--light-smoke)'
    },
    isOtherSpecificTaxes() {
      return this.insurancePrice.data[INSURANCE_PRICE_DATA_KEYS.isOtherSpecificTaxes]
    },
    isNpopt() {
      return Boolean(this.insurancePrice.is_npopt)
    },
    sumInsureds() {
      return this.insurancePrice.getLists()[INSURANCE_PRICE_LIST_FIELD_NAMES.sumInsured]
    },
    sumInsuredList() {
      const sumInsureds = []

      this.sumInsureds.forEach(sumInsured => {
        sumInsureds.push({text: moneyService.toMoneyFormat(sumInsured), value: sumInsured})
      })

      return sumInsureds
    },
    selectedSumInsured() {
      return this.insurancePrice.getData()[INSURANCE_PRICE_LIST_FIELD_NAMES.sumInsured]
    },
    totalPrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getTotalPrice"];
    },
    taxPrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getTaxPrice"];
    },
    finalPrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getFinalPrice"];
    },
    stepType() {
      return insuranceStepEnum.CUSTOMER
    },
    insuranceBuilders() {
      return this.$store.getters["insuranceModule/insuranceBuildersModule/getInsuranceStepBuilders"](this.stepType);
    },
    activeInsuranceBuilder() {
      return insuranceStepService.getActiveInsuranceBuilder(this.insuranceBuilders)
    },
    percentFromVSU() {
      if (typeof this.selectedSumInsured === 'number') {
        return this.selectedSumInsured * 20 / 100
      }

      return this.selectedSumInsured
    }
  },
  watch: {
    insurancePrice: {
      handler(){
        this.init()
      },
      deep: true,
      immediate: true
    }
  },
  data: () => ({
    priceData: {
      data: {},
      packages: {},
      selected_package: '',
    },
    selectedPackName: '',
    selectedPackPrice: 0,
    tooltip: 'Im Falle einer gewünschten unterjährigen Zahlungsweise kann nur das SEPA-Lastschriftmandat als Zahlungsart angeboten werden.',
  }),
  created() {
    this.init()
  },
  methods: {
    showProcessPreloader() {
      this.$store.commit('insuranceModule/showProcessPreloader')
    },
    hideProcessPreloader() {
      this.$store.commit('insuranceModule/hideProcessPreloader')
    },
    init() {
      this.priceData.data = this.insurancePrice.getData()
      this.priceData.packages = this.insurancePrice.getPackages()
      this.priceData.selected_package = this.insurancePrice.getSelectedPackage()

      this.fillPackageData(this.insurancePrice.getSelectedPackage())
    },
    fillPackageData(key) {
      this.selectedPackName = this.packages.find(el => el.key === key).name
      this.selectedPackPrice = this.packages.find(el => el.key === key).price
    },
    async changeDuration(val) {
      this.showProcessPreloader();
      this.priceData.data[INSURANCE_PRICE_LIST_FIELD_NAMES.duration] = val

      await this.update();

      await this.calculatePrice()

      //try to use builder relation instead it
      //await this.updateDurationBuilderElement(this.insurancePrice.getInsuranceId(), val)

      this.hideProcessPreloader()
    },
    async changePaymentPeriod(val) {
      this.showProcessPreloader();
      this.priceData.data[INSURANCE_PRICE_LIST_FIELD_NAMES.paymentPeriod] = val

      await this.update();

      await this.calculatePrice()

      await this.updatePaymentPeriodBuilderElement(this.insurancePrice.getInsuranceId(), val)

      this.hideProcessPreloader();
    },
    async changeSumInsured(val) {
      this.showProcessPreloader()
      this.priceData.data[INSURANCE_PRICE_LIST_FIELD_NAMES.sumInsured] = val

      await this.update()

      await this.calculatePrice()

      this.$emit('changeInsurancePriceElement');

      this.hideProcessPreloader()
    },
    acceptSuggestion() {
      this.onClickNext()
    },
    async onClickNext() {
      this.$store.commit('insuranceModule/showPreloader')
      await this.statusCustomer();
      await this.sendXml();
      this.$store.commit('insuranceModule/hidePreloader')
    },
    async statusCustomer() {
      await this.$store.dispatch('insuranceModule/statusCustomer')
    },
    sendXml() {
      this.$store.dispatch('insuranceModule/sendXml')
    },
    checkSelectedPackage(key) {
      return key === this.insurancePrice.getSelectedPackage()
    },
    async update() {
      await this.updateInsurancePrice(this.insurancePrice.getId(), this.priceData)
    },
    async updateInsurancePrice(insurancePriceId, requestData){
      await this.$store.dispatch('insuranceModule/insurancePriceModule/updatePrice', {
        insurancePriceId,
        requestData,
      })
    },
    async selectPackage(key) {
      this.showProcessPreloader();
      this.priceData.selected_package = key

      await this.update()

      await this.calculatePrice()

      this.fillPackageData(key)
      this.hideProcessPreloader();
    },
    getPackItemsByKeyItemAndPackKey(itemKey, packKey) {
      return this.insurancePrice.packages[packKey]['additionalData'][itemKey] ?? '';
    },
    updateLateRegistrationPeriod(val, key) {
      this.updatePackageItem(val, key, additionalDataEnum.lateRegistrationPeriod)
    },
    updateAnnualPeakPerformance(val, key) {
      this.updatePackageItem(val, key, additionalDataEnum.annualPeakPerformance)
    },
    updateAdditionalDefenseCostLimit(val, key) {
      this.updatePackageItem(val, key, additionalDataEnum.additionalDefenseCostLimit)
    },
    async updatePackageItem(val, packKey, itemKey) {
      this.showProcessPreloader();

      this.priceData.packages[packKey]['additionalData'][itemKey]['value'] = val

      await this.update()

      await this.calculatePrice()

      this.hideProcessPreloader();
    },
    getItemsForAdditionalCostLimit(itemKey, packKey) {
      const items = []

      const elements = this.getPackItemsByKeyItemAndPackKey(itemKey, packKey).lists

      elements.forEach(element => {
        items.push({text: moneyService.toMoneyFormat(element), value: element})
      })

      return items
    },
    async onChangedDiscount(discount) {
      this.priceData.data.surcharge = 0
      this.priceData.discount = discount
      this.showProcessPreloader();

      await this.update()

      await this.calculatePrice()

      this.hideProcessPreloader()
    },
    async calculatePrice() {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/calculatePrice', {
        insuranceId: this.insurancePrice.getInsuranceId(),
      })
    },
    async changedSurcharge(surcharge) {
      this.showProcessPreloader();
      this.priceData.discount = 0
      await this.$store.dispatch('insuranceModule/insurancePriceModule/saveSurcharge', {
        surcharge,
      });

      await this.calculatePrice()

      this.hideProcessPreloader();
    },
    async updateDurationBuilderElement(insuranceId, value) {
      await this.$store.dispatch('insuranceModule/builderElementsModule/updateDurationBuilderElement', {
        insuranceId,
        value,
      })
    },
    async updatePaymentPeriodBuilderElement(insuranceId, value) {
      await this.$store.dispatch('insuranceModule/builderElementsModule/updatePaymentPeriodBuilderElement', {
        insuranceId,
        value,
      })

      await this.resetBuilder()
    },
    async resetBuilder() {
      await this.$store.dispatch('insuranceModule/insuranceBuildersModule/resetInsuranceBuilders', {
        insuranceBuilder: this.activeInsuranceBuilder,
      })
    },
    getSublimitAllInsurances(maxSum, maxSumStr = '') {
      if (maxSum > this.percentFromVSU) {
        return `${moneyService.toMoneyFormat(this.percentFromVSU)},<br>max. ${maxSumStr ? maxSumStr : moneyService.toMoneyFormat(maxSum)}`
      }

      return maxSumStr ? maxSumStr : `${moneyService.toMoneyFormat(maxSum)}`
    },
    getCriminalDefenseCosts(maxSum) {
      if (maxSum > this.percentFromVSU) {
        return moneyService.toMoneyFormat(this.percentFromVSU)
      }

      return moneyService.toMoneyFormat(maxSum)
    },
  },
}
</script>

<style scoped>
.flex_space_between {
  display: flex;
  justify-content: space-between;
}

#packs .col:last-child{
   border-right: 1px solid var(--light-gray);
}

.height-100 {
  height: 100px;
}

.height-65 {
  height: 65px;
}
.height-70 {
  height: 70px;
}
.height-155 {
  height: 155px;
}

.custom-col {
  max-width: 18.75%;
}
</style>