export class DashboardInfo {
  constructor({id, name, value, data}) {
    this.id = id;
    this.name = name;
    this.value = value;
    this.data = data;
  }

  static createEmpty() {
    return new DashboardInfo({
      name: '',
      value: '',
      data: {},
    })
  }
}