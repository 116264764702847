import {objectService} from "@/utils/services/object-service.js";

export class BuilderElementRelationConditionGroup {

    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(BuilderElementRelationConditionGroup.empty().toObject())
            : this.fillData(data);
    }

    fillData(data) {
        this.id = data.id;
        this.relation_id = data.relation_id;
        this.name = data.name;
        this.code = data.code;
    }

    static empty(data = {}) {
        return new BuilderElementRelationConditionGroup({
            id: data.id || 0,
            relation_id: data.relation_id || 0,
            name: data.name || '',
            code: data.code || ''
        })
    }

    toObject() {
        return {
            ...this
        }
    }

}