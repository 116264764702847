<template>
  <div>
    <p class="app-text__bold">Css Class</p>

    <text-field
        name="css_class"
        :validation="{required: true}"
        :value="value"
        @saveField="saveCssClass"
    />
  </div>
</template>
<script>

import TextField from "@/ui/components/common/fields/TextField.vue";

export default {
  components: {TextField},
  props: {
    value: {
      type: String,
      default: '',
    }
  },
  methods: {
    saveCssClass(value) {
      this.$emit('input', value)
    }
  }
}
</script>