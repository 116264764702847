<template>
  <div :style="{width: '100%'}">
    <v-row>
      <v-col cols="3">
        <p class="app-text__bold mt-2">Condition</p>

        <searchable-select-field
            name="condition_type"
            :items="conditions"
            :validation="{required: true,}"
            v-model="insurancePriceAddonCondition.condition"
            @saveField="onChangeCondition"
        />
      </v-col>
      <v-col v-if="isBuilderElementCondition" class="pl-2" cols="3">
        <p class="app-text__bold builder-element mt-2">Builder Element</p>

        <select-builder-element-popup-field
            :validation="{required: true}"
            v-model="insurancePriceAddonCondition.builder_element_id"
            :builder-elements="builderElements"
        />
      </v-col>
      <v-col cols="3" class="pl-2">
        <div v-if="isStaticCondition">
          <p class="app-text__bold mt-2">Value</p>
          <text-field
              name="value"
              v-model="insurancePriceAddonCondition.condition_value"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SearchableSelectField from "@/ui/components/common/fields/SearchableSelectField.vue";
import {insurancePriceAddonRelationService} from "@/utils/services/insurance-price-addon-relation-service.js";
import TextField from "@/ui/components/common/fields/TextField.vue";
import {InsurancePriceAddonCondition} from "@/models/insurance-price-addon-relations/insurance-price-addon-condition.js";
import SelectBuilderElementPopupField from "@/ui/components/builder/use-cases/builder-relations/SelectBuilderElementPopupField.vue";

export default {
  components: {SelectBuilderElementPopupField, TextField, SearchableSelectField},
  props: {
    insurancePriceAddonCondition: {
      type: InsurancePriceAddonCondition,
      required: true,
    },
    conditions: {
      type: Array,
      required: true,
    },
    builderElements: {
      type: Array,
      required: true,
    }
  },
  data: () => ({
    isStaticCondition: false,
    isBuilderElementCondition: false,
    isInsurancePriceAddonCondition: false,
  }),
  created() {
    this.onChangeCondition(this.insurancePriceAddonCondition.condition)
  },
  methods: {
    onChangeCondition(conditionType) {
      this.isStaticCondition = insurancePriceAddonRelationService.staticConditions().includes(conditionType)
      this.isInsurancePriceAddonCondition = insurancePriceAddonRelationService.insurancePriceAddonConditions().includes(conditionType)
      this.isBuilderElementCondition = insurancePriceAddonRelationService.builderElementConditions().includes(conditionType)
    },
  },
}
</script>