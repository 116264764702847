import Vue from "vue";
import {SelectElementEvent} from "@/utils/events/events/select-element-event.js";
import {SelectRelationEvent} from "@/utils/events/events/select-relation-event.js";
import {ElementSavedEvent} from "@/utils/events/events/element-saved-event.js";

const eventBus = new Vue;

export const insuranceElementRelationEvents = {
    selectElement: new SelectElementEvent(eventBus),
    selectRelation: new SelectRelationEvent(eventBus),

    relationSaved: new ElementSavedEvent(eventBus),
}