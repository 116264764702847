<template>
  <div :style="{padding, margin, width, height, background}" :class="cssClass">
      <builder-elements
          :elements="children"
          :builder-element-id="builderElementId"
          :disabled-all-inputs="disabledAllInputs"
          :admin-edit-mode="adminEditMode"
      />
  </div>
</template>
<script>
import Padding from "./mixins/Padding.js";
import ParentElement from "./mixins/ParentElement.js";
import DefaultElement from "./mixins/DefaultElement.js";
import CssClass from "./mixins/CssClass.js";
import Size from "@/ui/components/builder/elements/mixins/Size.js";
import Background from "@/ui/components/builder/elements/mixins/Background.js";

export default {
  mixins:[DefaultElement, Size, Padding, Background, ParentElement, CssClass],
  props: {
    builderElementId: {
      type: String,
      required: false,
    },
    disabledAllInputs: {
      type: Boolean,
      required: false,
      default: false,
    },
    adminEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  }
}
</script>