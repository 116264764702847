<template>
  <white-btn :selected="selected" @click="onClick" width="175px" class="mt-4">
    <slot/>
  </white-btn>
</template>
<script>
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
export default {
  components: {WhiteBtn},
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>
<style scoped>

</style>