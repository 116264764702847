<template>
  <div>
    <div v-if="processPreloader" class="mt-12 price-tab-preloader" >
      <app-preloader />
    </div>
    <div v-show="loaded">
      <div class="mt-2 pt-3 app-bg-aqua">
        <app-block v-if="hasPrevBuilder" class="py-2 mb-4 app-container">
          <progress-bar
              name="Risikoerfassung"
              :selected-item="1"
          />
        </app-block>

        <div class="mt-2 mb-4 pt-2 text-center header">
          <div class="mb-3">
            <h3>Vermögensschaden-Haftpflichtversicherung</h3>
          </div>
          <div class="pb-4">
            <h1>{{ pageHeadline }}</h1>
          </div>
          <img class="bg-img" src="/img/man_sitting_on_vault_holding_money.png" alt="Allianz logo"/>
        </div>
      </div>

      <div class="app-container">
        <insurance-step-wrapper
            :prev-button-text="prevButtonText"
            @nextButtonClick="onClickNext"
            @prevButtonClick="onClickPrev"
        >
          <v-form :ref="formName">
            <insurance-builder
                :disabled-all-inputs="isDisableAllInputs"
                v-if="activeInsuranceBuilder"
                :builder="activeInsuranceBuilder.builder"
                :step-type="stepType"
                @resetBuilder="onResetBuilder"
                @resetBuilderWithoutRelations="onResetBuilderWithoutRelations"
                @resetBuilders="onResetBuilders"
            />
          </v-form>

        </insurance-step-wrapper>
      </div>
    </div>
  </div>
</template>
<script>

import {builderElementRelationActionsEnum} from "@/enums/builder-element-relations-enum";

const HEADLINE_PAGE_BEFORE_RISK = 'Erstellen Sie ein neues Angebot';
const HEADLINE_PAGE_RISK = 'Erfassen Sie die Risikodaten';

import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import ProgressBar from "@/ui/components/insurance/ProgressBar.vue";
import {insuranceStepEnum} from "@/enums/insurance-step-enum.js";
import InsuranceStepWrapper from "@/ui/components/insurance/insurance-wrappers/InsuranceStepWrapper.vue";
import {insuranceStepService} from "@/utils/services/insurance-step-service.js";
import {builderElementService} from "@/utils/services/builder-element-service.js";
import InsuranceBuilder from "@/ui/components/insurance/InsuranceBuilder.vue";
import {routeNames} from "@/router/route-names.js";
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue";
import {insuranceTypeEnum} from "@/enums/insurance-type-enum";

export default {
  components: {InsuranceBuilder, InsuranceStepWrapper, ProgressBar, AppBlock, AppPreloader},

  computed: {
    processPreloader() {
      return this.$store.getters["insuranceModule/getProcessPreloader"]
    },

    isFirstRiskPage() {
      if (!this.activeInsuranceBuilder || !this.insuranceBuilders.length) {
        return null;
      }

      return this.activeInsuranceBuilder.id === this.insuranceBuilders[0].id;
    },

    isDisableAllInputs() {
      return (this.isFirstRiskPage && this.$store.getters["insuranceModule/getIsPriceGot"]) || this.isInsuranceFinishedAt;
    },

    stepType() {
      return insuranceStepEnum.RISK
    },

    formName() {
      return 'risk-form';
    },

    form() {
      return this.$refs[this.formName];
    },

    insuranceId() {
      return this.$store.getters["insuranceModule/getInsuranceId"];
    },

    insuranceBuilders() {
      return this.$store.getters["insuranceModule/insuranceBuildersModule/getInsuranceStepBuilders"](this.stepType);
    },

    isLastWasPrevClick() {
      return this.$store.getters["insuranceModule/insuranceNavigationModule/lastWasPrevClick"]
    },

    activeInsuranceBuilder() {
      return insuranceStepService.getActiveInsuranceBuilder(this.insuranceBuilders)
    },

    hasPrevBuilder() {
      return insuranceStepService.hasPrevBuilder(this.activeInsuranceBuilder, this.insuranceBuilders);
    },

    hasNextBuilder() {
      return insuranceStepService.hasNextBuilder(this.activeInsuranceBuilder, this.insuranceBuilders)
    },

    firstInsuranceBuilder() {
      return insuranceStepService.getFirstInsuranceBuilder(this.insuranceBuilders)
    },

    prevButtonText() {
      if (this.hasPrevBuilder) {
          return 'Zurück'
      }

      return 'Abbrechen'
    },

    pageHeadline() {
      if (this.hasPrevBuilder) {
        return HEADLINE_PAGE_RISK
      }

      return HEADLINE_PAGE_BEFORE_RISK
    },
    insurancePrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePrice"]
    },
    isEmptyPrice() {
      return this.insurancePrice.isEmpty()
    },
    isInsuranceFinishedAt() {
      return Boolean(this.$store.getters["insuranceModule/getFinishedAt"])
    },
    insuranceType() {
      return this.$store.getters['insuranceModule/getInsuranceType'];
    },
    corporateGovernanceType() {
      return this.insuranceType.key === insuranceTypeEnum.CORPORATE_GOVERNANCE;
    },
    isUserTestProcess() {
      return this.$store.getters.isTestProcess;
    }
  },

  data: () => ({
    loaded: false,
  }),

  async mounted() {
    //if current insuranceBuilder have no builderElements, skip it
    if (this.checkOnEmptyBuilder(this.activeInsuranceBuilder.builder.elements)) {
      this.isLastWasPrevClick
          ? await this.onClickPrev()
          : await this.onClickNext();

      return
    }

    if (this.hasPrevBuilder) {
      await this.riskStarted();
      await this.getInsurancePrice(this.insuranceId)
      await this.skipInsuranceBuilderIfEmptyPrice()
    }

    this.loaded = true
  },

  methods: {
    showProcessPreloader() {
      this.$store.commit('insuranceModule/showProcessPreloader');
    },

    hideProcessPreloader() {
      this.$store.commit('insuranceModule/hideProcessPreloader');
    },

    async onClickPrev() {
      this.$store.commit('insuranceModule/showPreloader');

      if (this.hasPrevBuilder) {
        await this.prevBuilder();
      } else {
        await this.$router.push({name: routeNames.insuranceCreate})
      }

      this.$store.commit('insuranceModule/hidePreloader')
    },

    async runValidationRelations() {
      return await this.$store.dispatch('insuranceModule/builderElementRelationsModule/runValidationRelations')
    },

    async onClickNext() {
      //check validation
      if (!await this.form.validate() && !this.isDisableAllInputs) {
        this.scrollToValidation();

        return;
      }

      const actionsResult = await this.runValidationRelations();

      if (actionsResult) {
        return;
      }

      await this.runRelationsAndNextBuilder()

      if (!this.isEmptyPrice && !this.corporateGovernanceType) {
        await this.sendXml();
      }

      if (!this.hasPrevBuilder) {
        await this.setRiskData(this.insuranceId)
      }

      if (this.hasPrevBuilder &&
        (
          this.insuranceType.key === insuranceTypeEnum.CORPORATE_GOVERNANCE ||
          this.insuranceType.key === insuranceTypeEnum.COMPANY_CLOSURE
        )) {

        await this.setRisk2Data(this.insuranceId)
      }
    },

    async runRelationsAndNextBuilder() {
      this.$store.commit('insuranceModule/showPreloader');

      if (!this.hasPrevBuilder) {
        await this.updateElementValues();
      }

      await this.runInsuranceRelations();

      if (this.hasPrevBuilder) {
        await this.runInsurancePriceAddonRelations();
      }

      if (this.hasNextBuilder) {
        await this.nextBuilder();
      } else {
        await this.nextStep();
      }

      this.$store.commit('insuranceModule/hidePreloader')
    },

    async updateElementValues() {
      await this.$store.dispatch('insuranceModule/builderElementsModule/updateValues')
    },

    async onResetBuilders() {
      //reset same builder
      this.showProcessPreloader();

      await this.resetInsuranceBuilders(this.activeInsuranceBuilder);
      await this.runBuilderRelations();

      this.hideProcessPreloader();
    },

    async onResetBuilder() {
      //reset same builder
      this.showProcessPreloader();

      await this.resetInsuranceBuilder(this.activeInsuranceBuilder);
      await this.runBuilderRelations();

      this.hideProcessPreloader();
    },

    async onResetBuilderWithoutRelations() {
      //todo envtest, when we fill test data to duplicate we generate unnecessary runBuilderRelations
      if (this.isUserTestProcess) {
        this.hideProcessPreloader();

        return
      }

      //reset same builder
      this.showProcessPreloader();
      await this.resetInsuranceBuilder(this.activeInsuranceBuilder);
      await this.runBuilderRelations([
        builderElementRelationActionsEnum.SET_SUM_BY_FIELDS,
        builderElementRelationActionsEnum.SET_VALIDATION_BY_SUM,
        builderElementRelationActionsEnum.SET_VALIDATION_BY_SUM_MORE,
        builderElementRelationActionsEnum.SHOW,
        builderElementRelationActionsEnum.HIDE,
        builderElementRelationActionsEnum.REQUIRED,

        builderElementRelationActionsEnum.SET_DISABLED,
        builderElementRelationActionsEnum.SET_ENABLED,
      ]);

      this.hideProcessPreloader();
    },

    scrollToValidation() {
      const errMsgSelector = document.querySelector(".v-messages.error--text:first-of-type")

      if (!errMsgSelector) {
        document.querySelector(".v-messages.error--text").closest(".app-block").scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      } else {
        errMsgSelector.closest(".app-block").scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    },

    checkOnEmptyBuilder(elements) {
      return builderElementService.checkOnEmptyBuilder(elements);
    },

    async prevBuilder() {
      const prevBuilder = insuranceStepService.findPrevBuilder(this.activeInsuranceBuilder, this.insuranceBuilders);

      await this.changeActiveBuilder(prevBuilder);
    },

    async nextBuilder() {
      const nextBuilder = insuranceStepService.findNextBuilder(this.activeInsuranceBuilder, this.insuranceBuilders);

      await this.changeActiveBuilder(nextBuilder);
    },

    async nextStep() {
      if (!await this.checkOnEmptyPrice(this.insuranceId)) {
        await this.riskFinished()
      }
    },

    async changeActiveBuilder(nextActiveBuilder) {
      await this.$store.dispatch('insuranceModule/insuranceBuildersModule/changeActiveBuilder', {
        nextActiveBuilder
      })
    },

    async resetInsuranceBuilders(insuranceBuilder) {
      await this.$store.dispatch('insuranceModule/insuranceBuildersModule/resetInsuranceBuilders', {
        insuranceBuilder,
      })
    },

    async resetInsuranceBuilder(insuranceBuilder) {
      await this.$store.dispatch('insuranceModule/insuranceBuildersModule/resetInsuranceBuilder', {
        insuranceBuilder,
      })
    },

    async runBuilderRelations(definedActions = null) {
      await this.$store.dispatch('insuranceModule/builderElementRelationsModule/runRelations', {definedActions})
    },

    async riskFinished() {
      await this.$store.dispatch('insuranceModule/statusRiskFinished')
    },

    async riskStarted() {
      await this.$store.dispatch('insuranceModule/statusInsuranceRiskStarted')
    },

    checkOnEmptyPrice(insuranceId) {
      return this.$store.dispatch('insuranceModule/insurancePriceModule/checkEmptyPrice', {
        insuranceId,
      })
    },

    async runInsuranceRelations() {
      await this.$store.dispatch('insuranceModule/runInsuranceRelations');
    },

    async runInsurancePriceAddonRelations() {
      await this.$store.dispatch('insuranceModule/runInsurancePriceAddonRelations');
    },

    async getInsurancePrice(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/getInsurancePriceByInsuranceId', {
        insuranceId,
      })
    },

    async skipInsuranceBuilderIfEmptyPrice() {
      if (this.isEmptyPrice) {
        this.isLastWasPrevClick
            ? await this.onClickPrev()
            : await this.runRelationsAndNextBuilder()
      }
    },

    sendXml() {
      this.$store.dispatch('insuranceModule/sendXml')
    },

    async setRiskData(insuranceId) {
      await this.$store.dispatch('insuranceModule/setRiskData', {
        insuranceId,
      })
    },
    async setRisk2Data(insuranceId) {
      await this.$store.dispatch('insuranceModule/setRisk2Data', {
        insuranceId,
      })
    },
  }
}
</script>
<style scoped>
.bg-img {
  max-height: 195px;
  margin-bottom: -12px;
}
.price-tab-preloader {
  z-index: 10000;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5)
}
</style>