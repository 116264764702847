import {serverApi} from "@/api/server/server-api.js"

export const insuranceFinishedModule = {
    namespaced: true,
    state: {
        files: [],
    },

    getters: {
        getFiles: (state) => state.files,
    },

    mutations: {
        setFiles: (state, files) => {
            state.files = files
        },
    },

    actions: {
        getFiles: async (context, {insuranceTypeId, insuranceId}) => {
            const {data} = await serverApi.insurance.getInsuranceFinishedFiles(insuranceTypeId, insuranceId)

            if (data.success) {
                context.commit('setFiles', data.files)
            }
        },
    },
}