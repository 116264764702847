import Vue from "vue";
import {SelectElementEvent} from "@/utils/events/events/select-element-event.js";
import {UnselectElementEvent} from "@/utils/events/events/unselect-element-event.js";
import {CreateElementEvent} from "@/utils/events/events/create-element-event.js";
import {UpdateElementEvent} from "@/utils/events/events/update-element-event.js";
import {DeleteElementEvent} from "@/utils/events/events/delete-element-event.js";
import {RemoveFromListElementEvent} from "@/utils/events/events/remove-from-list-element-event.js";
import {AddToListElementEvent} from "@/utils/events/events/add-to-list-element-event.js";
import {ElementSavedEvent} from "@/utils/events/events/element-saved-event.js";
import {ChangedParentEvent} from "@/utils/events/events/ChangedParentEvent";

const eventBus = new Vue;

export const builderEditorEvents = {

    selectElement: new SelectElementEvent(eventBus),

    unselectElement: new UnselectElementEvent(eventBus),

    createElement: new CreateElementEvent(eventBus),

    updateElement: new UpdateElementEvent(eventBus),

    deleteElement: new DeleteElementEvent(eventBus),

    removeElementFromList: new RemoveFromListElementEvent(eventBus),

    addElementToList: new AddToListElementEvent(eventBus),

    elementSaved: new ElementSavedEvent(eventBus),

    changedParentElement: new ChangedParentEvent(eventBus),
}

