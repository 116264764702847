<template>
  <div :style="{width, height, margin, padding}">
    <div class="checkbox-builder-wrapper">
      <div>
        <checkbox
            :name="builderElement.name"
            :value="builderElement.value"
            :text="label"
            :disabled="disabled || disabledBuilderElement"
            :validation="validation"
            @saveField="saveField"
        />
      </div>
      <div class="checkbox-tooltip" v-if="useTooltip">
        <div class="tooltip-wrap">
          <exclamation-tooltip :font-size="'20px'">
            <div v-html="builderElement.data.tooltip"></div>
          </exclamation-tooltip>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Checkbox from "@/ui/components/common/fields/Checkbox.vue";
import ExclamationTooltip from "@/ui/components/common/tooltips/ExclamationTooltip.vue";
import DefaultElement from "@/ui/components/builder/elements/mixins/DefaultElement.js";
import Size from "@/ui/components/builder/elements/mixins/Size.js";
import Padding from "@/ui/components/builder/elements/mixins/Padding.js";
import {builderElementsEvents} from "@/utils/events/builder-elements-events.js";
import {fieldService} from "@/utils/services/field-service.js";
import {isEnvProduction, isEnvStaging, isEnvTest} from "@/plugins/env";
import Validation from "@/ui/components/builder/elements/mixins/Validation";

export default {
  components: {Checkbox, ExclamationTooltip},
  mixins:[DefaultElement, Size, Padding, Validation],
  props: {
    disabledBuilderElement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    label() {
      const label = this.builderElement.data?.label || '';

      return label.replaceAll('`', "'")
    },
    useTooltip() {
      return this.builderElement.data.useTooltip
    },
    disabled() {
      const disabled = fieldService.isFieldSelected(this.builderElement.isDisabled());

      if (disabled) {
        return true;
      }

      return this.builderElement.data.isDisabledOnTest && isEnvTest() ||
          this.builderElement.data.isDisabledOnStaging && isEnvStaging() ||
          this.builderElement.data.isDisabledOnProduction && isEnvProduction()
    },
  },

  methods: {
    saveField(value) {
      this.builderElement.value = fieldService.selectedFieldValue(value)

      builderElementsEvents.updateValue.emit(this.builderElement);
    },
  }
}
</script>
<style scoped>
  .checkbox-builder-wrapper {
    display: flex;
  }
  .checkbox-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    flex-wrap: wrap;
  }
  .tooltip-wrap {
    width: 20px;
    height: 20px;
  }
</style>