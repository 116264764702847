<template>
  <div>
    <div class="text-center">
      <h3>Checkbox</h3>
    </div>

    <v-row>
      <v-col cols="12">
        <name-editor
            v-model="builderElement.name"
        />
      </v-col>

      <v-col cols="6">
        <priority-editor
            v-model.number="builderElement.priority"
        />
      </v-col>

      <v-col class="pl-4" cols="6">
        <width-editor
            v-model="builderElement.data.width"
        />
      </v-col>

      <v-col cols="12">
        <p class="app-text__bold">Label</p>
        <text-area-field
            name="label"
            v-model="builderElement.data.label"
        />
      </v-col>
      <v-col cols="12" class="mt-2">
        <disabled-editor
            v-model="builderElement.data.disabled"
        />
      </v-col>
      <v-row v-if="isShowCheckboxesBlock">
        <v-col cols="4">
          <checkbox
              text="On test server"
              :value="builderElement.data.isDisabledOnTest ? '1' : '0'"
              @input="changeIsDisabledOnTest"
          />
        </v-col>
        <v-col cols="4">
          <checkbox
              text="On stage server"
              :value="builderElement.data.isDisabledOnStaging ? '1' : '0'"
              @input="changeIsDisabledOnStaging"
          />
        </v-col>
        <v-col cols="4">
          <checkbox
              text="On production server"
              :value="builderElement.data.isDisabledOnProduction ? '1' : '0'"
              @input="changeIsDisabledOnProduction"
          />
        </v-col>
      </v-row>
      <v-col cols="12">
        <p class="app-text__bold">Use tooltip</p>
        <checkbox
            v-model="builderElement.data.useTooltip"
        />
      </v-col>
      <v-col cols="12" v-if="isShowTooltip">
        <p class="app-text__bold">Tooltip text</p>
        <text-area-field
            name="label"
            v-model="builderElement.data.tooltip"
        />
      </v-col>

      <v-col cols="6">
        <p class="app-text__bold">Default Value</p>
        <checkbox
          v-model="builderElement.value"
        />
      </v-col>
    </v-row>

    <margin-editor
        v-model="builderElement.data.margin"
    />
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import WidthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/WidthEditor.vue";
import MarginEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MarginEditor.vue";
import Checkbox from "@/ui/components/common/fields/Checkbox.vue";
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";
import DisabledEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/DisabledEditor";

export default {
  components: {
    TextAreaField,
    Checkbox,
    MarginEditor,
    WidthEditor,
    PriorityEditor,
    NameEditor,
    DisabledEditor
  },
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  },
  computed: {
    isShowTooltip() {
      return this.builderElement.data.useTooltip === '1'
    },
    isShowCheckboxesBlock() {
      return this.builderElement.data.disabled === '1'
    }
  },
  methods: {
    changeIsDisabledOnTest(value) {
      this.builderElement.data.isDisabledOnTest = value === '1'
    },
    changeIsDisabledOnStaging(value) {
      this.builderElement.data.isDisabledOnStaging = value === '1'
    },
    changeIsDisabledOnProduction(value) {
      this.builderElement.data.isDisabledOnProduction = value === '1'
    },
  }
}
</script>