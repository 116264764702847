<template>
  <a :style="{margin}"
     target="_blank"
     class="download-file-button"
     :id="id"
     :class="cssClass"
     :href="buttonLink">{{builderElement.data.title}}</a>
</template>

<script>
import DefaultElement from "@/ui/components/builder/elements/mixins/DefaultElement";
import Padding from "@/ui/components/builder/elements/mixins/Padding";
import Id from "@/ui/components/builder/elements/mixins/Id";
import CssClass from "@/ui/components/builder/elements/mixins/CssClass";

export default {
  mixins: [DefaultElement, Padding, Id, CssClass],
  computed: {
    buttonLink() {
      return this.builderElement.data?.link || '#';
    }
  }
}
</script>

<style scoped>

</style>