<template>
  <v-icon
      @click="$emit('click')"
      class="cursor-pointer rounded-circle px-1 py-1"
      v-ripple="true"
      :size="size"
  >
    mdi-logout
  </v-icon>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: '20px',
    }
  }
}
</script>