<template>
  <div>
    <div class="mt-2 mb-4 text-center header app-bg-aqua">
      <div class="mb-2 pt-11">
        <h3>Vermögensschadenhaftpflicht</h3>
      </div>
      <div class="mb-4 pb-14">
        <h1>Ihre gerechneten Angebote</h1>
      </div>
    </div>

    <div class="app-container">
      <div class="d-flex justify-end">
        <insurance-search-field
          :value="search"
          @saveField="onChangeSearchText"
        />
      </div>
      <insurance-table
        :insurances="insurances"
        :loading="loading"
        :per-page="perPage"
        :page="page"
        :last-page="lastPage"
        @changeOptions="onChangeOptions"
        @deleteClick="onClickDelete"
      />

      <simple-popup
          v-if="showDeletingInsurancePopup"
          header="Möchten Sie diesen Vorgang wirklich unwiderruflich löschen?"
          :is-show-close-button="false"
          @close="onCloseDeletingInsurancePopup"
      >
        <v-row>
          <v-col cols="12">
            <p>Dies kann nicht rückgängig gemacht werden.</p>
          </v-col>
          <div class="d-flex">
            <white-btn class="mr-2" @click="onConfirmDeletingPopup">Vorgang löschen</white-btn>
            <white-btn class="mr-2" @click="onCloseDeletingInsurancePopup">Nicht löschen</white-btn>
          </div>
        </v-row>
      </simple-popup>
    </div>
  </div>
</template>
<script>
import InsuranceSearchField from "@/ui/components/insurance/insurance-fields/InsuranceSearchField.vue";
import InsuranceTable from "@/ui/components/insurance/insurance-tables/InsuranceTable.vue";
import {debounce} from "@/utils/functions.js";
import {notificationService} from "@/utils/services/notification-service.js";
import SimplePopup from "@/ui/components/common/popup/SimplePopup.vue";
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";

export default {
  components: {WhiteBtn, InsuranceTable, InsuranceSearchField, SimplePopup},
  data: () => ({
    loading: true,
    insurances: [],
    search: '',
    page: 1,
    perPage: 10,
    lastPage: 1,
    showDeletingInsurancePopup: false,
    currentInsuranceId: null,
  }),
  created() {
    setTimeout(async () => {
      await this.resetInsurances();
    }, 500)
  },
  methods: {
    onChangeSearchText(searchText) {
      this.search = searchText;

      debounce(async () =>  await this.resetInsurances(), 'search', 700);
    },

    async onClickDelete(insuranceId) {
      this.currentInsuranceId = insuranceId
      this.showDeletingInsurancePopup = true
    },

    async onChangeOptions({perPage, page}) {
      this.perPage = perPage;
      this.page = page;

      if(this.page < 1 || this.page > this.lastPage) {
        this.page = 1;
      }

      await this.resetInsurances();
    },

    async resetInsurances() {
      this.loading = true;

      const {insurances, lastPage} = await this.getInsurances(this.search, this.perPage, this.page)

      this.insurances = insurances;
      this.lastPage = lastPage

      this.loading = false;
    },

    getInsurances(search = '', perPage = 10, page = 1) {
      return this.$store.dispatch('insuranceModule/insuranceList', {
        search,
        perPage,
        page
      })
    },

    deleteInsurance(insuranceId) {
      return this.$store.dispatch('insuranceModule/deleteInsurance', {
        insuranceId,
      })
    },

    onCloseDeletingInsurancePopup() {
      this.showDeletingInsurancePopup = false
    },

    async onConfirmDeletingPopup() {
      const result = await this.deleteInsurance(this.currentInsuranceId)

      if(result) {
        notificationService.success("Insurance was deleted successfully")
      } else {
        notificationService.error("Something went wrong")
      }

      await this.resetInsurances();

      this.showDeletingInsurancePopup = false
    },
  }
}
</script>