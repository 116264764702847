<template>
  <div v-if="isLoaded">
    <div class="mt-2 pt-3 pb-3 app-bg-aqua">
      <app-block class="py-2 mb-4 app-container">
        <progress-bar
            name="Vorschlag"
            :selected-item="2"
        />
      </app-block>
    </div>
    <insurance-step-wrapper
        :show-next-button="isShowNextBtn"
        @prevButtonClick="onClickPrev"
        @nextButtonClick="onClickNext"
    >
      <price-data-block
          :insurance-id="insurance.id"
          :is-disable-all-inputs="isDisableAllInputs"
      />

      <addon-blocks
          v-if="isShowAddons"
          :addons="addons"
          :show-price="!insurancePrice.getIsNpopt()"
          :insurance-type-id="insurance.type.id"
          :period="insurancePrice.getPaymentPeriodLabel()"
          :is-disable-all-inputs="isDisableAllInputs"
          @changeAddon="onChangeAddon"
      />

      <insurance-price-block
          v-if="!insurancePrice.getIsNpopt()"
          class="mt-4"
          :price-items="priceItems"
          :total-price="totalPrice"
          :period="insurancePrice.getPaymentPeriodLabel()"
          :tax-price="taxPrice"
          :final-price="finalPrice"
          :discount="insurancePrice.discount"
          :is-disable-all-inputs="isDisableAllInputs"
          :surcharge="insurancePrice.getSurcharge()"
          @acceptSuggestion="acceptSuggestion"
          @changedDiscount="onChangedDiscount"
          @changedSurcharge="onChangedSurcharge"
      />

      <document-applications-block
          class="mt-4"
          v-if="isShowDocsApplications"
          :documents="documentApplications"
      />

    </insurance-step-wrapper>
  </div>
</template>
<script>
import ProgressBar from "@/ui/components/insurance/ProgressBar.vue";
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import InsuranceStepWrapper from "@/ui/components/insurance/insurance-wrappers/InsuranceStepWrapper.vue";
import InsurancePriceBlock from "@/ui/components/insurance/insurance-blocks/insurance-price/InsurancePriceBlock.vue";
import {InsurancePriceItem} from "@/models/insurance-price-item.js";
import {insuranceStepService} from "@/utils/services/insurance-step-service.js";
import PriceDataBlock from "@/ui/components/insurance/insurance-prices/blocks/PriceDataBlock.vue";
import AddonBlocks from "@/ui/components/insurance/insurance-prices/blocks/AddonBlocks.vue";
import DocumentApplicationsBlock from "@/ui/components/insurance/insurance-prices/blocks/DocumentApplicationsBlock.vue";
import {prepareInsuranceFullName} from "@/utils/helpers";
import {insuranceTypeEnum} from "@/enums/insurance-type-enum.js"

export default {
  components: {
    InsurancePriceBlock,
    InsuranceStepWrapper,
    AppBlock,
    ProgressBar,
    PriceDataBlock,
    AddonBlocks,
    DocumentApplicationsBlock,
  },
  props: {
    isDisableAllInputs: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    priceItems() {
      let items = []

      if (this.insuranceType.key === insuranceTypeEnum.COMPANY_CLOSURE) {
        items = this.additionalPriceItems
      } else {
        items.push(
          new InsurancePriceItem.empty({
            name: this.insuranceFullName || 'Versicherung Einzelanwalt',
            price: this.periodPrice,
          })
        )

        if (this.additionalPriceItems.length) {
          this.additionalPriceItems.forEach((component) => {
            items.push(
              new InsurancePriceItem.empty({
                name: component.name,
                price: component.price,
              })
            )
          })

          if (!this.isBabyContract) {
            this.selectedAddons.forEach(addon => {
              items.push(
                new InsurancePriceItem.empty({
                  name: addon.name,
                  price: addon.price,
                  additionalText: addon.details.info,
                })
              )
            })
          }
        }
      }

      return items
    },
    insuranceBuilders() {
      return this.$store.getters["insuranceModule/insuranceBuildersModule/getInsuranceBuilders"];
    },
    insurance() {
      return this.$store.getters["insuranceModule/getInsurance"];
    },
    defaultPriceBuilder() {
      return insuranceStepService.getSelectedPriceBuilder(this.insuranceBuilders)
    },
    formName() {
      return 'default-price-form';
    },
    periodPrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getPerPeriodPrice"];
    },
    addons() {
      return this.$store.getters["insuranceModule/insurancePriceAddonModule/getAddons"];
    },
    selectedAddons() {
      return this.$store.getters["insuranceModule/insurancePriceAddonModule/getSelectedAddons"];
    },
    totalPrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getTotalPrice"];
    },
    insurancePrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getInsurancePrice"];
    },
    taxPrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getTaxPrice"];
    },
    finalPrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getFinalPrice"];
    },
    isBabyContract() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getIsBabyContract"];
    },
    isShowAddons() {
      return !this.isBabyContract && !this.insurancePrice.getIsNpopt()
    },
    insuranceFullName() {
      return prepareInsuranceFullName(this.$store.getters["insuranceModule/insurancePriceModule/getInsuranceFullName"]);
    },
    isShowNextBtn() {
      return this.insurancePrice.getIsNpopt()
    },
    additionalPriceItems() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getAdditionalPriceItems"];
    },
    isShowDocsApplications: function () {
      return !this.insurancePrice.getIsNpopt()
    },
    insuranceType() {
      return this.$store.getters['insuranceModule/getInsuranceType'];
    },
  },
  data: () => ({
    paymentPeriod: "Jährlich",
    isLoaded: false,
    documentApplications: [],
  }),
  async created() {
    this.showProcessPreloader();
    await this.calculatePrices()

    await this.getDocumentApplicationsByType(this.insurance.type.id)

    if (this.isBabyContract && +this.insurancePrice.discount > 0) {
      await this.onChangedDiscount(0);
    }

    this.isLoaded = true
    this.hideProcessPreloader();
  },
  methods: {
    showProcessPreloader() {
      this.$store.commit('insuranceModule/showProcessPreloader')
    },
    hideProcessPreloader() {
      this.$store.commit('insuranceModule/hideProcessPreloader')
    },
    async onClickPrev() {
      this.$store.commit('insuranceModule/showPreloader')
      await this.prevStep();
      this.$store.commit('insuranceModule/hidePreloader')
    },

    async onClickNext() {
      this.$store.commit('insuranceModule/showPreloader')
      await this.statusCustomer();
      await this.sendXml();
      this.$store.commit('insuranceModule/hidePreloader')
    },

    async prevStep() {
      //TODO: think about status when we comeback from PriceTab to RiskTab
      await this.statusDraft();
    },

    async statusDraft() {
      await this.$store.dispatch('insuranceModule/statusDraft')
    },

    async statusCustomer() {
      await this.$store.dispatch('insuranceModule/statusCustomer')
    },
    async onChangeAddon() {
      await this.calculatePrice(this.insurance['id'])

      this.documentApplications = await this.getDocumentApplications(this.insurance.type.id)
    },
    async calculatePrice(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/calculatePrice', {
        insuranceId,
      })
    },
    async calculatePrices() {
      await this.calculatePrice(this.insurance['id'])
    },
    acceptSuggestion() {
      this.onClickNext()
    },
    sendXml() {
      this.$store.dispatch('insuranceModule/sendXml')
    },
    async onChangedDiscount(discount) {
      this.showProcessPreloader();

      await this.$store.dispatch('insuranceModule/insurancePriceModule/saveDiscount', {
        discount,
      });

      await this.calculatePrices()

      this.hideProcessPreloader();
    },
    async onChangedSurcharge(surcharge) {
      this.showProcessPreloader();

      await this.$store.dispatch('insuranceModule/insurancePriceModule/saveSurcharge', {
        surcharge,
      });

      await this.calculatePrices()

      this.hideProcessPreloader();
    },
    async getDocumentApplications(insuranceTypeId) {
      return await this.$store.dispatch('insuranceModule/insurancePriceModule/getDocumentApplications', {
        insuranceTypeId,
      })
    },
    async getDocumentApplicationsByType() {
      this.documentApplications = await this.getDocumentApplications(this.insurance.type.id);
    }
  }
}
</script>

