<template>
  <div>
    <v-text-field
        :value="value"
        @input="saveField"
        :rules="validationRules"
        :placeholder="placeholder"
        type="password"
        outlined
        dense
    />
  </div>
</template>
<script>
import {equalToElementValue, required} from "@/utils/validation-rules.js";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      required: false,
    },
    validation: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    allBuilderElements() {
      return this.$store.getters["insuranceModule/builderElementsModule/getAllBuilderElements"];
    },
    validationRules() {
      const rules = [];

      if (this.validation.required && this.validation.required !== '0') {
        rules.push(required);
      }

      if (this.validation.equalTo && this.validation.equalTo.elementId) {
        rules.push(
            (val, message = this.validation.equalTo.message || 'Error') =>
                equalToElementValue(val, this.validation.equalTo.elementId, this.allBuilderElements, message)
        );
      }

      return rules;
    }
  },
  methods: {
    saveField(value) {
      this.$emit('saveField', value, this.name);
      this.$emit('input', value);
    }
  }
}
</script>