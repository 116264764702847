import {authApi} from "./auth/auth-api.js";
import {adminApi} from "./admin/admin-api.js";
import {insuranceApi} from "@/api/server/insurance/insurance-api.js";
import {http} from "@/api/server/server-configs.js";
import {builderElementsApi} from "@/api/server/builder-elements/builder-elements-api.js";
import {insurancePriceApi} from "@/api/server/insurance/insurance-price-api.js";
import {insurancePriceAddonApi} from "@/api/server/insurance/insurance-price-addon-api.js";
import {insurancePoliceApi} from "@/api/server/insurance/insurance-police-api.js";
import {expiringTokenApi} from "@/api/server/expiring-token/expiring-token-api.js";
import {apiDataApi} from "@/api/server/api-data/api-data-api.js";

export const serverApi = {
    auth: authApi(http),
    admin: adminApi(http),
    insurance: insuranceApi(http),
    insurancePrice: insurancePriceApi(http),
    builderElements: builderElementsApi(http),
    insurancePriceAddon: insurancePriceAddonApi(http),
    insurancePolice: insurancePoliceApi(http),
    expiringToken: expiringTokenApi(http),
    apiData: apiDataApi(http),
}