<template>
  <simple-popup  header="Hinweis zur Haftungsbeschränkung" @close="onClose">
    <v-row>
      <v-col cols="12">
        <p>{{ content }}</p>
      </v-col>
      <div class="d-flex">
        <white-btn class="mr-2" @click="onClickOk">Hinweis zur Kenntnis genommen</white-btn>
      </div>
    </v-row>
  </simple-popup>
</template>

<script>
import SimplePopup from "@/ui/components/common/popup/SimplePopup.vue";
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";


export default {
  components: {
    WhiteBtn,
    SimplePopup
  },
  props: {
    content: {
      type: String,
      default: `Wenn mit Haftungsbeschränkungen im Rahmen von vorformulierten Vertragsbedingungen z.B.
                  Allgemeinen Auftragsbedingungen gearbeitet wird, empfehlen wir für die Wirksamkeit die 4-fache gesetzlich
                  vorgeschriebene Mindestversicherungssumme.`,
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    onClickOk() {
      this.$emit('confirmNoticeLimitation')
    },
  },
}
</script>
