export const insuranceApi = (http) => ({
    create: (insuranceTypeId, insuranceName) => http.post(`/api/insurance/create`, {
        insuranceTypeId,
        insuranceName,
    }),

    show: (insuranceId) => http.get(`/api/insurance/show/${insuranceId}`),

    delete: (insuranceId) => http.post(`/api/insurance/delete/${insuranceId}`),

    list: (data) => http.get(`/api/insurance/list`, data),

    insuranceTypes: () => http.get(`/api/insurance/types`),

    changeActiveBuilder: (newBuilderId) => http.post(`/api/insurance/insurance-builders/change-active/${newBuilderId}`),

    resetInsuranceBuilders: (insuranceBuilderId) => http.post(`/api/insurance/insurance-builders/reset-data/${insuranceBuilderId}`),
    resetInsuranceBuilder: (insuranceBuilderId) => http.post(`/api/insurance/insurance-builders/reset-data-one-insurance-builder/${insuranceBuilderId}`),

    statusRiskFinished: (insuranceId) => http.post(`/api/insurance/change-status/${insuranceId}/risk-finished`),

    statusDraft: (insuranceId) => http.post(`/api/insurance/change-status/${insuranceId}/draft`),

    statusCustomer: (insuranceId) => http.post(`/api/insurance/change-status/${insuranceId}/customer`),

    statusPolice: (insuranceId) => http.post(`/api/insurance/change-status/${insuranceId}/police-step`),

    statusInsuranceRiskStarted: (insuranceId) => http.post(`/api/insurance/change-status/${insuranceId}/risk-started`),

    statusInsuranceFinished: (insuranceId) => http.post(`/api/insurance/change-status/${insuranceId}/insurance-finished`),

    applicationSubmitted: (insuranceId) => http.post(`/api/insurance/change-status/${insuranceId}/application-submitted`),

    statusFromFinishToPolice: (insuranceId) => http.post(`/api/insurance/change-status/${insuranceId}/from-finish-to-police`),

    statusDeterminePoliceStatus: (insuranceId) => http.post(`/api/insurance/change-status/${insuranceId}/determine-police-status`),

    dataAcquisitionForModulation: (insuranceId) => http.post(`/api/insurance/change-status/${insuranceId}/data-acquisition-for-modulation`),

    emptyPrice: (insuranceId) => http.post(`/api/insurance/change-status/${insuranceId}/empty-price`),

    runInsuranceRelations: (insuranceId) => http.post(`/api/insurance/insurance-relations/${insuranceId}/run`),

    runGeneratePdf: (insuranceId, pdf) => http.post(`/api/insurance/${insuranceId}/generate-pdf/${pdf}`, {}, {responseType: 'blob'}),

    getPdfName: (insuranceId, documentName) => http.get(`/api/insurance/${insuranceId}/pdf-name/${documentName}`),

    runInsurancePriceAddonRelations: (insuranceId) => http.post(`/api/insurance/insurance-price-addon-relations/${insuranceId}/run`),

    sendXml: (insuranceId) => http.post(`/api/insurance/${insuranceId}/send-xml`),

    sendBigXml: (insuranceId) => http.post(`/api/insurance/${insuranceId}/send-big-xml`),

    getInsuranceFinishedFiles: (insuranceTypeId, insuranceId) => http.get(`/api/insurance-type/${insuranceTypeId}/insurance-finished-files/${insuranceId}`),

    sendEmailToManager: (insuranceId, token) => http.get(`/api/insurance/${insuranceId}/send-finish-manager-email?token=${token}`),

    sendEmailToCustomer: (insuranceId, token) => http.get(`/api/insurance/${insuranceId}/send-finish-customer-email?token=${token}`),

    setCustomerConfirmed: (insuranceId, token) => http.get(`/api/insurance/${insuranceId}/confirm?token=${token}`),

    setRiskData: (insuranceId) => http.post(`/api/insurance/${insuranceId}/set-risk-data`),

    setRisk2Data: (insuranceId) => http.post(`/api/insurance/${insuranceId}/set-risk-2-data`),

    setCustomerData: (insuranceId) => http.post(`/api/insurance/${insuranceId}/set-customer-data`),

    checkIban: (iban) => http.get(`/api/insurance/check-iban/${iban}`),
})