<template>
  <div>
    <div class="text-center">
      <h3>Link Button</h3>
    </div>

    <v-row>
      <v-col cols="12">
        <name-editor
            v-model="builderElement.name"
        />
      </v-col>

      <v-col cols="3" >
        <priority-editor
            v-model.number="builderElement.priority"
        />
      </v-col>
      <v-col cols="9" class="pl-4">
        <p class="app-text__bold">Title</p>
        <text-field
            name="title"
            v-model="builderElement.data.title"
        />
      </v-col>
    </v-row>
    <v-row>
      <p class="app-text__bold">Select method to set file link</p>
      <v-col cols="12">
        <select-field
            class="mt-4"
            name="selectedMethod"
            :items="fileOptions"
            :value="defaultMethod"
            @saveField="changeMethod"
        />
      </v-col>
    </v-row>
    <v-col v-if="selectedMethod === editLinkManual" cols="12">
      <p class="app-text__bold">Link</p>
      <text-field
          name="value"
          v-model="builderElement.data.link"
      />
    </v-col>
    <div v-if="selectedMethod === editLinkByFile" class="pb-2 row">
      <file-field
          :name="'upload_file'"
          :placeholder="'Upload file'"
          :width="'auto'"
          @saveFile="saveFile"
      />
    </div>
    <v-col v-if="selectedMethod === editLinkByFile" cols="12">
      <p class="app-text__bold">Link</p>
      <div>
        <a target="_blank" :href="builderElement.data.link">{{builderElement.data.link}}</a>
      </div>
    </v-col>

    <margin-editor
        v-model="builderElement.data.margin"
    />

    <id-editor
        class="mt-6"
        v-model="builderElement.data.id"
    />

    <css-class-editor
        class="mt-6"
        v-model="builderElement.data.css_class"
    />
  </div>
</template>

<script>
import {BuilderElement} from "@/models/builder-element.js";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import MarginEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MarginEditor.vue";
import IdEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/IdEditor.vue";
import CssClassEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/CssClassEditor.vue";
import TextField from "@/ui/components/common/fields/TextField.vue";
import FileField from "@/ui/components/common/fields/FileField";
import {builderElementsEvents} from "@/utils/events/builder-elements-events";
import SelectField from "@/ui/components/common/fields/SelectField.vue";

export default {
  components: {MarginEditor, PriorityEditor, NameEditor, IdEditor, CssClassEditor, TextField,
    FileField, SelectField},
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  },
  data() {
    return {
      editLinkManual: 'manual',
      editLinkByFile: 'by_file',
      fileOptions: [
        {
          value: 'manual',
          text: 'Set custom link to file'
        },
        {
          value: 'by_file',
          text: 'Upload file file'
        }
      ],
      defaultMethod: 'manual',
      selectedMethod: 'manual'
    }
  },
  methods: {
    saveFile(file) {
      builderElementsEvents.editorLoadFile.emit(file)
    },
    changeMethod(item) {
      this.selectedMethod = item;
    }
  }
}
</script>
