var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-10",attrs:{"headers":_vm.headers,"items":_vm.insuranceBuilders,"loading-text":"Loading... Please wait","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(_vm.inEditModeId !== item.id)?_c('div',[_vm._v(" "+_vm._s(item.name))]):_c('text-field',{staticClass:"mt-4",attrs:{"name":"name"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}},{key:"item.step_type",fn:function(ref){
var item = ref.item;
return [(_vm.inEditModeId !== item.id)?_c('div',[_vm._v(" "+_vm._s(item.step_type))]):_c('select-field',{staticClass:"mt-4",attrs:{"name":"step_type","items":_vm.insuranceSteps},model:{value:(item.step_type),callback:function ($$v) {_vm.$set(item, "step_type", $$v)},expression:"item.step_type"}})]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [(_vm.inEditModeId !== item.id)?_c('div',[_vm._v(" "+_vm._s(item.priority))]):_c('number-field',{staticClass:"mt-4",attrs:{"name":"priority"},model:{value:(item.priority),callback:function ($$v) {_vm.$set(item, "priority", $$v)},expression:"item.priority"}})]}},{key:"item.operation",fn:function(ref){
var item = ref.item;
return [(_vm.inEditModeId !== item.id)?_c('div',[_c('show-icon'),_c('show-query-icon',{attrs:{"size":"24"},on:{"click":function($event){return _vm.sqlClick(item)}}}),_c('edit-icon',{attrs:{"size":"24","color":"var(--light-blue)"},on:{"click":function($event){return _vm.editClick(item)}}}),_c('delete-icon',{on:{"click":function($event){return _vm.deleteClick(item)}}})],1):_c('div',{staticClass:"float-right d-flex"},[_c('orange-btn',{staticClass:"mr-4 mt-1",attrs:{"height":"28px"},on:{"click":function($event){return _vm.builderEditorClick(item)}}},[_vm._v(" Builder Editor")]),_c('check-icon',{on:{"click":function($event){return _vm.updateClick(item)}}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }