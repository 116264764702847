<template>
  <div>
    {{ isArray ? '' : '"' +builderElement.name + '":' }} [
    <json-builder-elements
        v-if="builderElement.children.length"
        :builder-elements="builderElement.children"
        :is-array="true"
    />
    ] {{comma ? ',' : ''}}
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";

export default {
  components: {
    JsonBuilderElements: () => import("../JsonBuilderElements.vue")
  },
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    },
    comma: {
      type: Boolean,
      default: false,
    },
    isArray: {
      type: Boolean,
      default: false,
    }
  }
}
</script>