<template>
  <div class="empty-price-component">
  <insurance-step-wrapper
      v-if="isLoaded"
      next-button-text="Anfrage an Underwriting senden"
      next-button-width="350px"
      @prevButtonClick="onClickPrev"
      @nextButtonClick="onClickNext"
  >
    <app-block class="py-8 mb-4">
      <progress-bar
          name="Datenerfassung für Aussteuerung"
          :selected-item="2"
          :is-empty-price="true"
      />
    </app-block>

    <v-form v-if="emptyPriceBuilder" :ref="formName">
      <insurance-builder-component
          v-if="emptyPriceBuilder"
          :builder="emptyPriceBuilder.builder"
          @resetBuilder="onResetBuilder"
          @resetBuilderWithoutRelations="onResetBuilderWithoutRelations"
      />
    </v-form>

    <app-block v-else header="Can't Find Empty Price Builder">
    </app-block>

  </insurance-step-wrapper>
  </div>
</template>
<script>
import InsuranceStepWrapper from "@/ui/components/insurance/insurance-wrappers/InsuranceStepWrapper.vue";
import InsuranceBuilderComponent from "@/ui/components/insurance/InsuranceBuilder.vue";

import ProgressBar from "@/ui/components/insurance/ProgressBar.vue";
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import {insuranceStepService} from "@/utils/services/insurance-step-service.js";
import {builderQuerySelectors} from "@/utils/query-selectors/builder-query-selectors.js";
import {builderElementRelationActionsEnum} from "@/enums/builder-element-relations-enum";

export default {
  components: {AppBlock, ProgressBar, InsuranceBuilderComponent, InsuranceStepWrapper},
  computed: {
    formName() {
      return 'empty-price-form';
    },

    form() {
      return this.$refs[this.formName];
    },

    insuranceBuilders() {
      return this.$store.getters["insuranceModule/insuranceBuildersModule/getInsuranceBuilders"];
    },

    emptyPriceBuilder() {
      return insuranceStepService.getEmptyPriceBuilder(this.insuranceBuilders)
    },

    insuranceId() {
      return this.$store.getters["insuranceModule/getInsuranceId"];
    },
  },
  data: () => ({
    isLoaded: false,
  }),
  async created() {
    this.showProcessPreloader();
    await this.configureEmptyPrice(this.insuranceId)
    await this.dataAcquisitionForModulation()
    this.hideProcessPreloader();
    this.isLoaded = true
  },
  methods: {
    showProcessPreloader() {
      this.$store.commit('insuranceModule/showProcessPreloader')
    },
    hideProcessPreloader() {
      this.$store.commit('insuranceModule/hideProcessPreloader')
    },
    async runBuilderRelations(definedActions = null) {
      await this.$store.dispatch('insuranceModule/builderElementRelationsModule/runRelations', {definedActions})
    },
    async onClickPrev() {
      this.$store.commit('insuranceModule/showPreloader')
      await this.prevStep();
      this.$store.commit('insuranceModule/hidePreloader')
    },

    async onClickNext() {

      if (!await this.form.validate()) {
        builderQuerySelectors.scrollToValidation();

        return;
      }

      const actionsResult = await this.runValidationRelations();

      if (actionsResult) {
        return;
      }

      this.$store.commit('insuranceModule/showPreloader')
      await this.updateElementValues();
      await this.sendEmail(this.insuranceId)
      await this.statusFinished();
      await this.emptyPrice();
      await this.sendXml();
      await this.sendBigXml();
      this.$store.commit('insuranceModule/hidePreloader')
    },

    async prevStep() {
      //TODO: think about status when we comeback from PriceTab to RiskTab
      await this.statusDraft();
    },

    async statusDraft() {
      await this.$store.dispatch('insuranceModule/statusDraft')
    },

    async statusCustomer() {
      await this.$store.dispatch('insuranceModule/statusCustomer')
    },
    async configureEmptyPrice(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/configureEmptyPrice', {
        insuranceId,
      })

      await this.resetBuilder()
    },
    async resetBuilder() {
      await this.$store.dispatch('insuranceModule/insuranceBuildersModule/resetInsuranceBuilders', {
        insuranceBuilder: this.emptyPriceBuilder,
      })
    },
    async statusFinished() {
      await this.$store.dispatch('insuranceModule/statusInsuranceFinished')
    },
    async sendEmail(insuranceId) {
      return await this.$store.dispatch('insuranceModule/insurancePriceModule/sendEmptyPriceEmail', {
            insuranceId
          }
      );
    },
    async updateElementValues() {
      await this.$store.dispatch('insuranceModule/builderElementsModule/updateValues')
    },
    async onResetBuilder() {
      //reset same builder
      this.showProcessPreloader();

      await this.resetInsuranceBuilders(this.emptyPriceBuilder);

      this.hideProcessPreloader();
    },
    async onResetBuilderWithoutRelations() {
      this.showProcessPreloader();

      await this.resetInsuranceBuilders(this.emptyPriceBuilder);
      await this.runBuilderRelations([
        builderElementRelationActionsEnum.SET_SUM_BY_FIELDS,
        builderElementRelationActionsEnum.SET_VALIDATION_BY_SUM,
        builderElementRelationActionsEnum.SET_VALIDATION_BY_SUM_MORE,
        builderElementRelationActionsEnum.SHOW,
        builderElementRelationActionsEnum.HIDE,
        builderElementRelationActionsEnum.REQUIRED,

        builderElementRelationActionsEnum.SET_DISABLED,
        builderElementRelationActionsEnum.SET_ENABLED,
      ]);

      this.hideProcessPreloader();
    },

    async resetInsuranceBuilders(insuranceBuilder) {
      await this.$store.dispatch('insuranceModule/insuranceBuildersModule/resetInsuranceBuilders', {
        insuranceBuilder,
      })
    },
    async runValidationRelations() {
      return await this.$store.dispatch('insuranceModule/builderElementRelationsModule/runValidationRelations')
    },
    async dataAcquisitionForModulation() {
      await this.$store.dispatch('insuranceModule/dataAcquisitionForModulation')
    },
    async emptyPrice() {
      await this.$store.dispatch('insuranceModule/emptyPrice')
    },
    sendXml() {
      this.$store.dispatch('insuranceModule/sendXml')
    },
    sendBigXml() {
      this.$store.dispatch('insuranceModule/sendBigXml')
    },
  }
}
</script>
