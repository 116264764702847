export const DATA_FIELD_KEYS = {
    managerName: 'managerName',
    managerNumber1: 'managerNumber1',
    managerNumber2: 'managerNumber2',
    foreignOrderFeature: 'foreignOrderFeature',
    consultantName: 'consultantName',
    consultantDate: 'consultantDate',
    wasSaleTargetMarket: 'wasSaleTargetMarket',
    wasSaleTargetMarketComment: 'wasSaleTargetMarketComment',
    isAvailableBrokerMandate: 'isAvailableBrokerMandate',

    sendDocPolice: 'sendDocPolice',
    sendDocInvoice: 'sendDocInvoice',
    sendDocInsuranceConfirmation: 'sendDocInsuranceConfirmation',

    paymentOption: 'paymentOption',

    customerEmail: 'customerEmail',
    managerEmail: 'managerEmail',

    phoneSaleFirstName: 'phoneSaleFirstName',
    phoneSaleLastName: 'phoneSaleLastName',
    phoneSaleDate: 'phoneSaleDate',
    phoneSaleTime: 'phoneSaleTime',

    infoPackEmail: 'infoPackEmail',

    electronicSignatureSubmittedAt: 'electronicSignatureSubmittedAt',

    confirmationClick: 'confirmationClick',
    electronicSignature: 'electronicSignature',

    notes: 'notes',
}