<template>
  <div>
    <p class="app-text__bold">Background</p>

    <select-field
        name="name"
        :items="colors"
        :value="value"
        @saveField="saveBackground"
    />
  </div>
</template>
<script>
import SelectField from "@/ui/components/common/fields/SelectField.vue";

export default {
  components: {SelectField},
  props: {
    value: {
      type: String,
      default: 'var(--white-bg)',
    },
  },
  computed: {
    colors() {
      return [
        {
          text: 'white',
          value: 'var(--white-bg)',
        },
        {
          text: 'transparent',
          value: 'transparent',
        },
        {
          text: 'gray',
          value: 'var(--smoke)',
        },
        {
          text: 'light-blue',
          value: 'var(--light-blue)',
        },
        {
          text: 'blue',
          value: '#003787',
        },
        {
          text: 'yellow',
          value: 'yellow',
        }
      ]
    },
  },
  methods: {
    saveBackground(value) {
      this.$emit('input', value)
      this.$emit('saveBackground', value);
    }
  }
}

</script>