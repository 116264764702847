import {builderElementService} from "@/utils/services/builder-element-service";

export const sumByFieldsCondition = ({condition_value, allBuilderElements}) => {
    const arr = condition_value.split('|');

    const elementNames = arr[0] ? arr[0].split(',') : [];
    const operation = arr[1] || '';
    const targetValue = arr[2] || 0;

    const builderElements = builderElementService.findAllElementsByParameters(allBuilderElements, 'name', elementNames)

    const sum = builderElements.reduce(
        (total, currentValue) =>  {
            const val = parseFloat(currentValue.value);

            if (!isNaN(val)) {
                return total + val;
            }

            return total;
        },
        0
    );

    switch (operation) {
        case '>' : return sum > +targetValue;
        case '<' : return sum < +targetValue;
        case '>=' : return sum >= +targetValue;
        case '<=' : return sum <= +targetValue;
        case '!=' : return sum !== +targetValue;
        default : return sum === +targetValue;
    }
}