import {localStorageService} from "@/utils/services/local-storage-service.js";
import {serverApi} from "@/api/server/server-api.js";
import {builderElementService} from "@/utils/services/builder-element-service.js";

export const builderElementsModule = {
    namespaced: true,

    state: {
        allBuilderElements: [],
    },

    getters: {
        getAllBuilderElements: (state) => state.allBuilderElements,
        getCopiedElements: (state) => (ids) => {
            return state.allBuilderElements.filter(itm => ids.includes(itm.id));
        }
    },

    mutations: {
        setBuilderElements: (state, elements) => {
            state.allBuilderElements = elements;
        },
    },

    actions: {
        resetBuilderElementsData: async (context) => {
            const elementsData = localStorageService.builderElement.getElements();
            const allElements = context.getters.getAllBuilderElements;

            if (elementsData) {
                elementsData.forEach((elemData) => {
                    const builderElement = builderElementService.findElementById(allElements, elemData.id)

                    if (builderElement) {
                        builderElement.fillData({...builderElement.toObject(), ...elemData,});
                    }
                })
            }

            localStorageService.builderElement.clearElements();
        },

        saveElement: (context, {builderElement}) => {
            localStorageService.builderElement.saveElement(builderElement)
        },

        multipleDuplicate:async (context, {builderElement, amount, titleBuilderElementName}) => {
            const {data} = await serverApi.builderElements.multipleDuplicate(builderElement.id, amount, titleBuilderElementName)

            return data.success;
        },

        deleteDuplicated:async (context, {builderElement}) => {
            const {data} = await serverApi.builderElements.deleteDuplicated(builderElement.id)

            return data?.deleted || false;
        },

        specialDuplicate:async (context, {copyFromElement, copyToElement, actionId}) => {
            const {data} = await serverApi.builderElements.specialDuplicate(copyFromElement.id, copyToElement.id, actionId)

            return data.success;
        },

        repeatByDuplicatedElement: async (context, {copyFromElement, copyToElement, actionId}) => {
            const {data} = await serverApi.builderElements.repeatByDuplicated(copyFromElement.id, copyToElement.id, actionId)

            return data.success;
        },

        specialCopyData:async (context, {copyFromElement, copyToElement, actionId}) => {
            const {data} = await serverApi.builderElements.specialCopyData(copyFromElement.id, copyToElement.id, actionId)

            return data.success;
        },

        repeatByBlock: async (context, {relationId, activeBuilderElementId, passiveBuilderElementId, amount}) => {
            const {data} = await serverApi.builderElements.repeatByBlock(relationId, activeBuilderElementId, passiveBuilderElementId, amount)

            return data.success;
        },

        repeatByCheckboxes: async (context, {
            repeatElementId,
            changeTitleElementId,
            checkboxElementIds,
            originalTitle,
            maxSelectedCheckboxesCount,
        }) => {
            const {data} = await serverApi.builderElements.repeatByCheckboxes({
                repeatElementId,
                changeTitleElementId,
                checkboxElementIds,
                originalTitle,
                maxSelectedCheckboxesCount
            });

            return data.success;
        },

        repeatValue: async (context, {activeBuilderElementId, passiveBuilderElementId, elementValue, }) => {
            const {data} = await serverApi.builderElements.repeatValue(activeBuilderElementId, passiveBuilderElementId, elementValue);

            if (data.success) {
                for (const [key, val] of Object.entries(data.repeatedElementsId)) {
                    localStorageService.builderElement.saveElement({id: key, value: val})
                }
            }

            return data.success;
        },

        updateValues: async () => {
            const values = localStorageService.builderElement.getElements()
                .filter((elem) => elem['value'] !== undefined)

            if (values.length) {
                return serverApi.builderElements.updateValues(values);
            }
        },

        updateVisibility: async () => {
            const values = localStorageService.builderElement.getElements()
                .filter((elem) => elem['is_show'] !== undefined)

            if (values.length) {
                return serverApi.builderElements.updateVisibility(values)
            }
        },

        uploadFile: async (context, {builderElementId, file}) => {
            const {data} = await serverApi.builderElements.uploadFile(builderElementId, file);

            return data?.success || false
        },

        deleteFile: async (context, {builderElementId}) => {
            const {data} = await serverApi.builderElements.deleteFile(builderElementId);

            return data?.success || false
        },

        duplicate: async (context, {builderElementId}) => {
            const {data} = await serverApi.builderElements.duplicate(builderElementId)

            return data.success
        },

        removeDuplicate: async (context, {builderElementId}) => {
            const {data} = await serverApi.builderElements.removeDuplicate(builderElementId)

            return data.success;
        },

        updateValue: async (context, {builderId, builderData}) => {
            await serverApi.builderElements.updateValue(builderId, builderData);
        },

        updatePaymentPeriodBuilderElement: async (context, {insuranceId, value}) => {
            await serverApi.builderElements.updatePaymentPeriodBuilderElement(insuranceId, value)
        },

        updateDurationBuilderElement: async (context, {insuranceId, value}) => {
            await serverApi.builderElements.updateDurationBuilderElement(insuranceId, value)
        },

        setElementIdsToHide: async (context, {ids}) => {
            localStorageService.builderElement.setElementIdsToHide(ids);
        },

        setElementIdsToShow: async (context, {ids}) => {
            localStorageService.builderElement.setElementIdsToShow(ids);
        },
    }
}