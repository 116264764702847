<template>
  <div>
    <div>
      <p class="app-text__bold">Max Length</p>

      <number-field
        name="maxLength"
        :value="value"
        @saveField="saveMaxLength"
      />
    </div>
  </div>
</template>
<script>
import NumberField from "@/ui/components/common/fields/NumberField.vue";
export default {
  components: {NumberField},
  props: {
    value: {
      type: String,
      default: '0',
    },
  },
  methods: {
    saveMaxLength(value) {
      this.$emit('input', value)
      this.$emit('saveMaxLength', value)
    }
  }
}
</script>