<template>
  <simple-popup header="" @close="onClose">
    <center-block v-if="loading">
      <app-preloader/>
    </center-block>
    <div v-else>
      <div class="d-flex justify-space-between builder-element-sql-toolbar">
        <p class="mt-2 mr-4 app-text__bold">Sql Query for builder element</p>

        <div class="d-flex">
          <blue-btn v-if="sql.length !== 0" class="mr-2" @click="onClickCopy">Copy SQL</blue-btn>
        </div>
      </div>

      <div class="d-flex justify-space-between builder-element-sql-toolbar">
        <div class="d-flex">
          <div>
            <label>Target insurance type</label>
            <select-field
                name="insurance_type"
                :value="insuranceTypeId"
                :items="insuranceTypeSelectItems"
                @input="onChangeInsuranceType"
            />
          </div>
          <div>
            <label>Target builder</label>
            <select-field
                name="builder"
                :value="builderId"
                :items="insuranceBuilderSelectItems"
                @input="onChangeBuilderId"
            />
          </div>
        </div>
      </div>

      <text-area-field
          name="query"
          :rows="18"
          v-model="sql"
      />
    </div>

  </simple-popup>
</template>
<script>
import SimplePopup from "@/ui/components/common/popup/SimplePopup.vue";
import CenterBlock from "@/ui/components/common/blocks/CenterBlock.vue";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue";
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";
import {notificationService} from "@/utils/services/notification-service.js";
import SelectField from "@/ui/components/common/fields/SelectField";
import {BuilderElement} from "@/models/builder-element";

export default {
  components: {SelectField, TextAreaField, AppPreloader, BlueBtn, CenterBlock, SimplePopup},

  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    },
    insuranceTypes: {
      type: Array
    },
  },

  computed: {
    insuranceTypeSelectItems() {
      return this.insuranceTypes.map(item => {
        return {
          value: item.id,
          text: item.name,
        }
      })
    },

    insuranceBuilderSelectItems() {
      return this.insuranceBuilders.map(item => {
        return {
          value: item.builder.id,
          text: item.builder.name,
        }
      })
    }
  },

  data: () => ({
    loading: false,
    sql: '',
    insuranceTypeId: null,
    builderId: null,
    insuranceBuilders: [],
  }),

  methods: {
    async onChangeInsuranceType(value) {
      this.insuranceTypeId = value;

      this.loading = true;

      const insuranceType = this.insuranceTypes.find(item => item.id === this.insuranceTypeId);
      this.insuranceBuilders = await this.getInsuranceBuildersByType(insuranceType);
      this.sql = '';

      this.loading = false;
    },

    async getInsuranceBuildersByType(insuranceType) {
      return this.$store.dispatch('admin/insuranceTypeModule/buildersByInsuranceType', {
        insuranceType
      });
    },

    async getBuilderElementSql(builderElementId, newBuilderId) {
      return this.$store.dispatch('admin/builderEditorModule/getBuilderElementSql', {
        builderElementId,
        newBuilderId
      });
    },

    async onChangeBuilderId(value) {
      this.builderId = value;

      this.loading = true;
      this.sql = await this.getBuilderElementSql(this.builderElement.id, this.builderId)
      this.loading = false;
    },

    onClose() {
      this.$emit('close');
    },

    async onClickCopy() {
      this.copyText(this.sql)

      notificationService.success('Sql was copied')
    },

    copyText(text) {
      navigator.clipboard.writeText(text);
    },
  }
}
</script>
<style scoped>
  .builder-element-sql-toolbar {
    margin-bottom: 20px;
  }
</style>