export const cities = [
    {city: 'Aach', zip: '78267'},
    {city: 'Aach', zip: '54298'},
    {city: 'Aachen', zip: '52062'},
    {city: 'Aachen', zip: '52064'},
    {city: 'Aachen', zip: '52066'},
    {city: 'Aachen', zip: '52068'},
    {city: 'Aachen', zip: '52070'},
    {city: 'Aachen', zip: '52072'},
    {city: 'Aachen', zip: '52074'},
    {city: 'Aachen', zip: '52076'},
    {city: 'Aachen', zip: '52078'},
    {city: 'Aachen', zip: '52080'},
    {city: 'Aalen', zip: '73430'},
    {city: 'Aalen', zip: '73431'},
    {city: 'Aalen', zip: '73432'},
    {city: 'Aalen', zip: '73433'},
    {city: 'Aalen', zip: '73434'},
    {city: 'Aarbergen', zip: '65326'},
    {city: 'Aasbüttel', zip: '25560'},
    {city: 'Abenberg', zip: '91183'},
    {city: 'Abensberg', zip: '93326'},
    {city: 'Abentheuer', zip: '55767'},
    {city: 'Absberg', zip: '91720'},
    {city: 'Abstatt', zip: '74232'},
    {city: 'Abtsbessingen', zip: '99713'},
    {city: 'Abtsgmünd', zip: '73453'},
    {city: 'Abtsteinach', zip: '69518'},
    {city: 'Abtswind', zip: '97355'},
    {city: 'Abtweiler', zip: '55568'},
    {city: 'Achberg', zip: '88147'},
    {city: 'Achern', zip: '77855'},
    {city: 'Achim', zip: '28832'},
    {city: 'Achslach', zip: '94250'},
    {city: 'Achstetten', zip: '88480'},
    {city: 'Acht', zip: '56729'},
    {city: 'Achtelsbach', zip: '55767'},
    {city: 'Achterwehr', zip: '24239'},
    {city: 'Achtrup', zip: '25917'},
    {city: 'Adelberg', zip: '73099'},
    {city: 'Adelebsen', zip: '37139'},
    {city: 'Adelheidsdorf', zip: '29352'},
    {city: 'Adelmannsfelden', zip: '73486'},
    {city: 'Adelschlag', zip: '85111'},
    {city: 'Adelsdorf', zip: '91325'},
    {city: 'Adelsheim', zip: '74740'},
    {city: 'Adelshofen', zip: '91587'},
    {city: 'Adelshofen', zip: '82276'},
    {city: 'Adelsried', zip: '86477'},
    {city: 'Adelzhausen', zip: '86559'},
    {city: 'Adenau', zip: '53518'},
    {city: 'Adenbach', zip: '67742'},
    {city: 'Adenbüttel', zip: '38528'},
    {city: 'Adendorf', zip: '21365'},
    {city: 'Adlkofen', zip: '84166'},
    {city: 'Admannshagen-Bargeshagen', zip: '18211'},
    {city: 'Adorf/Vogtland', zip: '08626'},
    {city: 'Aebtissinwisch', zip: '25572'},
    {city: 'Aerzen', zip: '31855'},
    {city: 'Affalterbach', zip: '71563'},
    {city: 'Affing', zip: '86444'},
    {city: 'Affinghausen', zip: '27257'},
    {city: 'Affler', zip: '54689'},
    {city: 'Agathenburg', zip: '21684'},
    {city: 'Agethorst', zip: '25560'},
    {city: 'Aglasterhausen', zip: '74858'},
    {city: 'Aham', zip: '84168'},
    {city: 'Ahaus', zip: '48683'},
    {city: 'Ahausen', zip: '27367'},
    {city: 'Ahlbeck', zip: '17375'},
    {city: 'Ahlden', zip: '29693'},
    {city: 'Ahlefeld-Bistensee', zip: '24358'},
    {city: 'Ahlen', zip: '59227'},
    {city: 'Ahlen', zip: '59229'},
    {city: 'Ahlerstedt', zip: '21702'},
    {city: 'Ahlsdorf', zip: '06313'},
    {city: 'Ahlstädt', zip: '98553'},
    {city: 'Ahnatal', zip: '34292'},
    {city: 'Ahneby', zip: '24996'},
    {city: 'Ahnsbeck', zip: '29353'},
    {city: 'Ahnsen', zip: '31708'},
    {city: 'Aholfing', zip: '94345'},
    {city: 'Aholming', zip: '94527'},
    {city: 'Ahorn', zip: '74744'},
    {city: 'Ahorn', zip: '96482'},
    {city: 'Ahorntal', zip: '95491'},
    {city: 'Ahrbrück', zip: '53506'},
    {city: 'Ahrensbök', zip: '23623'},
    {city: 'Ahrensburg', zip: '22926'},
    {city: 'Ahrensfelde', zip: '16356'},
    {city: 'Ahrenshagen-Daskow', zip: '18320'},
    {city: 'Ahrenshöft', zip: '25853'},
    {city: 'Ahrenshoop', zip: '18347'},
    {city: 'Ahrenviöl', zip: '25885'},
    {city: 'Ahrenviölfeld', zip: '24896'},
    {city: 'Ahrenviölfeld', zip: '25885'},
    {city: 'Aichach', zip: '86551'},
    {city: 'Aicha vorm Wald', zip: '94529'},
    {city: 'Aichelberg', zip: '73101'},
    {city: 'Aichen', zip: '86479'},
    {city: 'Aichhalden', zip: '78733'},
    {city: 'Aichstetten', zip: '88317'},
    {city: 'Aichtal', zip: '72631'},
    {city: 'Aichwald', zip: '73773'},
    {city: 'Aidenbach', zip: '94501'},
    {city: 'Aidhausen', zip: '97491'},
    {city: 'Aidlingen', zip: '71134'},
    {city: 'Aiglsbach', zip: '84089'},
    {city: 'Ailertchen', zip: '56459'},
    {city: 'Aindling', zip: '86447'},
    {city: 'Ainring', zip: '83404'},
    {city: 'Aislingen', zip: '89344'},
    {city: 'Aiterhofen', zip: '94330'},
    {city: 'Aitern', zip: '79677'},
    {city: 'Aitrach', zip: '88319'},
    {city: 'Aitrang', zip: '87648'},
    {city: 'Aken (Elbe)', zip: '06385'},
    {city: 'Aken (Elbe)', zip: '06386'},
    {city: 'Aland', zip: '39615'},
    {city: 'Albaching', zip: '83544'},
    {city: 'Albbruck', zip: '79774'},
    {city: 'Albersdorf', zip: '25767'},
    {city: 'Albersdorf', zip: '07646'},
    {city: 'Albershausen', zip: '73095'},
    {city: 'Albersweiler', zip: '76857'},
    {city: 'Albertshofen', zip: '97320'},
    {city: 'Albessen', zip: '66871'},
    {city: 'Albig', zip: '55234'},
    {city: 'Albisheim (Pfrimm)', zip: '67308'},
    {city: 'Albsfelde', zip: '23909'},
    {city: 'Albstadt', zip: '72458'},
    {city: 'Albstadt', zip: '72459'},
    {city: 'Albstadt', zip: '72461'},
    {city: 'Aldenhoven', zip: '52457'},
    {city: 'Aldersbach', zip: '94501'},
    {city: 'Aldingen', zip: '78554'},
    {city: 'Alerheim', zip: '86733'},
    {city: 'Alesheim', zip: '91793'},
    {city: 'Aletshausen', zip: '86480'},
    {city: 'Alf', zip: '56859'},
    {city: 'Alfdorf', zip: '73553'},
    {city: 'Alfdorf', zip: '73655'},
    {city: 'Alfeld', zip: '91236'},
    {city: 'Alfeld (Leine)', zip: '31061'},
    {city: 'Alfhausen', zip: '49594'},
    {city: 'Alflen', zip: '56828'},
    {city: 'Alfstedt', zip: '27432'},
    {city: 'Alfter', zip: '53347'},
    {city: 'Algermissen', zip: '31191'},
    {city: 'Alheim', zip: '36211'},
    {city: 'Alken', zip: '56332'},
    {city: 'Alkersleben', zip: '99310'},
    {city: 'Alkersum', zip: '25938'},
    {city: 'Allenbach', zip: '55758'},
    {city: 'Allendorf', zip: '35108'},
    {city: 'Allendorf', zip: '56370'},
    {city: 'Allendorf', zip: '07426'},
    {city: 'Allendorf (Lumda)', zip: '35469'},
    {city: 'Allenfeld', zip: '55595'},
    {city: 'Allensbach', zip: '78476'},
    {city: 'Allersberg', zip: '90584'},
    {city: 'Allershausen', zip: '85391'},
    {city: 'Alleshausen', zip: '88422'},
    {city: 'Alling', zip: '82239'},
    {city: 'Allmannshofen', zip: '86695'},
    {city: 'Allmannsweiler', zip: '88348'},
    {city: 'Allmendingen', zip: '89604'},
    {city: 'Allmersbach im Tal', zip: '71573'},
    {city: 'Allstedt', zip: '06542'},
    {city: 'Almdorf', zip: '25821'},
    {city: 'Almersbach', zip: '57610'},
    {city: 'Alpen', zip: '46519'},
    {city: 'Alpenrod', zip: '57642'},
    {city: 'Alpenrod', zip: '57647'},
    {city: 'Alperstedt', zip: '99195'},
    {city: 'Alpirsbach', zip: '72275'},
    {city: 'Alsbach', zip: '56237'},
    {city: 'Alsbach-Hähnlein', zip: '64665'},
    {city: 'Alsdorf', zip: '52477'},
    {city: 'Alsdorf', zip: '57518'},
    {city: 'Alsdorf', zip: '54668'},
    {city: 'Alsenz', zip: '67821'},
    {city: 'Alsfeld', zip: '36304'},
    {city: 'Alsheim', zip: '67577'},
    {city: 'Alsleben', zip: '06425'},
    {city: 'Altbach', zip: '73776'},
    {city: 'Alt Bennebek', zip: '24848'},
    {city: 'Alt Bukow', zip: '18233'},
    {city: 'Altdöbern', zip: '03229'},
    {city: 'Altdorf', zip: '84032'},
    {city: 'Altdorf', zip: '71155'},
    {city: 'Altdorf', zip: '72655'},
    {city: 'Altdorf', zip: '67482'},
    {city: 'Altdorf bei Nürnberg', zip: '90518'},
    {city: 'Alt Duvenstedt', zip: '24782'},
    {city: 'Alt Duvenstedt', zip: '24791'},
    {city: 'Alt Duvenstedt', zip: '24811'},
    {city: 'Altefähr', zip: '18573'},
    {city: 'Alteglofsheim', zip: '93087'},
    {city: 'Altena', zip: '58762'},
    {city: 'Altenahr', zip: '53505'},
    {city: 'Altenbamberg', zip: '55585'},
    {city: 'Altenbeken', zip: '33184'},
    {city: 'Altenberg', zip: '01773'},
    {city: 'Altenberg', zip: '01778'},
    {city: 'Altenberga', zip: '07768'},
    {city: 'Altenberge', zip: '48341'},
    {city: 'Altenbeuthen', zip: '07338'},
    {city: 'Altenbuch', zip: '97901'},
    {city: 'Altenburg', zip: '04600'},
    {city: 'Altendiez', zip: '65624'},
    {city: 'Altendorf', zip: '92540'},
    {city: 'Altendorf', zip: '96146'},
    {city: 'Altenglan', zip: '66885'},
    {city: 'Altenhagen', zip: '17091'},
    {city: 'Altenhausen', zip: '39343'},
    {city: 'Altenhof', zip: '24340'},
    {city: 'Altenhof', zip: '17209'},
    {city: 'Altenholz', zip: '24161'},
    {city: 'Altenkirchen', zip: '66903'},
    {city: 'Altenkirchen', zip: '18556'},
    {city: 'Altenkirchen (Westerwald)', zip: '57610'},
    {city: 'Altenkrempe', zip: '23730'},
    {city: 'Altenkunstadt', zip: '96264'},
    {city: 'Altenmarkt an der Alz', zip: '83352'},
    {city: 'Altenmedingen', zip: '29575'},
    {city: 'Altenmoor', zip: '25335'},
    {city: 'Altenmünster', zip: '86450'},
    {city: 'Altenpleen', zip: '18445'},
    {city: 'Altenriet', zip: '72657'},
    {city: 'Altenstadt', zip: '63674'},
    {city: 'Altenstadt', zip: '86972'},
    {city: 'Altenstadt', zip: '89281'},
    {city: 'Altenstadt an der Waldnaab', zip: '92665'},
    {city: 'Altensteig', zip: '72213'},
    {city: 'Altenthann', zip: '93177'},
    {city: 'Altentreptow', zip: '17087'},
    {city: 'Alterkülz', zip: '56288'},
    {city: 'Altertheim', zip: '97237'},
    {city: 'Altfraunhofen', zip: '84169'},
    {city: 'Althegnenberg', zip: '82278'},
    {city: 'Altheim', zip: '88499'},
    {city: 'Altheim', zip: '89605'},
    {city: 'Altheim (Alb)', zip: '89174'},
    {city: 'Althengstett', zip: '75382'},
    {city: 'Althornbach', zip: '66484'},
    {city: 'Althütte', zip: '71566'},
    {city: 'Althüttendorf', zip: '16247'},
    {city: 'Altkalen', zip: '17179'},
    {city: 'Alt Krenzlin', zip: '19288'},
    {city: 'Altlandsberg', zip: '15345'},
    {city: 'Altlay', zip: '56858'},
    {city: 'Altleiningen', zip: '67316'},
    {city: 'Altleiningen', zip: '67317'},
    {city: 'Altlußheim', zip: '68804'},
    {city: 'Altmannstein', zip: '93336'},
    {city: 'Altmärkische Höhe', zip: '39606'},
    {city: 'Altmärkische Höhe', zip: '39615'},
    {city: 'Altmärkische Wische', zip: '39615'},
    {city: 'Alt Meteln', zip: '19069'},
    {city: 'Altmittweida', zip: '09648'},
    {city: 'Alt Mölln', zip: '23881'},
    {city: 'Altomünster', zip: '85250'},
    {city: 'Altötting', zip: '84503'},
    {city: 'Altrich', zip: '54518'},
    {city: 'Altrip', zip: '67122'},
    {city: 'Altscheid', zip: '54636'},
    {city: 'Alt Schwerin', zip: '17214'},
    {city: 'Altshausen', zip: '88361'},
    {city: 'Altstrimmig', zip: '56858'},
    {city: 'Alt Sührkow', zip: '17166'},
    {city: 'Alt Tellin', zip: '17129'},
    {city: 'Alt Tucheband', zip: '15328'},
    {city: 'Altusried', zip: '87452'},
    {city: 'Altwarp', zip: '17375'},
    {city: 'Altweidelbach', zip: '55469'},
    {city: 'Altwigshagen', zip: '17379'},
    {city: 'Alt Zachun', zip: '19230'},
    {city: 'Alt Zauche-Wußwerk', zip: '15913'},
    {city: 'Alveslohe', zip: '25486'},
    {city: 'Alzenau', zip: '63755'},
    {city: 'Alzey', zip: '55232'},
    {city: 'Amberg', zip: '92224'},
    {city: 'Amberg', zip: '86854'},
    {city: 'Amelinghausen', zip: '21385'},
    {city: 'Amerang', zip: '83123'},
    {city: 'Amerdingen', zip: '86735'},
    {city: 'Am Ettersberg', zip: '99439'},
    {city: 'Am Großen Bruch', zip: '39393'},
    {city: 'Ammeldingen an der Our', zip: '54675'},
    {city: 'Ammeldingen bei Neuerburg', zip: '54673'},
    {city: 'Am Mellensee', zip: '15838'},
    {city: 'Ammerbuch', zip: '72119'},
    {city: 'Ammerndorf', zip: '90614'},
    {city: 'Ammersbek', zip: '22949'},
    {city: 'Ammerthal', zip: '92260'},
    {city: 'Am Ohmberg', zip: '37345'},
    {city: 'Amöneburg', zip: '35287'},
    {city: 'Amorbach', zip: '63916'},
    {city: 'Ampfing', zip: '84539'},
    {city: 'Am Salzhaff', zip: '18233'},
    {city: 'Amstetten', zip: '73340'},
    {city: 'Amt Creuzburg', zip: '99831'},
    {city: 'Amt Neuhaus', zip: '19273'},
    {city: 'Amtsberg', zip: '09439'},
    {city: 'Amt Wachsenburg', zip: '99102'},
    {city: 'Amt Wachsenburg', zip: '99334'},
    {city: 'Amtzell', zip: '88279'},
    {city: 'Andechs', zip: '82346'},
    {city: 'Anderlingen', zip: '27446'},
    {city: 'Andernach', zip: '56626'},
    {city: 'An der Poststraße', zip: '06647'},
    {city: 'An der Schmücke', zip: '06577'},
    {city: 'Andervenne', zip: '49832'},
    {city: 'Andisleben', zip: '99189'},
    {city: 'Angelbachtal', zip: '74918'},
    {city: 'Angelburg', zip: '35719'},
    {city: 'Anger', zip: '83454'},
    {city: 'Angermünde', zip: '16278'},
    {city: 'Angern', zip: '39326'},
    {city: 'Angern', zip: '39517'},
    {city: 'Anhausen', zip: '56584'},
    {city: 'Ankershagen', zip: '17219'},
    {city: 'Anklam', zip: '17389'},
    {city: 'Ankum', zip: '49577'},
    {city: 'Annaberg-Buchholz', zip: '09456'},
    {city: 'Annaburg', zip: '06925'},
    {city: 'Annweiler am Trifels', zip: '76855'},
    {city: 'Anröchte', zip: '59609'},
    {city: 'Anrode', zip: '99976'},
    {city: 'Ansbach', zip: '91522'},
    {city: 'Anschau', zip: '56729'},
    {city: 'Antdorf', zip: '82387'},
    {city: 'Antrifttal', zip: '36326'},
    {city: 'Antweiler', zip: '53533'},
    {city: 'Anzing', zip: '85646'},
    {city: 'Apelern', zip: '31552'},
    {city: 'Apen', zip: '26689'},
    {city: 'Apenburg-Winterfeld', zip: '38486'},
    {city: 'Apensen', zip: '21641'},
    {city: 'Apfeldorf', zip: '86974'},
    {city: 'Apfeltrach', zip: '87742'},
    {city: 'Apolda', zip: '99510'},
    {city: 'Appel', zip: '21279'},
    {city: 'Appen', zip: '25482'},
    {city: 'Appenheim', zip: '55437'},
    {city: 'Appenweier', zip: '77767'},
    {city: 'Arbach', zip: '56769'},
    {city: 'Arberg', zip: '91722'},
    {city: 'Aremberg', zip: '53533'},
    {city: 'Arendsee (Altmark)', zip: '39619'},
    {city: 'Arenrath', zip: '54518'},
    {city: 'Arenshausen', zip: '37318'},
    {city: 'Aresing', zip: '86561'},
    {city: 'Arft', zip: '56729'},
    {city: 'Argenbühl', zip: '88260'},
    {city: 'Argenschwang', zip: '55595'},
    {city: 'Argenthal', zip: '55496'},
    {city: 'Arholzen', zip: '37627'},
    {city: 'Arkebek', zip: '25767'},
    {city: 'Arlewatt', zip: '25860'},
    {city: 'Armsheim', zip: '55288'},
    {city: 'Armstedt', zip: '24616'},
    {city: 'Armstorf', zip: '21769'},
    {city: 'Arnbruck', zip: '93471'},
    {city: 'Arneburg', zip: '39596'},
    {city: 'Arnis', zip: '24399'},
    {city: 'Arnsberg', zip: '59755'},
    {city: 'Arnsberg', zip: '59757'},
    {city: 'Arnsberg', zip: '59759'},
    {city: 'Arnsberg', zip: '59821'},
    {city: 'Arnsberg', zip: '59823'},
    {city: 'Arnschwang', zip: '93473'},
    {city: 'Arnsdorf', zip: '01477'},
    {city: 'Arnshöfen', zip: '56244'},
    {city: 'Arnstadt', zip: '99310'},
    {city: 'Arnstein', zip: '97450'},
    {city: 'Arnstein', zip: '06456'},
    {city: 'Arnstorf', zip: '94424'},
    {city: 'Arpsdorf', zip: '24634'},
    {city: 'Arrach', zip: '93474'},
    {city: 'Artern', zip: '06556'},
    {city: 'Artlenburg', zip: '21380'},
    {city: 'Arzbach', zip: '56337'},
    {city: 'Arzberg', zip: '04886'},
    {city: 'Arzberg', zip: '95659'},
    {city: 'Arzfeld', zip: '54687'},
    {city: 'Asbach', zip: '53567'},
    {city: 'Asbach', zip: '55758'},
    {city: 'Asbach-Bäumenheim', zip: '86663'},
    {city: 'Asbach-Sickenberg', zip: '37318'},
    {city: 'Ascha', zip: '94347'},
    {city: 'Aschaffenburg', zip: '63739'},
    {city: 'Aschaffenburg', zip: '63741'},
    {city: 'Aschaffenburg', zip: '63743'},
    {city: 'Aschau am Inn', zip: '84544'},
    {city: 'Aschau im Chiemgau', zip: '83229'},
    {city: 'Aschbach', zip: '67753'},
    {city: 'Ascheberg', zip: '59387'},
    {city: 'Ascheberg', zip: '24326'},
    {city: 'Ascheffel', zip: '24358'},
    {city: 'Aschersleben', zip: '06449'},
    {city: 'Aschheim', zip: '85609'},
    {city: 'Asendorf', zip: '21271'},
    {city: 'Asendorf', zip: '27330'},
    {city: 'Aspach', zip: '71546'},
    {city: 'Asperg', zip: '71679'},
    {city: 'Aspisheim', zip: '55459'},
    {city: 'Assamstadt', zip: '97959'},
    {city: 'Asselfingen', zip: '89176'},
    {city: 'Aßlar', zip: '35614'},
    {city: 'Aßling', zip: '85617'},
    {city: 'Astert', zip: '57627'},
    {city: 'Attendorn', zip: '57439'},
    {city: 'Attenhausen', zip: '56370'},
    {city: 'Attenhofen', zip: '84091'},
    {city: 'Attenkirchen', zip: '85395'},
    {city: 'Attenweiler', zip: '88448'},
    {city: 'Atting', zip: '94348'},
    {city: 'Atzelgift', zip: '57629'},
    {city: 'Au', zip: '79280'},
    {city: 'Au am Rhein', zip: '76474'},
    {city: 'Aub', zip: '97239'},
    {city: 'Aubstadt', zip: '97633'},
    {city: 'Auderath', zip: '56766'},
    {city: 'Aue-Bad Schlema', zip: '08280'},
    {city: 'Aue-Bad Schlema', zip: '08301'},
    {city: 'Auel', zip: '56357'},
    {city: 'Auen', zip: '55569'},
    {city: 'Auengrund', zip: '98673'},
    {city: 'Auenwald', zip: '71549'},
    {city: 'Auerbach', zip: '09392'},
    {city: 'Auerbach', zip: '94530'},
    {city: 'Auerbach in der Oberpfalz', zip: '91275'},
    {city: 'Auerbach/Vogtland', zip: '08209'},
    {city: 'Auetal', zip: '31749'},
    {city: 'Aufhausen', zip: '93089'},
    {city: 'Aufseß', zip: '91347'},
    {city: 'Auggen', zip: '79424'},
    {city: 'Augsburg', zip: '86150'},
    {city: 'Augsburg', zip: '86152'},
    {city: 'Augsburg', zip: '86153'},
    {city: 'Augsburg', zip: '86154'},
    {city: 'Augsburg', zip: '86156'},
    {city: 'Augsburg', zip: '86157'},
    {city: 'Augsburg', zip: '86159'},
    {city: 'Augsburg', zip: '86161'},
    {city: 'Augsburg', zip: '86163'},
    {city: 'Augsburg', zip: '86165'},
    {city: 'Augsburg', zip: '86167'},
    {city: 'Augsburg', zip: '86169'},
    {city: 'Augsburg', zip: '86179'},
    {city: 'Augsburg', zip: '86199'},
    {city: 'Augustdorf', zip: '32832'},
    {city: 'Augustusburg', zip: '09573'},
    {city: 'Auhagen', zip: '31553'},
    {city: 'Auhausen', zip: '86736'},
    {city: 'Au in der Hallertau', zip: '84072'},
    {city: 'Aukrug', zip: '24613'},
    {city: 'Aulendorf', zip: '88326'},
    {city: 'Aull', zip: '65582'},
    {city: 'Auma-Weidatal', zip: '07955'},
    {city: 'Aumühle', zip: '21521'},
    {city: 'Aura a.d.Saale', zip: '97717'},
    {city: 'Aurach', zip: '91589'},
    {city: 'Aurachtal', zip: '91086'},
    {city: 'Aura im Sinngrund', zip: '97773'},
    {city: 'Aurich', zip: '26603'},
    {city: 'Aurich', zip: '26605'},
    {city: 'Aurich', zip: '26607'},
    {city: 'Ausacker', zip: '24975'},
    {city: 'Ausleben', zip: '39393'},
    {city: 'Außernzell', zip: '94532'},
    {city: 'Auufer', zip: '25548'},
    {city: 'Auw an der Kyll', zip: '54664'},
    {city: 'Auw bei Prüm', zip: '54597'},
    {city: 'Aventoft', zip: '25927'},
    {city: 'Averlak', zip: '25715'},
    {city: 'Axstedt', zip: '27729'},
    {city: 'Aying', zip: '85653'},
    {city: 'Ayl', zip: '54441'},
    {city: 'Aystetten', zip: '86482'},
    {city: 'Baabe', zip: '18586'},
    {city: 'Baar', zip: '86674'},
    {city: 'Baar', zip: '56729'},
    {city: 'Baar-Ebenhausen', zip: '85107'},
    {city: 'Babenhausen', zip: '64832'},
    {city: 'Babenhausen', zip: '87727'},
    {city: 'Babensham', zip: '83547'},
    {city: 'Bach an der Donau', zip: '93090'},
    {city: 'Bacharach', zip: '55422'},
    {city: 'Bachenberg', zip: '57610'},
    {city: 'Bachhagel', zip: '89429'},
    {city: 'Bächingen a.d.Brenz', zip: '89431'},
    {city: 'Backnang', zip: '71522'},
    {city: 'Bad Abbach', zip: '93077'},
    {city: 'Bad Aibling', zip: '83043'},
    {city: 'Bad Alexandersbad', zip: '95680'},
    {city: 'Bad Arolsen', zip: '34454'},
    {city: 'Bad Bayersoien', zip: '82435'},
    {city: 'Bad Bellingen', zip: '79415'},
    {city: 'Bad Belzig', zip: '14806'},
    {city: 'Bad Bentheim', zip: '48455'},
    {city: 'Badbergen', zip: '49635'},
    {city: 'Bad Bergzabern', zip: '76887'},
    {city: 'Bad Berka', zip: '99438'},
    {city: 'Bad Berleburg', zip: '57319'},
    {city: 'Bad Berneck im Fichtelgebirge', zip: '95460'},
    {city: 'Bad Bertrich', zip: '56864'},
    {city: 'Bad Bevensen', zip: '29549'},
    {city: 'Bad Bibra', zip: '06647'},
    {city: 'Bad Birnbach', zip: '84364'},
    {city: 'Bad Blankenburg', zip: '07422'},
    {city: 'Bad Bocklet', zip: '97708'},
    {city: 'Bad Bodenteich', zip: '29389'},
    {city: 'Bad Boll', zip: '73087'},
    {city: 'Bad Brambach', zip: '08648'},
    {city: 'Bad Bramstedt', zip: '24576'},
    {city: 'Bad Breisig', zip: '53498'},
    {city: 'Bad Brückenau', zip: '97769'},
    {city: 'Bad Buchau', zip: '88422'},
    {city: 'Bad Camberg', zip: '65520'},
    {city: 'Baddeckenstedt', zip: '38271'},
    {city: 'Bad Ditzenbach', zip: '73312'},
    {city: 'Bad Ditzenbach', zip: '73342'},
    {city: 'Bad Ditzenbach', zip: '73345'},
    {city: 'Bad Doberan', zip: '18209'},
    {city: 'Bad Driburg', zip: '33014'},
    {city: 'Bad Düben', zip: '04849'},
    {city: 'Authausen Gem. Laußig', zip: '04849'},
    {city: 'Kossa Gem. Laußig', zip: '04849'},
    {city: 'Pressel Gem. Laußig', zip: '04849'},
    {city: 'Bad Dürkheim', zip: '67098'},
    {city: 'Bad Dürrenberg', zip: '06231'},
    {city: 'Bad Dürrheim', zip: '78073'},
    {city: 'Bad Eilsen', zip: '31707'},
    {city: 'Bad Elster', zip: '08645'},
    {city: 'Badem', zip: '54657'},
    {city: 'Bad Ems', zip: '56130'},
    {city: 'Bad Emstal', zip: '34308'},
    {city: 'Baden-Baden', zip: '76530'},
    {city: 'Baden-Baden', zip: '76532'},
    {city: 'Baden-Baden', zip: '76534'},
    {city: 'Bad Endbach', zip: '35080'},
    {city: 'Badendorf', zip: '23619'},
    {city: 'Bad Endorf', zip: '83093'},
    {city: 'Badenhard', zip: '56291'},
    {city: 'Badenheim', zip: '55576'},
    {city: 'Badenweiler', zip: '79410'},
    {city: 'Bad Essen', zip: '49152'},
    {city: 'Bad Fallingbostel', zip: '29683'},
    {city: 'Bad Feilnbach', zip: '83075'},
    {city: 'Bad Frankenhausen/Kyffhäuser', zip: '06567'},
    {city: 'Bad Freienwalde (Oder)', zip: '16259'},
    {city: 'Bad Friedrichshall', zip: '74177'},
    {city: 'Bad Füssing', zip: '94072'},
    {city: 'Bad Gandersheim', zip: '37581'},
    {city: 'Bad Gottleuba-Berggießhübel', zip: '01816'},
    {city: 'Bad Griesbach i.Rottal', zip: '94086'},
    {city: 'Bad Grönenbach', zip: '87730'},
    {city: 'Bad Grund', zip: '37539'},
    {city: 'Bad Harzburg', zip: '38667'},
    {city: 'Bad Heilbrunn', zip: '83670'},
    {city: 'Bad Herrenalb', zip: '76332'},
    {city: 'Bad Hersfeld', zip: '36251'},
    {city: 'Bad Hindelang', zip: '87541'},
    {city: 'Bad Homburg vor der Höhe', zip: '61348'},
    {city: 'Bad Homburg vor der Höhe', zip: '61350'},
    {city: 'Bad Homburg vor der Höhe', zip: '61352'},
    {city: 'Bad Honnef', zip: '53604'},
    {city: 'Bad Hönningen', zip: '53547'},
    {city: 'Bad Hönningen', zip: '53557'},
    {city: 'Bad Iburg', zip: '49186'},
    {city: 'Bad Karlshafen', zip: '34385'},
    {city: 'Bad Kissingen', zip: '97688'},
    {city: 'Bad Kleinen', zip: '23996'},
    {city: 'Bad Klosterlausnitz', zip: '07639'},
    {city: 'Bad Kohlgrub', zip: '82433'},
    {city: 'Bad König', zip: '64732'},
    {city: 'Bad Königshofen im Grabfeld', zip: '97631'},
    {city: 'Bad Köstritz', zip: '07586'},
    {city: 'Bad Kötzting', zip: '93444'},
    {city: 'Bad Kreuznach', zip: '55543'},
    {city: 'Bad Kreuznach', zip: '55545'},
    {city: 'Bad Kreuznach', zip: '55583'},
    {city: 'Bad Krozingen', zip: '79189'},
    {city: 'Bad Laasphe', zip: '57334'},
    {city: 'Bad Laer', zip: '49196'},
    {city: 'Bad Langensalza', zip: '99947'},
    {city: 'Bad Lausick', zip: '04651'},
    {city: 'Bad Lauterberg', zip: '37431'},
    {city: 'Bad Liebenstein', zip: '36448'},
    {city: 'Bad Liebenwerda', zip: '04895'},
    {city: 'Bad Liebenwerda', zip: '04924'},
    {city: 'Bad Liebenwerda', zip: '04931'},
    {city: 'Bad Liebenzell', zip: '75378'},
    {city: 'Bad Lippspringe', zip: '33175'},
    {city: 'Bad Lobenstein', zip: '07356'},
    {city: 'Bad Marienberg (Westerwald)', zip: '56470'},
    {city: 'Bad Mergentheim', zip: '97980'},
    {city: 'Bad Münder am Deister', zip: '31848'},
    {city: 'Bad Münstereifel', zip: '53902'},
    {city: 'Bad Muskau', zip: '02953'},
    {city: 'Bad Nauheim', zip: '61231'},
    {city: 'Bad Nenndorf', zip: '31542'},
    {city: 'Bad Neuenahr-Ahrweiler', zip: '53474'},
    {city: 'Bad Neustadt an der Saale', zip: '97616'},
    {city: 'Bad Oeynhausen', zip: '32545'},
    {city: 'Bad Oeynhausen', zip: '32547'},
    {city: 'Bad Oeynhausen', zip: '32549'},
    {city: 'Bad Oldesloe', zip: '23843'},
    {city: 'Bad Orb', zip: '63619'},
    {city: 'Bad Peterstal-Griesbach', zip: '72250'},
    {city: 'Bad Peterstal-Griesbach', zip: '77740'},
    {city: 'Bad Pyrmont', zip: '31812'},
    {city: 'Bad Rappenau', zip: '74906'},
    {city: 'Bad Reichenhall', zip: '83435'},
    {city: 'Bad Rippoldsau-Schapbach', zip: '77776'},
    {city: 'Bad Rodach', zip: '96476'},
    {city: 'Bad Rothenfelde', zip: '49214'},
    {city: 'Bad Saarow', zip: '15526'},
    {city: 'Bad Sachsa', zip: '37441'},
    {city: 'Bad Säckingen', zip: '79713'},
    {city: 'Bad Salzdetfurth', zip: '31162'},
    {city: 'Bad Salzschlirf', zip: '36364'},
    {city: 'Bad Salzuflen', zip: '32105'},
    {city: 'Bad Salzuflen', zip: '32107'},
    {city: 'Bad Salzuflen', zip: '32108'},
    {city: 'Bad Salzungen', zip: '36433'},
    {city: 'Bad Salzungen', zip: '36469'},
    {city: 'Bad Sassendorf', zip: '59505'},
    {city: 'Bad Saulgau', zip: '88348'},
    {city: 'Bad Schandau', zip: '01814'},
    {city: 'Bad Schmiedeberg', zip: '06905'},
    {city: 'Bad Schönborn', zip: '76669'},
    {city: 'Bad Schussenried', zip: '88427'},
    {city: 'Bad Schwalbach', zip: '65307'},
    {city: 'Bad Schwartau', zip: '23611'},
    {city: 'Bad Segeberg', zip: '23795'},
    {city: 'Bad Sobernheim', zip: '55566'},
    {city: 'Bad Soden', zip: '65812'},
    {city: 'Bad Soden-Salmünster', zip: '63628'},
    {city: 'Bad Sooden-Allendorf', zip: '37242'},
    {city: 'Bad Staffelstein', zip: '96231'},
    {city: 'Bad Steben', zip: '95138'},
    {city: 'Bad Sulza', zip: '99518'},
    {city: 'Bad Sülze', zip: '18334'},
    {city: 'Bad Tabarz', zip: '99891'},
    {city: 'Bad Teinach-Zavelstein', zip: '75385'},
    {city: 'Bad Tennstedt', zip: '99955'},
    {city: 'Bad Tölz', zip: '83646'},
    {city: 'Bad Überkingen', zip: '73337'},
    {city: 'Bad Urach', zip: '72574'},
    {city: 'Bad Vilbel', zip: '61118'},
    {city: 'Bad Waldsee', zip: '88339'},
    {city: 'Bad Wiessee', zip: '83707'},
    {city: 'Bad Wildbad', zip: '75323'},
    {city: 'Bad Wildungen', zip: '34537'},
    {city: 'Bad Wilsnack', zip: '19336'},
    {city: 'Bad Wimpfen', zip: '74206'},
    {city: 'Bad Windsheim', zip: '91438'},
    {city: 'Bad Wörishofen', zip: '86825'},
    {city: 'Bad Wünnenberg', zip: '33181'},
    {city: 'Bad Wurzach', zip: '88410'},
    {city: 'Bad Zwesten', zip: '34596'},
    {city: 'Bad Zwischenahn', zip: '26160'},
    {city: 'Baesweiler', zip: '52499'},
    {city: 'Bahlingen', zip: '79353'},
    {city: 'Bahrdorf', zip: '38459'},
    {city: 'Bahrenborstel', zip: '27245'},
    {city: 'Bahrenfleth', zip: '25569'},
    {city: 'Bahrenhof', zip: '23845'},
    {city: 'Bahretal', zip: '01819'},
    {city: 'Baienfurt', zip: '88255'},
    {city: 'Baierbach', zip: '84171'},
    {city: 'Baierbrunn', zip: '82065'},
    {city: 'Baiern', zip: '85625'},
    {city: 'Baiersbronn', zip: '72270'},
    {city: 'Baiersdorf', zip: '91083'},
    {city: 'Baindt', zip: '88255'},
    {city: 'Baisweil', zip: '87650'},
    {city: 'Bäk', zip: '23909'},
    {city: 'Bakum', zip: '49456'},
    {city: 'Bälau', zip: '23881'},
    {city: 'Balderschwang', zip: '87538'},
    {city: 'Baldringen', zip: '54314'},
    {city: 'Balduinstein', zip: '65558'},
    {city: 'Balesfeld', zip: '54597'},
    {city: 'Balge', zip: '31609'},
    {city: 'Balgheim', zip: '78582'},
    {city: 'Balgstädt', zip: '06632'},
    {city: 'Balingen', zip: '72336'},
    {city: 'Balje', zip: '21730'},
    {city: 'Ballendorf', zip: '89177'},
    {city: 'Ballenstedt', zip: '06493'},
    {city: 'Ballhausen', zip: '99955'},
    {city: 'Ballrechten-Dottingen', zip: '79282'},
    {city: 'Ballstedt', zip: '99439'},
    {city: 'Balow', zip: '19300'},
    {city: 'Baltmannsweiler', zip: '73666'},
    {city: 'Baltrum', zip: '26579'},
    {city: 'Balve', zip: '58802'},
    {city: 'Balzhausen', zip: '86483'},
    {city: 'Balzheim', zip: '88481'},
    {city: 'Bamberg', zip: '96047'},
    {city: 'Bamberg', zip: '96049'},
    {city: 'Bamberg', zip: '96050'},
    {city: 'Bamberg', zip: '96052'},
    {city: 'Bammental', zip: '69245'},
    {city: 'Bandelin', zip: '17506'},
    {city: 'Bandenitz', zip: '19230'},
    {city: 'Bann', zip: '66851'},
    {city: 'Bannberscheid', zip: '56424'},
    {city: 'Bannewitz', zip: '01728'},
    {city: 'Banzkow', zip: '19079'},
    {city: 'Barbelroth', zip: '76889'},
    {city: 'Barbing', zip: '93092'},
    {city: 'Barby', zip: '39240'},
    {city: 'Barby', zip: '39249'},
    {city: 'Barchfeld-Immelborn', zip: '36456'},
    {city: 'Bardowick', zip: '21357'},
    {city: 'Bärenbach', zip: '55483'},
    {city: 'Bärenbach', zip: '55758'},
    {city: 'Barenburg', zip: '27245'},
    {city: 'Barendorf', zip: '21397'},
    {city: 'Bärenstein', zip: '09471'},
    {city: 'Bärenthal', zip: '78580'},
    {city: 'Bargenstedt', zip: '25704'},
    {city: 'Bargfeld-Stegen', zip: '23863'},
    {city: 'Bargischow', zip: '17398'},
    {city: 'Bargstall', zip: '24806'},
    {city: 'Bargstedt', zip: '21698'},
    {city: 'Bargstedt', zip: '24793'},
    {city: 'Bargteheide', zip: '22941'},
    {city: 'Bargum', zip: '25842'},
    {city: 'Bark', zip: '23826'},
    {city: 'Barkelsby', zip: '24360'},
    {city: 'Barkenholm', zip: '25791'},
    {city: 'Barkhagen', zip: '19395'},
    {city: 'Barleben', zip: '39179'},
    {city: 'Barlt', zip: '25719'},
    {city: 'Barmissen', zip: '24245'},
    {city: 'Barmstedt', zip: '25355'},
    {city: 'Bärnau', zip: '95671'},
    {city: 'Barnekow', zip: '23968'},
    {city: 'Barnin', zip: '19089'},
    {city: 'Barnitz', zip: '23858'},
    {city: 'Barnstädt', zip: '06268'},
    {city: 'Barnstedt', zip: '21406'},
    {city: 'Barnstorf', zip: '49406'},
    {city: 'Barntrup', zip: '32683'},
    {city: 'Barsbek', zip: '24217'},
    {city: 'Barsbüttel', zip: '22885'},
    {city: 'Barsinghausen', zip: '30890'},
    {city: 'Barßel', zip: '26676'},
    {city: 'Bartenshagen-Parkentin', zip: '18209'},
    {city: 'Barth', zip: '18356'},
    {city: 'Bartholomä', zip: '73566'},
    {city: 'Bartow', zip: '17089'},
    {city: 'Barum', zip: '29576'},
    {city: 'Barum', zip: '21357'},
    {city: 'Baruth/Mark', zip: '15837'},
    {city: 'Barver', zip: '49453'},
    {city: 'Barwedel', zip: '38476'},
    {city: 'Barweiler', zip: '53534'},
    {city: 'Bärweiler', zip: '55606'},
    {city: 'Basberg', zip: '54578'},
    {city: 'Basdahl', zip: '27432'},
    {city: 'Basedow', zip: '17139'},
    {city: 'Basedow', zip: '21483'},
    {city: 'Bassenheim', zip: '56220'},
    {city: 'Bassum', zip: '27211'},
    {city: 'Bastheim', zip: '97654'},
    {city: 'Basthorst', zip: '21493'},
    {city: 'Bastorf', zip: '18230'},
    {city: 'Battenberg', zip: '35088'},
    {city: 'Battenberg (Pfalz)', zip: '67271'},
    {city: 'Battweiler', zip: '66484'},
    {city: 'Baudenbach', zip: '91460'},
    {city: 'Bauler', zip: '53534'},
    {city: 'Bauler', zip: '54673'},
    {city: 'Baumgarten', zip: '18246'},
    {city: 'Baumgarten', zip: '18249'},
    {city: 'Baumholder', zip: '55774'},
    {city: 'Baunach', zip: '96148'},
    {city: 'Baunatal', zip: '34225'},
    {city: 'Bausendorf', zip: '54538'},
    {city: 'Baustert', zip: '54636'},
    {city: 'Bautzen', zip: '02625'},
    {city: 'Bawinkel', zip: '49844'},
    {city: 'Bayerbach', zip: '94137'},
    {city: 'Bayerbach b. Ergoldsbach', zip: '84092'},
    {city: 'Bayerfeld-Steckweiler', zip: '67808'},
    {city: 'Bayerfeld-Steckweiler', zip: '67822'},
    {city: 'Bayerisch Eisenstein', zip: '94252'},
    {city: 'Bayerisch Gmain', zip: '83457'},
    {city: 'Bayreuth', zip: '95444'},
    {city: 'Bayreuth', zip: '95445'},
    {city: 'Bayreuth', zip: '95447'},
    {city: 'Bayreuth', zip: '95448'},
    {city: 'Bayrischzell', zip: '83735'},
    {city: 'Bebensee', zip: '23816'},
    {city: 'Bebra', zip: '36179'},
    {city: 'Becheln', zip: '56132'},
    {city: 'Bechenheim', zip: '55234'},
    {city: 'Becherbach', zip: '67827'},
    {city: 'Becherbach bei Kirn', zip: '55608'},
    {city: 'Bechhofen', zip: '91572'},
    {city: 'Bechhofen', zip: '66894'},
    {city: 'Bechstedt', zip: '07426'},
    {city: 'Bechtheim', zip: '67595'},
    {city: 'Bechtolsheim', zip: '55234'},
    {city: 'Bechtsrieth', zip: '92699'},
    {city: 'Beckdorf', zip: '21643'},
    {city: 'Beckedorf', zip: '31699'},
    {city: 'Beckeln', zip: '27243'},
    {city: 'Beckingen', zip: '66701'},
    {city: 'Beckum', zip: '59269'},
    {city: 'Bedburg', zip: '50181'},
    {city: 'Bedburg-Hau', zip: '47551'},
    {city: 'Bedesbach', zip: '66885'},
    {city: 'Beedenbostel', zip: '29355'},
    {city: 'Beelen', zip: '48361'},
    {city: 'Beelitz', zip: '14547'},
    {city: 'Beendorf', zip: '39343'},
    {city: 'Beeskow', zip: '15848'},
    {city: 'Beesten', zip: '49832'},
    {city: 'Beetzendorf', zip: '38489'},
    {city: 'Beetzsee', zip: '14778'},
    {city: 'Beetzseeheide', zip: '14778'},
    {city: 'Beggerow', zip: '17111'},
    {city: 'Behlendorf', zip: '23919'},
    {city: 'Behrendorf', zip: '25850'},
    {city: 'Behrenhoff', zip: '17498'},
    {city: 'Behren-Lübchin', zip: '17179'},
    {city: 'Behrensdorf', zip: '24321'},
    {city: 'Beidenfleth', zip: '25573'},
    {city: 'Beiersdorf', zip: '02736'},
    {city: 'Beiersdorf-Freudenberg', zip: '16259'},
    {city: 'Beierstedt', zip: '38382'},
    {city: 'Beilingen', zip: '54662'},
    {city: 'Beilngries', zip: '92339'},
    {city: 'Beilrode', zip: '04886'},
    {city: 'Beilstein', zip: '71543'},
    {city: 'Beilstein', zip: '71717'},
    {city: 'Beilstein', zip: '56814'},
    {city: 'Beimerstetten', zip: '89179'},
    {city: 'Beindersheim', zip: '67259'},
    {city: 'Beinerstadt', zip: '98660'},
    {city: 'Beinhausen', zip: '54552'},
    {city: 'Bekdorf', zip: '25554'},
    {city: 'Bekmünde', zip: '25524'},
    {city: 'Bekond', zip: '54340'},
    {city: 'Belau', zip: '24601'},
    {city: 'Beldorf', zip: '25557'},
    {city: 'Beldorf', zip: '25767'},
    {city: 'Belg', zip: '56858'},
    {city: 'Belgern-Schildau', zip: '04874'},
    {city: 'Belgern-Schildau', zip: '04889'},
    {city: 'Belgershain', zip: '04683'},
    {city: 'Belgweiler', zip: '55469'},
    {city: 'Bell', zip: '56745'},
    {city: 'Bellenberg', zip: '89287'},
    {city: 'Bellheim', zip: '76756'},
    {city: 'Bell (Hunsrück)', zip: '56288'},
    {city: 'Bellingen', zip: '56459'},
    {city: 'Bellstedt', zip: '99713'},
    {city: 'Belm', zip: '49191'},
    {city: 'Belrieth', zip: '98617'},
    {city: 'Belsch', zip: '19230'},
    {city: 'Beltheim', zip: '56290'},
    {city: 'Belum', zip: '21785'},
    {city: 'Bempflingen', zip: '72658'},
    {city: 'Bendestorf', zip: '21227'},
    {city: 'Bendfeld', zip: '24217'},
    {city: 'Bendorf', zip: '56170'},
    {city: 'Bendorf', zip: '25557'},
    {city: 'Benediktbeuern', zip: '83671'},
    {city: 'Bengel', zip: '54538'},
    {city: 'Bengerstorf', zip: '19258'},
    {city: 'Benitz', zip: '18258'},
    {city: 'Benndorf', zip: '06308'},
    {city: 'Bennewitz', zip: '04828'},
    {city: 'Bennhausen', zip: '67808'},
    {city: 'Benningen', zip: '87734'},
    {city: 'Benningen am Neckar', zip: '71726'},
    {city: 'Bensdorf', zip: '14789'},
    {city: 'Bensheim', zip: '64625'},
    {city: 'Bentwisch', zip: '18182'},
    {city: 'Bentzin', zip: '17129'},
    {city: 'Benz', zip: '17429'},
    {city: 'Benz', zip: '23970'},
    {city: 'Benzweiler', zip: '55494'},
    {city: 'Beratzhausen', zip: '93176'},
    {city: 'Berching', zip: '92334'},
    {city: 'Berchtesgaden', zip: '83471'},
    {city: 'Bereborn', zip: '56769'},
    {city: 'Berenbach', zip: '56766'},
    {city: 'Berg', zip: '56357'},
    {city: 'Berg', zip: '82335'},
    {city: 'Berg', zip: '95180'},
    {city: 'Berg', zip: '53505'},
    {city: 'Berg', zip: '88276'},
    {city: 'Berga', zip: '06536'},
    {city: 'Berga/Elster', zip: '07980'},
    {city: 'Bergatreute', zip: '88368'},
    {city: 'Berg b.Neumarkt i.d.OPf.', zip: '92348'},
    {city: 'Berge', zip: '19348'},
    {city: 'Berge', zip: '49626'},
    {city: 'Bergen', zip: '08239'},
    {city: 'Bergen', zip: '83346'},
    {city: 'Bergen', zip: '91790'},
    {city: 'Bergen', zip: '55608'},
    {city: 'Bergen', zip: '29303'},
    {city: 'Bergen an der Dumme', zip: '29468'},
    {city: 'Bergen auf Rügen', zip: '18528'},
    {city: 'Bergenhausen', zip: '55469'},
    {city: 'Bergenhusen', zip: '24861'},
    {city: 'Bergewöhrden', zip: '25779'},
    {city: 'Bergfeld', zip: '38467'},
    {city: 'Berghaupten', zip: '77791'},
    {city: 'Berghausen', zip: '56368'},
    {city: 'Bergheim', zip: '50126'},
    {city: 'Bergheim', zip: '50127'},
    {city: 'Bergheim', zip: '50129'},
    {city: 'Bergheim', zip: '86673'},
    {city: 'Bergholz', zip: '17321'},
    {city: 'Berghülen', zip: '89180'},
    {city: 'Berg im Gau', zip: '86562'},
    {city: 'Bergisch Gladbach', zip: '51427'},
    {city: 'Bergisch Gladbach', zip: '51429'},
    {city: 'Bergisch Gladbach', zip: '51465'},
    {city: 'Bergisch Gladbach', zip: '51467'},
    {city: 'Bergisch Gladbach', zip: '51469'},
    {city: 'Bergkamen', zip: '59192'},
    {city: 'Bergkirchen', zip: '85232'},
    {city: 'Berglangenbach', zip: '55776'},
    {city: 'Berglen', zip: '73663'},
    {city: 'Berglern', zip: '85459'},
    {city: 'Berglicht', zip: '54426'},
    {city: 'Bergneustadt', zip: '51702'},
    {city: 'Berg (Pfalz)', zip: '76768'},
    {city: 'Bergrheinfeld', zip: '97493'},
    {city: 'Bergtheim', zip: '97241'},
    {city: 'Bergweiler', zip: '54518'},
    {city: 'Beringstedt', zip: '25575'},
    {city: 'Berkatal', zip: '37297'},
    {city: 'Berka vor dem Hainich', zip: '99826'},
    {city: 'Berkenbrück', zip: '15518'},
    {city: 'Berkenthin', zip: '23919'},
    {city: 'Berkheim', zip: '88450'},
    {city: 'Berkholz-Meyenburg', zip: '16306'},
    {city: 'Berkoth', zip: '54673'},
    {city: 'Berlin', zip: '10115'},
    {city: 'Berlin', zip: '10117'},
    {city: 'Berlin', zip: '10119'},
    {city: 'Berlin', zip: '10178'},
    {city: 'Berlin', zip: '10179'},
    {city: 'Berlin', zip: '10243'},
    {city: 'Berlin', zip: '10245'},
    {city: 'Berlin', zip: '10247'},
    {city: 'Berlin', zip: '10249'},
    {city: 'Berlin', zip: '10315'},
    {city: 'Berlin', zip: '10317'},
    {city: 'Berlin', zip: '10318'},
    {city: 'Berlin', zip: '10319'},
    {city: 'Berlin', zip: '10365'},
    {city: 'Berlin', zip: '10367'},
    {city: 'Berlin', zip: '10369'},
    {city: 'Berlin', zip: '10405'},
    {city: 'Berlin', zip: '10407'},
    {city: 'Berlin', zip: '10409'},
    {city: 'Berlin', zip: '10435'},
    {city: 'Berlin', zip: '10437'},
    {city: 'Berlin', zip: '10439'},
    {city: 'Berlin', zip: '10551'},
    {city: 'Berlin', zip: '10553'},
    {city: 'Berlin', zip: '10555'},
    {city: 'Berlin', zip: '10557'},
    {city: 'Berlin', zip: '10559'},
    {city: 'Berlin', zip: '10585'},
    {city: 'Berlin', zip: '10587'},
    {city: 'Berlin', zip: '10589'},
    {city: 'Berlin', zip: '10623'},
    {city: 'Berlin', zip: '10625'},
    {city: 'Berlin', zip: '10627'},
    {city: 'Berlin', zip: '10629'},
    {city: 'Berlin', zip: '10707'},
    {city: 'Berlin', zip: '10709'},
    {city: 'Berlin', zip: '10711'},
    {city: 'Berlin', zip: '10713'},
    {city: 'Berlin', zip: '10715'},
    {city: 'Berlin', zip: '10717'},
    {city: 'Berlin', zip: '10719'},
    {city: 'Berlin', zip: '10777'},
    {city: 'Berlin', zip: '10779'},
    {city: 'Berlin', zip: '10781'},
    {city: 'Berlin', zip: '10783'},
    {city: 'Berlin', zip: '10785'},
    {city: 'Berlin', zip: '10787'},
    {city: 'Berlin', zip: '10789'},
    {city: 'Berlin', zip: '10823'},
    {city: 'Berlin', zip: '10825'},
    {city: 'Berlin', zip: '10827'},
    {city: 'Berlin', zip: '10829'},
    {city: 'Berlin', zip: '10961'},
    {city: 'Berlin', zip: '10963'},
    {city: 'Berlin', zip: '10965'},
    {city: 'Berlin', zip: '10967'},
    {city: 'Berlin', zip: '10969'},
    {city: 'Berlin', zip: '10997'},
    {city: 'Berlin', zip: '10999'},
    {city: 'Berlin', zip: '12043'},
    {city: 'Berlin', zip: '12045'},
    {city: 'Berlin', zip: '12047'},
    {city: 'Berlin', zip: '12049'},
    {city: 'Berlin', zip: '12051'},
    {city: 'Berlin', zip: '12053'},
    {city: 'Berlin', zip: '12055'},
    {city: 'Berlin', zip: '12057'},
    {city: 'Berlin', zip: '12059'},
    {city: 'Berlin', zip: '12099'},
    {city: 'Berlin', zip: '12101'},
    {city: 'Berlin', zip: '12103'},
    {city: 'Berlin', zip: '12105'},
    {city: 'Berlin', zip: '12107'},
    {city: 'Berlin', zip: '12109'},
    {city: 'Berlin', zip: '12157'},
    {city: 'Berlin', zip: '12159'},
    {city: 'Berlin', zip: '12161'},
    {city: 'Berlin', zip: '12163'},
    {city: 'Berlin', zip: '12165'},
    {city: 'Berlin', zip: '12167'},
    {city: 'Berlin', zip: '12169'},
    {city: 'Berlin', zip: '12203'},
    {city: 'Berlin', zip: '12205'},
    {city: 'Berlin', zip: '12207'},
    {city: 'Berlin', zip: '12209'},
    {city: 'Berlin', zip: '12247'},
    {city: 'Berlin', zip: '12249'},
    {city: 'Berlin', zip: '12277'},
    {city: 'Berlin', zip: '12279'},
    {city: 'Berlin', zip: '12305'},
    {city: 'Berlin', zip: '12307'},
    {city: 'Berlin', zip: '12309'},
    {city: 'Berlin', zip: '12347'},
    {city: 'Berlin', zip: '12349'},
    {city: 'Berlin', zip: '12351'},
    {city: 'Berlin', zip: '12353'},
    {city: 'Berlin', zip: '12355'},
    {city: 'Berlin', zip: '12357'},
    {city: 'Berlin', zip: '12359'},
    {city: 'Berlin', zip: '12435'},
    {city: 'Berlin', zip: '12437'},
    {city: 'Berlin', zip: '12439'},
    {city: 'Berlin', zip: '12459'},
    {city: 'Berlin', zip: '12487'},
    {city: 'Berlin', zip: '12489'},
    {city: 'Berlin', zip: '12524'},
    {city: 'Berlin', zip: '12526'},
    {city: 'Berlin', zip: '12527'},
    {city: 'Berlin', zip: '12555'},
    {city: 'Berlin', zip: '12557'},
    {city: 'Berlin', zip: '12559'},
    {city: 'Berlin', zip: '12587'},
    {city: 'Berlin', zip: '12589'},
    {city: 'Berlin', zip: '12619'},
    {city: 'Berlin', zip: '12621'},
    {city: 'Berlin', zip: '12623'},
    {city: 'Berlin', zip: '12627'},
    {city: 'Berlin', zip: '12629'},
    {city: 'Berlin', zip: '12679'},
    {city: 'Berlin', zip: '12681'},
    {city: 'Berlin', zip: '12683'},
    {city: 'Berlin', zip: '12685'},
    {city: 'Berlin', zip: '12687'},
    {city: 'Berlin', zip: '12689'},
    {city: 'Berlin', zip: '13051'},
    {city: 'Berlin', zip: '13053'},
    {city: 'Berlin', zip: '13055'},
    {city: 'Berlin', zip: '13057'},
    {city: 'Berlin', zip: '13059'},
    {city: 'Berlin', zip: '13086'},
    {city: 'Berlin', zip: '13088'},
    {city: 'Berlin', zip: '13089'},
    {city: 'Berlin', zip: '13125'},
    {city: 'Berlin', zip: '13127'},
    {city: 'Berlin', zip: '13129'},
    {city: 'Berlin', zip: '13156'},
    {city: 'Berlin', zip: '13158'},
    {city: 'Berlin', zip: '13159'},
    {city: 'Berlin', zip: '13187'},
    {city: 'Berlin', zip: '13189'},
    {city: 'Berlin', zip: '13347'},
    {city: 'Berlin', zip: '13349'},
    {city: 'Berlin', zip: '13351'},
    {city: 'Berlin', zip: '13353'},
    {city: 'Berlin', zip: '13355'},
    {city: 'Berlin', zip: '13357'},
    {city: 'Berlin', zip: '13359'},
    {city: 'Berlin', zip: '13403'},
    {city: 'Berlin', zip: '13405'},
    {city: 'Berlin', zip: '13407'},
    {city: 'Berlin', zip: '13409'},
    {city: 'Berlin', zip: '13435'},
    {city: 'Berlin', zip: '13437'},
    {city: 'Berlin', zip: '13439'},
    {city: 'Berlin', zip: '13465'},
    {city: 'Berlin', zip: '13467'},
    {city: 'Berlin', zip: '13469'},
    {city: 'Berlin', zip: '13503'},
    {city: 'Berlin', zip: '13505'},
    {city: 'Berlin', zip: '13507'},
    {city: 'Berlin', zip: '13509'},
    {city: 'Berlin', zip: '13581'},
    {city: 'Berlin', zip: '13583'},
    {city: 'Berlin', zip: '13585'},
    {city: 'Berlin', zip: '13587'},
    {city: 'Berlin', zip: '13589'},
    {city: 'Berlin', zip: '13591'},
    {city: 'Berlin', zip: '13593'},
    {city: 'Berlin', zip: '13595'},
    {city: 'Berlin', zip: '13597'},
    {city: 'Berlin', zip: '13599'},
    {city: 'Berlin', zip: '13627'},
    {city: 'Berlin', zip: '13629'},
    {city: 'Berlin', zip: '14050'},
    {city: 'Berlin', zip: '14052'},
    {city: 'Berlin', zip: '14053'},
    {city: 'Berlin', zip: '14055'},
    {city: 'Berlin', zip: '14057'},
    {city: 'Berlin', zip: '14059'},
    {city: 'Berlin', zip: '14089'},
    {city: 'Berlin', zip: '14109'},
    {city: 'Berlin', zip: '14129'},
    {city: 'Berlin', zip: '14163'},
    {city: 'Berlin', zip: '14165'},
    {city: 'Berlin', zip: '14167'},
    {city: 'Berlin', zip: '14169'},
    {city: 'Berlin', zip: '14193'},
    {city: 'Berlin', zip: '14195'},
    {city: 'Berlin', zip: '14197'},
    {city: 'Berlin', zip: '14199'},
    {city: 'Berlingen', zip: '54570'},
    {city: 'Berlingerode', zip: '37339'},
    {city: 'Bermatingen', zip: '88697'},
    {city: 'Bermel', zip: '56729'},
    {city: 'Bermersheim', zip: '67593'},
    {city: 'Bermersheim vor der Höhe', zip: '55234'},
    {city: 'Bernau', zip: '16321'},
    {city: 'Bernau am Chiemsee', zip: '83233'},
    {city: 'Bernau im Schwarzwald', zip: '79872'},
    {city: 'Bernbeuren', zip: '86975'},
    {city: 'Bernburg', zip: '06406'},
    {city: 'Berndorf', zip: '54578'},
    {city: 'Berndroth', zip: '56370'},
    {city: 'Berne', zip: '27804'},
    {city: 'Berngau', zip: '92361'},
    {city: 'Bernhardswald', zip: '93170'},
    {city: 'Bernitt', zip: '18246'},
    {city: 'Bernitt', zip: '18249'},
    {city: 'Bernkastel-Kues', zip: '54470'},
    {city: 'Bernkastel-Kues', zip: '54492'},
    {city: 'Bernried', zip: '94505'},
    {city: 'Bernried am Starnberger See', zip: '82347'},
    {city: 'Bernsdorf', zip: '09337'},
    {city: 'Bernsdorf', zip: '02994'},
    {city: 'Bernstadt', zip: '89182'},
    {city: 'Bernstadt auf dem Eigen', zip: '02748'},
    {city: 'Bernstorf', zip: '23936'},
    {city: 'Berod bei Hachenburg', zip: '57614'},
    {city: 'Berod bei Wallmerod', zip: '56414'},
    {city: 'Berscheid', zip: '54673'},
    {city: 'Berschweiler bei Baumholder', zip: '55777'},
    {city: 'Berschweiler bei Kirn', zip: '55608'},
    {city: 'Bersenbrück', zip: '49593'},
    {city: 'Bersteland', zip: '15910'},
    {city: 'Bertsdorf-Hörnitz', zip: '02763'},
    {city: 'Berumbur', zip: '26524'},
    {city: 'Berzhahn', zip: '56459'},
    {city: 'Berzhausen', zip: '57632'},
    {city: 'Bescheid', zip: '54413'},
    {city: 'Beschendorf', zip: '23738'},
    {city: 'Besdorf', zip: '25584'},
    {city: 'Beselich', zip: '65614'},
    {city: 'Besenthal', zip: '23899'},
    {city: 'Beseritz', zip: '17039'},
    {city: 'Besigheim', zip: '74354'},
    {city: 'Besitz', zip: '19258'},
    {city: 'Bessenbach', zip: '63856'},
    {city: 'Bestensee', zip: '15741'},
    {city: 'Bestwig', zip: '59909'},
    {city: 'Bethenhausen', zip: '07554'},
    {city: 'Betteldorf', zip: '54570'},
    {city: 'Bettendorf', zip: '56355'},
    {city: 'Bettenfeld', zip: '54533'},
    {city: 'Bettingen', zip: '54646'},
    {city: 'Betzdorf', zip: '57518'},
    {city: 'Betzendorf', zip: '21386'},
    {city: 'Betzenstein', zip: '91282'},
    {city: 'Betzenweiler', zip: '88422'},
    {city: 'Betzigau', zip: '87488'},
    {city: 'Beulich', zip: '56283'},
    {city: 'Beuren', zip: '56825'},
    {city: 'Beuren', zip: '72660'},
    {city: 'Beuren (Hochwald)', zip: '54413'},
    {city: 'Beuron', zip: '88631'},
    {city: 'Beutelsbach', zip: '94501'},
    {city: 'Bevern', zip: '25355'},
    {city: 'Bevern', zip: '37639'},
    {city: 'Beverstedt', zip: '27616'},
    {city: 'Beverungen', zip: '37688'},
    {city: 'Bexbach', zip: '66450'},
    {city: 'Biberach', zip: '77781'},
    {city: 'Biberach an der Riß', zip: '88400'},
    {city: 'Biberbach', zip: '86485'},
    {city: 'Bibertal', zip: '89346'},
    {city: 'Biblis', zip: '68647'},
    {city: 'Bibow', zip: '19417'},
    {city: 'Bibra', zip: '07768'},
    {city: 'Biburg', zip: '93354'},
    {city: 'Bichl', zip: '83673'},
    {city: 'Bickenbach', zip: '64404'},
    {city: 'Bickenbach', zip: '56291'},
    {city: 'Bickendorf', zip: '54636'},
    {city: 'Bidingen', zip: '87651'},
    {city: 'Biebelnheim', zip: '55234'},
    {city: 'Biebelried', zip: '97318'},
    {city: 'Biebelsheim', zip: '55546'},
    {city: 'Bieberehren', zip: '97243'},
    {city: 'Biebergemünd', zip: '63599'},
    {city: 'Biebern', zip: '55471'},
    {city: 'Biebertal', zip: '35444'},
    {city: 'Biebesheim', zip: '64584'},
    {city: 'Biebrich', zip: '56370'},
    {city: 'Biedenkopf', zip: '35216'},
    {city: 'Biederbach', zip: '79215'},
    {city: 'Biederitz', zip: '39175'},
    {city: 'Biedershausen', zip: '66917'},
    {city: 'Biedesheim', zip: '67308'},
    {city: 'Bielefeld', zip: '33602'},
    {city: 'Bielefeld', zip: '33604'},
    {city: 'Bielefeld', zip: '33605'},
    {city: 'Bielefeld', zip: '33607'},
    {city: 'Bielefeld', zip: '33609'},
    {city: 'Bielefeld', zip: '33611'},
    {city: 'Bielefeld', zip: '33613'},
    {city: 'Bielefeld', zip: '33615'},
    {city: 'Bielefeld', zip: '33617'},
    {city: 'Bielefeld', zip: '33619'},
    {city: 'Bielefeld', zip: '33647'},
    {city: 'Bielefeld', zip: '33649'},
    {city: 'Bielefeld', zip: '33659'},
    {city: 'Bielefeld', zip: '33689'},
    {city: 'Bielefeld', zip: '33699'},
    {city: 'Bielefeld', zip: '33719'},
    {city: 'Bielefeld', zip: '33729'},
    {city: 'Bielefeld', zip: '33739'},
    {city: 'Biendorf', zip: '18230'},
    {city: 'Bienenbüttel', zip: '29553'},
    {city: 'Bienstädt', zip: '99100'},
    {city: 'Biersdorf am See', zip: '54636'},
    {city: 'Biesdorf', zip: '54675'},
    {city: 'Biesenthal', zip: '16359'},
    {city: 'Biessenhofen', zip: '87640'},
    {city: 'Bietigheim', zip: '76467'},
    {city: 'Bietigheim-Bissingen', zip: '74321'},
    {city: 'Bilkheim', zip: '56414'},
    {city: 'Billerbeck', zip: '48727'},
    {city: 'Billigheim', zip: '74842'},
    {city: 'Billigheim-Ingenheim', zip: '76831'},
    {city: 'Bilsen', zip: '25485'},
    {city: 'Bilshausen', zip: '37434'},
    {city: 'Bimöhlen', zip: '24576'},
    {city: 'Binau', zip: '74862'},
    {city: 'Bindlach', zip: '95463'},
    {city: 'Bingen', zip: '72511'},
    {city: 'Bingen am Rhein', zip: '55411'},
    {city: 'Binnen', zip: '31619'},
    {city: 'Binningen', zip: '56754'},
    {city: 'Binsfeld', zip: '54518'},
    {city: 'Binswangen', zip: '86637'},
    {city: 'Binz', zip: '18609'},
    {city: 'Binzen', zip: '79589'},
    {city: 'Bippen', zip: '49626'},
    {city: 'Birenbach', zip: '73102'},
    {city: 'Birgel', zip: '54587'},
    {city: 'Birgland', zip: '92262'},
    {city: 'Birkenau', zip: '69488'},
    {city: 'Birkenbeul', zip: '57589'},
    {city: 'Birkenfeld', zip: '75217'},
    {city: 'Birkenfeld', zip: '97834'},
    {city: 'Birkenfeld', zip: '55765'},
    {city: 'Birkenfelde', zip: '37318'},
    {city: 'Birkenheide', zip: '67134'},
    {city: 'Birken-Honigsessen', zip: '57587'},
    {city: 'Birkenhördt', zip: '76889'},
    {city: 'Birkenwerder', zip: '16547'},
    {city: 'Birkheim', zip: '56291'},
    {city: 'Birkweiler', zip: '76831'},
    {city: 'Birlenbach', zip: '65626'},
    {city: 'Birnbach', zip: '57612'},
    {city: 'Birresborn', zip: '54574'},
    {city: 'Birstein', zip: '63633'},
    {city: 'Birtlingen', zip: '54634'},
    {city: 'Birx', zip: '98634'},
    {city: 'Bischberg', zip: '96120'},
    {city: 'Bischbrunn', zip: '97836'},
    {city: 'Bischbrunn', zip: '97852'},
    {city: 'Bischheim', zip: '67294'},
    {city: 'Bischoffen', zip: '35649'},
    {city: 'Bischofrod', zip: '98553'},
    {city: 'Bischofroda', zip: '99826'},
    {city: 'Bischofsgrün', zip: '95493'},
    {city: 'Bischofsheim', zip: '65474'},
    {city: 'Bischofsheim i.d.Rhön', zip: '97653'},
    {city: 'Bischofsmais', zip: '94253'},
    {city: 'Bischofswerda', zip: '01877'},
    {city: 'Bischofswiesen', zip: '83483'},
    {city: 'Bischweier', zip: '76476'},
    {city: 'Bisingen', zip: '72406'},
    {city: 'Bismark (Altmark)', zip: '39628'},
    {city: 'Bismark (Altmark)', zip: '39629'},
    {city: 'Bispingen', zip: '29646'},
    {city: 'Bissee', zip: '24582'},
    {city: 'Bissendorf', zip: '49143'},
    {city: 'Bissersheim', zip: '67281'},
    {city: 'Bissingen', zip: '86657'},
    {city: 'Bissingen an der Teck', zip: '73266'},
    {city: 'Bisterschied', zip: '67806'},
    {city: 'Bitburg', zip: '54634'},
    {city: 'Bitterfeld-Wolfen', zip: '06749'},
    {city: 'Bitterfeld-Wolfen', zip: '06766'},
    {city: 'Bitterfeld-Wolfen', zip: '06803'},
    {city: 'Bitterfeld-Wolfen', zip: '06808'},
    {city: 'Bitz', zip: '72475'},
    {city: 'Bitzen', zip: '57539'},
    {city: 'Blaibach', zip: '93476'},
    {city: 'Blaichach', zip: '87544'},
    {city: 'Blankenbach', zip: '63825'},
    {city: 'Blankenberg', zip: '19412'},
    {city: 'Blankenburg', zip: '06502'},
    {city: 'Blankenburg', zip: '38889'},
    {city: 'Blankenburg', zip: '38895'},
    {city: 'Blankenburg', zip: '99955'},
    {city: 'Blankenfelde-Mahlow', zip: '15827'},
    {city: 'Blankenfelde-Mahlow', zip: '15831'},
    {city: 'Blankenhagen', zip: '18182'},
    {city: 'Blankenhain', zip: '99444'},
    {city: 'Blankenheim', zip: '53945'},
    {city: 'Blankenheim', zip: '06528'},
    {city: 'Blankenhof', zip: '17039'},
    {city: 'Blankenrath', zip: '56865'},
    {city: 'Blankensee', zip: '17322'},
    {city: 'Blankensee', zip: '17237'},
    {city: 'Blaubach', zip: '66869'},
    {city: 'Blaubeuren', zip: '89143'},
    {city: 'Blaufelden', zip: '74572'},
    {city: 'Blaustein', zip: '89134'},
    {city: 'Bleckede', zip: '19273'},
    {city: 'Bleckede', zip: '21354'},
    {city: 'Bleckhausen', zip: '54570'},
    {city: 'Bleialf', zip: '54608'},
    {city: 'Bleicherode', zip: '99752'},
    {city: 'Blekendorf', zip: '24327'},
    {city: 'Blender', zip: '27337'},
    {city: 'Blesewitz', zip: '17392'},
    {city: 'Bleyen-Genschmar', zip: '15328'},
    {city: 'Bliedersdorf', zip: '21640'},
    {city: 'Bliesdorf', zip: '16269'},
    {city: 'Blieskastel', zip: '66440'},
    {city: 'Bliestorf', zip: '23847'},
    {city: 'Blievenstorf', zip: '19306'},
    {city: 'Blindheim', zip: '89434'},
    {city: 'Blomberg', zip: '32825'},
    {city: 'Blomberg', zip: '26487'},
    {city: 'Blomesche Wildnis', zip: '25348'},
    {city: 'Blowatz', zip: '23974'},
    {city: 'Blumberg', zip: '78176'},
    {city: 'Blumenholz', zip: '17237'},
    {city: 'Blumenthal', zip: '24241'},
    {city: 'Blunk', zip: '23813'},
    {city: 'Bobeck', zip: '07646'},
    {city: 'Bobenheim am Berg', zip: '67273'},
    {city: 'Bobenheim-Roxheim', zip: '67240'},
    {city: 'Bobenthal', zip: '76891'},
    {city: 'Böbing', zip: '82389'},
    {city: 'Bobingen', zip: '86399'},
    {city: 'Böbingen', zip: '67482'},
    {city: 'Böbingen an der Rems', zip: '73560'},
    {city: 'Bobitz', zip: '23966'},
    {city: 'Bobitz', zip: '23996'},
    {city: 'Böblingen', zip: '71032'},
    {city: 'Böblingen', zip: '71034'},
    {city: 'Böbrach', zip: '94255'},
    {city: 'Bobritzsch-Hilbersdorf', zip: '09627'},
    {city: 'Bobzin', zip: '19230'},
    {city: 'Böchingen', zip: '76833'},
    {city: 'Bocholt', zip: '46395'},
    {city: 'Bocholt', zip: '46397'},
    {city: 'Bocholt', zip: '46399'},
    {city: 'Bochum', zip: '44787'},
    {city: 'Bochum', zip: '44789'},
    {city: 'Bochum', zip: '44791'},
    {city: 'Bochum', zip: '44793'},
    {city: 'Bochum', zip: '44795'},
    {city: 'Bochum', zip: '44797'},
    {city: 'Bochum', zip: '44799'},
    {city: 'Bochum', zip: '44801'},
    {city: 'Bochum', zip: '44803'},
    {city: 'Bochum', zip: '44805'},
    {city: 'Bochum', zip: '44807'},
    {city: 'Bochum', zip: '44809'},
    {city: 'Bochum', zip: '44866'},
    {city: 'Bochum', zip: '44867'},
    {city: 'Bochum', zip: '44869'},
    {city: 'Bochum', zip: '44879'},
    {city: 'Bochum', zip: '44892'},
    {city: 'Bochum', zip: '44894'},
    {city: 'Bocka', zip: '07589'},
    {city: 'Bockau', zip: '08324'},
    {city: 'Bockenau', zip: '55595'},
    {city: 'Bockenem', zip: '31167'},
    {city: 'Bockenheim an der Weinstraße', zip: '67278'},
    {city: 'Bockhorn', zip: '85461'},
    {city: 'Bockhorn', zip: '26345'},
    {city: 'Bockhorst', zip: '26897'},
    {city: 'Bodelshausen', zip: '72411'},
    {city: 'Bodelwitz', zip: '07381'},
    {city: 'Boden', zip: '56412'},
    {city: 'Bodenbach', zip: '53539'},
    {city: 'Bodenfelde', zip: '37194'},
    {city: 'Bodenheim', zip: '55294'},
    {city: 'Bodenkirchen', zip: '84155'},
    {city: 'Bodenmais', zip: '94249'},
    {city: 'Bodenrode-Westhausen', zip: '37308'},
    {city: 'Bodensee', zip: '37434'},
    {city: 'Bodenwerder', zip: '37619'},
    {city: 'Bodenwöhr', zip: '92439'},
    {city: 'Bodman-Ludwigshafen', zip: '78351'},
    {city: 'Bodnegg', zip: '88285'},
    {city: 'Bodolz', zip: '88131'},
    {city: 'Böel', zip: '24401'},
    {city: 'Böel', zip: '24405'},
    {city: 'Böel', zip: '24891'},
    {city: 'Boffzen', zip: '37691'},
    {city: 'Bogel', zip: '56357'},
    {city: 'Bogen', zip: '94327'},
    {city: 'Böhen', zip: '87736'},
    {city: 'Böhlen', zip: '04564'},
    {city: 'Böhl-Iggelheim', zip: '67459'},
    {city: 'Böhme', zip: '29693'},
    {city: 'Böhmenkirch', zip: '89558'},
    {city: 'Böhmfeld', zip: '85113'},
    {city: 'Bohmstedt', zip: '25853'},
    {city: 'Bohmte', zip: '49163'},
    {city: 'Böhnhusen', zip: '24220'},
    {city: 'Boiensdorf', zip: '23974'},
    {city: 'Boitze', zip: '21368'},
    {city: 'Boitzenburger Land', zip: '17268'},
    {city: 'Boizenburg/Elbe', zip: '19258'},
    {city: 'Bokel', zip: '25364'},
    {city: 'Bokel', zip: '24802'},
    {city: 'Bokelrehm', zip: '25596'},
    {city: 'Bokensdorf', zip: '38556'},
    {city: 'Bokholt-Hanredder', zip: '25335'},
    {city: 'Bokhorst', zip: '25560'},
    {city: 'Bokhorst', zip: '25572'},
    {city: 'Böklund', zip: '24860'},
    {city: 'Boksee', zip: '24220'},
    {city: 'Bolanden', zip: '67295'},
    {city: 'Boldekow', zip: '17392'},
    {city: 'Böllen', zip: '79677'},
    {city: 'Bollenbach', zip: '55624'},
    {city: 'Böllenborn', zip: '76887'},
    {city: 'Bollendorf', zip: '54669'},
    {city: 'Bollewick', zip: '17207'},
    {city: 'Bollingstedt', zip: '24855'},
    {city: 'Bollschweil', zip: '79283'},
    {city: 'Bölsberg', zip: '57648'},
    {city: 'Bolsterlang', zip: '87538'},
    {city: 'Boltenhagen', zip: '23946'},
    {city: 'Boms', zip: '88361'},
    {city: 'Bondelum', zip: '25850'},
    {city: 'Bondorf', zip: '71149'},
    {city: 'Bönebüttel', zip: '24620'},
    {city: 'Bonefeld', zip: '56579'},
    {city: 'Bönen', zip: '59199'},
    {city: 'Bonerath', zip: '54316'},
    {city: 'Bongard', zip: '53539'},
    {city: 'Bonn', zip: '53111'},
    {city: 'Bonn', zip: '53113'},
    {city: 'Bonn', zip: '53115'},
    {city: 'Bonn', zip: '53117'},
    {city: 'Bonn', zip: '53119'},
    {city: 'Bonn', zip: '53121'},
    {city: 'Bonn', zip: '53123'},
    {city: 'Bonn', zip: '53125'},
    {city: 'Bonn', zip: '53127'},
    {city: 'Bonn', zip: '53129'},
    {city: 'Bonn', zip: '53173'},
    {city: 'Bonn', zip: '53175'},
    {city: 'Bonn', zip: '53177'},
    {city: 'Bonn', zip: '53179'},
    {city: 'Bonn', zip: '53225'},
    {city: 'Bonn', zip: '53227'},
    {city: 'Bonn', zip: '53229'},
    {city: 'Bonndorf im Schwarzwald', zip: '79848'},
    {city: 'Bönnigheim', zip: '74357'},
    {city: 'Bönningstedt', zip: '25474'},
    {city: 'Bonstetten', zip: '86486'},
    {city: 'Boock', zip: '17322'},
    {city: 'Boos', zip: '56729'},
    {city: 'Boos', zip: '87737'},
    {city: 'Boos', zip: '55595'},
    {city: 'Boostedt', zip: '24598'},
    {city: 'Bopfingen', zip: '73441'},
    {city: 'Boppard', zip: '56154'},
    {city: 'Borchen', zip: '33178'},
    {city: 'Bördeaue', zip: '39435'},
    {city: 'Börde-Hakel', zip: '39448'},
    {city: 'Bördeland', zip: '39221'},
    {city: 'Bordelum', zip: '25821'},
    {city: 'Bordelum', zip: '25842'},
    {city: 'Bordelum', zip: '25852'},
    {city: 'Bordesholm', zip: '24582'},
    {city: 'Boren', zip: '24392'},
    {city: 'Börfink', zip: '54422'},
    {city: 'Borgdorf-Seedorf', zip: '24589'},
    {city: 'Borgentreich', zip: '34434'},
    {city: 'Börger', zip: '26904'},
    {city: 'Börgerende-Rethwisch', zip: '18211'},
    {city: 'Borgholzhausen', zip: '33829'},
    {city: 'Borgstedt', zip: '24794'},
    {city: 'Borgsum', zip: '25938'},
    {city: 'Borgwedel', zip: '24857'},
    {city: 'Borken', zip: '46325'},
    {city: 'Borken', zip: '34582'},
    {city: 'Borkheide', zip: '14822'},
    {city: 'Borkow', zip: '19406'},
    {city: 'Borkum', zip: '26757'},
    {city: 'Borkwalde', zip: '14822'},
    {city: 'Borler', zip: '53539'},
    {city: 'Börm', zip: '24863'},
    {city: 'Borna', zip: '04552'},
    {city: 'Born a. Darß', zip: '18375'},
    {city: 'Borne', zip: '39435'},
    {city: 'Bornhagen', zip: '37318'},
    {city: 'Bornheim', zip: '53332'},
    {city: 'Bornheim', zip: '55237'},
    {city: 'Bornheim', zip: '76879'},
    {city: 'Bornholt', zip: '25557'},
    {city: 'Bornholt', zip: '25725'},
    {city: 'Bornhöved', zip: '24619'},
    {city: 'Bornich', zip: '56348'},
    {city: 'Börnichen', zip: '09437'},
    {city: 'Börnsen', zip: '21039'},
    {city: 'Bornstedt', zip: '06295'},
    {city: 'Borod', zip: '57614'},
    {city: 'Borrentin', zip: '17111'},
    {city: 'Börrstadt', zip: '67725'},
    {city: 'Börsborn', zip: '66904'},
    {city: 'Borsdorf', zip: '04451'},
    {city: 'Borsfleth', zip: '25376'},
    {city: 'Börslingen', zip: '89177'},
    {city: 'Börßum', zip: '38312'},
    {city: 'Borstel', zip: '24616'},
    {city: 'Borstel', zip: '27246'},
    {city: 'Borstel-Hohenraden', zip: '25494'},
    {city: 'Borstorf', zip: '23881'},
    {city: 'Börtlingen', zip: '73104'},
    {city: 'Borxleben', zip: '06556'},
    {city: 'Bosau', zip: '23715'},
    {city: 'Bosbüll', zip: '25899'},
    {city: 'Bösdorf', zip: '24306'},
    {city: 'Bösel', zip: '26219'},
    {city: 'Bosenbach', zip: '66887'},
    {city: 'Bösenbrunn', zip: '08606'},
    {city: 'Bösingen', zip: '78662'},
    {city: 'Bösleben-Wüllersleben', zip: '99310'},
    {city: 'Bötersen', zip: '27367'},
    {city: 'Bothel', zip: '27386'},
    {city: 'Bothkamp', zip: '24250'},
    {city: 'Bottenbach', zip: '66504'},
    {city: 'Böttingen', zip: '78583'},
    {city: 'Bottrop', zip: '46236'},
    {city: 'Bottrop', zip: '46238'},
    {city: 'Bottrop', zip: '46240'},
    {city: 'Bottrop', zip: '46242'},
    {city: 'Bottrop', zip: '46244'},
    {city: 'Bötzingen', zip: '79268'},
    {city: 'Bous', zip: '66359'},
    {city: 'Bovenau', zip: '24796'},
    {city: 'Bovenden', zip: '37120'},
    {city: 'Boxberg', zip: '97944'},
    {city: 'Boxberg', zip: '54552'},
    {city: 'Boxberg/O.L.', zip: '02943'},
    {city: 'Böxlund', zip: '24994'},
    {city: 'Braak', zip: '22145'},
    {city: 'Brachbach', zip: '57555'},
    {city: 'Brachtendorf', zip: '56761'},
    {city: 'Brachttal', zip: '63636'},
    {city: 'Brackel', zip: '21438'},
    {city: 'Brackenheim', zip: '74336'},
    {city: 'Braderup', zip: '25923'},
    {city: 'Brahlstorf', zip: '19273'},
    {city: 'Brahmenau', zip: '07554'},
    {city: 'Brake', zip: '26919'},
    {city: 'Brakel', zip: '33034'},
    {city: 'Brammer', zip: '24793'},
    {city: 'Bramsche', zip: '49565'},
    {city: 'Bramstedtlund', zip: '25926'},
    {city: 'Brand', zip: '95682'},
    {city: 'Brande-Hörnerkirchen', zip: '25364'},
    {city: 'Brandenburg an der Havel', zip: '14770'},
    {city: 'Brandenburg an der Havel', zip: '14772'},
    {city: 'Brandenburg an der Havel', zip: '14774'},
    {city: 'Brandenburg an der Havel', zip: '14776'},
    {city: 'Brand-Erbisdorf', zip: '09618'},
    {city: 'Brandis', zip: '04821'},
    {city: 'Brandis', zip: '04824'},
    {city: 'Brandscheid', zip: '56459'},
    {city: 'Brandscheid', zip: '54608'},
    {city: 'Brannenburg', zip: '83098'},
    {city: 'Braubach', zip: '56338'},
    {city: 'Brauneberg', zip: '54470'},
    {city: 'Brauneberg', zip: '54472'},
    {city: 'Braunfels', zip: '35619'},
    {city: 'Braunichswalde', zip: '07580'},
    {city: 'Braunlage', zip: '37444'},
    {city: 'Braunlage', zip: '38700'},
    {city: 'Bräunlingen', zip: '78199'},
    {city: 'Braunsbach', zip: '74542'},
    {city: 'Braunsbedra', zip: '06242'},
    {city: 'Braunsbedra', zip: '06259'},
    {city: 'Braunschweig', zip: '38100'},
    {city: 'Braunschweig', zip: '38102'},
    {city: 'Braunschweig', zip: '38104'},
    {city: 'Braunschweig', zip: '38106'},
    {city: 'Braunschweig', zip: '38108'},
    {city: 'Braunschweig', zip: '38110'},
    {city: 'Braunschweig', zip: '38112'},
    {city: 'Braunschweig', zip: '38114'},
    {city: 'Braunschweig', zip: '38116'},
    {city: 'Braunschweig', zip: '38118'},
    {city: 'Braunschweig', zip: '38120'},
    {city: 'Braunschweig', zip: '38122'},
    {city: 'Braunschweig', zip: '38124'},
    {city: 'Braunschweig', zip: '38126'},
    {city: 'Braunshorn', zip: '56288'},
    {city: 'Braunweiler', zip: '55595'},
    {city: 'Brauweiler', zip: '55606'},
    {city: 'Brechen', zip: '65611'},
    {city: 'Brecht', zip: '54636'},
    {city: 'Breckerfeld', zip: '58339'},
    {city: 'Breddenberg', zip: '26897'},
    {city: 'Breddin', zip: '16845'},
    {city: 'Breddorf', zip: '27412'},
    {city: 'Breddorf', zip: '27726'},
    {city: 'Bredenbek', zip: '24796'},
    {city: 'Bredenfelde', zip: '17153'},
    {city: 'Bredstedt', zip: '25821'},
    {city: 'Breege', zip: '18556'},
    {city: 'Breese', zip: '19322'},
    {city: 'Breesen', zip: '17091'},
    {city: 'Breest', zip: '17089'},
    {city: 'Brehme', zip: '37339'},
    {city: 'Breidenbach', zip: '35236'},
    {city: 'Breiholz', zip: '24797'},
    {city: 'Breisach am Rhein', zip: '79206'},
    {city: 'Breit', zip: '54426'},
    {city: 'Breitbrunn', zip: '96151'},
    {city: 'Breitbrunn am Chiemsee', zip: '83254'},
    {city: 'Breitenau', zip: '56237'},
    {city: 'Breitenbach', zip: '66916'},
    {city: 'Breitenbach am Herzberg', zip: '36287'},
    {city: 'Breitenberg', zip: '25597'},
    {city: 'Breitenberg', zip: '94139'},
    {city: 'Breitenbrunn', zip: '87739'},
    {city: 'Breitenbrunn', zip: '92363'},
    {city: 'Breitenbrunn/Erzgebirge', zip: '08359'},
    {city: 'Breitenburg', zip: '25524'},
    {city: 'Breitenfelde', zip: '23881'},
    {city: 'Breitengüßbach', zip: '96149'},
    {city: 'Breitenheim', zip: '55592'},
    {city: 'Breitenthal', zip: '86488'},
    {city: 'Breitenthal', zip: '55758'},
    {city: 'Breitenworbis', zip: '37339'},
    {city: 'Breitingen', zip: '89183'},
    {city: 'Breitnau', zip: '79874'},
    {city: 'Breitscheid', zip: '35767'},
    {city: 'Breitscheid', zip: '53547'},
    {city: 'Breitscheid', zip: '55422'},
    {city: 'Breitscheidt', zip: '57539'},
    {city: 'Breitungen/Werra', zip: '98597'},
    {city: 'Brekendorf', zip: '24811'},
    {city: 'Breklum', zip: '25821'},
    {city: 'Bremberg', zip: '56370'},
    {city: 'Bremen', zip: '27568'},
    {city: 'Bremen', zip: '28195'},
    {city: 'Bremen', zip: '28197'},
    {city: 'Bremen', zip: '28199'},
    {city: 'Bremen', zip: '28201'},
    {city: 'Bremen', zip: '28203'},
    {city: 'Bremen', zip: '28205'},
    {city: 'Bremen', zip: '28207'},
    {city: 'Bremen', zip: '28209'},
    {city: 'Bremen', zip: '28211'},
    {city: 'Bremen', zip: '28213'},
    {city: 'Bremen', zip: '28215'},
    {city: 'Bremen', zip: '28217'},
    {city: 'Bremen', zip: '28219'},
    {city: 'Bremen', zip: '28237'},
    {city: 'Bremen', zip: '28239'},
    {city: 'Bremen', zip: '28259'},
    {city: 'Bremen', zip: '28277'},
    {city: 'Bremen', zip: '28279'},
    {city: 'Bremen', zip: '28307'},
    {city: 'Bremen', zip: '28309'},
    {city: 'Bremen', zip: '28325'},
    {city: 'Bremen', zip: '28327'},
    {city: 'Bremen', zip: '28329'},
    {city: 'Bremen', zip: '28355'},
    {city: 'Bremen', zip: '28357'},
    {city: 'Bremen', zip: '28359'},
    {city: 'Bremen', zip: '28717'},
    {city: 'Bremen', zip: '28719'},
    {city: 'Bremen', zip: '28755'},
    {city: 'Bremen', zip: '28757'},
    {city: 'Bremen', zip: '28759'},
    {city: 'Bremen', zip: '28777'},
    {city: 'Bremen', zip: '28779'},
    {city: 'Bremerhaven', zip: '27568'},
    {city: 'Bremerhaven', zip: '27570'},
    {city: 'Bremerhaven', zip: '27572'},
    {city: 'Bremerhaven', zip: '27574'},
    {city: 'Bremerhaven', zip: '27576'},
    {city: 'Bremerhaven', zip: '27578'},
    {city: 'Bremerhaven', zip: '27580'},
    {city: 'Bremervörde', zip: '27432'},
    {city: 'Bremm', zip: '56814'},
    {city: 'Bremsnitz', zip: '07646'},
    {city: 'Brenk', zip: '56651'},
    {city: 'Brennberg', zip: '93179'},
    {city: 'Brensbach', zip: '64395'},
    {city: 'Brenz', zip: '19306'},
    {city: 'Bresegard bei Eldena', zip: '19294'},
    {city: 'Bresegard bei Picher', zip: '19230'},
    {city: 'Brest', zip: '21698'},
    {city: 'Bretten', zip: '75015'},
    {city: 'Bretthausen', zip: '56479'},
    {city: 'Bretzenheim', zip: '55559'},
    {city: 'Bretzfeld', zip: '74626'},
    {city: 'Breuberg', zip: '64747'},
    {city: 'Breuna', zip: '34479'},
    {city: 'Breunigweiler', zip: '67725'},
    {city: 'Brevörde', zip: '37647'},
    {city: 'Brey', zip: '56321'},
    {city: 'Breydin', zip: '16230'},
    {city: 'Brickeln', zip: '25712'},
    {city: 'Briedel', zip: '56850'},
    {city: 'Briedel', zip: '56867'},
    {city: 'Brieden', zip: '56829'},
    {city: 'Briedern', zip: '56820'},
    {city: 'Brieselang', zip: '14656'},
    {city: 'Briesen', zip: '03096'},
    {city: 'Briesen (Mark)', zip: '15518'},
    {city: 'Brieskow-Finkenheerd', zip: '15295'},
    {city: 'Brietlingen', zip: '21382'},
    {city: 'Brietzig', zip: '17309'},
    {city: 'Brigachtal', zip: '78086'},
    {city: 'Briggow', zip: '17153'},
    {city: 'Brilon', zip: '59929'},
    {city: 'Brimingen', zip: '54646'},
    {city: 'Brinjahe', zip: '24816'},
    {city: 'Brinkum', zip: '26835'},
    {city: 'Britz', zip: '16230'},
    {city: 'Bröbberow', zip: '18258'},
    {city: 'Brockel', zip: '27386'},
    {city: 'Bröckel', zip: '29356'},
    {city: 'Brockscheid', zip: '54552'},
    {city: 'Brockum', zip: '49448'},
    {city: 'Brodenbach', zip: '56332'},
    {city: 'Brodersby', zip: '24398'},
    {city: 'Brodersby-Goltoft', zip: '24864'},
    {city: 'Brodersdorf', zip: '24235'},
    {city: 'Broderstorf', zip: '18184'},
    {city: 'Brohl', zip: '56754'},
    {city: 'Brohl-Lützing', zip: '56656'},
    {city: 'Brokdorf', zip: '25554'},
    {city: 'Brokdorf', zip: '25576'},
    {city: 'Brokstedt', zip: '24616'},
    {city: 'Brombachtal', zip: '64753'},
    {city: 'Brome', zip: '38465'},
    {city: 'Bromskirchen', zip: '59969'},
    {city: 'Bronkow', zip: '03205'},
    {city: 'Bröthen', zip: '21514'},
    {city: 'Brotterode-Trusetal', zip: '98596'},
    {city: 'Bruch', zip: '54518'},
    {city: 'Bruchertseifen', zip: '57539'},
    {city: 'Bruchhausen', zip: '53572'},
    {city: 'Bruchhausen-Vilsen', zip: '27305'},
    {city: 'Bruchköbel', zip: '63486'},
    {city: 'Bruchmühlbach-Miesau', zip: '66892'},
    {city: 'Bruchsal', zip: '76646'},
    {city: 'Bruchstedt', zip: '99955'},
    {city: 'Bruchweiler', zip: '55758'},
    {city: 'Bruchweiler-Bärenbach', zip: '76891'},
    {city: 'Bruck', zip: '85567'},
    {city: 'Brück', zip: '14822'},
    {city: 'Bruckberg', zip: '84079'},
    {city: 'Bruckberg', zip: '91590'},
    {city: 'Brücken', zip: '55767'},
    {city: 'Brücken-Hackpfüffel', zip: '06528'},
    {city: 'Brücken (Pfalz)', zip: '66904'},
    {city: 'Bruck i.d.OPf.', zip: '92436'},
    {city: 'Bruckmühl', zip: '83052'},
    {city: 'Brücktal', zip: '53539'},
    {city: 'Brüel', zip: '19412'},
    {city: 'Brügge', zip: '24582'},
    {city: 'Brüggen', zip: '41379'},
    {city: 'Brühl', zip: '50321'},
    {city: 'Brühl', zip: '68782'},
    {city: 'Brunn', zip: '93164'},
    {city: 'Brunn', zip: '17039'},
    {city: 'Brunnen', zip: '86564'},
    {city: 'Brunnthal', zip: '85649'},
    {city: 'Brünn/Thür.', zip: '98673'},
    {city: 'Brunow', zip: '19357'},
    {city: 'Brunow', zip: '19372'},
    {city: 'Brunsbek', zip: '22946'},
    {city: 'Brunsbüttel', zip: '25541'},
    {city: 'Brunsmark', zip: '23883'},
    {city: 'Brunstorf', zip: '21524'},
    {city: 'Brünzow', zip: '17509'},
    {city: 'Bruschied', zip: '55606'},
    {city: 'Brüsewitz', zip: '19071'},
    {city: 'Brüssow', zip: '17326'},
    {city: 'Bruttig-Fankel', zip: '56814'},
    {city: 'Bubach', zip: '56288'},
    {city: 'Bubenheim', zip: '55270'},
    {city: 'Bubenheim', zip: '67308'},
    {city: 'Bubenreuth', zip: '91088'},
    {city: 'Bubesheim', zip: '89347'},
    {city: 'Buborn', zip: '67742'},
    {city: 'Bubsheim', zip: '78585'},
    {city: 'Buch', zip: '56357'},
    {city: 'Buch', zip: '89290'},
    {city: 'Buch', zip: '56290'},
    {city: 'Bucha', zip: '07751'},
    {city: 'Buch am Buchrain', zip: '85656'},
    {city: 'Buch am Erlbach', zip: '84172'},
    {city: 'Buch am Wald', zip: '91592'},
    {city: 'Buchbach', zip: '84428'},
    {city: 'Buchbrunn', zip: '97320'},
    {city: 'Buchdorf', zip: '86675'},
    {city: 'Büchel', zip: '56823'},
    {city: 'Büchel', zip: '99638'},
    {city: 'Büchen', zip: '21514'},
    {city: 'Buchenbach', zip: '79256'},
    {city: 'Büchenbach', zip: '91186'},
    {city: 'Buchenberg', zip: '87474'},
    {city: 'Büchenbeuren', zip: '55491'},
    {city: 'Buchen (Odenwald)', zip: '74722'},
    {city: 'Buchet', zip: '54608'},
    {city: 'Buchfart', zip: '99438'},
    {city: 'Buchheim', zip: '78567'},
    {city: 'Buchheim', zip: '88637'},
    {city: 'Buchhofen', zip: '94533'},
    {city: 'Buchholz', zip: '25712'},
    {city: 'Buchholz', zip: '31710'},
    {city: 'Buchholz', zip: '23911'},
    {city: 'Buchholz', zip: '17209'},
    {city: 'Buchholz (Aller)', zip: '29690'},
    {city: 'Buchholz in der Nordheide', zip: '21244'},
    {city: 'Buchholz (Westerwald)', zip: '53567'},
    {city: 'Buchhorst', zip: '21481'},
    {city: 'Büchlberg', zip: '94124'},
    {city: 'Buchloe', zip: '86807'},
    {city: 'Buckautal', zip: '14793'},
    {city: 'Bückeburg', zip: '31675'},
    {city: 'Bücken', zip: '27333'},
    {city: 'Buckenhof', zip: '91054'},
    {city: 'Buckow', zip: '15377'},
    {city: 'Büdelsdorf', zip: '24782'},
    {city: 'Budenbach', zip: '55469'},
    {city: 'Budenheim', zip: '55257'},
    {city: 'Büdesheim', zip: '54610'},
    {city: 'Büdingen', zip: '63654'},
    {city: 'Büdlich', zip: '54426'},
    {city: 'Bugewitz', zip: '17398'},
    {city: 'Buggenhagen', zip: '17440'},
    {city: 'Buggingen', zip: '79426'},
    {city: 'Bühl', zip: '77815'},
    {city: 'Buhla', zip: '37339'},
    {city: 'Buhlenberg', zip: '55767'},
    {city: 'Bühlertal', zip: '77830'},
    {city: 'Bühlertann', zip: '74424'},
    {city: 'Bühlerzell', zip: '74426'},
    {city: 'Bühnsdorf', zip: '23845'},
    {city: 'Bühren', zip: '37127'},
    {city: 'Bülkau', zip: '21782'},
    {city: 'Bullay', zip: '56859'},
    {city: 'Bullenkuhlen', zip: '25355'},
    {city: 'Bülow', zip: '19089'},
    {city: 'Bülstedt', zip: '27412'},
    {city: 'Bülstringen', zip: '39345'},
    {city: 'Bunde', zip: '26831'},
    {city: 'Bünde', zip: '32257'},
    {city: 'Bundenbach', zip: '55626'},
    {city: 'Bundenthal', zip: '76891'},
    {city: 'Bundorf', zip: '97494'},
    {city: 'Bünsdorf', zip: '24794'},
    {city: 'Bunsoh', zip: '25767'},
    {city: 'Burbach', zip: '57299'},
    {city: 'Burbach', zip: '54597'},
    {city: 'Bürdenbach', zip: '56593'},
    {city: 'Büren', zip: '33142'},
    {city: 'Burg', zip: '39288'},
    {city: 'Burg', zip: '54646'},
    {city: 'Burgau', zip: '89331'},
    {city: 'Burgberg i.Allgäu', zip: '87545'},
    {city: 'Burgbernheim', zip: '91593'},
    {city: 'Burgbrohl', zip: '56659'},
    {city: 'Burg (Dithmarschen)', zip: '25712'},
    {city: 'Burgdorf', zip: '31303'},
    {city: 'Burgdorf', zip: '38272'},
    {city: 'Burgebrach', zip: '96138'},
    {city: 'Bürgel', zip: '07616'},
    {city: 'Burgen', zip: '56332'},
    {city: 'Burgen', zip: '54472'},
    {city: 'Burggen', zip: '86977'},
    {city: 'Burghaslach', zip: '96152'},
    {city: 'Burghaun', zip: '36151'},
    {city: 'Burghausen', zip: '84489'},
    {city: 'Burgheim', zip: '86666'},
    {city: 'Burgkirchen an der Alz', zip: '84508'},
    {city: 'Burgkunstadt', zip: '96224'},
    {city: 'Burglahr', zip: '57632'},
    {city: 'Burglauer', zip: '97724'},
    {city: 'Burglengenfeld', zip: '93133'},
    {city: 'Burg (Mosel)', zip: '56843'},
    {city: 'Burgoberbach', zip: '91595'},
    {city: 'Burgpreppach', zip: '97496'},
    {city: 'Burgrieden', zip: '88483'},
    {city: 'Burgsalach', zip: '91790'},
    {city: 'Burgschwalbach', zip: '65558'},
    {city: 'Burgsinn', zip: '97775'},
    {city: 'Burgsponheim', zip: '55595'},
    {city: 'Burg (Spreewald)', zip: '03096'},
    {city: 'Burgstädt', zip: '09217'},
    {city: 'Bürgstadt', zip: '63927'},
    {city: 'Burgstall', zip: '39517'},
    {city: 'Burg Stargard', zip: '17094'},
    {city: 'Burgstetten', zip: '71576'},
    {city: 'Burgthann', zip: '90559'},
    {city: 'Burgwald', zip: '35099'},
    {city: 'Burgwalde', zip: '37318'},
    {city: 'Burgwedel', zip: '30938'},
    {city: 'Burgwindheim', zip: '96154'},
    {city: 'Burk', zip: '91596'},
    {city: 'Burkardroth', zip: '97705'},
    {city: 'Burkau', zip: '01906'},
    {city: 'Burkhardtsdorf', zip: '09235'},
    {city: 'Burladingen', zip: '72393'},
    {city: 'Burow', zip: '17089'},
    {city: 'Burrweiler', zip: '76835'},
    {city: 'Burscheid', zip: '51399'},
    {city: 'Bürstadt', zip: '68642'},
    {city: 'Burtenbach', zip: '89349'},
    {city: 'Burtscheid', zip: '54424'},
    {city: 'Burweg', zip: '21709'},
    {city: 'Buschvitz', zip: '18528'},
    {city: 'Busdorf', zip: '24866'},
    {city: 'Buseck', zip: '35418'},
    {city: 'Busenberg', zip: '66996'},
    {city: 'Busenberg', zip: '76891'},
    {city: 'Busenhausen', zip: '57612'},
    {city: 'Busenwurth', zip: '25719'},
    {city: 'Büsingen am Hochrhein', zip: '78266'},
    {city: 'Büsum', zip: '25761'},
    {city: 'Büsumer Deichhausen', zip: '25761'},
    {city: 'Butjadingen', zip: '26969'},
    {city: 'Bütow', zip: '17209'},
    {city: 'Büttel', zip: '25572'},
    {city: 'Büttelborn', zip: '64572'},
    {city: 'Buttenheim', zip: '96155'},
    {city: 'Buttenwiesen', zip: '86647'},
    {city: 'Bütthard', zip: '97244'},
    {city: 'Buttlar', zip: '36419'},
    {city: 'Buttstädt', zip: '99628'},
    {city: 'Büttstedt', zip: '37359'},
    {city: 'Butzbach', zip: '35510'},
    {city: 'Butzow', zip: '17392'},
    {city: 'Bützow', zip: '18246'},
    {city: 'Buxheim', zip: '87740'},
    {city: 'Buxheim', zip: '85114'},
    {city: 'Buxtehude', zip: '21614'},
    {city: 'Byhleguhre-Byhlen', zip: '15913'},
    {city: 'Caan', zip: '56237'},
    {city: 'Caaschwitz', zip: '07586'},
    {city: 'Cadenberge', zip: '21781'},
    {city: 'Cadolzburg', zip: '90556'},
    {city: 'Calau', zip: '03205'},
    {city: 'Calberlah', zip: '38547'},
    {city: 'Calbe (Saale)', zip: '39240'},
    {city: 'Calden', zip: '34379'},
    {city: 'Callbach', zip: '67829'},
    {city: 'Callenberg', zip: '09337'},
    {city: 'Calvörde', zip: '39359'},
    {city: 'Calvörde', zip: '39638'},
    {city: 'Calw', zip: '75365'},
    {city: 'Cambs', zip: '19067'},
    {city: 'Cammin', zip: '18195'},
    {city: 'Cappeln (Oldenburg)', zip: '49692'},
    {city: 'Carinerland', zip: '18233'},
    {city: 'Carinerland', zip: '18236'},
    {city: 'Carlow', zip: '19217'},
    {city: 'Carlsberg', zip: '67316'},
    {city: 'Carmzow-Wallmow', zip: '17291'},
    {city: 'Carpin', zip: '17237'},
    {city: 'Casekow', zip: '16306'},
    {city: 'Castell', zip: '97355'},
    {city: 'Castrop-Rauxel', zip: '44575'},
    {city: 'Castrop-Rauxel', zip: '44577'},
    {city: 'Castrop-Rauxel', zip: '44579'},
    {city: 'Castrop-Rauxel', zip: '44581'},
    {city: 'Cavertitz', zip: '04758'},
    {city: 'Celle', zip: '29221'},
    {city: 'Celle', zip: '29223'},
    {city: 'Celle', zip: '29225'},
    {city: 'Celle', zip: '29227'},
    {city: 'Celle', zip: '29229'},
    {city: 'Cham', zip: '93413'},
    {city: 'Chamerau', zip: '93466'},
    {city: 'Charlottenberg', zip: '56379'},
    {city: 'Chemnitz', zip: '09111'},
    {city: 'Chemnitz', zip: '09112'},
    {city: 'Chemnitz', zip: '09113'},
    {city: 'Chemnitz', zip: '09114'},
    {city: 'Chemnitz', zip: '09116'},
    {city: 'Chemnitz', zip: '09117'},
    {city: 'Chemnitz', zip: '09119'},
    {city: 'Chemnitz', zip: '09120'},
    {city: 'Chemnitz', zip: '09122'},
    {city: 'Chemnitz', zip: '09123'},
    {city: 'Chemnitz', zip: '09125'},
    {city: 'Chemnitz', zip: '09126'},
    {city: 'Chemnitz', zip: '09127'},
    {city: 'Chemnitz', zip: '09128'},
    {city: 'Chemnitz', zip: '09130'},
    {city: 'Chemnitz', zip: '09131'},
    {city: 'Chemnitz', zip: '09224'},
    {city: 'Chemnitz', zip: '09228'},
    {city: 'Chemnitz', zip: '09247'},
    {city: 'Chieming', zip: '83339'},
    {city: 'Chiemsee', zip: '83209'},
    {city: 'Chiemsee', zip: '83256'},
    {city: 'Chorin', zip: '16230'},
    {city: 'Christes', zip: '98547'},
    {city: 'Christiansholm', zip: '24799'},
    {city: 'Christinenthal', zip: '25593'},
    {city: 'Clausen', zip: '66978'},
    {city: 'Claußnitz', zip: '09236'},
    {city: 'Clausthal-Zellerfeld', zip: '38667'},
    {city: 'Clausthal-Zellerfeld', zip: '38678'},
    {city: 'Clausthal-Zellerfeld', zip: '38707'},
    {city: 'Clausthal-Zellerfeld', zip: '38709'},
    {city: 'Cleebronn', zip: '74389'},
    {city: 'Clenze', zip: '29459'},
    {city: 'Clingen', zip: '99718'},
    {city: 'Cloppenburg', zip: '49661'},
    {city: 'Coburg', zip: '96450'},
    {city: 'Cochem', zip: '56812'},
    {city: 'Coesfeld', zip: '48653'},
    {city: 'Cölbe', zip: '35091'},
    {city: 'Colbitz', zip: '39326'},
    {city: 'Colditz', zip: '04680'},
    {city: 'Collenberg', zip: '97896'},
    {city: 'Collenberg', zip: '97903'},
    {city: 'Colmberg', zip: '91598'},
    {city: 'Colnrade', zip: '27243'},
    {city: 'Cölpin', zip: '17094'},
    {city: 'Contwig', zip: '66497'},
    {city: 'Coppenbrügge', zip: '31863'},
    {city: 'Cornberg', zip: '36219'},
    {city: 'Coswig', zip: '01640'},
    {city: 'Coswig (Anhalt)', zip: '06868'},
    {city: 'Coswig (Anhalt)', zip: '06869'},
    {city: 'Cottbus', zip: '03042'},
    {city: 'Cottbus', zip: '03044'},
    {city: 'Cottbus', zip: '03046'},
    {city: 'Cottbus', zip: '03048'},
    {city: 'Cottbus', zip: '03050'},
    {city: 'Cottbus', zip: '03051'},
    {city: 'Cottbus', zip: '03052'},
    {city: 'Cottbus', zip: '03053'},
    {city: 'Cottbus', zip: '03054'},
    {city: 'Cottbus', zip: '03055'},
    {city: 'Crailsheim', zip: '74564'},
    {city: 'Crailsheim', zip: '74597'},
    {city: 'Cramberg', zip: '65558'},
    {city: 'Cramme', zip: '38312'},
    {city: 'Cramonshagen', zip: '19071'},
    {city: 'Creglingen', zip: '97993'},
    {city: 'Cremlingen', zip: '38162'},
    {city: 'Creußen', zip: '95473'},
    {city: 'Crimla', zip: '07557'},
    {city: 'Crimmitschau', zip: '08451'},
    {city: 'Crinitz', zip: '03246'},
    {city: 'Crinitzberg', zip: '08147'},
    {city: 'Crivitz', zip: '19089'},
    {city: 'Cronenberg', zip: '67744'},
    {city: 'Crossen an der Elster', zip: '07613'},
    {city: 'Crostwitz', zip: '01920'},
    {city: 'Crottendorf', zip: '09474'},
    {city: 'Cumlosen', zip: '19322'},
    {city: 'Cunewalde', zip: '02733'},
    {city: 'Cursdorf', zip: '98744'},
    {city: 'Cuxhaven', zip: '27472'},
    {city: 'Cuxhaven', zip: '27474'},
    {city: 'Cuxhaven', zip: '27476'},
    {city: 'Cuxhaven', zip: '27478'},
    {city: 'Daaden', zip: '57567'},
    {city: 'Dabel', zip: '19406'},
    {city: 'Dabergotz', zip: '16818'},
    {city: 'Daberkow', zip: '17129'},
    {city: 'Dachau', zip: '85221'},
    {city: 'Dachsbach', zip: '91462'},
    {city: 'Dachsberg (Südschwarzwald)', zip: '79837'},
    {city: 'Dachsberg (Südschwarzwald)', zip: '79875'},
    {city: 'Dachsenhausen', zip: '56340'},
    {city: 'Dachwig', zip: '99100'},
    {city: 'Dackenheim', zip: '67273'},
    {city: 'Dackscheid', zip: '54649'},
    {city: 'Dagebüll', zip: '25899'},
    {city: 'Dägeling', zip: '25578'},
    {city: 'Dahlem', zip: '53949'},
    {city: 'Dahlem', zip: '54636'},
    {city: 'Dahlem', zip: '21368'},
    {city: 'Dahlen', zip: '04774'},
    {city: 'Dahlenburg', zip: '21368'},
    {city: 'Dahlheim', zip: '56348'},
    {city: 'Dahlum', zip: '38170'},
    {city: 'Dahme', zip: '23747'},
    {city: 'Dahme/Mark', zip: '15936'},
    {city: 'Dahmen', zip: '17166'},
    {city: 'Dahmetal', zip: '15936'},
    {city: 'Dahmker', zip: '22946'},
    {city: 'Dahn', zip: '66994'},
    {city: 'Dahnen', zip: '54689'},
    {city: 'Dähre', zip: '29413'},
    {city: 'Daisendorf', zip: '88718'},
    {city: 'Daiting', zip: '86653'},
    {city: 'Dalberg', zip: '55595'},
    {city: 'Dalberg-Wendelstorf', zip: '19071'},
    {city: 'Daldorf', zip: '24635'},
    {city: 'Daleiden', zip: '54689'},
    {city: 'Dalheim', zip: '55278'},
    {city: 'Dalkendorf', zip: '17166'},
    {city: 'Dalldorf', zip: '21483'},
    {city: 'Dallgow-Döberitz', zip: '14624'},
    {city: 'Dambach', zip: '55765'},
    {city: 'Dambeck', zip: '19357'},
    {city: 'Damendorf', zip: '24361'},
    {city: 'Damflos', zip: '54413'},
    {city: 'Damlos', zip: '23738'},
    {city: 'Dammbach', zip: '63874'},
    {city: 'Damme', zip: '49401'},
    {city: 'Dammfleth', zip: '25554'},
    {city: 'Dammfleth', zip: '25573'},
    {city: 'Damnatz', zip: '29472'},
    {city: 'Damp', zip: '24351'},
    {city: 'Damscheid', zip: '55432'},
    {city: 'Damsdorf', zip: '23824'},
    {city: 'Damshagen', zip: '23936'},
    {city: 'Damshagen', zip: '23948'},
    {city: 'Dänischenhagen', zip: '24229'},
    {city: 'Dankerath', zip: '53520'},
    {city: 'Dannau', zip: '24329'},
    {city: 'Danndorf', zip: '38461'},
    {city: 'Dannenberg', zip: '29451'},
    {city: 'Dannenfels', zip: '67814'},
    {city: 'Dannewerk', zip: '24867'},
    {city: 'Dannstadt-Schauernheim', zip: '67125'},
    {city: 'Dargelin', zip: '17498'},
    {city: 'Dargen', zip: '17419'},
    {city: 'Dargen', zip: '17429'},
    {city: 'Dargun', zip: '17159'},
    {city: 'Darmstadt', zip: '64283'},
    {city: 'Darmstadt', zip: '64285'},
    {city: 'Darmstadt', zip: '64287'},
    {city: 'Darmstadt', zip: '64289'},
    {city: 'Darmstadt', zip: '64291'},
    {city: 'Darmstadt', zip: '64293'},
    {city: 'Darmstadt', zip: '64295'},
    {city: 'Darmstadt', zip: '64297'},
    {city: 'Darscheid', zip: '54552'},
    {city: 'Darstein', zip: '76848'},
    {city: 'Dasburg', zip: '54689'},
    {city: 'Dasing', zip: '86453'},
    {city: 'Dassel', zip: '37586'},
    {city: 'Dassendorf', zip: '21521'},
    {city: 'Dassow', zip: '23942'},
    {city: 'Dätgen', zip: '24589'},
    {city: 'Datteln', zip: '45711'},
    {city: 'Dattenberg', zip: '53547'},
    {city: 'Datzeroth', zip: '56589'},
    {city: 'Datzetal', zip: '17099'},
    {city: 'Daubach', zip: '56412'},
    {city: 'Daubach', zip: '55566'},
    {city: 'Dauchingen', zip: '78083'},
    {city: 'Daun', zip: '54550'},
    {city: 'Dausenau', zip: '56132'},
    {city: 'Dautmergen', zip: '72356'},
    {city: 'Dautphetal', zip: '35232'},
    {city: 'Dauwelshausen', zip: '54673'},
    {city: 'Daxweiler', zip: '55442'},
    {city: 'Dechow', zip: '19217'},
    {city: 'Deckenpfronn', zip: '75392'},
    {city: 'Dedelstorf', zip: '29386'},
    {city: 'Dedenbach', zip: '53426'},
    {city: 'Deensen', zip: '37627'},
    {city: 'Deesbach', zip: '98744'},
    {city: 'Deesen', zip: '56237'},
    {city: 'Deggendorf', zip: '94469'},
    {city: 'Deggenhausertal', zip: '88693'},
    {city: 'Deggingen', zip: '73312'},
    {city: 'Deggingen', zip: '73326'},
    {city: 'Deidesheim', zip: '67146'},
    {city: 'Deidesheim', zip: '67157'},
    {city: 'Deidesheim', zip: '67435'},
    {city: 'Deidesheim', zip: '67466'},
    {city: 'Deilingen', zip: '78586'},
    {city: 'Deimberg', zip: '67742'},
    {city: 'Deining', zip: '92364'},
    {city: 'Deiningen', zip: '86738'},
    {city: 'Deinste', zip: '21717'},
    {city: 'Deinstedt', zip: '27446'},
    {city: 'Deisenhausen', zip: '86489'},
    {city: 'Deißlingen', zip: '78652'},
    {city: 'Deizisau', zip: '73779'},
    {city: 'Delbrück', zip: '33129'},
    {city: 'Delingsdorf', zip: '22941'},
    {city: 'Delitzsch', zip: '04509'},
    {city: 'Dellfeld', zip: '66503'},
    {city: 'Delligsen', zip: '31073'},
    {city: 'Dellstedt', zip: '25786'},
    {city: 'Delmenhorst', zip: '27749'},
    {city: 'Delmenhorst', zip: '27751'},
    {city: 'Delmenhorst', zip: '27753'},
    {city: 'Delmenhorst', zip: '27755'},
    {city: 'Delve', zip: '25788'},
    {city: 'Demen', zip: '19089'},
    {city: 'Demerath', zip: '54552'},
    {city: 'Demitz-Thumitz', zip: '01877'},
    {city: 'Demmin', zip: '17109'},
    {city: 'Denkendorf', zip: '85095'},
    {city: 'Denkendorf', zip: '73770'},
    {city: 'Denkingen', zip: '78554'},
    {city: 'Denkingen', zip: '78588'},
    {city: 'Denklingen', zip: '86920'},
    {city: 'Denkte', zip: '38321'},
    {city: 'Dennheritz', zip: '08393'},
    {city: 'Dennweiler-Frohnbach', zip: '66871'},
    {city: 'Densborn', zip: '54570'},
    {city: 'Dentlein am Forst', zip: '91599'},
    {city: 'Denzlingen', zip: '79211'},
    {city: 'Derental', zip: '37691'},
    {city: 'Dermbach', zip: '36466'},
    {city: 'Dernau', zip: '53507'},
    {city: 'Dernbach', zip: '56307'},
    {city: 'Dernbach', zip: '76857'},
    {city: 'Dernbach (Westerwald)', zip: '56428'},
    {city: 'Dersau', zip: '24326'},
    {city: 'Derschen', zip: '57520'},
    {city: 'Dersekow', zip: '17498'},
    {city: 'Dersenow', zip: '19260'},
    {city: 'Dersum', zip: '26906'},
    {city: 'Desloch', zip: '55592'},
    {city: 'Dessau-Roßlau', zip: '06842'},
    {city: 'Dessau-Roßlau', zip: '06844'},
    {city: 'Dessau-Roßlau', zip: '06846'},
    {city: 'Dessau-Roßlau', zip: '06847'},
    {city: 'Dessau-Roßlau', zip: '06849'},
    {city: 'Dessau-Roßlau', zip: '06861'},
    {city: 'Dessau-Roßlau', zip: '06862'},
    {city: 'Dessighofen', zip: '56357'},
    {city: 'Detern', zip: '26847'},
    {city: 'Detmold', zip: '32756'},
    {city: 'Detmold', zip: '32758'},
    {city: 'Detmold', zip: '32760'},
    {city: 'Dettelbach', zip: '97337'},
    {city: 'Dettenhausen', zip: '72135'},
    {city: 'Dettenheim', zip: '76706'},
    {city: 'Dettighofen', zip: '79802'},
    {city: 'Dettingen an der Erms', zip: '72581'},
    {city: 'Dettingen an der Iller', zip: '88451'},
    {city: 'Dettingen unter Teck', zip: '73265'},
    {city: 'Dettmannsdorf', zip: '18334'},
    {city: 'Dettum', zip: '38173'},
    {city: 'Detzem', zip: '54340'},
    {city: 'Deudesfeld', zip: '54570'},
    {city: 'Deuerling', zip: '93180'},
    {city: 'Deuselbach', zip: '54411'},
    {city: 'Deutsch Evern', zip: '21407'},
    {city: 'Deutschneudorf', zip: '09548'},
    {city: 'Dexheim', zip: '55278'},
    {city: 'Deyelsdorf', zip: '18513'},
    {city: 'Dhronecken', zip: '54426'},
    {city: 'Dichtelbach', zip: '55494'},
    {city: 'Dickel', zip: '49453'},
    {city: 'Dickendorf', zip: '57520'},
    {city: 'Dickenschied', zip: '55483'},
    {city: 'Dickesbach', zip: '55758'},
    {city: 'Didderse', zip: '38530'},
    {city: 'Diebach', zip: '91583'},
    {city: 'Dieblich', zip: '56332'},
    {city: 'Dieburg', zip: '64807'},
    {city: 'Diedorf', zip: '86420'},
    {city: 'Diefenbach', zip: '54538'},
    {city: 'Diekholzen', zip: '31199'},
    {city: 'Diekhusen-Fahrstedt', zip: '25709'},
    {city: 'Dielheim', zip: '69234'},
    {city: 'Dielkirchen', zip: '67811'},
    {city: 'Dielmissen', zip: '37633'},
    {city: 'Diemelsee', zip: '34519'},
    {city: 'Diemelstadt', zip: '34474'},
    {city: 'Dienethal', zip: '56379'},
    {city: 'Dienheim', zip: '55276'},
    {city: 'Diensdorf-Radlow', zip: '15864'},
    {city: 'Dienstweiler', zip: '55765'},
    {city: 'Diepenau', zip: '31603'},
    {city: 'Diepholz', zip: '49356'},
    {city: 'Diera-Zehren', zip: '01665'},
    {city: 'Dierbach', zip: '76889'},
    {city: 'Dierdorf', zip: '56269'},
    {city: 'Dierfeld', zip: '54533'},
    {city: 'Dierhagen', zip: '18347'},
    {city: 'Dierscheid', zip: '54523'},
    {city: 'Diesdorf', zip: '29413'},
    {city: 'Diespeck', zip: '91456'},
    {city: 'Dießen am Ammersee', zip: '86911'},
    {city: 'Dietenheim', zip: '89165'},
    {city: 'Dietenhofen', zip: '90599'},
    {city: 'Dieterode', zip: '37318'},
    {city: 'Dietersburg', zip: '84378'},
    {city: 'Dietersheim', zip: '91463'},
    {city: 'Dieterskirchen', zip: '92542'},
    {city: 'Dietfurt an der Altmühl', zip: '92345'},
    {city: 'Diethardt', zip: '56355'},
    {city: 'Dietingen', zip: '78661'},
    {city: 'Dietmannsried', zip: '87463'},
    {city: 'Dietramszell', zip: '83623'},
    {city: 'Dietrichingen', zip: '66484'},
    {city: 'Dietzenbach', zip: '63128'},
    {city: 'Dietzenrode/Vatterode', zip: '37318'},
    {city: 'Dietzhölztal', zip: '35716'},
    {city: 'Diez', zip: '65582'},
    {city: 'Dill', zip: '55487'},
    {city: 'Dillenburg', zip: '35683'},
    {city: 'Dillenburg', zip: '35684'},
    {city: 'Dillenburg', zip: '35685'},
    {city: 'Dillenburg', zip: '35686'},
    {city: 'Dillenburg', zip: '35687'},
    {city: 'Dillenburg', zip: '35688'},
    {city: 'Dillenburg', zip: '35689'},
    {city: 'Dillenburg', zip: '35690'},
    {city: 'Dillendorf', zip: '55481'},
    {city: 'Dillingen a.d.Donau', zip: '89407'},
    {city: 'Dillingen/Saar', zip: '66763'},
    {city: 'Dillstädt', zip: '98530'},
    {city: 'Dimbach', zip: '76848'},
    {city: 'Dingdorf', zip: '54614'},
    {city: 'Dingelstädt', zip: '37351'},
    {city: 'Dingen', zip: '25715'},
    {city: 'Dingolfing', zip: '84130'},
    {city: 'Dingolshausen', zip: '97497'},
    {city: 'Dingsleben', zip: '98646'},
    {city: 'Dinkelsbühl', zip: '91550'},
    {city: 'Dinkelscherben', zip: '86424'},
    {city: 'Dinklage', zip: '49413'},
    {city: 'Dinslaken', zip: '46535'},
    {city: 'Dinslaken', zip: '46537'},
    {city: 'Dinslaken', zip: '46539'},
    {city: 'Dintesheim', zip: '55234'},
    {city: 'Dipperz', zip: '36160'},
    {city: 'Dippoldiswalde', zip: '01744'},
    {city: 'Dirlewang', zip: '87742'},
    {city: 'Dirlewang', zip: '87782'},
    {city: 'Dirmstein', zip: '67246'},
    {city: 'Dischingen', zip: '89561'},
    {city: 'Dissen', zip: '49201'},
    {city: 'Dissen-Striesow', zip: '03096'},
    {city: 'Ditfurt', zip: '06484'},
    {city: 'Ditscheid', zip: '56729'},
    {city: 'Dittelbrunn', zip: '97456'},
    {city: 'Dittelsheim-Heßloch', zip: '67596'},
    {city: 'Dittenheim', zip: '91723'},
    {city: 'Dittersdorf', zip: '07907'},
    {city: 'Dittweiler', zip: '66903'},
    {city: 'Ditzingen', zip: '71254'},
    {city: 'Divitz-Spoldershagen', zip: '18314'},
    {city: 'Dobbertin', zip: '19399'},
    {city: 'Dobbin-Linstow', zip: '18292'},
    {city: 'Dobel', zip: '75335'},
    {city: 'Döbeln', zip: '04720'},
    {city: 'Doberlug-Kirchhain', zip: '03253'},
    {city: 'Döbern', zip: '03159'},
    {city: 'Doberschau-Gaußig', zip: '01877'},
    {city: 'Doberschau-Gaußig', zip: '02633'},
    {city: 'Doberschau-Gaußig', zip: '02692'},
    {city: 'Doberschütz', zip: '04838'},
    {city: 'Dobersdorf', zip: '24232'},
    {city: 'Dobin am See', zip: '19067'},
    {city: 'Dobitschen', zip: '04626'},
    {city: 'Döbritschen', zip: '99441'},
    {city: 'Döbritz', zip: '07381'},
    {city: 'Dockendorf', zip: '54636'},
    {city: 'Dockweiler', zip: '54552'},
    {city: 'Dodenburg', zip: '54518'},
    {city: 'Dogern', zip: '79804'},
    {city: 'Döhlau', zip: '95182'},
    {city: 'Dohma', zip: '01796'},
    {city: 'Dohm-Lammersdorf', zip: '54576'},
    {city: 'Dohna', zip: '01809'},
    {city: 'Dohr', zip: '56812'},
    {city: 'Dohren', zip: '21255'},
    {city: 'Dohren', zip: '49770'},
    {city: 'Dolgen am See', zip: '18299'},
    {city: 'Dolgesheim', zip: '55278'},
    {city: 'Dollern', zip: '21739'},
    {city: 'Dollerup', zip: '24989'},
    {city: 'Dollnstein', zip: '91795'},
    {city: 'Döllstädt', zip: '99100'},
    {city: 'Dombühl', zip: '91601'},
    {city: 'Dömitz', zip: '19303'},
    {city: 'Dommershausen', zip: '56290'},
    {city: 'Dommitzsch', zip: '04880'},
    {city: 'Domsühl', zip: '19374'},
    {city: 'Donaueschingen', zip: '78166'},
    {city: 'Donaustauf', zip: '93093'},
    {city: 'Donauwörth', zip: '86609'},
    {city: 'Donnersdorf', zip: '97499'},
    {city: 'Donsieders', zip: '66978'},
    {city: 'Donzdorf', zip: '73072'},
    {city: 'Dörentrup', zip: '32694'},
    {city: 'Dorfchemnitz', zip: '09619'},
    {city: 'Dorfen', zip: '84405'},
    {city: 'Dorfhain', zip: '01738'},
    {city: 'Dörfles-Esbach', zip: '96487'},
    {city: 'Dorf Mecklenburg', zip: '23972'},
    {city: 'Dorfprozelten', zip: '97904'},
    {city: 'Dormagen', zip: '41539'},
    {city: 'Dormagen', zip: '41540'},
    {city: 'Dormagen', zip: '41541'},
    {city: 'Dormagen', zip: '41542'},
    {city: 'Dormettingen', zip: '72358'},
    {city: 'Dormitz', zip: '91077'},
    {city: 'Dörnberg', zip: '56377'},
    {city: 'Dörnberg', zip: '56379'},
    {city: 'Dornburg', zip: '65599'},
    {city: 'Dornburg-Camburg', zip: '07774'},
    {city: 'Dorn-Dürkheim', zip: '67585'},
    {city: 'Dornhan', zip: '72175'},
    {city: 'Dornheim', zip: '99310'},
    {city: 'Dornholzhausen', zip: '56357'},
    {city: 'Dörnick', zip: '24326'},
    {city: 'Dornstadt', zip: '89160'},
    {city: 'Dornstetten', zip: '72280'},
    {city: 'Dornum', zip: '26553'},
    {city: 'Dörpen', zip: '26892'},
    {city: 'Dörphof', zip: '24398'},
    {city: 'Dörpling', zip: '25794'},
    {city: 'Dörpstedt', zip: '24869'},
    {city: 'Dörrebach', zip: '55444'},
    {city: 'Dörrenbach', zip: '76889'},
    {city: 'Dörrmoschel', zip: '67806'},
    {city: 'Dörscheid', zip: '56348'},
    {city: 'Dörsdorf', zip: '56370'},
    {city: 'Dorsel', zip: '53533'},
    {city: 'Dorsheim', zip: '55452'},
    {city: 'Dorstadt', zip: '38312'},
    {city: 'Dorsten', zip: '46282'},
    {city: 'Dorsten', zip: '46284'},
    {city: 'Dorsten', zip: '46286'},
    {city: 'Dörth', zip: '56281'},
    {city: 'Dortmund', zip: '44135'},
    {city: 'Dortmund', zip: '44137'},
    {city: 'Dortmund', zip: '44139'},
    {city: 'Dortmund', zip: '44141'},
    {city: 'Dortmund', zip: '44143'},
    {city: 'Dortmund', zip: '44145'},
    {city: 'Dortmund', zip: '44147'},
    {city: 'Dortmund', zip: '44149'},
    {city: 'Dortmund', zip: '44225'},
    {city: 'Dortmund', zip: '44227'},
    {city: 'Dortmund', zip: '44229'},
    {city: 'Dortmund', zip: '44263'},
    {city: 'Dortmund', zip: '44265'},
    {city: 'Dortmund', zip: '44267'},
    {city: 'Dortmund', zip: '44269'},
    {city: 'Dortmund', zip: '44287'},
    {city: 'Dortmund', zip: '44289'},
    {city: 'Dortmund', zip: '44309'},
    {city: 'Dortmund', zip: '44319'},
    {city: 'Dortmund', zip: '44328'},
    {city: 'Dortmund', zip: '44329'},
    {city: 'Dortmund', zip: '44339'},
    {city: 'Dortmund', zip: '44357'},
    {city: 'Dortmund', zip: '44359'},
    {city: 'Dortmund', zip: '44369'},
    {city: 'Dortmund', zip: '44379'},
    {city: 'Dortmund', zip: '44388'},
    {city: 'Dörverden', zip: '27313'},
    {city: 'Dörzbach', zip: '74677'},
    {city: 'Döschnitz', zip: '07429'},
    {city: 'Dossenheim', zip: '69221'},
    {city: 'Dötlingen', zip: '27801'},
    {city: 'Dotternhausen', zip: '72359'},
    {city: 'Döttesfeld', zip: '56305'},
    {city: 'Drachhausen', zip: '03185'},
    {city: 'Drachselsried', zip: '94256'},
    {city: 'Drackenstein', zip: '73345'},
    {city: 'Drage', zip: '21423'},
    {city: 'Drage', zip: '25582'},
    {city: 'Drage', zip: '25840'},
    {city: 'Drage', zip: '25878'},
    {city: 'Dragun', zip: '19205'},
    {city: 'Drahnsdorf', zip: '15938'},
    {city: 'Drakenburg', zip: '31623'},
    {city: 'Dransfeld', zip: '37127'},
    {city: 'Dranske', zip: '18556'},
    {city: 'Drebach', zip: '09430'},
    {city: 'Drebber', zip: '49457'},
    {city: 'Drebkau', zip: '03116'},
    {city: 'Drechow', zip: '18465'},
    {city: 'Drees', zip: '53520'},
    {city: 'Dreetz', zip: '16845'},
    {city: 'Dreetz', zip: '18249'},
    {city: 'Dreggers', zip: '23845'},
    {city: 'Drehnow', zip: '03185'},
    {city: 'Dreieich', zip: '63303'},
    {city: 'Dreifelden', zip: '57629'},
    {city: 'Drei Gleichen', zip: '99869'},
    {city: 'Dreiheide', zip: '04860'},
    {city: 'Dreikirchen', zip: '56414'},
    {city: 'Dreis', zip: '54518'},
    {city: 'Dreisbach', zip: '56472'},
    {city: 'Dreis-Brück', zip: '54552'},
    {city: 'Dreisen', zip: '67816'},
    {city: 'Dreitzsch', zip: '07819'},
    {city: 'Drelsdorf', zip: '25853'},
    {city: 'Drensteinfurt', zip: '48317'},
    {city: 'Drentwede', zip: '49406'},
    {city: 'Dreschvitz', zip: '18573'},
    {city: 'Dresden', zip: '01067'},
    {city: 'Dresden', zip: '01069'},
    {city: 'Dresden', zip: '01097'},
    {city: 'Dresden', zip: '01099'},
    {city: 'Dresden', zip: '01108'},
    {city: 'Dresden', zip: '01109'},
    {city: 'Dresden', zip: '01127'},
    {city: 'Dresden', zip: '01129'},
    {city: 'Dresden', zip: '01139'},
    {city: 'Dresden', zip: '01156'},
    {city: 'Dresden', zip: '01157'},
    {city: 'Dresden', zip: '01159'},
    {city: 'Dresden', zip: '01169'},
    {city: 'Dresden', zip: '01187'},
    {city: 'Dresden', zip: '01189'},
    {city: 'Dresden', zip: '01217'},
    {city: 'Dresden', zip: '01219'},
    {city: 'Dresden', zip: '01237'},
    {city: 'Dresden', zip: '01239'},
    {city: 'Dresden', zip: '01257'},
    {city: 'Dresden', zip: '01259'},
    {city: 'Dresden', zip: '01277'},
    {city: 'Dresden', zip: '01279'},
    {city: 'Dresden', zip: '01307'},
    {city: 'Dresden', zip: '01309'},
    {city: 'Dresden', zip: '01324'},
    {city: 'Dresden', zip: '01326'},
    {city: 'Dresden', zip: '01328'},
    {city: 'Dresden', zip: '01465'},
    {city: 'Drestedt', zip: '21255'},
    {city: 'Drestedt', zip: '21279'},
    {city: 'Driedorf', zip: '35759'},
    {city: 'Drochtersen', zip: '21706'},
    {city: 'Drognitz', zip: '07338'},
    {city: 'Drolshagen', zip: '57489'},
    {city: 'Droyßig', zip: '06722'},
    {city: 'Düchelsdorf', zip: '23847'},
    {city: 'Ducherow', zip: '17398'},
    {city: 'Duchroth', zip: '55585'},
    {city: 'Duchroth', zip: '67826'},
    {city: 'Dudeldorf', zip: '54647'},
    {city: 'Düdenbüttel', zip: '21709'},
    {city: 'Dudenhofen', zip: '67373'},
    {city: 'Duderstadt', zip: '37115'},
    {city: 'Duggendorf', zip: '93182'},
    {city: 'Duggendorf', zip: '93195'},
    {city: 'Duingen', zip: '31089'},
    {city: 'Duisburg', zip: '47051'},
    {city: 'Duisburg', zip: '47053'},
    {city: 'Duisburg', zip: '47055'},
    {city: 'Duisburg', zip: '47057'},
    {city: 'Duisburg', zip: '47058'},
    {city: 'Duisburg', zip: '47059'},
    {city: 'Duisburg', zip: '47119'},
    {city: 'Duisburg', zip: '47137'},
    {city: 'Duisburg', zip: '47138'},
    {city: 'Duisburg', zip: '47139'},
    {city: 'Duisburg', zip: '47166'},
    {city: 'Duisburg', zip: '47167'},
    {city: 'Duisburg', zip: '47169'},
    {city: 'Duisburg', zip: '47178'},
    {city: 'Duisburg', zip: '47179'},
    {city: 'Duisburg', zip: '47198'},
    {city: 'Duisburg', zip: '47199'},
    {city: 'Duisburg', zip: '47226'},
    {city: 'Duisburg', zip: '47228'},
    {city: 'Duisburg', zip: '47229'},
    {city: 'Duisburg', zip: '47239'},
    {city: 'Duisburg', zip: '47249'},
    {city: 'Duisburg', zip: '47259'},
    {city: 'Duisburg', zip: '47269'},
    {city: 'Duisburg', zip: '47279'},
    {city: 'Dülmen', zip: '48249'},
    {city: 'Dümmer', zip: '19073'},
    {city: 'Dummerstorf', zip: '18196'},
    {city: 'Dümpelfeld', zip: '53520'},
    {city: 'Dünfus', zip: '56754'},
    {city: 'Düngenheim', zip: '56761'},
    {city: 'Dunningen', zip: '78655'},
    {city: 'Dunningen', zip: '78713'},
    {city: 'Dünsen', zip: '27243'},
    {city: 'Dunsum', zip: '25938'},
    {city: 'Dunum', zip: '26427'},
    {city: 'Dünwald', zip: '99976'},
    {city: 'Dunzweiler', zip: '66916'},
    {city: 'Duppach', zip: '54597'},
    {city: 'Durach', zip: '87471'},
    {city: 'Durbach', zip: '77770'},
    {city: 'Dürbheim', zip: '78589'},
    {city: 'Durchhausen', zip: '78591'},
    {city: 'Düren', zip: '52349'},
    {city: 'Düren', zip: '52351'},
    {city: 'Düren', zip: '52353'},
    {city: 'Düren', zip: '52355'},
    {city: 'Durlangen', zip: '73568'},
    {city: 'Dürmentingen', zip: '88525'},
    {city: 'Durmersheim', zip: '76448'},
    {city: 'Dürnau', zip: '73105'},
    {city: 'Dürnau', zip: '88422'},
    {city: 'Dürrhennersdorf', zip: '02708'},
    {city: 'Dürrholz', zip: '56307'},
    {city: 'Dürrlauingen', zip: '89350'},
    {city: 'Dürrröhrsdorf-Dittersbach', zip: '01833'},
    {city: 'Dürrwangen', zip: '91602'},
    {city: 'Düsseldorf', zip: '40210'},
    {city: 'Düsseldorf', zip: '40211'},
    {city: 'Düsseldorf', zip: '40212'},
    {city: 'Düsseldorf', zip: '40213'},
    {city: 'Düsseldorf', zip: '40215'},
    {city: 'Düsseldorf', zip: '40217'},
    {city: 'Düsseldorf', zip: '40219'},
    {city: 'Düsseldorf', zip: '40221'},
    {city: 'Düsseldorf', zip: '40223'},
    {city: 'Düsseldorf', zip: '40225'},
    {city: 'Düsseldorf', zip: '40227'},
    {city: 'Düsseldorf', zip: '40229'},
    {city: 'Düsseldorf', zip: '40231'},
    {city: 'Düsseldorf', zip: '40233'},
    {city: 'Düsseldorf', zip: '40235'},
    {city: 'Düsseldorf', zip: '40237'},
    {city: 'Düsseldorf', zip: '40239'},
    {city: 'Düsseldorf', zip: '40468'},
    {city: 'Düsseldorf', zip: '40470'},
    {city: 'Düsseldorf', zip: '40472'},
    {city: 'Düsseldorf', zip: '40474'},
    {city: 'Düsseldorf', zip: '40476'},
    {city: 'Düsseldorf', zip: '40477'},
    {city: 'Düsseldorf', zip: '40479'},
    {city: 'Düsseldorf', zip: '40489'},
    {city: 'Düsseldorf', zip: '40545'},
    {city: 'Düsseldorf', zip: '40547'},
    {city: 'Düsseldorf', zip: '40549'},
    {city: 'Düsseldorf', zip: '40589'},
    {city: 'Düsseldorf', zip: '40591'},
    {city: 'Düsseldorf', zip: '40593'},
    {city: 'Düsseldorf', zip: '40595'},
    {city: 'Düsseldorf', zip: '40597'},
    {city: 'Düsseldorf', zip: '40599'},
    {city: 'Düsseldorf', zip: '40625'},
    {city: 'Düsseldorf', zip: '40627'},
    {city: 'Düsseldorf', zip: '40629'},
    {city: 'Düsseldorf', zip: '40721'},
    {city: 'Dußlingen', zip: '72144'},
    {city: 'Duvensee', zip: '23898'},
    {city: 'Ebeleben', zip: '99713'},
    {city: 'Ebelsbach', zip: '97500'},
    {city: 'Ebensfeld', zip: '96250'},
    {city: 'Ebenweiler', zip: '88370'},
    {city: 'Eberbach', zip: '64754'},
    {city: 'Eberbach', zip: '69412'},
    {city: 'Eberbach', zip: '69429'},
    {city: 'Eberbach', zip: '69434'},
    {city: 'Eberdingen', zip: '71735'},
    {city: 'Eberfing', zip: '82390'},
    {city: 'Ebergötzen', zip: '37136'},
    {city: 'Eberhardzell', zip: '88436'},
    {city: 'Ebermannsdorf', zip: '92263'},
    {city: 'Ebermannstadt', zip: '91320'},
    {city: 'Ebern', zip: '96106'},
    {city: 'Ebernhahn', zip: '56424'},
    {city: 'Ebersbach', zip: '01561'},
    {city: 'Ebersbach an der Fils', zip: '73061'},
    {city: 'Ebersbach-Musbach', zip: '88371'},
    {city: 'Ebersbach-Neugersdorf', zip: '02727'},
    {city: 'Ebersbach-Neugersdorf', zip: '02730'},
    {city: 'Ebersberg', zip: '85560'},
    {city: 'Ebersburg', zip: '36157'},
    {city: 'Ebersdorf', zip: '27432'},
    {city: 'Ebersdorf b.Coburg', zip: '96237'},
    {city: 'Ebershausen', zip: '86491'},
    {city: 'Eberstadt', zip: '74246'},
    {city: 'Eberstedt', zip: '99518'},
    {city: 'Eberswalde', zip: '16225'},
    {city: 'Eberswalde', zip: '16227'},
    {city: 'Ebertshausen', zip: '56370'},
    {city: 'Ebertsheim', zip: '67280'},
    {city: 'Ebhausen', zip: '72224'},
    {city: 'Ebnath', zip: '95683'},
    {city: 'Ebrach', zip: '96157'},
    {city: 'Ebringen', zip: '79285'},
    {city: 'Ebsdorfergrund', zip: '35085'},
    {city: 'Ebstorf', zip: '29574'},
    {city: 'Echem', zip: '21379'},
    {city: 'Eching', zip: '85386'},
    {city: 'Eching', zip: '84174'},
    {city: 'Eching am Ammersee', zip: '82279'},
    {city: 'Echternacherbrück', zip: '54668'},
    {city: 'Echtershausen', zip: '54636'},
    {city: 'Echzell', zip: '61200'},
    {city: 'Echzell', zip: '61209'},
    {city: 'Eckartsberga', zip: '06648'},
    {city: 'Eckelsheim', zip: '55599'},
    {city: 'Eckenroth', zip: '55444'},
    {city: 'Eckental', zip: '90542'},
    {city: 'Eckernförde', zip: '24340'},
    {city: 'Eckersdorf', zip: '95488'},
    {city: 'Eckersweiler', zip: '55777'},
    {city: 'Eckfeld', zip: '54531'},
    {city: 'Ecklak', zip: '25572'},
    {city: 'Ecklingerode', zip: '37339'},
    {city: 'Eckstedt', zip: '99195'},
    {city: 'Eddelak', zip: '25715'},
    {city: 'Edelsfeld', zip: '92265'},
    {city: 'Edemissen', zip: '31234'},
    {city: 'Edenkoben', zip: '67480'},
    {city: 'Ederheim', zip: '86739'},
    {city: 'Edermünde', zip: '34295'},
    {city: 'Edersleben', zip: '06528'},
    {city: 'Edertal', zip: '34549'},
    {city: 'Edesheim', zip: '67483'},
    {city: 'Edewecht', zip: '26188'},
    {city: 'Ediger-Eller', zip: '56814'},
    {city: 'Edingen-Neckarhausen', zip: '68535'},
    {city: 'Edling', zip: '83533'},
    {city: 'Effelder', zip: '37359'},
    {city: 'Effeltrich', zip: '91090'},
    {city: 'Efringen-Kirchen', zip: '79588'},
    {city: 'Egeln', zip: '39435'},
    {city: 'Egelsbach', zip: '63329'},
    {city: 'Egenhausen', zip: '72227'},
    {city: 'Egenhofen', zip: '82281'},
    {city: 'Egesheim', zip: '78592'},
    {city: 'Egestorf', zip: '21272'},
    {city: 'Egg an der Günz', zip: '87743'},
    {city: 'Eggebek', zip: '24852'},
    {city: 'Eggenfelden', zip: '84307'},
    {city: 'Eggenstein-Leopoldshafen', zip: '76344'},
    {city: 'Eggenthal', zip: '87653'},
    {city: 'Eggermühlen', zip: '49577'},
    {city: 'Eggesin', zip: '17367'},
    {city: 'Eggesin', zip: '17375'},
    {city: 'Eggingen', zip: '79805'},
    {city: 'Egglham', zip: '84385'},
    {city: 'Egglkofen', zip: '84546'},
    {city: 'Eggolsheim', zip: '91330'},
    {city: 'Eggstätt', zip: '83125'},
    {city: 'Eggstedt', zip: '25721'},
    {city: 'Eging a.See', zip: '94535'},
    {city: 'Eglfing', zip: '82436'},
    {city: 'Egling', zip: '82544'},
    {city: 'Egling an der Paar', zip: '86492'},
    {city: 'Egloffstein', zip: '91349'},
    {city: 'Egmating', zip: '85658'},
    {city: 'Egweil', zip: '85116'},
    {city: 'Ehekirchen', zip: '86676'},
    {city: 'Ehingen', zip: '86678'},
    {city: 'Ehingen', zip: '91725'},
    {city: 'Ehingen a.Ries', zip: '86741'},
    {city: 'Ehingen (Donau)', zip: '89584'},
    {city: 'Ehlenz', zip: '54636'},
    {city: 'Ehlscheid', zip: '56581'},
    {city: 'Ehndorf', zip: '24647'},
    {city: 'Ehningen', zip: '71139'},
    {city: 'Ehr', zip: '56357'},
    {city: 'Ehra-Lessien', zip: '38468'},
    {city: 'Ehrenberg', zip: '36115'},
    {city: 'Ehrenberg', zip: '98660'},
    {city: 'Ehrenburg', zip: '27248'},
    {city: 'Ehrenfriedersdorf', zip: '09427'},
    {city: 'Ehrenkirchen', zip: '79238'},
    {city: 'Ehringshausen', zip: '35630'},
    {city: 'Ehweiler', zip: '66871'},
    {city: 'Eibelstadt', zip: '97246'},
    {city: 'Eibenstock', zip: '08309'},
    {city: 'Eich', zip: '67575'},
    {city: 'Eichelhardt', zip: '57612'},
    {city: 'Eichen', zip: '57632'},
    {city: 'Eichenau', zip: '82223'},
    {city: 'Eichenbach', zip: '53533'},
    {city: 'Eichenberg', zip: '98553'},
    {city: 'Eichenberg', zip: '07768'},
    {city: 'Eichenbühl', zip: '63928'},
    {city: 'Eichenbühl', zip: '74731'},
    {city: 'Eichenbühl', zip: '97896'},
    {city: 'Eichendorf', zip: '94428'},
    {city: 'Eichenzell', zip: '36124'},
    {city: 'Eichigt', zip: '08626'},
    {city: 'Eichstätt', zip: '85072'},
    {city: 'Eichstedt (Altmark)', zip: '39596'},
    {city: 'Eichstegen', zip: '88361'},
    {city: 'Eichstetten', zip: '79356'},
    {city: 'Eichstruth', zip: '37318'},
    {city: 'Eichwalde', zip: '15732'},
    {city: 'Eickeloh', zip: '29693'},
    {city: 'Eicklingen', zip: '29358'},
    {city: 'Eigeltingen', zip: '78253'},
    {city: 'Eilenburg', zip: '04838'},
    {city: 'Eilscheid', zip: '54649'},
    {city: 'Eilsleben', zip: '39365'},
    {city: 'Eime', zip: '31036'},
    {city: 'Eimeldingen', zip: '79591'},
    {city: 'Eimen', zip: '37632'},
    {city: 'Eimke', zip: '29578'},
    {city: 'Eimsheim', zip: '55278'},
    {city: 'Einbeck', zip: '37574'},
    {city: 'Eineborn', zip: '07646'},
    {city: 'Einhaus', zip: '23911'},
    {city: 'Einhausen', zip: '64683'},
    {city: 'Einhausen', zip: '98617'},
    {city: 'Einig', zip: '56751'},
    {city: 'Einöllen', zip: '67753'},
    {city: 'Einselthum', zip: '67308'},
    {city: 'Eiselfing', zip: '83549'},
    {city: 'Eisenach', zip: '99817'},
    {city: 'Eisenach', zip: '54298'},
    {city: 'Eisenbach', zip: '79871'},
    {city: 'Eisenberg', zip: '87637'},
    {city: 'Eisenberg', zip: '07607'},
    {city: 'Eisenberg (Pfalz)', zip: '67304'},
    {city: 'Eisendorf', zip: '24589'},
    {city: 'Eisenheim', zip: '97247'},
    {city: 'Eisenhüttenstadt', zip: '15890'},
    {city: 'Eisenschmitt', zip: '54533'},
    {city: 'Eisfeld', zip: '98673'},
    {city: 'Eisighofen', zip: '56370'},
    {city: 'Eisingen', zip: '75239'},
    {city: 'Eisingen', zip: '97249'},
    {city: 'Eislingen/Fils', zip: '73054'},
    {city: 'Eitelborn', zip: '56337'},
    {city: 'Eitensheim', zip: '85117'},
    {city: 'Eiterfeld', zip: '36132'},
    {city: 'Eitorf', zip: '53783'},
    {city: 'Eitting', zip: '85462'},
    {city: 'Eixen', zip: '18334'},
    {city: 'Elbe', zip: '38274'},
    {city: 'Elben', zip: '57580'},
    {city: 'Elbe-Parey', zip: '39307'},
    {city: 'Elbe-Parey', zip: '39317'},
    {city: 'Elbingen', zip: '56459'},
    {city: 'Elbingerode', zip: '37412'},
    {city: 'Elbtal', zip: '65627'},
    {city: 'Elchesheim-Illingen', zip: '76477'},
    {city: 'Elchingen', zip: '89081'},
    {city: 'Elchingen', zip: '89275'},
    {city: 'Elchweiler', zip: '55765'},
    {city: 'Eldena', zip: '19294'},
    {city: 'Eldetal', zip: '17209'},
    {city: 'Eldingen', zip: '29351'},
    {city: 'Elfershausen', zip: '97725'},
    {city: 'Elgersburg', zip: '98716'},
    {city: 'Elisabeth-Sophien-Koog', zip: '25845'},
    {city: 'Elkenroth', zip: '57578'},
    {city: 'Elleben', zip: '99334'},
    {city: 'Ellefeld', zip: '08236'},
    {city: 'Ellenberg', zip: '73488'},
    {city: 'Ellenberg', zip: '55765'},
    {city: 'Ellenhausen', zip: '56242'},
    {city: 'Ellenz-Poltersdorf', zip: '56821'},
    {city: 'Ellerau', zip: '25479'},
    {city: 'Ellerbek', zip: '25474'},
    {city: 'Ellerdorf', zip: '24589'},
    {city: 'Ellerhoop', zip: '25373'},
    {city: 'Ellern (Hunsrück)', zip: '55497'},
    {city: 'Ellerstadt', zip: '67158'},
    {city: 'Ellgau', zip: '86679'},
    {city: 'Ellhofen', zip: '74248'},
    {city: 'Ellhöft', zip: '25923'},
    {city: 'Ellingen', zip: '91792'},
    {city: 'Ellingshausen', zip: '98617'},
    {city: 'Ellingstedt', zip: '24870'},
    {city: 'Ellrich', zip: '99755'},
    {city: 'Ellscheid', zip: '54552'},
    {city: 'Ellwangen (Jagst)', zip: '73479'},
    {city: 'Ellweiler', zip: '55765'},
    {city: 'Ellzee', zip: '89352'},
    {city: 'Elmenhorst', zip: '18510'},
    {city: 'Elmenhorst', zip: '23869'},
    {city: 'Elmenhorst', zip: '21493'},
    {city: 'Elmenhorst/Lichtenhagen', zip: '18107'},
    {city: 'Elmshorn', zip: '25335'},
    {city: 'Elmshorn', zip: '25336'},
    {city: 'Elmshorn', zip: '25337'},
    {city: 'Elmstein', zip: '67471'},
    {city: 'Elpersbüttel', zip: '25704'},
    {city: 'Elsdorf', zip: '50189'},
    {city: 'Elsdorf', zip: '27404'},
    {city: 'Elsdorf-Westermühlen', zip: '24800'},
    {city: 'Elsendorf', zip: '84094'},
    {city: 'Elsenfeld', zip: '63820'},
    {city: 'Elsfleth', zip: '26931'},
    {city: 'Elskop', zip: '25361'},
    {city: 'Elsnig', zip: '04880'},
    {city: 'Elsoff (Westerwald)', zip: '56479'},
    {city: 'Elsteraue', zip: '06729'},
    {city: 'Elsterberg', zip: '07985'},
    {city: 'Elsterheide', zip: '02979'},
    {city: 'Elstertrebnitz', zip: '04523'},
    {city: 'Elsterwerda', zip: '04910'},
    {city: 'Elstra', zip: '01920'},
    {city: 'Elterlein', zip: '09481'},
    {city: 'Eltmann', zip: '97483'},
    {city: 'Eltville am Rhein', zip: '65343'},
    {city: 'Eltville am Rhein', zip: '65344'},
    {city: 'Eltville am Rhein', zip: '65345'},
    {city: 'Eltville am Rhein', zip: '65346'},
    {city: 'Eltville am Rhein', zip: '65347'},
    {city: 'Elxleben', zip: '99334'},
    {city: 'Elxleben', zip: '99189'},
    {city: 'Elz', zip: '65604'},
    {city: 'Elzach', zip: '79215'},
    {city: 'Elze', zip: '31008'},
    {city: 'Elztal', zip: '74834'},
    {city: 'Elzweiler', zip: '66887'},
    {city: 'Embsen', zip: '21409'},
    {city: 'Embühren', zip: '24819'},
    {city: 'Emden', zip: '26721'},
    {city: 'Emden', zip: '26723'},
    {city: 'Emden', zip: '26725'},
    {city: 'Emeringen', zip: '88499'},
    {city: 'Emerkingen', zip: '89607'},
    {city: 'Emersacker', zip: '86494'},
    {city: 'Emkendorf', zip: '24802'},
    {city: 'Emleben', zip: '99869'},
    {city: 'Emlichheim', zip: '49824'},
    {city: 'Emmelbaum', zip: '54673'},
    {city: 'Emmelsbüll-Horsbüll', zip: '25924'},
    {city: 'Emmelshausen', zip: '56281'},
    {city: 'Emmendingen', zip: '79312'},
    {city: 'Emmendorf', zip: '29579'},
    {city: 'Emmerich am Rhein', zip: '46446'},
    {city: 'Emmering', zip: '83550'},
    {city: 'Emmering', zip: '82275'},
    {city: 'Emmerthal', zip: '31860'},
    {city: 'Emmerting', zip: '84547'},
    {city: 'Emmerzhausen', zip: '57520'},
    {city: 'Emmingen-Liptingen', zip: '78576'},
    {city: 'Empfertshausen', zip: '36452'},
    {city: 'Empfingen', zip: '72186'},
    {city: 'Emsbüren', zip: '48488'},
    {city: 'Emsdetten', zip: '48282'},
    {city: 'Emskirchen', zip: '91448'},
    {city: 'Emstek', zip: '49685'},
    {city: 'Emtinghausen', zip: '27321'},
    {city: 'Emtmannsberg', zip: '95517'},
    {city: 'Endingen', zip: '79346'},
    {city: 'Endlichhofen', zip: '56355'},
    {city: 'Endschütz', zip: '07570'},
    {city: 'Engden', zip: '48465'},
    {city: 'Engelbrechtsche Wildnis', zip: '25348'},
    {city: 'Engelsberg', zip: '84549'},
    {city: 'Engelsbrand', zip: '75331'},
    {city: 'Engelschoff', zip: '21710'},
    {city: 'Engelskirchen', zip: '51766'},
    {city: 'Engelstadt', zip: '55270'},
    {city: 'Engelthal', zip: '91238'},
    {city: 'Engen', zip: '78234'},
    {city: 'Enger', zip: '32130'},
    {city: 'Enge-Sande', zip: '25917'},
    {city: 'Engstingen', zip: '72829'},
    {city: 'Eningen unter Achalm', zip: '72800'},
    {city: 'Enkenbach-Alsenborn', zip: '67677'},
    {city: 'Enkirch', zip: '56850'},
    {city: 'Ennepetal', zip: '58256'},
    {city: 'Ennigerloh', zip: '59320'},
    {city: 'Ensch', zip: '54340'},
    {city: 'Ensdorf', zip: '92266'},
    {city: 'Ensdorf/Saar', zip: '66806'},
    {city: 'Ense', zip: '59469'},
    {city: 'Ensheim', zip: '55232'},
    {city: 'Enspel', zip: '57647'},
    {city: 'Enzen', zip: '54646'},
    {city: 'Enzklösterle', zip: '75337'},
    {city: 'Epenwöhrden', zip: '25704'},
    {city: 'Epfenbach', zip: '74925'},
    {city: 'Epfendorf', zip: '78736'},
    {city: 'Eppelborn', zip: '66571'},
    {city: 'Eppelheim', zip: '69214'},
    {city: 'Eppelsheim', zip: '55234'},
    {city: 'Eppenberg', zip: '56759'},
    {city: 'Eppenbrunn', zip: '66957'},
    {city: 'Eppendorf', zip: '09575'},
    {city: 'Eppenrod', zip: '65558'},
    {city: 'Eppenschlag', zip: '94536'},
    {city: 'Eppertshausen', zip: '64859'},
    {city: 'Eppingen', zip: '75031'},
    {city: 'Eppishausen', zip: '87745'},
    {city: 'Eppstein', zip: '65817'},
    {city: 'Erbach', zip: '64711'},
    {city: 'Erbach', zip: '55494'},
    {city: 'Erbach', zip: '89155'},
    {city: 'Erbendorf', zip: '92681'},
    {city: 'Erbenhausen', zip: '98634'},
    {city: 'Erbes-Büdesheim', zip: '55234'},
    {city: 'Erden', zip: '54492'},
    {city: 'Erdesbach', zip: '66887'},
    {city: 'Erding', zip: '85435'},
    {city: 'Erdmannhausen', zip: '71729'},
    {city: 'Erdweg', zip: '85253'},
    {city: 'Eresing', zip: '86922'},
    {city: 'Eresing', zip: '86926'},
    {city: 'Eresing', zip: '86941'},
    {city: 'Erfde', zip: '24803'},
    {city: 'Erftstadt', zip: '50374'},
    {city: 'Erfurt', zip: '99084'},
    {city: 'Erfurt', zip: '99085'},
    {city: 'Erfurt', zip: '99086'},
    {city: 'Erfurt', zip: '99087'},
    {city: 'Erfurt', zip: '99089'},
    {city: 'Erfurt', zip: '99090'},
    {city: 'Erfurt', zip: '99091'},
    {city: 'Erfurt', zip: '99092'},
    {city: 'Erfurt', zip: '99094'},
    {city: 'Erfurt', zip: '99095'},
    {city: 'Erfurt', zip: '99096'},
    {city: 'Erfurt', zip: '99097'},
    {city: 'Erfurt', zip: '99098'},
    {city: 'Erfurt', zip: '99099'},
    {city: 'Erfweiler', zip: '66996'},
    {city: 'Ergersheim', zip: '91465'},
    {city: 'Ergeshausen', zip: '56368'},
    {city: 'Ergolding', zip: '84030'},
    {city: 'Ergoldsbach', zip: '84061'},
    {city: 'Erharting', zip: '84513'},
    {city: 'Ering', zip: '94140'},
    {city: 'Eriskirch', zip: '88097'},
    {city: 'Erkelenz', zip: '41812'},
    {city: 'Erkenbrechtsweiler', zip: '73268'},
    {city: 'Erkerode', zip: '38173'},
    {city: 'Erkheim', zip: '87746'},
    {city: 'Erkner', zip: '15537'},
    {city: 'Erkrath', zip: '40699'},
    {city: 'Erlabrunn', zip: '97250'},
    {city: 'Erlangen', zip: '91052'},
    {city: 'Erlangen', zip: '91054'},
    {city: 'Erlangen', zip: '91056'},
    {city: 'Erlangen', zip: '91058'},
    {city: 'Erlau', zip: '09306'},
    {city: 'Erlbach', zip: '84567'},
    {city: 'Erlenbach', zip: '74235'},
    {city: 'Erlenbach am Main', zip: '63906'},
    {city: 'Erlenbach bei Dahn', zip: '76891'},
    {city: 'Erlenbach bei Kandel', zip: '76872'},
    {city: 'Erlenbach b.Marktheidenfeld', zip: '97837'},
    {city: 'Erlenmoos', zip: '88416'},
    {city: 'Erlensee', zip: '63526'},
    {city: 'Erligheim', zip: '74391'},
    {city: 'Ermershausen', zip: '96126'},
    {city: 'Erndtebrück', zip: '57339'},
    {city: 'Ernsgaden', zip: '85119'},
    {city: 'Ernst', zip: '56814'},
    {city: 'Ernzen', zip: '54668'},
    {city: 'Erolzheim', zip: '88453'},
    {city: 'Erpel', zip: '53579'},
    {city: 'Erpolzheim', zip: '67167'},
    {city: 'Ersfeld', zip: '57635'},
    {city: 'Ertingen', zip: '88521'},
    {city: 'Erwitte', zip: '59597'},
    {city: 'Erxleben', zip: '39343'},
    {city: 'Erzenhausen', zip: '67685'},
    {city: 'Erzhausen', zip: '64390'},
    {city: 'Esch', zip: '54585'},
    {city: 'Esch', zip: '54518'},
    {city: 'Eschach', zip: '73569'},
    {city: 'Eschau', zip: '63863'},
    {city: 'Eschbach', zip: '56357'},
    {city: 'Eschbach', zip: '76831'},
    {city: 'Eschbach', zip: '79427'},
    {city: 'Eschborn', zip: '65760'},
    {city: 'Eschbronn', zip: '78664'},
    {city: 'Esche', zip: '49828'},
    {city: 'Escheburg', zip: '21039'},
    {city: 'Eschede', zip: '29348'},
    {city: 'Eschede', zip: '29359'},
    {city: 'Eschede', zip: '29361'},
    {city: 'Eschelbronn', zip: '74927'},
    {city: 'Eschenbach', zip: '73037'},
    {city: 'Eschenbach', zip: '73107'},
    {city: 'Eschenbach i.d.OPf.', zip: '92676'},
    {city: 'Eschenbergen', zip: '99869'},
    {city: 'Eschenburg', zip: '35713'},
    {city: 'Eschenlohe', zip: '82438'},
    {city: 'Eschershausen', zip: '37632'},
    {city: 'Eschfeld', zip: '54619'},
    {city: 'Eschlkam', zip: '93458'},
    {city: 'Eschwege', zip: '37269'},
    {city: 'Eschweiler', zip: '52249'},
    {city: 'Esens', zip: '26427'},
    {city: 'Esgrus', zip: '24402'},
    {city: 'Eslarn', zip: '92693'},
    {city: 'Eslohe', zip: '59889'},
    {city: 'Espelkamp', zip: '32339'},
    {city: 'Espenau', zip: '34314'},
    {city: 'Eßbach', zip: '07924'},
    {city: 'Essel', zip: '29690'},
    {city: 'Esselbach', zip: '97839'},
    {city: 'Esselborn', zip: '55234'},
    {city: 'Essen', zip: '45127'},
    {city: 'Essen', zip: '45128'},
    {city: 'Essen', zip: '45130'},
    {city: 'Essen', zip: '45131'},
    {city: 'Essen', zip: '45133'},
    {city: 'Essen', zip: '45134'},
    {city: 'Essen', zip: '45136'},
    {city: 'Essen', zip: '45138'},
    {city: 'Essen', zip: '45139'},
    {city: 'Essen', zip: '45141'},
    {city: 'Essen', zip: '45143'},
    {city: 'Essen', zip: '45144'},
    {city: 'Essen', zip: '45145'},
    {city: 'Essen', zip: '45147'},
    {city: 'Essen', zip: '45149'},
    {city: 'Essen', zip: '45219'},
    {city: 'Essen', zip: '45239'},
    {city: 'Essen', zip: '45257'},
    {city: 'Essen', zip: '45259'},
    {city: 'Essen', zip: '45276'},
    {city: 'Essen', zip: '45277'},
    {city: 'Essen', zip: '45279'},
    {city: 'Essen', zip: '45289'},
    {city: 'Essen', zip: '45307'},
    {city: 'Essen', zip: '45309'},
    {city: 'Essen', zip: '45326'},
    {city: 'Essen', zip: '45327'},
    {city: 'Essen', zip: '45329'},
    {city: 'Essen', zip: '45355'},
    {city: 'Essen', zip: '45356'},
    {city: 'Essen', zip: '45357'},
    {city: 'Essen', zip: '45359'},
    {city: 'Essenbach', zip: '84051'},
    {city: 'Essenheim', zip: '55270'},
    {city: 'Essen/Oldenburg', zip: '49632'},
    {city: 'Essing', zip: '93343'},
    {city: 'Essingen', zip: '76879'},
    {city: 'Essingen', zip: '73457'},
    {city: 'Essingen', zip: '73563'},
    {city: 'Essingen', zip: '73566'},
    {city: 'Eßlingen', zip: '54636'},
    {city: 'Esslingen am Neckar', zip: '73728'},
    {city: 'Esslingen am Neckar', zip: '73730'},
    {city: 'Esslingen am Neckar', zip: '73732'},
    {city: 'Esslingen am Neckar', zip: '73733'},
    {city: 'Esslingen am Neckar', zip: '73734'},
    {city: 'Eßweiler', zip: '67754'},
    {city: 'Estenfeld', zip: '97230'},
    {city: 'Esterwegen', zip: '26897'},
    {city: 'Esthal', zip: '67466'},
    {city: 'Esthal', zip: '67468'},
    {city: 'Esthal', zip: '67472'},
    {city: 'Estorf', zip: '21727'},
    {city: 'Estorf', zip: '31629'},
    {city: 'Etgert', zip: '54424'},
    {city: 'Etschberg', zip: '66871'},
    {city: 'Ettal', zip: '82488'},
    {city: 'Etteldorf', zip: '54655'},
    {city: 'Ettenheim', zip: '77955'},
    {city: 'Ettenstatt', zip: '91796'},
    {city: 'Ettersburg', zip: '99439'},
    {city: 'Ettinghausen', zip: '56244'},
    {city: 'Ettlingen', zip: '76275'},
    {city: 'Ettringen', zip: '56729'},
    {city: 'Ettringen', zip: '86833'},
    {city: 'Etzbach', zip: '57539'},
    {city: 'Etzelwang', zip: '92268'},
    {city: 'Etzenricht', zip: '92694'},
    {city: 'Etzleben', zip: '06577'},
    {city: 'Euerbach', zip: '97502'},
    {city: 'Euerdorf', zip: '97717'},
    {city: 'Eulenberg', zip: '57632'},
    {city: 'Eulenbis', zip: '67685'},
    {city: 'Eulgem', zip: '56761'},
    {city: 'Eurasburg', zip: '86495'},
    {city: 'Eurasburg', zip: '82547'},
    {city: 'Euscheid', zip: '54597'},
    {city: 'Euskirchen', zip: '53879'},
    {city: 'Euskirchen', zip: '53881'},
    {city: 'Eußenheim', zip: '97776'},
    {city: 'Eußerthal', zip: '76857'},
    {city: 'Eutin', zip: '23701'},
    {city: 'Eutingen im Gäu', zip: '72184'},
    {city: 'Eversmeer', zip: '26556'},
    {city: 'Everswinkel', zip: '48351'},
    {city: 'Evessen', zip: '38173'},
    {city: 'Ewighausen', zip: '56244'},
    {city: 'Extertal', zip: '32699'},
    {city: 'Eydelstedt', zip: '49406'},
    {city: 'Eyendorf', zip: '21376'},
    {city: 'Eystrup', zip: '27324'},
    {city: 'Fachbach', zip: '56133'},
    {city: 'Fahrdorf', zip: '24857'},
    {city: 'Fahren', zip: '24253'},
    {city: 'Fahrenbach', zip: '74864'},
    {city: 'Fahrenkrug', zip: '23795'},
    {city: 'Fahrenwalde', zip: '17309'},
    {city: 'Fahrenzhausen', zip: '85777'},
    {city: 'Faid', zip: '56814'},
    {city: 'Falkenberg', zip: '84326'},
    {city: 'Falkenberg', zip: '95685'},
    {city: 'Falkenberg', zip: '16259'},
    {city: 'Falkenberg/Elster', zip: '04895'},
    {city: 'Falkenfels', zip: '94350'},
    {city: 'Falkenhagen (Mark)', zip: '15306'},
    {city: 'Falkensee', zip: '14612'},
    {city: 'Falkenstein', zip: '93167'},
    {city: 'Falkenstein', zip: '67808'},
    {city: 'Falkenstein/Harz', zip: '06333'},
    {city: 'Falkenstein/Harz', zip: '06463'},
    {city: 'Falkenstein/Harz', zip: '06543'},
    {city: 'Falkenstein/Vogtland', zip: '08223'},
    {city: 'Falkenstein/Vogtland', zip: '08239'},
    {city: 'Fambach', zip: '98597'},
    {city: 'Farchant', zip: '82490'},
    {city: 'Fargau-Pratjau', zip: '24256'},
    {city: 'Farnstädt', zip: '06279'},
    {city: 'Farschweiler', zip: '54317'},
    {city: 'Farven', zip: '27446'},
    {city: 'Faßberg', zip: '29328'},
    {city: 'Faulbach', zip: '97906'},
    {city: 'Faulenrost', zip: '17139'},
    {city: 'Fedderingen', zip: '25779'},
    {city: 'Fehl-Ritzhausen', zip: '56472'},
    {city: 'Fehmarn', zip: '23769'},
    {city: 'Fehrbellin', zip: '16818'},
    {city: 'Fehrbellin', zip: '16833'},
    {city: 'Fehrbellin', zip: '16845'},
    {city: 'Feichten an der Alz', zip: '84550'},
    {city: 'Feilbingert', zip: '67824'},
    {city: 'Feilitzsch', zip: '95183'},
    {city: 'Feilsdorf', zip: '54636'},
    {city: 'Feldafing', zip: '82340'},
    {city: 'Feldatal', zip: '36325'},
    {city: 'Feldberg', zip: '79868'},
    {city: 'Feldberger Seenlandschaft', zip: '17258'},
    {city: 'Felde', zip: '24242'},
    {city: 'Feldhorst', zip: '23858'},
    {city: 'Feldkirchen', zip: '94351'},
    {city: 'Feldkirchen', zip: '85622'},
    {city: 'Feldkirchen-Westerham', zip: '83620'},
    {city: 'Felixsee', zip: '03130'},
    {city: 'Fell', zip: '54341'},
    {city: 'Fellbach', zip: '70734'},
    {city: 'Fellbach', zip: '70736'},
    {city: 'Fellen', zip: '97778'},
    {city: 'Fellheim', zip: '87748'},
    {city: 'Felm', zip: '24161'},
    {city: 'Felm', zip: '24214'},
    {city: 'Felm', zip: '24244'},
    {city: 'Felsberg', zip: '34587'},
    {city: 'Fensdorf', zip: '57580'},
    {city: 'Fensterbach', zip: '92269'},
    {city: 'Ferdinandshof', zip: '17379'},
    {city: 'Ferna', zip: '37339'},
    {city: 'Fernwald', zip: '35463'},
    {city: 'Ferschweiler', zip: '54668'},
    {city: 'Feucht', zip: '90537'},
    {city: 'Feuchtwangen', zip: '91555'},
    {city: 'Feuerscheid', zip: '54597'},
    {city: 'Feusdorf', zip: '54584'},
    {city: 'Fichtelberg', zip: '95686'},
    {city: 'Fichtenau', zip: '74579'},
    {city: 'Fichtenberg', zip: '74427'},
    {city: 'Fichtenhöhe', zip: '15306'},
    {city: 'Fichtwald', zip: '04936'},
    {city: 'Fiefbergen', zip: '24217'},
    {city: 'Fiersbach', zip: '57635'},
    {city: 'Filderstadt', zip: '70794'},
    {city: 'Filderstadt', zip: '71111'},
    {city: 'Filsen', zip: '56341'},
    {city: 'Filsum', zip: '26849'},
    {city: 'Filz', zip: '56766'},
    {city: 'Fincken', zip: '17209'},
    {city: 'Finkenbach-Gersweiler', zip: '67822'},
    {city: 'Finkenthal', zip: '17179'},
    {city: 'Finne', zip: '06647'},
    {city: 'Finneland', zip: '06647'},
    {city: 'Finnentrop', zip: '57413'},
    {city: 'Finning', zip: '86923'},
    {city: 'Finningen', zip: '89435'},
    {city: 'Finsing', zip: '85464'},
    {city: 'Finsterwalde', zip: '03238'},
    {city: 'Fintel', zip: '27389'},
    {city: 'Firrel', zip: '26835'},
    {city: 'Fisch', zip: '54439'},
    {city: 'Fischach', zip: '86850'},
    {city: 'Fischbach', zip: '55743'},
    {city: 'Fischbach', zip: '67693'},
    {city: 'Fischbachau', zip: '83730'},
    {city: 'Fischbach bei Dahn', zip: '66996'},
    {city: 'Fischbach-Oberraden', zip: '54675'},
    {city: 'Fischbachtal', zip: '64405'},
    {city: 'Fischen im Allgäu', zip: '87538'},
    {city: 'Fischerbach', zip: '77716'},
    {city: 'Fischingen', zip: '79592'},
    {city: 'Fitzbek', zip: '25579'},
    {city: 'Fitzen', zip: '21514'},
    {city: 'Flachslanden', zip: '91604'},
    {city: 'Flacht', zip: '65558'},
    {city: 'Fladungen', zip: '97650'},
    {city: 'Flammersfeld', zip: '57632'},
    {city: 'Flechtingen', zip: '39345'},
    {city: 'Flechtingen', zip: '39356'},
    {city: 'Flechtingen', zip: '39359'},
    {city: 'Fleckeby', zip: '24357'},
    {city: 'Flein', zip: '74223'},
    {city: 'Fleischwangen', zip: '88373'},
    {city: 'Flemlingen', zip: '76835'},
    {city: 'Flensburg', zip: '24937'},
    {city: 'Flensburg', zip: '24939'},
    {city: 'Flensburg', zip: '24941'},
    {city: 'Flensburg', zip: '24943'},
    {city: 'Flensburg', zip: '24944'},
    {city: 'Fleringen', zip: '54597'},
    {city: 'Flieden', zip: '36103'},
    {city: 'Fließem', zip: '54636'},
    {city: 'Flieth-Stegelitz', zip: '17268'},
    {city: 'Flintbek', zip: '24220'},
    {city: 'Flintsbach am Inn', zip: '83126'},
    {city: 'Flöha', zip: '09557'},
    {city: 'Floh-Seligenthal', zip: '98593'},
    {city: 'Flomborn', zip: '55234'},
    {city: 'Flonheim', zip: '55237'},
    {city: 'Flörsbachtal', zip: '63639'},
    {city: 'Flörsheim', zip: '65439'},
    {city: 'Flörsheim-Dalsheim', zip: '67592'},
    {city: 'Florstadt', zip: '61197'},
    {city: 'Floß', zip: '92685'},
    {city: 'Flossenbürg', zip: '92696'},
    {city: 'Flöthe', zip: '38312'},
    {city: 'Fluorn-Winzeln', zip: '78737'},
    {city: 'Flußbach', zip: '54516'},
    {city: 'Fluterschen', zip: '57614'},
    {city: 'Fockbek', zip: '24787'},
    {city: 'Föckelberg', zip: '66887'},
    {city: 'Fockendorf', zip: '04617'},
    {city: 'Föhrden-Barl', zip: '25563'},
    {city: 'Föhren', zip: '54343'},
    {city: 'Fohren-Linden', zip: '55777'},
    {city: 'Forbach', zip: '76596'},
    {city: 'Forchheim', zip: '91301'},
    {city: 'Forchheim', zip: '79362'},
    {city: 'Forchtenberg', zip: '74670'},
    {city: 'Forheim', zip: '86735'},
    {city: 'Föritztal', zip: '96524'},
    {city: 'Forst', zip: '57537'},
    {city: 'Forst', zip: '76694'},
    {city: 'Forst an der Weinstraße', zip: '67147'},
    {city: 'Forst (Eifel)', zip: '56754'},
    {city: 'Forstern', zip: '85659'},
    {city: 'Forst (Hunsrück)', zip: '56858'},
    {city: 'Forstinning', zip: '85661'},
    {city: 'Forst (Lausitz)', zip: '03149'},
    {city: 'Forstmehren', zip: '57635'},
    {city: 'Framersheim', zip: '55234'},
    {city: 'Frammersbach', zip: '97833'},
    {city: 'Frankelbach', zip: '67737'},
    {city: 'Frankenau', zip: '35110'},
    {city: 'Frankenberg', zip: '35066'},
    {city: 'Frankenberg/Sachsen', zip: '09669'},
    {city: 'Frankenblick', zip: '96528'},
    {city: 'Frankendorf', zip: '99441'},
    {city: 'Frankeneck', zip: '67468'},
    {city: 'Frankenfeld', zip: '27336'},
    {city: 'Frankenhardt', zip: '74586'},
    {city: 'Frankenheim/Rhön', zip: '98634'},
    {city: 'Frankenroda', zip: '99826'},
    {city: 'Frankenstein', zip: '67468'},
    {city: 'Frankenthal', zip: '01909'},
    {city: 'Frankenthal (Pfalz)', zip: '67227'},
    {city: 'Frankenwinheim', zip: '97447'},
    {city: 'Frankfurt am Main', zip: '60306'},
    {city: 'Frankfurt am Main', zip: '60308'},
    {city: 'Frankfurt am Main', zip: '60310'},
    {city: 'Frankfurt am Main', zip: '60311'},
    {city: 'Frankfurt am Main', zip: '60312'},
    {city: 'Frankfurt am Main', zip: '60313'},
    {city: 'Frankfurt am Main', zip: '60314'},
    {city: 'Frankfurt am Main', zip: '60316'},
    {city: 'Frankfurt am Main', zip: '60318'},
    {city: 'Frankfurt am Main', zip: '60320'},
    {city: 'Frankfurt am Main', zip: '60322'},
    {city: 'Frankfurt am Main', zip: '60323'},
    {city: 'Frankfurt am Main', zip: '60325'},
    {city: 'Frankfurt am Main', zip: '60326'},
    {city: 'Frankfurt am Main', zip: '60327'},
    {city: 'Frankfurt am Main', zip: '60329'},
    {city: 'Frankfurt am Main', zip: '60385'},
    {city: 'Frankfurt am Main', zip: '60386'},
    {city: 'Frankfurt am Main', zip: '60388'},
    {city: 'Frankfurt am Main', zip: '60389'},
    {city: 'Frankfurt am Main', zip: '60431'},
    {city: 'Frankfurt am Main', zip: '60433'},
    {city: 'Frankfurt am Main', zip: '60435'},
    {city: 'Frankfurt am Main', zip: '60437'},
    {city: 'Frankfurt am Main', zip: '60438'},
    {city: 'Frankfurt am Main', zip: '60439'},
    {city: 'Frankfurt am Main', zip: '60486'},
    {city: 'Frankfurt am Main', zip: '60487'},
    {city: 'Frankfurt am Main', zip: '60488'},
    {city: 'Frankfurt am Main', zip: '60489'},
    {city: 'Frankfurt am Main', zip: '60528'},
    {city: 'Frankfurt am Main', zip: '60529'},
    {city: 'Frankfurt am Main', zip: '60549'},
    {city: 'Frankfurt am Main', zip: '60594'},
    {city: 'Frankfurt am Main', zip: '60596'},
    {city: 'Frankfurt am Main', zip: '60598'},
    {city: 'Frankfurt am Main', zip: '60599'},
    {city: 'Frankfurt am Main', zip: '65929'},
    {city: 'Frankfurt am Main', zip: '65931'},
    {city: 'Frankfurt am Main', zip: '65933'},
    {city: 'Frankfurt am Main', zip: '65934'},
    {city: 'Frankfurt am Main', zip: '65936'},
    {city: 'Frankfurt (Oder)', zip: '15230'},
    {city: 'Frankfurt (Oder)', zip: '15232'},
    {city: 'Frankfurt (Oder)', zip: '15234'},
    {city: 'Frankfurt (Oder)', zip: '15236'},
    {city: 'Fränkisch-Crumbach', zip: '64407'},
    {city: 'Frankweiler', zip: '76833'},
    {city: 'Franzburg', zip: '18461'},
    {city: 'Franzenheim', zip: '54316'},
    {city: 'Frasdorf', zip: '83112'},
    {city: 'Frauenau', zip: '94258'},
    {city: 'Frauenberg', zip: '55776'},
    {city: 'Frauendorf', zip: '01945'},
    {city: 'Frauenneuharting', zip: '83553'},
    {city: 'Frauenprießnitz', zip: '07774'},
    {city: 'Frauenstein', zip: '09623'},
    {city: 'Fraunberg', zip: '85447'},
    {city: 'Fraureuth', zip: '08427'},
    {city: 'Frechen', zip: '50226'},
    {city: 'Freckenfeld', zip: '76872'},
    {city: 'Fredeburg', zip: '23909'},
    {city: 'Fredenbeck', zip: '21717'},
    {city: 'Freden (Leine)', zip: '31084'},
    {city: 'Fredersdorf-Vogelsdorf', zip: '15370'},
    {city: 'Fredesdorf', zip: '23826'},
    {city: 'Freiamt', zip: '79348'},
    {city: 'Freiberg', zip: '09599'},
    {city: 'Freiberg am Neckar', zip: '71691'},
    {city: 'Freiburg (Elbe)', zip: '21729'},
    {city: 'Freiburg im Breisgau', zip: '79098'},
    {city: 'Freiburg im Breisgau', zip: '79100'},
    {city: 'Freiburg im Breisgau', zip: '79102'},
    {city: 'Freiburg im Breisgau', zip: '79104'},
    {city: 'Freiburg im Breisgau', zip: '79106'},
    {city: 'Freiburg im Breisgau', zip: '79108'},
    {city: 'Freiburg im Breisgau', zip: '79110'},
    {city: 'Freiburg im Breisgau', zip: '79111'},
    {city: 'Freiburg im Breisgau', zip: '79112'},
    {city: 'Freiburg im Breisgau', zip: '79114'},
    {city: 'Freiburg im Breisgau', zip: '79115'},
    {city: 'Freiburg im Breisgau', zip: '79117'},
    {city: 'Freienbessingen', zip: '99713'},
    {city: 'Freienhagen', zip: '37318'},
    {city: 'Freienorla', zip: '07768'},
    {city: 'Freiensteinau', zip: '36399'},
    {city: 'Freienwill', zip: '24991'},
    {city: 'Freigericht', zip: '63579'},
    {city: 'Freihung', zip: '92271'},
    {city: 'Freilassing', zip: '83395'},
    {city: 'Frei-Laubersheim', zip: '55546'},
    {city: 'Freilingen', zip: '56244'},
    {city: 'Freimersheim', zip: '55234'},
    {city: 'Freimersheim (Pfalz)', zip: '67482'},
    {city: 'Freinsheim', zip: '67098'},
    {city: 'Freinsheim', zip: '67251'},
    {city: 'Freirachdorf', zip: '56244'},
    {city: 'Freisbach', zip: '67361'},
    {city: 'Freisen', zip: '66629'},
    {city: 'Freising', zip: '85354'},
    {city: 'Freising', zip: '85356'},
    {city: 'Freistatt', zip: '27259'},
    {city: 'Freital', zip: '01705'},
    {city: 'Frellstedt', zip: '38373'},
    {city: 'Fremdingen', zip: '86742'},
    {city: 'Frensdorf', zip: '96158'},
    {city: 'Freren', zip: '49832'},
    {city: 'Fresenburg', zip: '49762'},
    {city: 'Fresendelf', zip: '25876'},
    {city: 'Frestedt', zip: '25727'},
    {city: 'Frettenheim', zip: '67596'},
    {city: 'Fretterode', zip: '37318'},
    {city: 'Freudenberg', zip: '57258'},
    {city: 'Freudenberg', zip: '97896'},
    {city: 'Freudenberg', zip: '92272'},
    {city: 'Freudenburg', zip: '54450'},
    {city: 'Freudenstadt', zip: '72250'},
    {city: 'Freudental', zip: '74392'},
    {city: 'Freyburg (Unstrut)', zip: '06632'},
    {city: 'Freystadt', zip: '92342'},
    {city: 'Freyung', zip: '94078'},
    {city: 'Frickenhausen', zip: '72636'},
    {city: 'Frickenhausen a.Main', zip: '97252'},
    {city: 'Frickingen', zip: '88699'},
    {city: 'Fridingen an der Donau', zip: '78567'},
    {city: 'Fridolfing', zip: '83413'},
    {city: 'Friedberg', zip: '86316'},
    {city: 'Friedberg (Hessen)', zip: '61169'},
    {city: 'Friedeburg', zip: '26446'},
    {city: 'Friedelshausen', zip: '98634'},
    {city: 'Friedelsheim', zip: '67159'},
    {city: 'Friedenfels', zip: '95688'},
    {city: 'Friedenweiler', zip: '79877'},
    {city: 'Friedewald', zip: '36289'},
    {city: 'Friedewald', zip: '57520'},
    {city: 'Friedland', zip: '15848'},
    {city: 'Friedland', zip: '37133'},
    {city: 'Friedland', zip: '17098'},
    {city: 'Friedrichroda', zip: '99894'},
    {city: 'Friedrichsdorf', zip: '61273'},
    {city: 'Friedrichsdorf', zip: '61381'},
    {city: 'Friedrichsgabekoog', zip: '25764'},
    {city: 'Friedrichsgraben', zip: '24799'},
    {city: 'Friedrichshafen', zip: '88045'},
    {city: 'Friedrichshafen', zip: '88046'},
    {city: 'Friedrichshafen', zip: '88048'},
    {city: 'Friedrichsholm', zip: '24799'},
    {city: 'Friedrichskoog', zip: '25718'},
    {city: 'Friedrichsruhe', zip: '19089'},
    {city: 'Friedrichstadt', zip: '25840'},
    {city: 'Friedrichsthal', zip: '66299'},
    {city: 'Friedrichswalde', zip: '16247'},
    {city: 'Friedrich-Wilhelm-Lübke-Koog', zip: '25924'},
    {city: 'Frielendorf', zip: '34621'},
    {city: 'Friemar', zip: '99869'},
    {city: 'Friesack', zip: '14662'},
    {city: 'Friesenhagen', zip: '51598'},
    {city: 'Friesenheim', zip: '77948'},
    {city: 'Friesenheim', zip: '55278'},
    {city: 'Friesenried', zip: '87654'},
    {city: 'Friesoythe', zip: '26169'},
    {city: 'Friolzheim', zip: '71292'},
    {city: 'Frittlingen', zip: '78665'},
    {city: 'Fritzlar', zip: '34560'},
    {city: 'Frohburg', zip: '04654'},
    {city: 'Fröhnd', zip: '79677'},
    {city: 'Frohnhofen', zip: '66903'},
    {city: 'Fröndenberg/Ruhr', zip: '58730'},
    {city: 'Fronhausen', zip: '35112'},
    {city: 'Fronhofen', zip: '55471'},
    {city: 'Fronreute', zip: '88273'},
    {city: 'Frontenhausen', zip: '84160'},
    {city: 'Frücht', zip: '56132'},
    {city: 'Fuchshofen', zip: '53533'},
    {city: 'Fuchsmühl', zip: '95689'},
    {city: 'Fuchsstadt', zip: '97727'},
    {city: 'Fuchstal', zip: '86925'},
    {city: 'Fuhlendorf', zip: '18356'},
    {city: 'Fuhlendorf', zip: '24649'},
    {city: 'Fuhlenhagen', zip: '21493'},
    {city: 'Fulda', zip: '36037'},
    {city: 'Fulda', zip: '36039'},
    {city: 'Fulda', zip: '36041'},
    {city: 'Fulda', zip: '36043'},
    {city: 'Fuldabrück', zip: '34277'},
    {city: 'Fuldatal', zip: '34127'},
    {city: 'Fuldatal', zip: '34233'},
    {city: 'Fünfseen', zip: '17213'},
    {city: 'Fünfstetten', zip: '86681'},
    {city: 'Fürfeld', zip: '55546'},
    {city: 'Fürstenau', zip: '49584'},
    {city: 'Fürstenberg', zip: '37699'},
    {city: 'Fürstenberg/Havel', zip: '16798'},
    {city: 'Fürsteneck', zip: '94142'},
    {city: 'Fürstenfeldbruck', zip: '82256'},
    {city: 'Fürstenstein', zip: '94538'},
    {city: 'Fürstenwalde/Spree', zip: '15517'},
    {city: 'Fürstenzell', zip: '94081'},
    {city: 'Furth', zip: '84095'},
    {city: 'Fürth', zip: '90762'},
    {city: 'Fürth', zip: '90763'},
    {city: 'Fürth', zip: '90765'},
    {city: 'Fürth', zip: '90766'},
    {city: 'Fürth', zip: '90768'},
    {city: 'Fürth', zip: '64658'},
    {city: 'Fürthen', zip: '57539'},
    {city: 'Furth im Wald', zip: '93437'},
    {city: 'Furtwangen im Schwarzwald', zip: '78120'},
    {city: 'Füssen', zip: '87629'},
    {city: 'Fußgönheim', zip: '67136'},
    {city: 'Gablenz', zip: '02953'},
    {city: 'Gablingen', zip: '86456'},
    {city: 'Gabsheim', zip: '55288'},
    {city: 'Gachenbach', zip: '86565'},
    {city: 'Gackenbach', zip: '56412'},
    {city: 'Gadebusch', zip: '19205'},
    {city: 'Gädheim', zip: '97503'},
    {city: 'Gägelow', zip: '23968'},
    {city: 'Gaggenau', zip: '76571'},
    {city: 'Gaiberg', zip: '69251'},
    {city: 'Gaienhofen', zip: '78343'},
    {city: 'Gaildorf', zip: '74405'},
    {city: 'Gailingen am Hochrhein', zip: '78262'},
    {city: 'Gaimersheim', zip: '85080'},
    {city: 'Gaißach', zip: '83674'},
    {city: 'Galenbeck', zip: '17099'},
    {city: 'Galenberg', zip: '56651'},
    {city: 'Gallin', zip: '19258'},
    {city: 'Gallin-Kuppentin', zip: '19386'},
    {city: 'Gallmersgarten', zip: '91605'},
    {city: 'Galmsbüll', zip: '25899'},
    {city: 'Gamlen', zip: '56761'},
    {city: 'Gammelby', zip: '24340'},
    {city: 'Gammelin', zip: '19230'},
    {city: 'Gammelsdorf', zip: '85408'},
    {city: 'Gammelshausen', zip: '73108'},
    {city: 'Gammertingen', zip: '72501'},
    {city: 'Ganderkesee', zip: '27777'},
    {city: 'Gandesbergen', zip: '27324'},
    {city: 'Gangelt', zip: '52538'},
    {city: 'Gangkofen', zip: '84140'},
    {city: 'Gangloffsömmern', zip: '99634'},
    {city: 'Ganzlin', zip: '19395'},
    {city: 'Gappenach', zip: '56294'},
    {city: 'Garbsen', zip: '30823'},
    {city: 'Garbsen', zip: '30826'},
    {city: 'Garbsen', zip: '30827'},
    {city: 'Garching an der Alz', zip: '84518'},
    {city: 'Garching bei München', zip: '85748'},
    {city: 'Gardelegen', zip: '39638'},
    {city: 'Gardelegen', zip: '39646'},
    {city: 'Gardelegen', zip: '39649'},
    {city: 'Garding', zip: '25836'},
    {city: 'Garlstorf', zip: '21376'},
    {city: 'Garmisch-Partenkirchen', zip: '82467'},
    {city: 'Garmisch-Partenkirchen', zip: '82475'},
    {city: 'Garrel', zip: '49681'},
    {city: 'Gars am Inn', zip: '83536'},
    {city: 'Gars am Inn', zip: '83546'},
    {city: 'Gars am Inn', zip: '83555'},
    {city: 'Gars am Inn', zip: '83559'},
    {city: 'Garstedt', zip: '21441'},
    {city: 'Gartow', zip: '29471'},
    {city: 'Gärtringen', zip: '71116'},
    {city: 'Gartz (Oder)', zip: '16307'},
    {city: 'Garz', zip: '17419'},
    {city: 'Garzau-Garzin', zip: '15345'},
    {city: 'Garz/Rügen', zip: '18574'},
    {city: 'Gattendorf', zip: '95185'},
    {city: 'Gau-Algesheim', zip: '55435'},
    {city: 'Gau-Bickelheim', zip: '55599'},
    {city: 'Gau-Bischofsheim', zip: '55296'},
    {city: 'Gauern', zip: '07580'},
    {city: 'Gauersheim', zip: '67294'},
    {city: 'Gäufelden', zip: '71126'},
    {city: 'Gaugrehweiler', zip: '67822'},
    {city: 'Gau-Heppenheim', zip: '55234'},
    {city: 'Gaukönigshofen', zip: '97253'},
    {city: 'Gau-Odernheim', zip: '55239'},
    {city: 'Gaushorn', zip: '25782'},
    {city: 'Gauting', zip: '82131'},
    {city: 'Gau-Weinheim', zip: '55578'},
    {city: 'Gebenbach', zip: '92274'},
    {city: 'Gebesee', zip: '99189'},
    {city: 'Gebhardshain', zip: '57580'},
    {city: 'Gebroth', zip: '55595'},
    {city: 'Gebsattel', zip: '91607'},
    {city: 'Gechingen', zip: '75391'},
    {city: 'Gedern', zip: '63688'},
    {city: 'Geeste', zip: '49744'},
    {city: 'Geesthacht', zip: '21502'},
    {city: 'Geestland', zip: '27607'},
    {city: 'Geestland', zip: '27624'},
    {city: 'Gefell', zip: '54552'},
    {city: 'Gefell', zip: '07926'},
    {city: 'Gefrees', zip: '95482'},
    {city: 'Gehlert', zip: '57627'},
    {city: 'Gehlsbach', zip: '19386'},
    {city: 'Gehlweiler', zip: '55490'},
    {city: 'Gehofen', zip: '06571'},
    {city: 'Gehrde', zip: '49596'},
    {city: 'Gehrden', zip: '30989'},
    {city: 'Gehrweiler', zip: '67724'},
    {city: 'Geichlingen', zip: '54675'},
    {city: 'Geiersthal', zip: '94244'},
    {city: 'Geilenkirchen', zip: '52511'},
    {city: 'Geilnau', zip: '56379'},
    {city: 'Geisa', zip: '36419'},
    {city: 'Geiselbach', zip: '63826'},
    {city: 'Geiselberg', zip: '67715'},
    {city: 'Geiselhöring', zip: '94333'},
    {city: 'Geiselwind', zip: '96160'},
    {city: 'Geisenfeld', zip: '85290'},
    {city: 'Geisenhain', zip: '07646'},
    {city: 'Geisenhausen', zip: '84144'},
    {city: 'Geisenheim', zip: '65366'},
    {city: 'Geisfeld', zip: '54413'},
    {city: 'Geisig', zip: '56357'},
    {city: 'Geisingen', zip: '78187'},
    {city: 'Geisleden', zip: '37308'},
    {city: 'Geislingen', zip: '72351'},
    {city: 'Geislingen an der Steige', zip: '73312'},
    {city: 'Geismar', zip: '37308'},
    {city: 'Geithain', zip: '04643'},
    {city: 'Gelbensande', zip: '18182'},
    {city: 'Gelchsheim', zip: '97255'},
    {city: 'Geldern', zip: '47608'},
    {city: 'Geldersheim', zip: '97505'},
    {city: 'Gelenau/Erzgebirge', zip: '09423'},
    {city: 'Gelenberg', zip: '53539'},
    {city: 'Gelnhausen', zip: '63571'},
    {city: 'Gelnhausen', zip: '63589'},
    {city: 'Gelsenkirchen', zip: '45879'},
    {city: 'Gelsenkirchen', zip: '45881'},
    {city: 'Gelsenkirchen', zip: '45883'},
    {city: 'Gelsenkirchen', zip: '45884'},
    {city: 'Gelsenkirchen', zip: '45886'},
    {city: 'Gelsenkirchen', zip: '45888'},
    {city: 'Gelsenkirchen', zip: '45889'},
    {city: 'Gelsenkirchen', zip: '45891'},
    {city: 'Gelsenkirchen', zip: '45892'},
    {city: 'Gelsenkirchen', zip: '45894'},
    {city: 'Gelsenkirchen', zip: '45896'},
    {city: 'Gelsenkirchen', zip: '45897'},
    {city: 'Gelsenkirchen', zip: '45899'},
    {city: 'Geltendorf', zip: '82269'},
    {city: 'Gelting', zip: '24395'},
    {city: 'Geltorf', zip: '24884'},
    {city: 'Gemmerich', zip: '56357'},
    {city: 'Gemmingen', zip: '75050'},
    {city: 'Gemmrigheim', zip: '74376'},
    {city: 'Gemünd', zip: '54673'},
    {city: 'Gemünden', zip: '35285'},
    {city: 'Gemünden', zip: '56459'},
    {city: 'Gemünden', zip: '55490'},
    {city: 'Gemünden am Main', zip: '97737'},
    {city: 'Gemünden (Felda)', zip: '35329'},
    {city: 'Genderkingen', zip: '86682'},
    {city: 'Gengenbach', zip: '77723'},
    {city: 'Gensingen', zip: '55457'},
    {city: 'Genthin', zip: '39291'},
    {city: 'Genthin', zip: '39307'},
    {city: 'Gentingen', zip: '54675'},
    {city: 'Georgenberg', zip: '92697'},
    {city: 'Georgensgmünd', zip: '91166'},
    {city: 'Georgenthal', zip: '99887'},
    {city: 'Georgsdorf', zip: '49828'},
    {city: 'Georgsmarienhütte', zip: '49124'},
    {city: 'Gera', zip: '07545'},
    {city: 'Gera', zip: '07546'},
    {city: 'Gera', zip: '07548'},
    {city: 'Gera', zip: '07549'},
    {city: 'Gera', zip: '07551'},
    {city: 'Gera', zip: '07552'},
    {city: 'Gera', zip: '07554'},
    {city: 'Gera', zip: '07557'},
    {city: 'Gerabronn', zip: '74582'},
    {city: 'Gerach', zip: '96161'},
    {city: 'Gerach', zip: '55743'},
    {city: 'Geratal', zip: '99330'},
    {city: 'Geratal', zip: '99331'},
    {city: 'Geratskirchen', zip: '84552'},
    {city: 'Gerbach', zip: '67813'},
    {city: 'Gerbershausen', zip: '37318'},
    {city: 'Gerbrunn', zip: '97218'},
    {city: 'Gerbstedt', zip: '06347'},
    {city: 'Gerdau', zip: '29581'},
    {city: 'Gerdshagen', zip: '16928'},
    {city: 'Geretsried', zip: '82538'},
    {city: 'Gerhardsbrunn', zip: '66851'},
    {city: 'Gerhardsbrunn', zip: '66894'},
    {city: 'Gerhardshofen', zip: '91466'},
    {city: 'Gering', zip: '56751'},
    {city: 'Geringswalde', zip: '09326'},
    {city: 'Gerlingen', zip: '70839'},
    {city: 'Germaringen', zip: '87656'},
    {city: 'Germering', zip: '82110'},
    {city: 'Germersheim', zip: '76726'},
    {city: 'Gernrode', zip: '37339'},
    {city: 'Gernsbach', zip: '76593'},
    {city: 'Gernsheim', zip: '64579'},
    {city: 'Geroda', zip: '97779'},
    {city: 'Geroda', zip: '07819'},
    {city: 'Geroldsgrün', zip: '95179'},
    {city: 'Geroldshausen', zip: '97256'},
    {city: 'Gerolfingen', zip: '91726'},
    {city: 'Gerolsbach', zip: '85302'},
    {city: 'Gerolsheim', zip: '67229'},
    {city: 'Gerolstein', zip: '54568'},
    {city: 'Gerolzhofen', zip: '97447'},
    {city: 'Gersdorf', zip: '09355'},
    {city: 'Gersfeld', zip: '36129'},
    {city: 'Gersheim', zip: '66453'},
    {city: 'Gersten', zip: '49838'},
    {city: 'Gerstenberg', zip: '04617'},
    {city: 'Gerstengrund', zip: '36419'},
    {city: 'Gerstetten', zip: '89547'},
    {city: 'Gersthofen', zip: '86368'},
    {city: 'Gerstungen', zip: '99834'},
    {city: 'Gerswalde', zip: '17268'},
    {city: 'Gertewitz', zip: '07389'},
    {city: 'Gerzen', zip: '84175'},
    {city: 'Geschendorf', zip: '23815'},
    {city: 'Gescher', zip: '48712'},
    {city: 'Gesees', zip: '95494'},
    {city: 'Geseke', zip: '59590'},
    {city: 'Geslau', zip: '91608'},
    {city: 'Gessertshausen', zip: '86459'},
    {city: 'Gestratz', zip: '88167'},
    {city: 'Getelo', zip: '49843'},
    {city: 'Gettorf', zip: '24214'},
    {city: 'Gevelsberg', zip: '58285'},
    {city: 'Gevenich', zip: '56825'},
    {city: 'Gevensleben', zip: '38384'},
    {city: 'Geyer', zip: '09468'},
    {city: 'Giebelstadt', zip: '97232'},
    {city: 'Gieboldehausen', zip: '37434'},
    {city: 'Giekau', zip: '24321'},
    {city: 'Gieleroth', zip: '57610'},
    {city: 'Gielert', zip: '54424'},
    {city: 'Gielow', zip: '17139'},
    {city: 'Giengen an der Brenz', zip: '89522'},
    {city: 'Giengen an der Brenz', zip: '89537'},
    {city: 'Gierschnach', zip: '56294'},
    {city: 'Giershausen', zip: '57632'},
    {city: 'Giersleben', zip: '06449'},
    {city: 'Gierstädt', zip: '99100'},
    {city: 'Giesdorf', zip: '54614'},
    {city: 'Giesen', zip: '31180'},
    {city: 'Giesenhausen', zip: '57612'},
    {city: 'Giesensdorf', zip: '23909'},
    {city: 'Gießen', zip: '35390'},
    {city: 'Gießen', zip: '35392'},
    {city: 'Gießen', zip: '35394'},
    {city: 'Gießen', zip: '35396'},
    {city: 'Gießen', zip: '35398'},
    {city: 'Gifhorn', zip: '38518'},
    {city: 'Gifhorn', zip: '38559'},
    {city: 'Gilching', zip: '82205'},
    {city: 'Gillenbeuren', zip: '56825'},
    {city: 'Gillenfeld', zip: '54558'},
    {city: 'Gilserberg', zip: '34630'},
    {city: 'Gilten', zip: '29690'},
    {city: 'Gilzem', zip: '54298'},
    {city: 'Gimbsheim', zip: '67578'},
    {city: 'Gimbweiler', zip: '55767'},
    {city: 'Gindorf', zip: '54657'},
    {city: 'Gingen an der Fils', zip: '73333'},
    {city: 'Gingst', zip: '18569'},
    {city: 'Ginsheim-Gustavsburg', zip: '65462'},
    {city: 'Ginsweiler', zip: '67742'},
    {city: 'Gipperath', zip: '54533'},
    {city: 'Girkenroth', zip: '56459'},
    {city: 'Girod', zip: '56412'},
    {city: 'Gladbach', zip: '54518'},
    {city: 'Gladbeck', zip: '45964'},
    {city: 'Gladbeck', zip: '45966'},
    {city: 'Gladbeck', zip: '45968'},
    {city: 'Gladenbach', zip: '35075'},
    {city: 'Glanbrücken', zip: '66887'},
    {city: 'Glandorf', zip: '49219'},
    {city: 'Glan-Münchweiler', zip: '66907'},
    {city: 'Glasau', zip: '23719'},
    {city: 'Glasehausen', zip: '37308'},
    {city: 'Glasewitz', zip: '18276'},
    {city: 'Glashütte', zip: '01768'},
    {city: 'Glashütten', zip: '61479'},
    {city: 'Glashütten', zip: '95496'},
    {city: 'Glasin', zip: '23992'},
    {city: 'Glasow', zip: '17322'},
    {city: 'Glattbach', zip: '63864'},
    {city: 'Glatten', zip: '72293'},
    {city: 'Glaubitz', zip: '01612'},
    {city: 'Glauburg', zip: '63695'},
    {city: 'Glauchau', zip: '08371'},
    {city: 'Glees', zip: '56653'},
    {city: 'Gleichen', zip: '37130'},
    {city: 'Gleina', zip: '06632'},
    {city: 'Gleiritsch', zip: '92723'},
    {city: 'Gleißenberg', zip: '93477'},
    {city: 'Gleisweiler', zip: '76835'},
    {city: 'Gleiszellen-Gleishorbach', zip: '76889'},
    {city: 'Glewitz', zip: '18513'},
    {city: 'Glienicke/Nordbahn', zip: '16548'},
    {city: 'Glinde', zip: '21509'},
    {city: 'Glonn', zip: '85625'},
    {city: 'Glött', zip: '89353'},
    {city: 'Glottertal', zip: '79286'},
    {city: 'Glowe', zip: '18551'},
    {city: 'Glücksburg', zip: '24960'},
    {city: 'Glückstadt', zip: '25348'},
    {city: 'Glüsing', zip: '25779'},
    {city: 'Gmund', zip: '83703'},
    {city: 'Gnarrenburg', zip: '27442'},
    {city: 'Gneus', zip: '07646'},
    {city: 'Gneven', zip: '19065'},
    {city: 'Gnevkow', zip: '17089'},
    {city: 'Gnewitz', zip: '18195'},
    {city: 'Gnoien', zip: '17179'},
    {city: 'Gnotzheim', zip: '91728'},
    {city: 'Gnutz', zip: '24622'},
    {city: 'Goch', zip: '47574'},
    {city: 'Gochsheim', zip: '97469'},
    {city: 'Göcklingen', zip: '76831'},
    {city: 'Göda', zip: '02633'},
    {city: 'Goddert', zip: '56244'},
    {city: 'Godendorf', zip: '17237'},
    {city: 'Gödenroth', zip: '56290'},
    {city: 'Gödenstorf', zip: '21376'},
    {city: 'Goethestadt Bad Lauchstädt', zip: '06246'},
    {city: 'Göggingen', zip: '73571'},
    {city: 'Göhl', zip: '23758'},
    {city: 'Göhlen', zip: '19288'},
    {city: 'Göhrde', zip: '29473'},
    {city: 'Göhren', zip: '18586'},
    {city: 'Göhren', zip: '04603'},
    {city: 'Göhren-Lebbin', zip: '17213'},
    {city: 'Gohrisch', zip: '01824'},
    {city: 'Gokels', zip: '25557'},
    {city: 'Golchen', zip: '17089'},
    {city: 'Goldbach', zip: '63773'},
    {city: 'Goldbeck', zip: '39596'},
    {city: 'Goldberg', zip: '19399'},
    {city: 'Goldebek', zip: '25862'},
    {city: 'Goldelund', zip: '25862'},
    {city: 'Göldenitz', zip: '23919'},
    {city: 'Goldenstedt', zip: '49424'},
    {city: 'Goldisthal', zip: '98746'},
    {city: 'Goldkronach', zip: '95497'},
    {city: 'Gölenkamp', zip: '49843'},
    {city: 'Gollenberg', zip: '55767'},
    {city: 'Gollenberg', zip: '14728'},
    {city: 'Göllheim', zip: '67307'},
    {city: 'Gollhofen', zip: '97258'},
    {city: 'Göllnitz', zip: '04626'},
    {city: 'Golmbach', zip: '37640'},
    {city: 'Golmsdorf', zip: '07751'},
    {city: 'Golßen', zip: '15938'},
    {city: 'Golzow', zip: '14778'},
    {city: 'Golzow', zip: '15328'},
    {city: 'Gomadingen', zip: '72532'},
    {city: 'Gomaringen', zip: '72810'},
    {city: 'Gommern', zip: '39175'},
    {city: 'Gommern', zip: '39245'},
    {city: 'Gommern', zip: '39264'},
    {city: 'Gommern', zip: '39279'},
    {city: 'Gommern', zip: '39291'},
    {city: 'Gommersheim', zip: '67377'},
    {city: 'Gonbach', zip: '67724'},
    {city: 'Gondelsheim', zip: '75053'},
    {city: 'Gondenbrett', zip: '54595'},
    {city: 'Gondershausen', zip: '56283'},
    {city: 'Gondorf', zip: '54647'},
    {city: 'Gönnebek', zip: '24610'},
    {city: 'Gönnersdorf', zip: '53498'},
    {city: 'Gönnersdorf', zip: '54584'},
    {city: 'Gönnheim', zip: '67161'},
    {city: 'Goosefeld', zip: '24340'},
    {city: 'Göpfersdorf', zip: '04618'},
    {city: 'Göppingen', zip: '73033'},
    {city: 'Göppingen', zip: '73035'},
    {city: 'Göppingen', zip: '73037'},
    {city: 'Göppingen', zip: '73116'},
    {city: 'Gorden-Staupitz', zip: '03238'},
    {city: 'Görgeshausen', zip: '56412'},
    {city: 'Görisried', zip: '87657'},
    {city: 'Göritz', zip: '17291'},
    {city: 'Görkwitz', zip: '07907'},
    {city: 'Gorleben', zip: '29475'},
    {city: 'Görlitz', zip: '02826'},
    {city: 'Görlitz', zip: '02827'},
    {city: 'Görlitz', zip: '02828'},
    {city: 'Gorlosen', zip: '19294'},
    {city: 'Görmin', zip: '17121'},
    {city: 'Gornau', zip: '09405'},
    {city: 'Gornau', zip: '09437'},
    {city: 'Gornau', zip: '09573'},
    {city: 'Gornhausen', zip: '54472'},
    {city: 'Gornsdorf', zip: '09390'},
    {city: 'Görsbach', zip: '99765'},
    {city: 'Görwihl', zip: '79733'},
    {city: 'Gorxheimertal', zip: '69517'},
    {city: 'Görzke', zip: '14828'},
    {city: 'Göschitz', zip: '07907'},
    {city: 'Goseck', zip: '06667'},
    {city: 'Gösen', zip: '07607'},
    {city: 'Gosen-Neu Zittau', zip: '15537'},
    {city: 'Gösenroth', zip: '55624'},
    {city: 'Gosheim', zip: '78559'},
    {city: 'Goslar', zip: '38640'},
    {city: 'Goslar', zip: '38642'},
    {city: 'Goslar', zip: '38644'},
    {city: 'Goslar', zip: '38690'},
    {city: 'Gössenheim', zip: '97780'},
    {city: 'Gossersweiler-Stein', zip: '76857'},
    {city: 'Gössitz', zip: '07389'},
    {city: 'Gößnitz', zip: '04639'},
    {city: 'Gößweinstein', zip: '91327'},
    {city: 'Gotha', zip: '99867'},
    {city: 'Gottenheim', zip: '79288'},
    {city: 'Gottesgabe', zip: '19209'},
    {city: 'Gotteszell', zip: '94239'},
    {city: 'Gottfrieding', zip: '84177'},
    {city: 'Gotthun', zip: '17207'},
    {city: 'Göttin', zip: '21514'},
    {city: 'Göttingen', zip: '37073'},
    {city: 'Göttingen', zip: '37075'},
    {city: 'Göttingen', zip: '37077'},
    {city: 'Göttingen', zip: '37079'},
    {city: 'Göttingen', zip: '37081'},
    {city: 'Göttingen', zip: '37083'},
    {city: 'Göttingen', zip: '37085'},
    {city: 'Gottmadingen', zip: '78244'},
    {city: 'Graach an der Mosel', zip: '54470'},
    {city: 'Graal-Müritz', zip: '18181'},
    {city: 'Grabau', zip: '23845'},
    {city: 'Grabau', zip: '21493'},
    {city: 'Graben', zip: '86836'},
    {city: 'Gräben', zip: '14793'},
    {city: 'Graben-Neudorf', zip: '76676'},
    {city: 'Grabenstätt', zip: '83355'},
    {city: 'Grabenstetten', zip: '72582'},
    {city: 'Grabfeld', zip: '98631'},
    {city: 'Grabow', zip: '19300'},
    {city: 'Grabowhöfe', zip: '17194'},
    {city: 'Gräfelfing', zip: '82166'},
    {city: 'Grafenau', zip: '71120'},
    {city: 'Grafenau', zip: '94481'},
    {city: 'Grafenberg', zip: '72661'},
    {city: 'Gräfenberg', zip: '91322'},
    {city: 'Gräfendhron', zip: '54426'},
    {city: 'Gräfendorf', zip: '97782'},
    {city: 'Grafengehaig', zip: '95356'},
    {city: 'Gräfenhainichen', zip: '06772'},
    {city: 'Gräfenhainichen', zip: '06773'},
    {city: 'Grafenhausen', zip: '79865'},
    {city: 'Grafenrheinfeld', zip: '97506'},
    {city: 'Gräfenthal', zip: '98743'},
    {city: 'Grafenwiesen', zip: '93479'},
    {city: 'Grafenwöhr', zip: '92655'},
    {city: 'Grafhorst', zip: '38462'},
    {city: 'Grafing bei München', zip: '85567'},
    {city: 'Grafling', zip: '94539'},
    {city: 'Grafrath', zip: '82284'},
    {city: 'Grafschaft', zip: '53501'},
    {city: 'Grainau', zip: '82491'},
    {city: 'Grainet', zip: '94143'},
    {city: 'Graitschen bei Bürgel', zip: '07616'},
    {city: 'Grambek', zip: '23883'},
    {city: 'Grambin', zip: '17375'},
    {city: 'Grambow', zip: '17322'},
    {city: 'Grambow', zip: '19071'},
    {city: 'Grambow', zip: '19073'},
    {city: 'Grammendorf', zip: '18513'},
    {city: 'Grammentin', zip: '17153'},
    {city: 'Grammetal', zip: '99428'},
    {city: 'Grammow', zip: '18195'},
    {city: 'Gramzow', zip: '17291'},
    {city: 'Grande', zip: '22946'},
    {city: 'Gransdorf', zip: '54533'},
    {city: 'Gransebieth', zip: '18513'},
    {city: 'Gransee', zip: '16775'},
    {city: 'Granzin', zip: '19386'},
    {city: 'Grapzow', zip: '17089'},
    {city: 'Grasberg', zip: '28879'},
    {city: 'Grasbrunn', zip: '85630'},
    {city: 'Grasellenbach', zip: '64689'},
    {city: 'Grasleben', zip: '38368'},
    {city: 'Grassau', zip: '83224'},
    {city: 'Grattersdorf', zip: '94541'},
    {city: 'Grauel', zip: '24594'},
    {city: 'Grävenwiesbach', zip: '61279'},
    {city: 'Grebenau', zip: '36323'},
    {city: 'Grebenhain', zip: '36355'},
    {city: 'Grebenstein', zip: '34393'},
    {city: 'Grebin', zip: '24329'},
    {city: 'Grebs-Niendorf', zip: '19294'},
    {city: 'Greding', zip: '91171'},
    {city: 'Grefrath', zip: '47929'},
    {city: 'Greifenberg', zip: '86926'},
    {city: 'Greifenstein', zip: '35753'},
    {city: 'Greifswald', zip: '17489'},
    {city: 'Greifswald', zip: '17491'},
    {city: 'Greifswald', zip: '17493'},
    {city: 'Greiling', zip: '83677'},
    {city: 'Greimerath', zip: '54314'},
    {city: 'Greimerath', zip: '54533'},
    {city: 'Greimersburg', zip: '56814'},
    {city: 'Greiz', zip: '07973'},
    {city: 'Gremersdorf', zip: '23758'},
    {city: 'Gremersdorf-Buchholz', zip: '18461'},
    {city: 'Gremsdorf', zip: '91350'},
    {city: 'Grenderich', zip: '56858'},
    {city: 'Grenzach-Wyhlen', zip: '79639'},
    {city: 'Gresse', zip: '19258'},
    {city: 'Grethem', zip: '29690'},
    {city: 'Grettstadt', zip: '97508'},
    {city: 'Greußen', zip: '99718'},
    {city: 'Greußenheim', zip: '97259'},
    {city: 'Greven', zip: '48268'},
    {city: 'Greven', zip: '19258'},
    {city: 'Grevenbroich', zip: '41515'},
    {city: 'Grevenbroich', zip: '41516'},
    {city: 'Grevenbroich', zip: '41517'},
    {city: 'Grevenkop', zip: '25361'},
    {city: 'Grevenkrug', zip: '24241'},
    {city: 'Grevesmühlen', zip: '23936'},
    {city: 'Gribbohm', zip: '25596'},
    {city: 'Gribow', zip: '17506'},
    {city: 'Griebelschied', zip: '55608'},
    {city: 'Grieben', zip: '23936'},
    {city: 'Griefstedt', zip: '99638'},
    {city: 'Gries', zip: '66903'},
    {city: 'Griesheim', zip: '64347'},
    {city: 'Griesingen', zip: '89608'},
    {city: 'Griesstätt', zip: '83556'},
    {city: 'Grimburg', zip: '54413'},
    {city: 'Grimma', zip: '04668'},
    {city: 'Grimmelshausen', zip: '98660'},
    {city: 'Grimmen', zip: '18507'},
    {city: 'Grinau', zip: '23847'},
    {city: 'Grischow', zip: '17089'},
    {city: 'Grobengereuth', zip: '07389'},
    {city: 'Gröbenzell', zip: '82194'},
    {city: 'Gröde', zip: '25869'},
    {city: 'Gröden', zip: '04932'},
    {city: 'Grödersby', zip: '24376'},
    {city: 'Grödersby', zip: '24399'},
    {city: 'Gröditz', zip: '01609'},
    {city: 'Groitzsch', zip: '04539'},
    {city: 'Grolsheim', zip: '55459'},
    {city: 'Grömbach', zip: '72294'},
    {city: 'Grömbach', zip: '72297'},
    {city: 'Grömitz', zip: '23743'},
    {city: 'Gronau', zip: '48599'},
    {city: 'Gronau', zip: '31028'},
    {city: 'Gröningen', zip: '39397'},
    {city: 'Grönwohld', zip: '22956'},
    {city: 'Großaitingen', zip: '86845'},
    {city: 'Großalmerode', zip: '37247'},
    {city: 'Großbardorf', zip: '97633'},
    {city: 'Großbarkau', zip: '24245'},
    {city: 'Großbartloff', zip: '37359'},
    {city: 'Großbeeren', zip: '14979'},
    {city: 'Groß Berßen', zip: '49777'},
    {city: 'Großbettlingen', zip: '72663'},
    {city: 'Groß-Bieberau', zip: '64395'},
    {city: 'Groß-Bieberau', zip: '64401'},
    {city: 'Großbockedra', zip: '07646'},
    {city: 'Groß Boden', zip: '23847'},
    {city: 'Großbottwar', zip: '71723'},
    {city: 'Großbreitenbach', zip: '98701'},
    {city: 'Groß Buchwald', zip: '24582'},
    {city: 'Großbundenbach', zip: '66501'},
    {city: 'Großderschau', zip: '16845'},
    {city: 'Groß Disnack', zip: '23911'},
    {city: 'Groß Düben', zip: '02953'},
    {city: 'Groß Düben', zip: '02959'},
    {city: 'Großdubrau', zip: '02694'},
    {city: 'Großefehn', zip: '26629'},
    {city: 'Großeibstadt', zip: '97633'},
    {city: 'Grosselfingen', zip: '72415'},
    {city: 'Großenaspe', zip: '24623'},
    {city: 'Großenbrode', zip: '23775'},
    {city: 'Großenehrich', zip: '99718'},
    {city: 'Großenhain', zip: '01558'},
    {city: 'Großenhain', zip: '01561'},
    {city: 'Großenkneten', zip: '26197'},
    {city: 'Großenlüder', zip: '36137'},
    {city: 'Großenrade', zip: '25712'},
    {city: 'Großensee', zip: '22946'},
    {city: 'Großenseebach', zip: '91091'},
    {city: 'Großenstein', zip: '07580'},
    {city: 'Großenwiehe', zip: '24969'},
    {city: 'Großenwörden', zip: '21712'},
    {city: 'Großerlach', zip: '71577'},
    {city: 'Großeutersdorf', zip: '07768'},
    {city: 'Großfahner', zip: '99100'},
    {city: 'Großfischlingen', zip: '67483'},
    {city: 'Groß-Gerau', zip: '64521'},
    {city: 'Groß Godems', zip: '19372'},
    {city: 'Groß Grönau', zip: '23627'},
    {city: 'Großhabersdorf', zip: '90613'},
    {city: 'Großhansdorf', zip: '22927'},
    {city: 'Großharrie', zip: '24625'},
    {city: 'Großharthau', zip: '01909'},
    {city: 'Großhartmannsdorf', zip: '09618'},
    {city: 'Großheide', zip: '26532'},
    {city: 'Großheirath', zip: '96269'},
    {city: 'Großheringen', zip: '99518'},
    {city: 'Großheubach', zip: '63920'},
    {city: 'Großholbach', zip: '56412'},
    {city: 'Groß Ippener', zip: '27243'},
    {city: 'Großkampenberg', zip: '54619'},
    {city: 'Großkarlbach', zip: '67229'},
    {city: 'Großkarolinenfeld', zip: '83109'},
    {city: 'Groß Kelle', zip: '17207'},
    {city: 'Groß Kiesow', zip: '17495'},
    {city: 'Großkmehlen', zip: '01990'},
    {city: 'Groß Kordshagen', zip: '18442'},
    {city: 'Groß Köris', zip: '15746'},
    {city: 'Groß Krams', zip: '19230'},
    {city: 'Groß Kreutz (Havel)', zip: '14550'},
    {city: 'Großkrotzenburg', zip: '63538'},
    {city: 'Groß Kummerfeld', zip: '24626'},
    {city: 'Groß Laasch', zip: '19288'},
    {city: 'Großlangenfeld', zip: '54608'},
    {city: 'Großlangheim', zip: '97320'},
    {city: 'Groß Lindow', zip: '15295'},
    {city: 'Großlittgen', zip: '54534'},
    {city: 'Großlöbichau', zip: '07751'},
    {city: 'Großlohra', zip: '99759'},
    {city: 'Groß Luckow', zip: '17337'},
    {city: 'Großmaischeid', zip: '56276'},
    {city: 'Groß Meckelsen', zip: '27419'},
    {city: 'Großmehring', zip: '85098'},
    {city: 'Groß Miltzow', zip: '17349'},
    {city: 'Groß Mohrdorf', zip: '18445'},
    {city: 'Großmölsen', zip: '99198'},
    {city: 'Groß Molzahn', zip: '19217'},
    {city: 'Großnaundorf', zip: '01936'},
    {city: 'Groß Nemerow', zip: '17094'},
    {city: 'Großneuhausen', zip: '99625'},
    {city: 'Großniedesheim', zip: '67259'},
    {city: 'Groß Niendorf', zip: '23816'},
    {city: 'Groß Nordende', zip: '25436'},
    {city: 'Groß Oesingen', zip: '29393'},
    {city: 'Groß Offenseth-Aspern', zip: '25355'},
    {city: 'Großolbersdorf', zip: '09432'},
    {city: 'Großostheim', zip: '63762'},
    {city: 'Groß Pampau', zip: '21493'},
    {city: 'Groß Pankow (Prignitz)', zip: '16928'},
    {city: 'Groß Plasten', zip: '17192'},
    {city: 'Groß Polzin', zip: '17390'},
    {city: 'Großpösna', zip: '04463'},
    {city: 'Großpostwitz/O.L.', zip: '02692'},
    {city: 'Großpürschütz', zip: '07751'},
    {city: 'Groß Quenstedt', zip: '38822'},
    {city: 'Großräschen', zip: '01983'},
    {city: 'Groß Rheide', zip: '24872'},
    {city: 'Großrinderfeld', zip: '97950'},
    {city: 'Groß Roge', zip: '17166'},
    {city: 'Groß-Rohrheim', zip: '68649'},
    {city: 'Großröhrsdorf', zip: '01900'},
    {city: 'Groß Rönnau', zip: '23795'},
    {city: 'Großrosseln', zip: '66352'},
    {city: 'Großrückerswalde', zip: '09518'},
    {city: 'Großrudestedt', zip: '99195'},
    {city: 'Groß Sarau', zip: '23627'},
    {city: 'Groß Schacksdorf-Simmersdorf', zip: '03149'},
    {city: 'Groß Schenkenberg', zip: '23860'},
    {city: 'Großschirma', zip: '09603'},
    {city: 'Großschönau', zip: '02779'},
    {city: 'Großschönau', zip: '02799'},
    {city: 'Großschwabhausen', zip: '99441'},
    {city: 'Großschweidnitz', zip: '02708'},
    {city: 'Groß Schwiesow', zip: '18276'},
    {city: 'Großseifen', zip: '56472'},
    {city: 'Großsolt', zip: '24991'},
    {city: 'Großsteinhausen', zip: '66484'},
    {city: 'Groß Stieten', zip: '23972'},
    {city: 'Groß Teetzleben', zip: '17091'},
    {city: 'Großthiemig', zip: '04932'},
    {city: 'Groß Twülpstedt', zip: '38464'},
    {city: 'Groß-Umstadt', zip: '64823'},
    {city: 'Großvargula', zip: '99958'},
    {city: 'Groß Vollstedt', zip: '24802'},
    {city: 'Großwallstadt', zip: '63868'},
    {city: 'Großweil', zip: '82439'},
    {city: 'Großweitzschen', zip: '04720'},
    {city: 'Groß Wittensee', zip: '24361'},
    {city: 'Groß Wokern', zip: '17166'},
    {city: 'Großwoltersdorf', zip: '16775'},
    {city: 'Groß Wüstenfelde', zip: '17168'},
    {city: 'Groß-Zimmern', zip: '64846'},
    {city: 'Grothusenkoog', zip: '25836'},
    {city: 'Grove', zip: '21493'},
    {city: 'Groven', zip: '25774'},
    {city: 'Grub', zip: '98530'},
    {city: 'Grub a.Forst', zip: '96271'},
    {city: 'Grube', zip: '23749'},
    {city: 'Gruibingen', zip: '73344'},
    {city: 'Grumbach', zip: '67745'},
    {city: 'Grünbach', zip: '08223'},
    {city: 'Grünberg', zip: '35305'},
    {city: 'Gründau', zip: '63584'},
    {city: 'Grundhof', zip: '24977'},
    {city: 'Grundsheim', zip: '89613'},
    {city: 'Grünebach', zip: '57520'},
    {city: 'Grünenbach', zip: '88167'},
    {city: 'Grünendeich', zip: '21720'},
    {city: 'Grünewald', zip: '01945'},
    {city: 'Grünhain-Beierfeld', zip: '08344'},
    {city: 'Grünhainichen', zip: '09579'},
    {city: 'Grünheide (Mark)', zip: '15537'},
    {city: 'Grünkraut', zip: '88287'},
    {city: 'Grünow', zip: '17291'},
    {city: 'Grünow', zip: '17237'},
    {city: 'Grunow-Dammendorf', zip: '15299'},
    {city: 'Grünsfeld', zip: '97947'},
    {city: 'Grünstadt', zip: '67269'},
    {city: 'Grünwald', zip: '82031'},
    {city: 'Grünwald', zip: '82064'},
    {city: 'Gschwend', zip: '74417'},
    {city: 'Gstadt am Chiemsee', zip: '83257'},
    {city: 'Guben', zip: '03172'},
    {city: 'Güby', zip: '24357'},
    {city: 'Guckheim', zip: '56459'},
    {city: 'Gückingen', zip: '65558'},
    {city: 'Gudendorf', zip: '25693'},
    {city: 'Gudensberg', zip: '34281'},
    {city: 'Guderhandviertel', zip: '21720'},
    {city: 'Gudow', zip: '23899'},
    {city: 'Guggenhausen', zip: '88379'},
    {city: 'Güglingen', zip: '74363'},
    {city: 'Guhrow', zip: '03096'},
    {city: 'Guldental', zip: '55452'},
    {city: 'Gülitz-Reetz', zip: '19348'},
    {city: 'Güllesheim', zip: '56593'},
    {city: 'Gültz', zip: '17089'},
    {city: 'Gülzow', zip: '17153'},
    {city: 'Gülzow', zip: '21483'},
    {city: 'Gülzow-Prüzen', zip: '18276'},
    {city: 'Gumbsheim', zip: '55597'},
    {city: 'Gummersbach', zip: '51643'},
    {city: 'Gummersbach', zip: '51645'},
    {city: 'Gummersbach', zip: '51647'},
    {city: 'Gumperda', zip: '07768'},
    {city: 'Gumtow', zip: '16866'},
    {city: 'Gundelfingen', zip: '79194'},
    {city: 'Gundelfingen a.d.Donau', zip: '89423'},
    {city: 'Gundelsheim', zip: '96163'},
    {city: 'Gundelsheim', zip: '74831'},
    {city: 'Gunderath', zip: '56767'},
    {city: 'Gundersheim', zip: '67598'},
    {city: 'Gundersweiler', zip: '67724'},
    {city: 'Gundheim', zip: '67599'},
    {city: 'Gundremmingen', zip: '89355'},
    {city: 'Gunningen', zip: '78594'},
    {city: 'Günstedt', zip: '99631'},
    {city: 'Guntersblum', zip: '67583'},
    {city: 'Güntersleben', zip: '97261'},
    {city: 'Günzach', zip: '87634'},
    {city: 'Günzburg', zip: '89312'},
    {city: 'Gunzenhausen', zip: '91710'},
    {city: 'Gusborn', zip: '29476'},
    {city: 'Gusenburg', zip: '54413'},
    {city: 'Gusow-Platkow', zip: '15306'},
    {city: 'Güsten', zip: '39439'},
    {city: 'Güster', zip: '21514'},
    {city: 'Gusterath', zip: '54317'},
    {city: 'Gustow', zip: '18574'},
    {city: 'Güstrow', zip: '18273'},
    {city: 'Gutach im Breisgau', zip: '79261'},
    {city: 'Gutach (Schwarzwaldbahn)', zip: '77793'},
    {city: 'Guteborn', zip: '01945'},
    {city: 'Gutenacker', zip: '56370'},
    {city: 'Gütenbach', zip: '78148'},
    {city: 'Gutenberg', zip: '55595'},
    {city: 'Gutenborn', zip: '06712'},
    {city: 'Guteneck', zip: '92543'},
    {city: 'Gutenstetten', zip: '91468'},
    {city: 'Gutenzell-Hürbel', zip: '88484'},
    {city: 'Gütersloh', zip: '33330'},
    {city: 'Gütersloh', zip: '33332'},
    {city: 'Gütersloh', zip: '33333'},
    {city: 'Gütersloh', zip: '33334'},
    {city: 'Gütersloh', zip: '33335'},
    {city: 'Gutow', zip: '18276'},
    {city: 'Guttenberg', zip: '95358'},
    {city: 'Gutweiler', zip: '54317'},
    {city: 'Gützkow', zip: '17506'},
    {city: 'Guxhagen', zip: '34302'},
    {city: 'Gyhum', zip: '27404'},
    {city: 'Haag', zip: '95473'},
    {city: 'Haag an der Amper', zip: '85410'},
    {city: 'Haag in Oberbayern', zip: '83527'},
    {city: 'Haale', zip: '24819'},
    {city: 'Haan', zip: '42781'},
    {city: 'Haar', zip: '85540'},
    {city: 'Haarbach', zip: '94501'},
    {city: 'Haarbach', zip: '94542'},
    {city: 'Habach', zip: '82392'},
    {city: 'Habichtswald', zip: '34317'},
    {city: 'Habscheid', zip: '54597'},
    {city: 'Haby', zip: '24361'},
    {city: 'Hachenburg', zip: '57627'},
    {city: 'Hackenheim', zip: '55546'},
    {city: 'Hadamar', zip: '65589'},
    {city: 'Hademstorf', zip: '29693'},
    {city: 'Hadenfeld', zip: '25560'},
    {city: 'Hafenlohr', zip: '97840'},
    {city: 'Hage', zip: '26524'},
    {city: 'Häg-Ehrsberg', zip: '79685'},
    {city: 'Hagelstadt', zip: '93095'},
    {city: 'Hagen', zip: '24576'},
    {city: 'Hagen', zip: '58089'},
    {city: 'Hagen', zip: '58091'},
    {city: 'Hagen', zip: '58093'},
    {city: 'Hagen', zip: '58095'},
    {city: 'Hagen', zip: '58097'},
    {city: 'Hagen', zip: '58099'},
    {city: 'Hagen', zip: '58119'},
    {city: 'Hagen', zip: '58135'},
    {city: 'Hagen am Teutoburger Wald', zip: '49170'},
    {city: 'Hagenbach', zip: '76767'},
    {city: 'Hagenbüchach', zip: '91469'},
    {city: 'Hagenburg', zip: '31558'},
    {city: 'Hagen im Bremischen', zip: '27628'},
    {city: 'Hagenow', zip: '19230'},
    {city: 'Hagermarsch', zip: '26524'},
    {city: 'Hagnau am Bodensee', zip: '88709'},
    {city: 'Hahausen', zip: '38729'},
    {city: 'Hahn', zip: '56850'},
    {city: 'Hahn am See', zip: '56244'},
    {city: 'Hahnbach', zip: '92256'},
    {city: 'Hahn bei Marienberg', zip: '56472'},
    {city: 'Hahnenbach', zip: '55606'},
    {city: 'Hahnheim', zip: '55278'},
    {city: 'Hähnichen', zip: '02923'},
    {city: 'Hahnstätten', zip: '65623'},
    {city: 'Hahnweiler', zip: '55776'},
    {city: 'Haibach', zip: '63808'},
    {city: 'Haibach', zip: '94353'},
    {city: 'Haidmühle', zip: '94145'},
    {city: 'Haiger', zip: '35708'},
    {city: 'Haigerloch', zip: '72401'},
    {city: 'Haimhausen', zip: '85778'},
    {city: 'Haiming', zip: '84533'},
    {city: 'Haina', zip: '35114'},
    {city: 'Hainau', zip: '56357'},
    {city: 'Hainburg', zip: '63512'},
    {city: 'Hainewalde', zip: '02779'},
    {city: 'Hainfeld', zip: '76835'},
    {city: 'Hainichen', zip: '09661'},
    {city: 'Hainichen', zip: '07778'},
    {city: 'Hainsfarth', zip: '86744'},
    {city: 'Hainspitz', zip: '07607'},
    {city: 'Haiterbach', zip: '72221'},
    {city: 'Halbe', zip: '15757'},
    {city: 'Halbemond', zip: '26524'},
    {city: 'Halberstadt', zip: '38820'},
    {city: 'Halberstadt', zip: '38822'},
    {city: 'Halberstadt', zip: '38895'},
    {city: 'Halblech', zip: '87642'},
    {city: 'Halbs', zip: '56457'},
    {city: 'Haldensleben', zip: '39340'},
    {city: 'Haldensleben', zip: '39343'},
    {city: 'Haldensleben', zip: '39345'},
    {city: 'Haldenwang', zip: '87490'},
    {city: 'Haldenwang', zip: '89356'},
    {city: 'Halenbeck-Rohlsdorf', zip: '16945'},
    {city: 'Halfing', zip: '83128'},
    {city: 'Hallbergmoos', zip: '85399'},
    {city: 'Halle', zip: '37620'},
    {city: 'Halle', zip: '49843'},
    {city: 'Hallenberg', zip: '59969'},
    {city: 'Hallerndorf', zip: '91352'},
    {city: 'Halle (Saale)', zip: '06108'},
    {city: 'Halle (Saale)', zip: '06110'},
    {city: 'Halle (Saale)', zip: '06112'},
    {city: 'Halle (Saale)', zip: '06114'},
    {city: 'Halle (Saale)', zip: '06116'},
    {city: 'Halle (Saale)', zip: '06118'},
    {city: 'Halle (Saale)', zip: '06120'},
    {city: 'Halle (Saale)', zip: '06122'},
    {city: 'Halle (Saale)', zip: '06124'},
    {city: 'Halle (Saale)', zip: '06126'},
    {city: 'Halle (Saale)', zip: '06128'},
    {city: 'Halle (Saale)', zip: '06130'},
    {city: 'Halle (Saale)', zip: '06132'},
    {city: 'Halle (Westf.)', zip: '33790'},
    {city: 'Hallgarten', zip: '67826'},
    {city: 'Hallig Hooge', zip: '25859'},
    {city: 'Hallschlag', zip: '54611'},
    {city: 'Hallstadt', zip: '96103'},
    {city: 'Hallungen', zip: '99826'},
    {city: 'Halsbach', zip: '84553'},
    {city: 'Halsbrücke', zip: '09633'},
    {city: 'Halsdorf', zip: '54646'},
    {city: 'Halsenbach', zip: '56283'},
    {city: 'Halstenbek', zip: '25469'},
    {city: 'Haltern am See', zip: '45721'},
    {city: 'Halver', zip: '58553'},
    {city: 'Halvesbostel', zip: '21646'},
    {city: 'Hambach', zip: '65582'},
    {city: 'Hamberge', zip: '23619'},
    {city: 'Hambergen', zip: '27729'},
    {city: 'Hambrücken', zip: '76707'},
    {city: 'Hambuch', zip: '56761'},
    {city: 'Hambühren', zip: '29313'},
    {city: 'Hamburg', zip: '20095'},
    {city: 'Hamburg', zip: '20097'},
    {city: 'Hamburg', zip: '20099'},
    {city: 'Hamburg', zip: '20144'},
    {city: 'Hamburg', zip: '20146'},
    {city: 'Hamburg', zip: '20148'},
    {city: 'Hamburg', zip: '20149'},
    {city: 'Hamburg', zip: '20249'},
    {city: 'Hamburg', zip: '20251'},
    {city: 'Hamburg', zip: '20253'},
    {city: 'Hamburg', zip: '20255'},
    {city: 'Hamburg', zip: '20257'},
    {city: 'Hamburg', zip: '20259'},
    {city: 'Hamburg', zip: '20354'},
    {city: 'Hamburg', zip: '20355'},
    {city: 'Hamburg', zip: '20357'},
    {city: 'Hamburg', zip: '20359'},
    {city: 'Hamburg', zip: '20457'},
    {city: 'Hamburg', zip: '20459'},
    {city: 'Hamburg', zip: '20535'},
    {city: 'Hamburg', zip: '20537'},
    {city: 'Hamburg', zip: '20539'},
    {city: 'Hamburg', zip: '21029'},
    {city: 'Hamburg', zip: '21031'},
    {city: 'Hamburg', zip: '21033'},
    {city: 'Hamburg', zip: '21035'},
    {city: 'Hamburg', zip: '21037'},
    {city: 'Hamburg', zip: '21039'},
    {city: 'Hamburg', zip: '21073'},
    {city: 'Hamburg', zip: '21075'},
    {city: 'Hamburg', zip: '21077'},
    {city: 'Hamburg', zip: '21079'},
    {city: 'Hamburg', zip: '21107'},
    {city: 'Hamburg', zip: '21109'},
    {city: 'Hamburg', zip: '21129'},
    {city: 'Hamburg', zip: '21147'},
    {city: 'Hamburg', zip: '21149'},
    {city: 'Hamburg', zip: '22041'},
    {city: 'Hamburg', zip: '22043'},
    {city: 'Hamburg', zip: '22045'},
    {city: 'Hamburg', zip: '22047'},
    {city: 'Hamburg', zip: '22049'},
    {city: 'Hamburg', zip: '22081'},
    {city: 'Hamburg', zip: '22083'},
    {city: 'Hamburg', zip: '22085'},
    {city: 'Hamburg', zip: '22087'},
    {city: 'Hamburg', zip: '22089'},
    {city: 'Hamburg', zip: '22111'},
    {city: 'Hamburg', zip: '22113'},
    {city: 'Hamburg', zip: '22115'},
    {city: 'Hamburg', zip: '22117'},
    {city: 'Hamburg', zip: '22119'},
    {city: 'Hamburg', zip: '22143'},
    {city: 'Hamburg', zip: '22145'},
    {city: 'Hamburg', zip: '22147'},
    {city: 'Hamburg', zip: '22149'},
    {city: 'Hamburg', zip: '22159'},
    {city: 'Hamburg', zip: '22175'},
    {city: 'Hamburg', zip: '22177'},
    {city: 'Hamburg', zip: '22179'},
    {city: 'Hamburg', zip: '22297'},
    {city: 'Hamburg', zip: '22299'},
    {city: 'Hamburg', zip: '22301'},
    {city: 'Hamburg', zip: '22303'},
    {city: 'Hamburg', zip: '22305'},
    {city: 'Hamburg', zip: '22307'},
    {city: 'Hamburg', zip: '22309'},
    {city: 'Hamburg', zip: '22335'},
    {city: 'Hamburg', zip: '22337'},
    {city: 'Hamburg', zip: '22339'},
    {city: 'Hamburg', zip: '22359'},
    {city: 'Hamburg', zip: '22391'},
    {city: 'Hamburg', zip: '22393'},
    {city: 'Hamburg', zip: '22395'},
    {city: 'Hamburg', zip: '22397'},
    {city: 'Hamburg', zip: '22399'},
    {city: 'Hamburg', zip: '22415'},
    {city: 'Hamburg', zip: '22417'},
    {city: 'Hamburg', zip: '22419'},
    {city: 'Hamburg', zip: '22453'},
    {city: 'Hamburg', zip: '22455'},
    {city: 'Hamburg', zip: '22457'},
    {city: 'Hamburg', zip: '22459'},
    {city: 'Hamburg', zip: '22523'},
    {city: 'Hamburg', zip: '22525'},
    {city: 'Hamburg', zip: '22527'},
    {city: 'Hamburg', zip: '22529'},
    {city: 'Hamburg', zip: '22547'},
    {city: 'Hamburg', zip: '22549'},
    {city: 'Hamburg', zip: '22559'},
    {city: 'Hamburg', zip: '22587'},
    {city: 'Hamburg', zip: '22589'},
    {city: 'Hamburg', zip: '22605'},
    {city: 'Hamburg', zip: '22607'},
    {city: 'Hamburg', zip: '22609'},
    {city: 'Hamburg', zip: '22761'},
    {city: 'Hamburg', zip: '22763'},
    {city: 'Hamburg', zip: '22765'},
    {city: 'Hamburg', zip: '22767'},
    {city: 'Hamburg', zip: '22769'},
    {city: 'Hamburg', zip: '27499'},
    {city: 'Hamdorf', zip: '24805'},
    {city: 'Hämelhausen', zip: '27324'},
    {city: 'Hameln', zip: '31785'},
    {city: 'Hameln', zip: '31787'},
    {city: 'Hameln', zip: '31789'},
    {city: 'Hamersen', zip: '27419'},
    {city: 'Hamfelde', zip: '22929'},
    {city: 'Hamfelde', zip: '22929'},
    {city: 'Hamm', zip: '59063'},
    {city: 'Hamm', zip: '59065'},
    {city: 'Hamm', zip: '59067'},
    {city: 'Hamm', zip: '59069'},
    {city: 'Hamm', zip: '59071'},
    {city: 'Hamm', zip: '59073'},
    {city: 'Hamm', zip: '59075'},
    {city: 'Hamm', zip: '59077'},
    {city: 'Hamm', zip: '54636'},
    {city: 'Hammah', zip: '21714'},
    {city: 'Hamm am Rhein', zip: '67580'},
    {city: 'Hammelburg', zip: '97762'},
    {city: 'Hammer a.d. Uecker', zip: '17358'},
    {city: 'Hammersbach', zip: '63546'},
    {city: 'Hammerstedt', zip: '99441'},
    {city: 'Hammerstein', zip: '56598'},
    {city: 'Hamminkeln', zip: '46499'},
    {city: 'Hammoor', zip: '22941'},
    {city: 'Hamm (Sieg)', zip: '57577'},
    {city: 'Hamwarde', zip: '21502'},
    {city: 'Hamweddel', zip: '24816'},
    {city: 'Hanau', zip: '63450'},
    {city: 'Hanau', zip: '63452'},
    {city: 'Hanau', zip: '63454'},
    {city: 'Hanau', zip: '63456'},
    {city: 'Hanau', zip: '63457'},
    {city: 'Handeloh', zip: '21256'},
    {city: 'Handewitt', zip: '24976'},
    {city: 'Handewitt', zip: '24983'},
    {city: 'Handorf', zip: '21447'},
    {city: 'Handrup', zip: '49838'},
    {city: 'Hanerau-Hademarschen', zip: '25557'},
    {city: 'Hangen-Weisheim', zip: '55234'},
    {city: 'Hanhofen', zip: '67374'},
    {city: 'Hankensbüttel', zip: '29386'},
    {city: 'Hann. Münden', zip: '34346'},
    {city: 'Hannover', zip: '30159'},
    {city: 'Hannover', zip: '30161'},
    {city: 'Hannover', zip: '30163'},
    {city: 'Hannover', zip: '30165'},
    {city: 'Hannover', zip: '30167'},
    {city: 'Hannover', zip: '30169'},
    {city: 'Hannover', zip: '30171'},
    {city: 'Hannover', zip: '30173'},
    {city: 'Hannover', zip: '30175'},
    {city: 'Hannover', zip: '30177'},
    {city: 'Hannover', zip: '30179'},
    {city: 'Hannover', zip: '30419'},
    {city: 'Hannover', zip: '30449'},
    {city: 'Hannover', zip: '30451'},
    {city: 'Hannover', zip: '30453'},
    {city: 'Hannover', zip: '30455'},
    {city: 'Hannover', zip: '30457'},
    {city: 'Hannover', zip: '30459'},
    {city: 'Hannover', zip: '30519'},
    {city: 'Hannover', zip: '30521'},
    {city: 'Hannover', zip: '30539'},
    {city: 'Hannover', zip: '30559'},
    {city: 'Hannover', zip: '30625'},
    {city: 'Hannover', zip: '30627'},
    {city: 'Hannover', zip: '30629'},
    {city: 'Hannover', zip: '30655'},
    {city: 'Hannover', zip: '30657'},
    {city: 'Hannover', zip: '30659'},
    {city: 'Hannover', zip: '30669'},
    {city: 'Hanroth', zip: '56316'},
    {city: 'Hanshagen', zip: '17509'},
    {city: 'Hanstedt', zip: '21271'},
    {city: 'Hanstedt', zip: '29582'},
    {city: 'Happurg', zip: '91230'},
    {city: 'Harbach', zip: '57572'},
    {city: 'Harbke', zip: '39365'},
    {city: 'Harburg (Schwaben)', zip: '86655'},
    {city: 'Hardebek', zip: '24616'},
    {city: 'Hardegsen', zip: '37181'},
    {city: 'Hardert', zip: '56579'},
    {city: 'Hardheim', zip: '74736'},
    {city: 'Hardt', zip: '56472'},
    {city: 'Hardt', zip: '78739'},
    {city: 'Hardthausen am Kocher', zip: '74239'},
    {city: 'Haren (Ems)', zip: '49733'},
    {city: 'Hargarten', zip: '54649'},
    {city: 'Hargesheim', zip: '55595'},
    {city: 'Harmsdorf', zip: '23738'},
    {city: 'Harmsdorf', zip: '23911'},
    {city: 'Harmstorf', zip: '21228'},
    {city: 'Harpstedt', zip: '27243'},
    {city: 'Harrislee', zip: '24955'},
    {city: 'Harschbach', zip: '56307'},
    {city: 'Harscheid', zip: '53520'},
    {city: 'Harsdorf', zip: '95499'},
    {city: 'Harsefeld', zip: '21698'},
    {city: 'Harsewinkel', zip: '33428'},
    {city: 'Harsleben', zip: '38829'},
    {city: 'Harspelt', zip: '54617'},
    {city: 'Harsum', zip: '31177'},
    {city: 'Hartenfels', zip: '56244'},
    {city: 'Hartenholm', zip: '24628'},
    {city: 'Hartenstein', zip: '08118'},
    {city: 'Hartenstein', zip: '91235'},
    {city: 'Hartha', zip: '04746'},
    {city: 'Harthausen', zip: '67376'},
    {city: 'Hartheim am Rhein', zip: '79258'},
    {city: 'Harth-Pöllnitz', zip: '07570'},
    {city: 'Härtlingen', zip: '56459'},
    {city: 'Hartmannsdorf', zip: '09232'},
    {city: 'Hartmannsdorf', zip: '07613'},
    {city: 'Hartmannsdorf', zip: '07586'},
    {city: 'Hartmannsdorf bei Kirchberg', zip: '08107'},
    {city: 'Hartmannsdorf-Reichenau', zip: '01762'},
    {city: 'Harxheim', zip: '55296'},
    {city: 'Harzgerode', zip: '06493'},
    {city: 'Harztor', zip: '99768'},
    {city: 'Hasbergen', zip: '49205'},
    {city: 'Hasborn', zip: '54533'},
    {city: 'Haschbach am Remigiusberg', zip: '66871'},
    {city: 'Hasel', zip: '79686'},
    {city: 'Haselau', zip: '25489'},
    {city: 'Haselbach', zip: '94354'},
    {city: 'Haselbach', zip: '04617'},
    {city: 'Haselbachtal', zip: '01920'},
    {city: 'Haseldorf', zip: '25489'},
    {city: 'Haselund', zip: '25855'},
    {city: 'Haselünne', zip: '49740'},
    {city: 'Hasenkrug', zip: '24616'},
    {city: 'Hasenmoor', zip: '24640'},
    {city: 'Haserich', zip: '56858'},
    {city: 'Haslach im Kinzigtal', zip: '77716'},
    {city: 'Hasloch', zip: '97907'},
    {city: 'Hasloh', zip: '25474'},
    {city: 'Haßbergen', zip: '31626'},
    {city: 'Hassel', zip: '39596'},
    {city: 'Hasselbach', zip: '57635'},
    {city: 'Hasselbach', zip: '56288'},
    {city: 'Hasselberg', zip: '24376'},
    {city: 'Hasselroth', zip: '63594'},
    {city: 'Hassel (Weser)', zip: '27324'},
    {city: 'Hassendorf', zip: '27367'},
    {city: 'Haßfurt', zip: '97437'},
    {city: 'Haßleben', zip: '99189'},
    {city: 'Haßloch', zip: '67454'},
    {city: 'Haßmersheim', zip: '74855'},
    {city: 'Haßmoor', zip: '24790'},
    {city: 'Haste', zip: '31559'},
    {city: 'Hatten', zip: '26209'},
    {city: 'Hattenhofen', zip: '82285'},
    {city: 'Hattenhofen', zip: '73110'},
    {city: 'Hattersheim', zip: '65795'},
    {city: 'Hattert', zip: '57644'},
    {city: 'Hattgenstein', zip: '55767'},
    {city: 'Hattingen', zip: '45525'},
    {city: 'Hattingen', zip: '45527'},
    {city: 'Hattingen', zip: '45529'},
    {city: 'Hattorf am Harz', zip: '37197'},
    {city: 'Hattstedt', zip: '25856'},
    {city: 'Hattstedtermarsch', zip: '25856'},
    {city: 'Hatzenbühl', zip: '76770'},
    {city: 'Hatzenport', zip: '56332'},
    {city: 'Hatzfeld', zip: '35116'},
    {city: 'Hauenstein', zip: '76846'},
    {city: 'Haundorf', zip: '91729'},
    {city: 'Hauneck', zip: '36282'},
    {city: 'Haunetal', zip: '36166'},
    {city: 'Haunsheim', zip: '89437'},
    {city: 'Hauptstuhl', zip: '66851'},
    {city: 'Hauroth', zip: '56761'},
    {city: 'Hausach', zip: '77756'},
    {city: 'Hausbay', zip: '56291'},
    {city: 'Hausen', zip: '97647'},
    {city: 'Hausen', zip: '63840'},
    {city: 'Hausen', zip: '93345'},
    {city: 'Hausen', zip: '91353'},
    {city: 'Hausen', zip: '55608'},
    {city: 'Hausen am Bussen', zip: '89597'},
    {city: 'Hausen am Tann', zip: '72361'},
    {city: 'Hausen b. Würzburg', zip: '97262'},
    {city: 'Hausen im Wiesental', zip: '79688'},
    {city: 'Hausen ob Verena', zip: '78595'},
    {city: 'Hausen (Wied)', zip: '53547'},
    {city: 'Hausen (Wied)', zip: '56588'},
    {city: 'Häusern', zip: '79837'},
    {city: 'Hausham', zip: '83734'},
    {city: 'Häuslingen', zip: '27336'},
    {city: 'Haussömmern', zip: '99955'},
    {city: 'Hausten', zip: '56745'},
    {city: 'Hausweiler', zip: '67742'},
    {city: 'Hauzenberg', zip: '94051'},
    {city: 'Havekost', zip: '21493'},
    {city: 'Havelaue', zip: '14715'},
    {city: 'Havelberg', zip: '39539'},
    {city: 'Havelsee', zip: '14798'},
    {city: 'Haverlah', zip: '38275'},
    {city: 'Havetoft', zip: '24873'},
    {city: 'Havixbeck', zip: '48329'},
    {city: 'Hawangen', zip: '87749'},
    {city: 'Hayingen', zip: '72534'},
    {city: 'Haynrode', zip: '37339'},
    {city: 'Hebertsfelden', zip: '84332'},
    {city: 'Hebertshausen', zip: '85241'},
    {city: 'Hebertshausen', zip: '85764'},
    {city: 'Hechingen', zip: '72379'},
    {city: 'Hechthausen', zip: '21755'},
    {city: 'Heckelberg-Brunow', zip: '16259'},
    {city: 'Hecken', zip: '55481'},
    {city: 'Heckenbach', zip: '53506'},
    {city: 'Heckenmünster', zip: '54518'},
    {city: 'Heckenmünster', zip: '54523'},
    {city: 'Heckhuscheid', zip: '54619'},
    {city: 'Hecklingen', zip: '39444'},
    {city: 'Heddert', zip: '54429'},
    {city: 'Heddesbach', zip: '69434'},
    {city: 'Heddesheim', zip: '68542'},
    {city: 'Hedeper', zip: '38322'},
    {city: 'Hedersleben', zip: '06458'},
    {city: 'Hedwigenkoog', zip: '25761'},
    {city: 'Heede', zip: '25355'},
    {city: 'Heede', zip: '26892'},
    {city: 'Heek', zip: '48619'},
    {city: 'Heemsen', zip: '31622'},
    {city: 'Heere', zip: '38277'},
    {city: 'Heeslingen', zip: '27404'},
    {city: 'Heeßen', zip: '31707'},
    {city: 'Hefersweiler', zip: '67753'},
    {city: 'Hehlen', zip: '37619'},
    {city: 'Heide', zip: '25746'},
    {city: 'Heideblick', zip: '15926'},
    {city: 'Heideck', zip: '91180'},
    {city: 'Heidekamp', zip: '23858'},
    {city: 'Heideland', zip: '03238'},
    {city: 'Heideland', zip: '07613'},
    {city: 'Heidelberg', zip: '69115'},
    {city: 'Heidelberg', zip: '69117'},
    {city: 'Heidelberg', zip: '69118'},
    {city: 'Heidelberg', zip: '69120'},
    {city: 'Heidelberg', zip: '69121'},
    {city: 'Heidelberg', zip: '69123'},
    {city: 'Heidelberg', zip: '69124'},
    {city: 'Heidelberg', zip: '69126'},
    {city: 'Heiden', zip: '46359'},
    {city: 'Heidenau', zip: '21258'},
    {city: 'Heidenau', zip: '01809'},
    {city: 'Heidenburg', zip: '54426'},
    {city: 'Heidenheim', zip: '91719'},
    {city: 'Heidenheim an der Brenz', zip: '89518'},
    {city: 'Heidenheim an der Brenz', zip: '89520'},
    {city: 'Heidenheim an der Brenz', zip: '89522'},
    {city: 'Heidenrod', zip: '65321'},
    {city: 'Heidersdorf', zip: '09526'},
    {city: 'Heidesee', zip: '15754'},
    {city: 'Heidgraben', zip: '25436'},
    {city: 'Heidmoor', zip: '24632'},
    {city: 'Heidmühlen', zip: '24598'},
    {city: 'Heidweiler', zip: '54518'},
    {city: 'Heigenbrücken', zip: '63869'},
    {city: 'Heikendorf', zip: '24226'},
    {city: 'Heilbach', zip: '54673'},
    {city: 'Heilbad Heiligenstadt', zip: '37308'},
    {city: 'Heilberscheid', zip: '56412'},
    {city: 'Heilbronn', zip: '74072'},
    {city: 'Heilbronn', zip: '74074'},
    {city: 'Heilbronn', zip: '74076'},
    {city: 'Heilbronn', zip: '74078'},
    {city: 'Heilbronn', zip: '74080'},
    {city: 'Heilbronn', zip: '74081'},
    {city: 'Heilenbach', zip: '54636'},
    {city: 'Heiligenberg', zip: '88633'},
    {city: 'Heiligengrabe', zip: '16909'},
    {city: 'Heiligenhafen', zip: '23774'},
    {city: 'Heiligenhaus', zip: '42579'},
    {city: 'Heiligenmoschel', zip: '67699'},
    {city: 'Heiligenroth', zip: '56412'},
    {city: 'Heiligenstadt', zip: '91332'},
    {city: 'Heiligenstedten', zip: '25524'},
    {city: 'Heiligenstedtenerkamp', zip: '25524'},
    {city: 'Heiligkreuzsteinach', zip: '69253'},
    {city: 'Heilsbronn', zip: '91560'},
    {city: 'Heilshoop', zip: '23619'},
    {city: 'Heimbach', zip: '52396'},
    {city: 'Heimbach', zip: '55779'},
    {city: 'Heimborn', zip: '57629'},
    {city: 'Heimbuchenthal', zip: '63872'},
    {city: 'Heimenkirch', zip: '88178'},
    {city: 'Heimertingen', zip: '87751'},
    {city: 'Heimsheim', zip: '71296'},
    {city: 'Heimweiler', zip: '55606'},
    {city: 'Heinade', zip: '37627'},
    {city: 'Heinbockel', zip: '21726'},
    {city: 'Heinersbrück', zip: '03185'},
    {city: 'Heinersreuth', zip: '95500'},
    {city: 'Heiningen', zip: '38312'},
    {city: 'Heiningen', zip: '73037'},
    {city: 'Heiningen', zip: '73092'},
    {city: 'Heiningen', zip: '73107'},
    {city: 'Heinkenborstel', zip: '24594'},
    {city: 'Heinrichsthal', zip: '63871'},
    {city: 'Heinrichswalde', zip: '17379'},
    {city: 'Heinsberg', zip: '52525'},
    {city: 'Heinsdorfergrund', zip: '08468'},
    {city: 'Heinsen', zip: '37649'},
    {city: 'Heinzenbach', zip: '55483'},
    {city: 'Heinzenberg', zip: '55606'},
    {city: 'Heinzenhausen', zip: '67742'},
    {city: 'Heisdorf', zip: '54614'},
    {city: 'Heist', zip: '25492'},
    {city: 'Heistenbach', zip: '65558'},
    {city: 'Heitersheim', zip: '79423'},
    {city: 'Helbedündorf', zip: '99713'},
    {city: 'Helbra', zip: '06311'},
    {city: 'Heldburg', zip: '98663'},
    {city: 'Heldenstein', zip: '84431'},
    {city: 'Helferskirchen', zip: '56244'},
    {city: 'Helgoland', zip: '27498'},
    {city: 'Hellenhahn-Schellenberg', zip: '56479'},
    {city: 'Hellenthal', zip: '53940'},
    {city: 'Hellertshausen', zip: '55758'},
    {city: 'Hellschen-Heringsand-Unterschaar', zip: '25764'},
    {city: 'Hellwege', zip: '27367'},
    {city: 'Helmbrechts', zip: '95233'},
    {city: 'Helmenzen', zip: '57612'},
    {city: 'Helmeroth', zip: '57612'},
    {city: 'Helmstadt', zip: '97264'},
    {city: 'Helmstadt-Bargen', zip: '74921'},
    {city: 'Helmstedt', zip: '38350'},
    {city: 'Helmstedt', zip: '38372'},
    {city: 'Helmstorf', zip: '24321'},
    {city: 'Helpsen', zip: '31691'},
    {city: 'Helsa', zip: '34298'},
    {city: 'Helse', zip: '25709'},
    {city: 'Heltersberg', zip: '67716'},
    {city: 'Helvesiek', zip: '27389'},
    {city: 'Hemau', zip: '93155'},
    {city: 'Hemdingen', zip: '25485'},
    {city: 'Hemer', zip: '58675'},
    {city: 'Hemhofen', zip: '91334'},
    {city: 'Hemme', zip: '25774'},
    {city: 'Hemmelzen', zip: '57612'},
    {city: 'Hemmersheim', zip: '97258'},
    {city: 'Hemmingen', zip: '71282'},
    {city: 'Hemmingen', zip: '30966'},
    {city: 'Hemmingstedt', zip: '25770'},
    {city: 'Hemmoor', zip: '21745'},
    {city: 'Hemsbach', zip: '69502'},
    {city: 'Hemsbünde', zip: '27386'},
    {city: 'Hemslingen', zip: '27386'},
    {city: 'Hemsloh', zip: '49453'},
    {city: 'Henau', zip: '55490'},
    {city: 'Hendungen', zip: '97640'},
    {city: 'Henfenfeld', zip: '91239'},
    {city: 'Henfstädt', zip: '98660'},
    {city: 'Hengersberg', zip: '94491'},
    {city: 'Hennef (Sieg)', zip: '53773'},
    {city: 'Hennigsdorf', zip: '16761'},
    {city: 'Hennstedt', zip: '25581'},
    {city: 'Hennstedt', zip: '25779'},
    {city: 'Hennweiler', zip: '55619'},
    {city: 'Henschtal', zip: '66909'},
    {city: 'Henstedt-Ulzburg', zip: '24558'},
    {city: 'Hentern', zip: '54314'},
    {city: 'Hepberg', zip: '85120'},
    {city: 'Heppenheim', zip: '64646'},
    {city: 'Hepstedt', zip: '27412'},
    {city: 'Herbertingen', zip: '88518'},
    {city: 'Herbolzheim', zip: '79336'},
    {city: 'Herborn', zip: '35745'},
    {city: 'Herborn', zip: '55758'},
    {city: 'Herbrechtingen', zip: '89542'},
    {city: 'Herbsleben', zip: '99955'},
    {city: 'Herbstadt', zip: '97633'},
    {city: 'Herbstein', zip: '36358'},
    {city: 'Herbstmühle', zip: '54673'},
    {city: 'Herchweiler', zip: '66871'},
    {city: 'Herdecke', zip: '58313'},
    {city: 'Herdorf', zip: '57562'},
    {city: 'Herdwangen-Schönach', zip: '88634'},
    {city: 'Heretsried', zip: '86465'},
    {city: 'Herford', zip: '32049'},
    {city: 'Herford', zip: '32051'},
    {city: 'Herford', zip: '32052'},
    {city: 'Herforst', zip: '54662'},
    {city: 'Hergatz', zip: '88145'},
    {city: 'Hergenfeld', zip: '55452'},
    {city: 'Hergenroth', zip: '56457'},
    {city: 'Hergensweiler', zip: '88138'},
    {city: 'Hergersweiler', zip: '76872'},
    {city: 'Hergisdorf', zip: '06313'},
    {city: 'Heringen', zip: '36266'},
    {city: 'Heringen/Helme', zip: '99765'},
    {city: 'Heringsdorf', zip: '23777'},
    {city: 'Heringsdorf', zip: '17419'},
    {city: 'Heringsdorf', zip: '17424'},
    {city: 'Heringsdorf', zip: '17429'},
    {city: 'Herl', zip: '54317'},
    {city: 'Herleshausen', zip: '37293'},
    {city: 'Hermaringen', zip: '89537'},
    {city: 'Hermaringen', zip: '89568'},
    {city: 'Hermersberg', zip: '66919'},
    {city: 'Hermeskeil', zip: '54411'},
    {city: 'Hermsdorf', zip: '01945'},
    {city: 'Hermsdorf', zip: '07629'},
    {city: 'Hermsdorf/Erzgebirge', zip: '01776'},
    {city: 'Herne', zip: '44623'},
    {city: 'Herne', zip: '44625'},
    {city: 'Herne', zip: '44627'},
    {city: 'Herne', zip: '44628'},
    {city: 'Herne', zip: '44629'},
    {city: 'Herne', zip: '44649'},
    {city: 'Herne', zip: '44651'},
    {city: 'Herne', zip: '44652'},
    {city: 'Herne', zip: '44653'},
    {city: 'Herold', zip: '56368'},
    {city: 'Heroldsbach', zip: '91336'},
    {city: 'Heroldsberg', zip: '90562'},
    {city: 'Heroldstatt', zip: '72535'},
    {city: 'Herrenberg', zip: '71083'},
    {city: 'Herrenhof', zip: '99887'},
    {city: 'Herren-Sulzbach', zip: '67742'},
    {city: 'Herresbach', zip: '56729'},
    {city: 'Herrieden', zip: '91567'},
    {city: 'Herrischried', zip: '79737'},
    {city: 'Herrngiersdorf', zip: '84097'},
    {city: 'Herrnhut', zip: '02747'},
    {city: 'Herrsching am Ammersee', zip: '82211'},
    {city: 'Herrstein', zip: '55756'},
    {city: 'Hersbruck', zip: '91217'},
    {city: 'Herschbach', zip: '56249'},
    {city: 'Herschbach', zip: '56414'},
    {city: 'Herschberg', zip: '66917'},
    {city: 'Herschberg', zip: '66919'},
    {city: 'Herschberg', zip: '66987'},
    {city: 'Herschbroich', zip: '53518'},
    {city: 'Herscheid', zip: '58849'},
    {city: 'Herschweiler-Pettersheim', zip: '66909'},
    {city: 'Hersdorf', zip: '54597'},
    {city: 'Herten', zip: '45699'},
    {city: 'Herten', zip: '45701'},
    {city: 'Herxheim', zip: '76863'},
    {city: 'Herxheim am Berg', zip: '67273'},
    {city: 'Herxheimweyher', zip: '76863'},
    {city: 'Herzberg am Harz', zip: '37412'},
    {city: 'Herzberg am Harz', zip: '37434'},
    {city: 'Herzberg (Elster)', zip: '04916'},
    {city: 'Herzberg (Mark)', zip: '16835'},
    {city: 'Herzebrock-Clarholz', zip: '33442'},
    {city: 'Herzfeld', zip: '54619'},
    {city: 'Herzhorn', zip: '25379'},
    {city: 'Herzlake', zip: '49770'},
    {city: 'Herzogenaurach', zip: '91074'},
    {city: 'Herzogenrath', zip: '52134'},
    {city: 'Hesel', zip: '26835'},
    {city: 'Hespe', zip: '31693'},
    {city: 'Heßdorf', zip: '91093'},
    {city: 'Heßheim', zip: '67258'},
    {city: 'Hessigheim', zip: '74394'},
    {city: 'Hessisch Lichtenau', zip: '37235'},
    {city: 'Hessisch Oldendorf', zip: '31840'},
    {city: 'Hesweiler', zip: '56865'},
    {city: 'Hetlingen', zip: '25491'},
    {city: 'Hetschburg', zip: '99438'},
    {city: 'Hettenhausen', zip: '66919'},
    {city: 'Hettenleidelheim', zip: '67310'},
    {city: 'Hettenrodt', zip: '55758'},
    {city: 'Hettenshausen', zip: '85276'},
    {city: 'Hettenshausen', zip: '85304'},
    {city: 'Hettenshausen', zip: '85307'},
    {city: 'Hettingen', zip: '72513'},
    {city: 'Hettstadt', zip: '97265'},
    {city: 'Hettstedt', zip: '06333'},
    {city: 'Hetzerath', zip: '54523'},
    {city: 'Hetzles', zip: '91077'},
    {city: 'Heubach', zip: '73540'},
    {city: 'Heuchelheim', zip: '35452'},
    {city: 'Heuchelheim bei Frankenthal', zip: '67259'},
    {city: 'Heuchelheim-Klingen', zip: '76831'},
    {city: 'Heuchlingen', zip: '73572'},
    {city: 'Heuerßen', zip: '31700'},
    {city: 'Heukewalde', zip: '04626'},
    {city: 'Heupelzen', zip: '57612'},
    {city: 'Heusenstamm', zip: '63150'},
    {city: 'Heustreu', zip: '97618'},
    {city: 'Heusweiler', zip: '66265'},
    {city: 'Heuthen', zip: '37308'},
    {city: 'Heuweiler', zip: '79194'},
    {city: 'Heuzert', zip: '57627'},
    {city: 'Heyen', zip: '37619'},
    {city: 'Heyersdorf', zip: '04626'},
    {city: 'Hiddenhausen', zip: '32120'},
    {city: 'Hilbersdorf', zip: '07580'},
    {city: 'Hilchenbach', zip: '57271'},
    {city: 'Hildburghausen', zip: '98646'},
    {city: 'Hilden', zip: '40721'},
    {city: 'Hilden', zip: '40723'},
    {city: 'Hilden', zip: '40724'},
    {city: 'Hilders', zip: '36115'},
    {city: 'Hildesheim', zip: '31134'},
    {city: 'Hildesheim', zip: '31135'},
    {city: 'Hildesheim', zip: '31137'},
    {city: 'Hildesheim', zip: '31139'},
    {city: 'Hildesheim', zip: '31141'},
    {city: 'Hildrizhausen', zip: '71157'},
    {city: 'Hilgenroth', zip: '57612'},
    {city: 'Hilgermissen', zip: '27318'},
    {city: 'Hilgert', zip: '56206'},
    {city: 'Hilgertshausen-Tandern', zip: '86567'},
    {city: 'Hilkenbrook', zip: '26897'},
    {city: 'Hille', zip: '32479'},
    {city: 'Hillerse', zip: '38543'},
    {city: 'Hillesheim', zip: '54576'},
    {city: 'Hillesheim', zip: '67586'},
    {city: 'Hillgroven', zip: '25764'},
    {city: 'Hillscheid', zip: '56204'},
    {city: 'Hilpoltstein', zip: '91161'},
    {city: 'Hilscheid', zip: '54426'},
    {city: 'Hilst', zip: '66957'},
    {city: 'Hiltenfingen', zip: '86856'},
    {city: 'Hilter', zip: '49176'},
    {city: 'Hiltpoltstein', zip: '91355'},
    {city: 'Hilzingen', zip: '78247'},
    {city: 'Himbergen', zip: '29584'},
    {city: 'Himmelkron', zip: '95502'},
    {city: 'Himmelpforten', zip: '21709'},
    {city: 'Himmelstadt', zip: '97267'},
    {city: 'Himmighofen', zip: '56357'},
    {city: 'Hingstheide', zip: '25563'},
    {city: 'Hinrichshagen', zip: '17498'},
    {city: 'Hinte', zip: '26759'},
    {city: 'Hinterschmiding', zip: '94146'},
    {city: 'Hintersee', zip: '17375'},
    {city: 'Hintertiefenbach', zip: '55743'},
    {city: 'Hinterweidenthal', zip: '66999'},
    {city: 'Hinterweiler', zip: '54570'},
    {city: 'Hinterzarten', zip: '79856'},
    {city: 'Hinzenburg', zip: '54316'},
    {city: 'Hinzert-Pölert', zip: '54421'},
    {city: 'Hinzweiler', zip: '67756'},
    {city: 'Hipstedt', zip: '27432'},
    {city: 'Hirrlingen', zip: '72145'},
    {city: 'Hirschaid', zip: '96114'},
    {city: 'Hirschau', zip: '92242'},
    {city: 'Hirschbach', zip: '92275'},
    {city: 'Hirschberg', zip: '65558'},
    {city: 'Hirschberg', zip: '07927'},
    {city: 'Hirschberg an der Bergstraße', zip: '69493'},
    {city: 'Hirschfeld', zip: '08144'},
    {city: 'Hirschfeld', zip: '04932'},
    {city: 'Hirschfeld', zip: '07554'},
    {city: 'Hirschfeld (Hunsrück)', zip: '55483'},
    {city: 'Hirschhorn', zip: '64757'},
    {city: 'Hirschhorn', zip: '69412'},
    {city: 'Hirschhorn', zip: '69434'},
    {city: 'Hirschhorn/Pfalz', zip: '67732'},
    {city: 'Hirschstein', zip: '01594'},
    {city: 'Hirschthal', zip: '66996'},
    {city: 'Hirten', zip: '56729'},
    {city: 'Hirzenhain', zip: '63697'},
    {city: 'Hirz-Maulsbach', zip: '57635'},
    {city: 'Hittbergen', zip: '21522'},
    {city: 'Hitzacker', zip: '29456'},
    {city: 'Hitzhofen', zip: '85122'},
    {city: 'Hitzhusen', zip: '24576'},
    {city: 'Höchberg', zip: '97204'},
    {city: 'Hochborn', zip: '55234'},
    {city: 'Hochdonn', zip: '25712'},
    {city: 'Hochdorf', zip: '73269'},
    {city: 'Hochdorf', zip: '88436'},
    {city: 'Hochdorf', zip: '88454'},
    {city: 'Hochdorf-Assenheim', zip: '67126'},
    {city: 'Höchenschwand', zip: '79862'},
    {city: 'Hochheim', zip: '65239'},
    {city: 'Höchheim', zip: '97633'},
    {city: 'Hochkirch', zip: '02627'},
    {city: 'Hochscheid', zip: '54472'},
    {city: 'Hochspeyer', zip: '67691'},
    {city: 'Höchstadt a.d.Aisch', zip: '91315'},
    {city: 'Höchstädt a.d.Donau', zip: '89420'},
    {city: 'Hochstadt a.Main', zip: '96272'},
    {city: 'Höchstädt im Fichtelgebirge', zip: '95186'},
    {city: 'Hochstadt (Pfalz)', zip: '76879'},
    {city: 'Hochstätten', zip: '55585'},
    {city: 'Höchstberg', zip: '56767'},
    {city: 'Höchstenbach', zip: '57629'},
    {city: 'Hochstetten-Dhaun', zip: '55606'},
    {city: 'Höchst im Odenwald', zip: '64739'},
    {city: 'Hockenheim', zip: '68766'},
    {city: 'Hockweiler', zip: '54316'},
    {city: 'Hodenhagen', zip: '29693'},
    {city: 'Hodorf', zip: '25569'},
    {city: 'Hof', zip: '95028'},
    {city: 'Hof', zip: '95030'},
    {city: 'Hof', zip: '95032'},
    {city: 'Hof', zip: '56472'},
    {city: 'Hofbieber', zip: '36145'},
    {city: 'Höfen an der Enz', zip: '75339'},
    {city: 'Hoffeld', zip: '24582'},
    {city: 'Hoffeld', zip: '53534'},
    {city: 'Hofgeismar', zip: '34369'},
    {city: 'Hofheim am Taunus', zip: '65719'},
    {city: 'Hofheim in Unterfranken', zip: '97461'},
    {city: 'Hofkirchen', zip: '94544'},
    {city: 'Hofstetten', zip: '77716'},
    {city: 'Hofstetten', zip: '86928'},
    {city: 'Högel', zip: '25858'},
    {city: 'Högersdorf', zip: '23795'},
    {city: 'Högsdorf', zip: '24327'},
    {city: 'Höhbeck', zip: '29478'},
    {city: 'Hohberg', zip: '77749'},
    {city: 'Hohe Börde', zip: '39167'},
    {city: 'Hohe Börde', zip: '39326'},
    {city: 'Hohe Börde', zip: '39343'},
    {city: 'Höheinöd', zip: '66989'},
    {city: 'Höheischweiler', zip: '66989'},
    {city: 'Hohenahr', zip: '35644'},
    {city: 'Hohenaltheim', zip: '86745'},
    {city: 'Hohenaspe', zip: '25582'},
    {city: 'Hohenau', zip: '94545'},
    {city: 'Hohenberg an der Eger', zip: '95691'},
    {city: 'Hohenberg-Krusemark', zip: '39596'},
    {city: 'Hohenbocka', zip: '01945'},
    {city: 'Hohenbollentin', zip: '17111'},
    {city: 'Hohenbrunn', zip: '85521'},
    {city: 'Hohenbrunn', zip: '85662'},
    {city: 'Hohenbucko', zip: '04936'},
    {city: 'Hohenburg', zip: '92277'},
    {city: 'Hohen Demzin', zip: '17166'},
    {city: 'Hohendubrau', zip: '02906'},
    {city: 'Hohenfelde', zip: '24257'},
    {city: 'Hohenfelde', zip: '18209'},
    {city: 'Hohenfelde', zip: '18211'},
    {city: 'Hohenfelde', zip: '25358'},
    {city: 'Hohenfelde', zip: '22946'},
    {city: 'Hohenfelden', zip: '99448'},
    {city: 'Hohenfels', zip: '92366'},
    {city: 'Hohenfels', zip: '78355'},
    {city: 'Hohenfels-Essingen', zip: '54570'},
    {city: 'Hohenfinow', zip: '16248'},
    {city: 'Hohenfurch', zip: '86978'},
    {city: 'Hohengandern', zip: '37318'},
    {city: 'Hohenhameln', zip: '31249'},
    {city: 'Hohenhorn', zip: '21526'},
    {city: 'Hohenkammer', zip: '85411'},
    {city: 'Hohenkirchen', zip: '23968'},
    {city: 'Höhenkirchen-Siegertsbrunn', zip: '85635'},
    {city: 'Höhenland', zip: '16259'},
    {city: 'Hohenleimbach', zip: '56746'},
    {city: 'Hohenleipisch', zip: '04934'},
    {city: 'Hohenleuben', zip: '07958'},
    {city: 'Hohenlinden', zip: '85664'},
    {city: 'Hohenlockstedt', zip: '25551'},
    {city: 'Hohenmocker', zip: '17111'},
    {city: 'Hohenmölsen', zip: '06679'},
    {city: 'Hohen Neuendorf', zip: '16540'},
    {city: 'Hohen Neuendorf', zip: '16556'},
    {city: 'Hohen Neuendorf', zip: '16562'},
    {city: 'Hohenöllen', zip: '67744'},
    {city: 'Hohenpeißenberg', zip: '82383'},
    {city: 'Hohenpolding', zip: '84432'},
    {city: 'Hohen Pritz', zip: '19406'},
    {city: 'Hohenroda', zip: '36284'},
    {city: 'Hohenroth', zip: '97618'},
    {city: 'Hohenselchow-Groß Pinnow', zip: '16306'},
    {city: 'Hohen Sprenz', zip: '18299'},
    {city: 'Hohenstadt', zip: '73345'},
    {city: 'Hohenstein', zip: '65329'},
    {city: 'Hohenstein', zip: '99755'},
    {city: 'Hohenstein', zip: '72531'},
    {city: 'Hohenstein-Ernstthal', zip: '09337'},
    {city: 'Hohen-Sülzen', zip: '67591'},
    {city: 'Hohentengen', zip: '88367'},
    {city: 'Hohentengen am Hochrhein', zip: '79801'},
    {city: 'Hohenthann', zip: '84098'},
    {city: 'Hohen Viecheln', zip: '23996'},
    {city: 'Hohen Wangelin', zip: '17194'},
    {city: 'Hohenwart', zip: '86558'},
    {city: 'Hohenwarte', zip: '07338'},
    {city: 'Hohenwarth', zip: '93480'},
    {city: 'Hohenwestedt', zip: '24594'},
    {city: 'Hohenzieritz', zip: '17237'},
    {city: 'Hohes Kreuz', zip: '37308'},
    {city: 'Höhfröschen', zip: '66989'},
    {city: 'Hohn', zip: '24806'},
    {city: 'Höhn', zip: '56462'},
    {city: 'Hohndorf', zip: '09394'},
    {city: 'Höhndorf', zip: '24217'},
    {city: 'Hohne', zip: '29362'},
    {city: 'Hohnhorst', zip: '31559'},
    {city: 'Hohnstein', zip: '01848'},
    {city: 'Hohnstorf (Elbe)', zip: '21522'},
    {city: 'Höhr-Grenzhausen', zip: '56203'},
    {city: 'Hohwacht', zip: '24321'},
    {city: 'Hoisdorf', zip: '22955'},
    {city: 'Hoisdorf', zip: '22961'},
    {city: 'Holdorf', zip: '49451'},
    {city: 'Holdorf', zip: '19205'},
    {city: 'Holdorf', zip: '19217'},
    {city: 'Holenberg', zip: '37642'},
    {city: 'Holldorf', zip: '17094'},
    {city: 'Holle', zip: '31188'},
    {city: 'Hollenbach', zip: '86568'},
    {city: 'Hollenbek', zip: '23883'},
    {city: 'Hollenstedt', zip: '21279'},
    {city: 'Holler', zip: '56412'},
    {city: 'Hollern-Twielenfleth', zip: '21723'},
    {city: 'Hollfeld', zip: '96142'},
    {city: 'Hollingstedt', zip: '25788'},
    {city: 'Hollingstedt', zip: '24876'},
    {city: 'Hollnich', zip: '56288'},
    {city: 'Hollnseth', zip: '21769'},
    {city: 'Hollstadt', zip: '97618'},
    {city: 'Holm', zip: '25488'},
    {city: 'Holm', zip: '25923'},
    {city: 'Holste', zip: '27729'},
    {city: 'Holstenniendorf', zip: '25584'},
    {city: 'Holsthum', zip: '54668'},
    {city: 'Holt', zip: '24994'},
    {city: 'Holtgast', zip: '26427'},
    {city: 'Holthusen', zip: '19075'},
    {city: 'Holtland', zip: '26835'},
    {city: 'Holtsee', zip: '24363'},
    {city: 'Holzappel', zip: '56379'},
    {city: 'Holzbach', zip: '55469'},
    {city: 'Holzbunge', zip: '24361'},
    {city: 'Holzdorf', zip: '24364'},
    {city: 'Holzen', zip: '37632'},
    {city: 'Holzerath', zip: '54316'},
    {city: 'Holzgerlingen', zip: '71088'},
    {city: 'Holzgünz', zip: '87752'},
    {city: 'Holzhausen an der Haide', zip: '56357'},
    {city: 'Holzheim', zip: '65558'},
    {city: 'Holzheim', zip: '89291'},
    {city: 'Holzheim', zip: '89438'},
    {city: 'Holzheim', zip: '86684'},
    {city: 'Holzheim a.Forst', zip: '93183'},
    {city: 'Holzkirch', zip: '89183'},
    {city: 'Holzkirchen', zip: '97292'},
    {city: 'Holzkirchen', zip: '83607'},
    {city: 'Holzmaden', zip: '73271'},
    {city: 'Holzminden', zip: '37603'},
    {city: 'Holzsußra', zip: '99713'},
    {city: 'Holzwickede', zip: '59439'},
    {city: 'Homberg', zip: '34576'},
    {city: 'Homberg', zip: '56479'},
    {city: 'Homberg', zip: '67742'},
    {city: 'Homberg', zip: '67744'},
    {city: 'Hömberg', zip: '56379'},
    {city: 'Homberg (Ohm)', zip: '35315'},
    {city: 'Homburg', zip: '66424'},
    {city: 'Hommerdingen', zip: '54675'},
    {city: 'Honerath', zip: '53518'},
    {city: 'Honigsee', zip: '24211'},
    {city: 'Hönningen', zip: '53506'},
    {city: 'Hontheim', zip: '54538'},
    {city: 'Hoogstede', zip: '49846'},
    {city: 'Hoort', zip: '19230'},
    {city: 'Hopferau', zip: '87659'},
    {city: 'Höpfingen', zip: '74746'},
    {city: 'Hoppegarten', zip: '15366'},
    {city: 'Hoppenrade', zip: '18292'},
    {city: 'Hoppstädten', zip: '67744'},
    {city: 'Hoppstädten-Weiersbach', zip: '55768'},
    {city: 'Hopsten', zip: '48496'},
    {city: 'Horath', zip: '54497'},
    {city: 'Horbach', zip: '56412'},
    {city: 'Horbach', zip: '66851'},
    {city: 'Horbach', zip: '55606'},
    {city: 'Horb am Neckar', zip: '72160'},
    {city: 'Horben', zip: '79289'},
    {city: 'Horbruch', zip: '55483'},
    {city: 'Hörden am Harz', zip: '37412'},
    {city: 'Hördt', zip: '76771'},
    {city: 'Horgau', zip: '86497'},
    {city: 'Horgenzell', zip: '88263'},
    {city: 'Hörgertshausen', zip: '85413'},
    {city: 'Horhausen', zip: '56379'},
    {city: 'Horhausen (Westerwald)', zip: '56593'},
    {city: 'Höringen', zip: '67724'},
    {city: 'Horka', zip: '02923'},
    {city: 'Horn', zip: '55469'},
    {city: 'Hornbach', zip: '66500'},
    {city: 'Horn-Bad Meinberg', zip: '32805'},
    {city: 'Hornbek', zip: '21514'},
    {city: 'Hornberg', zip: '78132'},
    {city: 'Horneburg', zip: '21640'},
    {city: 'Hornsömmern', zip: '99955'},
    {city: 'Hornstorf', zip: '23970'},
    {city: 'Hornstorf', zip: '23974'},
    {city: 'Hörnum (Sylt)', zip: '25997'},
    {city: 'Horperath', zip: '56766'},
    {city: 'Horrweiler', zip: '55457'},
    {city: 'Horschbach', zip: '66887'},
    {city: 'Hörscheid', zip: '54552'},
    {city: 'Hörschhausen', zip: '54552'},
    {city: 'Hörsel', zip: '99869'},
    {city: 'Hörsel', zip: '99880'},
    {city: 'Hörselberg-Hainich', zip: '99820'},
    {city: 'Horst', zip: '25358'},
    {city: 'Horst', zip: '23883'},
    {city: 'Horstedt', zip: '27367'},
    {city: 'Horstedt', zip: '25860'},
    {city: 'Hörstel', zip: '48477'},
    {city: 'Hörsten', zip: '24797'},
    {city: 'Horstmar', zip: '48612'},
    {city: 'Hörup', zip: '24980'},
    {city: 'Hösbach', zip: '63768'},
    {city: 'Hosenfeld', zip: '36154'},
    {city: 'Höslwang', zip: '83129'},
    {city: 'Hoßkirch', zip: '88374'},
    {city: 'Hosten', zip: '54664'},
    {city: 'Hötensleben', zip: '39393'},
    {city: 'Hottenbach', zip: '55758'},
    {city: 'Höttingen', zip: '91798'},
    {city: 'Hövede', zip: '25782'},
    {city: 'Hövelhof', zip: '33161'},
    {city: 'Hövels', zip: '57537'},
    {city: 'Höxter', zip: '37671'},
    {city: 'Hoya', zip: '27318'},
    {city: 'Hoyerhagen', zip: '27318'},
    {city: 'Hoyerswerda', zip: '02977'},
    {city: 'Hübingen', zip: '56412'},
    {city: 'Hüblingen', zip: '56479'},
    {city: 'Hückelhoven', zip: '41836'},
    {city: 'Hückeswagen', zip: '42499'},
    {city: 'Hude', zip: '25876'},
    {city: 'Hüde', zip: '49448'},
    {city: 'Hude (Oldenburg)', zip: '27798'},
    {city: 'Hüffelsheim', zip: '55595'},
    {city: 'Hüffenhardt', zip: '74928'},
    {city: 'Hüffler', zip: '66909'},
    {city: 'Hüfingen', zip: '78183'},
    {city: 'Hügelsheim', zip: '76549'},
    {city: 'Huglfing', zip: '82386'},
    {city: 'Hugoldsdorf', zip: '18465'},
    {city: 'Huisheim', zip: '86685'},
    {city: 'Huje', zip: '25588'},
    {city: 'Hülben', zip: '72584'},
    {city: 'Hüllhorst', zip: '32609'},
    {city: 'Hülseburg', zip: '19230'},
    {city: 'Hülsede', zip: '31867'},
    {city: 'Hümmel', zip: '53520'},
    {city: 'Hummelfeld', zip: '24357'},
    {city: 'Hummelshain', zip: '07768'},
    {city: 'Hummeltal', zip: '95503'},
    {city: 'Hümmerich', zip: '53547'},
    {city: 'Humptrup', zip: '25923'},
    {city: 'Hunderdorf', zip: '94336'},
    {city: 'Hundhaupten', zip: '07557'},
    {city: 'Hunding', zip: '94551'},
    {city: 'Hundsangen', zip: '56414'},
    {city: 'Hundsbach', zip: '55621'},
    {city: 'Hundsdorf', zip: '56235'},
    {city: 'Hünfeld', zip: '36088'},
    {city: 'Hünfelden', zip: '65597'},
    {city: 'Hungen', zip: '35410'},
    {city: 'Hungenroth', zip: '56281'},
    {city: 'Hünstetten', zip: '65510'},
    {city: 'Hünxe', zip: '46569'},
    {city: 'Hunzel', zip: '56355'},
    {city: 'Hupperath', zip: '54518'},
    {city: 'Hurlach', zip: '86857'},
    {city: 'Hürtgenwald', zip: '52393'},
    {city: 'Hürth', zip: '50354'},
    {city: 'Hürup', zip: '24975'},
    {city: 'Husby', zip: '24975'},
    {city: 'Hüsby', zip: '24850'},
    {city: 'Husum', zip: '31632'},
    {city: 'Husum', zip: '25813'},
    {city: 'Hütschenhausen', zip: '66882'},
    {city: 'Hüttblek', zip: '24641'},
    {city: 'Hütten', zip: '24358'},
    {city: 'Hütten', zip: '54673'},
    {city: 'Hüttenberg', zip: '35625'},
    {city: 'Hütterscheid', zip: '54636'},
    {city: 'Hutthurm', zip: '94116'},
    {city: 'Hüttingen an der Kyll', zip: '54636'},
    {city: 'Hüttingen bei Lahr', zip: '54675'},
    {city: 'Hüttisheim', zip: '89185'},
    {city: 'Hüttlingen', zip: '73434'},
    {city: 'Hüttlingen', zip: '73453'},
    {city: 'Hüttlingen', zip: '73460'},
    {city: 'Hüven', zip: '49751'},
    {city: 'Huy', zip: '38836'},
    {city: 'Huy', zip: '38838'},
    {city: 'Ibach', zip: '79837'},
    {city: 'Ibbenbüren', zip: '49477'},
    {city: 'Ibbenbüren', zip: '49479'},
    {city: 'Ichenhausen', zip: '89335'},
    {city: 'Icking', zip: '82057'},
    {city: 'Idar-Oberstein', zip: '55743'},
    {city: 'Idelberg', zip: '57612'},
    {city: 'Iden', zip: '39606'},
    {city: 'Idenheim', zip: '54636'},
    {city: 'Idesheim', zip: '54636'},
    {city: 'Idstedt', zip: '24879'},
    {city: 'Idstein', zip: '65510'},
    {city: 'Iffeldorf', zip: '82393'},
    {city: 'Iffezheim', zip: '76473'},
    {city: 'Igel', zip: '54298'},
    {city: 'Igensdorf', zip: '91338'},
    {city: 'Igersheim', zip: '97999'},
    {city: 'Iggensbach', zip: '94547'},
    {city: 'Iggingen', zip: '73574'},
    {city: 'Igling', zip: '86859'},
    {city: 'Ihlienworth', zip: '21775'},
    {city: 'Ihlow', zip: '15936'},
    {city: 'Ihlow', zip: '26632'},
    {city: 'Ihringen', zip: '79241'},
    {city: 'Ihrlerstein', zip: '93346'},
    {city: 'Ilberstedt', zip: '06408'},
    {city: 'Ilbesheim', zip: '67294'},
    {city: 'Ilbesheim bei Landau in der Pfalz', zip: '76831'},
    {city: 'Illerich', zip: '56814'},
    {city: 'Illerkirchberg', zip: '89171'},
    {city: 'Illerrieden', zip: '89186'},
    {city: 'Illertissen', zip: '89257'},
    {city: 'Illesheim', zip: '91471'},
    {city: 'Illingen', zip: '75428'},
    {city: 'Illingen', zip: '66557'},
    {city: 'Illmensee', zip: '88636'},
    {city: 'Illschwang', zip: '92278'},
    {city: 'Ilmenau', zip: '98693'},
    {city: 'Ilmenau', zip: '98694'},
    {city: 'Ilmmünster', zip: '85304'},
    {city: 'Ilmtal-Weinstraße', zip: '99510'},
    {city: 'Ilsede', zip: '31241'},
    {city: 'Ilsede', zip: '31246'},
    {city: 'Ilsenburg', zip: '38871'},
    {city: 'Ilsfeld', zip: '74360'},
    {city: 'Ilshofen', zip: '74532'},
    {city: 'Ilvesheim', zip: '68549'},
    {city: 'Immendingen', zip: '78194'},
    {city: 'Immenhausen', zip: '34376'},
    {city: 'Immenreuth', zip: '95505'},
    {city: 'Immenstaad am Bodensee', zip: '88090'},
    {city: 'Immenstadt im Allgäu', zip: '87509'},
    {city: 'Immenstedt', zip: '25767'},
    {city: 'Immenstedt', zip: '25885'},
    {city: 'Immerath', zip: '54552'},
    {city: 'Immert', zip: '54426'},
    {city: 'Immesheim', zip: '67308'},
    {city: 'Impflingen', zip: '76831'},
    {city: 'Imsbach', zip: '67725'},
    {city: 'Imsbach', zip: '67808'},
    {city: 'Imsbach', zip: '67817'},
    {city: 'Imsweiler', zip: '67806'},
    {city: 'Imsweiler', zip: '67808'},
    {city: 'Inchenhofen', zip: '86570'},
    {city: 'Inden', zip: '52459'},
    {city: 'Ingelbach', zip: '57610'},
    {city: 'Ingelfingen', zip: '74653'},
    {city: 'Ingelheim am Rhein', zip: '55218'},
    {city: 'Ingelheim am Rhein', zip: '55262'},
    {city: 'Ingelheim am Rhein', zip: '55263'},
    {city: 'Ingendorf', zip: '54636'},
    {city: 'Ingenried', zip: '86980'},
    {city: 'Ingersheim', zip: '74379'},
    {city: 'Ingersleben', zip: '39343'},
    {city: 'Ingoldingen', zip: '88456'},
    {city: 'Ingolstadt', zip: '85049'},
    {city: 'Ingolstadt', zip: '85051'},
    {city: 'Ingolstadt', zip: '85053'},
    {city: 'Ingolstadt', zip: '85055'},
    {city: 'Ingolstadt', zip: '85057'},
    {city: 'Innernzell', zip: '94548'},
    {city: 'Inning am Ammersee', zip: '82266'},
    {city: 'Inning am Holz', zip: '84416'},
    {city: 'Insel Hiddensee', zip: '18565'},
    {city: 'Insel Poel', zip: '23999'},
    {city: 'Insheim', zip: '76865'},
    {city: 'Insingen', zip: '91610'},
    {city: 'Insul', zip: '53520'},
    {city: 'Inzell', zip: '83334'},
    {city: 'Inzigkofen', zip: '72514'},
    {city: 'Inzlingen', zip: '79594'},
    {city: 'Iphofen', zip: '97346'},
    {city: 'Ippenschied', zip: '55566'},
    {city: 'Ippesheim', zip: '97258'},
    {city: 'Ipsheim', zip: '91472'},
    {city: 'Irchenrieth', zip: '92699'},
    {city: 'Irlbach', zip: '94342'},
    {city: 'Irmenach', zip: '56843'},
    {city: 'Irmtraut', zip: '56479'},
    {city: 'Irndorf', zip: '78597'},
    {city: 'Irrel', zip: '54666'},
    {city: 'Irrhausen', zip: '54689'},
    {city: 'Irsch', zip: '54451'},
    {city: 'Irschenberg', zip: '83737'},
    {city: 'Irsee', zip: '87660'},
    {city: 'Isen', zip: '84424'},
    {city: 'Isenburg', zip: '56271'},
    {city: 'Isenbüttel', zip: '38550'},
    {city: 'Iserlohn', zip: '58636'},
    {city: 'Iserlohn', zip: '58638'},
    {city: 'Iserlohn', zip: '58640'},
    {city: 'Iserlohn', zip: '58642'},
    {city: 'Iserlohn', zip: '58644'},
    {city: 'Isernhagen', zip: '30916'},
    {city: 'Isert', zip: '57612'},
    {city: 'Ismaning', zip: '85737'},
    {city: 'Isny im Allgäu', zip: '88316'},
    {city: 'Ispringen', zip: '75228'},
    {city: 'Isselbach', zip: '65558'},
    {city: 'Isselburg', zip: '46419'},
    {city: 'Issigau', zip: '95188'},
    {city: 'Issum', zip: '47661'},
    {city: 'Isterberg', zip: '48465'},
    {city: 'Itterbeck', zip: '49847'},
    {city: 'Ittlingen', zip: '74930'},
    {city: 'Itzehoe', zip: '25524'},
    {city: 'Itzgrund', zip: '96274'},
    {city: 'Itzstedt', zip: '23845'},
    {city: 'Iven', zip: '17391'},
    {city: 'Ivenack', zip: '17153'},
    {city: 'Jabel', zip: '17194'},
    {city: 'Jachenau', zip: '83676'},
    {city: 'Jacobsdorf', zip: '15236'},
    {city: 'Jade', zip: '26349'},
    {city: 'Jagel', zip: '24848'},
    {city: 'Jagel', zip: '24878'},
    {city: 'Jagsthausen', zip: '74249'},
    {city: 'Jagstzell', zip: '73489'},
    {city: 'Jahnsdorf/Erzgebirge', zip: '09387'},
    {city: 'Jahrsdorf', zip: '24594'},
    {city: 'Jakobsdorf', zip: '18442'},
    {city: 'Jakobsweiler', zip: '67814'},
    {city: 'Jameln', zip: '29479'},
    {city: 'Jamlitz', zip: '15868'},
    {city: 'Jämlitz-Klein Düben', zip: '03130'},
    {city: 'Jandelsbrunn', zip: '94118'},
    {city: 'Janneby', zip: '24992'},
    {city: 'Jänschwalde', zip: '03172'},
    {city: 'Jänschwalde', zip: '03197'},
    {city: 'Jardelund', zip: '24994'},
    {city: 'Jarmen', zip: '17126'},
    {city: 'Jatznick', zip: '17309'},
    {city: 'Jeckenbach', zip: '55592'},
    {city: 'Jelmstorf', zip: '29585'},
    {city: 'Jembke', zip: '38477'},
    {city: 'Jemgum', zip: '26844'},
    {city: 'Jena', zip: '07743'},
    {city: 'Jena', zip: '07745'},
    {city: 'Jena', zip: '07747'},
    {city: 'Jena', zip: '07749'},
    {city: 'Jena', zip: '07751'},
    {city: 'Jenalöbnitz', zip: '07751'},
    {city: 'Jengen', zip: '86860'},
    {city: 'Jerichow', zip: '39307'},
    {city: 'Jerichow', zip: '39319'},
    {city: 'Jerrishoe', zip: '24963'},
    {city: 'Jersbek', zip: '22941'},
    {city: 'Jerxheim', zip: '38381'},
    {city: 'Jesberg', zip: '34632'},
    {city: 'Jesendorf', zip: '19417'},
    {city: 'Jesenwang', zip: '82287'},
    {city: 'Jesewitz', zip: '04838'},
    {city: 'Jessen (Elster)', zip: '06917'},
    {city: 'Jesteburg', zip: '21266'},
    {city: 'Jestetten', zip: '79798'},
    {city: 'Jettenbach', zip: '84555'},
    {city: 'Jettenbach', zip: '66887'},
    {city: 'Jettingen', zip: '71131'},
    {city: 'Jettingen-Scheppach', zip: '89343'},
    {city: 'Jetzendorf', zip: '85305'},
    {city: 'Jevenstedt', zip: '24808'},
    {city: 'Jever', zip: '26441'},
    {city: 'Joachimsthal', zip: '16247'},
    {city: 'Jockgrim', zip: '76751'},
    {city: 'Johannesberg', zip: '63867'},
    {city: 'Johanngeorgenstadt', zip: '08349'},
    {city: 'Johanniskirchen', zip: '84381'},
    {city: 'Jöhstadt', zip: '09477'},
    {city: 'Joldelund', zip: '25862'},
    {city: 'Jonaswalde', zip: '04626'},
    {city: 'Jonsdorf', zip: '02796'},
    {city: 'Jördenstorf', zip: '17168'},
    {city: 'Jork', zip: '21635'},
    {city: 'Jörl', zip: '24992'},
    {city: 'Jossgrund', zip: '63637'},
    {city: 'Jübar', zip: '38489'},
    {city: 'Jübek', zip: '24855'},
    {city: 'Jüchen', zip: '41363'},
    {city: 'Jucken', zip: '54689'},
    {city: 'Jugenheim', zip: '55270'},
    {city: 'Jühnde', zip: '37127'},
    {city: 'Juist', zip: '26571'},
    {city: 'Julbach', zip: '84387'},
    {city: 'Jülich', zip: '52428'},
    {city: 'Juliusburg', zip: '21483'},
    {city: 'Jungingen', zip: '72417'},
    {city: 'Jünkerath', zip: '54584'},
    {city: 'Jürgenshagen', zip: '18246'},
    {city: 'Jürgenstorf', zip: '17153'},
    {city: 'Jüterbog', zip: '14913'},
    {city: 'Kaaks', zip: '25582'},
    {city: 'Kaarst', zip: '41564'},
    {city: 'Kabelhorst', zip: '23738'},
    {city: 'Kabelsketal', zip: '06184'},
    {city: 'Käbschütztal', zip: '01665'},
    {city: 'Kaden', zip: '56459'},
    {city: 'Kadenbach', zip: '56337'},
    {city: 'Kahla', zip: '07768'},
    {city: 'Kahl am Main', zip: '63796'},
    {city: 'Kaifenheim', zip: '56761'},
    {city: 'Kail', zip: '56829'},
    {city: 'Kaisborstel', zip: '25560'},
    {city: 'Kaiserpfalz', zip: '06642'},
    {city: 'Kaisersbach', zip: '73553'},
    {city: 'Kaisersbach', zip: '73642'},
    {city: 'Kaisersbach', zip: '73667'},
    {city: 'Kaisersesch', zip: '56759'},
    {city: 'Kaiserslautern', zip: '67655'},
    {city: 'Kaiserslautern', zip: '67657'},
    {city: 'Kaiserslautern', zip: '67659'},
    {city: 'Kaiserslautern', zip: '67661'},
    {city: 'Kaiserslautern', zip: '67663'},
    {city: 'Kaiser-Wilhelm-Koog', zip: '25709'},
    {city: 'Kaisheim', zip: '86687'},
    {city: 'Kakenstorf', zip: '21255'},
    {city: 'Kalbach', zip: '36148'},
    {city: 'Kalbe', zip: '27419'},
    {city: 'Kalbe (Milde)', zip: '39624'},
    {city: 'Kalbsrieth', zip: '06556'},
    {city: 'Kalchreuth', zip: '90562'},
    {city: 'Kalefeld', zip: '37589'},
    {city: 'Kalenborn', zip: '56759'},
    {city: 'Kalenborn', zip: '53505'},
    {city: 'Kalenborn-Scheuern', zip: '54570'},
    {city: 'Kalkar', zip: '47546'},
    {city: 'Kalkhorst', zip: '23942'},
    {city: 'Kalkhorst', zip: '23948'},
    {city: 'Kalkofen', zip: '67822'},
    {city: 'Kall', zip: '53925'},
    {city: 'Kalletal', zip: '32689'},
    {city: 'Kallmünz', zip: '93183'},
    {city: 'Kallstadt', zip: '67169'},
    {city: 'Kalt', zip: '56294'},
    {city: 'Kaltenborn', zip: '53520'},
    {city: 'Kaltenengers', zip: '56220'},
    {city: 'Kaltenholzhausen', zip: '65558'},
    {city: 'Kaltenkirchen', zip: '24568'},
    {city: 'Kaltennordheim', zip: '36452'},
    {city: 'Kaltental', zip: '87662'},
    {city: 'Kalübbe', zip: '24326'},
    {city: 'Kamen', zip: '59174'},
    {city: 'Kamenz', zip: '01917'},
    {city: 'Kamern', zip: '39524'},
    {city: 'Kammeltal', zip: '89358'},
    {city: 'Kammerforst', zip: '56206'},
    {city: 'Kammerforst', zip: '99986'},
    {city: 'Kammerstein', zip: '91126'},
    {city: 'Kamminke', zip: '17419'},
    {city: 'Kammlach', zip: '87754'},
    {city: 'Kamp-Bornhofen', zip: '56341'},
    {city: 'Kampen (Sylt)', zip: '25999'},
    {city: 'Kämpfelbach', zip: '75236'},
    {city: 'Kamp-Lintfort', zip: '47475'},
    {city: 'Kandel', zip: '76870'},
    {city: 'Kandern', zip: '79400'},
    {city: 'Kankelau', zip: '21514'},
    {city: 'Kanzach', zip: '88422'},
    {city: 'Kanzem', zip: '54441'},
    {city: 'Kapellendorf', zip: '99510'},
    {city: 'Kapellen-Drusweiler', zip: '76889'},
    {city: 'Kaperich', zip: '56767'},
    {city: 'Kappel', zip: '55483'},
    {city: 'Kappel-Grafenhausen', zip: '77966'},
    {city: 'Kappeln', zip: '24376'},
    {city: 'Kappeln', zip: '24404'},
    {city: 'Kappeln', zip: '67744'},
    {city: 'Kappelrodeck', zip: '77876'},
    {city: 'Kapsweyer', zip: '76889'},
    {city: 'Karbach', zip: '97842'},
    {city: 'Karbach', zip: '56281'},
    {city: 'Karben', zip: '61184'},
    {city: 'Karby', zip: '24398'},
    {city: 'Karenz', zip: '19294'},
    {city: 'Kargow', zip: '17192'},
    {city: 'Karl', zip: '54534'},
    {city: 'Karlsbad', zip: '76307'},
    {city: 'Karlsburg', zip: '17495'},
    {city: 'Karlsdorf', zip: '07646'},
    {city: 'Karlsdorf-Neuthard', zip: '76689'},
    {city: 'Karlsfeld', zip: '85757'},
    {city: 'Karlshagen', zip: '17449'},
    {city: 'Karlshausen', zip: '54673'},
    {city: 'Karlshuld', zip: '86668'},
    {city: 'Karlskron', zip: '85123'},
    {city: 'Karlsruhe', zip: '76131'},
    {city: 'Karlsruhe', zip: '76133'},
    {city: 'Karlsruhe', zip: '76135'},
    {city: 'Karlsruhe', zip: '76137'},
    {city: 'Karlsruhe', zip: '76139'},
    {city: 'Karlsruhe', zip: '76149'},
    {city: 'Karlsruhe', zip: '76185'},
    {city: 'Karlsruhe', zip: '76187'},
    {city: 'Karlsruhe', zip: '76189'},
    {city: 'Karlsruhe', zip: '76199'},
    {city: 'Karlsruhe', zip: '76227'},
    {city: 'Karlsruhe', zip: '76228'},
    {city: 'Karlsruhe', zip: '76229'},
    {city: 'Karlstadt', zip: '97753'},
    {city: 'Karlstein am Main', zip: '63791'},
    {city: 'Karlum', zip: '25926'},
    {city: 'Karnin', zip: '18469'},
    {city: 'Karolinenkoog', zip: '25774'},
    {city: 'Karrenzin', zip: '19372'},
    {city: 'Karsbach', zip: '97783'},
    {city: 'Karsdorf', zip: '06638'},
    {city: 'Karstädt', zip: '19357'},
    {city: 'Karstädt', zip: '19294'},
    {city: 'Karwitz', zip: '29481'},
    {city: 'Kasbach-Ohlenberg', zip: '53547'},
    {city: 'Kaschenbach', zip: '54668'},
    {city: 'Kasdorf', zip: '56357'},
    {city: 'Kasel', zip: '54317'},
    {city: 'Kasel-Golzig', zip: '15938'},
    {city: 'Kasendorf', zip: '95359'},
    {city: 'Käshofen', zip: '66894'},
    {city: 'Kasseburg', zip: '22929'},
    {city: 'Kasseedorf', zip: '23717'},
    {city: 'Kassel', zip: '34117'},
    {city: 'Kassel', zip: '34119'},
    {city: 'Kassel', zip: '34121'},
    {city: 'Kassel', zip: '34123'},
    {city: 'Kassel', zip: '34125'},
    {city: 'Kassel', zip: '34127'},
    {city: 'Kassel', zip: '34128'},
    {city: 'Kassel', zip: '34130'},
    {city: 'Kassel', zip: '34131'},
    {city: 'Kassel', zip: '34132'},
    {city: 'Kassel', zip: '34134'},
    {city: 'Kassel', zip: '34233'},
    {city: 'Kassel', zip: '34355'},
    {city: 'Kassow', zip: '18258'},
    {city: 'Kastellaun', zip: '56288'},
    {city: 'Kastel-Staadt', zip: '54441'},
    {city: 'Kastl', zip: '84556'},
    {city: 'Kastl', zip: '95506'},
    {city: 'Kastl', zip: '92280'},
    {city: 'Kastorf', zip: '23847'},
    {city: 'Katharinenheerd', zip: '25836'},
    {city: 'Katlenburg-Lindau', zip: '37191'},
    {city: 'Kattendorf', zip: '24568'},
    {city: 'Katzenbach', zip: '67806'},
    {city: 'Katzenelnbogen', zip: '56368'},
    {city: 'Katzhütte', zip: '98746'},
    {city: 'Katzow', zip: '17509'},
    {city: 'Katzweiler', zip: '67734'},
    {city: 'Katzwinkel', zip: '54552'},
    {city: 'Katzwinkel (Sieg)', zip: '57581'},
    {city: 'Kaub', zip: '56349'},
    {city: 'Kauern', zip: '07554'},
    {city: 'Kaufbeuren', zip: '87600'},
    {city: 'Kaufering', zip: '86916'},
    {city: 'Kaufungen', zip: '34260'},
    {city: 'Kaulsdorf', zip: '07338'},
    {city: 'Kausen', zip: '57520'},
    {city: 'Kayhude', zip: '23863'},
    {city: 'Kefenrod', zip: '63699'},
    {city: 'Kehl', zip: '77694'},
    {city: 'Kehlbach', zip: '56355'},
    {city: 'Kehmstedt', zip: '99752'},
    {city: 'Kehrig', zip: '56729'},
    {city: 'Keidelheim', zip: '55471'},
    {city: 'Keila', zip: '07389'},
    {city: 'Kelberg', zip: '53539'},
    {city: 'Kelbra (Kyffhäuser)', zip: '06537'},
    {city: 'Kelheim', zip: '93309'},
    {city: 'Kelkheim', zip: '65779'},
    {city: 'Kella', zip: '37308'},
    {city: 'Kell am See', zip: '54427'},
    {city: 'Kellenbach', zip: '55606'},
    {city: 'Kellenhusen', zip: '23746'},
    {city: 'Kellinghusen', zip: '25548'},
    {city: 'Kellmünz a.d.Iller', zip: '89293'},
    {city: 'Kelsterbach', zip: '65451'},
    {city: 'Keltern', zip: '75210'},
    {city: 'Kemberg', zip: '06901'},
    {city: 'Kemmenau', zip: '56132'},
    {city: 'Kemmern', zip: '96164'},
    {city: 'Kemnath', zip: '95478'},
    {city: 'Kemnitz', zip: '17509'},
    {city: 'Kempen', zip: '47906'},
    {city: 'Kempenich', zip: '56746'},
    {city: 'Kempfeld', zip: '55758'},
    {city: 'Kempten (Allgäu)', zip: '87435'},
    {city: 'Kempten (Allgäu)', zip: '87437'},
    {city: 'Kempten (Allgäu)', zip: '87439'},
    {city: 'Kenn', zip: '54344'},
    {city: 'Kentzlin', zip: '17111'},
    {city: 'Kenzingen', zip: '79341'},
    {city: 'Kenz-Küstrow', zip: '18314'},
    {city: 'Keppeshausen', zip: '54673'},
    {city: 'Kerben', zip: '56295'},
    {city: 'Kerken', zip: '47647'},
    {city: 'Kernen im Remstal', zip: '71394'},
    {city: 'Kerpen', zip: '50169'},
    {city: 'Kerpen', zip: '50170'},
    {city: 'Kerpen', zip: '50171'},
    {city: 'Kerpen (Eifel)', zip: '54578'},
    {city: 'Kerschenbach', zip: '54589'},
    {city: 'Kerzenheim', zip: '67304'},
    {city: 'Kescheid', zip: '57632'},
    {city: 'Kesfeld', zip: '54619'},
    {city: 'Kesseling', zip: '53506'},
    {city: 'Kesten', zip: '54518'},
    {city: 'Kestert', zip: '56348'},
    {city: 'Ketsch', zip: '68775'},
    {city: 'Kettenhausen', zip: '57612'},
    {city: 'Kettenheim', zip: '55234'},
    {city: 'Kettenkamp', zip: '49577'},
    {city: 'Kettershausen', zip: '86498'},
    {city: 'Kettig', zip: '56220'},
    {city: 'Ketzin/Havel', zip: '14669'},
    {city: 'Kevelaer', zip: '47623'},
    {city: 'Kevelaer', zip: '47624'},
    {city: 'Kevelaer', zip: '47625'},
    {city: 'Kevelaer', zip: '47626'},
    {city: 'Kevelaer', zip: '47627'},
    {city: 'Kickeshausen', zip: '54689'},
    {city: 'Kiebitzreihe', zip: '25368'},
    {city: 'Kiedrich', zip: '65399'},
    {city: 'Kiefersfelden', zip: '83088'},
    {city: 'Kiel', zip: '24103'},
    {city: 'Kiel', zip: '24105'},
    {city: 'Kiel', zip: '24106'},
    {city: 'Kiel', zip: '24107'},
    {city: 'Kiel', zip: '24109'},
    {city: 'Kiel', zip: '24111'},
    {city: 'Kiel', zip: '24113'},
    {city: 'Kiel', zip: '24114'},
    {city: 'Kiel', zip: '24116'},
    {city: 'Kiel', zip: '24118'},
    {city: 'Kiel', zip: '24143'},
    {city: 'Kiel', zip: '24145'},
    {city: 'Kiel', zip: '24146'},
    {city: 'Kiel', zip: '24147'},
    {city: 'Kiel', zip: '24148'},
    {city: 'Kiel', zip: '24149'},
    {city: 'Kiel', zip: '24159'},
    {city: 'Kienberg', zip: '83361'},
    {city: 'Kierspe', zip: '58566'},
    {city: 'Kieselbronn', zip: '75249'},
    {city: 'Kieve', zip: '17209'},
    {city: 'Kiliansroda', zip: '99441'},
    {city: 'Kindelbrück', zip: '06578'},
    {city: 'Kindelbrück', zip: '99638'},
    {city: 'Kindenheim', zip: '67271'},
    {city: 'Kinderbeuern', zip: '54538'},
    {city: 'Kinding', zip: '85125'},
    {city: 'Kindsbach', zip: '66862'},
    {city: 'Kinheim', zip: '54538'},
    {city: 'Kinsau', zip: '86981'},
    {city: 'Kinzenburg', zip: '54597'},
    {city: 'Kipfenberg', zip: '85110'},
    {city: 'Kippenheim', zip: '77971'},
    {city: 'Kirburg', zip: '57629'},
    {city: 'Kirchanschöring', zip: '83329'},
    {city: 'Kirchanschöring', zip: '83417'},
    {city: 'Kirchardt', zip: '74912'},
    {city: 'Kirchbarkau', zip: '24245'},
    {city: 'Kirchberg', zip: '08107'},
    {city: 'Kirchberg', zip: '55481'},
    {city: 'Kirchberg', zip: '84434'},
    {city: 'Kirchberg an der Iller', zip: '88486'},
    {city: 'Kirchberg an der Jagst', zip: '74592'},
    {city: 'Kirchberg an der Murr', zip: '71737'},
    {city: 'Kirchberg i.Wald', zip: '94259'},
    {city: 'Kirchbrak', zip: '37619'},
    {city: 'Kirchdorf', zip: '83527'},
    {city: 'Kirchdorf', zip: '93348'},
    {city: 'Kirchdorf', zip: '27245'},
    {city: 'Kirchdorf am Inn', zip: '84375'},
    {city: 'Kirchdorf an der Amper', zip: '85414'},
    {city: 'Kirchdorf an der Iller', zip: '88457'},
    {city: 'Kirchdorf i.Wald', zip: '94261'},
    {city: 'Kirchehrenbach', zip: '91356'},
    {city: 'Kircheib', zip: '57635'},
    {city: 'Kirchendemenreuth', zip: '92665'},
    {city: 'Kirchenlamitz', zip: '95158'},
    {city: 'Kirchenpingarten', zip: '95466'},
    {city: 'Kirchen (Sieg)', zip: '57548'},
    {city: 'Kirchensittenbach', zip: '91241'},
    {city: 'Kirchentellinsfurt', zip: '72138'},
    {city: 'Kirchenthumbach', zip: '91281'},
    {city: 'Kirchgandern', zip: '37318'},
    {city: 'Kirchgellersen', zip: '21394'},
    {city: 'Kirchhain', zip: '35274'},
    {city: 'Kirchham', zip: '94148'},
    {city: 'Kirchhaslach', zip: '87755'},
    {city: 'Kirchheilingen', zip: '99947'},
    {city: 'Kirchheim', zip: '97268'},
    {city: 'Kirchheim', zip: '36275'},
    {city: 'Kirchheim am Neckar', zip: '74366'},
    {city: 'Kirchheim am Ries', zip: '73467'},
    {city: 'Kirchheim an der Weinstraße', zip: '67281'},
    {city: 'Kirchheim bei München', zip: '85551'},
    {city: 'Kirchheimbolanden', zip: '67292'},
    {city: 'Kirchheim in Schwaben', zip: '87757'},
    {city: 'Kirchheim unter Teck', zip: '73230'},
    {city: 'Kirchhundem', zip: '57399'},
    {city: 'Kirch Jesar', zip: '19230'},
    {city: 'Kirchlauter', zip: '96166'},
    {city: 'Kirchlengern', zip: '32278'},
    {city: 'Kirchlinteln', zip: '27308'},
    {city: 'Kirchnüchel', zip: '23714'},
    {city: 'Kirchroth', zip: '94356'},
    {city: 'Kirchsahr', zip: '53505'},
    {city: 'Kirchseelte', zip: '27243'},
    {city: 'Kirchseeon', zip: '85614'},
    {city: 'Kirchspiel Garding', zip: '25836'},
    {city: 'Kirchtimke', zip: '27412'},
    {city: 'Kirchwald', zip: '56729'},
    {city: 'Kirchwalsede', zip: '27386'},
    {city: 'Kirchweidach', zip: '84558'},
    {city: 'Kirchweiler', zip: '54570'},
    {city: 'Kirchworbis', zip: '37339'},
    {city: 'Kirchzarten', zip: '79199'},
    {city: 'Kirchzell', zip: '63931'},
    {city: 'Kirf', zip: '54441'},
    {city: 'Kirkel', zip: '66459'},
    {city: 'Kirn', zip: '55606'},
    {city: 'Kirrweiler', zip: '67744'},
    {city: 'Kirrweiler', zip: '67489'},
    {city: 'Kirsbach', zip: '53539'},
    {city: 'Kirschkau', zip: '07919'},
    {city: 'Kirschroth', zip: '55566'},
    {city: 'Kirschweiler', zip: '55743'},
    {city: 'Kirtorf', zip: '36320'},
    {city: 'Kisdorf', zip: '24629'},
    {city: 'Kisselbach', zip: '56291'},
    {city: 'Kissenbrück', zip: '38324'},
    {city: 'Kissing', zip: '86438'},
    {city: 'Kißlegg', zip: '88353'},
    {city: 'Kist', zip: '97270'},
    {city: 'Kittendorf', zip: '17153'},
    {city: 'Kittlitz', zip: '23911'},
    {city: 'Kitzingen', zip: '97318'},
    {city: 'Kitzscher', zip: '04567'},
    {city: 'Klamp', zip: '24321'},
    {city: 'Klanxbüll', zip: '25924'},
    {city: 'Klappholz', zip: '24860'},
    {city: 'Klausdorf', zip: '18445'},
    {city: 'Klausen', zip: '54524'},
    {city: 'Kleinaitingen', zip: '86507'},
    {city: 'Klein Barkau', zip: '24245'},
    {city: 'Klein Belitz', zip: '18246'},
    {city: 'Klein Bennebek', zip: '24848'},
    {city: 'Klein Bennebek', zip: '24863'},
    {city: 'Klein Berßen', zip: '49777'},
    {city: 'Kleinblittersdorf', zip: '66271'},
    {city: 'Kleinbockedra', zip: '07646'},
    {city: 'Kleinbundenbach', zip: '66501'},
    {city: 'Klein Bünzow', zip: '17390'},
    {city: 'Kleinebersdorf', zip: '07646'},
    {city: 'Kleines Wiesental', zip: '79692'},
    {city: 'Kleineutersdorf', zip: '07768'},
    {city: 'Kleinfischlingen', zip: '67483'},
    {city: 'Kleinfurra', zip: '99735'},
    {city: 'Klein Gladebrügge', zip: '23795'},
    {city: 'Kleinheubach', zip: '63924'},
    {city: 'Kleinich', zip: '54483'},
    {city: 'Kleinkahl', zip: '63828'},
    {city: 'Kleinkarlbach', zip: '67271'},
    {city: 'Kleinlangenfeld', zip: '54597'},
    {city: 'Kleinlangheim', zip: '97355'},
    {city: 'Kleinmachnow', zip: '14532'},
    {city: 'Kleinmaischeid', zip: '56271'},
    {city: 'Klein Meckelsen', zip: '27419'},
    {city: 'Kleinmölsen', zip: '99198'},
    {city: 'Kleinneuhausen', zip: '99625'},
    {city: 'Kleinniedesheim', zip: '67259'},
    {city: 'Klein Nordende', zip: '25336'},
    {city: 'Klein Offenseth-Sparrieshoop', zip: '25365'},
    {city: 'Kleinostheim', zip: '63801'},
    {city: 'Klein Pampau', zip: '21514'},
    {city: 'Klein Rheide', zip: '24848'},
    {city: 'Kleinrinderfeld', zip: '97271'},
    {city: 'Klein Rogahn', zip: '19073'},
    {city: 'Klein Rönnau', zip: '23795'},
    {city: 'Kleinschwabhausen', zip: '99441'},
    {city: 'Kleinsendelbach', zip: '91077'},
    {city: 'Kleinsteinhausen', zip: '66484'},
    {city: 'Klein Trebbow', zip: '19069'},
    {city: 'Klein Upahl', zip: '18276'},
    {city: 'Klein Vielen', zip: '17237'},
    {city: 'Kleinwallstadt', zip: '63839'},
    {city: 'Klein Wesenberg', zip: '23860'},
    {city: 'Klein-Winternheim', zip: '55270'},
    {city: 'Klein Wittensee', zip: '24361'},
    {city: 'Klein Zecher', zip: '23883'},
    {city: 'Klempau', zip: '23628'},
    {city: 'Kleßen-Görne', zip: '14728'},
    {city: 'Kletkamp', zip: '24327'},
    {city: 'Klettbach', zip: '99102'},
    {city: 'Klettgau', zip: '79771'},
    {city: 'Kletzin', zip: '17111'},
    {city: 'Kleve', zip: '47533'},
    {city: 'Kleve', zip: '25554'},
    {city: 'Kleve', zip: '25776'},
    {city: 'Kleve', zip: '25779'},
    {city: 'Kliding', zip: '56825'},
    {city: 'Klietz', zip: '39524'},
    {city: 'Klingelbach', zip: '56368'},
    {city: 'Klingenberg', zip: '01774'},
    {city: 'Klingenberg am Main', zip: '63911'},
    {city: 'Klingenmünster', zip: '76889'},
    {city: 'Klingenthal', zip: '08248'},
    {city: 'Klingenthal', zip: '08267'},
    {city: 'Klink', zip: '17192'},
    {city: 'Klinkrade', zip: '23898'},
    {city: 'Klipphausen', zip: '01665'},
    {city: 'Klixbüll', zip: '25899'},
    {city: 'Klocksin', zip: '17194'},
    {city: 'Klosterkumbd', zip: '55469'},
    {city: 'Klosterlechfeld', zip: '86836'},
    {city: 'Kloster Lehnin', zip: '14797'},
    {city: 'Klostermansfeld', zip: '06308'},
    {city: 'Kloster Tempzin', zip: '19412'},
    {city: 'Kloster Veßra', zip: '98660'},
    {city: 'Klotten', zip: '56818'},
    {city: 'Klötze', zip: '38486'},
    {city: 'Kludenbach', zip: '55481'},
    {city: 'Kluis', zip: '18569'},
    {city: 'Kluse', zip: '26892'},
    {city: 'Klüsserath', zip: '54340'},
    {city: 'Klütz', zip: '23946'},
    {city: 'Klütz', zip: '23948'},
    {city: 'Kneese', zip: '19205'},
    {city: 'Kneitlingen', zip: '38170'},
    {city: 'Knetzgau', zip: '97478'},
    {city: 'Knittelsheim', zip: '76879'},
    {city: 'Knittlingen', zip: '75438'},
    {city: 'Knopp-Labach', zip: '66917'},
    {city: 'Knöringen', zip: '76833'},
    {city: 'Knorrendorf', zip: '17091'},
    {city: 'Knüllwald', zip: '34593'},
    {city: 'Koberg', zip: '23881'},
    {city: 'Kobern-Gondorf', zip: '56330'},
    {city: 'Koblentz', zip: '17309'},
    {city: 'Koblenz', zip: '56068'},
    {city: 'Koblenz', zip: '56070'},
    {city: 'Koblenz', zip: '56072'},
    {city: 'Koblenz', zip: '56073'},
    {city: 'Koblenz', zip: '56075'},
    {city: 'Koblenz', zip: '56076'},
    {city: 'Koblenz', zip: '56077'},
    {city: 'Kobrow', zip: '19406'},
    {city: 'Kochel am See', zip: '82431'},
    {city: 'Kochel am See', zip: '82432'},
    {city: 'Kodersdorf', zip: '02923'},
    {city: 'Köditz', zip: '95189'},
    {city: 'Ködnitz', zip: '95361'},
    {city: 'Köfering', zip: '93096'},
    {city: 'Kogel', zip: '19246'},
    {city: 'Kohlberg', zip: '92702'},
    {city: 'Kohlberg', zip: '72664'},
    {city: 'Köhn', zip: '24257'},
    {city: 'Kolbermoor', zip: '83059'},
    {city: 'Kolbingen', zip: '78600'},
    {city: 'Kölbingen', zip: '56459'},
    {city: 'Koldenbüttel', zip: '25840'},
    {city: 'Kolitzheim', zip: '97509'},
    {city: 'Kolkerheide', zip: '25862'},
    {city: 'Kolkwitz', zip: '03099'},
    {city: 'Kölleda', zip: '99625'},
    {city: 'Kollig', zip: '56751'},
    {city: 'Kollmar', zip: '25377'},
    {city: 'Kollmoor', zip: '25524'},
    {city: 'Kollnburg', zip: '94262'},
    {city: 'Kölln-Reisiek', zip: '25337'},
    {city: 'Kollow', zip: '21527'},
    {city: 'Kollweiler', zip: '66879'},
    {city: 'Köln', zip: '50667'},
    {city: 'Köln', zip: '50668'},
    {city: 'Köln', zip: '50670'},
    {city: 'Köln', zip: '50672'},
    {city: 'Köln', zip: '50674'},
    {city: 'Köln', zip: '50676'},
    {city: 'Köln', zip: '50677'},
    {city: 'Köln', zip: '50678'},
    {city: 'Köln', zip: '50679'},
    {city: 'Köln', zip: '50733'},
    {city: 'Köln', zip: '50735'},
    {city: 'Köln', zip: '50737'},
    {city: 'Köln', zip: '50739'},
    {city: 'Köln', zip: '50765'},
    {city: 'Köln', zip: '50767'},
    {city: 'Köln', zip: '50769'},
    {city: 'Köln', zip: '50823'},
    {city: 'Köln', zip: '50825'},
    {city: 'Köln', zip: '50827'},
    {city: 'Köln', zip: '50829'},
    {city: 'Köln', zip: '50858'},
    {city: 'Köln', zip: '50859'},
    {city: 'Köln', zip: '50931'},
    {city: 'Köln', zip: '50933'},
    {city: 'Köln', zip: '50935'},
    {city: 'Köln', zip: '50937'},
    {city: 'Köln', zip: '50939'},
    {city: 'Köln', zip: '50968'},
    {city: 'Köln', zip: '50969'},
    {city: 'Köln', zip: '50996'},
    {city: 'Köln', zip: '50997'},
    {city: 'Köln', zip: '50999'},
    {city: 'Köln', zip: '51061'},
    {city: 'Köln', zip: '51063'},
    {city: 'Köln', zip: '51065'},
    {city: 'Köln', zip: '51067'},
    {city: 'Köln', zip: '51069'},
    {city: 'Köln', zip: '51103'},
    {city: 'Köln', zip: '51105'},
    {city: 'Köln', zip: '51107'},
    {city: 'Köln', zip: '51109'},
    {city: 'Köln', zip: '51143'},
    {city: 'Köln', zip: '51145'},
    {city: 'Köln', zip: '51147'},
    {city: 'Köln', zip: '51149'},
    {city: 'Köln', zip: '51467'},
    {city: 'Kolverath', zip: '56767'},
    {city: 'Kommen', zip: '54472'},
    {city: 'Köngen', zip: '73257'},
    {city: 'Köngernheim', zip: '55278'},
    {city: 'Königheim', zip: '97953'},
    {city: 'Königsau', zip: '55606'},
    {city: 'Königsbach-Stein', zip: '75203'},
    {city: 'Königsberg in Bayern', zip: '97486'},
    {city: 'Königsbronn', zip: '89551'},
    {city: 'Königsbrück', zip: '01936'},
    {city: 'Königsbrunn', zip: '86343'},
    {city: 'Königsdorf', zip: '82549'},
    {city: 'Königsee', zip: '07426'},
    {city: 'Königseggwald', zip: '88376'},
    {city: 'Königsfeld', zip: '09306'},
    {city: 'Königsfeld', zip: '53426'},
    {city: 'Königsfeld', zip: '96167'},
    {city: 'Königsfeld', zip: '19217'},
    {city: 'Königsfeld im Schwarzwald', zip: '78126'},
    {city: 'Königshain', zip: '02829'},
    {city: 'Königshain-Wiederau', zip: '09306'},
    {city: 'Königsheim', zip: '78598'},
    {city: 'Königshügel', zip: '24799'},
    {city: 'Königslutter am Elm', zip: '38154'},
    {city: 'Königsmoor', zip: '21255'},
    {city: 'Königsmoos', zip: '86669'},
    {city: 'Königstein', zip: '61462'},
    {city: 'Königstein', zip: '01824'},
    {city: 'Königstein', zip: '92281'},
    {city: 'Königswalde', zip: '09471'},
    {city: 'Königswartha', zip: '02699'},
    {city: 'Königswinter', zip: '53639'},
    {city: 'Königs Wusterhausen', zip: '15711'},
    {city: 'Königs Wusterhausen', zip: '15712'},
    {city: 'Königs Wusterhausen', zip: '15713'},
    {city: 'Konken', zip: '66871'},
    {city: 'Könnern', zip: '06420'},
    {city: 'Konnersreuth', zip: '95692'},
    {city: 'Konradsreuth', zip: '95176'},
    {city: 'Konstanz', zip: '78462'},
    {city: 'Konstanz', zip: '78464'},
    {city: 'Konstanz', zip: '78465'},
    {city: 'Konstanz', zip: '78467'},
    {city: 'Konz', zip: '54329'},
    {city: 'Konzell', zip: '94357'},
    {city: 'Kopp', zip: '54574'},
    {city: 'Korb', zip: '71404'},
    {city: 'Korbach', zip: '34497'},
    {city: 'Korbach', zip: '34513'},
    {city: 'Körborn', zip: '66871'},
    {city: 'Korbußen', zip: '07554'},
    {city: 'Kordel', zip: '54306'},
    {city: 'Kördorf', zip: '56370'},
    {city: 'Körle', zip: '34327'},
    {city: 'Korlingen', zip: '54317'},
    {city: 'Körner', zip: '99998'},
    {city: 'Korntal-Münchingen', zip: '70825'},
    {city: 'Kornwestheim', zip: '70806'},
    {city: 'Körperich', zip: '54675'},
    {city: 'Korschenbroich', zip: '41352'},
    {city: 'Korswandt', zip: '17419'},
    {city: 'Korweiler', zip: '56288'},
    {city: 'Kösching', zip: '85092'},
    {city: 'Kosel', zip: '24354'},
    {city: 'Koserow', zip: '17459'},
    {city: 'Kospoda', zip: '07806'},
    {city: 'Kößlarn', zip: '94149'},
    {city: 'Köthel', zip: '22929'},
    {city: 'Köthel', zip: '22929'},
    {city: 'Köthen (Anhalt)', zip: '06366'},
    {city: 'Köthen (Anhalt)', zip: '06369'},
    {city: 'Köthen (Anhalt)', zip: '06388'},
    {city: 'Kottenborn', zip: '53518'},
    {city: 'Kottenheim', zip: '56736'},
    {city: 'Kötterichen', zip: '56767'},
    {city: 'Kottgeisering', zip: '82288'},
    {city: 'Kottmar', zip: '02708'},
    {city: 'Kottmar', zip: '02739'},
    {city: 'Kottweiler-Schwanden', zip: '66879'},
    {city: 'Kötz', zip: '89359'},
    {city: 'Kotzen', zip: '14715'},
    {city: 'Kotzenbüll', zip: '25832'},
    {city: 'Köwerich', zip: '54340'},
    {city: 'Koxhausen', zip: '54673'},
    {city: 'Kraam', zip: '57635'},
    {city: 'Krackow', zip: '17322'},
    {city: 'Krackow', zip: '17328'},
    {city: 'Krackow', zip: '17329'},
    {city: 'Kradenbach', zip: '54552'},
    {city: 'Kraftisried', zip: '87647'},
    {city: 'Kraftsdorf', zip: '07586'},
    {city: 'Krähenberg', zip: '66894'},
    {city: 'Kraiburg', zip: '84559'},
    {city: 'Kraichtal', zip: '76703'},
    {city: 'Krailling', zip: '82152'},
    {city: 'Krailling', zip: '82349'},
    {city: 'Krakow am See', zip: '18292'},
    {city: 'Kramerhof', zip: '18445'},
    {city: 'Kranenburg', zip: '47559'},
    {city: 'Kranenburg', zip: '21726'},
    {city: 'Kranichfeld', zip: '99448'},
    {city: 'Kranzberg', zip: '85402'},
    {city: 'Kratzeburg', zip: '17237'},
    {city: 'Kratzenburg', zip: '56283'},
    {city: 'Krauchenwies', zip: '72505'},
    {city: 'Krauschwitz', zip: '02957'},
    {city: 'Krausnick-Groß Wasserburg', zip: '15910'},
    {city: 'Krauthausen', zip: '99819'},
    {city: 'Krautheim', zip: '74238'},
    {city: 'Krautscheid', zip: '54673'},
    {city: 'Krayenberggemeinde', zip: '36460'},
    {city: 'Kreba-Neudorf', zip: '02906'},
    {city: 'Krebeck', zip: '37434'},
    {city: 'Krefeld', zip: '47798'},
    {city: 'Krefeld', zip: '47799'},
    {city: 'Krefeld', zip: '47800'},
    {city: 'Krefeld', zip: '47802'},
    {city: 'Krefeld', zip: '47803'},
    {city: 'Krefeld', zip: '47804'},
    {city: 'Krefeld', zip: '47805'},
    {city: 'Krefeld', zip: '47807'},
    {city: 'Krefeld', zip: '47809'},
    {city: 'Krefeld', zip: '47829'},
    {city: 'Krefeld', zip: '47839'},
    {city: 'Kreien', zip: '19386'},
    {city: 'Kreimbach-Kaulbach', zip: '67757'},
    {city: 'Kreischa', zip: '01731'},
    {city: 'Krembz', zip: '19205'},
    {city: 'Kremitzaue', zip: '04916'},
    {city: 'Kremitzaue', zip: '04936'},
    {city: 'Kremmen', zip: '16766'},
    {city: 'Kremmin', zip: '19300'},
    {city: 'Krempdorf', zip: '25361'},
    {city: 'Krempdorf', zip: '25376'},
    {city: 'Krempe', zip: '25361'},
    {city: 'Krempel', zip: '25774'},
    {city: 'Kremperheide', zip: '25569'},
    {city: 'Krempermoor', zip: '25569'},
    {city: 'Krems II', zip: '23827'},
    {city: 'Kreßberg', zip: '74594'},
    {city: 'Kressbronn am Bodensee', zip: '88079'},
    {city: 'Kretz', zip: '56630'},
    {city: 'Kretzschau', zip: '06712'},
    {city: 'Kreuth', zip: '83708'},
    {city: 'Kreuzau', zip: '52372'},
    {city: 'Kreuztal', zip: '57223'},
    {city: 'Kreuzwertheim', zip: '97892'},
    {city: 'Krickenbach', zip: '67706'},
    {city: 'Kriebitzsch', zip: '04617'},
    {city: 'Kriebstein', zip: '09648'},
    {city: 'Kriegsfeld', zip: '67819'},
    {city: 'Krien', zip: '17391'},
    {city: 'Kriesow', zip: '17091'},
    {city: 'Kriftel', zip: '65830'},
    {city: 'Kritzmow', zip: '18198'},
    {city: 'Kritzow', zip: '19386'},
    {city: 'Krogaspe', zip: '24644'},
    {city: 'Krokau', zip: '24217'},
    {city: 'Krölpa', zip: '07387'},
    {city: 'Krombach', zip: '63829'},
    {city: 'Krombach', zip: '37308'},
    {city: 'Kronach', zip: '96317'},
    {city: 'Kronau', zip: '76709'},
    {city: 'Kronberg', zip: '61476'},
    {city: 'Kronburg', zip: '87758'},
    {city: 'Kröning', zip: '84178'},
    {city: 'Kronprinzenkoog', zip: '25709'},
    {city: 'Kronsgaard', zip: '24395'},
    {city: 'Kronshagen', zip: '24119'},
    {city: 'Kronsmoor', zip: '25597'},
    {city: 'Kronweiler', zip: '55767'},
    {city: 'Kröpelin', zip: '18236'},
    {city: 'Kropp', zip: '24848'},
    {city: 'Kroppach', zip: '57612'},
    {city: 'Kröppelshagen-Fahrendorf', zip: '21039'},
    {city: 'Kröppelshagen-Fahrendorf', zip: '21529'},
    {city: 'Kroppen', zip: '01945'},
    {city: 'Kröppen', zip: '66957'},
    {city: 'Kroppenstedt', zip: '39397'},
    {city: 'Kröslin', zip: '17440'},
    {city: 'Krostitz', zip: '04509'},
    {city: 'Krottelbach', zip: '66909'},
    {city: 'Kröv', zip: '54536'},
    {city: 'Kruchten', zip: '54675'},
    {city: 'Kruckow', zip: '17129'},
    {city: 'Kruft', zip: '56642'},
    {city: 'Krugsdorf', zip: '17309'},
    {city: 'Krukow', zip: '21483'},
    {city: 'Krumbach (Schwaben)', zip: '86381'},
    {city: 'Krummbek', zip: '24217'},
    {city: 'Krümmel', zip: '56244'},
    {city: 'Krummenau', zip: '55483'},
    {city: 'Krummendeich', zip: '21732'},
    {city: 'Krummendiek', zip: '25554'},
    {city: 'Krummennaab', zip: '92703'},
    {city: 'Krummesse', zip: '23628'},
    {city: 'Krummhörn', zip: '26736'},
    {city: 'Krummin', zip: '17440'},
    {city: 'Krummwisch', zip: '24796'},
    {city: 'Krumstedt', zip: '25727'},
    {city: 'Krün', zip: '82467'},
    {city: 'Krün', zip: '82493'},
    {city: 'Krün', zip: '82494'},
    {city: 'Krunkel', zip: '56593'},
    {city: 'Krusenfelde', zip: '17391'},
    {city: 'Krusenhagen', zip: '23974'},
    {city: 'Krüzen', zip: '21483'},
    {city: 'Kublank', zip: '17349'},
    {city: 'Kubschütz', zip: '02627'},
    {city: 'Kuchelmiß', zip: '18292'},
    {city: 'Kuchen', zip: '73329'},
    {city: 'Kuckssee', zip: '17217'},
    {city: 'Kuddewörde', zip: '22958'},
    {city: 'Kuden', zip: '25712'},
    {city: 'Kudensee', zip: '25572'},
    {city: 'Kuhardt', zip: '76773'},
    {city: 'Kühbach', zip: '86556'},
    {city: 'Kühdorf', zip: '07980'},
    {city: 'Kuhfelde', zip: '29416'},
    {city: 'Kühlenthal', zip: '86707'},
    {city: 'Kuhlen-Wendorf', zip: '19412'},
    {city: 'Kühlungsborn', zip: '18225'},
    {city: 'Kühndorf', zip: '98547'},
    {city: 'Kuhnhöfen', zip: '56244'},
    {city: 'Kühren', zip: '24211'},
    {city: 'Kuhs', zip: '18276'},
    {city: 'Kühsen', zip: '23898'},
    {city: 'Kuhstorf', zip: '19230'},
    {city: 'Kükels', zip: '23829'},
    {city: 'Küllstedt', zip: '37359'},
    {city: 'Kulmain', zip: '95508'},
    {city: 'Kulmbach', zip: '95326'},
    {city: 'Kulmbach', zip: '96369'},
    {city: 'Kulpin', zip: '23911'},
    {city: 'Külsheim', zip: '97900'},
    {city: 'Külz (Hunsrück)', zip: '55471'},
    {city: 'Kümbdchen', zip: '55471'},
    {city: 'Kumhausen', zip: '84036'},
    {city: 'Kummerfeld', zip: '25495'},
    {city: 'Kümmernitztal', zip: '16945'},
    {city: 'Kummerow', zip: '17139'},
    {city: 'Kümmersbruck', zip: '92245'},
    {city: 'Kundert', zip: '57629'},
    {city: 'Kunreuth', zip: '91358'},
    {city: 'Künzell', zip: '36093'},
    {city: 'Künzelsau', zip: '74653'},
    {city: 'Künzing', zip: '94550'},
    {city: 'Kupferberg', zip: '95362'},
    {city: 'Kupferzell', zip: '74635'},
    {city: 'Kuppenheim', zip: '76456'},
    {city: 'Küps', zip: '96328'},
    {city: 'Kürnach', zip: '97273'},
    {city: 'Kürnbach', zip: '75057'},
    {city: 'Kürten', zip: '51515'},
    {city: 'Kurtscheid', zip: '56581'},
    {city: 'Kusel', zip: '66869'},
    {city: 'Küssaberg', zip: '79790'},
    {city: 'Küsten', zip: '29482'},
    {city: 'Kusterdingen', zip: '72127'},
    {city: 'Kusterdingen', zip: '72138'},
    {city: 'Küstriner Vorland', zip: '15328'},
    {city: 'Kutenholz', zip: '27449'},
    {city: 'Kutzenhausen', zip: '86500'},
    {city: 'Kutzleben', zip: '99955'},
    {city: 'Kyffhäuserland', zip: '99707'},
    {city: 'Kyllburg', zip: '54655'},
    {city: 'Kyllburgweiler', zip: '54655'},
    {city: 'Kyritz', zip: '16866'},
    {city: 'Laaber', zip: '93164'},
    {city: 'Laage', zip: '18299'},
    {city: 'Laar', zip: '49824'},
    {city: 'Laasdorf', zip: '07646'},
    {city: 'Laatzen', zip: '30880'},
    {city: 'Labenz', zip: '23898'},
    {city: 'Laberweinting', zip: '84082'},
    {city: 'Laboe', zip: '24235'},
    {city: 'Lachen', zip: '87760'},
    {city: 'Lachendorf', zip: '29331'},
    {city: 'Ladbergen', zip: '49549'},
    {city: 'Ladelund', zip: '25926'},
    {city: 'Ladenburg', zip: '68526'},
    {city: 'Laer', zip: '48366'},
    {city: 'Lage', zip: '32791'},
    {city: 'Lage', zip: '49828'},
    {city: 'Lägerdorf', zip: '25566'},
    {city: 'Lähden', zip: '49774'},
    {city: 'Lahn', zip: '49757'},
    {city: 'Lahnau', zip: '35633'},
    {city: 'Lahnstein', zip: '56112'},
    {city: 'Lahnstein', zip: '56130'},
    {city: 'Lahntal', zip: '35094'},
    {city: 'Lahr', zip: '54675'},
    {city: 'Lahr', zip: '56288'},
    {city: 'Lahr/Schwarzwald', zip: '77933'},
    {city: 'Laichingen', zip: '89150'},
    {city: 'Lalendorf', zip: '18279'},
    {city: 'Lalling', zip: '94551'},
    {city: 'Lam', zip: '93462'},
    {city: 'Lambertsberg', zip: '54649'},
    {city: 'Lambrecht', zip: '67466'},
    {city: 'Lambrechtshagen', zip: '18069'},
    {city: 'Lambsborn', zip: '66894'},
    {city: 'Lambsheim', zip: '67245'},
    {city: 'Lamerdingen', zip: '86862'},
    {city: 'Lammershagen', zip: '24238'},
    {city: 'Lampaden', zip: '54316'},
    {city: 'Lampertheim', zip: '68623'},
    {city: 'Lampertswalde', zip: '01561'},
    {city: 'Lamspringe', zip: '31195'},
    {city: 'Lamstedt', zip: '21769'},
    {city: 'Lancken-Granitz', zip: '18586'},
    {city: 'Landau an der Isar', zip: '94405'},
    {city: 'Landau in der Pfalz', zip: '76829'},
    {city: 'Landensberg', zip: '89361'},
    {city: 'Landesbergen', zip: '31628'},
    {city: 'Landkern', zip: '56814'},
    {city: 'Landolfshausen', zip: '37136'},
    {city: 'Landrecht', zip: '25554'},
    {city: 'Landsberg', zip: '06188'},
    {city: 'Landsberg am Lech', zip: '86899'},
    {city: 'Landsberg am Lech', zip: '86932'},
    {city: 'Landsberied', zip: '82290'},
    {city: 'Landscheid', zip: '54526'},
    {city: 'Landscheide', zip: '25572'},
    {city: 'Landshut', zip: '84028'},
    {city: 'Landshut', zip: '84030'},
    {city: 'Landshut', zip: '84032'},
    {city: 'Landshut', zip: '84034'},
    {city: 'Landshut', zip: '84036'},
    {city: 'Landstuhl', zip: '66849'},
    {city: 'Langballig', zip: '24977'},
    {city: 'Langdorf', zip: '94264'},
    {city: 'Langeln', zip: '25485'},
    {city: 'Langelsheim', zip: '38685'},
    {city: 'Langen', zip: '63225'},
    {city: 'Langen', zip: '49838'},
    {city: 'Langenaltheim', zip: '91799'},
    {city: 'Langenargen', zip: '88085'},
    {city: 'Langenau', zip: '89129'},
    {city: 'Langenbach', zip: '85416'},
    {city: 'Langenbach', zip: '66909'},
    {city: 'Langenbach bei Kirburg', zip: '57520'},
    {city: 'Langenberg', zip: '33449'},
    {city: 'Langenbernsdorf', zip: '08428'},
    {city: 'Langenbrettach', zip: '74243'},
    {city: 'Langen Brütz', zip: '19067'},
    {city: 'Langenburg', zip: '74595'},
    {city: 'Langendorf', zip: '29484'},
    {city: 'Langenenslingen', zip: '88515'},
    {city: 'Langeneß', zip: '25863'},
    {city: 'Langeneß', zip: '25867'},
    {city: 'Langenfeld', zip: '91474'},
    {city: 'Langenfeld', zip: '56729'},
    {city: 'Langenfeld (Rheinland)', zip: '40764'},
    {city: 'Langenhagen', zip: '30669'},
    {city: 'Langenhagen', zip: '30851'},
    {city: 'Langenhagen', zip: '30853'},
    {city: 'Langenhagen', zip: '30855'},
    {city: 'Langenhahn', zip: '56459'},
    {city: 'Langenhorn', zip: '25842'},
    {city: 'Langenlehsten', zip: '21514'},
    {city: 'Langenleuba-Niederhain', zip: '04618'},
    {city: 'Langenlonsheim', zip: '55450'},
    {city: 'Langenmosen', zip: '86571'},
    {city: 'Langenneufnach', zip: '86863'},
    {city: 'Langenorla', zip: '07381'},
    {city: 'Langenpreising', zip: '85465'},
    {city: 'Langenscheid', zip: '65558'},
    {city: 'Langenselbold', zip: '63505'},
    {city: 'Langensendelbach', zip: '91094'},
    {city: 'Langenthal', zip: '55569'},
    {city: 'Langenweißbach', zip: '08134'},
    {city: 'Langenwetzendorf', zip: '07957'},
    {city: 'Langenwolschendorf', zip: '07937'},
    {city: 'Langenzenn', zip: '90579'},
    {city: 'Langeoog', zip: '26465'},
    {city: 'Langerringen', zip: '86853'},
    {city: 'Langerwehe', zip: '52379'},
    {city: 'Langewahl', zip: '15518'},
    {city: 'Langfurth', zip: '91731'},
    {city: 'Langgöns', zip: '35428'},
    {city: 'Langlingen', zip: '29364'},
    {city: 'Langquaid', zip: '84085'},
    {city: 'Langscheid', zip: '56729'},
    {city: 'Langstedt', zip: '24852'},
    {city: 'Langsur', zip: '54308'},
    {city: 'Langwedel', zip: '24631'},
    {city: 'Langwedel', zip: '27299'},
    {city: 'Langweid am Lech', zip: '86462'},
    {city: 'Langweiler', zip: '55758'},
    {city: 'Langweiler', zip: '67746'},
    {city: 'Langwieden', zip: '66894'},
    {city: 'Lanitz-Hassel-Tal', zip: '06628'},
    {city: 'Lankau', zip: '23881'},
    {city: 'Lanz', zip: '19309'},
    {city: 'Lanze', zip: '21483'},
    {city: 'Lappersdorf', zip: '93138'},
    {city: 'Lärz', zip: '17248'},
    {city: 'Lasbek', zip: '23847'},
    {city: 'Lascheid', zip: '54597'},
    {city: 'Lasel', zip: '54612'},
    {city: 'Lassan', zip: '17440'},
    {city: 'Lastrup', zip: '49688'},
    {city: 'Latendorf', zip: '24598'},
    {city: 'Lathen', zip: '49762'},
    {city: 'Laubach', zip: '35321'},
    {city: 'Laubach', zip: '56288'},
    {city: 'Laubach', zip: '56759'},
    {city: 'Lauben', zip: '87493'},
    {city: 'Lauben', zip: '87761'},
    {city: 'Laubenheim', zip: '55452'},
    {city: 'Laucha an der Unstrut', zip: '06636'},
    {city: 'Lauchhammer', zip: '01979'},
    {city: 'Lauchheim', zip: '73466'},
    {city: 'Lauchringen', zip: '79787'},
    {city: 'Lauda-Königshofen', zip: '97922'},
    {city: 'Laudenbach', zip: '69514'},
    {city: 'Laudenbach', zip: '63925'},
    {city: 'Laudert', zip: '56291'},
    {city: 'Lauenau', zip: '31867'},
    {city: 'Lauenbrück', zip: '27389'},
    {city: 'Lauenburg/Elbe', zip: '21481'},
    {city: 'Lauenförde', zip: '37697'},
    {city: 'Lauenhagen', zip: '31714'},
    {city: 'Lauf', zip: '77886'},
    {city: 'Laufach', zip: '63846'},
    {city: 'Lauf an der Pegnitz', zip: '91207'},
    {city: 'Laufeld', zip: '54533'},
    {city: 'Laufen', zip: '83410'},
    {city: 'Laufenburg (Baden)', zip: '79725'},
    {city: 'Laufersweiler', zip: '55487'},
    {city: 'Lauffen am Neckar', zip: '74348'},
    {city: 'Laugna', zip: '86502'},
    {city: 'Lauingen (Donau)', zip: '89415'},
    {city: 'Laumersheim', zip: '67229'},
    {city: 'Lauperath', zip: '54649'},
    {city: 'Laupheim', zip: '88471'},
    {city: 'Laurenburg', zip: '56379'},
    {city: 'Lauscha', zip: '98724'},
    {city: 'Lauschied', zip: '55568'},
    {city: 'Lausnitz', zip: '07806'},
    {city: 'Laußig', zip: '04838'},
    {city: 'Laußnitz', zip: '01936'},
    {city: 'Lauta', zip: '02991'},
    {city: 'Lautenbach', zip: '77794'},
    {city: 'Lauter', zip: '96169'},
    {city: 'Lauterach', zip: '89584'},
    {city: 'Lauterbach', zip: '36341'},
    {city: 'Lauterbach', zip: '78730'},
    {city: 'Lauterbach', zip: '99826'},
    {city: 'Lauter-Bernsbach', zip: '08315'},
    {city: 'Lauterecken', zip: '67742'},
    {city: 'Lauterhofen', zip: '92283'},
    {city: 'Lautersheim', zip: '67308'},
    {city: 'Lauterstein', zip: '73111'},
    {city: 'Lautert', zip: '56355'},
    {city: 'Lautertal', zip: '36369'},
    {city: 'Lautertal', zip: '64686'},
    {city: 'Lautertal', zip: '96486'},
    {city: 'Lautrach', zip: '87763'},
    {city: 'Lautzenbrücken', zip: '56472'},
    {city: 'Lautzenhausen', zip: '55483'},
    {city: 'Lawalde', zip: '02708'},
    {city: 'Lawitz', zip: '15898'},
    {city: 'Lebach', zip: '66822'},
    {city: 'Lebrade', zip: '24306'},
    {city: 'Lebus', zip: '15326'},
    {city: 'Lebusa', zip: '04936'},
    {city: 'Lechbruck a.See', zip: '86983'},
    {city: 'Leck', zip: '25917'},
    {city: 'Lederhose', zip: '07589'},
    {city: 'Leegebruch', zip: '16767'},
    {city: 'Leer', zip: '26789'},
    {city: 'Leese', zip: '31633'},
    {city: 'Leezdorf', zip: '26529'},
    {city: 'Leezen', zip: '23816'},
    {city: 'Leezen', zip: '19067'},
    {city: 'Legau', zip: '87764'},
    {city: 'Legden', zip: '48739'},
    {city: 'Legde/Quitzöbel', zip: '19336'},
    {city: 'Lehe', zip: '25774'},
    {city: 'Lehe', zip: '25776'},
    {city: 'Lehe', zip: '26892'},
    {city: 'Lehesten', zip: '07349'},
    {city: 'Lehesten', zip: '07778'},
    {city: 'Lehmen', zip: '56332'},
    {city: 'Lehmkuhlen', zip: '24211'},
    {city: 'Lehmrade', zip: '23883'},
    {city: 'Lehnstedt', zip: '99441'},
    {city: 'Lehrberg', zip: '91611'},
    {city: 'Lehre', zip: '38165'},
    {city: 'Lehrensteinsfeld', zip: '74251'},
    {city: 'Lehrte', zip: '31275'},
    {city: 'Leibertingen', zip: '88637'},
    {city: 'Leiblfing', zip: '94339'},
    {city: 'Leichlingen', zip: '42799'},
    {city: 'Leidenborn', zip: '54619'},
    {city: 'Leidersbach', zip: '63849'},
    {city: 'Leienkaul', zip: '56759'},
    {city: 'Leiferde', zip: '38542'},
    {city: 'Leimbach', zip: '53518'},
    {city: 'Leimbach', zip: '54673'},
    {city: 'Leimbach', zip: '36433'},
    {city: 'Leimen', zip: '69181'},
    {city: 'Leimen', zip: '66978'},
    {city: 'Leimersheim', zip: '76774'},
    {city: 'Leinach', zip: '97274'},
    {city: 'Leinburg', zip: '91227'},
    {city: 'Leinefelde-Worbis', zip: '37327'},
    {city: 'Leinefelde-Worbis', zip: '37339'},
    {city: 'Leinfelden-Echterdingen', zip: '70771'},
    {city: 'Leingarten', zip: '74211'},
    {city: 'Leiningen', zip: '56291'},
    {city: 'Leinsweiler', zip: '76829'},
    {city: 'Leinzell', zip: '73575'},
    {city: 'Leipheim', zip: '89340'},
    {city: 'Leipzig', zip: '04103'},
    {city: 'Leipzig', zip: '04105'},
    {city: 'Leipzig', zip: '04107'},
    {city: 'Leipzig', zip: '04109'},
    {city: 'Leipzig', zip: '04129'},
    {city: 'Leipzig', zip: '04155'},
    {city: 'Leipzig', zip: '04157'},
    {city: 'Leipzig', zip: '04158'},
    {city: 'Leipzig', zip: '04159'},
    {city: 'Leipzig', zip: '04177'},
    {city: 'Leipzig', zip: '04178'},
    {city: 'Leipzig', zip: '04179'},
    {city: 'Leipzig', zip: '04205'},
    {city: 'Leipzig', zip: '04207'},
    {city: 'Leipzig', zip: '04209'},
    {city: 'Leipzig', zip: '04229'},
    {city: 'Leipzig', zip: '04249'},
    {city: 'Leipzig', zip: '04275'},
    {city: 'Leipzig', zip: '04277'},
    {city: 'Leipzig', zip: '04279'},
    {city: 'Leipzig', zip: '04288'},
    {city: 'Leipzig', zip: '04289'},
    {city: 'Leipzig', zip: '04299'},
    {city: 'Leipzig', zip: '04315'},
    {city: 'Leipzig', zip: '04316'},
    {city: 'Leipzig', zip: '04317'},
    {city: 'Leipzig', zip: '04318'},
    {city: 'Leipzig', zip: '04319'},
    {city: 'Leipzig', zip: '04328'},
    {city: 'Leipzig', zip: '04329'},
    {city: 'Leipzig', zip: '04347'},
    {city: 'Leipzig', zip: '04349'},
    {city: 'Leipzig', zip: '04356'},
    {city: 'Leipzig', zip: '04357'},
    {city: 'Leisel', zip: '55767'},
    {city: 'Leisnig', zip: '04703'},
    {city: 'Leitzweiler', zip: '55779'},
    {city: 'Leiwen', zip: '54340'},
    {city: 'Leizen', zip: '17209'},
    {city: 'Lelkendorf', zip: '17168'},
    {city: 'Lemberg', zip: '66969'},
    {city: 'Lembruch', zip: '49459'},
    {city: 'Lemförde', zip: '49448'},
    {city: 'Lemgo', zip: '32657'},
    {city: 'Lemgow', zip: '29485'},
    {city: 'Lemnitz', zip: '07819'},
    {city: 'Lemwerder', zip: '27809'},
    {city: 'Lengdorf', zip: '84435'},
    {city: 'Lengede', zip: '38268'},
    {city: 'Lengenbostel', zip: '27419'},
    {city: 'Lengenfeld', zip: '08485'},
    {city: 'Lengenwang', zip: '87663'},
    {city: 'Lengerich', zip: '49525'},
    {city: 'Lengerich', zip: '49838'},
    {city: 'Lengfeld', zip: '98660'},
    {city: 'Lenggries', zip: '83661'},
    {city: 'Lenne', zip: '37627'},
    {city: 'Lennestadt', zip: '57368'},
    {city: 'Lenningen', zip: '73252'},
    {city: 'Lenningen', zip: '73266'},
    {city: 'Lensahn', zip: '23738'},
    {city: 'Lenterode', zip: '37318'},
    {city: 'Lentföhrden', zip: '24632'},
    {city: 'Lentföhrden', zip: '24640'},
    {city: 'Lenting', zip: '85101'},
    {city: 'Lenzen (Elbe)', zip: '19309'},
    {city: 'Lenzerwische', zip: '19309'},
    {city: 'Lenzkirch', zip: '79853'},
    {city: 'Leonberg', zip: '71229'},
    {city: 'Leonberg', zip: '95666'},
    {city: 'Leopoldshagen', zip: '17375'},
    {city: 'Leopoldshöhe', zip: '33818'},
    {city: 'Letschin', zip: '15324'},
    {city: 'Lettweiler', zip: '67823'},
    {city: 'Leubsdorf', zip: '09573'},
    {city: 'Leubsdorf', zip: '53547'},
    {city: 'Leuchtenberg', zip: '92705'},
    {city: 'Leun', zip: '35638'},
    {city: 'Leuna', zip: '06237'},
    {city: 'Leupoldsgrün', zip: '95191'},
    {city: 'Leutenbach', zip: '71397'},
    {city: 'Leutenbach', zip: '91359'},
    {city: 'Leutenberg', zip: '07338'},
    {city: 'Leuterod', zip: '56244'},
    {city: 'Leutersdorf', zip: '02794'},
    {city: 'Leutersdorf', zip: '98617'},
    {city: 'Leutershausen', zip: '91578'},
    {city: 'Leutesdorf', zip: '56599'},
    {city: 'Leutkirch im Allgäu', zip: '88299'},
    {city: 'Levenhagen', zip: '17498'},
    {city: 'Leverkusen', zip: '51371'},
    {city: 'Leverkusen', zip: '51373'},
    {city: 'Leverkusen', zip: '51375'},
    {city: 'Leverkusen', zip: '51377'},
    {city: 'Leverkusen', zip: '51379'},
    {city: 'Leverkusen', zip: '51381'},
    {city: 'Lewitzrand', zip: '19372'},
    {city: 'Lewitzrand', zip: '19374'},
    {city: 'Lexgaard', zip: '25923'},
    {city: 'Lich', zip: '35423'},
    {city: 'Lichtenau', zip: '33165'},
    {city: 'Lichtenau', zip: '09244'},
    {city: 'Lichtenau', zip: '91586'},
    {city: 'Lichtenau', zip: '77839'},
    {city: 'Lichtenberg', zip: '95192'},
    {city: 'Lichtenberg', zip: '01896'},
    {city: 'Lichtenberg/Erzgebirge', zip: '09638'},
    {city: 'Lichtenborn', zip: '54619'},
    {city: 'Lichtenfels', zip: '35104'},
    {city: 'Lichtenfels', zip: '96215'},
    {city: 'Lichtenstein', zip: '72805'},
    {city: 'Lichtenstein/Sachsen', zip: '09350'},
    {city: 'Lichtentanne', zip: '08115'},
    {city: 'Lichtenwald', zip: '73669'},
    {city: 'Lichterfeld-Schacksdorf', zip: '03238'},
    {city: 'Liebenau', zip: '34396'},
    {city: 'Liebenau', zip: '31618'},
    {city: 'Liebenburg', zip: '38704'},
    {city: 'Liebenscheid', zip: '56479'},
    {city: 'Liebenwalde', zip: '16559'},
    {city: 'Lieberose', zip: '15868'},
    {city: 'Liebschützberg', zip: '04758'},
    {city: 'Liebshausen', zip: '55494'},
    {city: 'Liebstadt', zip: '01825'},
    {city: 'Liederbach', zip: '65835'},
    {city: 'Lieg', zip: '56290'},
    {city: 'Lienen', zip: '49536'},
    {city: 'Liepe', zip: '16248'},
    {city: 'Liepgarten', zip: '17375'},
    {city: 'Lierfeld', zip: '54597'},
    {city: 'Lierschied', zip: '56357'},
    {city: 'Liesenich', zip: '56858'},
    {city: 'Lieser', zip: '54470'},
    {city: 'Ließem', zip: '54636'},
    {city: 'Lieth', zip: '25770'},
    {city: 'Lietzen', zip: '15306'},
    {city: 'Lietzow', zip: '18528'},
    {city: 'Lilienthal', zip: '28865'},
    {city: 'Limbach', zip: '74838'},
    {city: 'Limbach', zip: '08468'},
    {city: 'Limbach', zip: '08491'},
    {city: 'Limbach', zip: '57629'},
    {city: 'Limbach', zip: '55606'},
    {city: 'Limbach-Oberfrohna', zip: '09212'},
    {city: 'Limburg a. d. Lahn', zip: '65549'},
    {city: 'Limburg a. d. Lahn', zip: '65550'},
    {city: 'Limburg a. d. Lahn', zip: '65551'},
    {city: 'Limburg a. d. Lahn', zip: '65552'},
    {city: 'Limburg a. d. Lahn', zip: '65553'},
    {city: 'Limburg a. d. Lahn', zip: '65554'},
    {city: 'Limburg a. d. Lahn', zip: '65555'},
    {city: 'Limburg a. d. Lahn', zip: '65556'},
    {city: 'Limburgerhof', zip: '67117'},
    {city: 'Limeshain', zip: '63694'},
    {city: 'Linau', zip: '22959'},
    {city: 'Lind', zip: '56729'},
    {city: 'Lind', zip: '53506'},
    {city: 'Linda b. Weida', zip: '07580'},
    {city: 'Lindau', zip: '24214'},
    {city: 'Lindau (Bodensee)', zip: '88131'},
    {city: 'Lindberg', zip: '94227'},
    {city: 'Linden', zip: '35440'},
    {city: 'Linden', zip: '57629'},
    {city: 'Linden', zip: '25791'},
    {city: 'Linden', zip: '66851'},
    {city: 'Linden', zip: '67706'},
    {city: 'Lindenau', zip: '01945'},
    {city: 'Lindenberg', zip: '67473'},
    {city: 'Lindenberg', zip: '17111'},
    {city: 'Lindenberg im Allgäu', zip: '88161'},
    {city: 'Lindendorf', zip: '15306'},
    {city: 'Lindenfels', zip: '64678'},
    {city: 'Lindenkreuz', zip: '07589'},
    {city: 'Lindenschied', zip: '55481'},
    {city: 'Lindern (Oldenburg)', zip: '49699'},
    {city: 'Lindetal', zip: '17349'},
    {city: 'Lindewerra', zip: '37318'},
    {city: 'Lindewitt', zip: '24969'},
    {city: 'Lindholz', zip: '18334'},
    {city: 'Lindhorst', zip: '31698'},
    {city: 'Lindig', zip: '07768'},
    {city: 'Lindlar', zip: '51789'},
    {city: 'Lindow (Mark)', zip: '16835'},
    {city: 'Lindwedel', zip: '29690'},
    {city: 'Lingen (Ems)', zip: '49808'},
    {city: 'Lingen (Ems)', zip: '49809'},
    {city: 'Lingen (Ems)', zip: '49811'},
    {city: 'Lingenfeld', zip: '67360'},
    {city: 'Lingerhahn', zip: '56291'},
    {city: 'Linkenbach', zip: '56317'},
    {city: 'Linkenheim-Hochstetten', zip: '76351'},
    {city: 'Linnich', zip: '52441'},
    {city: 'Linsburg', zip: '31636'},
    {city: 'Linsengericht', zip: '63589'},
    {city: 'Linthe', zip: '14822'},
    {city: 'Linz am Rhein', zip: '53545'},
    {city: 'Linz am Rhein', zip: '53560'},
    {city: 'Lippersdorf-Erdmannsdorf', zip: '07646'},
    {city: 'Lippetal', zip: '59510'},
    {city: 'Lipporn', zip: '56357'},
    {city: 'Lipprechterode', zip: '99752'},
    {city: 'Lippstadt', zip: '59555'},
    {city: 'Lippstadt', zip: '59556'},
    {city: 'Lippstadt', zip: '59557'},
    {city: 'Lippstadt', zip: '59558'},
    {city: 'Lirstal', zip: '56767'},
    {city: 'Lisberg', zip: '96170'},
    {city: 'Lissendorf', zip: '54587'},
    {city: 'List auf Sylt', zip: '25992'},
    {city: 'Litzendorf', zip: '96123'},
    {city: 'Löbau', zip: '02708'},
    {city: 'Lobbach', zip: '74931'},
    {city: 'Löberschütz', zip: '07751'},
    {city: 'Löbichau', zip: '04626'},
    {city: 'Löbnitz', zip: '18314'},
    {city: 'Löbnitz', zip: '04509'},
    {city: 'Löchgau', zip: '74369'},
    {city: 'Lochum', zip: '57629'},
    {city: 'Löcknitz', zip: '17321'},
    {city: 'Lockstedt', zip: '25551'},
    {city: 'Loddin', zip: '17459'},
    {city: 'Lödla', zip: '04617'},
    {city: 'Löf', zip: '56332'},
    {city: 'Loffenau', zip: '76597'},
    {city: 'Löffingen', zip: '79843'},
    {city: 'Lohbarbek', zip: '25551'},
    {city: 'Lohberg', zip: '93470'},
    {city: 'Lohe-Föhrden', zip: '24806'},
    {city: 'Lohe-Rickelshof', zip: '25746'},
    {city: 'Lohfelden', zip: '34253'},
    {city: 'Lohheide', zip: '29303'},
    {city: 'Lohkirchen', zip: '84494'},
    {city: 'Löhma', zip: '07907'},
    {city: 'Lohmar', zip: '53797'},
    {city: 'Lohme', zip: '18551'},
    {city: 'Lohmen', zip: '01847'},
    {city: 'Lohmen', zip: '18276'},
    {city: 'Löhnberg', zip: '35792'},
    {city: 'Lohne', zip: '49393'},
    {city: 'Löhne', zip: '32584'},
    {city: 'Lohnsfeld', zip: '67727'},
    {city: 'Lohnweiler', zip: '67744'},
    {city: 'Lohra', zip: '35102'},
    {city: 'Lohr am Main', zip: '97816'},
    {city: 'Lohrheim', zip: '65558'},
    {city: 'Lohsa', zip: '02999'},
    {city: 'Loiching', zip: '84180'},
    {city: 'Loissin', zip: '17509'},
    {city: 'Loit', zip: '24888'},
    {city: 'Loitsche-Heinrichsberg', zip: '39326'},
    {city: 'Loitz', zip: '17121'},
    {city: 'Loitzendorf', zip: '94359'},
    {city: 'Lollar', zip: '35457'},
    {city: 'Löllbach', zip: '67744'},
    {city: 'Lollschied', zip: '56357'},
    {city: 'Lommatzsch', zip: '01623'},
    {city: 'Longen', zip: '54338'},
    {city: 'Longkamp', zip: '54472'},
    {city: 'Longuich', zip: '54340'},
    {city: 'Löningen', zip: '49624'},
    {city: 'Lonnerstadt', zip: '91475'},
    {city: 'Lonnig', zip: '56295'},
    {city: 'Lonsee', zip: '89173'},
    {city: 'Lonsheim', zip: '55237'},
    {city: 'Looft', zip: '25582'},
    {city: 'Loop', zip: '24644'},
    {city: 'Loose', zip: '24366'},
    {city: 'Löptin', zip: '24250'},
    {city: 'Lorch', zip: '65391'},
    {city: 'Lorch', zip: '73547'},
    {city: 'Lörrach', zip: '79539'},
    {city: 'Lörrach', zip: '79540'},
    {city: 'Lörrach', zip: '79541'},
    {city: 'Lorsch', zip: '64653'},
    {city: 'Lorscheid', zip: '54317'},
    {city: 'Lorup', zip: '26901'},
    {city: 'Lörzweiler', zip: '55296'},
    {city: 'Losheim am See', zip: '66679'},
    {city: 'Lösnich', zip: '54492'},
    {city: 'Lossatal', zip: '04808'},
    {city: 'Loßburg', zip: '72290'},
    {city: 'Lößnitz', zip: '08294'},
    {city: 'Lotte', zip: '49504'},
    {city: 'Lottorf', zip: '24878'},
    {city: 'Lottstetten', zip: '79807'},
    {city: 'Lötzbeuren', zip: '56843'},
    {city: 'Löwenberger Land', zip: '16775'},
    {city: 'Löwenstedt', zip: '25864'},
    {city: 'Löwenstein', zip: '74245'},
    {city: 'Loxstedt', zip: '27612'},
    {city: 'Lübbecke', zip: '32312'},
    {city: 'Lübbenau/Spreewald', zip: '03222'},
    {city: 'Lübben (Spreewald)', zip: '15907'},
    {city: 'Lübberstedt', zip: '27729'},
    {city: 'Lübberstorf', zip: '23992'},
    {city: 'Lübbow', zip: '29488'},
    {city: 'Lübeck', zip: '23552'},
    {city: 'Lübeck', zip: '23554'},
    {city: 'Lübeck', zip: '23556'},
    {city: 'Lübeck', zip: '23558'},
    {city: 'Lübeck', zip: '23560'},
    {city: 'Lübeck', zip: '23562'},
    {city: 'Lübeck', zip: '23564'},
    {city: 'Lübeck', zip: '23566'},
    {city: 'Lübeck', zip: '23568'},
    {city: 'Lübeck', zip: '23569'},
    {city: 'Lübeck', zip: '23570'},
    {city: 'Lübesse', zip: '19077'},
    {city: 'Lüblow', zip: '19288'},
    {city: 'Lubmin', zip: '17509'},
    {city: 'Lübow', zip: '23972'},
    {city: 'Lübs', zip: '17379'},
    {city: 'Lübstorf', zip: '19069'},
    {city: 'Lübtheen', zip: '19249'},
    {city: 'Lübz', zip: '19386'},
    {city: 'Lüchow', zip: '23898'},
    {city: 'Lüchow', zip: '29439'},
    {city: 'Lucka', zip: '04613'},
    {city: 'Luckaitztal', zip: '03229'},
    {city: 'Luckau', zip: '15926'},
    {city: 'Luckau (Wendland)', zip: '29487'},
    {city: 'Luckenbach', zip: '57629'},
    {city: 'Lückenburg', zip: '54424'},
    {city: 'Luckenwalde', zip: '14943'},
    {city: 'Luckow', zip: '17375'},
    {city: 'Lüdenscheid', zip: '58507'},
    {city: 'Lüdenscheid', zip: '58509'},
    {city: 'Lüdenscheid', zip: '58511'},
    {city: 'Lüdenscheid', zip: '58513'},
    {city: 'Lüdenscheid', zip: '58515'},
    {city: 'Lüder', zip: '29394'},
    {city: 'Lüdersburg', zip: '21379'},
    {city: 'Lüdersdorf', zip: '23923'},
    {city: 'Lüdersfeld', zip: '31702'},
    {city: 'Lüdershagen', zip: '18314'},
    {city: 'Lüdinghausen', zip: '59348'},
    {city: 'Ludwigsau', zip: '36251'},
    {city: 'Ludwigsburg', zip: '71634'},
    {city: 'Ludwigsburg', zip: '71636'},
    {city: 'Ludwigsburg', zip: '71638'},
    {city: 'Ludwigsburg', zip: '71640'},
    {city: 'Ludwigsburg', zip: '71642'},
    {city: 'Ludwigsburg', zip: '71672'},
    {city: 'Ludwigschorgast', zip: '95364'},
    {city: 'Ludwigsfelde', zip: '14974'},
    {city: 'Ludwigshafen am Rhein', zip: '67059'},
    {city: 'Ludwigshafen am Rhein', zip: '67061'},
    {city: 'Ludwigshafen am Rhein', zip: '67063'},
    {city: 'Ludwigshafen am Rhein', zip: '67065'},
    {city: 'Ludwigshafen am Rhein', zip: '67067'},
    {city: 'Ludwigshafen am Rhein', zip: '67069'},
    {city: 'Ludwigshafen am Rhein', zip: '67071'},
    {city: 'Ludwigshöhe', zip: '55278'},
    {city: 'Ludwigslust', zip: '19288'},
    {city: 'Ludwigsstadt', zip: '96337'},
    {city: 'Ludwigswinkel', zip: '66996'},
    {city: 'Lüerdissen', zip: '37635'},
    {city: 'Lug', zip: '76848'},
    {city: 'Lugau', zip: '09385'},
    {city: 'Lügde', zip: '32676'},
    {city: 'Luhden', zip: '31711'},
    {city: 'Luhe-Wildenau', zip: '92706'},
    {city: 'Luhnstedt', zip: '24816'},
    {city: 'Luisenthal', zip: '99885'},
    {city: 'Lülsfeld', zip: '97511'},
    {city: 'Lunden', zip: '25774'},
    {city: 'Lünebach', zip: '54597'},
    {city: 'Lüneburg', zip: '21335'},
    {city: 'Lüneburg', zip: '21337'},
    {city: 'Lüneburg', zip: '21339'},
    {city: 'Lüneburg', zip: '21391'},
    {city: 'Lünen', zip: '44532'},
    {city: 'Lünen', zip: '44534'},
    {city: 'Lünen', zip: '44536'},
    {city: 'Lünne', zip: '48480'},
    {city: 'Lunow-Stolzenhagen', zip: '16248'},
    {city: 'Lunzenau', zip: '09328'},
    {city: 'Lupburg', zip: '92331'},
    {city: 'Lürschau', zip: '24850'},
    {city: 'Lüssow', zip: '18442'},
    {city: 'Lüssow', zip: '18276'},
    {city: 'Lustadt', zip: '67363'},
    {city: 'Lütau', zip: '21483'},
    {city: 'Lütetsburg', zip: '26524'},
    {city: 'Lutherstadt Eisleben', zip: '06295'},
    {city: 'Lütjenburg', zip: '24321'},
    {city: 'Lütjenholm', zip: '25842'},
    {city: 'Lütjensee', zip: '22952'},
    {city: 'Lütjenwestedt', zip: '25585'},
    {city: 'Lütow', zip: '17440'},
    {city: 'Lutter', zip: '37318'},
    {city: 'Lutter am Barenberge', zip: '38729'},
    {city: 'Lutterbek', zip: '24235'},
    {city: 'Lüttow-Valluhn', zip: '19246'},
    {city: 'Lütz', zip: '56290'},
    {city: 'Lützelbach', zip: '64750'},
    {city: 'Lützen', zip: '06686'},
    {city: 'Lutzerath', zip: '56826'},
    {city: 'Lutzhorn', zip: '25355'},
    {city: 'Lutzingen', zip: '89440'},
    {city: 'Lützkampen', zip: '54617'},
    {city: 'Lützow', zip: '19209'},
    {city: 'Luxem', zip: '56729'},
    {city: 'Lychen', zip: '17279'},
    {city: 'Lykershausen', zip: '56346'},
    {city: 'Maasbüll', zip: '24975'},
    {city: 'Maasen', zip: '27249'},
    {city: 'Maasholm', zip: '24404'},
    {city: 'Machern', zip: '04827'},
    {city: 'Macken', zip: '56290'},
    {city: 'Mackenbach', zip: '67686'},
    {city: 'Mackenrode', zip: '37318'},
    {city: 'Mackenrodt', zip: '55758'},
    {city: 'Magdala', zip: '99441'},
    {city: 'Magdeburg', zip: '39104'},
    {city: 'Magdeburg', zip: '39106'},
    {city: 'Magdeburg', zip: '39108'},
    {city: 'Magdeburg', zip: '39110'},
    {city: 'Magdeburg', zip: '39112'},
    {city: 'Magdeburg', zip: '39114'},
    {city: 'Magdeburg', zip: '39116'},
    {city: 'Magdeburg', zip: '39118'},
    {city: 'Magdeburg', zip: '39120'},
    {city: 'Magdeburg', zip: '39122'},
    {city: 'Magdeburg', zip: '39124'},
    {city: 'Magdeburg', zip: '39126'},
    {city: 'Magdeburg', zip: '39128'},
    {city: 'Magdeburg', zip: '39130'},
    {city: 'Magstadt', zip: '71106'},
    {city: 'Mahlberg', zip: '77972'},
    {city: 'Mahlstetten', zip: '78601'},
    {city: 'Mähren', zip: '56459'},
    {city: 'Mähring', zip: '95695'},
    {city: 'Maierhöfen', zip: '88167'},
    {city: 'Maihingen', zip: '86747'},
    {city: 'Maikammer', zip: '67487'},
    {city: 'Mainaschaff', zip: '63814'},
    {city: 'Mainbernheim', zip: '97350'},
    {city: 'Mainburg', zip: '84048'},
    {city: 'Mainhardt', zip: '74535'},
    {city: 'Mainhausen', zip: '63533'},
    {city: 'Mainleus', zip: '95336'},
    {city: 'Mainstockheim', zip: '97320'},
    {city: 'Maintal', zip: '63477'},
    {city: 'Mainz', zip: '55116'},
    {city: 'Mainz', zip: '55118'},
    {city: 'Mainz', zip: '55120'},
    {city: 'Mainz', zip: '55122'},
    {city: 'Mainz', zip: '55124'},
    {city: 'Mainz', zip: '55126'},
    {city: 'Mainz', zip: '55127'},
    {city: 'Mainz', zip: '55128'},
    {city: 'Mainz', zip: '55129'},
    {city: 'Mainz', zip: '55130'},
    {city: 'Mainz', zip: '55131'},
    {city: 'Maisach', zip: '82216'},
    {city: 'Maisborn', zip: '56291'},
    {city: 'Maitenbeth', zip: '83558'},
    {city: 'Maitzborn', zip: '55481'},
    {city: 'Malberg', zip: '57629'},
    {city: 'Malberg', zip: '54655'},
    {city: 'Malbergweich', zip: '54655'},
    {city: 'Malborn', zip: '54426'},
    {city: 'Malchin', zip: '17139'},
    {city: 'Malching', zip: '94094'},
    {city: 'Malchow', zip: '17213'},
    {city: 'Malente', zip: '23714'},
    {city: 'Malgersdorf', zip: '84333'},
    {city: 'Malk Göhren', zip: '19294'},
    {city: 'Mallersdorf-Pfaffenberg', zip: '84066'},
    {city: 'Malliß', zip: '19294'},
    {city: 'Malsburg-Marzell', zip: '79429'},
    {city: 'Malsch', zip: '69254'},
    {city: 'Malsch', zip: '76316'},
    {city: 'Malschwitz', zip: '02694'},
    {city: 'Malsfeld', zip: '34323'},
    {city: 'Malterdingen', zip: '79364'},
    {city: 'Mammelzen', zip: '57636'},
    {city: 'Mammendorf', zip: '82291'},
    {city: 'Mamming', zip: '94437'},
    {city: 'Manching', zip: '85077'},
    {city: 'Mandel', zip: '55595'},
    {city: 'Mandelbachtal', zip: '66399'},
    {city: 'Mandern', zip: '54429'},
    {city: 'Manderscheid', zip: '54649'},
    {city: 'Manderscheid', zip: '54531'},
    {city: 'Manhagen', zip: '23738'},
    {city: 'Mannebach', zip: '56769'},
    {city: 'Mannebach', zip: '54441'},
    {city: 'Mannheim', zip: '68159'},
    {city: 'Mannheim', zip: '68161'},
    {city: 'Mannheim', zip: '68163'},
    {city: 'Mannheim', zip: '68165'},
    {city: 'Mannheim', zip: '68167'},
    {city: 'Mannheim', zip: '68169'},
    {city: 'Mannheim', zip: '68199'},
    {city: 'Mannheim', zip: '68219'},
    {city: 'Mannheim', zip: '68229'},
    {city: 'Mannheim', zip: '68239'},
    {city: 'Mannheim', zip: '68259'},
    {city: 'Mannheim', zip: '68305'},
    {city: 'Mannheim', zip: '68307'},
    {city: 'Mannheim', zip: '68309'},
    {city: 'Mannweiler-Cölln', zip: '67822'},
    {city: 'Mansfeld', zip: '06343'},
    {city: 'Mantel', zip: '92708'},
    {city: 'Manubach', zip: '55413'},
    {city: 'Marbach am Neckar', zip: '71672'},
    {city: 'Marbach am Neckar', zip: '71711'},
    {city: 'Marburg', zip: '35037'},
    {city: 'Marburg', zip: '35039'},
    {city: 'Marburg', zip: '35041'},
    {city: 'Marburg', zip: '35043'},
    {city: 'Marburg', zip: '35094'},
    {city: 'March', zip: '79232'},
    {city: 'Margetshöchheim', zip: '97276'},
    {city: 'Mariaposching', zip: '94553'},
    {city: 'Marienberg', zip: '09496'},
    {city: 'Marienfels', zip: '56357'},
    {city: 'Marienfließ', zip: '16945'},
    {city: 'Marienhafe', zip: '26529'},
    {city: 'Marienhausen', zip: '56269'},
    {city: 'Marienheide', zip: '51709'},
    {city: 'Marienmünster', zip: '37696'},
    {city: 'Marienrachdorf', zip: '56242'},
    {city: 'Mariental', zip: '38368'},
    {city: 'Marienwerder', zip: '16348'},
    {city: 'Maring-Noviand', zip: '54484'},
    {city: 'Marisfeld', zip: '98530'},
    {city: 'Markdorf', zip: '88677'},
    {city: 'Markersdorf', zip: '02829'},
    {city: 'Markgröningen', zip: '71706'},
    {city: 'Märkisch Buchholz', zip: '15748'},
    {city: 'Märkische Heide', zip: '15913'},
    {city: 'Märkische Höhe', zip: '15377'},
    {city: 'Märkisch Linden', zip: '16818'},
    {city: 'Märkisch Luch', zip: '14715'},
    {city: 'Markkleeberg', zip: '04416'},
    {city: 'Mark Landin', zip: '16278'},
    {city: 'Marklkofen', zip: '84163'},
    {city: 'Marklohe', zip: '31608'},
    {city: 'Markneukirchen', zip: '08258'},
    {city: 'Markranstädt', zip: '04420'},
    {city: 'Marktbergel', zip: '91613'},
    {city: 'Markt Berolzheim', zip: '91801'},
    {city: 'Markt Bibart', zip: '91477'},
    {city: 'Marktbreit', zip: '97340'},
    {city: 'Markt Einersheim', zip: '97348'},
    {city: 'Markt Erlbach', zip: '91459'},
    {city: 'Marktgraitz', zip: '96257'},
    {city: 'Marktheidenfeld', zip: '97828'},
    {city: 'Markt Indersdorf', zip: '85229'},
    {city: 'Marktl', zip: '84533'},
    {city: 'Marktleugast', zip: '95352'},
    {city: 'Marktleuthen', zip: '95168'},
    {city: 'Markt Nordheim', zip: '91478'},
    {city: 'Marktoberdorf', zip: '87616'},
    {city: 'Marktoffingen', zip: '86748'},
    {city: 'Marktredwitz', zip: '95615'},
    {city: 'Markt Rettenbach', zip: '87733'},
    {city: 'Marktrodach', zip: '96364'},
    {city: 'Marktschellenberg', zip: '83487'},
    {city: 'Marktschorgast', zip: '95509'},
    {city: 'Markt Schwaben', zip: '85570'},
    {city: 'Marktsteft', zip: '97342'},
    {city: 'Markt Taschendorf', zip: '91480'},
    {city: 'Markt Wald', zip: '86865'},
    {city: 'Marktzeuln', zip: '96275'},
    {city: 'Markvippach', zip: '99195'},
    {city: 'Marl', zip: '45768'},
    {city: 'Marl', zip: '45770'},
    {city: 'Marl', zip: '45772'},
    {city: 'Marl', zip: '49448'},
    {city: 'Marloffstein', zip: '91080'},
    {city: 'Marlow', zip: '18337'},
    {city: 'Marne', zip: '25709'},
    {city: 'Marnerdeich', zip: '25709'},
    {city: 'Marnheim', zip: '67297'},
    {city: 'Maroldsweisach', zip: '96126'},
    {city: 'Marolterode', zip: '99994'},
    {city: 'Maroth', zip: '56271'},
    {city: 'Marpingen', zip: '66646'},
    {city: 'Marquartstein', zip: '83250'},
    {city: 'Marsberg', zip: '34431'},
    {city: 'Marschacht', zip: '21436'},
    {city: 'Martensrade', zip: '24238'},
    {city: 'Martfeld', zip: '27327'},
    {city: 'Marth', zip: '37318'},
    {city: 'Martinroda', zip: '98693'},
    {city: 'Martinsheim', zip: '97340'},
    {city: 'Martinshöhe', zip: '66894'},
    {city: 'Martinstein', zip: '55627'},
    {city: 'Marxen', zip: '21439'},
    {city: 'Marxheim', zip: '86688'},
    {city: 'Marxzell', zip: '76359'},
    {city: 'Marzhausen', zip: '57627'},
    {city: 'Marzling', zip: '85417'},
    {city: 'Masburg', zip: '56761'},
    {city: 'Maselheim', zip: '88437'},
    {city: 'Maßbach', zip: '97711'},
    {city: 'Massenbachhausen', zip: '74252'},
    {city: 'Massen-Niederlausitz', zip: '03238'},
    {city: 'Massen-Niederlausitz', zip: '03246'},
    {city: 'Masserberg', zip: '98666'},
    {city: 'Massing', zip: '84323'},
    {city: 'Maßweiler', zip: '66506'},
    {city: 'Mastershausen', zip: '56869'},
    {city: 'Masthorn', zip: '54597'},
    {city: 'Matzenbach', zip: '66909'},
    {city: 'Matzerath', zip: '54597'},
    {city: 'Mauchenheim', zip: '67294'},
    {city: 'Mauden', zip: '57520'},
    {city: 'Mauel', zip: '54649'},
    {city: 'Mauer', zip: '69256'},
    {city: 'Mauern', zip: '85419'},
    {city: 'Mauerstetten', zip: '87665'},
    {city: 'Maulbronn', zip: '75433'},
    {city: 'Maulburg', zip: '79689'},
    {city: 'Mauschbach', zip: '66500'},
    {city: 'Mauth', zip: '94151'},
    {city: 'Maxdorf', zip: '67133'},
    {city: 'Maxhütte-Haidhof', zip: '93142'},
    {city: 'Maxsain', zip: '56244'},
    {city: 'Mayen', zip: '56727'},
    {city: 'Mayen', zip: '56729'},
    {city: 'Mayschoß', zip: '53508'},
    {city: 'Mechelroda', zip: '99441'},
    {city: 'Mechernich', zip: '53894'},
    {city: 'Mechow', zip: '23909'},
    {city: 'Mechtersen', zip: '21358'},
    {city: 'Meckel', zip: '54636'},
    {city: 'Meckenbach', zip: '55606'},
    {city: 'Meckenbach', zip: '55767'},
    {city: 'Meckenbeuren', zip: '88074'},
    {city: 'Meckenheim', zip: '53340'},
    {city: 'Meckenheim', zip: '67149'},
    {city: 'Meckesheim', zip: '74909'},
    {city: 'Medard', zip: '67744'},
    {city: 'Meddersheim', zip: '55566'},
    {city: 'Meddewade', zip: '23847'},
    {city: 'Medebach', zip: '59964'},
    {city: 'Medelby', zip: '24994'},
    {city: 'Medlingen', zip: '89441'},
    {city: 'Medow', zip: '17391'},
    {city: 'Meeder', zip: '96484'},
    {city: 'Meerane', zip: '08393'},
    {city: 'Meerbeck', zip: '31715'},
    {city: 'Meerbusch', zip: '40667'},
    {city: 'Meerbusch', zip: '40668'},
    {city: 'Meerbusch', zip: '40670'},
    {city: 'Meerfeld', zip: '54531'},
    {city: 'Meersburg', zip: '88709'},
    {city: 'Meesiger', zip: '17111'},
    {city: 'Meezen', zip: '24594'},
    {city: 'Megesheim', zip: '86750'},
    {city: 'Meggerdorf', zip: '24799'},
    {city: 'Meggerdorf', zip: '24861'},
    {city: 'Mehlbach', zip: '67735'},
    {city: 'Mehlbek', zip: '25588'},
    {city: 'Mehlingen', zip: '67678'},
    {city: 'Mehlmeisel', zip: '95694'},
    {city: 'Mehmels', zip: '98634'},
    {city: 'Mehna', zip: '04626'},
    {city: 'Mehren', zip: '57635'},
    {city: 'Mehren', zip: '54552'},
    {city: 'Mehring', zip: '84561'},
    {city: 'Mehring', zip: '54346'},
    {city: 'Mehrstetten', zip: '72537'},
    {city: 'Meiersberg', zip: '17375'},
    {city: 'Meinborn', zip: '56584'},
    {city: 'Meine', zip: '38527'},
    {city: 'Meinersen', zip: '38536'},
    {city: 'Meinerzhagen', zip: '58540'},
    {city: 'Meineweh', zip: '06721'},
    {city: 'Meinhard', zip: '37276'},
    {city: 'Meinheim', zip: '91802'},
    {city: 'Meiningen', zip: '98617'},
    {city: 'Meisburg', zip: '54570'},
    {city: 'Meisenheim', zip: '55590'},
    {city: 'Meißen', zip: '01662'},
    {city: 'Meißenheim', zip: '77974'},
    {city: 'Meißner', zip: '37290'},
    {city: 'Meitingen', zip: '86405'},
    {city: 'Melbeck', zip: '21406'},
    {city: 'Melchow', zip: '16230'},
    {city: 'Meldorf', zip: '25704'},
    {city: 'Melle', zip: '49324'},
    {city: 'Melle', zip: '49326'},
    {city: 'Melle', zip: '49328'},
    {city: 'Mellenthin', zip: '17429'},
    {city: 'Mellingen', zip: '99441'},
    {city: 'Mellinghausen', zip: '27249'},
    {city: 'Mellrichstadt', zip: '97638'},
    {city: 'Melsbach', zip: '56581'},
    {city: 'Melsdorf', zip: '24109'},
    {city: 'Melsungen', zip: '34212'},
    {city: 'Melz', zip: '17209'},
    {city: 'Memmelsdorf', zip: '96117'},
    {city: 'Memmingen', zip: '87700'},
    {city: 'Memmingerberg', zip: '87766'},
    {city: 'Menden', zip: '58706'},
    {city: 'Menden', zip: '58708'},
    {city: 'Menden', zip: '58710'},
    {city: 'Mendig', zip: '56743'},
    {city: 'Mengen', zip: '88512'},
    {city: 'Mengerschied', zip: '55490'},
    {city: 'Mengerskirchen', zip: '35794'},
    {city: 'Mengkofen', zip: '84152'},
    {city: 'Menningen', zip: '54310'},
    {city: 'Menslage', zip: '49637'},
    {city: 'Menteroda', zip: '99976'},
    {city: 'Menteroda', zip: '99996'},
    {city: 'Menzendorf', zip: '23923'},
    {city: 'Meppen', zip: '49716'},
    {city: 'Merching', zip: '86504'},
    {city: 'Merchweiler', zip: '66589'},
    {city: 'Merdingen', zip: '79291'},
    {city: 'Merenberg', zip: '35799'},
    {city: 'Mering', zip: '86415'},
    {city: 'Merkelbach', zip: '57629'},
    {city: 'Merkendorf', zip: '91732'},
    {city: 'Merklingen', zip: '89188'},
    {city: 'Merlscheid', zip: '54597'},
    {city: 'Mermuth', zip: '56283'},
    {city: 'Merschbach', zip: '54426'},
    {city: 'Merseburg', zip: '06217'},
    {city: 'Mertendorf', zip: '06618'},
    {city: 'Mertendorf', zip: '07619'},
    {city: 'Mertesdorf', zip: '54318'},
    {city: 'Mertesheim', zip: '67271'},
    {city: 'Mertingen', zip: '86690'},
    {city: 'Mertloch', zip: '56753'},
    {city: 'Merxheim', zip: '55627'},
    {city: 'Merzalben', zip: '66978'},
    {city: 'Merzdorf', zip: '04932'},
    {city: 'Merzen', zip: '49586'},
    {city: 'Merzenich', zip: '52399'},
    {city: 'Merzhausen', zip: '79249'},
    {city: 'Merzig', zip: '66663'},
    {city: 'Merzkirchen', zip: '54439'},
    {city: 'Merzweiler', zip: '67746'},
    {city: 'Meschede', zip: '59872'},
    {city: 'Mescherin', zip: '16307'},
    {city: 'Mesekenhagen', zip: '17498'},
    {city: 'Mesenich', zip: '56820'},
    {city: 'Mespelbrunn', zip: '63875'},
    {city: 'Messel', zip: '64409'},
    {city: 'Messenkamp', zip: '31867'},
    {city: 'Messerich', zip: '54636'},
    {city: 'Messingen', zip: '49832'},
    {city: 'Meßkirch', zip: '88605'},
    {city: 'Meßstetten', zip: '72469'},
    {city: 'Mestlin', zip: '19374'},
    {city: 'Metelen', zip: '48629'},
    {city: 'Metelsdorf', zip: '23972'},
    {city: 'Metten', zip: '94526'},
    {city: 'Mettendorf', zip: '54675'},
    {city: 'Mettenheim', zip: '84562'},
    {city: 'Mettenheim', zip: '67582'},
    {city: 'Metterich', zip: '54634'},
    {city: 'Mettingen', zip: '49497'},
    {city: 'Mettlach', zip: '66693'},
    {city: 'Mettmann', zip: '40822'},
    {city: 'Mettweiler', zip: '55777'},
    {city: 'Metzenhausen', zip: '55481'},
    {city: 'Metzingen', zip: '72555'},
    {city: 'Meudt', zip: '56414'},
    {city: 'Meura', zip: '98744'},
    {city: 'Meusebach', zip: '07646'},
    {city: 'Meuselwitz', zip: '04610'},
    {city: 'Meuspath', zip: '53520'},
    {city: 'Meyenburg', zip: '16945'},
    {city: 'Meyn', zip: '24980'},
    {city: 'Michelau in Oberfranken', zip: '96247'},
    {city: 'Michelau i.Steigerwald', zip: '97513'},
    {city: 'Michelbach', zip: '57610'},
    {city: 'Michelbach', zip: '56288'},
    {city: 'Michelbach an der Bilz', zip: '74544'},
    {city: 'Michelfeld', zip: '74545'},
    {city: 'Michelsneukirchen', zip: '93185'},
    {city: 'Michelstadt', zip: '64720'},
    {city: 'Michendorf', zip: '14552'},
    {city: 'Mickhausen', zip: '86866'},
    {city: 'Midlum', zip: '25938'},
    {city: 'Miehlen', zip: '56357'},
    {city: 'Mielkendorf', zip: '24247'},
    {city: 'Miellen', zip: '56132'},
    {city: 'Miesbach', zip: '83714'},
    {city: 'Miesitz', zip: '07819'},
    {city: 'Mietingen', zip: '88487'},
    {city: 'Milda', zip: '07751'},
    {city: 'Mildenau', zip: '09456'},
    {city: 'Mildstedt', zip: '25866'},
    {city: 'Millienhagen-Oebelitz', zip: '18461'},
    {city: 'Milmersdorf', zip: '17268'},
    {city: 'Milow', zip: '19294'},
    {city: 'Milow', zip: '19300'},
    {city: 'Milower Land', zip: '14715'},
    {city: 'Miltach', zip: '93468'},
    {city: 'Miltenberg', zip: '63897'},
    {city: 'Mindelheim', zip: '87719'},
    {city: 'Mindelstetten', zip: '93349'},
    {city: 'Minden', zip: '32423'},
    {city: 'Minden', zip: '32425'},
    {city: 'Minden', zip: '32427'},
    {city: 'Minden', zip: '32429'},
    {city: 'Minden', zip: '54310'},
    {city: 'Minderlittgen', zip: '54518'},
    {city: 'Minfeld', zip: '76872'},
    {city: 'Minheim', zip: '54518'},
    {city: 'Mintraching', zip: '93098'},
    {city: 'Mirow', zip: '17252'},
    {city: 'Misselberg', zip: '56377'},
    {city: 'Missen-Wilhams', zip: '87547'},
    {city: 'Mistelbach', zip: '95511'},
    {city: 'Mistelgau', zip: '95490'},
    {city: 'Mistorf', zip: '18276'},
    {city: 'Mittelangeln', zip: '24986'},
    {city: 'Mittelbiberach', zip: '88441'},
    {city: 'Mittelbrunn', zip: '66851'},
    {city: 'Mitteleschenbach', zip: '91734'},
    {city: 'Mittelfischbach', zip: '56370'},
    {city: 'Mittelherwigsdorf', zip: '02763'},
    {city: 'Mittelhof', zip: '57537'},
    {city: 'Mittelneufnach', zip: '86868'},
    {city: 'Mittelnkirchen', zip: '21720'},
    {city: 'Mittelpöllnitz', zip: '07819'},
    {city: 'Mittelreidenbach', zip: '55758'},
    {city: 'Mittelsinn', zip: '97785'},
    {city: 'Mittelsömmern', zip: '99955'},
    {city: 'Mittelstenahe', zip: '21770'},
    {city: 'Mittelstetten', zip: '82293'},
    {city: 'Mittelstrimmig', zip: '56858'},
    {city: 'Mittenaar', zip: '35756'},
    {city: 'Mittenwald', zip: '82481'},
    {city: 'Mittenwalde', zip: '15749'},
    {city: 'Mittenwalde', zip: '17268'},
    {city: 'Mitterfels', zip: '94360'},
    {city: 'Mitterskirchen', zip: '84335'},
    {city: 'Mitterteich', zip: '95666'},
    {city: 'Mittweida', zip: '09648'},
    {city: 'Mitwitz', zip: '96268'},
    {city: 'Mixdorf', zip: '15299'},
    {city: 'Möckern', zip: '39279'},
    {city: 'Möckern', zip: '39291'},
    {city: 'Möckern', zip: '07646'},
    {city: 'Möckmühl', zip: '74219'},
    {city: 'Mockrehna', zip: '04862'},
    {city: 'Modautal', zip: '64397'},
    {city: 'Mödingen', zip: '89426'},
    {city: 'Moers', zip: '47441'},
    {city: 'Moers', zip: '47443'},
    {city: 'Moers', zip: '47445'},
    {city: 'Moers', zip: '47447'},
    {city: 'Mogendorf', zip: '56424'},
    {city: 'Mögglingen', zip: '73563'},
    {city: 'Möglingen', zip: '71696'},
    {city: 'Mohlsdorf-Teichwolframsdorf', zip: '07987'},
    {city: 'Möhnesee', zip: '59519'},
    {city: 'Möhnsen', zip: '21493'},
    {city: 'Möhrendorf', zip: '91096'},
    {city: 'Mohrkirch', zip: '24392'},
    {city: 'Mohrkirch', zip: '24405'},
    {city: 'Mohrkirch', zip: '24891'},
    {city: 'Moisburg', zip: '21647'},
    {city: 'Molauer Land', zip: '06618'},
    {city: 'Molbergen', zip: '49696'},
    {city: 'Molfsee', zip: '24113'},
    {city: 'Möllenbeck', zip: '17237'},
    {city: 'Möllenbeck', zip: '19300'},
    {city: 'Möllenhagen', zip: '17219'},
    {city: 'Mölln', zip: '17091'},
    {city: 'Mölln', zip: '23879'},
    {city: 'Molsberg', zip: '56414'},
    {city: 'Molschleben', zip: '99869'},
    {city: 'Mölschow', zip: '17449'},
    {city: 'Mölsheim', zip: '67591'},
    {city: 'Moltzow', zip: '17194'},
    {city: 'Molzhain', zip: '57520'},
    {city: 'Mömbris', zip: '63776'},
    {city: 'Mömlingen', zip: '63853'},
    {city: 'Mommenheim', zip: '55278'},
    {city: 'Mönchberg', zip: '63933'},
    {city: 'Mönchengladbach', zip: '41061'},
    {city: 'Mönchengladbach', zip: '41063'},
    {city: 'Mönchengladbach', zip: '41065'},
    {city: 'Mönchengladbach', zip: '41066'},
    {city: 'Mönchengladbach', zip: '41068'},
    {city: 'Mönchengladbach', zip: '41069'},
    {city: 'Mönchengladbach', zip: '41169'},
    {city: 'Mönchengladbach', zip: '41179'},
    {city: 'Mönchengladbach', zip: '41189'},
    {city: 'Mönchengladbach', zip: '41199'},
    {city: 'Mönchengladbach', zip: '41236'},
    {city: 'Mönchengladbach', zip: '41238'},
    {city: 'Mönchengladbach', zip: '41239'},
    {city: 'Mönchgut', zip: '18586'},
    {city: 'Mönchhagen', zip: '18182'},
    {city: 'Mönchpfiffel-Nikolausrieth', zip: '06556'},
    {city: 'Mönchsdeggingen', zip: '86751'},
    {city: 'Mönchsroth', zip: '91614'},
    {city: 'Mönchweiler', zip: '78087'},
    {city: 'Monheim', zip: '86653'},
    {city: 'Monheim am Rhein', zip: '40789'},
    {city: 'Mönkeberg', zip: '24248'},
    {city: 'Mönkebude', zip: '17375'},
    {city: 'Mönkhagen', zip: '23619'},
    {city: 'Mönkloh', zip: '24576'},
    {city: 'Monreal', zip: '56729'},
    {city: 'Monschau', zip: '52156'},
    {city: 'Monsheim', zip: '67590'},
    {city: 'Mönsheim', zip: '71297'},
    {city: 'Monstab', zip: '04617'},
    {city: 'Montabaur', zip: '56410'},
    {city: 'Möntenich', zip: '56754'},
    {city: 'Monzelfeld', zip: '54472'},
    {city: 'Monzernheim', zip: '55234'},
    {city: 'Monzingen', zip: '55569'},
    {city: 'Moordiek', zip: '25597'},
    {city: 'Moorenweis', zip: '82272'},
    {city: 'Moorgrund', zip: '36433'},
    {city: 'Moorhusen', zip: '25554'},
    {city: 'Moormerland', zip: '26802'},
    {city: 'Moorrege', zip: '25436'},
    {city: 'Moorweg', zip: '26427'},
    {city: 'Moos', zip: '94554'},
    {city: 'Moos', zip: '78345'},
    {city: 'Moosach', zip: '85665'},
    {city: 'Moosbach', zip: '92709'},
    {city: 'Moosburg', zip: '85368'},
    {city: 'Moosburg', zip: '88422'},
    {city: 'Moosinning', zip: '85452'},
    {city: 'Moosthenning', zip: '84164'},
    {city: 'Moraas', zip: '19230'},
    {city: 'Morbach', zip: '54497'},
    {city: 'Mörel', zip: '24594'},
    {city: 'Mörfelden-Walldorf', zip: '64546'},
    {city: 'Moringen', zip: '37186'},
    {city: 'Moritzburg', zip: '01468'},
    {city: 'Moritzheim', zip: '56865'},
    {city: 'Mörlen', zip: '57583'},
    {city: 'Mörlenbach', zip: '69509'},
    {city: 'Mörnsheim', zip: '91804'},
    {city: 'Morsbach', zip: '51597'},
    {city: 'Mörsbach', zip: '57629'},
    {city: 'Mörschbach', zip: '55494'},
    {city: 'Morscheid', zip: '54317'},
    {city: 'Morschen', zip: '34326'},
    {city: 'Morschheim', zip: '67294'},
    {city: 'Mörschied', zip: '55758'},
    {city: 'Mörsdorf', zip: '56290'},
    {city: 'Mörsdorf', zip: '07646'},
    {city: 'Mörsfeld', zip: '67808'},
    {city: 'Morshausen', zip: '56283'},
    {city: 'Mörstadt', zip: '67591'},
    {city: 'Mosbach', zip: '74821'},
    {city: 'Mosbruch', zip: '56767'},
    {city: 'Moschheim', zip: '56424'},
    {city: 'Moselkern', zip: '56254'},
    {city: 'Möser', zip: '39175'},
    {city: 'Möser', zip: '39291'},
    {city: 'Mossautal', zip: '64756'},
    {city: 'Moßbach', zip: '07907'},
    {city: 'Mössingen', zip: '72116'},
    {city: 'Motten', zip: '97786'},
    {city: 'Möttingen', zip: '86753'},
    {city: 'Mötzing', zip: '93099'},
    {city: 'Mötzingen', zip: '71159'},
    {city: 'Moxa', zip: '07381'},
    {city: 'Mözen', zip: '23795'},
    {city: 'Much', zip: '53804'},
    {city: 'Mucheln', zip: '24238'},
    {city: 'Mücheln (Geiseltal)', zip: '06249'},
    {city: 'Mücheln (Geiseltal)', zip: '06255'},
    {city: 'Mücheln (Geiseltal)', zip: '06268'},
    {city: 'Mücheln (Geiseltal)', zip: '06632'},
    {city: 'Muchow', zip: '19300'},
    {city: 'Mücka', zip: '02906'},
    {city: 'Mücke', zip: '35325'},
    {city: 'Mückeln', zip: '54558'},
    {city: 'Mudau', zip: '69427'},
    {city: 'Müden (Aller)', zip: '38539'},
    {city: 'Mudenbach', zip: '57614'},
    {city: 'Müden (Mosel)', zip: '56254'},
    {city: 'Mudersbach', zip: '57555'},
    {city: 'Mudershausen', zip: '65623'},
    {city: 'Mügeln', zip: '04769'},
    {city: 'Muggensturm', zip: '76461'},
    {city: 'Müglitztal', zip: '01809'},
    {city: 'Mühbrook', zip: '24582'},
    {city: 'Mühlacker', zip: '75417'},
    {city: 'Mühlau', zip: '09241'},
    {city: 'Mühlberg/Elbe', zip: '04895'},
    {city: 'Mühlberg/Elbe', zip: '04931'},
    {city: 'Mühldorf a. Inn', zip: '84453'},
    {city: 'Mühlenbach', zip: '77796'},
    {city: 'Mühlenbarbek', zip: '25548'},
    {city: 'Mühlenbecker Land', zip: '16515'},
    {city: 'Mühlenbecker Land', zip: '16552'},
    {city: 'Mühlenbecker Land', zip: '16567'},
    {city: 'Mühlenberge', zip: '14662'},
    {city: 'Mühlen Eichsen', zip: '19205'},
    {city: 'Mühlenfließ', zip: '14822'},
    {city: 'Mühlenfließ', zip: '14823'},
    {city: 'Mühlenrade', zip: '21493'},
    {city: 'Mühlental', zip: '08626'},
    {city: 'Mühlhausen', zip: '69242'},
    {city: 'Mühlhausen', zip: '96172'},
    {city: 'Mühlhausen', zip: '92360'},
    {city: 'Mühlhausen', zip: '99974'},
    {city: 'Mühlhausen', zip: '99998'},
    {city: 'Mühlhausen-Ehingen', zip: '78259'},
    {city: 'Mühlhausen im Täle', zip: '73347'},
    {city: 'Mühlheim am Main', zip: '63165'},
    {city: 'Mühlheim an der Donau', zip: '78570'},
    {city: 'Mühlingen', zip: '78357'},
    {city: 'Mühlpfad', zip: '56291'},
    {city: 'Mühl Rosin', zip: '18276'},
    {city: 'Mühltal', zip: '64367'},
    {city: 'Muhr am See', zip: '91735'},
    {city: 'Mülbach', zip: '54636'},
    {city: 'Mulda/Sachsen', zip: '09619'},
    {city: 'Muldenhammer', zip: '08262'},
    {city: 'Muldestausee', zip: '06774'},
    {city: 'Mulfingen', zip: '74673'},
    {city: 'Mülheim an der Mosel', zip: '54486'},
    {city: 'Mülheim an der Ruhr', zip: '45468'},
    {city: 'Mülheim an der Ruhr', zip: '45470'},
    {city: 'Mülheim an der Ruhr', zip: '45472'},
    {city: 'Mülheim an der Ruhr', zip: '45473'},
    {city: 'Mülheim an der Ruhr', zip: '45475'},
    {city: 'Mülheim an der Ruhr', zip: '45476'},
    {city: 'Mülheim an der Ruhr', zip: '45478'},
    {city: 'Mülheim an der Ruhr', zip: '45479'},
    {city: 'Mülheim an der Ruhr', zip: '45481'},
    {city: 'Mülheim-Kärlich', zip: '56218'},
    {city: 'Müllenbach', zip: '53520'},
    {city: 'Müllenbach', zip: '56761'},
    {city: 'Müllheim', zip: '79379'},
    {city: 'Müllrose', zip: '15299'},
    {city: 'Mülsen', zip: '08132'},
    {city: 'Münchberg', zip: '95213'},
    {city: 'Müncheberg', zip: '15374'},
    {city: 'Münchehofe', zip: '15748'},
    {city: 'München', zip: '80331'},
    {city: 'München', zip: '80333'},
    {city: 'München', zip: '80335'},
    {city: 'München', zip: '80336'},
    {city: 'München', zip: '80337'},
    {city: 'München', zip: '80339'},
    {city: 'München', zip: '80469'},
    {city: 'München', zip: '80538'},
    {city: 'München', zip: '80539'},
    {city: 'München', zip: '80634'},
    {city: 'München', zip: '80636'},
    {city: 'München', zip: '80637'},
    {city: 'München', zip: '80638'},
    {city: 'München', zip: '80639'},
    {city: 'München', zip: '80686'},
    {city: 'München', zip: '80687'},
    {city: 'München', zip: '80689'},
    {city: 'München', zip: '80796'},
    {city: 'München', zip: '80797'},
    {city: 'München', zip: '80798'},
    {city: 'München', zip: '80799'},
    {city: 'München', zip: '80801'},
    {city: 'München', zip: '80802'},
    {city: 'München', zip: '80803'},
    {city: 'München', zip: '80804'},
    {city: 'München', zip: '80805'},
    {city: 'München', zip: '80807'},
    {city: 'München', zip: '80809'},
    {city: 'München', zip: '80933'},
    {city: 'München', zip: '80935'},
    {city: 'München', zip: '80937'},
    {city: 'München', zip: '80939'},
    {city: 'München', zip: '80992'},
    {city: 'München', zip: '80993'},
    {city: 'München', zip: '80995'},
    {city: 'München', zip: '80997'},
    {city: 'München', zip: '80999'},
    {city: 'München', zip: '81241'},
    {city: 'München', zip: '81243'},
    {city: 'München', zip: '81245'},
    {city: 'München', zip: '81247'},
    {city: 'München', zip: '81249'},
    {city: 'München', zip: '81369'},
    {city: 'München', zip: '81371'},
    {city: 'München', zip: '81373'},
    {city: 'München', zip: '81375'},
    {city: 'München', zip: '81377'},
    {city: 'München', zip: '81379'},
    {city: 'München', zip: '81475'},
    {city: 'München', zip: '81476'},
    {city: 'München', zip: '81477'},
    {city: 'München', zip: '81479'},
    {city: 'München', zip: '81539'},
    {city: 'München', zip: '81541'},
    {city: 'München', zip: '81543'},
    {city: 'München', zip: '81545'},
    {city: 'München', zip: '81547'},
    {city: 'München', zip: '81549'},
    {city: 'München', zip: '81667'},
    {city: 'München', zip: '81669'},
    {city: 'München', zip: '81671'},
    {city: 'München', zip: '81673'},
    {city: 'München', zip: '81675'},
    {city: 'München', zip: '81677'},
    {city: 'München', zip: '81679'},
    {city: 'München', zip: '81735'},
    {city: 'München', zip: '81737'},
    {city: 'München', zip: '81739'},
    {city: 'München', zip: '81825'},
    {city: 'München', zip: '81827'},
    {city: 'München', zip: '81829'},
    {city: 'München', zip: '81925'},
    {city: 'München', zip: '81927'},
    {city: 'München', zip: '81929'},
    {city: 'München', zip: '85540'},
    {city: 'Münchenbernsdorf', zip: '07589'},
    {city: 'Münchhausen', zip: '35117'},
    {city: 'Münchsmünster', zip: '85126'},
    {city: 'Münchsteinach', zip: '91481'},
    {city: 'Münchwald', zip: '55595'},
    {city: 'Münchweiler am Klingbach', zip: '76857'},
    {city: 'Münchweiler an der Alsenz', zip: '67728'},
    {city: 'Münchweiler an der Rodalb', zip: '66955'},
    {city: 'Münchweiler an der Rodalb', zip: '66981'},
    {city: 'Mundelsheim', zip: '74395'},
    {city: 'Munderkingen', zip: '89597'},
    {city: 'Mündersbach', zip: '56271'},
    {city: 'Münk', zip: '56729'},
    {city: 'Munkbrarup', zip: '24960'},
    {city: 'Münnerstadt', zip: '97702'},
    {city: 'Munningen', zip: '86754'},
    {city: 'Münsing', zip: '82541'},
    {city: 'Münsingen', zip: '72525'},
    {city: 'Munster', zip: '29633'},
    {city: 'Münster', zip: '48143'},
    {city: 'Münster', zip: '48145'},
    {city: 'Münster', zip: '48147'},
    {city: 'Münster', zip: '48149'},
    {city: 'Münster', zip: '48151'},
    {city: 'Münster', zip: '48153'},
    {city: 'Münster', zip: '48155'},
    {city: 'Münster', zip: '48157'},
    {city: 'Münster', zip: '48159'},
    {city: 'Münster', zip: '48161'},
    {city: 'Münster', zip: '48163'},
    {city: 'Münster', zip: '48165'},
    {city: 'Münster', zip: '48167'},
    {city: 'Münster', zip: '86692'},
    {city: 'Münsterappel', zip: '67822'},
    {city: 'Münsterdorf', zip: '25587'},
    {city: 'Münsterhausen', zip: '86505'},
    {city: 'Münster (Hessen)', zip: '64839'},
    {city: 'Münstermaifeld', zip: '56294'},
    {city: 'Münster-Sarmsheim', zip: '55424'},
    {city: 'Münstertal/Schwarzwald', zip: '79244'},
    {city: 'Münzenberg', zip: '35516'},
    {city: 'Murchin', zip: '17390'},
    {city: 'Murg', zip: '79730'},
    {city: 'Mürlenbach', zip: '54570'},
    {city: 'Murnau am Staffelsee', zip: '82418'},
    {city: 'Murr', zip: '71711'},
    {city: 'Murrhardt', zip: '71540'},
    {city: 'Müsch', zip: '53533'},
    {city: 'Müschenbach', zip: '57629'},
    {city: 'Müssen', zip: '21516'},
    {city: 'Mustin', zip: '23911'},
    {city: 'Mustin', zip: '19406'},
    {city: 'Musweiler', zip: '54534'},
    {city: 'Mutlangen', zip: '73557'},
    {city: 'Mutterschied', zip: '55469'},
    {city: 'Mutterstadt', zip: '67112'},
    {city: 'Mützenich', zip: '54608'},
    {city: 'Muxerath', zip: '54673'},
    {city: 'Nabburg', zip: '92507'},
    {city: 'Nachrodt-Wiblingwerde', zip: '58769'},
    {city: 'Nachtsheim', zip: '56729'},
    {city: 'Nack', zip: '55234'},
    {city: 'Nackenheim', zip: '55299'},
    {city: 'Nadrensee', zip: '17329'},
    {city: 'Nagel', zip: '95697'},
    {city: 'Nagold', zip: '72202'},
    {city: 'Nahe', zip: '23866'},
    {city: 'Nahrendorf', zip: '21369'},
    {city: 'Naila', zip: '95119'},
    {city: 'Nalbach', zip: '66809'},
    {city: 'Namborn', zip: '66640'},
    {city: 'Nandlstadt', zip: '85395'},
    {city: 'Nandlstadt', zip: '85405'},
    {city: 'Nannhausen', zip: '55469'},
    {city: 'Nanzdietschweiler', zip: '66909'},
    {city: 'Nasingen', zip: '54673'},
    {city: 'Nassau', zip: '56377'},
    {city: 'Nassenfels', zip: '85128'},
    {city: 'Nastätten', zip: '56355'},
    {city: 'Natendorf', zip: '29587'},
    {city: 'Nattenheim', zip: '54636'},
    {city: 'Nattheim', zip: '89564'},
    {city: 'Nauen', zip: '14641'},
    {city: 'Nauendorf', zip: '99448'},
    {city: 'Nauheim', zip: '64569'},
    {city: 'Naumburg', zip: '34311'},
    {city: 'Naumburg (Saale)', zip: '06618'},
    {city: 'Naumburg (Saale)', zip: '06628'},
    {city: 'Naundorf', zip: '04758'},
    {city: 'Naundorf', zip: '04769'},
    {city: 'Naunheim', zip: '56753'},
    {city: 'Naunhof', zip: '04683'},
    {city: 'Nauort', zip: '56237'},
    {city: 'Naurath (Eifel)', zip: '54340'},
    {city: 'Naurath (Wald)', zip: '54426'},
    {city: 'Nauroth', zip: '57583'},
    {city: 'Nausnitz', zip: '07616'},
    {city: 'Nazza', zip: '99826'},
    {city: 'Nebel', zip: '25946'},
    {city: 'Nebelschütz', zip: '01920'},
    {city: 'Nebra (Unstrut)', zip: '06642'},
    {city: 'Neckarbischofsheim', zip: '74924'},
    {city: 'Neckargemünd', zip: '69151'},
    {city: 'Neckargerach', zip: '69437'},
    {city: 'Neckarsteinach', zip: '69239'},
    {city: 'Neckarsulm', zip: '74172'},
    {city: 'Neckartailfingen', zip: '72666'},
    {city: 'Neckartenzlingen', zip: '72654'},
    {city: 'Neckarwestheim', zip: '74382'},
    {city: 'Neckarzimmern', zip: '74865'},
    {city: 'Neddemin', zip: '17039'},
    {city: 'Neef', zip: '56858'},
    {city: 'Neenstetten', zip: '89189'},
    {city: 'Neetze', zip: '21398'},
    {city: 'Neetzka', zip: '17349'},
    {city: 'Neetzow-Liepen', zip: '17391'},
    {city: 'Negenborn', zip: '37643'},
    {city: 'Negenharrie', zip: '24625'},
    {city: 'Negernbötel', zip: '23795'},
    {city: 'Nehms', zip: '23813'},
    {city: 'Nehmten', zip: '24326'},
    {city: 'Nehren', zip: '56820'},
    {city: 'Nehren', zip: '72147'},
    {city: 'Neichen', zip: '54552'},
    {city: 'Neidenbach', zip: '54657'},
    {city: 'Neidenfels', zip: '67468'},
    {city: 'Neidenstein', zip: '74933'},
    {city: 'Neidlingen', zip: '73272'},
    {city: 'Neißeaue', zip: '02829'},
    {city: 'Neiße-Malxetal', zip: '03159'},
    {city: 'Neißemünde', zip: '15898'},
    {city: 'Neitersen', zip: '57638'},
    {city: 'Nellingen', zip: '89191'},
    {city: 'Nemsdorf-Göhrendorf', zip: '06268'},
    {city: 'Nenndorf', zip: '26556'},
    {city: 'Nennhausen', zip: '14715'},
    {city: 'Nennslingen', zip: '91790'},
    {city: 'Nentershausen', zip: '36214'},
    {city: 'Nentershausen', zip: '56412'},
    {city: 'Nerdlen', zip: '54552'},
    {city: 'Nerenstetten', zip: '89129'},
    {city: 'Neresheim', zip: '73450'},
    {city: 'Neritz', zip: '23843'},
    {city: 'Neroth', zip: '54570'},
    {city: 'Nersingen', zip: '89278'},
    {city: 'Nerzweiler', zip: '67749'},
    {city: 'Neschwitz', zip: '02699'},
    {city: 'Nesse-Apfelstädt', zip: '99192'},
    {city: 'Nesselwang', zip: '87484'},
    {city: 'Nessetal', zip: '99869'},
    {city: 'Netphen', zip: '57250'},
    {city: 'Nettelsee', zip: '24250'},
    {city: 'Nettersheim', zip: '53947'},
    {city: 'Nettetal', zip: '41334'},
    {city: 'Netzbach', zip: '65623'},
    {city: 'Netzschkau', zip: '08491'},
    {city: 'Neualbenreuth', zip: '95698'},
    {city: 'Neu-Anspach', zip: '61267'},
    {city: 'Neu-Bamberg', zip: '55546'},
    {city: 'Neuberend', zip: '24879'},
    {city: 'Neuberg', zip: '63543'},
    {city: 'Neubeuern', zip: '83115'},
    {city: 'Neubiberg', zip: '85579'},
    {city: 'Neu Boltenhagen', zip: '17509'},
    {city: 'Neubörger', zip: '26909'},
    {city: 'Neubrandenburg', zip: '17033'},
    {city: 'Neubrandenburg', zip: '17034'},
    {city: 'Neubrandenburg', zip: '17036'},
    {city: 'Neubrunn', zip: '97277'},
    {city: 'Neubrunn', zip: '98617'},
    {city: 'Neubukow', zip: '18233'},
    {city: 'Neubulach', zip: '75385'},
    {city: 'Neubulach', zip: '75387'},
    {city: 'Neuburg', zip: '23974'},
    {city: 'Neuburg am Inn', zip: '94036'},
    {city: 'Neuburg am Inn', zip: '94127'},
    {city: 'Neuburg am Rhein', zip: '76776'},
    {city: 'Neuburg an der Donau', zip: '86633'},
    {city: 'Neuburg an der Kammel', zip: '86476'},
    {city: 'Neuching', zip: '85467'},
    {city: 'Neu Darchau', zip: '29490'},
    {city: 'Neudenau', zip: '74861'},
    {city: 'Neudorf-Bornstein', zip: '24214'},
    {city: 'Neudrossenfeld', zip: '95512'},
    {city: 'Neu Duvenstedt', zip: '24791'},
    {city: 'Neu Duvenstedt', zip: '24794'},
    {city: 'Neu-Eichenberg', zip: '37249'},
    {city: 'Neuenbrook', zip: '25361'},
    {city: 'Neuenbrook', zip: '25578'},
    {city: 'Neuenbürg', zip: '75305'},
    {city: 'Neuenburg am Rhein', zip: '79395'},
    {city: 'Neuendeich', zip: '25436'},
    {city: 'Neuendettelsau', zip: '91564'},
    {city: 'Neuendorf', zip: '97788'},
    {city: 'Neuendorf', zip: '54597'},
    {city: 'Neuendorf bei Elmshorn', zip: '25335'},
    {city: 'Neuendorf-Sachsenbande', zip: '25554'},
    {city: 'Neuengönna', zip: '07778'},
    {city: 'Neuengörs', zip: '23818'},
    {city: 'Neuenhagen bei Berlin', zip: '15366'},
    {city: 'Neuenhaus', zip: '49828'},
    {city: 'Neuenkirchen', zip: '48485'},
    {city: 'Neuenkirchen', zip: '25792'},
    {city: 'Neuenkirchen', zip: '21640'},
    {city: 'Neuenkirchen', zip: '17498'},
    {city: 'Neuenkirchen', zip: '17039'},
    {city: 'Neuenkirchen', zip: '17392'},
    {city: 'Neuenkirchen', zip: '18569'},
    {city: 'Neuenkirchen', zip: '27251'},
    {city: 'Neuenkirchen', zip: '21763'},
    {city: 'Neuenkirchen', zip: '29643'},
    {city: 'Neuenkirchen', zip: '49586'},
    {city: 'Neuenkirchen-Vörden', zip: '49434'},
    {city: 'Neuenmarkt', zip: '95339'},
    {city: 'Neuenrade', zip: '58809'},
    {city: 'Neuensalz', zip: '08541'},
    {city: 'Neuenstadt am Kocher', zip: '74196'},
    {city: 'Neuenstein', zip: '36286'},
    {city: 'Neuenstein', zip: '74632'},
    {city: 'Neuental', zip: '34599'},
    {city: 'Neuerburg', zip: '54673'},
    {city: 'Neuerkirch', zip: '55471'},
    {city: 'Neufahrn bei Freising', zip: '85375'},
    {city: 'Neufahrn bei Freising', zip: '85376'},
    {city: 'Neufahrn in Niederbayern', zip: '84088'},
    {city: 'Neufeld', zip: '25724'},
    {city: 'Neufelderkoog', zip: '25724'},
    {city: 'Neuffen', zip: '72639'},
    {city: 'Neufra', zip: '72419'},
    {city: 'Neufraunhofen', zip: '84181'},
    {city: 'Neu Gülze', zip: '19258'},
    {city: 'Neuhardenberg', zip: '15320'},
    {city: 'Neuharlingersiel', zip: '26427'},
    {city: 'Neuhaus', zip: '21785'},
    {city: 'Neuhaus a.d.Pegnitz', zip: '91284'},
    {city: 'Neuhaus am Inn', zip: '94152'},
    {city: 'Neuhaus am Rennweg', zip: '98724'},
    {city: 'Neuhäusel', zip: '56335'},
    {city: 'Neuhausen', zip: '75242'},
    {city: 'Neuhausen auf den Fildern', zip: '73765'},
    {city: 'Neuhausen/Erzgebirge', zip: '09544'},
    {city: 'Neuhausen ob Eck', zip: '78579'},
    {city: 'Neuhausen/Spree', zip: '03058'},
    {city: 'Neuheilenbach', zip: '54597'},
    {city: 'Neuhemsbach', zip: '67680'},
    {city: 'Neuhof', zip: '36119'},
    {city: 'Neuhof a.d.Zenn', zip: '90616'},
    {city: 'Neuhofen', zip: '67141'},
    {city: 'Neuhütten', zip: '97843'},
    {city: 'Neuhütten', zip: '54422'},
    {city: 'Neu-Isenburg', zip: '63263'},
    {city: 'Neukalen', zip: '17154'},
    {city: 'Neu Kaliß', zip: '19294'},
    {city: 'Neukamperfehn', zip: '26835'},
    {city: 'Neukieritzsch', zip: '04575'},
    {city: 'Neukirch', zip: '01936'},
    {city: 'Neukirch', zip: '88099'},
    {city: 'Neukirchen', zip: '23779'},
    {city: 'Neukirchen', zip: '34626'},
    {city: 'Neukirchen', zip: '94362'},
    {city: 'Neukirchen', zip: '25927'},
    {city: 'Neukirchen-Balbini', zip: '92445'},
    {city: 'Neukirchen beim Heiligen Blut', zip: '93453'},
    {city: 'Neukirchen bei Sulzbach-Rosenberg', zip: '92259'},
    {city: 'Neukirchen/Erzgebirge', zip: '09221'},
    {city: 'Neukirchen/Pleiße', zip: '08459'},
    {city: 'Neukirchen-Vluyn', zip: '47506'},
    {city: 'Neukirchen vorm Wald', zip: '94154'},
    {city: 'Neukirch/Lausitz', zip: '01904'},
    {city: 'Neukloster', zip: '23992'},
    {city: 'Neu Kosenow', zip: '17398'},
    {city: 'Neulehe', zip: '26909'},
    {city: 'Neuleiningen', zip: '67271'},
    {city: 'Neuler', zip: '73491'},
    {city: 'Neulewin', zip: '16259'},
    {city: 'Neulingen', zip: '75245'},
    {city: 'Neulußheim', zip: '68809'},
    {city: 'Neumagen-Dhron', zip: '54347'},
    {city: 'Neumark', zip: '08496'},
    {city: 'Neumark', zip: '99439'},
    {city: 'Neumarkt in der Oberpfalz', zip: '92318'},
    {city: 'Neumarkt in der Oberpfalz', zip: '92348'},
    {city: 'Neumarkt-Sankt Veit', zip: '84494'},
    {city: 'Neumünster', zip: '24534'},
    {city: 'Neumünster', zip: '24536'},
    {city: 'Neumünster', zip: '24537'},
    {city: 'Neumünster', zip: '24539'},
    {city: 'Neunburg vorm Wald', zip: '92431'},
    {city: 'Neundorf', zip: '07924'},
    {city: 'Neunkhausen', zip: '57520'},
    {city: 'Neunkirchen', zip: '57290'},
    {city: 'Neunkirchen', zip: '63930'},
    {city: 'Neunkirchen', zip: '74867'},
    {city: 'Neunkirchen', zip: '56479'},
    {city: 'Neunkirchen', zip: '66538'},
    {city: 'Neunkirchen', zip: '66539'},
    {city: 'Neunkirchen', zip: '66540'},
    {city: 'Neunkirchen', zip: '54426'},
    {city: 'Neunkirchen a.Brand', zip: '91077'},
    {city: 'Neunkirchen am Potzberg', zip: '66887'},
    {city: 'Neunkirchen a. Sand', zip: '91233'},
    {city: 'Neunkirchen-Seelscheid', zip: '53819'},
    {city: 'Neuötting', zip: '84524'},
    {city: 'Neupetershain', zip: '03103'},
    {city: 'Neu Poserin', zip: '19399'},
    {city: 'Neupotz', zip: '76777'},
    {city: 'Neureichenau', zip: '94089'},
    {city: 'Neuried', zip: '82061'},
    {city: 'Neuried', zip: '82131'},
    {city: 'Neuried', zip: '77743'},
    {city: 'Neuruppin', zip: '16816'},
    {city: 'Neuruppin', zip: '16818'},
    {city: 'Neuruppin', zip: '16827'},
    {city: 'Neuruppin', zip: '16833'},
    {city: 'Neuruppin', zip: '16835'},
    {city: 'Neusalza-Spremberg', zip: '02742'},
    {city: 'Neusäß', zip: '86356'},
    {city: 'Neuschönau', zip: '94556'},
    {city: 'Neuschoo', zip: '26487'},
    {city: 'Neu-Seeland', zip: '03103'},
    {city: 'Neusitz', zip: '91616'},
    {city: 'Neusorg', zip: '95700'},
    {city: 'Neuss', zip: '41460'},
    {city: 'Neuss', zip: '41462'},
    {city: 'Neuss', zip: '41464'},
    {city: 'Neuss', zip: '41466'},
    {city: 'Neuss', zip: '41468'},
    {city: 'Neuss', zip: '41469'},
    {city: 'Neuss', zip: '41470'},
    {city: 'Neuss', zip: '41472'},
    {city: 'Neustadt', zip: '35279'},
    {city: 'Neustadt a.Main', zip: '97845'},
    {city: 'Neustadt am Kulm', zip: '95514'},
    {city: 'Neustadt am Rübenberge', zip: '31535'},
    {city: 'Neustadt an der Aisch', zip: '91413'},
    {city: 'Neustadt an der Donau', zip: '93333'},
    {city: 'Neustadt an der Orla', zip: '07806'},
    {city: 'Neustadt an der Waldnaab', zip: '92660'},
    {city: 'Neustadt an der Weinstraße', zip: '67433'},
    {city: 'Neustadt an der Weinstraße', zip: '67434'},
    {city: 'Neustadt an der Weinstraße', zip: '67435'},
    {city: 'Neustadt bei Coburg', zip: '96465'},
    {city: 'Neustadt (Dosse)', zip: '16845'},
    {city: 'Neustadt-Glewe', zip: '19306'},
    {city: 'Neustadt in Holstein', zip: '23730'},
    {city: 'Neustadt in Sachsen', zip: '01844'},
    {city: 'Neustadt/Vogtland', zip: '08223'},
    {city: 'Neustadt/ Westerwald', zip: '56479'},
    {city: 'Neustadt (Wied)', zip: '53577'},
    {city: 'Neustetten', zip: '72149'},
    {city: 'Neustrelitz', zip: '17235'},
    {city: 'Neutraubling', zip: '93073'},
    {city: 'Neutrebbin', zip: '15320'},
    {city: 'Neu-Ulm', zip: '89231'},
    {city: 'Neu-Ulm', zip: '89233'},
    {city: 'Neuweiler', zip: '75389'},
    {city: 'Neuwied', zip: '56564'},
    {city: 'Neuwied', zip: '56566'},
    {city: 'Neuwied', zip: '56567'},
    {city: 'Neuwittenbek', zip: '24161'},
    {city: 'Neuwittenbek', zip: '24214'},
    {city: 'Neu Wulmstorf', zip: '21629'},
    {city: 'Neu Zauche', zip: '15913'},
    {city: 'Neuzelle', zip: '15898'},
    {city: 'Neverin', zip: '17039'},
    {city: 'Neversdorf', zip: '23816'},
    {city: 'Newel', zip: '54309'},
    {city: 'Ney', zip: '56283'},
    {city: 'Nickenich', zip: '56645'},
    {city: 'Nidda', zip: '63667'},
    {city: 'Niddatal', zip: '61194'},
    {city: 'Nidderau', zip: '61130'},
    {city: 'Nideggen', zip: '52385'},
    {city: 'Nieblum', zip: '25938'},
    {city: 'Niebüll', zip: '25899'},
    {city: 'Nieby', zip: '24395'},
    {city: 'Nieden', zip: '17309'},
    {city: 'Niedenstein', zip: '34305'},
    {city: 'Niederahr', zip: '56414'},
    {city: 'Niederaichbach', zip: '84100'},
    {city: 'Niederalben', zip: '66887'},
    {city: 'Niederalteich', zip: '94557'},
    {city: 'Niederau', zip: '01689'},
    {city: 'Niederaula', zip: '36272'},
    {city: 'Niederbachheim', zip: '56357'},
    {city: 'Niederbergkirchen', zip: '84494'},
    {city: 'Niederbösa', zip: '99718'},
    {city: 'Niederbreitbach', zip: '56589'},
    {city: 'Niederbrombach', zip: '55767'},
    {city: 'Niederburg', zip: '55432'},
    {city: 'Niederdorf', zip: '09366'},
    {city: 'Niederdorfelden', zip: '61138'},
    {city: 'Niederdreisbach', zip: '57520'},
    {city: 'Niederdürenbach', zip: '56651'},
    {city: 'Niedere Börde', zip: '39326'},
    {city: 'Niedere Börde', zip: '39345'},
    {city: 'Niederelbert', zip: '56412'},
    {city: 'Niedererbach', zip: '56412'},
    {city: 'Niederer Fläming', zip: '14913'},
    {city: 'Niedereschach', zip: '78078'},
    {city: 'Niederfell', zip: '56332'},
    {city: 'Niederfinow', zip: '16248'},
    {city: 'Niederfischbach', zip: '57572'},
    {city: 'Niederfrohna', zip: '09243'},
    {city: 'Niederfüllbach', zip: '96489'},
    {city: 'Niedergebra', zip: '99759'},
    {city: 'Niedergeckler', zip: '54675'},
    {city: 'Niedergörsdorf', zip: '14913'},
    {city: 'Niederhambach', zip: '55767'},
    {city: 'Niederhausen', zip: '55585'},
    {city: 'Niederhausen an der Appel', zip: '67822'},
    {city: 'Niederheimbach', zip: '55413'},
    {city: 'Nieder-Hilbersheim', zip: '55437'},
    {city: 'Niederhofen', zip: '56316'},
    {city: 'Niederhorbach', zip: '76889'},
    {city: 'Niederhosenbach', zip: '55758'},
    {city: 'Niederirsen', zip: '57589'},
    {city: 'Niederkassel', zip: '53859'},
    {city: 'Niederkirchen', zip: '67700'},
    {city: 'Niederkirchen bei Deidesheim', zip: '67150'},
    {city: 'Nieder Kostenz', zip: '55481'},
    {city: 'Niederkrüchten', zip: '41372'},
    {city: 'Niederkumbd', zip: '55469'},
    {city: 'Niederlangen', zip: '49779'},
    {city: 'Niederlauch', zip: '54614'},
    {city: 'Niederlauer', zip: '97618'},
    {city: 'Niedermohr', zip: '66879'},
    {city: 'Niedermoschel', zip: '67822'},
    {city: 'Niedermurach', zip: '92545'},
    {city: 'Niedernberg', zip: '63843'},
    {city: 'Niederneisen', zip: '65629'},
    {city: 'Niedernhall', zip: '74676'},
    {city: 'Niedernhausen', zip: '65527'},
    {city: 'Niedernwöhren', zip: '31712'},
    {city: 'Niederöfflingen', zip: '54533'},
    {city: 'Nieder-Olm', zip: '55268'},
    {city: 'Niederorschel', zip: '37355'},
    {city: 'Niederotterbach', zip: '76889'},
    {city: 'Niederpierscheid', zip: '54649'},
    {city: 'Niederraden', zip: '54675'},
    {city: 'Niederrieden', zip: '87767'},
    {city: 'Niederroßbach', zip: '56479'},
    {city: 'Niedersayn', zip: '56244'},
    {city: 'Niederscheidweiler', zip: '54533'},
    {city: 'Niederschlettenbach', zip: '76891'},
    {city: 'Niederschönenfeld', zip: '86694'},
    {city: 'Niedersohren', zip: '55487'},
    {city: 'Niederstadtfeld', zip: '54570'},
    {city: 'Niederstaufenbach', zip: '66879'},
    {city: 'Niederstedem', zip: '54634'},
    {city: 'Niedersteinebach', zip: '56593'},
    {city: 'Niederstetten', zip: '97996'},
    {city: 'Niederstotzingen', zip: '89168'},
    {city: 'Niedert', zip: '56291'},
    {city: 'Niedertaufkirchen', zip: '84494'},
    {city: 'Niedertiefenbach', zip: '56368'},
    {city: 'Niedertrebra', zip: '99518'},
    {city: 'Niederviehbach', zip: '84183'},
    {city: 'Niederwallmenach', zip: '56357'},
    {city: 'Niederwambach', zip: '57614'},
    {city: 'Niederweiler', zip: '54636'},
    {city: 'Niederweiler', zip: '55491'},
    {city: 'Niederweis', zip: '54298'},
    {city: 'Niederweis', zip: '54668'},
    {city: 'Niederwerrn', zip: '97464'},
    {city: 'Niederwerth', zip: '56179'},
    {city: 'Niederwiesa', zip: '09577'},
    {city: 'Nieder-Wiesen', zip: '55234'},
    {city: 'Niederwinkling', zip: '94559'},
    {city: 'Niederwörresbach', zip: '55758'},
    {city: 'Niederwürschnitz', zip: '09399'},
    {city: 'Niederzier', zip: '52382'},
    {city: 'Niederzissen', zip: '56651'},
    {city: 'Niefern-Öschelbronn', zip: '75223'},
    {city: 'Nieheim', zip: '33039'},
    {city: 'Niehl', zip: '54646'},
    {city: 'Niemegk', zip: '14823'},
    {city: 'Niemetal', zip: '37127'},
    {city: 'Nienborstel', zip: '24819'},
    {city: 'Nienburg (Saale)', zip: '06429'},
    {city: 'Nienburg/Weser', zip: '31582'},
    {city: 'Nienbüttel', zip: '25596'},
    {city: 'Niendorf bei Berkenthin', zip: '23919'},
    {city: 'Niendorf/Stecknitz', zip: '23881'},
    {city: 'Nienhagen', zip: '18211'},
    {city: 'Nienhagen', zip: '29336'},
    {city: 'Nienstädt', zip: '31688'},
    {city: 'Nienwohld', zip: '23863'},
    {city: 'Niepars', zip: '18442'},
    {city: 'Niersbach', zip: '54518'},
    {city: 'Nierstein', zip: '55283'},
    {city: 'Niesgrau', zip: '24395'},
    {city: 'Niesky', zip: '02906'},
    {city: 'Nieste', zip: '34329'},
    {city: 'Niestetal', zip: '34266'},
    {city: 'Nievern', zip: '56132'},
    {city: 'Nimritz', zip: '07381'},
    {city: 'Nimshuscheid', zip: '54612'},
    {city: 'Nimsreuland', zip: '54614'},
    {city: 'Nindorf', zip: '25704'},
    {city: 'Nindorf', zip: '24594'},
    {city: 'Nister', zip: '57645'},
    {city: 'Nisterau', zip: '56472'},
    {city: 'Nisterberg', zip: '56472'},
    {city: 'Nister-Möhrendorf', zip: '56477'},
    {city: 'Nistertal', zip: '57647'},
    {city: 'Nittel', zip: '54453'},
    {city: 'Nittenau', zip: '93149'},
    {city: 'Nittendorf', zip: '93152'},
    {city: 'Nitz', zip: '56729'},
    {city: 'Nobitz', zip: '04603'},
    {city: 'Nochern', zip: '56357'},
    {city: 'Nöda', zip: '99195'},
    {city: 'Noer', zip: '24214'},
    {city: 'Nohen', zip: '55767'},
    {city: 'Nohfelden', zip: '66625'},
    {city: 'Nohn', zip: '54578'},
    {city: 'Nomborn', zip: '56412'},
    {city: 'Nonnenhorn', zip: '88149'},
    {city: 'Nonnweiler', zip: '66620'},
    {city: 'Norath', zip: '56291'},
    {city: 'Norddeich', zip: '25764'},
    {city: 'Norddorf auf Amrum', zip: '25946'},
    {city: 'Norden', zip: '26506'},
    {city: 'Nordendorf', zip: '86695'},
    {city: 'Nordenham', zip: '26954'},
    {city: 'Norderbrarup', zip: '24392'},
    {city: 'Norderfriedrichskoog', zip: '25870'},
    {city: 'Norderheistedt', zip: '25779'},
    {city: 'Nordermeldorf', zip: '25704'},
    {city: 'Norderney', zip: '26548'},
    {city: 'Norderstedt', zip: '22844'},
    {city: 'Norderstedt', zip: '22846'},
    {city: 'Norderstedt', zip: '22848'},
    {city: 'Norderstedt', zip: '22850'},
    {city: 'Norderstedt', zip: '22851'},
    {city: 'Norderwöhrden', zip: '25746'},
    {city: 'Nordhackstedt', zip: '24980'},
    {city: 'Nordhalben', zip: '96365'},
    {city: 'Nordharz', zip: '38855'},
    {city: 'Nordharz', zip: '38871'},
    {city: 'Nordhastedt', zip: '25785'},
    {city: 'Nordhausen', zip: '99734'},
    {city: 'Nordheim', zip: '74226'},
    {city: 'Nordheim a.Main', zip: '97334'},
    {city: 'Nordheim v.d.Rhön', zip: '97647'},
    {city: 'Nordhofen', zip: '56242'},
    {city: 'Nordhorn', zip: '48527'},
    {city: 'Nordhorn', zip: '48529'},
    {city: 'Nordhorn', zip: '48531'},
    {city: 'Nordkirchen', zip: '59394'},
    {city: 'Nordleda', zip: '21765'},
    {city: 'Nördlingen', zip: '86720'},
    {city: 'Nordrach', zip: '77787'},
    {city: 'Nordsehl', zip: '31717'},
    {city: 'Nordstemmen', zip: '31171'},
    {city: 'Nordstrand', zip: '25845'},
    {city: 'Nordwalde', zip: '48356'},
    {city: 'Nordwestuckermark', zip: '17291'},
    {city: 'Norheim', zip: '55585'},
    {city: 'Norken', zip: '57629'},
    {city: 'Norstedt', zip: '25884'},
    {city: 'Nörten-Hardenberg', zip: '37176'},
    {city: 'Nörtershausen', zip: '56283'},
    {city: 'Northeim', zip: '37154'},
    {city: 'Nortmoor', zip: '26845'},
    {city: 'Nortorf', zip: '25554'},
    {city: 'Nortorf', zip: '24589'},
    {city: 'Nortrup', zip: '49638'},
    {city: 'Nörvenich', zip: '52388'},
    {city: 'Nossen', zip: '01683'},
    {city: 'Nossendorf', zip: '17111'},
    {city: 'Nossentiner Hütte', zip: '17214'},
    {city: 'Nostorf', zip: '19258'},
    {city: 'Nothweiler', zip: '76891'},
    {city: 'Nottensdorf', zip: '21640'},
    {city: 'Nottertal-Heilinger Höhen', zip: '99994'},
    {city: 'Nottfeld', zip: '24392'},
    {city: 'Nottleben', zip: '99192'},
    {city: 'Nottuln', zip: '48301'},
    {city: 'Notzingen', zip: '73274'},
    {city: 'Nübbel', zip: '24809'},
    {city: 'Nübel', zip: '24881'},
    {city: 'Nüdlingen', zip: '97720'},
    {city: 'Nufringen', zip: '71154'},
    {city: 'Nümbrecht', zip: '51588'},
    {city: 'Nünchritz', zip: '01612'},
    {city: 'Nünschweiler', zip: '66989'},
    {city: 'Nürburg', zip: '53520'},
    {city: 'Nürnberg', zip: '90402'},
    {city: 'Nürnberg', zip: '90403'},
    {city: 'Nürnberg', zip: '90408'},
    {city: 'Nürnberg', zip: '90409'},
    {city: 'Nürnberg', zip: '90411'},
    {city: 'Nürnberg', zip: '90419'},
    {city: 'Nürnberg', zip: '90425'},
    {city: 'Nürnberg', zip: '90427'},
    {city: 'Nürnberg', zip: '90429'},
    {city: 'Nürnberg', zip: '90431'},
    {city: 'Nürnberg', zip: '90439'},
    {city: 'Nürnberg', zip: '90441'},
    {city: 'Nürnberg', zip: '90443'},
    {city: 'Nürnberg', zip: '90449'},
    {city: 'Nürnberg', zip: '90451'},
    {city: 'Nürnberg', zip: '90453'},
    {city: 'Nürnberg', zip: '90455'},
    {city: 'Nürnberg', zip: '90459'},
    {city: 'Nürnberg', zip: '90461'},
    {city: 'Nürnberg', zip: '90469'},
    {city: 'Nürnberg', zip: '90471'},
    {city: 'Nürnberg', zip: '90473'},
    {city: 'Nürnberg', zip: '90475'},
    {city: 'Nürnberg', zip: '90478'},
    {city: 'Nürnberg', zip: '90480'},
    {city: 'Nürnberg', zip: '90482'},
    {city: 'Nürnberg', zip: '90489'},
    {city: 'Nürnberg', zip: '90491'},
    {city: 'Nürtingen', zip: '72622'},
    {city: 'Nusbaum', zip: '54675'},
    {city: 'Nusplingen', zip: '72362'},
    {city: 'Nußbach', zip: '67759'},
    {city: 'Nußbaum', zip: '55569'},
    {city: 'Nußdorf', zip: '83365'},
    {city: 'Nußdorf am Inn', zip: '83131'},
    {city: 'Nusse', zip: '23896'},
    {city: 'Nußloch', zip: '69226'},
    {city: 'Nustrow', zip: '18195'},
    {city: 'Nüsttal', zip: '36167'},
    {city: 'Nuthetal', zip: '14558'},
    {city: 'Nuthe-Urstromtal', zip: '14947'},
    {city: 'Nutteln', zip: '25594'},
    {city: 'Nützen', zip: '24568'},
    {city: 'Oberahr', zip: '56414'},
    {city: 'Oberalben', zip: '66871'},
    {city: 'Oberammergau', zip: '82487'},
    {city: 'Oberarnbach', zip: '66851'},
    {city: 'Oberasbach', zip: '90522'},
    {city: 'Oberau', zip: '82496'},
    {city: 'Oberaudorf', zip: '83080'},
    {city: 'Oberaula', zip: '36280'},
    {city: 'Oberaurach', zip: '97514'},
    {city: 'Oberbachheim', zip: '56355'},
    {city: 'Oberbarnim', zip: '15377'},
    {city: 'Oberbergkirchen', zip: '84564'},
    {city: 'Oberbettingen', zip: '54578'},
    {city: 'Oberbillig', zip: '54331'},
    {city: 'Oberbodnitz', zip: '07646'},
    {city: 'Oberboihingen', zip: '72644'},
    {city: 'Oberbösa', zip: '99718'},
    {city: 'Oberbrombach', zip: '55767'},
    {city: 'Oberdachstetten', zip: '91617'},
    {city: 'Oberderdingen', zip: '75038'},
    {city: 'Oberdiebach', zip: '55413'},
    {city: 'Oberding', zip: '85445'},
    {city: 'Oberdischingen', zip: '89610'},
    {city: 'Oberdolling', zip: '85129'},
    {city: 'Oberdreis', zip: '57639'},
    {city: 'Oberdürenbach', zip: '56651'},
    {city: 'Oberehe-Stroheich', zip: '54578'},
    {city: 'Oberelbert', zip: '56412'},
    {city: 'Oberelsbach', zip: '97656'},
    {city: 'Oberelz', zip: '56767'},
    {city: 'Obererbach', zip: '57612'},
    {city: 'Obererbach', zip: '56414'},
    {city: 'Obere Warnow', zip: '19374'},
    {city: 'Oberfell', zip: '56332'},
    {city: 'Oberfischbach', zip: '56370'},
    {city: 'Ober-Flörsheim', zip: '55234'},
    {city: 'Obergeckler', zip: '54675'},
    {city: 'Obergriesbach', zip: '86573'},
    {city: 'Obergröningen', zip: '73569'},
    {city: 'Obergünzburg', zip: '87634'},
    {city: 'Obergurig', zip: '02692'},
    {city: 'Oberhaching', zip: '82041'},
    {city: 'Oberhaching', zip: '82064'},
    {city: 'Oberhaid', zip: '56237'},
    {city: 'Oberhaid', zip: '96173'},
    {city: 'Oberhambach', zip: '55765'},
    {city: 'Oberharmersbach', zip: '77784'},
    {city: 'Oberharz am Brocken', zip: '38875'},
    {city: 'Oberharz am Brocken', zip: '38877'},
    {city: 'Oberharz am Brocken', zip: '38889'},
    {city: 'Oberharz am Brocken', zip: '38899'},
    {city: 'Oberhausen', zip: '46045'},
    {city: 'Oberhausen', zip: '46047'},
    {city: 'Oberhausen', zip: '46049'},
    {city: 'Oberhausen', zip: '46117'},
    {city: 'Oberhausen', zip: '46119'},
    {city: 'Oberhausen', zip: '46145'},
    {city: 'Oberhausen', zip: '46147'},
    {city: 'Oberhausen', zip: '46149'},
    {city: 'Oberhausen', zip: '82386'},
    {city: 'Oberhausen', zip: '86697'},
    {city: 'Oberhausen', zip: '76887'},
    {city: 'Oberhausen an der Appel', zip: '67822'},
    {city: 'Oberhausen an der Nahe', zip: '55585'},
    {city: 'Oberhausen bei Kirn', zip: '55606'},
    {city: 'Oberhausen-Rheinhausen', zip: '68794'},
    {city: 'Oberheimbach', zip: '55413'},
    {city: 'Oberheldrungen', zip: '06577'},
    {city: 'Ober-Hilbersheim', zip: '55437'},
    {city: 'Oberhof', zip: '98559'},
    {city: 'Oberhonnefeld-Gierend', zip: '56587'},
    {city: 'Oberhosenbach', zip: '55758'},
    {city: 'Oberickelsheim', zip: '97258'},
    {city: 'Oberirsen', zip: '57635'},
    {city: 'Oberkail', zip: '54533'},
    {city: 'Oberkirch', zip: '77704'},
    {city: 'Oberkirn', zip: '55624'},
    {city: 'Oberkochen', zip: '73447'},
    {city: 'Ober Kostenz', zip: '55481'},
    {city: 'Oberkotzau', zip: '95145'},
    {city: 'Oberkrämer', zip: '16727'},
    {city: 'Oberlahr', zip: '57641'},
    {city: 'Oberlangen', zip: '49779'},
    {city: 'Oberlascheid', zip: '54608'},
    {city: 'Oberlauch', zip: '54614'},
    {city: 'Oberleichtersbach', zip: '97789'},
    {city: 'Oberlungwitz', zip: '09353'},
    {city: 'Obermaiselstein', zip: '87538'},
    {city: 'Obermarchtal', zip: '89611'},
    {city: 'Obermaßfeld-Grimmenthal', zip: '98617'},
    {city: 'Obermeitingen', zip: '86836'},
    {city: 'Obermichelbach', zip: '90587'},
    {city: 'Ober-Mörlen', zip: '61239'},
    {city: 'Obermoschel', zip: '67823'},
    {city: 'Obernau', zip: '57638'},
    {city: 'Obernbreit', zip: '97342'},
    {city: 'Obernburg am Main', zip: '63785'},
    {city: 'Oberndorf', zip: '21787'},
    {city: 'Oberndorf', zip: '67821'},
    {city: 'Oberndorf a.Lech', zip: '86698'},
    {city: 'Oberndorf am Neckar', zip: '78727'},
    {city: 'Oberneisen', zip: '65558'},
    {city: 'Oberneukirchen', zip: '84565'},
    {city: 'Obernfeld', zip: '37434'},
    {city: 'Obernheim', zip: '72364'},
    {city: 'Obernheim-Kirchenarnbach', zip: '66919'},
    {city: 'Obernhof', zip: '56379'},
    {city: 'Obernholz', zip: '29386'},
    {city: 'Obernkirchen', zip: '31683'},
    {city: 'Obernzell', zip: '94107'},
    {city: 'Obernzell', zip: '94130'},
    {city: 'Obernzenn', zip: '91619'},
    {city: 'Oberöfflingen', zip: '54533'},
    {city: 'Ober-Olm', zip: '55270'},
    {city: 'Oberoppurg', zip: '07381'},
    {city: 'Oberostendorf', zip: '86869'},
    {city: 'Oberotterbach', zip: '76889'},
    {city: 'Oberottmarshausen', zip: '86507'},
    {city: 'Oberpframmern', zip: '85667'},
    {city: 'Oberpierscheid', zip: '54649'},
    {city: 'Oberpleichfeld', zip: '97241'},
    {city: 'Oberpöring', zip: '94562'},
    {city: 'Oberraden', zip: '56587'},
    {city: 'Ober-Ramstadt', zip: '64372'},
    {city: 'Oberreichenbach', zip: '75394'},
    {city: 'Oberreichenbach', zip: '91097'},
    {city: 'Oberreidenbach', zip: '55758'},
    {city: 'Oberreute', zip: '88179'},
    {city: 'Oberried', zip: '79254'},
    {city: 'Oberrieden', zip: '87769'},
    {city: 'Oberriexingen', zip: '71739'},
    {city: 'Oberrod', zip: '56479'},
    {city: 'Oberroßbach', zip: '56479'},
    {city: 'Oberrot', zip: '74420'},
    {city: 'Oberroth', zip: '89294'},
    {city: 'Oberscheidweiler', zip: '54533'},
    {city: 'Oberscheinfeld', zip: '91483'},
    {city: 'Oberschleißheim', zip: '85764'},
    {city: 'Oberschlettenbach', zip: '76889'},
    {city: 'Oberschneiding', zip: '94363'},
    {city: 'Oberschöna', zip: '09600'},
    {city: 'Oberschönegg', zip: '87770'},
    {city: 'Oberschwarzach', zip: '97516'},
    {city: 'Oberschweinbach', zip: '82294'},
    {city: 'Obersimten', zip: '66957'},
    {city: 'Obersinn', zip: '97791'},
    {city: 'Obersöchering', zip: '82395'},
    {city: 'Obersontheim', zip: '74423'},
    {city: 'Oberstadion', zip: '89613'},
    {city: 'Oberstadt', zip: '98530'},
    {city: 'Oberstadtfeld', zip: '54570'},
    {city: 'Oberstaufen', zip: '87534'},
    {city: 'Oberstaufenbach', zip: '66879'},
    {city: 'Oberstdorf', zip: '87561'},
    {city: 'Oberstedem', zip: '54634'},
    {city: 'Obersteinebach', zip: '56593'},
    {city: 'Oberstenfeld', zip: '71720'},
    {city: 'Oberstreit', zip: '55596'},
    {city: 'Oberstreu', zip: '97640'},
    {city: 'Obersulm', zip: '74182'},
    {city: 'Obersülzen', zip: '67271'},
    {city: 'Obersüßbach', zip: '84101'},
    {city: 'Obertaufkirchen', zip: '84419'},
    {city: 'Oberteuringen', zip: '88094'},
    {city: 'Oberthal', zip: '66649'},
    {city: 'Oberthulba', zip: '97723'},
    {city: 'Obertiefenbach', zip: '56357'},
    {city: 'Obertraubling', zip: '93083'},
    {city: 'Obertrebra', zip: '99510'},
    {city: 'Obertrubach', zip: '91286'},
    {city: 'Obertshausen', zip: '63179'},
    {city: 'Oberuckersee', zip: '17291'},
    {city: 'Oberursel', zip: '61440'},
    {city: 'Oberviechtach', zip: '92526'},
    {city: 'Oberwallmenach', zip: '56357'},
    {city: 'Oberwambach', zip: '57614'},
    {city: 'Oberweid', zip: '98634'},
    {city: 'Oberweiler', zip: '54636'},
    {city: 'Oberweiler im Tal', zip: '67756'},
    {city: 'Oberweiler-Tiefenbach', zip: '67752'},
    {city: 'Oberweis', zip: '54636'},
    {city: 'Oberwesel', zip: '55430'},
    {city: 'Oberwiera', zip: '08396'},
    {city: 'Oberwies', zip: '56379'},
    {city: 'Oberwiesen', zip: '67294'},
    {city: 'Oberwiesenthal', zip: '09484'},
    {city: 'Oberwolfach', zip: '77709'},
    {city: 'Oberwörresbach', zip: '55758'},
    {city: 'Oberzent', zip: '64760'},
    {city: 'Oberzissen', zip: '56651'},
    {city: 'Obhausen', zip: '06268'},
    {city: 'Obing', zip: '83119'},
    {city: 'Obrigheim', zip: '74847'},
    {city: 'Obrigheim (Pfalz)', zip: '67283'},
    {city: 'Ochsenfurt', zip: '97199'},
    {city: 'Ochsenhausen', zip: '88416'},
    {city: 'Ochtendung', zip: '56299'},
    {city: 'Ochtersum', zip: '26489'},
    {city: 'Ochtrup', zip: '48607'},
    {city: 'Ockenfels', zip: '53545'},
    {city: 'Ockenheim', zip: '55437'},
    {city: 'Ockfen', zip: '54441'},
    {city: 'Ockholm', zip: '25842'},
    {city: 'Odderade', zip: '25785'},
    {city: 'Odelzhausen', zip: '85235'},
    {city: 'Odenbach', zip: '67748'},
    {city: 'Odenthal', zip: '51519'},
    {city: 'Oderaue', zip: '16259'},
    {city: 'Oderberg', zip: '16248'},
    {city: 'Odernheim am Glan', zip: '55571'},
    {city: 'Oderwitz', zip: '02791'},
    {city: 'Odisheim', zip: '21775'},
    {city: 'Oebisfelde-Weferlingen', zip: '39343'},
    {city: 'Oebisfelde-Weferlingen', zip: '39356'},
    {city: 'Oebisfelde-Weferlingen', zip: '39359'},
    {city: 'Oebisfelde-Weferlingen', zip: '39646'},
    {city: 'Oechsen', zip: '36404'},
    {city: 'Oederan', zip: '09569'},
    {city: 'Oederquart', zip: '21734'},
    {city: 'Oedheim', zip: '74229'},
    {city: 'Oelde', zip: '59302'},
    {city: 'Oelixdorf', zip: '25524'},
    {city: 'Oelsberg', zip: '56357'},
    {city: 'Oelsnitz/Erzgeb.', zip: '09376'},
    {city: 'Oelsnitz/Vogtland', zip: '08606'},
    {city: 'Oerel', zip: '27432'},
    {city: 'Oer-Erkenschwick', zip: '45739'},
    {city: 'Oering', zip: '23845'},
    {city: 'Oerlenbach', zip: '97714'},
    {city: 'Oerlinghausen', zip: '33813'},
    {city: 'Oersberg', zip: '24407'},
    {city: 'Oersdorf', zip: '24568'},
    {city: 'Oeschebüttel', zip: '25548'},
    {city: 'Oesterdeichstrich', zip: '25761'},
    {city: 'Oesterwurth', zip: '25764'},
    {city: 'Oestrich-Winkel', zip: '65375'},
    {city: 'Oettern', zip: '99438'},
    {city: 'Oettersdorf', zip: '07907'},
    {city: 'Oettingen i.Bay.', zip: '86732'},
    {city: 'Oetzen', zip: '29588'},
    {city: 'Oevenum', zip: '25938'},
    {city: 'Oeversee', zip: '24988'},
    {city: 'Offenau', zip: '74254'},
    {city: 'Offenbach am Main', zip: '63065'},
    {city: 'Offenbach am Main', zip: '63067'},
    {city: 'Offenbach am Main', zip: '63069'},
    {city: 'Offenbach am Main', zip: '63071'},
    {city: 'Offenbach am Main', zip: '63073'},
    {city: 'Offenbach am Main', zip: '63075'},
    {city: 'Offenbach an der Queich', zip: '76877'},
    {city: 'Offenbach-Hundheim', zip: '67749'},
    {city: 'Offenberg', zip: '94560'},
    {city: 'Offenburg', zip: '77652'},
    {city: 'Offenburg', zip: '77654'},
    {city: 'Offenburg', zip: '77656'},
    {city: 'Offenbüttel', zip: '25767'},
    {city: 'Offenhausen', zip: '91238'},
    {city: 'Offenheim', zip: '55234'},
    {city: 'Offingen', zip: '89362'},
    {city: 'Offstein', zip: '67591'},
    {city: 'Ofterdingen', zip: '72131'},
    {city: 'Ofterschwang', zip: '87527'},
    {city: 'Oftersheim', zip: '68723'},
    {city: 'Oggelshausen', zip: '88422'},
    {city: 'Ohlenhard', zip: '53520'},
    {city: 'Ohlsbach', zip: '77797'},
    {city: 'Ohlstadt', zip: '82441'},
    {city: 'Ohlweiler', zip: '55469'},
    {city: 'Ohmbach', zip: '66903'},
    {city: 'Ohmden', zip: '73275'},
    {city: 'Ohne', zip: '48465'},
    {city: 'Öhningen', zip: '78337'},
    {city: 'Ohorn', zip: '01896'},
    {city: 'Ohrdruf', zip: '99885'},
    {city: 'Ohrenbach', zip: '91620'},
    {city: 'Öhringen', zip: '74613'},
    {city: 'Ohrum', zip: '38312'},
    {city: 'Olbernhau', zip: '09526'},
    {city: 'Olbersdorf', zip: '02785'},
    {city: 'Ölbronn-Dürrn', zip: '75248'},
    {city: 'Olching', zip: '82140'},
    {city: 'Oldenborstel', zip: '25560'},
    {city: 'Oldenburg', zip: '26121'},
    {city: 'Oldenburg', zip: '26122'},
    {city: 'Oldenburg', zip: '26123'},
    {city: 'Oldenburg', zip: '26125'},
    {city: 'Oldenburg', zip: '26127'},
    {city: 'Oldenburg', zip: '26129'},
    {city: 'Oldenburg', zip: '26131'},
    {city: 'Oldenburg', zip: '26133'},
    {city: 'Oldenburg', zip: '26135'},
    {city: 'Oldenburg in Holstein', zip: '23758'},
    {city: 'Oldenbüttel', zip: '25557'},
    {city: 'Oldendorf', zip: '21726'},
    {city: 'Oldendorf', zip: '25588'},
    {city: 'Oldendorf (Luhe)', zip: '21385'},
    {city: 'Oldenhütten', zip: '24793'},
    {city: 'Oldenswort', zip: '25870'},
    {city: 'Oldersbek', zip: '25873'},
    {city: 'Olderup', zip: '25860'},
    {city: 'Oldsum', zip: '25938'},
    {city: 'Olfen', zip: '59399'},
    {city: 'Ollendorf', zip: '99198'},
    {city: 'Öllingen', zip: '89129'},
    {city: 'Ollmuth', zip: '54316'},
    {city: 'Olmscheid', zip: '54689'},
    {city: 'Olpe', zip: '57462'},
    {city: 'Olsberg', zip: '59939'},
    {city: 'Olsbrücken', zip: '67737'},
    {city: 'Olsdorf', zip: '54646'},
    {city: 'Ölsen', zip: '57612'},
    {city: 'Olzheim', zip: '54597'},
    {city: 'Onsdorf', zip: '54456'},
    {city: 'Opfenbach', zip: '88145'},
    {city: 'Öpfingen', zip: '89614'},
    {city: 'Oppach', zip: '02736'},
    {city: 'Oppenau', zip: '77728'},
    {city: 'Oppenheim', zip: '55276'},
    {city: 'Oppenweiler', zip: '71560'},
    {city: 'Oppenweiler', zip: '71570'},
    {city: 'Oppershausen', zip: '99986'},
    {city: 'Oppertshausen', zip: '55469'},
    {city: 'Oppurg', zip: '07381'},
    {city: 'Oranienbaum-Wörlitz', zip: '06785'},
    {city: 'Oranienburg', zip: '16515'},
    {city: 'Orbis', zip: '67294'},
    {city: 'Orenhofen', zip: '54298'},
    {city: 'Orfgen', zip: '57632'},
    {city: 'Orlamünde', zip: '07768'},
    {city: 'Orlenbach', zip: '54595'},
    {city: 'Ormont', zip: '54597'},
    {city: 'Ornbau', zip: '91737'},
    {city: 'Orsfeld', zip: '54655'},
    {city: 'Orsingen-Nenzingen', zip: '78359'},
    {city: 'Ortenberg', zip: '63683'},
    {city: 'Ortenberg', zip: '77799'},
    {city: 'Ortenburg', zip: '94496'},
    {city: 'Ortrand', zip: '01990'},
    {city: 'Osann-Monzel', zip: '54518'},
    {city: 'Osburg', zip: '54317'},
    {city: 'Oschatz', zip: '04758'},
    {city: 'Oschersleben', zip: '39387'},
    {city: 'Osdorf', zip: '24251'},
    {city: 'Osloß', zip: '38557'},
    {city: 'Osnabrück', zip: '49074'},
    {city: 'Osnabrück', zip: '49076'},
    {city: 'Osnabrück', zip: '49078'},
    {city: 'Osnabrück', zip: '49080'},
    {city: 'Osnabrück', zip: '49082'},
    {city: 'Osnabrück', zip: '49084'},
    {city: 'Osnabrück', zip: '49086'},
    {city: 'Osnabrück', zip: '49088'},
    {city: 'Osnabrück', zip: '49090'},
    {city: 'Oßling', zip: '01920'},
    {city: 'Ostbevern', zip: '48346'},
    {city: 'Osteel', zip: '26529'},
    {city: 'Ostelsheim', zip: '75395'},
    {city: 'Osten', zip: '21756'},
    {city: 'Ostenfeld', zip: '24790'},
    {city: 'Ostenfeld (Husum)', zip: '25872'},
    {city: 'Osterberg', zip: '89296'},
    {city: 'Osterbruch', zip: '21762'},
    {city: 'Osterburg (Altmark)', zip: '39606'},
    {city: 'Osterburken', zip: '74706'},
    {city: 'Osterby', zip: '24367'},
    {city: 'Osterby', zip: '24994'},
    {city: 'Ostercappeln', zip: '49179'},
    {city: 'Ostereistedt', zip: '27404'},
    {city: 'Osterfeld', zip: '06721'},
    {city: 'Osterheide', zip: '29664'},
    {city: 'Osterheide', zip: '29683'},
    {city: 'Osterhever', zip: '25836'},
    {city: 'Osterhofen', zip: '94486'},
    {city: 'Osterholz-Scharmbeck', zip: '27711'},
    {city: 'Osterhorn', zip: '25364'},
    {city: 'Osternienburger Land', zip: '06386'},
    {city: 'Osterode', zip: '37520'},
    {city: 'Oster-Ohrstedt', zip: '25885'},
    {city: 'Osterrade', zip: '25767'},
    {city: 'Osterrönfeld', zip: '24783'},
    {city: 'Osterspai', zip: '56340'},
    {city: 'Osterstedt', zip: '25590'},
    {city: 'Osterwald', zip: '49828'},
    {city: 'Osterwieck', zip: '38835'},
    {city: 'Osterwieck', zip: '38836'},
    {city: 'Osterzell', zip: '87662'},
    {city: 'Ostfildern', zip: '73760'},
    {city: 'Osthausen-Wülfershausen', zip: '99310'},
    {city: 'Ostheim v.d.Rhön', zip: '97645'},
    {city: 'Osthofen', zip: '67574'},
    {city: 'Ostrach', zip: '88356'},
    {city: 'Ostramondra', zip: '99636'},
    {city: 'Ostrau', zip: '04749'},
    {city: 'Ostrhauderfehn', zip: '26842'},
    {city: 'Östringen', zip: '76684'},
    {city: 'Ostritz', zip: '02899'},
    {city: 'Ostrohe', zip: '25746'},
    {city: 'Oststeinbek', zip: '22113'},
    {city: 'Ötigheim', zip: '76470'},
    {city: 'Ötisheim', zip: '75443'},
    {city: 'Ottenbach', zip: '73113'},
    {city: 'Ottenbach', zip: '73529'},
    {city: 'Ottenbüttel', zip: '25591'},
    {city: 'Ottendorf', zip: '24107'},
    {city: 'Ottendorf', zip: '07646'},
    {city: 'Ottendorf-Okrilla', zip: '01458'},
    {city: 'Ottenhofen', zip: '85570'},
    {city: 'Ottenhöfen im Schwarzwald', zip: '77883'},
    {city: 'Ottensoos', zip: '91242'},
    {city: 'Ottenstein', zip: '31868'},
    {city: 'Otter', zip: '21259'},
    {city: 'Otterbach', zip: '67731'},
    {city: 'Otterberg', zip: '67697'},
    {city: 'Otterfing', zip: '83624'},
    {city: 'Otterndorf', zip: '21762'},
    {city: 'Ottersberg', zip: '28870'},
    {city: 'Ottersheim', zip: '67308'},
    {city: 'Ottersheim bei Landau', zip: '76879'},
    {city: 'Otterstadt', zip: '67166'},
    {city: 'Ottersweier', zip: '77833'},
    {city: 'Otterwisch', zip: '04668'},
    {city: 'Otting', zip: '86700'},
    {city: 'Ottobeuren', zip: '87724'},
    {city: 'Ottobrunn', zip: '85521'},
    {city: 'Ottrau', zip: '34633'},
    {city: 'Ottweiler', zip: '66564'},
    {city: 'Otzberg', zip: '64853'},
    {city: 'Otzing', zip: '94563'},
    {city: 'Ötzingen', zip: '56244'},
    {city: 'Otzweiler', zip: '55606'},
    {city: 'Ovelgönne', zip: '26939'},
    {city: 'Overath', zip: '51491'},
    {city: 'Owen', zip: '73277'},
    {city: 'Owingen', zip: '88696'},
    {city: 'Owschlag', zip: '24811'},
    {city: 'Owschlag', zip: '24848'},
    {city: 'Oybin', zip: '02797'},
    {city: 'Oy-Mittelberg', zip: '87466'},
    {city: 'Oyten', zip: '28876'},
    {city: 'Padenstedt', zip: '24634'},
    {city: 'Paderborn', zip: '33098'},
    {city: 'Paderborn', zip: '33100'},
    {city: 'Paderborn', zip: '33102'},
    {city: 'Paderborn', zip: '33104'},
    {city: 'Paderborn', zip: '33106'},
    {city: 'Paderborn', zip: '33154'},
    {city: 'Paderborn', zip: '33161'},
    {city: 'Pähl', zip: '82396'},
    {city: 'Pahlen', zip: '25794'},
    {city: 'Painten', zip: '93351'},
    {city: 'Paitzdorf', zip: '07580'},
    {city: 'Palling', zip: '83349'},
    {city: 'Palzem', zip: '54439'},
    {city: 'Pampow', zip: '19075'},
    {city: 'Panker', zip: '24321'},
    {city: 'Panketal', zip: '16341'},
    {city: 'Panschwitz-Kuckau', zip: '01920'},
    {city: 'Pantelitz', zip: '18442'},
    {city: 'Panten', zip: '23896'},
    {city: 'Pantenburg', zip: '54531'},
    {city: 'Panzweiler', zip: '56865'},
    {city: 'Papenburg', zip: '26871'},
    {city: 'Papendorf', zip: '18059'},
    {city: 'Papendorf', zip: '17309'},
    {city: 'Papenhagen', zip: '18510'},
    {city: 'Pappenheim', zip: '91788'},
    {city: 'Parchim', zip: '19370'},
    {city: 'Parchim', zip: '19374'},
    {city: 'Parchtitz', zip: '18528'},
    {city: 'Parkstein', zip: '92711'},
    {city: 'Parkstetten', zip: '94365'},
    {city: 'Parsau', zip: '38470'},
    {city: 'Parsberg', zip: '92331'},
    {city: 'Parsdorf', zip: '85599'},
    {city: 'Parsteinsee', zip: '16248'},
    {city: 'Partenheim', zip: '55288'},
    {city: 'Partenstein', zip: '97846'},
    {city: 'Parthenstein', zip: '04668'},
    {city: 'Paschel', zip: '54314'},
    {city: 'Pasewalk', zip: '17309'},
    {city: 'Paska', zip: '07381'},
    {city: 'Passade', zip: '24253'},
    {city: 'Passau', zip: '94032'},
    {city: 'Passau', zip: '94034'},
    {city: 'Passau', zip: '94036'},
    {city: 'Passee', zip: '23992'},
    {city: 'Passow', zip: '19386'},
    {city: 'Passow', zip: '16306'},
    {city: 'Pastetten', zip: '85669'},
    {city: 'Patersberg', zip: '56348'},
    {city: 'Patersdorf', zip: '94265'},
    {city: 'Pätow-Steegen', zip: '19230'},
    {city: 'Pattensen', zip: '30982'},
    {city: 'Patzig', zip: '18528'},
    {city: 'Paulinenaue', zip: '14641'},
    {city: 'Paunzhausen', zip: '85307'},
    {city: 'Pausa-Mühltroff', zip: '07919'},
    {city: 'Pausa-Mühltroff', zip: '07952'},
    {city: 'Pausa-Mühltroff', zip: '08539'},
    {city: 'Päwesin', zip: '14778'},
    {city: 'Pechbrunn', zip: '95701'},
    {city: 'Peenehagen', zip: '17192'},
    {city: 'Peenehagen', zip: '17194'},
    {city: 'Peenemünde', zip: '17449'},
    {city: 'Peffingen', zip: '54668'},
    {city: 'Pegau', zip: '04523'},
    {city: 'Pegestorf', zip: '37619'},
    {city: 'Pegnitz', zip: '91257'},
    {city: 'Peine', zip: '31224'},
    {city: 'Peine', zip: '31226'},
    {city: 'Peine', zip: '31228'},
    {city: 'Peissen', zip: '25551'},
    {city: 'Peißenberg', zip: '82380'},
    {city: 'Peiting', zip: '86971'},
    {city: 'Peitz', zip: '03185'},
    {city: 'Pellingen', zip: '54331'},
    {city: 'Pellworm', zip: '25845'},
    {city: 'Pellworm', zip: '25849'},
    {city: 'Pelm', zip: '54570'},
    {city: 'Pemfling', zip: '93482'},
    {city: 'Penig', zip: '09322'},
    {city: 'Penkow', zip: '17213'},
    {city: 'Penkun', zip: '17328'},
    {city: 'Pennigsehl', zip: '31621'},
    {city: 'Pentling', zip: '93080'},
    {city: 'Penzberg', zip: '82377'},
    {city: 'Penzin', zip: '18249'},
    {city: 'Penzing', zip: '86929'},
    {city: 'Penzlin', zip: '17217'},
    {city: 'Penzlin', zip: '17219'},
    {city: 'Perach', zip: '84567'},
    {city: 'Perasdorf', zip: '94366'},
    {city: 'Perkam', zip: '94368'},
    {city: 'Perl', zip: '66706'},
    {city: 'Perleberg', zip: '19348'},
    {city: 'Perlesreut', zip: '94157'},
    {city: 'Perlin', zip: '19209'},
    {city: 'Perscheid', zip: '55430'},
    {city: 'Pessin', zip: '14641'},
    {city: 'Petersaurach', zip: '91580'},
    {city: 'Petersberg', zip: '36100'},
    {city: 'Petersberg', zip: '66989'},
    {city: 'Petersberg', zip: '06193'},
    {city: 'Petersberg', zip: '07616'},
    {city: 'Petersdorf', zip: '86574'},
    {city: 'Petershagen', zip: '32469'},
    {city: 'Petershagen/Eggersdorf', zip: '15345'},
    {city: 'Petershagen/Eggersdorf', zip: '15370'},
    {city: 'Petershausen', zip: '85238'},
    {city: 'Peterslahr', zip: '57632'},
    {city: 'Peterswald-Löffelscheid', zip: '56858'},
    {city: 'Pettendorf', zip: '93186'},
    {city: 'Petting', zip: '83367'},
    {city: 'Pettstadt', zip: '96175'},
    {city: 'Peuschen', zip: '07389'},
    {city: 'Pfaffenhausen', zip: '87772'},
    {city: 'Pfaffenhofen', zip: '74397'},
    {city: 'Pfaffenhofen an der Glonn', zip: '85235'},
    {city: 'Pfaffenhofen an der Ilm', zip: '85276'},
    {city: 'Pfaffenhofen an der Roth', zip: '89284'},
    {city: 'Pfaffen-Schwabenheim', zip: '55546'},
    {city: 'Pfaffenweiler', zip: '79292'},
    {city: 'Pfaffing', zip: '83539'},
    {city: 'Pfaffschwende', zip: '37308'},
    {city: 'Pfakofen', zip: '93101'},
    {city: 'Pfalzfeld', zip: '56291'},
    {city: 'Pfalzgrafenweiler', zip: '72285'},
    {city: 'Pfarrkirchen', zip: '84347'},
    {city: 'Pfarrweisach', zip: '96176'},
    {city: 'Pfatter', zip: '93102'},
    {city: 'Pfedelbach', zip: '74629'},
    {city: 'Pfeffelbach', zip: '66871'},
    {city: 'Pfeffenhausen', zip: '84076'},
    {city: 'Pferdingsleben', zip: '99869'},
    {city: 'Pfinztal', zip: '76327'},
    {city: 'Pfofeld', zip: '91738'},
    {city: 'Pförring', zip: '85104'},
    {city: 'Pforzen', zip: '87666'},
    {city: 'Pforzheim', zip: '75172'},
    {city: 'Pforzheim', zip: '75173'},
    {city: 'Pforzheim', zip: '75175'},
    {city: 'Pforzheim', zip: '75177'},
    {city: 'Pforzheim', zip: '75179'},
    {city: 'Pforzheim', zip: '75180'},
    {city: 'Pforzheim', zip: '75181'},
    {city: 'Pfreimd', zip: '92536'},
    {city: 'Pfronstetten', zip: '72539'},
    {city: 'Pfronten', zip: '87459'},
    {city: 'Pfullendorf', zip: '88630'},
    {city: 'Pfullingen', zip: '72793'},
    {city: 'Pfungstadt', zip: '64319'},
    {city: 'Philippsburg', zip: '76661'},
    {city: 'Philippsheim', zip: '54662'},
    {city: 'Philippsreut', zip: '94158'},
    {city: 'Philippsthal', zip: '36269'},
    {city: 'Picher', zip: '19230'},
    {city: 'Pickließem', zip: '54647'},
    {city: 'Piding', zip: '83451'},
    {city: 'Pielenhofen', zip: '93188'},
    {city: 'Piesport', zip: '54498'},
    {city: 'Pillig', zip: '56753'},
    {city: 'Pilsach', zip: '92367'},
    {city: 'Pilsting', zip: '94431'},
    {city: 'Pingelshagen', zip: '19069'},
    {city: 'Pinneberg', zip: '25421'},
    {city: 'Pinnow', zip: '19065'},
    {city: 'Pinnow', zip: '16278'},
    {city: 'Pintesfeld', zip: '54649'},
    {city: 'Pinzberg', zip: '91361'},
    {city: 'Pirk', zip: '92712'},
    {city: 'Pirmasens', zip: '66953'},
    {city: 'Pirmasens', zip: '66954'},
    {city: 'Pirmasens', zip: '66955'},
    {city: 'Pirna', zip: '01796'},
    {city: 'Pirow', zip: '19348'},
    {city: 'Pittenbach', zip: '54595'},
    {city: 'Pittenhart', zip: '83132'},
    {city: 'Plaaz', zip: '18276'},
    {city: 'Plaidt', zip: '56637'},
    {city: 'Planebruch', zip: '14822'},
    {city: 'Planegg', zip: '82152'},
    {city: 'Planetal', zip: '14806'},
    {city: 'Plankenfels', zip: '95515'},
    {city: 'Plankstadt', zip: '68723'},
    {city: 'Plascheid', zip: '54673'},
    {city: 'Plate', zip: '19086'},
    {city: 'Platten', zip: '54518'},
    {city: 'Plattenburg', zip: '19339'},
    {city: 'Plattling', zip: '94447'},
    {city: 'Plau am See', zip: '19395'},
    {city: 'Plaue', zip: '99338'},
    {city: 'Plauen', zip: '08523'},
    {city: 'Plauen', zip: '08525'},
    {city: 'Plauen', zip: '08527'},
    {city: 'Plauen', zip: '08529'},
    {city: 'Plauen', zip: '08541'},
    {city: 'Plauen', zip: '08547'},
    {city: 'Plech', zip: '91287'},
    {city: 'Pleckhausen', zip: '56593'},
    {city: 'Pleidelsheim', zip: '74385'},
    {city: 'Plein', zip: '54518'},
    {city: 'Pleinfeld', zip: '91785'},
    {city: 'Pleiskirchen', zip: '84568'},
    {city: 'Pleisweiler-Oberhofen', zip: '76889'},
    {city: 'Pleitersheim', zip: '55576'},
    {city: 'Pleizenhausen', zip: '55469'},
    {city: 'Pleß', zip: '87773'},
    {city: 'Plessa', zip: '04928'},
    {city: 'Plettenberg', zip: '58840'},
    {city: 'Pleystein', zip: '92714'},
    {city: 'Pliening', zip: '85652'},
    {city: 'Pliezhausen', zip: '72124'},
    {city: 'Plochingen', zip: '73207'},
    {city: 'Plön', zip: '24306'},
    {city: 'Plößberg', zip: '95703'},
    {city: 'Plothen', zip: '07907'},
    {city: 'Plötzkau', zip: '06425'},
    {city: 'Plöwen', zip: '17321'},
    {city: 'Plüderhausen', zip: '73642'},
    {city: 'Plüderhausen', zip: '73655'},
    {city: 'Plütscheid', zip: '54597'},
    {city: 'Pluwig', zip: '54316'},
    {city: 'Pockau-Lengefeld', zip: '09509'},
    {city: 'Pockau-Lengefeld', zip: '09514'},
    {city: 'Pocking', zip: '94060'},
    {city: 'Pöcking', zip: '82343'},
    {city: 'Podelzig', zip: '15326'},
    {city: 'Pogeez', zip: '23911'},
    {city: 'Poggensee', zip: '23896'},
    {city: 'Pohl', zip: '56357'},
    {city: 'Pöhl', zip: '08543'},
    {city: 'Pohle', zip: '31867'},
    {city: 'Pohlheim', zip: '35415'},
    {city: 'Pohnsdorf', zip: '24211'},
    {city: 'Poing', zip: '85586'},
    {city: 'Pokrent', zip: '19205'},
    {city: 'Polch', zip: '56751'},
    {city: 'Pölchow', zip: '18059'},
    {city: 'Pölich', zip: '54340'},
    {city: 'Pölitz', zip: '23847'},
    {city: 'Polle', zip: '37647'},
    {city: 'Pollenfeld', zip: '85131'},
    {city: 'Pollhagen', zip: '31718'},
    {city: 'Polling', zip: '82398'},
    {city: 'Polling', zip: '84570'},
    {city: 'Polsingen', zip: '91805'},
    {city: 'Pölzig', zip: '07554'},
    {city: 'Polzow', zip: '17309'},
    {city: 'Pommelsbrunn', zip: '91224'},
    {city: 'Pommerby', zip: '24395'},
    {city: 'Pommern', zip: '56829'},
    {city: 'Pommersfelden', zip: '96178'},
    {city: 'Pomster', zip: '53534'},
    {city: 'Ponitz', zip: '04639'},
    {city: 'Poppenbüll', zip: '25836'},
    {city: 'Poppendorf', zip: '18184'},
    {city: 'Poppenhausen', zip: '97490'},
    {city: 'Poppenhausen', zip: '36163'},
    {city: 'Poppenricht', zip: '92284'},
    {city: 'Pörmitz', zip: '07907'},
    {city: 'Pörnbach', zip: '85309'},
    {city: 'Porta Westfalica', zip: '32457'},
    {city: 'Pöschendorf', zip: '25560'},
    {city: 'Poseritz', zip: '18574'},
    {city: 'Pösing', zip: '93483'},
    {city: 'Pößneck', zip: '07381'},
    {city: 'Postau', zip: '84103'},
    {city: 'Postbauer-Heng', zip: '92353'},
    {city: 'Posterstein', zip: '04626'},
    {city: 'Postfeld', zip: '24211'},
    {city: 'Postlow', zip: '17391'},
    {city: 'Postmünster', zip: '84389'},
    {city: 'Potsdam', zip: '14467'},
    {city: 'Potsdam', zip: '14469'},
    {city: 'Potsdam', zip: '14471'},
    {city: 'Potsdam', zip: '14473'},
    {city: 'Potsdam', zip: '14476'},
    {city: 'Potsdam', zip: '14478'},
    {city: 'Potsdam', zip: '14480'},
    {city: 'Potsdam', zip: '14482'},
    {city: 'Pottenstein', zip: '91278'},
    {city: 'Pöttmes', zip: '86554'},
    {city: 'Pottum', zip: '56459'},
    {city: 'Poxdorf', zip: '91099'},
    {city: 'Poxdorf', zip: '07616'},
    {city: 'Poyenberg', zip: '25581'},
    {city: 'Pracht', zip: '57589'},
    {city: 'Prackenbach', zip: '94267'},
    {city: 'Pragsdorf', zip: '17094'},
    {city: 'Prasdorf', zip: '24253'},
    {city: 'Prath', zip: '56346'},
    {city: 'Prebberede', zip: '17168'},
    {city: 'Prebitz', zip: '95473'},
    {city: 'Preetz', zip: '24211'},
    {city: 'Preetz', zip: '18445'},
    {city: 'Preischeid', zip: '54673'},
    {city: 'Preischeid', zip: '54689'},
    {city: 'Preist', zip: '54664'},
    {city: 'Prem', zip: '86984'},
    {city: 'Premnitz', zip: '14727'},
    {city: 'Prenzlau', zip: '17291'},
    {city: 'Prerow', zip: '18375'},
    {city: 'Pressath', zip: '92690'},
    {city: 'Presseck', zip: '95355'},
    {city: 'Pressig', zip: '96332'},
    {city: 'Pretzfeld', zip: '91362'},
    {city: 'Preußisch Oldendorf', zip: '32361'},
    {city: 'Prezelle', zip: '29491'},
    {city: 'Priborn', zip: '17209'},
    {city: 'Prichsenstadt', zip: '97357'},
    {city: 'Prien am Chiemsee', zip: '83209'},
    {city: 'Priepert', zip: '17255'},
    {city: 'Priesendorf', zip: '96170'},
    {city: 'Priestewitz', zip: '01561'},
    {city: 'Prinzenmoor', zip: '24805'},
    {city: 'Prinzhöfte', zip: '27243'},
    {city: 'Pripsleben', zip: '17091'},
    {city: 'Prisdorf', zip: '25497'},
    {city: 'Prislich', zip: '19300'},
    {city: 'Prittriching', zip: '86931'},
    {city: 'Pritzier', zip: '19230'},
    {city: 'Pritzwalk', zip: '16928'},
    {city: 'Probsteierhagen', zip: '24253'},
    {city: 'Probstzella', zip: '07330'},
    {city: 'Prohn', zip: '18445'},
    {city: 'Pronsfeld', zip: '54597'},
    {city: 'Pronstorf', zip: '23820'},
    {city: 'Prosselsheim', zip: '97279'},
    {city: 'Prötzel', zip: '15345'},
    {city: 'Pruchten', zip: '18356'},
    {city: 'Prüm', zip: '54595'},
    {city: 'Prümzurlay', zip: '54668'},
    {city: 'Prutting', zip: '83134'},
    {city: 'Püchersreuth', zip: '92715'},
    {city: 'Puchheim', zip: '82178'},
    {city: 'Pudagla', zip: '17429'},
    {city: 'Puderbach', zip: '56305'},
    {city: 'Pulheim', zip: '50259'},
    {city: 'Pullach im Isartal', zip: '82049'},
    {city: 'Pullenreuth', zip: '95704'},
    {city: 'Puls', zip: '25560'},
    {city: 'Pulsnitz', zip: '01896'},
    {city: 'Pünderich', zip: '56862'},
    {city: 'Pürgen', zip: '86932'},
    {city: 'Puschendorf', zip: '90617'},
    {city: 'Puschwitz', zip: '02699'},
    {city: 'Putbus', zip: '18581'},
    {city: 'Putgarten', zip: '18556'},
    {city: 'Putlitz', zip: '16949'},
    {city: 'Püttlingen', zip: '66346'},
    {city: 'Putzbrunn', zip: '85640'},
    {city: 'Pyrbaum', zip: '90602'},
    {city: 'Quakenbrück', zip: '49610'},
    {city: 'Quarnbek', zip: '24107'},
    {city: 'Quarnstedt', zip: '25563'},
    {city: 'Quaschwitz', zip: '07389'},
    {city: 'Quedlinburg', zip: '06484'},
    {city: 'Quedlinburg', zip: '06485'},
    {city: 'Queidersbach', zip: '66851'},
    {city: 'Quendorf', zip: '48465'},
    {city: 'Querenhorst', zip: '38368'},
    {city: 'Querfurt', zip: '06268'},
    {city: 'Quernheim', zip: '49448'},
    {city: 'Quickborn', zip: '25451'},
    {city: 'Quickborn', zip: '25712'},
    {city: 'Quiddelbach', zip: '53518'},
    {city: 'Quierschied', zip: '66287'},
    {city: 'Quirnbach', zip: '56242'},
    {city: 'Quirnbach/Pfalz', zip: '66909'},
    {city: 'Quirnheim', zip: '67280'},
    {city: 'Quitzdorf am See', zip: '02906'},
    {city: 'Raa-Besenbek', zip: '25335'},
    {city: 'Rabel', zip: '24376'},
    {city: 'Rabenau', zip: '35466'},
    {city: 'Rabenau', zip: '01734'},
    {city: 'Rabenholz', zip: '24395'},
    {city: 'Rabenkirchen-Faulück', zip: '24407'},
    {city: 'Raben Steinfeld', zip: '19065'},
    {city: 'Rabenstein/Fläming', zip: '14823'},
    {city: 'Räbke', zip: '38375'},
    {city: 'Räckelwitz', zip: '01920'},
    {city: 'Racksen', zip: '57612'},
    {city: 'Rackwitz', zip: '04519'},
    {city: 'Radbruch', zip: '21449'},
    {city: 'Raddestorf', zip: '31604'},
    {city: 'Rade', zip: '25579'},
    {city: 'Rade', zip: '24790'},
    {city: 'Rade', zip: '24794'},
    {city: 'Radeberg', zip: '01454'},
    {city: 'Radebeul', zip: '01445'},
    {city: 'Rade b. Hohenwestedt', zip: '24594'},
    {city: 'Radeburg', zip: '01471'},
    {city: 'Radevormwald', zip: '42477'},
    {city: 'Radibor', zip: '02627'},
    {city: 'Radolfzell am Bodensee', zip: '78315'},
    {city: 'Raesfeld', zip: '46348'},
    {city: 'Ragow-Merz', zip: '15848'},
    {city: 'Raguhn-Jeßnitz', zip: '06779'},
    {city: 'Raguhn-Jeßnitz', zip: '06800'},
    {city: 'Rahden', zip: '32369'},
    {city: 'Rain', zip: '94369'},
    {city: 'Rain', zip: '86641'},
    {city: 'Rainau', zip: '73492'},
    {city: 'Raisting', zip: '82399'},
    {city: 'Raitenbuch', zip: '91790'},
    {city: 'Ralbitz-Rosenthal', zip: '01920'},
    {city: 'Ralingen', zip: '54310'},
    {city: 'Ralswiek', zip: '18528'},
    {city: 'Ramberg', zip: '76829'},
    {city: 'Ramberg', zip: '76857'},
    {city: 'Rambin', zip: '18573'},
    {city: 'Ramerberg', zip: '83561'},
    {city: 'Ramhusen', zip: '25715'},
    {city: 'Ramin', zip: '17321'},
    {city: 'Rammelsbach', zip: '66887'},
    {city: 'Rammenau', zip: '01877'},
    {city: 'Rammingen', zip: '89192'},
    {city: 'Rammingen', zip: '86871'},
    {city: 'Ramsau bei Berchtesgaden', zip: '83486'},
    {city: 'Ramsen', zip: '67305'},
    {city: 'Ramstedt', zip: '25876'},
    {city: 'Ramstein-Miesenbach', zip: '66877'},
    {city: 'Ramsthal', zip: '97729'},
    {city: 'Randersacker', zip: '97236'},
    {city: 'Randowtal', zip: '17291'},
    {city: 'Rangendingen', zip: '72414'},
    {city: 'Rangsdorf', zip: '15834'},
    {city: 'Ranis', zip: '07389'},
    {city: 'Rankwitz', zip: '17406'},
    {city: 'Rannstedt', zip: '99518'},
    {city: 'Rannungen', zip: '97517'},
    {city: 'Ransbach-Baumbach', zip: '56235'},
    {city: 'Ranschbach', zip: '76829'},
    {city: 'Ranstadt', zip: '63691'},
    {city: 'Ransweiler', zip: '67808'},
    {city: 'Rantrum', zip: '25873'},
    {city: 'Rantzau', zip: '24321'},
    {city: 'Rantzau', zip: '24329'},
    {city: 'Rappin', zip: '18528'},
    {city: 'Raschau-Markersbach', zip: '08352'},
    {city: 'Rascheid', zip: '54413'},
    {city: 'Rasdorf', zip: '36169'},
    {city: 'Rastatt', zip: '76437'},
    {city: 'Rastdorf', zip: '26901'},
    {city: 'Rastede', zip: '26180'},
    {city: 'Rastenberg', zip: '99636'},
    {city: 'Rastorf', zip: '24211'},
    {city: 'Rastow', zip: '19077'},
    {city: 'Ratekau', zip: '23611'},
    {city: 'Ratekau', zip: '23626'},
    {city: 'Ratekau', zip: '23669'},
    {city: 'Ratekau', zip: '23689'},
    {city: 'Rathen', zip: '01824'},
    {city: 'Rathenow', zip: '14712'},
    {city: 'Rathjensdorf', zip: '24306'},
    {city: 'Rathmannsdorf', zip: '01814'},
    {city: 'Rathskirchen', zip: '67744'},
    {city: 'Rathsweiler', zip: '66887'},
    {city: 'Ratingen', zip: '40472'},
    {city: 'Ratingen', zip: '40878'},
    {city: 'Ratingen', zip: '40880'},
    {city: 'Ratingen', zip: '40882'},
    {city: 'Ratingen', zip: '40883'},
    {city: 'Ratingen', zip: '40885'},
    {city: 'Ratshausen', zip: '72365'},
    {city: 'Rattelsdorf', zip: '96179'},
    {city: 'Rattelsdorf', zip: '07646'},
    {city: 'Rattenberg', zip: '94371'},
    {city: 'Rattenkirchen', zip: '84431'},
    {city: 'Rattiszell', zip: '94372'},
    {city: 'Ratzeburg', zip: '23909'},
    {city: 'Ratzert', zip: '57614'},
    {city: 'Rätzlingen', zip: '29590'},
    {city: 'Raubach', zip: '56316'},
    {city: 'Raubling', zip: '83064'},
    {city: 'Rauda', zip: '07613'},
    {city: 'Rauen', zip: '15518'},
    {city: 'Rauenberg', zip: '69231'},
    {city: 'Rauhenebrach', zip: '96181'},
    {city: 'Raumbach', zip: '55592'},
    {city: 'Raunheim', zip: '65479'},
    {city: 'Rauschenberg', zip: '35282'},
    {city: 'Rauschwitz', zip: '07616'},
    {city: 'Rausdorf', zip: '22929'},
    {city: 'Rausdorf', zip: '07646'},
    {city: 'Ravengiersburg', zip: '55471'},
    {city: 'Ravensburg', zip: '88212'},
    {city: 'Ravensburg', zip: '88213'},
    {city: 'Ravensburg', zip: '88214'},
    {city: 'Ravenstein', zip: '74747'},
    {city: 'Raversbeuren', zip: '56850'},
    {city: 'Rayerschied', zip: '55469'},
    {city: 'Rech', zip: '53506'},
    {city: 'Rechberghausen', zip: '73098'},
    {city: 'Rechenberg-Bienenmühle', zip: '09623'},
    {city: 'Rechlin', zip: '17248'},
    {city: 'Rechtenbach', zip: '97848'},
    {city: 'Rechtenstein', zip: '89611'},
    {city: 'Rechtmehring', zip: '83562'},
    {city: 'Rechtsupweg', zip: '26529'},
    {city: 'Recke', zip: '49509'},
    {city: 'Reckendorf', zip: '96182'},
    {city: 'Reckenroth', zip: '56370'},
    {city: 'Reckershausen', zip: '55481'},
    {city: 'Recklinghausen', zip: '45657'},
    {city: 'Recklinghausen', zip: '45659'},
    {city: 'Recklinghausen', zip: '45661'},
    {city: 'Recklinghausen', zip: '45663'},
    {city: 'Recklinghausen', zip: '45665'},
    {city: 'Reddelich', zip: '18209'},
    {city: 'Redefin', zip: '19230'},
    {city: 'Rednitzhembach', zip: '91126'},
    {city: 'Redwitz a.d.Rodach', zip: '96257'},
    {city: 'Rees', zip: '46459'},
    {city: 'Reesdorf', zip: '24241'},
    {city: 'Reeßum', zip: '27367'},
    {city: 'Regen', zip: '94209'},
    {city: 'Regensburg', zip: '93047'},
    {city: 'Regensburg', zip: '93049'},
    {city: 'Regensburg', zip: '93051'},
    {city: 'Regensburg', zip: '93053'},
    {city: 'Regensburg', zip: '93055'},
    {city: 'Regensburg', zip: '93057'},
    {city: 'Regensburg', zip: '93059'},
    {city: 'Regenstauf', zip: '93128'},
    {city: 'Regesbostel', zip: '21649'},
    {city: 'Regis-Breitingen', zip: '04565'},
    {city: 'Regnitzlosau', zip: '95194'},
    {city: 'Rehau', zip: '95111'},
    {city: 'Rehbach', zip: '55566'},
    {city: 'Rehborn', zip: '55592'},
    {city: 'Rehburg-Loccum', zip: '31547'},
    {city: 'Rehden', zip: '49453'},
    {city: 'Rehe', zip: '56479'},
    {city: 'Reher', zip: '25593'},
    {city: 'Rehfelde', zip: '15345'},
    {city: 'Rehhorst', zip: '23619'},
    {city: 'Rehling', zip: '86508'},
    {city: 'Rehlingen', zip: '21385'},
    {city: 'Rehlingen-Siersburg', zip: '66780'},
    {city: 'Rehm-Flehde-Bargen', zip: '25776'},
    {city: 'Rehna', zip: '19217'},
    {city: 'Rehweiler', zip: '66907'},
    {city: 'Reich', zip: '55471'},
    {city: 'Reichartshausen', zip: '74934'},
    {city: 'Reichelsheim', zip: '61203'},
    {city: 'Reichelsheim (Odenwald)', zip: '64385'},
    {city: 'Reichenau', zip: '78479'},
    {city: 'Reichenbach', zip: '93189'},
    {city: 'Reichenbach', zip: '96358'},
    {city: 'Reichenbach', zip: '55776'},
    {city: 'Reichenbach', zip: '07629'},
    {city: 'Reichenbach', zip: '08468'},
    {city: 'Reichenbach', zip: '08491'},
    {city: 'Reichenbach', zip: '08499'},
    {city: 'Reichenbach am Heuberg', zip: '78564'},
    {city: 'Reichenbach an der Fils', zip: '73262'},
    {city: 'Reichenbach/Oberlausitz', zip: '02894'},
    {city: 'Reichenbach-Steegen', zip: '66879'},
    {city: 'Reichenberg', zip: '97234'},
    {city: 'Reichenberg', zip: '56357'},
    {city: 'Reichenow-Möglin', zip: '15345'},
    {city: 'Reichenschwand', zip: '91244'},
    {city: 'Reichenwalde', zip: '15526'},
    {city: 'Reichersbeuern', zip: '83677'},
    {city: 'Reichertshausen', zip: '85293'},
    {city: 'Reichertsheim', zip: '84437'},
    {city: 'Reichertshofen', zip: '85084'},
    {city: 'Reichling', zip: '86934'},
    {city: 'Reichshof', zip: '51580'},
    {city: 'Reichstädt', zip: '07580'},
    {city: 'Reichsthal', zip: '67759'},
    {city: 'Reichweiler', zip: '66871'},
    {city: 'Reidenhausen', zip: '56865'},
    {city: 'Reifenberg', zip: '66507'},
    {city: 'Reiferscheid', zip: '57632'},
    {city: 'Reiff', zip: '54619'},
    {city: 'Reiffelbach', zip: '67829'},
    {city: 'Reifferscheid', zip: '53520'},
    {city: 'Reil', zip: '56861'},
    {city: 'Reilingen', zip: '68799'},
    {city: 'Reimerath', zip: '53539'},
    {city: 'Reimershagen', zip: '18276'},
    {city: 'Reimlingen', zip: '86756'},
    {city: 'Reinbek', zip: '21465'},
    {city: 'Reinbek', zip: '21521'},
    {city: 'Reinfeld', zip: '23858'},
    {city: 'Reinhardshagen', zip: '34359'},
    {city: 'Reinhardtsdorf-Schöna', zip: '01814'},
    {city: 'Reinheim', zip: '64354'},
    {city: 'Reinholterode', zip: '37308'},
    {city: 'Reinsberg', zip: '09629'},
    {city: 'Reinsberg', zip: '09634'},
    {city: 'Reinsbüttel', zip: '25764'},
    {city: 'Reinsdorf', zip: '08141'},
    {city: 'Reinsdorf', zip: '06556'},
    {city: 'Reinsfeld', zip: '54421'},
    {city: 'Reinstädt', zip: '07768'},
    {city: 'Reinstorf', zip: '21400'},
    {city: 'Reipeldingen', zip: '54689'},
    {city: 'Reipoltskirchen', zip: '67753'},
    {city: 'Reisbach', zip: '94419'},
    {city: 'Reischach', zip: '84571'},
    {city: 'Reiskirchen', zip: '35447'},
    {city: 'Reit im Winkl', zip: '83242'},
    {city: 'Reitwein', zip: '15328'},
    {city: 'Reitzenhain', zip: '56357'},
    {city: 'Reken', zip: '48734'},
    {city: 'Rellingen', zip: '25462'},
    {city: 'Relsberg', zip: '67753'},
    {city: 'Remagen', zip: '53424'},
    {city: 'Remchingen', zip: '75196'},
    {city: 'Remlingen', zip: '97280'},
    {city: 'Remlingen-Semmenstedt', zip: '38319'},
    {city: 'Remlingen-Semmenstedt', zip: '38327'},
    {city: 'Remmels', zip: '24594'},
    {city: 'Remptendorf', zip: '07368'},
    {city: 'Remscheid', zip: '42853'},
    {city: 'Remscheid', zip: '42855'},
    {city: 'Remscheid', zip: '42857'},
    {city: 'Remscheid', zip: '42859'},
    {city: 'Remscheid', zip: '42897'},
    {city: 'Remscheid', zip: '42899'},
    {city: 'Remse', zip: '08373'},
    {city: 'Remseck am Neckar', zip: '71686'},
    {city: 'Remshalden', zip: '73630'},
    {city: 'Renchen', zip: '77871'},
    {city: 'Rendsburg', zip: '24768'},
    {city: 'Rendswühren', zip: '24619'},
    {city: 'Rengsdorf', zip: '56579'},
    {city: 'Renkenberge', zip: '49762'},
    {city: 'Rennau', zip: '38368'},
    {city: 'Rennerod', zip: '56477'},
    {city: 'Rennertshofen', zip: '86643'},
    {city: 'Renningen', zip: '71272'},
    {city: 'Renquishausen', zip: '78603'},
    {city: 'Renthendorf', zip: '07646'},
    {city: 'Rentweinsdorf', zip: '96184'},
    {city: 'Reppenstedt', zip: '21391'},
    {city: 'Rerik', zip: '18230'},
    {city: 'Rethem (Aller)', zip: '27336'},
    {city: 'Rethwisch', zip: '25566'},
    {city: 'Rethwisch', zip: '23847'},
    {city: 'Retschow', zip: '18211'},
    {city: 'Rettenbach', zip: '93191'},
    {city: 'Rettenbach', zip: '89364'},
    {city: 'Rettenbach a.Auerberg', zip: '87675'},
    {city: 'Rettenberg', zip: '87549'},
    {city: 'Retterath', zip: '56769'},
    {city: 'Rettersen', zip: '57635'},
    {city: 'Rettershain', zip: '56357'},
    {city: 'Rettert', zip: '56370'},
    {city: 'Retzow', zip: '14641'},
    {city: 'Retzstadt', zip: '97282'},
    {city: 'Reudelsterz', zip: '56727'},
    {city: 'Reurieth', zip: '98646'},
    {city: 'Reußenköge', zip: '25821'},
    {city: 'Reut', zip: '84367'},
    {city: 'Reute', zip: '79276'},
    {city: 'Reuth', zip: '54597'},
    {city: 'Reuth b.Erbendorf', zip: '92717'},
    {city: 'Reutlingen', zip: '72760'},
    {city: 'Reutlingen', zip: '72762'},
    {city: 'Reutlingen', zip: '72764'},
    {city: 'Reutlingen', zip: '72766'},
    {city: 'Reutlingen', zip: '72768'},
    {city: 'Reutlingen', zip: '72770'},
    {city: 'Rhade', zip: '27404'},
    {city: 'Rhauderfehn', zip: '26817'},
    {city: 'Rhaunen', zip: '55624'},
    {city: 'Rheda-Wiedenbrück', zip: '33378'},
    {city: 'Rhede', zip: '46414'},
    {city: 'Rhede (Ems)', zip: '26899'},
    {city: 'Rheinau', zip: '77866'},
    {city: 'Rheinbach', zip: '53359'},
    {city: 'Rheinberg', zip: '47495'},
    {city: 'Rheinböllen', zip: '55494'},
    {city: 'Rheinbreitbach', zip: '53619'},
    {city: 'Rheinbrohl', zip: '56598'},
    {city: 'Rheine', zip: '48429'},
    {city: 'Rheine', zip: '48431'},
    {city: 'Rheine', zip: '48432'},
    {city: 'Rheinfelden (Baden)', zip: '79618'},
    {city: 'Rheinhausen', zip: '79365'},
    {city: 'Rheinmünster', zip: '77836'},
    {city: 'Rheinsberg', zip: '16818'},
    {city: 'Rheinsberg', zip: '16831'},
    {city: 'Rheinsberg', zip: '16835'},
    {city: 'Rheinsberg', zip: '16837'},
    {city: 'Rheinstetten', zip: '76287'},
    {city: 'Rheinzabern', zip: '76764'},
    {city: 'Rhens', zip: '56321'},
    {city: 'Rhens', zip: '56323'},
    {city: 'Rheurdt', zip: '47509'},
    {city: 'Rhinow', zip: '14728'},
    {city: 'Rhodt unter Rietburg', zip: '76835'},
    {city: 'Rhönblick', zip: '98617'},
    {city: 'Rhumspringe', zip: '37434'},
    {city: 'Ribbesbüttel', zip: '38551'},
    {city: 'Ribnitz-Damgarten', zip: '18311'},
    {city: 'Richtenberg', zip: '18461'},
    {city: 'Rickenbach', zip: '79736'},
    {city: 'Rickert', zip: '24782'},
    {city: 'Rickert', zip: '24787'},
    {city: 'Rickling', zip: '24635'},
    {city: 'Ried', zip: '86510'},
    {city: 'Riedbach', zip: '97519'},
    {city: 'Riede', zip: '27339'},
    {city: 'Riedelberg', zip: '66484'},
    {city: 'Rieden', zip: '87668'},
    {city: 'Rieden', zip: '56745'},
    {city: 'Rieden', zip: '92286'},
    {city: 'Rieden am Forggensee', zip: '87669'},
    {city: 'Riedenberg', zip: '97792'},
    {city: 'Riedenburg', zip: '93339'},
    {city: 'Riedenheim', zip: '97283'},
    {city: 'Riederich', zip: '72585'},
    {city: 'Riedering', zip: '83083'},
    {city: 'Riedhausen', zip: '88377'},
    {city: 'Riedlingen', zip: '88499'},
    {city: 'Riedstadt', zip: '64560'},
    {city: 'Riegel', zip: '79359'},
    {city: 'Riegelsberg', zip: '66292'},
    {city: 'Riegenroth', zip: '55469'},
    {city: 'Riegsee', zip: '82418'},
    {city: 'Riekofen', zip: '93104'},
    {city: 'Rielasingen-Worblingen', zip: '78239'},
    {city: 'Rieneck', zip: '97794'},
    {city: 'Rieps', zip: '19217'},
    {city: 'Riepsdorf', zip: '23738'},
    {city: 'Riesa', zip: '01587'},
    {city: 'Riesa', zip: '01589'},
    {city: 'Riesa', zip: '01591'},
    {city: 'Riesa', zip: '01594'},
    {city: 'Riesbürg', zip: '73469'},
    {city: 'Rieschweiler-Mühlbach', zip: '66509'},
    {city: 'Rieseby', zip: '24354'},
    {city: 'Rieste', zip: '49597'},
    {city: 'Riesweiler', zip: '55499'},
    {city: 'Rietberg', zip: '33397'},
    {city: 'Rietheim-Weilheim', zip: '78604'},
    {city: 'Riethgen', zip: '99638'},
    {city: 'Riethnordhausen', zip: '99195'},
    {city: 'Rietschen', zip: '02956'},
    {city: 'Rietz-Neuendorf', zip: '15848'},
    {city: 'Rietzneuendorf-Staakow', zip: '15910'},
    {city: 'Rimbach', zip: '64668'},
    {city: 'Rimbach', zip: '84326'},
    {city: 'Rimbach', zip: '93485'},
    {city: 'Rimpar', zip: '97222'},
    {city: 'Rimsberg', zip: '55765'},
    {city: 'Rimsting', zip: '83253'},
    {city: 'Rinchnach', zip: '94269'},
    {city: 'Ringe', zip: '49824'},
    {city: 'Ringelai', zip: '94160'},
    {city: 'Ringgau', zip: '37296'},
    {city: 'Ringleben', zip: '99189'},
    {city: 'Ringsberg', zip: '24977'},
    {city: 'Ringsheim', zip: '77975'},
    {city: 'Rinnthal', zip: '76857'},
    {city: 'Rinteln', zip: '31737'},
    {city: 'Rinzenberg', zip: '55767'},
    {city: 'Riol', zip: '54340'},
    {city: 'Rippershausen', zip: '98639'},
    {city: 'Risum-Lindholm', zip: '25920'},
    {city: 'Ritschenhausen', zip: '98617'},
    {city: 'Ritterhude', zip: '27721'},
    {city: 'Rittersdorf', zip: '54636'},
    {city: 'Rittersdorf', zip: '99448'},
    {city: 'Rittersheim', zip: '67294'},
    {city: 'Ritzerau', zip: '23896'},
    {city: 'Ritzerow', zip: '17153'},
    {city: 'Rivenich', zip: '54518'},
    {city: 'Riveris', zip: '54317'},
    {city: 'Röbel/Müritz', zip: '17207'},
    {city: 'Rochau', zip: '39579'},
    {city: 'Rochlitz', zip: '09306'},
    {city: 'Rockenberg', zip: '35519'},
    {city: 'Rockenhausen', zip: '67806'},
    {city: 'Rockeskyll', zip: '54570'},
    {city: 'Röckingen', zip: '91740'},
    {city: 'Rockstedt', zip: '99713'},
    {city: 'Röckwitz', zip: '17091'},
    {city: 'Rodalben', zip: '66976'},
    {city: 'Rodder', zip: '53520'},
    {city: 'Rodeberg', zip: '99976'},
    {city: 'Rödelhausen', zip: '56858'},
    {city: 'Rödelmaier', zip: '97618'},
    {city: 'Rödelsee', zip: '97348'},
    {city: 'Roden', zip: '97849'},
    {city: 'Rodenäs', zip: '25924'},
    {city: 'Rodenbach', zip: '67688'},
    {city: 'Rodenbach', zip: '63517'},
    {city: 'Rodenbach bei Puderbach', zip: '57639'},
    {city: 'Rodenbek', zip: '24247'},
    {city: 'Rodenberg', zip: '31552'},
    {city: 'Rödental', zip: '96472'},
    {city: 'Röderaue', zip: '01609'},
    {city: 'Röderland', zip: '04932'},
    {city: 'Rödermark', zip: '63322'},
    {city: 'Rödern', zip: '55481'},
    {city: 'Rodershausen', zip: '54673'},
    {city: 'Rödersheim-Gronau', zip: '67127'},
    {city: 'Rodewald', zip: '31637'},
    {city: 'Rodewisch', zip: '08228'},
    {city: 'Rodgau', zip: '63110'},
    {city: 'Roding', zip: '93426'},
    {city: 'Rödinghausen', zip: '32289'},
    {city: 'Roduchelstorf', zip: '23923'},
    {city: 'Roes', zip: '56754'},
    {city: 'Roetgen', zip: '52159'},
    {city: 'Röfingen', zip: '89365'},
    {city: 'Rogätz', zip: '39326'},
    {city: 'Roggenburg', zip: '89297'},
    {city: 'Roggendorf', zip: '19205'},
    {city: 'Roggenstorf', zip: '23936'},
    {city: 'Roggentin', zip: '18184'},
    {city: 'Rögling', zip: '86703'},
    {city: 'Rögnitz', zip: '19205'},
    {city: 'Röhl', zip: '54636'},
    {city: 'Rohlstorf', zip: '23821'},
    {city: 'Rohr', zip: '91189'},
    {city: 'Rohr', zip: '98530'},
    {city: 'Rohrbach', zip: '85296'},
    {city: 'Rohrbach', zip: '55776'},
    {city: 'Rohrbach', zip: '55490'},
    {city: 'Rohrbach', zip: '76865'},
    {city: 'Rohrbach', zip: '07429'},
    {city: 'Rohrberg', zip: '38489'},
    {city: 'Rohrberg', zip: '37318'},
    {city: 'Rohrdorf', zip: '72229'},
    {city: 'Rohrdorf', zip: '83101'},
    {city: 'Rohrenfels', zip: '86701'},
    {city: 'Röhrig', zip: '37318'},
    {city: 'Rohr i.NB', zip: '93352'},
    {city: 'Röhrmoos', zip: '85244'},
    {city: 'Röhrnbach', zip: '94133'},
    {city: 'Rohrsen', zip: '31627'},
    {city: 'Roigheim', zip: '74255'},
    {city: 'Roklum', zip: '38325'},
    {city: 'Röllbach', zip: '63934'},
    {city: 'Rollshausen', zip: '37434'},
    {city: 'Rollwitz', zip: '17309'},
    {city: 'Rom', zip: '19372'},
    {city: 'Römerberg', zip: '67354'},
    {city: 'Römerstein', zip: '72587'},
    {city: 'Römhild', zip: '98630'},
    {city: 'Rommersheim', zip: '54597'},
    {city: 'Rommerskirchen', zip: '41569'},
    {city: 'Römnitz', zip: '23909'},
    {city: 'Romrod', zip: '36329'},
    {city: 'Römstedt', zip: '29591'},
    {city: 'Rondeshagen', zip: '23919'},
    {city: 'Ronneburg', zip: '63549'},
    {city: 'Ronneburg', zip: '07580'},
    {city: 'Ronnenberg', zip: '30952'},
    {city: 'Ronsberg', zip: '87671'},
    {city: 'Ronshausen', zip: '36217'},
    {city: 'Rorodt', zip: '54411'},
    {city: 'Rosa', zip: '98590'},
    {city: 'Rosbach v. d. Höhe', zip: '61191'},
    {city: 'Roschbach', zip: '76835'},
    {city: 'Rosche', zip: '29571'},
    {city: 'Roscheid', zip: '54619'},
    {city: 'Rosdorf', zip: '25548'},
    {city: 'Rosdorf', zip: '37124'},
    {city: 'Roseburg', zip: '21514'},
    {city: 'Rosenau', zip: '14789'},
    {city: 'Rosenbach', zip: '02708'},
    {city: 'Rosenbach/Vogtland', zip: '08527'},
    {city: 'Rosenbach/Vogtland', zip: '08539'},
    {city: 'Rosenbach/Vogtland', zip: '08548'},
    {city: 'Rosenberg', zip: '74749'},
    {city: 'Rosenberg', zip: '73494'},
    {city: 'Rosendahl', zip: '48720'},
    {city: 'Rosendorf', zip: '07819'},
    {city: 'Rosenfeld', zip: '72348'},
    {city: 'Rosengarten', zip: '21224'},
    {city: 'Rosengarten', zip: '74538'},
    {city: 'Rosenheim', zip: '57520'},
    {city: 'Rosenheim', zip: '83022'},
    {city: 'Rosenheim', zip: '83024'},
    {city: 'Rosenheim', zip: '83026'},
    {city: 'Rosenkopf', zip: '66894'},
    {city: 'Rosenow', zip: '17091'},
    {city: 'Rosenthal', zip: '35119'},
    {city: 'Rosenthal am Rennsteig', zip: '07366'},
    {city: 'Rosenthal-Bielatal', zip: '01824'},
    {city: 'Rositz', zip: '04617'},
    {city: 'Roskow', zip: '14778'},
    {city: 'Röslau', zip: '95195'},
    {city: 'Rösrath', zip: '51503'},
    {city: 'Rossau', zip: '09661'},
    {city: 'Roßbach', zip: '53547'},
    {city: 'Roßbach', zip: '56271'},
    {city: 'Roßbach', zip: '94439'},
    {city: 'Roßdorf', zip: '64380'},
    {city: 'Roßdorf', zip: '98590'},
    {city: 'Roßhaupten', zip: '87672'},
    {city: 'Rossin', zip: '17398'},
    {city: 'Roßleben-Wiehe', zip: '06571'},
    {city: 'Rossow', zip: '17322'},
    {city: 'Roßtal', zip: '90574'},
    {city: 'Roßwein', zip: '04741'},
    {city: 'Rostock', zip: '18055'},
    {city: 'Rostock', zip: '18057'},
    {city: 'Rostock', zip: '18059'},
    {city: 'Rostock', zip: '18069'},
    {city: 'Rostock', zip: '18106'},
    {city: 'Rostock', zip: '18107'},
    {city: 'Rostock', zip: '18109'},
    {city: 'Rostock', zip: '18119'},
    {city: 'Rostock', zip: '18146'},
    {city: 'Rostock', zip: '18147'},
    {city: 'Rostock', zip: '18181'},
    {city: 'Rostock', zip: '18182'},
    {city: 'Rot am See', zip: '74585'},
    {city: 'Rot an der Rot', zip: '88430'},
    {city: 'Rotenburg', zip: '27356'},
    {city: 'Rotenburg an der Fulda', zip: '36199'},
    {city: 'Rotenhain', zip: '56459'},
    {city: 'Rötgesbüttel', zip: '38531'},
    {city: 'Roth', zip: '91154'},
    {city: 'Roth', zip: '57539'},
    {city: 'Roth', zip: '56368'},
    {city: 'Roth', zip: '56288'},
    {city: 'Roth', zip: '55442'},
    {city: 'Rötha', zip: '04571'},
    {city: 'Roth an der Our', zip: '54675'},
    {city: 'Roth bei Prüm', zip: '54597'},
    {city: 'Rothemühl', zip: '17379'},
    {city: 'Rothenbach', zip: '56459'},
    {city: 'Röthenbach (Allgäu)', zip: '88167'},
    {city: 'Röthenbach an der Pegnitz', zip: '90552'},
    {city: 'Rothenbuch', zip: '63860'},
    {city: 'Rothenbuch', zip: '97840'},
    {city: 'Rothenburg ob der Tauber', zip: '91541'},
    {city: 'Rothenburg/Oberlausitz', zip: '02929'},
    {city: 'Rothenfels', zip: '97851'},
    {city: 'Rothenklempenow', zip: '17321'},
    {city: 'Rothenklempenow', zip: '17322'},
    {city: 'Rothenstein', zip: '07751'},
    {city: 'Röthlein', zip: '97520'},
    {city: 'Rothselberg', zip: '67753'},
    {city: 'Rötsweiler-Nockenthal', zip: '55767'},
    {city: 'Rott', zip: '57632'},
    {city: 'Rott', zip: '86935'},
    {city: 'Rottach-Egern', zip: '83700'},
    {city: 'Rott am Inn', zip: '83543'},
    {city: 'Rottenacker', zip: '89616'},
    {city: 'Röttenbach', zip: '91187'},
    {city: 'Röttenbach', zip: '91341'},
    {city: 'Rottenbuch', zip: '82401'},
    {city: 'Rottenburg a. d. Laaber', zip: '84056'},
    {city: 'Rottenburg am Neckar', zip: '72108'},
    {city: 'Rottendorf', zip: '97228'},
    {city: 'Rotthalmünster', zip: '94094'},
    {city: 'Röttingen', zip: '97285'},
    {city: 'Rottweil', zip: '78628'},
    {city: 'Rötz', zip: '92444'},
    {city: 'Rövershagen', zip: '18182'},
    {city: 'Roxheim', zip: '55595'},
    {city: 'Rubenow', zip: '17509'},
    {city: 'Rüber', zip: '56295'},
    {city: 'Rubkow', zip: '17390'},
    {city: 'Rückeroth', zip: '56244'},
    {city: 'Rückersdorf', zip: '90607'},
    {city: 'Rückersdorf', zip: '03238'},
    {city: 'Rückersdorf', zip: '07580'},
    {city: 'Rückholz', zip: '87494'},
    {city: 'Rückweiler', zip: '55776'},
    {city: 'Rudelzhausen', zip: '84104'},
    {city: 'Rüdenau', zip: '63924'},
    {city: 'Rüdenhausen', zip: '97355'},
    {city: 'Ruderatshofen', zip: '87674'},
    {city: 'Rudersberg', zip: '73635'},
    {city: 'Rüdersdorf bei Berlin', zip: '15345'},
    {city: 'Rüdersdorf bei Berlin', zip: '15378'},
    {city: 'Rüdersdorf bei Berlin', zip: '15562'},
    {city: 'Rüdershausen', zip: '37434'},
    {city: 'Ruderting', zip: '94161'},
    {city: 'Rüdesheim', zip: '55593'},
    {city: 'Rüdesheim am Rhein', zip: '65385'},
    {city: 'Rüdnitz', zip: '16321'},
    {city: 'Rudolstadt', zip: '07407'},
    {city: 'Rugendorf', zip: '95365'},
    {city: 'Rügge', zip: '24405'},
    {city: 'Rügland', zip: '91622'},
    {city: 'Rühen', zip: '38471'},
    {city: 'Ruhla', zip: '99842'},
    {city: 'Ruhland', zip: '01945'},
    {city: 'Ruhmannsfelden', zip: '94239'},
    {city: 'Rühn', zip: '18246'},
    {city: 'Ruhner Berge', zip: '19376'},
    {city: 'Ruhpolding', zip: '83324'},
    {city: 'Rühstädt', zip: '19322'},
    {city: 'Ruhstorf a.d.Rott', zip: '94099'},
    {city: 'Ruhwinkel', zip: '24601'},
    {city: 'Rukieten', zip: '18258'},
    {city: 'Rullstorf', zip: '21379'},
    {city: 'Rülzheim', zip: '76761'},
    {city: 'Rumbach', zip: '76891'},
    {city: 'Rümmelsheim', zip: '55452'},
    {city: 'Rümmingen', zip: '79595'},
    {city: 'Rumohr', zip: '24254'},
    {city: 'Rümpel', zip: '23843'},
    {city: 'Runding', zip: '93486'},
    {city: 'Runkel', zip: '65594'},
    {city: 'Ruppach-Goldhausen', zip: '56412'},
    {city: 'Ruppertsberg', zip: '67152'},
    {city: 'Ruppertsecken', zip: '67808'},
    {city: 'Ruppertshofen', zip: '56357'},
    {city: 'Ruppertshofen', zip: '73577'},
    {city: 'Ruppertsweiler', zip: '66957'},
    {city: 'Ruppichteroth', zip: '53809'},
    {city: 'Ruschberg', zip: '55776'},
    {city: 'Rüscheid', zip: '56584'},
    {city: 'Rüsselsheim am Main', zip: '65428'},
    {city: 'Rüssingen', zip: '67308'},
    {city: 'Rust', zip: '77977'},
    {city: 'Rustenfelde', zip: '37318'},
    {city: 'Rutesheim', zip: '71277'},
    {city: 'Rüthen', zip: '59602'},
    {city: 'Rüthnick', zip: '16835'},
    {city: 'Ruthweiler', zip: '66869'},
    {city: 'Rüting', zip: '23936'},
    {city: 'Rutsweiler am Glan', zip: '66887'},
    {city: 'Rutsweiler an der Lauter', zip: '67752'},
    {city: 'Ruttersdorf-Lotschen', zip: '07646'},
    {city: 'Saal', zip: '18317'},
    {city: 'Saal a.d.Donau', zip: '93342'},
    {city: 'Saal a.d.Saale', zip: '97633'},
    {city: 'Saalburg-Ebersdorf', zip: '07929'},
    {city: 'Saaldorf-Surheim', zip: '83416'},
    {city: 'Saalfeld/Saale', zip: '07318'},
    {city: 'Saalstadt', zip: '66919'},
    {city: 'Saara', zip: '07589'},
    {city: 'Saarbrücken', zip: '66111'},
    {city: 'Saarbrücken', zip: '66113'},
    {city: 'Saarbrücken', zip: '66115'},
    {city: 'Saarbrücken', zip: '66117'},
    {city: 'Saarbrücken', zip: '66119'},
    {city: 'Saarbrücken', zip: '66121'},
    {city: 'Saarbrücken', zip: '66123'},
    {city: 'Saarbrücken', zip: '66125'},
    {city: 'Saarbrücken', zip: '66126'},
    {city: 'Saarbrücken', zip: '66127'},
    {city: 'Saarbrücken', zip: '66128'},
    {city: 'Saarbrücken', zip: '66129'},
    {city: 'Saarbrücken', zip: '66130'},
    {city: 'Saarbrücken', zip: '66131'},
    {city: 'Saarbrücken', zip: '66132'},
    {city: 'Saarbrücken', zip: '66133'},
    {city: 'Saarburg', zip: '54439'},
    {city: 'Saarlouis', zip: '66740'},
    {city: 'Saarwellingen', zip: '66793'},
    {city: 'Sachsen bei Ansbach', zip: '91623'},
    {city: 'Sachsenhagen', zip: '31553'},
    {city: 'Sachsenheim', zip: '74343'},
    {city: 'Sachsenkam', zip: '83679'},
    {city: 'Saerbeck', zip: '48369'},
    {city: 'Saffig', zip: '56648'},
    {city: 'Sagard', zip: '18551'},
    {city: 'Sahms', zip: '21493'},
    {city: 'Sailauf', zip: '63877'},
    {city: 'Salach', zip: '73084'},
    {city: 'Salching', zip: '94330'},
    {city: 'Saldenburg', zip: '94163'},
    {city: 'Salem', zip: '23883'},
    {city: 'Salem', zip: '23911'},
    {city: 'Salem', zip: '88682'},
    {city: 'Salgen', zip: '87775'},
    {city: 'Sallgast', zip: '03238'},
    {city: 'Salm', zip: '54570'},
    {city: 'Salmtal', zip: '54528'},
    {city: 'Salz', zip: '97616'},
    {city: 'Salz', zip: '56414'},
    {city: 'Salzatal', zip: '06198'},
    {city: 'Salzbergen', zip: '48499'},
    {city: 'Salzburg', zip: '56479'},
    {city: 'Salzgitter', zip: '38226'},
    {city: 'Salzgitter', zip: '38228'},
    {city: 'Salzgitter', zip: '38229'},
    {city: 'Salzgitter', zip: '38239'},
    {city: 'Salzgitter', zip: '38259'},
    {city: 'Salzhausen', zip: '21376'},
    {city: 'Salzhemmendorf', zip: '31020'},
    {city: 'Salzkotten', zip: '33154'},
    {city: 'Salzwedel', zip: '29410'},
    {city: 'Salzweg', zip: '94121'},
    {city: 'Samerberg', zip: '83122'},
    {city: 'Samern', zip: '48465'},
    {city: 'Samtens', zip: '18573'},
    {city: 'Sand a.Main', zip: '97522'},
    {city: 'Sandau (Elbe)', zip: '39524'},
    {city: 'Sandberg', zip: '97657'},
    {city: 'Sandbostel', zip: '27446'},
    {city: 'Sande', zip: '26452'},
    {city: 'Sandersdorf-Brehna', zip: '06792'},
    {city: 'Sandersdorf-Brehna', zip: '06794'},
    {city: 'Sandersdorf-Brehna', zip: '06796'},
    {city: 'Sandersdorf-Brehna', zip: '06809'},
    {city: 'Sandesneben', zip: '23898'},
    {city: 'Sandhausen', zip: '69207'},
    {city: 'Sangerhausen', zip: '06526'},
    {city: 'Sanitz', zip: '18190'},
    {city: 'Sankt Alban', zip: '67813'},
    {city: 'Sankt Alban', zip: '67822'},
    {city: 'Sankt Aldegund', zip: '56858'},
    {city: 'Sankt Annen', zip: '25776'},
    {city: 'Sankt Augustin', zip: '53757'},
    {city: 'Sankt Bernhard', zip: '98660'},
    {city: 'Sankt Englmar', zip: '94379'},
    {city: 'Sankt Goar', zip: '56329'},
    {city: 'Sankt Goarshausen', zip: '56346'},
    {city: 'Sankt Ingbert', zip: '66386'},
    {city: 'Sankt Johann', zip: '56727'},
    {city: 'Sankt Johann', zip: '55578'},
    {city: 'Sankt Julian', zip: '66887'},
    {city: 'Sankt Katharinen', zip: '53562'},
    {city: 'Sankt Katharinen', zip: '55595'},
    {city: 'Sankt Margarethen', zip: '25572'},
    {city: 'Sankt Martin', zip: '67487'},
    {city: 'Sankt Michaelisdonn', zip: '25693'},
    {city: 'Sankt Oswald-Riedlhütte', zip: '94566'},
    {city: 'Sankt Oswald-Riedlhütte', zip: '94568'},
    {city: 'Sankt Peter', zip: '79271'},
    {city: 'Sankt Peter-Ording', zip: '25826'},
    {city: 'Sankt Sebastian', zip: '56220'},
    {city: 'Sankt Thomas', zip: '54655'},
    {city: 'Sankt Wendel', zip: '66606'},
    {city: 'Sankt Wolfgang', zip: '84427'},
    {city: 'Sargenroth', zip: '55471'},
    {city: 'Sarlhusen', zip: '24616'},
    {city: 'Sarmersbach', zip: '54552'},
    {city: 'Sarmstorf', zip: '18276'},
    {city: 'Sarnow', zip: '17392'},
    {city: 'Sarow', zip: '17111'},
    {city: 'Sarstedt', zip: '31157'},
    {city: 'Sarzbüttel', zip: '25785'},
    {city: 'Sasbach', zip: '77880'},
    {city: 'Sasbach am Kaiserstuhl', zip: '79361'},
    {city: 'Sasbachwalden', zip: '77887'},
    {city: 'Sassen', zip: '56767'},
    {city: 'Sassenberg', zip: '48336'},
    {city: 'Sassenburg', zip: '38524'},
    {city: 'Sassen-Trantow', zip: '17121'},
    {city: 'Sassnitz', zip: '18546'},
    {city: 'Saterland', zip: '26683'},
    {city: 'Satow', zip: '18239'},
    {city: 'Satteldorf', zip: '74589'},
    {city: 'Sauensiek', zip: '21644'},
    {city: 'Sauerlach', zip: '82054'},
    {city: 'Sauerthal', zip: '65391'},
    {city: 'Sauldorf', zip: '88605'},
    {city: 'Saulgrub', zip: '82442'},
    {city: 'Saulheim', zip: '55291'},
    {city: 'Saustrup', zip: '24392'},
    {city: 'Sauzin', zip: '17440'},
    {city: 'Saxler', zip: '54558'},
    {city: 'Sayda', zip: '09619'},
    {city: 'Schaafheim', zip: '64850'},
    {city: 'Schaalby', zip: '24882'},
    {city: 'Schacht-Audorf', zip: '24790'},
    {city: 'Schacht-Audorf', zip: '24794'},
    {city: 'Schachtebich', zip: '37318'},
    {city: 'Schackendorf', zip: '23795'},
    {city: 'Schafflund', zip: '24980'},
    {city: 'Schafstedt', zip: '25725'},
    {city: 'Schäftlarn', zip: '82067'},
    {city: 'Schäftlarn', zip: '82069'},
    {city: 'Schalkau', zip: '96528'},
    {city: 'Schalkenbach', zip: '53426'},
    {city: 'Schalkenmehren', zip: '54552'},
    {city: 'Schalkham', zip: '84175'},
    {city: 'Schalkholz', zip: '25782'},
    {city: 'Schalksmühle', zip: '58579'},
    {city: 'Schallbach', zip: '79597'},
    {city: 'Schallodenbach', zip: '67701'},
    {city: 'Schallstadt', zip: '79227'},
    {city: 'Schankweiler', zip: '54668'},
    {city: 'Schapen', zip: '48480'},
    {city: 'Schaprode', zip: '18569'},
    {city: 'Scharbeutz', zip: '23629'},
    {city: 'Scharbeutz', zip: '23683'},
    {city: 'Scharbeutz', zip: '23684'},
    {city: 'Scharfbillig', zip: '54636'},
    {city: 'Scharnebeck', zip: '21379'},
    {city: 'Schashagen', zip: '23730'},
    {city: 'Schauenburg', zip: '34270'},
    {city: 'Schauenstein', zip: '95197'},
    {city: 'Schauerberg', zip: '66919'},
    {city: 'Schaufling', zip: '94571'},
    {city: 'Schauren', zip: '55758'},
    {city: 'Schauren', zip: '56865'},
    {city: 'Schechen', zip: '83135'},
    {city: 'Schechingen', zip: '73579'},
    {city: 'Scheden', zip: '37127'},
    {city: 'Scheer', zip: '72516'},
    {city: 'Scheeßel', zip: '27383'},
    {city: 'Schefflenz', zip: '74850'},
    {city: 'Scheggerott', zip: '24392'},
    {city: 'Scheibenberg', zip: '09481'},
    {city: 'Scheibenhardt', zip: '76779'},
    {city: 'Scheid', zip: '54611'},
    {city: 'Scheidegg', zip: '88175'},
    {city: 'Scheiditz', zip: '07646'},
    {city: 'Scheidt', zip: '56379'},
    {city: 'Scheinfeld', zip: '91443'},
    {city: 'Scheitenkorb', zip: '54673'},
    {city: 'Schelklingen', zip: '89601'},
    {city: 'Schellerten', zip: '31174'},
    {city: 'Schellhorn', zip: '24211'},
    {city: 'Schellweiler', zip: '66869'},
    {city: 'Schemmerhofen', zip: '88433'},
    {city: 'Schenefeld', zip: '22869'},
    {city: 'Schenefeld', zip: '25560'},
    {city: 'Schenkelberg', zip: '56244'},
    {city: 'Schenkenberg', zip: '17291'},
    {city: 'Schenkendöbern', zip: '03172'},
    {city: 'Schenkenzell', zip: '77773'},
    {city: 'Schenklengsfeld', zip: '36277'},
    {city: 'Schermbeck', zip: '46514'},
    {city: 'Schernfeld', zip: '85132'},
    {city: 'Scherstetten', zip: '86872'},
    {city: 'Scheßlitz', zip: '96110'},
    {city: 'Scheuerfeld', zip: '57584'},
    {city: 'Scheuern', zip: '54673'},
    {city: 'Scheuring', zip: '86937'},
    {city: 'Scheyern', zip: '85298'},
    {city: 'Schieder-Schwalenberg', zip: '32816'},
    {city: 'Schieren', zip: '23795'},
    {city: 'Schierensee', zip: '24241'},
    {city: 'Schierling', zip: '84069'},
    {city: 'Schiersfeld', zip: '67808'},
    {city: 'Schiersfeld', zip: '67823'},
    {city: 'Schiesheim', zip: '65623'},
    {city: 'Schiffdorf', zip: '27619'},
    {city: 'Schifferstadt', zip: '67105'},
    {city: 'Schiffweiler', zip: '66578'},
    {city: 'Schilda', zip: '03253'},
    {city: 'Schildetal', zip: '19209'},
    {city: 'Schillingen', zip: '54429'},
    {city: 'Schillingsfürst', zip: '91583'},
    {city: 'Schillsdorf', zip: '24637'},
    {city: 'Schiltach', zip: '77761'},
    {city: 'Schiltberg', zip: '86576'},
    {city: 'Schimberg', zip: '37308'},
    {city: 'Schindhard', zip: '66996'},
    {city: 'Schinkel', zip: '24214'},
    {city: 'Schiphorst', zip: '23847'},
    {city: 'Schipkau', zip: '01968'},
    {city: 'Schipkau', zip: '01993'},
    {city: 'Schipkau', zip: '01994'},
    {city: 'Schipkau', zip: '01998'},
    {city: 'Schirgiswalde-Kirschau', zip: '02681'},
    {city: 'Schirmitz', zip: '92718'},
    {city: 'Schirnding', zip: '95706'},
    {city: 'Schkeuditz', zip: '04435'},
    {city: 'Schkölen', zip: '07619'},
    {city: 'Schkopau', zip: '06258'},
    {city: 'Schladen-Werla', zip: '38315'},
    {city: 'Schladt', zip: '54534'},
    {city: 'Schlagsdorf', zip: '19217'},
    {city: 'Schlaitdorf', zip: '72667'},
    {city: 'Schlammersdorf', zip: '95519'},
    {city: 'Schlangen', zip: '33189'},
    {city: 'Schlangenbad', zip: '65388'},
    {city: 'Schlat', zip: '73114'},
    {city: 'Schlaubetal', zip: '15890'},
    {city: 'Schleching', zip: '83259'},
    {city: 'Schlechtsart', zip: '98663'},
    {city: 'Schlehdorf', zip: '82439'},
    {city: 'Schlehdorf', zip: '82444'},
    {city: 'Schleich', zip: '54340'},
    {city: 'Schleid', zip: '54636'},
    {city: 'Schleid', zip: '36419'},
    {city: 'Schleiden', zip: '53937'},
    {city: 'Schleife', zip: '02959'},
    {city: 'Schleifreisen', zip: '07629'},
    {city: 'Schleiz', zip: '07907'},
    {city: 'Schlemmin', zip: '18320'},
    {city: 'Schlepzig', zip: '15910'},
    {city: 'Schlesen', zip: '24256'},
    {city: 'Schleswig', zip: '24837'},
    {city: 'Schlettau', zip: '09487'},
    {city: 'Schleusegrund', zip: '98666'},
    {city: 'Schleusegrund', zip: '98667'},
    {city: 'Schleusingen', zip: '98553'},
    {city: 'Schlichting', zip: '25779'},
    {city: 'Schlieben', zip: '04936'},
    {city: 'Schliengen', zip: '79418'},
    {city: 'Schlier', zip: '88281'},
    {city: 'Schlierbach', zip: '73278'},
    {city: 'Schlierschied', zip: '55483'},
    {city: 'Schliersee', zip: '83727'},
    {city: 'Schlitz', zip: '36110'},
    {city: 'Schlöben', zip: '07646'},
    {city: 'Schloen-Dratow', zip: '17192'},
    {city: 'Schloen-Dratow', zip: '17219'},
    {city: 'Schloßböckelheim', zip: '55596'},
    {city: 'Schloß Holte-Stukenbrock', zip: '33758'},
    {city: 'Schloßvippach', zip: '99195'},
    {city: 'Schlotfeld', zip: '25551'},
    {city: 'Schluchsee', zip: '79859'},
    {city: 'Schlüchtern', zip: '36381'},
    {city: 'Schlüsselfeld', zip: '96132'},
    {city: 'Schmalenberg', zip: '67718'},
    {city: 'Schmalensee', zip: '24638'},
    {city: 'Schmalfeld', zip: '24640'},
    {city: 'Schmalkalden', zip: '98574'},
    {city: 'Schmalkalden', zip: '98587'},
    {city: 'Schmallenberg', zip: '57392'},
    {city: 'Schmalstede', zip: '24241'},
    {city: 'Schmatzin', zip: '17390'},
    {city: 'Schmedeswurth', zip: '25724'},
    {city: 'Schmeheim', zip: '98530'},
    {city: 'Schmelz', zip: '66839'},
    {city: 'Schmidgaden', zip: '92546'},
    {city: 'Schmidmühlen', zip: '92287'},
    {city: 'Schmidthachenbach', zip: '55758'},
    {city: 'Schmiechen', zip: '86511'},
    {city: 'Schmiedehausen', zip: '99518'},
    {city: 'Schmieritz', zip: '07819'},
    {city: 'Schmilau', zip: '23883'},
    {city: 'Schmilau', zip: '23909'},
    {city: 'Schmilau', zip: '23911'},
    {city: 'Schmißberg', zip: '55765'},
    {city: 'Schmitshausen', zip: '66484'},
    {city: 'Schmitshausen', zip: '66917'},
    {city: 'Schmitt', zip: '56825'},
    {city: 'Schmitten', zip: '61389'},
    {city: 'Schmittweiler', zip: '67829'},
    {city: 'Schmogrow-Fehrow', zip: '03096'},
    {city: 'Schmölln', zip: '04626'},
    {city: 'Schmölln-Putzkau', zip: '01877'},
    {city: 'Schmorda', zip: '07389'},
    {city: 'Schnabelwaid', zip: '91289'},
    {city: 'Schnackenburg', zip: '29493'},
    {city: 'Schnaitsee', zip: '83530'},
    {city: 'Schnaittach', zip: '91220'},
    {city: 'Schnaittenbach', zip: '92253'},
    {city: 'Schnakenbek', zip: '21481'},
    {city: 'Schnarup-Thumby', zip: '24891'},
    {city: 'Schnaudertal', zip: '06712'},
    {city: 'Schneckenhausen', zip: '67699'},
    {city: 'Schneckenlohe', zip: '96277'},
    {city: 'Schneeberg', zip: '63936'},
    {city: 'Schneeberg', zip: '08289'},
    {city: 'Schnega', zip: '29465'},
    {city: 'Schneizlreuth', zip: '83458'},
    {city: 'Schnelldorf', zip: '91625'},
    {city: 'Schneppenbach', zip: '55608'},
    {city: 'Schneverdingen', zip: '29640'},
    {city: 'Schnorbach', zip: '55497'},
    {city: 'Schnürpflingen', zip: '89194'},
    {city: 'Schoden', zip: '54441'},
    {city: 'Schöffengrund', zip: '35641'},
    {city: 'Schöfweg', zip: '94572'},
    {city: 'Scholen', zip: '27251'},
    {city: 'Schollbrunn', zip: '97852'},
    {city: 'Schollbrunn', zip: '97907'},
    {city: 'Schollene', zip: '14715'},
    {city: 'Schöllkrippen', zip: '63825'},
    {city: 'Schöllnach', zip: '94508'},
    {city: 'Schömberg', zip: '75328'},
    {city: 'Schömberg', zip: '72355'},
    {city: 'Schömerich', zip: '54314'},
    {city: 'Schonach im Schwarzwald', zip: '78136'},
    {city: 'Schönaich', zip: '71101'},
    {city: 'Schönau', zip: '69250'},
    {city: 'Schönau', zip: '84337'},
    {city: 'Schönau a.d.Brend', zip: '97659'},
    {city: 'Schönau am Königssee', zip: '83471'},
    {city: 'Schönau-Berzdorf auf dem Eigen', zip: '02899'},
    {city: 'Schönau im Schwarzwald', zip: '79677'},
    {city: 'Schönau (Pfalz)', zip: '66996'},
    {city: 'Schönbach', zip: '54552'},
    {city: 'Schönbach', zip: '02708'},
    {city: 'Schönbeck', zip: '17349'},
    {city: 'Schönbek', zip: '24582'},
    {city: 'Schönberg', zip: '08393'},
    {city: 'Schönberg', zip: '84573'},
    {city: 'Schönberg', zip: '94513'},
    {city: 'Schönberg', zip: '54426'},
    {city: 'Schönberg', zip: '22929'},
    {city: 'Schönberg', zip: '23923'},
    {city: 'Schönberg (Holstein)', zip: '24217'},
    {city: 'Schönborn', zip: '56370'},
    {city: 'Schönborn', zip: '55469'},
    {city: 'Schönborn', zip: '67808'},
    {city: 'Schönborn', zip: '03253'},
    {city: 'Schönbrunn', zip: '69436'},
    {city: 'Schönbrunn i.Steigerwald', zip: '96185'},
    {city: 'Schönburg', zip: '06618'},
    {city: 'Schöndorf', zip: '54316'},
    {city: 'Schöndorf', zip: '07924'},
    {city: 'Schondorf am Ammersee', zip: '86938'},
    {city: 'Schondra', zip: '97795'},
    {city: 'Schönebeck (Elbe)', zip: '39217'},
    {city: 'Schönebeck (Elbe)', zip: '39218'},
    {city: 'Schöneberg', zip: '57638'},
    {city: 'Schöneberg', zip: '55444'},
    {city: 'Schöneberg', zip: '16278'},
    {city: 'Schöneck', zip: '61137'},
    {city: 'Schönecken', zip: '54614'},
    {city: 'Schöneck/Vogtl.', zip: '08261'},
    {city: 'Schönefeld', zip: '12529'},
    {city: 'Schöneiche bei Berlin', zip: '15566'},
    {city: 'Schönenberg', zip: '79677'},
    {city: 'Schönenberg-Kübelberg', zip: '66901'},
    {city: 'Schönermark', zip: '16775'},
    {city: 'Schönewalde', zip: '04916'},
    {city: 'Schönewörde', zip: '29396'},
    {city: 'Schönfeld', zip: '01561'},
    {city: 'Schönfeld', zip: '17291'},
    {city: 'Schönfeld', zip: '17111'},
    {city: 'Schongau', zip: '86956'},
    {city: 'Schöngeising', zip: '82296'},
    {city: 'Schöngleina', zip: '07646'},
    {city: 'Schönhagen', zip: '37318'},
    {city: 'Schönhausen', zip: '17337'},
    {city: 'Schönhausen (Elbe)', zip: '39524'},
    {city: 'Schönheide', zip: '08304'},
    {city: 'Schönhorst', zip: '24220'},
    {city: 'Schöningen', zip: '38364'},
    {city: 'Schönkirchen', zip: '24232'},
    {city: 'Schönsee', zip: '92539'},
    {city: 'Schönstedt', zip: '99947'},
    {city: 'Schonstett', zip: '83137'},
    {city: 'Schöntal', zip: '74214'},
    {city: 'Schönthal', zip: '93488'},
    {city: 'Schonungen', zip: '97453'},
    {city: 'Schönwald', zip: '95173'},
    {city: 'Schönwald', zip: '15910'},
    {city: 'Schönwalde', zip: '17309'},
    {city: 'Schönwalde am Bungsberg', zip: '23744'},
    {city: 'Schönwalde-Glien', zip: '14621'},
    {city: 'Schönwald im Schwarzwald', zip: '78141'},
    {city: 'Schönwölkau', zip: '04509'},
    {city: 'Schopfheim', zip: '79650'},
    {city: 'Schopfloch', zip: '91626'},
    {city: 'Schopfloch', zip: '72296'},
    {city: 'Schopp', zip: '67707'},
    {city: 'Schöppenstedt', zip: '38170'},
    {city: 'Schöppingen', zip: '48624'},
    {city: 'Schöps', zip: '07768'},
    {city: 'Schöpstal', zip: '02829'},
    {city: 'Schorfheide', zip: '16244'},
    {city: 'Schorndorf', zip: '73614'},
    {city: 'Schorndorf', zip: '93489'},
    {city: 'Schornsheim', zip: '55288'},
    {city: 'Schorssow', zip: '17166'},
    {city: 'Schortens', zip: '26419'},
    {city: 'Schossin', zip: '19073'},
    {city: 'Schossin', zip: '19075'},
    {city: 'Schotten', zip: '63679'},
    {city: 'Schraden', zip: '04928'},
    {city: 'Schramberg', zip: '78144'},
    {city: 'Schramberg', zip: '78713'},
    {city: 'Schraplau', zip: '06279'},
    {city: 'Schrecksbach', zip: '34637'},
    {city: 'Schretstaken', zip: '21493'},
    {city: 'Schriesheim', zip: '69198'},
    {city: 'Schrobenhausen', zip: '86529'},
    {city: 'Schrozberg', zip: '74575'},
    {city: 'Schrum', zip: '25782'},
    {city: 'Schuby', zip: '24837'},
    {city: 'Schuby', zip: '24850'},
    {city: 'Schuld', zip: '53520'},
    {city: 'Schulendorf', zip: '21516'},
    {city: 'Schülldorf', zip: '24790'},
    {city: 'Schüller', zip: '54586'},
    {city: 'Schülp', zip: '25764'},
    {city: 'Schülp bei Nortorf', zip: '24589'},
    {city: 'Schülp bei Rendsburg', zip: '24813'},
    {city: 'Schulzendorf', zip: '15732'},
    {city: 'Schürdt', zip: '57632'},
    {city: 'Schürensöhlen', zip: '23847'},
    {city: 'Schuttertal', zip: '77978'},
    {city: 'Schutterwald', zip: '77746'},
    {city: 'Schüttorf', zip: '48465'},
    {city: 'Schutz', zip: '54570'},
    {city: 'Schutzbach', zip: '57520'},
    {city: 'Schwaan', zip: '18258'},
    {city: 'Schwaara', zip: '07554'},
    {city: 'Schwabach', zip: '91126'},
    {city: 'Schwabbruck', zip: '86986'},
    {city: 'Schwabenheim an der Selz', zip: '55270'},
    {city: 'Schwabhausen', zip: '85247'},
    {city: 'Schwabhausen', zip: '99869'},
    {city: 'Schwäbisch Gmünd', zip: '73072'},
    {city: 'Schwäbisch Gmünd', zip: '73525'},
    {city: 'Schwäbisch Gmünd', zip: '73527'},
    {city: 'Schwäbisch Gmünd', zip: '73529'},
    {city: 'Schwäbisch Gmünd', zip: '73550'},
    {city: 'Schwäbisch Gmünd', zip: '73553'},
    {city: 'Schwäbisch Hall', zip: '74523'},
    {city: 'Schwabmünchen', zip: '86830'},
    {city: 'Schwabsoien', zip: '86987'},
    {city: 'Schwabstedt', zip: '25876'},
    {city: 'Schwaförden', zip: '27252'},
    {city: 'Schwaig bei Nürnberg', zip: '90571'},
    {city: 'Schwaigen', zip: '82445'},
    {city: 'Schwaigern', zip: '74193'},
    {city: 'Schwaikheim', zip: '71409'},
    {city: 'Schwalbach', zip: '65824'},
    {city: 'Schwalbach', zip: '66773'},
    {city: 'Schwall', zip: '56281'},
    {city: 'Schwallungen', zip: '98590'},
    {city: 'Schwalmstadt', zip: '34613'},
    {city: 'Schwalmtal', zip: '41366'},
    {city: 'Schwalmtal', zip: '36318'},
    {city: 'Schwanau', zip: '77963'},
    {city: 'Schwandorf', zip: '92421'},
    {city: 'Schwanebeck', zip: '39397'},
    {city: 'Schwanewede', zip: '28790'},
    {city: 'Schwanfeld', zip: '97523'},
    {city: 'Schwangau', zip: '87645'},
    {city: 'Schwanheide', zip: '19258'},
    {city: 'Schwanheim', zip: '76848'},
    {city: 'Schwanstetten', zip: '90596'},
    {city: 'Schwarme', zip: '27327'},
    {city: 'Schwarmstedt', zip: '29690'},
    {city: 'Schwartbuck', zip: '24257'},
    {city: 'Schwarz', zip: '17252'},
    {city: 'Schwarza', zip: '98547'},
    {city: 'Schwarzach', zip: '74869'},
    {city: 'Schwarzach', zip: '94374'},
    {city: 'Schwarzach am Main', zip: '97359'},
    {city: 'Schwarzach b.Nabburg', zip: '92548'},
    {city: 'Schwarzatal', zip: '98744'},
    {city: 'Schwarzbach', zip: '01945'},
    {city: 'Schwarzbach', zip: '07589'},
    {city: 'Schwarzburg', zip: '07427'},
    {city: 'Schwarzen', zip: '55481'},
    {city: 'Schwarzenbach', zip: '92720'},
    {city: 'Schwarzenbach a.d.Saale', zip: '95126'},
    {city: 'Schwarzenbach a.Wald', zip: '95131'},
    {city: 'Schwarzenbek', zip: '21493'},
    {city: 'Schwarzenberg/Erzgebirge', zip: '08340'},
    {city: 'Schwarzenborn', zip: '34639'},
    {city: 'Schwarzenborn', zip: '54533'},
    {city: 'Schwarzenbruck', zip: '90592'},
    {city: 'Schwarzenfeld', zip: '92521'},
    {city: 'Schwarzerden', zip: '55629'},
    {city: 'Schwarzheide', zip: '01987'},
    {city: 'Schwarzhofen', zip: '92447'},
    {city: 'Schwasdorf', zip: '17168'},
    {city: 'Schwebheim', zip: '97525'},
    {city: 'Schwedelbach', zip: '67685'},
    {city: 'Schwedeneck', zip: '24229'},
    {city: 'Schwedt/Oder', zip: '16303'},
    {city: 'Schwegenheim', zip: '67365'},
    {city: 'Schweich', zip: '54338'},
    {city: 'Schweickershausen', zip: '98663'},
    {city: 'Schweigen-Rechtenbach', zip: '76889'},
    {city: 'Schweighausen', zip: '56377'},
    {city: 'Schweighofen', zip: '76889'},
    {city: 'Schweindorf', zip: '26556'},
    {city: 'Schweinfurt', zip: '97421'},
    {city: 'Schweinfurt', zip: '97422'},
    {city: 'Schweinfurt', zip: '97424'},
    {city: 'Schweinschied', zip: '67744'},
    {city: 'Schweisweiler', zip: '67808'},
    {city: 'Schweitenkirchen', zip: '85301'},
    {city: 'Schweix', zip: '66957'},
    {city: 'Schwelm', zip: '58332'},
    {city: 'Schwendi', zip: '88477'},
    {city: 'Schwenningen', zip: '89443'},
    {city: 'Schwenningen', zip: '72477'},
    {city: 'Schwentinental', zip: '24222'},
    {city: 'Schwentinental', zip: '24223'},
    {city: 'Schwepnitz', zip: '01936'},
    {city: 'Schweppenhausen', zip: '55444'},
    {city: 'Schwerbach', zip: '55624'},
    {city: 'Schwerin', zip: '19053'},
    {city: 'Schwerin', zip: '19055'},
    {city: 'Schwerin', zip: '19057'},
    {city: 'Schwerin', zip: '19059'},
    {city: 'Schwerin', zip: '19061'},
    {city: 'Schwerin', zip: '19063'},
    {city: 'Schwerin', zip: '15755'},
    {city: 'Schweringen', zip: '27324'},
    {city: 'Schweringen', zip: '27333'},
    {city: 'Schwerinsdorf', zip: '26835'},
    {city: 'Schwerstedt', zip: '99634'},
    {city: 'Schwerte', zip: '58239'},
    {city: 'Schwesing', zip: '25813'},
    {city: 'Schwetzingen', zip: '68723'},
    {city: 'Schwieberdingen', zip: '71701'},
    {city: 'Schwieberdingen', zip: '71706'},
    {city: 'Schwielochsee', zip: '15913'},
    {city: 'Schwielowsee', zip: '14548'},
    {city: 'Schwienau', zip: '29593'},
    {city: 'Schwifting', zip: '86940'},
    {city: 'Schwindegg', zip: '84419'},
    {city: 'Schwirzheim', zip: '54597'},
    {city: 'Schwissel', zip: '23795'},
    {city: 'Schwobfeld', zip: '37318'},
    {city: 'Schwollen', zip: '55767'},
    {city: 'Schwörstadt', zip: '79739'},
    {city: 'Schwülper', zip: '38179'},
    {city: 'Sebnitz', zip: '01855'},
    {city: 'Seck', zip: '56479'},
    {city: 'Seckach', zip: '74743'},
    {city: 'Seddiner See', zip: '14554'},
    {city: 'Seebach', zip: '77889'},
    {city: 'Seebach', zip: '99846'},
    {city: 'Seeblick', zip: '14715'},
    {city: 'Seeburg', zip: '37136'},
    {city: 'Seedorf', zip: '23823'},
    {city: 'Seedorf', zip: '27404'},
    {city: 'Seedorf', zip: '23883'},
    {city: 'Seefeld', zip: '25557'},
    {city: 'Seefeld', zip: '82229'},
    {city: 'Seeg', zip: '87637'},
    {city: 'Seegebiet Mansfelder Land', zip: '06317'},
    {city: 'Seehausen', zip: '39615'},
    {city: 'Seehausen am Staffelsee', zip: '82418'},
    {city: 'Seeheim-Jugenheim', zip: '64342'},
    {city: 'Seehof', zip: '19069'},
    {city: 'Seekirch', zip: '88422'},
    {city: 'Seeland', zip: '06449'},
    {city: 'Seeland', zip: '06464'},
    {city: 'Seeland', zip: '06466'},
    {city: 'Seeland', zip: '06467'},
    {city: 'Seeland', zip: '06469'},
    {city: 'Seelbach', zip: '77960'},
    {city: 'Seelbach', zip: '56377'},
    {city: 'Seelbach bei Hamm (Sieg)', zip: '57577'},
    {city: 'Seelbach (Westerwald)', zip: '57632'},
    {city: 'Seelen', zip: '67744'},
    {city: 'Seelingstädt', zip: '07580'},
    {city: 'Seelitz', zip: '09306'},
    {city: 'Seelow', zip: '15306'},
    {city: 'Seelze', zip: '30926'},
    {city: 'Seeon-Seebruck', zip: '83358'},
    {city: 'Seeon-Seebruck', zip: '83370'},
    {city: 'Seeon-Seebruck', zip: '83376'},
    {city: 'Seesbach', zip: '55629'},
    {city: 'Seesen', zip: '38723'},
    {city: 'Seeshaupt', zip: '82402'},
    {city: 'Seester', zip: '25370'},
    {city: 'Seestermühe', zip: '25371'},
    {city: 'Seeth', zip: '25840'},
    {city: 'Seeth', zip: '25878'},
    {city: 'Seeth-Ekholt', zip: '25337'},
    {city: 'Seevetal', zip: '21217'},
    {city: 'Seevetal', zip: '21218'},
    {city: 'Seevetal', zip: '21220'},
    {city: 'Seewald', zip: '72297'},
    {city: 'Seffern', zip: '54636'},
    {city: 'Sefferweich', zip: '54636'},
    {city: 'Seggebruch', zip: '31691'},
    {city: 'Segnitz', zip: '97340'},
    {city: 'Sehestedt', zip: '24814'},
    {city: 'Sehlde', zip: '38279'},
    {city: 'Sehlem', zip: '54518'},
    {city: 'Sehlen', zip: '18528'},
    {city: 'Sehmatal', zip: '09465'},
    {city: 'Sehnde', zip: '31319'},
    {city: 'Seibersbach', zip: '55444'},
    {city: 'Seifen', zip: '57632'},
    {city: 'Seiffen/Erzgebirge', zip: '09548'},
    {city: 'Seifhennersdorf', zip: '02782'},
    {city: 'Seinsfeld', zip: '54655'},
    {city: 'Seinsheim', zip: '97342'},
    {city: 'Seisla', zip: '07389'},
    {city: 'Seitenroda', zip: '07768'},
    {city: 'Seitingen-Oberflacht', zip: '78606'},
    {city: 'Seiwerath', zip: '54597'},
    {city: 'Selb', zip: '95100'},
    {city: 'Selbach (Sieg)', zip: '57537'},
    {city: 'Selbitz', zip: '95152'},
    {city: 'Selchenbach', zip: '66871'},
    {city: 'Selent', zip: '24238'},
    {city: 'Selfkant', zip: '52538'},
    {city: 'Seligenstadt', zip: '63500'},
    {city: 'Selk', zip: '24884'},
    {city: 'Selke-Aue', zip: '06458'},
    {city: 'Sellerich', zip: '54608'},
    {city: 'Sellin', zip: '18586'},
    {city: 'Selm', zip: '59379'},
    {city: 'Selmsdorf', zip: '23923'},
    {city: 'Selpin', zip: '18195'},
    {city: 'Selsingen', zip: '27446'},
    {city: 'Selters (Taunus)', zip: '65618'},
    {city: 'Selters (Westerwald)', zip: '56242'},
    {city: 'Selzen', zip: '55278'},
    {city: 'Sembach', zip: '67681'},
    {city: 'Semlow', zip: '18334'},
    {city: 'Senden', zip: '48308'},
    {city: 'Senden', zip: '89250'},
    {city: 'Sendenhorst', zip: '48324'},
    {city: 'Senftenberg', zip: '01945'},
    {city: 'Senftenberg', zip: '01968'},
    {city: 'Senftenberg', zip: '01996'},
    {city: 'Sengenthal', zip: '92369'},
    {city: 'Sengerich', zip: '54619'},
    {city: 'Senheim', zip: '56820'},
    {city: 'Sennfeld', zip: '97526'},
    {city: 'Senscheid', zip: '53520'},
    {city: 'Sensweiler', zip: '55758'},
    {city: 'Serba', zip: '07616'},
    {city: 'Serrig', zip: '54455'},
    {city: 'Sersheim', zip: '74372'},
    {city: 'Sessenbach', zip: '56237'},
    {city: 'Sessenhausen', zip: '56244'},
    {city: 'Seßlach', zip: '96145'},
    {city: 'Seth', zip: '23845'},
    {city: 'Setzingen', zip: '89129'},
    {city: 'Seubersdorf in der Oberpfalz', zip: '92358'},
    {city: 'Seukendorf', zip: '90556'},
    {city: 'Seulingen', zip: '37136'},
    {city: 'Sevenig bei Neuerburg', zip: '54673'},
    {city: 'Sevenig (Our)', zip: '54617'},
    {city: 'Sexau', zip: '79350'},
    {city: 'Seybothenreuth', zip: '95517'},
    {city: 'Sibbesse', zip: '31079'},
    {city: 'Sickerode', zip: '37308'},
    {city: 'Sickte', zip: '38173'},
    {city: 'Siebeldingen', zip: '76833'},
    {city: 'Siebenbach', zip: '56729'},
    {city: 'Siebenbäumen', zip: '23847'},
    {city: 'Siebeneichen', zip: '21514'},
    {city: 'Siedenbollentin', zip: '17089'},
    {city: 'Siedenbrünzow', zip: '17111'},
    {city: 'Siedenburg', zip: '27254'},
    {city: 'Siefersheim', zip: '55599'},
    {city: 'Siegbach', zip: '35768'},
    {city: 'Siegburg', zip: '53721'},
    {city: 'Siegelsbach', zip: '74936'},
    {city: 'Siegen', zip: '57072'},
    {city: 'Siegen', zip: '57074'},
    {city: 'Siegen', zip: '57076'},
    {city: 'Siegen', zip: '57078'},
    {city: 'Siegen', zip: '57080'},
    {city: 'Siegenburg', zip: '93354'},
    {city: 'Siegsdorf', zip: '83313'},
    {city: 'Siehdichum', zip: '15890'},
    {city: 'Siek', zip: '22962'},
    {city: 'Sielenbach', zip: '86577'},
    {city: 'Siemz-Niendorf', zip: '23923'},
    {city: 'Sien', zip: '55758'},
    {city: 'Sienhachenbach', zip: '55758'},
    {city: 'Sierksdorf', zip: '23730'},
    {city: 'Sierksrade', zip: '23847'},
    {city: 'Sierscheid', zip: '53520'},
    {city: 'Siershahn', zip: '56427'},
    {city: 'Siesbach', zip: '55767'},
    {city: 'Sietow', zip: '17209'},
    {city: 'Sieversdorf-Hohenofen', zip: '16845'},
    {city: 'Sievershütten', zip: '24641'},
    {city: 'Sieverstedt', zip: '24885'},
    {city: 'Siggelkow', zip: '19376'},
    {city: 'Sigmaringen', zip: '72488'},
    {city: 'Sigmaringendorf', zip: '72517'},
    {city: 'Sigmarszell', zip: '88138'},
    {city: 'Silberstedt', zip: '24887'},
    {city: 'Silbitz', zip: '07613'},
    {city: 'Silz', zip: '76857'},
    {city: 'Silz', zip: '17214'},
    {city: 'Silzen', zip: '25551'},
    {city: 'Simbach', zip: '84359'},
    {city: 'Simbach', zip: '94436'},
    {city: 'Simmelsdorf', zip: '91245'},
    {city: 'Simmerath', zip: '52152'},
    {city: 'Simmern', zip: '56337'},
    {city: 'Simmern/Hunsrück', zip: '55469'},
    {city: 'Simmersfeld', zip: '72226'},
    {city: 'Simmershofen', zip: '97215'},
    {city: 'Simmertal', zip: '55618'},
    {city: 'Simmozheim', zip: '75397'},
    {city: 'Simonsberg', zip: '25813'},
    {city: 'Simonswald', zip: '79263'},
    {city: 'Simonswald', zip: '79271'},
    {city: 'Sindelfingen', zip: '71063'},
    {city: 'Sindelfingen', zip: '71065'},
    {city: 'Sindelfingen', zip: '71067'},
    {city: 'Sindelfingen', zip: '71069'},
    {city: 'Sindelsdorf', zip: '82404'},
    {city: 'Singen (Hohentwiel)', zip: '78224'},
    {city: 'Singhofen', zip: '56379'},
    {city: 'Sinn', zip: '35764'},
    {city: 'Sinntal', zip: '36391'},
    {city: 'Sinsheim', zip: '74889'},
    {city: 'Sinspelt', zip: '54675'},
    {city: 'Sinzheim', zip: '76547'},
    {city: 'Sinzig', zip: '53489'},
    {city: 'Sinzing', zip: '93161'},
    {city: 'Sippersfeld', zip: '67729'},
    {city: 'Sipplingen', zip: '78354'},
    {city: 'Sirksfelde', zip: '23898'},
    {city: 'Sittensen', zip: '27419'},
    {city: 'Sitters', zip: '67823'},
    {city: 'Sitzendorf', zip: '07429'},
    {city: 'Söchtenau', zip: '83139'},
    {city: 'Soderstorf', zip: '21388'},
    {city: 'Soest', zip: '59494'},
    {city: 'Sögel', zip: '49751'},
    {city: 'Sohland an der Spree', zip: '02689'},
    {city: 'Söhlde', zip: '31185'},
    {city: 'Sohren', zip: '55487'},
    {city: 'Söhrewald', zip: '34320'},
    {city: 'Sohrschied', zip: '55487'},
    {city: 'Sölden', zip: '79294'},
    {city: 'Solingen', zip: '42651'},
    {city: 'Solingen', zip: '42653'},
    {city: 'Solingen', zip: '42655'},
    {city: 'Solingen', zip: '42657'},
    {city: 'Solingen', zip: '42659'},
    {city: 'Solingen', zip: '42697'},
    {city: 'Solingen', zip: '42699'},
    {city: 'Solingen', zip: '42719'},
    {city: 'Solkwitz', zip: '07381'},
    {city: 'Sollerup', zip: '24852'},
    {city: 'Söllingen', zip: '38387'},
    {city: 'Sollstedt', zip: '99759'},
    {city: 'Sollwitt', zip: '25884'},
    {city: 'Solms', zip: '35606'},
    {city: 'Solnhofen', zip: '91807'},
    {city: 'Soltau', zip: '29614'},
    {city: 'Soltendieck', zip: '29594'},
    {city: 'Sommerach', zip: '97334'},
    {city: 'Sommerau', zip: '54317'},
    {city: 'Sömmerda', zip: '99610'},
    {city: 'Sömmerda', zip: '99625'},
    {city: 'Sommerhausen', zip: '97286'},
    {city: 'Sommerkahl', zip: '63825'},
    {city: 'Sommerland', zip: '25358'},
    {city: 'Sommerloch', zip: '55595'},
    {city: 'Sommersdorf', zip: '39365'},
    {city: 'Sommersdorf', zip: '17111'},
    {city: 'Sonderhofen', zip: '97255'},
    {city: 'Sondershausen', zip: '99706'},
    {city: 'Sondheim v.d.Rhön', zip: '97647'},
    {city: 'Sonneberg', zip: '96515'},
    {city: 'Sonneborn', zip: '99869'},
    {city: 'Sönnebüll', zip: '25821'},
    {city: 'Sonnefeld', zip: '96242'},
    {city: 'Sonnen', zip: '94164'},
    {city: 'Sonnenberg', zip: '16775'},
    {city: 'Sonnenberg-Winnenberg', zip: '55767'},
    {city: 'Sonnenbühl', zip: '72820'},
    {city: 'Sonnenstein', zip: '37345'},
    {city: 'Sonnewalde', zip: '03249'},
    {city: 'Sonnschied', zip: '55758'},
    {city: 'Sonsbeck', zip: '47665'},
    {city: 'Sontheim', zip: '87776'},
    {city: 'Sontheim an der Brenz', zip: '89567'},
    {city: 'Sonthofen', zip: '87527'},
    {city: 'Sontra', zip: '36205'},
    {city: 'Sophienhamm', zip: '24806'},
    {city: 'Sören', zip: '24241'},
    {city: 'Sörgenloch', zip: '55270'},
    {city: 'Sörth', zip: '57636'},
    {city: 'Sörup', zip: '24966'},
    {city: 'Sosberg', zip: '56858'},
    {city: 'Sottrum', zip: '27367'},
    {city: 'Soyen', zip: '83564'},
    {city: 'Spabrücken', zip: '55595'},
    {city: 'Spahnharrenstätte', zip: '49751'},
    {city: 'Spaichingen', zip: '78549'},
    {city: 'Spall', zip: '55595'},
    {city: 'Spalt', zip: '91174'},
    {city: 'Spangdahlem', zip: '54529'},
    {city: 'Spangenberg', zip: '34286'},
    {city: 'Spantekow', zip: '17391'},
    {city: 'Spantekow', zip: '17392'},
    {city: 'Spardorf', zip: '91080'},
    {city: 'Sparneck', zip: '95234'},
    {city: 'Spatzenhausen', zip: '82418'},
    {city: 'Spatzenhausen', zip: '82447'},
    {city: 'Spay', zip: '56322'},
    {city: 'Spechbach', zip: '74937'},
    {city: 'Speicher', zip: '54662'},
    {city: 'Speichersdorf', zip: '95469'},
    {city: 'Speinshart', zip: '92676'},
    {city: 'Spelle', zip: '48480'},
    {city: 'Spenge', zip: '32139'},
    {city: 'Spesenroth', zip: '56288'},
    {city: 'Spessart', zip: '56746'},
    {city: 'Speyer', zip: '67346'},
    {city: 'Spiegelau', zip: '94518'},
    {city: 'Spiegelberg', zip: '71579'},
    {city: 'Spiekeroog', zip: '26474'},
    {city: 'Spiesen-Elversberg', zip: '66583'},
    {city: 'Spiesheim', zip: '55288'},
    {city: 'Spirkelbach', zip: '76848'},
    {city: 'Splietsdorf', zip: '18513'},
    {city: 'Sponheim', zip: '55595'},
    {city: 'Sponholz', zip: '17039'},
    {city: 'Spornitz', zip: '19372'},
    {city: 'Spraitbach', zip: '73565'},
    {city: 'Spraitbach', zip: '73568'},
    {city: 'Spraitbach', zip: '73577'},
    {city: 'Sprakebüll', zip: '25917'},
    {city: 'Sprakensehl', zip: '29365'},
    {city: 'Spreenhagen', zip: '15528'},
    {city: 'Spreetal', zip: '02979'},
    {city: 'Spreewaldheide', zip: '15913'},
    {city: 'Spremberg', zip: '03130'},
    {city: 'Sprendlingen', zip: '55576'},
    {city: 'Springe', zip: '31832'},
    {city: 'Sprockhövel', zip: '45549'},
    {city: 'Sprötau', zip: '99610'},
    {city: 'Stäbelow', zip: '18198'},
    {city: 'Stade', zip: '21680'},
    {city: 'Stade', zip: '21682'},
    {city: 'Stade', zip: '21683'},
    {city: 'Stade', zip: '21684'},
    {city: 'Stadecken-Elsheim', zip: '55271'},
    {city: 'Stadelhofen', zip: '96187'},
    {city: 'Stadland', zip: '26935'},
    {city: 'Stadland', zip: '26936'},
    {city: 'Stadland', zip: '26937'},
    {city: 'Stadlern', zip: '92549'},
    {city: 'Stadtallendorf', zip: '35260'},
    {city: 'Stadtbergen', zip: '86391'},
    {city: 'Stadthagen', zip: '31655'},
    {city: 'Stadtilm', zip: '99326'},
    {city: 'Stadtkyll', zip: '54589'},
    {city: 'Stadtlauringen', zip: '97488'},
    {city: 'Stadtlohn', zip: '48703'},
    {city: 'Stadtoldendorf', zip: '37627'},
    {city: 'Stadtprozelten', zip: '97909'},
    {city: 'Stadtroda', zip: '07646'},
    {city: 'Stadtsteinach', zip: '95346'},
    {city: 'Stadum', zip: '25917'},
    {city: 'Staffhorst', zip: '27254'},
    {city: 'Stafstedt', zip: '24816'},
    {city: 'Stahlberg', zip: '67808'},
    {city: 'Stahlhofen', zip: '56412'},
    {city: 'Stahlhofen am Wiesensee', zip: '56459'},
    {city: 'Stahnsdorf', zip: '14532'},
    {city: 'Staig', zip: '89195'},
    {city: 'Stakendorf', zip: '24217'},
    {city: 'Stallwang', zip: '94375'},
    {city: 'Stammbach', zip: '95236'},
    {city: 'Stammham', zip: '84533'},
    {city: 'Stammham', zip: '85092'},
    {city: 'Stammham', zip: '85134'},
    {city: 'Stamsried', zip: '93491'},
    {city: 'Standenbühl', zip: '67816'},
    {city: 'Stangheck', zip: '24395'},
    {city: 'Stapel', zip: '25879'},
    {city: 'Stapelfeld', zip: '22145'},
    {city: 'Starkenberg', zip: '04617'},
    {city: 'Starkenburg', zip: '56843'},
    {city: 'Starnberg', zip: '82319'},
    {city: 'Starzach', zip: '72181'},
    {city: 'Staßfurt', zip: '39418'},
    {city: 'Staßfurt', zip: '39443'},
    {city: 'Staßfurt', zip: '39446'},
    {city: 'Stauchitz', zip: '01594'},
    {city: 'Staudach-Egerndach', zip: '83224'},
    {city: 'Staudernheim', zip: '55568'},
    {city: 'Staudt', zip: '56424'},
    {city: 'Staufenberg', zip: '35460'},
    {city: 'Staufenberg', zip: '34355'},
    {city: 'Staufen im Breisgau', zip: '79219'},
    {city: 'Staven', zip: '17039'},
    {city: 'Stavenhagen', zip: '17153'},
    {city: 'Stavern', zip: '49777'},
    {city: 'St. Blasien', zip: '79837'},
    {city: 'Stebach', zip: '56276'},
    {city: 'Stechlin', zip: '16775'},
    {city: 'Stechow-Ferchesar', zip: '14715'},
    {city: 'Stedesand', zip: '25920'},
    {city: 'Stedesdorf', zip: '26427'},
    {city: 'Steenfeld', zip: '25557'},
    {city: 'Steffeln', zip: '54597'},
    {city: 'Steffenberg', zip: '35239'},
    {city: 'Steffenshagen', zip: '18209'},
    {city: 'Stegaurach', zip: '96135'},
    {city: 'Stegen', zip: '79252'},
    {city: 'St. Egidien', zip: '09356'},
    {city: 'Steigra', zip: '06268'},
    {city: 'Steimbke', zip: '31634'},
    {city: 'Steimel', zip: '57614'},
    {city: 'Stein', zip: '24235'},
    {city: 'Stein', zip: '90547'},
    {city: 'Steina', zip: '01920'},
    {city: 'Steinach', zip: '77790'},
    {city: 'Steinach', zip: '94377'},
    {city: 'Steinach', zip: '96523'},
    {city: 'Steinalben', zip: '66851'},
    {city: 'Steinau', zip: '21775'},
    {city: 'Steinau an der Straße', zip: '36396'},
    {city: 'Steinbach', zip: '61449'},
    {city: 'Steinbach', zip: '56291'},
    {city: 'Steinbach', zip: '37308'},
    {city: 'Steinbach am Donnersberg', zip: '67808'},
    {city: 'Steinbach am Glan', zip: '66909'},
    {city: 'Steinbach am Wald', zip: '96361'},
    {city: 'Steinbach-Hallenberg', zip: '98587'},
    {city: 'Steinberg', zip: '08237'},
    {city: 'Steinberg', zip: '24972'},
    {city: 'Steinberg am See', zip: '92449'},
    {city: 'Steinbergkirche', zip: '24972'},
    {city: 'Stein-Bockenheim', zip: '55599'},
    {city: 'Steinborn', zip: '54655'},
    {city: 'Steinburg', zip: '22964'},
    {city: 'Steindorf', zip: '82297'},
    {city: 'Steinebach an der Wied', zip: '57629'},
    {city: 'Steinebach/Sieg', zip: '57520'},
    {city: 'Steineberg', zip: '54552'},
    {city: 'Steinefrenz', zip: '56414'},
    {city: 'Steinen', zip: '56244'},
    {city: 'Steinen', zip: '79585'},
    {city: 'Steinenbronn', zip: '71144'},
    {city: 'Steineroth', zip: '57518'},
    {city: 'Steinfeld', zip: '97854'},
    {city: 'Steinfeld', zip: '24888'},
    {city: 'Steinfeld', zip: '76889'},
    {city: 'Steinfeld', zip: '49439'},
    {city: 'Steinfurt', zip: '48565'},
    {city: 'Steingaden', zip: '86989'},
    {city: 'Steinhagen', zip: '33803'},
    {city: 'Steinhagen', zip: '18442'},
    {city: 'Steinhagen', zip: '18246'},
    {city: 'Steinhausen an der Rottum', zip: '88416'},
    {city: 'Steinheim', zip: '32839'},
    {city: 'Steinheim am Albuch', zip: '73566'},
    {city: 'Steinheim am Albuch', zip: '89555'},
    {city: 'Steinheim an der Murr', zip: '71711'},
    {city: 'Steinheuterode', zip: '37318'},
    {city: 'Steinhöfel', zip: '15518'},
    {city: 'Steinhöring', zip: '85643'},
    {city: 'Steinhorst', zip: '29367'},
    {city: 'Steinhorst', zip: '23847'},
    {city: 'Steinigtwolmsdorf', zip: '01904'},
    {city: 'Steiningen', zip: '54552'},
    {city: 'Steinkirchen', zip: '21720'},
    {city: 'Steinkirchen', zip: '84439'},
    {city: 'Steinmauern', zip: '76479'},
    {city: 'Stein-Neukirch', zip: '56479'},
    {city: 'Steinreich', zip: '15938'},
    {city: 'Steinsberg', zip: '56379'},
    {city: 'Steinsfeld', zip: '91628'},
    {city: 'Steinweiler', zip: '76872'},
    {city: 'Steinwenden', zip: '66879'},
    {city: 'Steinwiesen', zip: '96349'},
    {city: 'Stein-Wingert', zip: '57629'},
    {city: 'Steißlingen', zip: '78256'},
    {city: 'Stelle', zip: '21435'},
    {city: 'Stelle-Wittenwurth', zip: '25795'},
    {city: 'Stelzenberg', zip: '67705'},
    {city: 'Stemmen', zip: '27389'},
    {city: 'Stemshorn', zip: '49448'},
    {city: 'Stemwede', zip: '32351'},
    {city: 'Stendal', zip: '39576'},
    {city: 'Stepenitztal', zip: '23936'},
    {city: 'Stephanskirchen', zip: '83071'},
    {city: 'Stephansposching', zip: '94569'},
    {city: 'Sterley', zip: '23883'},
    {city: 'Sternberg', zip: '19406'},
    {city: 'Sternenfels', zip: '75447'},
    {city: 'Sterup', zip: '24996'},
    {city: 'Stetten', zip: '87778'},
    {city: 'Stetten', zip: '67294'},
    {city: 'Stetten', zip: '88719'},
    {city: 'Stetten am kalten Markt', zip: '72510'},
    {city: 'Stettfeld', zip: '96188'},
    {city: 'Steyerberg', zip: '31595'},
    {city: 'St. Gangloff', zip: '07629'},
    {city: 'St. Georgen im Schwarzwald', zip: '78112'},
    {city: 'Stiefenhofen', zip: '88167'},
    {city: 'Stimpfach', zip: '74597'},
    {city: 'Stinstedt', zip: '21772'},
    {city: 'Stipsdorf', zip: '23795'},
    {city: 'Stipshausen', zip: '55758'},
    {city: 'St. Johann', zip: '72813'},
    {city: 'St. Leon-Rot', zip: '68789'},
    {city: 'St. Märgen', zip: '79274'},
    {city: 'Stockach', zip: '78333'},
    {city: 'Stockelsdorf', zip: '23617'},
    {city: 'Stockem', zip: '54646'},
    {city: 'Stockhausen-Illfurth', zip: '56472'},
    {city: 'Stockheim', zip: '97640'},
    {city: 'Stockheim', zip: '96342'},
    {city: 'Stöckse', zip: '31638'},
    {city: 'Stocksee', zip: '24326'},
    {city: 'Stockstadt', zip: '64589'},
    {city: 'Stockstadt am Main', zip: '63811'},
    {city: 'Stockum-Püschen', zip: '56459'},
    {city: 'Stödtlen', zip: '73495'},
    {city: 'Stoetze', zip: '29597'},
    {city: 'Stolberg', zip: '52222'},
    {city: 'Stolberg', zip: '52223'},
    {city: 'Stolberg', zip: '52224'},
    {city: 'Stolk', zip: '24890'},
    {city: 'Stollberg/Erzgebirge', zip: '09366'},
    {city: 'Stolpe', zip: '24601'},
    {city: 'Stolpe', zip: '19372'},
    {city: 'Stolpe an der Peene', zip: '17391'},
    {city: 'Stolpe auf Usedom', zip: '17406'},
    {city: 'Stolpen', zip: '01833'},
    {city: 'Stoltebüll', zip: '24409'},
    {city: 'Stoltenberg', zip: '24256'},
    {city: 'Stolzenau', zip: '31592'},
    {city: 'Storbeck-Frankendorf', zip: '16818'},
    {city: 'Stördorf', zip: '25554'},
    {city: 'Störkathen', zip: '25548'},
    {city: 'Storkow (Mark)', zip: '15859'},
    {city: 'Störnstein', zip: '92721'},
    {city: 'Stößen', zip: '06667'},
    {city: 'Stötten a.Auerberg', zip: '87675'},
    {city: 'Stöttwang', zip: '87677'},
    {city: 'Straelen', zip: '47638'},
    {city: 'Strahlungen', zip: '97618'},
    {city: 'Stralendorf', zip: '19073'},
    {city: 'Stralsund', zip: '18435'},
    {city: 'Stralsund', zip: '18437'},
    {city: 'Stralsund', zip: '18439'},
    {city: 'Strande', zip: '24229'},
    {city: 'Strasburg (Uckermark)', zip: '17335'},
    {city: 'Straßberg', zip: '72479'},
    {city: 'Straßenhaus', zip: '56587'},
    {city: 'Straßkirchen', zip: '94342'},
    {city: 'Straßlach-Dingharting', zip: '82064'},
    {city: 'Straubenhardt', zip: '75334'},
    {city: 'Straubing', zip: '94315'},
    {city: 'Straufhain', zip: '98646'},
    {city: 'Straupitz', zip: '15913'},
    {city: 'Strausberg', zip: '15344'},
    {city: 'Straußfurt', zip: '99634'},
    {city: 'Strehla', zip: '01616'},
    {city: 'Streithausen', zip: '57629'},
    {city: 'Strickscheid', zip: '54597'},
    {city: 'Striegistal', zip: '09661'},
    {city: 'Strohkirchen', zip: '19230'},
    {city: 'Strohn', zip: '54558'},
    {city: 'Stromberg', zip: '55442'},
    {city: 'Strotzbüsch', zip: '54552'},
    {city: 'Strübbel', zip: '25792'},
    {city: 'Struckum', zip: '25821'},
    {city: 'Strukdorf', zip: '23815'},
    {city: 'Strullendorf', zip: '96129'},
    {city: 'Struppen', zip: '01796'},
    {city: 'Strüth', zip: '56357'},
    {city: 'Struvenhütten', zip: '24643'},
    {city: 'Struxdorf', zip: '24891'},
    {city: 'Stubben', zip: '23847'},
    {city: 'Stubbendorf', zip: '18195'},
    {city: 'Stubenberg', zip: '94166'},
    {city: 'Stüdenitz-Schönermark', zip: '16845'},
    {city: 'Stuer', zip: '17209'},
    {city: 'Stühlingen', zip: '79780'},
    {city: 'Stuhr', zip: '28816'},
    {city: 'Stulln', zip: '92551'},
    {city: 'Stürzelbach', zip: '57614'},
    {city: 'Stutensee', zip: '76297'},
    {city: 'Stuttgart', zip: '70173'},
    {city: 'Stuttgart', zip: '70174'},
    {city: 'Stuttgart', zip: '70176'},
    {city: 'Stuttgart', zip: '70178'},
    {city: 'Stuttgart', zip: '70180'},
    {city: 'Stuttgart', zip: '70182'},
    {city: 'Stuttgart', zip: '70184'},
    {city: 'Stuttgart', zip: '70186'},
    {city: 'Stuttgart', zip: '70188'},
    {city: 'Stuttgart', zip: '70190'},
    {city: 'Stuttgart', zip: '70191'},
    {city: 'Stuttgart', zip: '70192'},
    {city: 'Stuttgart', zip: '70193'},
    {city: 'Stuttgart', zip: '70195'},
    {city: 'Stuttgart', zip: '70197'},
    {city: 'Stuttgart', zip: '70199'},
    {city: 'Stuttgart', zip: '70327'},
    {city: 'Stuttgart', zip: '70329'},
    {city: 'Stuttgart', zip: '70372'},
    {city: 'Stuttgart', zip: '70374'},
    {city: 'Stuttgart', zip: '70376'},
    {city: 'Stuttgart', zip: '70378'},
    {city: 'Stuttgart', zip: '70435'},
    {city: 'Stuttgart', zip: '70437'},
    {city: 'Stuttgart', zip: '70439'},
    {city: 'Stuttgart', zip: '70469'},
    {city: 'Stuttgart', zip: '70499'},
    {city: 'Stuttgart', zip: '70563'},
    {city: 'Stuttgart', zip: '70565'},
    {city: 'Stuttgart', zip: '70567'},
    {city: 'Stuttgart', zip: '70569'},
    {city: 'Stuttgart', zip: '70597'},
    {city: 'Stuttgart', zip: '70599'},
    {city: 'Stuttgart', zip: '70619'},
    {city: 'Stuttgart', zip: '70629'},
    {city: 'Stützengrün', zip: '08328'},
    {city: 'Stuvenborn', zip: '24641'},
    {city: 'Südbrookmerland', zip: '26624'},
    {city: 'Südbrookmerland', zip: '26759'},
    {city: 'Südeichsfeld', zip: '99976'},
    {city: 'Südeichsfeld', zip: '99988'},
    {city: 'Süderau', zip: '25361'},
    {city: 'Süderbrarup', zip: '24392'},
    {city: 'Suderburg', zip: '29556'},
    {city: 'Süderdeich', zip: '25764'},
    {city: 'Süderdorf', zip: '25782'},
    {city: 'Süderende', zip: '25938'},
    {city: 'Süderfahrenstedt', zip: '24890'},
    {city: 'Südergellersen', zip: '21394'},
    {city: 'Süderhackstedt', zip: '24852'},
    {city: 'Süderhackstedt', zip: '24992'},
    {city: 'Süderhastedt', zip: '25727'},
    {city: 'Süderheistedt', zip: '25779'},
    {city: 'Süderhöft', zip: '25876'},
    {city: 'Süderholz', zip: '18516'},
    {city: 'Süderlügum', zip: '25923'},
    {city: 'Südermarsch', zip: '25813'},
    {city: 'Südermarsch', zip: '25840'},
    {city: 'Südharz', zip: '06536'},
    {city: 'Südheide', zip: '29303'},
    {city: 'Südheide', zip: '29320'},
    {city: 'Südheide', zip: '29345'},
    {city: 'Südliches Anhalt', zip: '06369'},
    {city: 'Südliches Anhalt', zip: '06386'},
    {city: 'Südliches Anhalt', zip: '06388'},
    {city: 'Südlohn', zip: '46354'},
    {city: 'Südmüritz', zip: '17207'},
    {city: 'Sudwalde', zip: '27257'},
    {city: 'Sugenheim', zip: '91438'},
    {city: 'Sugenheim', zip: '91484'},
    {city: 'Suhl', zip: '98527'},
    {city: 'Suhl', zip: '98528'},
    {city: 'Suhl', zip: '98529'},
    {city: 'Suhlendorf', zip: '29562'},
    {city: 'Sukow', zip: '19079'},
    {city: 'Sukow-Levitzow', zip: '17168'},
    {city: 'Sülfeld', zip: '23845'},
    {city: 'Sülfeld', zip: '23867'},
    {city: 'Sulingen', zip: '27232'},
    {city: 'Sülm', zip: '54636'},
    {city: 'Sülstorf', zip: '19077'},
    {city: 'Sulza', zip: '07751'},
    {city: 'Sulz am Neckar', zip: '72172'},
    {city: 'Sulzbach', zip: '65843'},
    {city: 'Sulzbach', zip: '56379'},
    {city: 'Sulzbach', zip: '55758'},
    {city: 'Sulzbach am Main', zip: '63834'},
    {city: 'Sulzbach an der Murr', zip: '71560'},
    {city: 'Sulzbach-Laufen', zip: '74429'},
    {city: 'Sulzbach-Rosenberg', zip: '92237'},
    {city: 'Sulzbach/Saar', zip: '66280'},
    {city: 'Sulzbachtal', zip: '67734'},
    {city: 'Sulzberg', zip: '87477'},
    {city: 'Sulzburg', zip: '79295'},
    {city: 'Sulzdorf a.d.Lederhecke', zip: '97528'},
    {city: 'Sulzemoos', zip: '85254'},
    {city: 'Sulzemoos', zip: '85259'},
    {city: 'Sülzetal', zip: '39171'},
    {city: 'Sulzfeld', zip: '97633'},
    {city: 'Sulzfeld', zip: '75056'},
    {city: 'Sülzfeld', zip: '98617'},
    {city: 'Sulzfeld am Main', zip: '97320'},
    {city: 'Sulzheim', zip: '97529'},
    {city: 'Sulzheim', zip: '55286'},
    {city: 'Sulzthal', zip: '97717'},
    {city: 'Sünching', zip: '93104'},
    {city: 'Sundern', zip: '59846'},
    {city: 'Sundhagen', zip: '18519'},
    {city: 'Sundhausen', zip: '99947'},
    {city: 'Süpplingen', zip: '38373'},
    {city: 'Süpplingenburg', zip: '38376'},
    {city: 'Surberg', zip: '83362'},
    {city: 'Surwold', zip: '26903'},
    {city: 'Süsel', zip: '23701'},
    {city: 'Süßen', zip: '73079'},
    {city: 'Sustrum', zip: '49762'},
    {city: 'Suthfeld', zip: '31555'},
    {city: 'Swisttal', zip: '53913'},
    {city: 'Sydower Fließ', zip: '16230'},
    {city: 'Syke', zip: '28857'},
    {city: 'Sylt', zip: '25980'},
    {city: 'Syrgenstein', zip: '89428'},
    {city: 'Taarstedt', zip: '24893'},
    {city: 'Taben-Rodt', zip: '54441'},
    {city: 'Tacherting', zip: '83342'},
    {city: 'Taching am See', zip: '83373'},
    {city: 'Tackesdorf', zip: '24797'},
    {city: 'Tackesdorf', zip: '25585'},
    {city: 'Täferrot', zip: '73527'},
    {city: 'Tagmersheim', zip: '86704'},
    {city: 'Talheim', zip: '74388'},
    {city: 'Talheim', zip: '78607'},
    {city: 'Talkau', zip: '21493'},
    {city: 'Talling', zip: '54426'},
    {city: 'Tambach-Dietharz', zip: '99897'},
    {city: 'Tamm', zip: '71732'},
    {city: 'Tangerhütte', zip: '39517'},
    {city: 'Tangerhütte', zip: '39579'},
    {city: 'Tangermünde', zip: '39590'},
    {city: 'Tangstedt', zip: '25499'},
    {city: 'Tangstedt', zip: '22889'},
    {city: 'Tann', zip: '36142'},
    {city: 'Tann', zip: '84367'},
    {city: 'Tanna', zip: '07922'},
    {city: 'Tannenberg', zip: '09468'},
    {city: 'Tännesberg', zip: '92723'},
    {city: 'Tannhausen', zip: '73497'},
    {city: 'Tannheim', zip: '88459'},
    {city: 'Tantow', zip: '16307'},
    {city: 'Tapfheim', zip: '86660'},
    {city: 'Tappenbeck', zip: '38479'},
    {city: 'Tappendorf', zip: '24594'},
    {city: 'Tarbek', zip: '24619'},
    {city: 'Tarmstedt', zip: '27412'},
    {city: 'Tarnow', zip: '18249'},
    {city: 'Tarp', zip: '24963'},
    {city: 'Tasdorf', zip: '24536'},
    {city: 'Tastrup', zip: '24943'},
    {city: 'Tastungen', zip: '37339'},
    {city: 'Tating', zip: '25881'},
    {city: 'Tauberbischofsheim', zip: '97941'},
    {city: 'Tauberrettersheim', zip: '97285'},
    {city: 'Taucha', zip: '04425'},
    {city: 'Tauche', zip: '15848'},
    {city: 'Tauer', zip: '03185'},
    {city: 'Taufkirchen', zip: '82024'},
    {city: 'Taufkirchen', zip: '84416'},
    {city: 'Taufkirchen', zip: '84574'},
    {city: 'Taunusstein', zip: '65232'},
    {city: 'Taura', zip: '09249'},
    {city: 'Tautenburg', zip: '07778'},
    {city: 'Tautendorf', zip: '07646'},
    {city: 'Tautenhain', zip: '07639'},
    {city: 'Tawern', zip: '54456'},
    {city: 'Techelsdorf', zip: '24220'},
    {city: 'Techentin', zip: '19399'},
    {city: 'Tecklenburg', zip: '49545'},
    {city: 'Tegau', zip: '07907'},
    {city: 'Tegernheim', zip: '93105'},
    {city: 'Tegernsee', zip: '83684'},
    {city: 'Teichland', zip: '03185'},
    {city: 'Teichwitz', zip: '07570'},
    {city: 'Teisendorf', zip: '83317'},
    {city: 'Teisendorf', zip: '83364'},
    {city: 'Teising', zip: '84576'},
    {city: 'Teisnach', zip: '94244'},
    {city: 'Teistungen', zip: '37339'},
    {city: 'Teldau', zip: '19273'},
    {city: 'Telgte', zip: '48291'},
    {city: 'Tellig', zip: '56858'},
    {city: 'Tellingstedt', zip: '25782'},
    {city: 'Teltow', zip: '14513'},
    {city: 'Temmels', zip: '54441'},
    {city: 'Temmen-Ringenwalde', zip: '17268'},
    {city: 'Temnitzquell', zip: '16818'},
    {city: 'Temnitztal', zip: '16845'},
    {city: 'Templin', zip: '17268'},
    {city: 'Tengen', zip: '78250'},
    {city: 'Teningen', zip: '79312'},
    {city: 'Teningen', zip: '79331'},
    {city: 'Tensbüttel-Röst', zip: '25767'},
    {city: 'Tensfeld', zip: '23824'},
    {city: 'Teschenmoschel', zip: '67806'},
    {city: 'Tespe', zip: '21395'},
    {city: 'Tessin', zip: '18195'},
    {city: 'Tessin b. Boizenburg', zip: '19258'},
    {city: 'Testorf-Steinfort', zip: '23936'},
    {city: 'Tetenbüll', zip: '25882'},
    {city: 'Tetenhusen', zip: '24817'},
    {city: 'Teterow', zip: '17166'},
    {city: 'Tettau', zip: '96355'},
    {city: 'Tettau', zip: '01945'},
    {city: 'Tettenweis', zip: '94167'},
    {city: 'Tettnang', zip: '88069'},
    {city: 'Tettnang', zip: '88079'},
    {city: 'Teublitz', zip: '93158'},
    {city: 'Teuchern', zip: '06682'},
    {city: 'Teugn', zip: '93356'},
    {city: 'Teunz', zip: '92552'},
    {city: 'Teupitz', zip: '15755'},
    {city: 'Teuschnitz', zip: '96358'},
    {city: 'Teutschenthal', zip: '06179'},
    {city: 'Thaden', zip: '25557'},
    {city: 'Thaining', zip: '86943'},
    {city: 'Thale', zip: '06502'},
    {city: 'Thaleischweiler-Fröschen', zip: '66987'},
    {city: 'Thalfang', zip: '54424'},
    {city: 'Thalhausen', zip: '56584'},
    {city: 'Thalheim/Erzgebirge', zip: '09380'},
    {city: 'Thallichtenberg', zip: '66871'},
    {city: 'Thallwitz', zip: '04808'},
    {city: 'Thalmassing', zip: '93107'},
    {city: 'Thalmässing', zip: '91177'},
    {city: 'Thalwenden', zip: '37318'},
    {city: 'Thandorf', zip: '19217'},
    {city: 'Thannhausen', zip: '86470'},
    {city: 'Thanstein', zip: '92554'},
    {city: 'Tharandt', zip: '01737'},
    {city: 'Thedinghausen', zip: '27321'},
    {city: 'Theilenhofen', zip: '91741'},
    {city: 'Theilheim', zip: '97288'},
    {city: 'Theisbergstegen', zip: '66871'},
    {city: 'Theisseil', zip: '92637'},
    {city: 'Thelkow', zip: '18195'},
    {city: 'Themar', zip: '98660'},
    {city: 'Theres', zip: '97531'},
    {city: 'Thermalbad Wiesenbad', zip: '09488'},
    {city: 'Theuma', zip: '08541'},
    {city: 'Thiendorf', zip: '01561'},
    {city: 'Thierhaupten', zip: '86672'},
    {city: 'Thierschneck', zip: '07774'},
    {city: 'Thiersheim', zip: '95707'},
    {city: 'Thierstein', zip: '95199'},
    {city: 'Tholey', zip: '66636'},
    {city: 'Thomasburg', zip: '21401'},
    {city: 'Thomm', zip: '54317'},
    {city: 'Thonhausen', zip: '04626'},
    {city: 'Thörlingen', zip: '56291'},
    {city: 'Thörnich', zip: '54340'},
    {city: 'Thuine', zip: '49832'},
    {city: 'Thulendorf', zip: '18184'},
    {city: 'Thum', zip: '09419'},
    {city: 'Thumby', zip: '24351'},
    {city: 'Thundorf i.UFr.', zip: '97711'},
    {city: 'Thüngen', zip: '97289'},
    {city: 'Thüngersheim', zip: '97291'},
    {city: 'Thür', zip: '56743'},
    {city: 'Thürkow', zip: '17168'},
    {city: 'Thurmansbang', zip: '94169'},
    {city: 'Thurnau', zip: '95349'},
    {city: 'Thyrnau', zip: '94136'},
    {city: 'Tiddische', zip: '38473'},
    {city: 'Tiefenbach', zip: '84184'},
    {city: 'Tiefenbach', zip: '94113'},
    {city: 'Tiefenbach', zip: '93464'},
    {city: 'Tiefenbach', zip: '55471'},
    {city: 'Tiefenbach', zip: '88422'},
    {city: 'Tiefenbronn', zip: '75233'},
    {city: 'Tiefenthal', zip: '55546'},
    {city: 'Tiefenthal', zip: '67311'},
    {city: 'Tielen', zip: '24803'},
    {city: 'Tielenhemme', zip: '25794'},
    {city: 'Timmaspe', zip: '24644'},
    {city: 'Timmendorfer Strand', zip: '23669'},
    {city: 'Tinningstedt', zip: '25917'},
    {city: 'Tirpersdorf', zip: '08606'},
    {city: 'Tirschenreuth', zip: '95643'},
    {city: 'Tissa', zip: '07646'},
    {city: 'Tiste', zip: '27419'},
    {city: 'Titisee-Neustadt', zip: '79822'},
    {city: 'Titting', zip: '85135'},
    {city: 'Tittling', zip: '94104'},
    {city: 'Tittmoning', zip: '84529'},
    {city: 'Titz', zip: '52445'},
    {city: 'Toddin', zip: '19230'},
    {city: 'Todenbüttel', zip: '24819'},
    {city: 'Todendorf', zip: '22965'},
    {city: 'Todenroth', zip: '55481'},
    {city: 'Todesfelde', zip: '23826'},
    {city: 'Todtenweis', zip: '86447'},
    {city: 'Todtmoos', zip: '79682'},
    {city: 'Todtnau', zip: '79674'},
    {city: 'Töging a.Inn', zip: '84513'},
    {city: 'Tolk', zip: '24894'},
    {city: 'Tömmelsdorf', zip: '07819'},
    {city: 'Tönisvorst', zip: '47918'},
    {city: 'Tonna', zip: '99958'},
    {city: 'Tonndorf', zip: '99438'},
    {city: 'Tönning', zip: '25832'},
    {city: 'Töpen', zip: '95183'},
    {city: 'Topfstedt', zip: '99718'},
    {city: 'Toppenstedt', zip: '21442'},
    {city: 'Torgau', zip: '04860'},
    {city: 'Torgau', zip: '04861'},
    {city: 'Torgelow', zip: '17358'},
    {city: 'Torgelow am See', zip: '17192'},
    {city: 'Tornesch', zip: '25436'},
    {city: 'Tostedt', zip: '21255'},
    {city: 'Tosterglope', zip: '21371'},
    {city: 'Tottleben', zip: '99947'},
    {city: 'Traben-Trarbach', zip: '56841'},
    {city: 'Trabitz', zip: '92724'},
    {city: 'Train', zip: '93358'},
    {city: 'Traisen', zip: '55595'},
    {city: 'Traitsching', zip: '93455'},
    {city: 'Tramm', zip: '19089'},
    {city: 'Tramm', zip: '21516'},
    {city: 'Trappenkamp', zip: '24610'},
    {city: 'Trappstadt', zip: '97633'},
    {city: 'Trassem', zip: '54441'},
    {city: 'Trassenheide', zip: '17449'},
    {city: 'Traunreut', zip: '83301'},
    {city: 'Traunreut', zip: '83368'},
    {city: 'Traunreut', zip: '83371'},
    {city: 'Traunreut', zip: '83374'},
    {city: 'Traunstein', zip: '83278'},
    {city: 'Trausnitz', zip: '92555'},
    {city: 'Trautskirchen', zip: '90619'},
    {city: 'Travenbrück', zip: '23843'},
    {city: 'Travenhorst', zip: '23827'},
    {city: 'Traventhal', zip: '23795'},
    {city: 'Trebbin', zip: '14959'},
    {city: 'Trebel', zip: '29494'},
    {city: 'Treben', zip: '04617'},
    {city: 'Trebendorf', zip: '02959'},
    {city: 'Trebgast', zip: '95367'},
    {city: 'Trebra', zip: '99718'},
    {city: 'Trebsen/Mulde', zip: '04687'},
    {city: 'Trebur', zip: '65468'},
    {city: 'Trechtingshausen', zip: '55413'},
    {city: 'Treffelstein', zip: '93492'},
    {city: 'Treffurt', zip: '99830'},
    {city: 'Treia', zip: '24896'},
    {city: 'Treis-Karden', zip: '56253'},
    {city: 'Tremsbüttel', zip: '22967'},
    {city: 'Trendelburg', zip: '34388'},
    {city: 'Trennewurth', zip: '25693'},
    {city: 'Trent', zip: '18569'},
    {city: 'Treplin', zip: '15236'},
    {city: 'Treuchtlingen', zip: '91757'},
    {city: 'Treuen', zip: '08233'},
    {city: 'Treuenbrietzen', zip: '14929'},
    {city: 'Triberg im Schwarzwald', zip: '78098'},
    {city: 'Tribsees', zip: '18465'},
    {city: 'Triebel', zip: '08606'},
    {city: 'Triefenstein', zip: '97855'},
    {city: 'Trier', zip: '54290'},
    {city: 'Trier', zip: '54292'},
    {city: 'Trier', zip: '54293'},
    {city: 'Trier', zip: '54294'},
    {city: 'Trier', zip: '54295'},
    {city: 'Trier', zip: '54296'},
    {city: 'Trierscheid', zip: '53520'},
    {city: 'Trierweiler', zip: '54311'},
    {city: 'Triftern', zip: '84371'},
    {city: 'Triglitz', zip: '16949'},
    {city: 'Trimbs', zip: '56753'},
    {city: 'Trimport', zip: '54636'},
    {city: 'Trinwillershagen', zip: '18320'},
    {city: 'Trippstadt', zip: '67705'},
    {city: 'Triptis', zip: '07819'},
    {city: 'Trittau', zip: '22946'},
    {city: 'Trittenheim', zip: '54349'},
    {city: 'Tröbitz', zip: '03253'},
    {city: 'Tröbnitz', zip: '07646'},
    {city: 'Tröchtelborn', zip: '99869'},
    {city: 'Trochtelfingen', zip: '72818'},
    {city: 'Trockenborn-Wolfersdorf', zip: '07646'},
    {city: 'Trogen', zip: '95183'},
    {city: 'Troisdorf', zip: '53840'},
    {city: 'Troisdorf', zip: '53842'},
    {city: 'Troisdorf', zip: '53844'},
    {city: 'Trollenhagen', zip: '17039'},
    {city: 'Tröndel', zip: '24321'},
    {city: 'Trossin', zip: '04880'},
    {city: 'Trossingen', zip: '78647'},
    {city: 'Tröstau', zip: '95709'},
    {city: 'Trostberg', zip: '83308'},
    {city: 'Trulben', zip: '66957'},
    {city: 'Trunkelsberg', zip: '87779'},
    {city: 'Tschernitz', zip: '03130'},
    {city: 'Tschirn', zip: '96367'},
    {city: 'Tübingen', zip: '72070'},
    {city: 'Tübingen', zip: '72072'},
    {city: 'Tübingen', zip: '72074'},
    {city: 'Tübingen', zip: '72076'},
    {city: 'Tuchenbach', zip: '90587'},
    {city: 'Tülau', zip: '38474'},
    {city: 'Tümlauer Koog', zip: '25881'},
    {city: 'Tunau', zip: '79677'},
    {city: 'Tuningen', zip: '78609'},
    {city: 'Tuntenhausen', zip: '83104'},
    {city: 'Türkenfeld', zip: '82299'},
    {city: 'Türkheim', zip: '86842'},
    {city: 'Turnow-Preilack', zip: '03185'},
    {city: 'Tussenhausen', zip: '86874'},
    {city: 'Tüßling', zip: '84577'},
    {city: 'Tutow', zip: '17129'},
    {city: 'Tüttendorf', zip: '24214'},
    {city: 'Tüttleben', zip: '99869'},
    {city: 'Tuttlingen', zip: '78532'},
    {city: 'Tutzing', zip: '82327'},
    {city: 'Tützpatz', zip: '17091'},
    {city: 'Twedt', zip: '24894'},
    {city: 'Twist', zip: '49767'},
    {city: 'Twistetal', zip: '34477'},
    {city: 'Twistringen', zip: '27239'},
    {city: 'Tyrlaching', zip: '84558'},
    {city: 'Übach-Palenberg', zip: '52531'},
    {city: 'Übereisenbach', zip: '54689'},
    {city: 'Überherrn', zip: '66802'},
    {city: 'Überlingen', zip: '88662'},
    {city: 'Übersee', zip: '83236'},
    {city: 'Ubstadt-Weiher', zip: '76698'},
    {city: 'Uchte', zip: '31600'},
    {city: 'Üchtelhausen', zip: '97532'},
    {city: 'Uckerfelde', zip: '17291'},
    {city: 'Ückeritz', zip: '17459'},
    {city: 'Uckerland', zip: '17337'},
    {city: 'Udenheim', zip: '55288'},
    {city: 'Uder', zip: '37318'},
    {city: 'Üdersdorf', zip: '54552'},
    {city: 'Udestedt', zip: '99198'},
    {city: 'Udler', zip: '54552'},
    {city: 'Uebigau-Wahrenbrück', zip: '04895'},
    {city: 'Uebigau-Wahrenbrück', zip: '04924'},
    {city: 'Uebigau-Wahrenbrück', zip: '04938'},
    {city: 'Ueckermünde', zip: '17373'},
    {city: 'Uedem', zip: '47589'},
    {city: 'Uehlfeld', zip: '91486'},
    {city: 'Uehrde', zip: '38170'},
    {city: 'Uelitz', zip: '19077'},
    {city: 'Uelsby', zip: '24860'},
    {city: 'Uelsen', zip: '49843'},
    {city: 'Uelversheim', zip: '55278'},
    {city: 'Uelvesbüll', zip: '25889'},
    {city: 'Uelzen', zip: '29525'},
    {city: 'Uersfeld', zip: '56767'},
    {city: 'Ueß', zip: '56767'},
    {city: 'Uetersen', zip: '25436'},
    {city: 'Uettingen', zip: '97292'},
    {city: 'Uetze', zip: '31311'},
    {city: 'Uffenheim', zip: '97215'},
    {city: 'Uffing am Staffelsee', zip: '82449'},
    {city: 'Uhingen', zip: '73066'},
    {city: 'Uhldingen-Mühlhofen', zip: '88690'},
    {city: 'Uhler', zip: '56290'},
    {city: 'Ühlingen-Birkendorf', zip: '79777'},
    {city: 'Uhlstädt-Kirchhasel', zip: '07407'},
    {city: 'Ulm', zip: '89073'},
    {city: 'Ulm', zip: '89075'},
    {city: 'Ulm', zip: '89077'},
    {city: 'Ulm', zip: '89079'},
    {city: 'Ulm', zip: '89081'},
    {city: 'Ulm', zip: '89160'},
    {city: 'Ulmen', zip: '56766'},
    {city: 'Ulmet', zip: '66887'},
    {city: 'Ulrichstein', zip: '35327'},
    {city: 'Ulsnis', zip: '24897'},
    {city: 'Umkirch', zip: '79224'},
    {city: 'Ummanz', zip: '18569'},
    {city: 'Ummendorf', zip: '39365'},
    {city: 'Ummendorf', zip: '88444'},
    {city: 'Ummern', zip: '29369'},
    {city: 'Ummerstadt', zip: '98663'},
    {city: 'Umpferstedt', zip: '99441'},
    {city: 'Undeloh', zip: '21274'},
    {city: 'Undeloh', zip: '29640'},
    {city: 'Undenheim', zip: '55278'},
    {city: 'Ungerhausen', zip: '87781'},
    {city: 'Unkel', zip: '53572'},
    {city: 'Unkenbach', zip: '67823'},
    {city: 'Unlingen', zip: '88527'},
    {city: 'Unna', zip: '59423'},
    {city: 'Unna', zip: '59425'},
    {city: 'Unna', zip: '59427'},
    {city: 'Unnau', zip: '57648'},
    {city: 'Unsleben', zip: '97618'},
    {city: 'Unstrut-Hainich', zip: '99991'},
    {city: 'Unstruttal', zip: '99974'},
    {city: 'Unstruttal', zip: '99976'},
    {city: 'Unterammergau', zip: '82497'},
    {city: 'Unterbodnitz', zip: '07646'},
    {city: 'Unterbreizbach', zip: '36414'},
    {city: 'Unterdießen', zip: '86944'},
    {city: 'Unterdietfurt', zip: '84339'},
    {city: 'Unteregg', zip: '87782'},
    {city: 'Untereisesheim', zip: '74257'},
    {city: 'Unterensingen', zip: '72669'},
    {city: 'Unterföhring', zip: '85774'},
    {city: 'Untergriesbach', zip: '94107'},
    {city: 'Untergruppenbach', zip: '74199'},
    {city: 'Unterhaching', zip: '82008'},
    {city: 'Unterjeckenbach', zip: '67746'},
    {city: 'Unterkirnach', zip: '78089'},
    {city: 'Unterleinleiter', zip: '91364'},
    {city: 'Untermarchtal', zip: '89617'},
    {city: 'Untermaßfeld', zip: '98617'},
    {city: 'Untermeitingen', zip: '86836'},
    {city: 'Untermerzbach', zip: '96190'},
    {city: 'Untermünkheim', zip: '74547'},
    {city: 'Unterneukirchen', zip: '84579'},
    {city: 'Unterpleichfeld', zip: '97294'},
    {city: 'Unterreichenbach', zip: '75399'},
    {city: 'Unterreit', zip: '83567'},
    {city: 'Unterroth', zip: '89299'},
    {city: 'Unterschleißheim', zip: '85716'},
    {city: 'Unterschneidheim', zip: '73485'},
    {city: 'Unterschwaningen', zip: '91743'},
    {city: 'Untershausen', zip: '56412'},
    {city: 'Untersiemau', zip: '96253'},
    {city: 'Unterspreewald', zip: '15910'},
    {city: 'Unterstadion', zip: '89619'},
    {city: 'Untersteinach', zip: '95369'},
    {city: 'Unterthingau', zip: '87647'},
    {city: 'Unterwachingen', zip: '89597'},
    {city: 'Unterwaldhausen', zip: '88379'},
    {city: 'Unterweißbach', zip: '98744'},
    {city: 'Unterwellenborn', zip: '07333'},
    {city: 'Unterwössen', zip: '83246'},
    {city: 'Untrasried', zip: '87496'},
    {city: 'Unzenberg', zip: '55483'},
    {city: 'Upahl', zip: '23936'},
    {city: 'Upgant-Schott', zip: '26529'},
    {city: 'Uphusum', zip: '25923'},
    {city: 'Uplengen', zip: '26670'},
    {city: 'Uppershausen', zip: '54673'},
    {city: 'Urbach', zip: '73655'},
    {city: 'Urbach', zip: '73660'},
    {city: 'Urbach', zip: '56317'},
    {city: 'Urbach', zip: '99765'},
    {city: 'Urbar', zip: '56182'},
    {city: 'Urbar', zip: '55430'},
    {city: 'Urleben', zip: '99955'},
    {city: 'Urmersbach', zip: '56761'},
    {city: 'Urmitz', zip: '56220'},
    {city: 'Ursberg', zip: '86513'},
    {city: 'Urschmitt', zip: '56825'},
    {city: 'Ursensollen', zip: '92289'},
    {city: 'Urspringen', zip: '97857'},
    {city: 'Ürzig', zip: '54539'},
    {city: 'Usch', zip: '54655'},
    {city: 'Usedom', zip: '17406'},
    {city: 'Userin', zip: '17237'},
    {city: 'Usingen', zip: '61250'},
    {city: 'Uslar', zip: '37170'},
    {city: 'Ustersbach', zip: '86514'},
    {city: 'Utarp', zip: '26556'},
    {city: 'Utecht', zip: '19217'},
    {city: 'Utendorf', zip: '98617'},
    {city: 'Utersum', zip: '25938'},
    {city: 'Utscheid', zip: '54675'},
    {city: 'Uttenreuth', zip: '91080'},
    {city: 'Uttenweiler', zip: '88524'},
    {city: 'Üttfeld', zip: '54619'},
    {city: 'Utting am Ammersee', zip: '86919'},
    {city: 'Utzedel', zip: '17111'},
    {city: 'Utzenfeld', zip: '79694'},
    {city: 'Utzenhain', zip: '56291'},
    {city: 'Utzerath', zip: '54552'},
    {city: 'Üxheim', zip: '54579'},
    {city: 'Vaale', zip: '25572'},
    {city: 'Vaale', zip: '25594'},
    {city: 'Vaalermoor', zip: '25594'},
    {city: 'Vacha', zip: '36404'},
    {city: 'Vachdorf', zip: '98617'},
    {city: 'Vachendorf', zip: '83377'},
    {city: 'Vahlberg', zip: '38170'},
    {city: 'Vahlbruch', zip: '37647'},
    {city: 'Vahlde', zip: '27389'},
    {city: 'Vaihingen an der Enz', zip: '71665'},
    {city: 'Vallendar', zip: '56179'},
    {city: 'Valley', zip: '83626'},
    {city: 'Valwig', zip: '56812'},
    {city: 'Varel', zip: '26316'},
    {city: 'Varrel', zip: '27259'},
    {city: 'Vastorf', zip: '21397'},
    {city: 'Vaterstetten', zip: '85591'},
    {city: 'Vaterstetten', zip: '85598'},
    {city: 'Vaterstetten', zip: '85599'},
    {city: 'Vaterstetten', zip: '85622'},
    {city: 'Vaterstetten', zip: '85646'},
    {city: 'Vechelde', zip: '38159'},
    {city: 'Vechta', zip: '49377'},
    {city: 'Veelböken', zip: '19205'},
    {city: 'Veilsdorf', zip: '98669'},
    {city: 'Veitsbronn', zip: '90587'},
    {city: 'Veitshöchheim', zip: '97209'},
    {city: 'Veitsrodt', zip: '55758'},
    {city: 'Velbert', zip: '42549'},
    {city: 'Velbert', zip: '42551'},
    {city: 'Velbert', zip: '42553'},
    {city: 'Velbert', zip: '42555'},
    {city: 'Velburg', zip: '92355'},
    {city: 'Velden', zip: '84149'},
    {city: 'Velden', zip: '91235'},
    {city: 'Veldenz', zip: '54472'},
    {city: 'Velen', zip: '46342'},
    {city: 'Velgast', zip: '18469'},
    {city: 'Vellahn', zip: '19260'},
    {city: 'Vellberg', zip: '74541'},
    {city: 'Vellmar', zip: '34246'},
    {city: 'Velpke', zip: '38458'},
    {city: 'Velten', zip: '16727'},
    {city: 'Veltheim', zip: '38173'},
    {city: 'Vendersheim', zip: '55578'},
    {city: 'Venningen', zip: '67482'},
    {city: 'Ventschow', zip: '19417'},
    {city: 'Verchen', zip: '17111'},
    {city: 'Verden (Aller)', zip: '27283'},
    {city: 'Veringenstadt', zip: '72519'},
    {city: 'Verl', zip: '33415'},
    {city: 'Versmold', zip: '33775'},
    {city: 'Vestenbergsgreuth', zip: '91487'},
    {city: 'Vetschau/Spreewald', zip: '03226'},
    {city: 'Vettelschoß', zip: '53560'},
    {city: 'Vettweiß', zip: '52391'},
    {city: 'Viechtach', zip: '94234'},
    {city: 'Vielank', zip: '19303'},
    {city: 'Vielbach', zip: '56244'},
    {city: 'Vielitzsee', zip: '16835'},
    {city: 'Vierden', zip: '27419'},
    {city: 'Viereck', zip: '17309'},
    {city: 'Viereth-Trunstadt', zip: '96191'},
    {city: 'Vierherrenborn', zip: '54314'},
    {city: 'Vierhöfen', zip: '21444'},
    {city: 'Vierkirchen', zip: '85256'},
    {city: 'Vierkirchen', zip: '02894'},
    {city: 'Vierlinden', zip: '15306'},
    {city: 'Viernheim', zip: '68519'},
    {city: 'Viersen', zip: '41747'},
    {city: 'Viersen', zip: '41748'},
    {city: 'Viersen', zip: '41749'},
    {city: 'Viersen', zip: '41751'},
    {city: 'Vilgertshofen', zip: '86946'},
    {city: 'Villenbach', zip: '86637'},
    {city: 'Villingendorf', zip: '78667'},
    {city: 'Villingen-Schwenningen', zip: '78048'},
    {city: 'Villingen-Schwenningen', zip: '78050'},
    {city: 'Villingen-Schwenningen', zip: '78052'},
    {city: 'Villingen-Schwenningen', zip: '78054'},
    {city: 'Villingen-Schwenningen', zip: '78056'},
    {city: 'Villmar', zip: '65606'},
    {city: 'Vilsbiburg', zip: '84137'},
    {city: 'Vilseck', zip: '92249'},
    {city: 'Vilsheim', zip: '84186'},
    {city: 'Vilshofen an der Donau', zip: '94474'},
    {city: 'Vinningen', zip: '66957'},
    {city: 'Viöl', zip: '25884'},
    {city: 'Virneburg', zip: '56729'},
    {city: 'Visbek', zip: '49429'},
    {city: 'Visselhövede', zip: '27374'},
    {city: 'Vlotho', zip: '32602'},
    {city: 'Voerde (Niederrhein)', zip: '46562'},
    {city: 'Vogelsang', zip: '15890'},
    {city: 'Vogelsang-Warsin', zip: '17375'},
    {city: 'Vogelsberg', zip: '99610'},
    {city: 'Vögelsen', zip: '21360'},
    {city: 'Vogt', zip: '88267'},
    {city: 'Vogtareuth', zip: '83569'},
    {city: 'Vogtei', zip: '99986'},
    {city: 'Vogtsburg im Kaiserstuhl', zip: '79235'},
    {city: 'Vohburg an der Donau', zip: '85088'},
    {city: 'Vohenstrauß', zip: '92648'},
    {city: 'Vöhl', zip: '34516'},
    {city: 'Vöhrenbach', zip: '78147'},
    {city: 'Vöhringen', zip: '89269'},
    {city: 'Vöhringen', zip: '72189'},
    {city: 'Voigtsdorf', zip: '17349'},
    {city: 'Volkach', zip: '97332'},
    {city: 'Volkenschwand', zip: '84106'},
    {city: 'Volkerode', zip: '37308'},
    {city: 'Völkersweiler', zip: '76857'},
    {city: 'Volkertshausen', zip: '78269'},
    {city: 'Volkerzen', zip: '57612'},
    {city: 'Volkesfeld', zip: '56745'},
    {city: 'Völklingen', zip: '66333'},
    {city: 'Volkmannsdorf', zip: '07924'},
    {city: 'Volkmarsen', zip: '34471'},
    {city: 'Vollersode', zip: '27729'},
    {city: 'Vollersroda', zip: '99438'},
    {city: 'Vollerwiek', zip: '25836'},
    {city: 'Vollmersbach', zip: '55758'},
    {city: 'Vollmershain', zip: '04626'},
    {city: 'Vollmersweiler', zip: '76744'},
    {city: 'Vollrathsruhe', zip: '17194'},
    {city: 'Vollstedt', zip: '25821'},
    {city: 'Völpke', zip: '39393'},
    {city: 'Völschow', zip: '17129'},
    {city: 'Volsemenhusen', zip: '25693'},
    {city: 'Voltlage', zip: '49599'},
    {city: 'Volxheim', zip: '55546'},
    {city: 'Vorbach', zip: '95519'},
    {city: 'Vorbeck', zip: '18258'},
    {city: 'Vorderweidenthal', zip: '76889'},
    {city: 'Vordorf', zip: '38533'},
    {city: 'Vorra', zip: '91247'},
    {city: 'Vörstetten', zip: '79279'},
    {city: 'Vorwerk', zip: '27412'},
    {city: 'Vreden', zip: '48691'},
    {city: 'Vrees', zip: '49757'},
    {city: 'Waabs', zip: '24369'},
    {city: 'Waake', zip: '37136'},
    {city: 'Waakirchen', zip: '83666'},
    {city: 'Waal', zip: '86875'},
    {city: 'Wabern', zip: '34590'},
    {city: 'Wachau', zip: '01454'},
    {city: 'Wachenheim', zip: '67591'},
    {city: 'Wachenheim an der Weinstraße', zip: '67157'},
    {city: 'Wachenroth', zip: '96193'},
    {city: 'Wachstedt', zip: '37359'},
    {city: 'Wachtberg', zip: '53343'},
    {city: 'Wachtendonk', zip: '47669'},
    {city: 'Wächtersbach', zip: '63607'},
    {city: 'Wacken', zip: '25596'},
    {city: 'Wackerow', zip: '17498'},
    {city: 'Wackersberg', zip: '83646'},
    {city: 'Wackersdorf', zip: '92442'},
    {city: 'Waddeweitz', zip: '29496'},
    {city: 'Wadern', zip: '66687'},
    {city: 'Wadersloh', zip: '59329'},
    {city: 'Wadgassen', zip: '66787'},
    {city: 'Waffenbrunn', zip: '93494'},
    {city: 'Wagenfeld', zip: '49419'},
    {city: 'Wagenhausen', zip: '56826'},
    {city: 'Wagenhoff', zip: '38559'},
    {city: 'Wagersrott', zip: '24392'},
    {city: 'Waghäusel', zip: '68753'},
    {city: 'Waging am See', zip: '83329'},
    {city: 'Wahlbach', zip: '55494'},
    {city: 'Wahlenau', zip: '55491'},
    {city: 'Wahlhausen', zip: '37318'},
    {city: 'Wahlheim', zip: '55234'},
    {city: 'Wahlrod', zip: '57614'},
    {city: 'Wahlstedt', zip: '23812'},
    {city: 'Wahlstorf', zip: '24211'},
    {city: 'Wahnwegen', zip: '66909'},
    {city: 'Wahrenholz', zip: '29399'},
    {city: 'Waiblingen', zip: '71332'},
    {city: 'Waiblingen', zip: '71334'},
    {city: 'Waiblingen', zip: '71336'},
    {city: 'Waibstadt', zip: '74915'},
    {city: 'Waidhaus', zip: '92726'},
    {city: 'Waidhofen', zip: '86579'},
    {city: 'Waigandshain', zip: '56477'},
    {city: 'Waigolshausen', zip: '97534'},
    {city: 'Wain', zip: '88489'},
    {city: 'Wain', zip: '89165'},
    {city: 'Waischenfeld', zip: '91344'},
    {city: 'Wakendorf I', zip: '23845'},
    {city: 'Wakendorf II', zip: '24558'},
    {city: 'Walchum', zip: '26907'},
    {city: 'Wald', zip: '87616'},
    {city: 'Wald', zip: '93192'},
    {city: 'Wald', zip: '88639'},
    {city: 'Waldachtal', zip: '72178'},
    {city: 'Waldalgesheim', zip: '55425'},
    {city: 'Waldaschaff', zip: '63857'},
    {city: 'Waldböckelheim', zip: '55596'},
    {city: 'Waldbreitbach', zip: '56588'},
    {city: 'Waldbröl', zip: '51545'},
    {city: 'Waldbronn', zip: '76337'},
    {city: 'Waldbrunn', zip: '97295'},
    {city: 'Waldbrunn', zip: '65620'},
    {city: 'Waldbrunn', zip: '69429'},
    {city: 'Waldburg', zip: '88289'},
    {city: 'Waldbüttelbrunn', zip: '97297'},
    {city: 'Walddorfhäslach', zip: '72141'},
    {city: 'Waldeck', zip: '34513'},
    {city: 'Waldeck', zip: '07646'},
    {city: 'Waldems', zip: '65529'},
    {city: 'Waldenbuch', zip: '71111'},
    {city: 'Waldenburg', zip: '74638'},
    {city: 'Waldenburg', zip: '08396'},
    {city: 'Walderbach', zip: '93194'},
    {city: 'Waldershof', zip: '95679'},
    {city: 'Waldesch', zip: '56323'},
    {city: 'Waldfeucht', zip: '52525'},
    {city: 'Waldfischbach-Burgalben', zip: '67714'},
    {city: 'Waldgrehweiler', zip: '67822'},
    {city: 'Waldhambach', zip: '76857'},
    {city: 'Waldheim', zip: '04736'},
    {city: 'Waldhof-Falkenstein', zip: '54673'},
    {city: 'Waldhufen', zip: '02906'},
    {city: 'Waldkappel', zip: '37284'},
    {city: 'Waldkirch', zip: '79183'},
    {city: 'Waldkirchen', zip: '94065'},
    {city: 'Waldkraiburg', zip: '84478'},
    {city: 'Waldlaubersheim', zip: '55444'},
    {city: 'Waldleiningen', zip: '67693'},
    {city: 'Wald-Michelbach', zip: '69483'},
    {city: 'Waldmohr', zip: '66914'},
    {city: 'Waldmühlen', zip: '56479'},
    {city: 'Waldmünchen', zip: '93449'},
    {city: 'Waldorf', zip: '53498'},
    {city: 'Waldrach', zip: '54320'},
    {city: 'Waldrohrbach', zip: '76857'},
    {city: 'Waldsassen', zip: '95652'},
    {city: 'Waldsee', zip: '67165'},
    {city: 'Waldshut-Tiengen', zip: '79761'},
    {city: 'Waldsieversdorf', zip: '15377'},
    {city: 'Waldsolms', zip: '35647'},
    {city: 'Waldstetten', zip: '89367'},
    {city: 'Waldstetten', zip: '73550'},
    {city: 'Waldthurn', zip: '92727'},
    {city: 'Waldweiler', zip: '54429'},
    {city: 'Walhausen', zip: '56865'},
    {city: 'Walheim', zip: '74399'},
    {city: 'Walkendorf', zip: '17179'},
    {city: 'Walkenried', zip: '37445'},
    {city: 'Walkertshofen', zip: '86877'},
    {city: 'Walksfelde', zip: '23896'},
    {city: 'Walldorf', zip: '69190'},
    {city: 'Walldürn', zip: '63928'},
    {city: 'Walldürn', zip: '74731'},
    {city: 'Wallen', zip: '25788'},
    {city: 'Wallenborn', zip: '54570'},
    {city: 'Wallendorf', zip: '54675'},
    {city: 'Wallenfels', zip: '96346'},
    {city: 'Wallenhorst', zip: '49134'},
    {city: 'Wallerfangen', zip: '66798'},
    {city: 'Wallerfing', zip: '94574'},
    {city: 'Wallersdorf', zip: '94522'},
    {city: 'Wallersheim', zip: '54597'},
    {city: 'Wallerstein', zip: '86757'},
    {city: 'Wallertheim', zip: '55578'},
    {city: 'Wallgau', zip: '82499'},
    {city: 'Wallhalben', zip: '66917'},
    {city: 'Wallhausen', zip: '74599'},
    {city: 'Wallhausen', zip: '55595'},
    {city: 'Wallhausen', zip: '06528'},
    {city: 'Wallmenroth', zip: '57584'},
    {city: 'Wallmerod', zip: '56414'},
    {city: 'Wallmoden', zip: '38729'},
    {city: 'Wallsbüll', zip: '24980'},
    {city: 'Wallscheid', zip: '54531'},
    {city: 'Wallstawe', zip: '29413'},
    {city: 'Walluf', zip: '65396'},
    {city: 'Walow', zip: '17209'},
    {city: 'Walpernhain', zip: '07613'},
    {city: 'Walpertskirchen', zip: '85469'},
    {city: 'Walschleben', zip: '99189'},
    {city: 'Walsdorf', zip: '54578'},
    {city: 'Walsdorf', zip: '96194'},
    {city: 'Walshausen', zip: '66484'},
    {city: 'Walsheim', zip: '76833'},
    {city: 'Walsleben', zip: '16818'},
    {city: 'Walsrode', zip: '29664'},
    {city: 'Walsrode', zip: '29699'},
    {city: 'Waltenhausen', zip: '86480'},
    {city: 'Waltenhofen', zip: '87448'},
    {city: 'Walterschen', zip: '57632'},
    {city: 'Waltersdorf', zip: '07646'},
    {city: 'Waltershausen', zip: '99880'},
    {city: 'Walting', zip: '85137'},
    {city: 'Waltrop', zip: '45731'},
    {city: 'Walzbachtal', zip: '75045'},
    {city: 'Wanderup', zip: '24997'},
    {city: 'Wandlitz', zip: '16348'},
    {city: 'Wanfried', zip: '37281'},
    {city: 'Wang', zip: '85368'},
    {city: 'Wangelau', zip: '21483'},
    {city: 'Wangelnstedt', zip: '37627'},
    {city: 'Wangels', zip: '23758'},
    {city: 'Wangen', zip: '73117'},
    {city: 'Wangen im Allgäu', zip: '88239'},
    {city: 'Wangerland', zip: '26434'},
    {city: 'Wangerooge', zip: '26486'},
    {city: 'Wankendorf', zip: '24601'},
    {city: 'Wanna', zip: '21776'},
    {city: 'Wannweil', zip: '72827'},
    {city: 'Wanzleben-Börde', zip: '39164'},
    {city: 'Wapelfeld', zip: '24594'},
    {city: 'Warberg', zip: '38378'},
    {city: 'Warburg', zip: '34414'},
    {city: 'Wardenburg', zip: '26203'},
    {city: 'Warder', zip: '24646'},
    {city: 'Wardow', zip: '18299'},
    {city: 'Warendorf', zip: '48231'},
    {city: 'Waren (Müritz)', zip: '17192'},
    {city: 'Warin', zip: '19417'},
    {city: 'Warlitz', zip: '19230'},
    {city: 'Warlow', zip: '19288'},
    {city: 'Warmensteinach', zip: '95485'},
    {city: 'Warmsen', zip: '31606'},
    {city: 'Warmsroth', zip: '55442'},
    {city: 'Warnau', zip: '24250'},
    {city: 'Warngau', zip: '83627'},
    {city: 'Warnkenhagen', zip: '17168'},
    {city: 'Warnow', zip: '23936'},
    {city: 'Warnow', zip: '23948'},
    {city: 'Warnow', zip: '18249'},
    {city: 'Warpe', zip: '27333'},
    {city: 'Warrenzin', zip: '17111'},
    {city: 'Warringholz', zip: '25560'},
    {city: 'Warsow', zip: '19075'},
    {city: 'Warstein', zip: '59581'},
    {city: 'Wartenberg', zip: '36367'},
    {city: 'Wartenberg', zip: '85456'},
    {city: 'Wartenberg-Rohrbach', zip: '67681'},
    {city: 'Warthausen', zip: '88447'},
    {city: 'Wartmannsroth', zip: '97797'},
    {city: 'Warwerort', zip: '25761'},
    {city: 'Wasbek', zip: '24647'},
    {city: 'Wasbüttel', zip: '38553'},
    {city: 'Wäschenbeuren', zip: '73116'},
    {city: 'Wäschenbeuren', zip: '73547'},
    {city: 'Wasenbach', zip: '56370'},
    {city: 'Wassenach', zip: '56653'},
    {city: 'Wassenberg', zip: '41849'},
    {city: 'Wasserburg am Inn', zip: '83512'},
    {city: 'Wasserburg (Bodensee)', zip: '88142'},
    {city: 'Wasserliesch', zip: '54332'},
    {city: 'Wasserlosen', zip: '97535'},
    {city: 'Wasserthaleben', zip: '99718'},
    {city: 'Wassertrüdingen', zip: '91717'},
    {city: 'Wasungen', zip: '98634'},
    {city: 'Wathlingen', zip: '29339'},
    {city: 'Wattenbek', zip: '24582'},
    {city: 'Wattendorf', zip: '96196'},
    {city: 'Wattenheim', zip: '67319'},
    {city: 'Watzerath', zip: '54595'},
    {city: 'Wawern', zip: '54612'},
    {city: 'Wawern', zip: '54441'},
    {city: 'Waxweiler', zip: '54649'},
    {city: 'Wechingen', zip: '86759'},
    {city: 'Wechselburg', zip: '09306'},
    {city: 'Weddelbrook', zip: '24576'},
    {city: 'Weddingstedt', zip: '25795'},
    {city: 'Wedel', zip: '22880'},
    {city: 'Wedemark', zip: '30900'},
    {city: 'Wedendorfersee', zip: '19217'},
    {city: 'Weede', zip: '23795'},
    {city: 'Weener', zip: '26826'},
    {city: 'Wees', zip: '24999'},
    {city: 'Weesby', zip: '24994'},
    {city: 'Weeze', zip: '47652'},
    {city: 'Wefensleben', zip: '39365'},
    {city: 'Wegberg', zip: '41844'},
    {city: 'Wegeleben', zip: '38828'},
    {city: 'Wegscheid', zip: '94110'},
    {city: 'Wehingen', zip: '78564'},
    {city: 'Wehlen', zip: '01829'},
    {city: 'Wehnde', zip: '37339'},
    {city: 'Wehr', zip: '56653'},
    {city: 'Wehr', zip: '79664'},
    {city: 'Wehrbleck', zip: '27259'},
    {city: 'Wehrbleck', zip: '49453'},
    {city: 'Wehretal', zip: '37287'},
    {city: 'Wehrheim', zip: '61273'},
    {city: 'Wehringen', zip: '86517'},
    {city: 'Weibern', zip: '56745'},
    {city: 'Weibersbrunn', zip: '63879'},
    {city: 'Weichering', zip: '86706'},
    {city: 'Weichs', zip: '85258'},
    {city: 'Weida', zip: '07570'},
    {city: 'Weiden', zip: '55758'},
    {city: 'Weidenbach', zip: '91746'},
    {city: 'Weidenbach', zip: '56355'},
    {city: 'Weidenbach', zip: '54570'},
    {city: 'Weidenberg', zip: '95466'},
    {city: 'Weidenhahn', zip: '56244'},
    {city: 'Weiden in der Oberpfalz', zip: '92637'},
    {city: 'Weidenstetten', zip: '89197'},
    {city: 'Weidenthal', zip: '67475'},
    {city: 'Weidhausen b.Coburg', zip: '96279'},
    {city: 'Weiding', zip: '93495'},
    {city: 'Weiding', zip: '92557'},
    {city: 'Weidingen', zip: '54636'},
    {city: 'Weigendorf', zip: '91249'},
    {city: 'Weigenheim', zip: '97215'},
    {city: 'Weihenzell', zip: '91629'},
    {city: 'Weiherhammer', zip: '92700'},
    {city: 'Weiherhammer', zip: '92729'},
    {city: 'Weihmichl', zip: '84107'},
    {city: 'Weikersheim', zip: '97990'},
    {city: 'Weil', zip: '86947'},
    {city: 'Weil am Rhein', zip: '79576'},
    {city: 'Weilar', zip: '36457'},
    {city: 'Weilbach', zip: '63937'},
    {city: 'Weilburg', zip: '35781'},
    {city: 'Weil der Stadt', zip: '71263'},
    {city: 'Weilen unter den Rinnen', zip: '72367'},
    {city: 'Weiler', zip: '56729'},
    {city: 'Weiler', zip: '56825'},
    {city: 'Weilerbach', zip: '67685'},
    {city: 'Weiler bei Bingen', zip: '55413'},
    {city: 'Weiler bei Monzingen', zip: '55627'},
    {city: 'Weilersbach', zip: '91365'},
    {city: 'Weiler-Simmerberg', zip: '88171'},
    {city: 'Weilerswist', zip: '53919'},
    {city: 'Weilheim', zip: '79809'},
    {city: 'Weilheim an der Teck', zip: '73235'},
    {city: 'Weilheim in Oberbayern', zip: '82362'},
    {city: 'Weil im Schönbuch', zip: '71093'},
    {city: 'Weilmünster', zip: '35789'},
    {city: 'Weilrod', zip: '61276'},
    {city: 'Weiltingen', zip: '91744'},
    {city: 'Weimar', zip: '99423'},
    {city: 'Weimar', zip: '99425'},
    {city: 'Weimar', zip: '99427'},
    {city: 'Weimar', zip: '99428'},
    {city: 'Weimar (Lahn)', zip: '35096'},
    {city: 'Weinähr', zip: '56379'},
    {city: 'Weinbach', zip: '35796'},
    {city: 'Weinböhla', zip: '01689'},
    {city: 'Weingarten', zip: '76356'},
    {city: 'Weingarten', zip: '88250'},
    {city: 'Weingarten (Pfalz)', zip: '67366'},
    {city: 'Weinheim', zip: '69469'},
    {city: 'Weinolsheim', zip: '55278'},
    {city: 'Weinsberg', zip: '74189'},
    {city: 'Weinsheim', zip: '54595'},
    {city: 'Weinsheim', zip: '55595'},
    {city: 'Weinstadt', zip: '71384'},
    {city: 'Weira', zip: '07806'},
    {city: 'Weischlitz', zip: '08538'},
    {city: 'Weisel', zip: '56348'},
    {city: 'Weisen', zip: '19322'},
    {city: 'Weisenbach', zip: '76599'},
    {city: 'Weisendorf', zip: '91085'},
    {city: 'Weisenheim am Berg', zip: '67273'},
    {city: 'Weisenheim am Sand', zip: '67256'},
    {city: 'Weiskirchen', zip: '66709'},
    {city: 'Weismain', zip: '96260'},
    {city: 'Weissach', zip: '71287'},
    {city: 'Weissach im Tal', zip: '71554'},
    {city: 'Weißbach', zip: '74679'},
    {city: 'Weißbach', zip: '07646'},
    {city: 'Weißdorf', zip: '95237'},
    {city: 'Weißenberg', zip: '02627'},
    {city: 'Weißenborn', zip: '37299'},
    {city: 'Weißenborn', zip: '07639'},
    {city: 'Weißenborn/Erzgebirge', zip: '09600'},
    {city: 'Weißenbrunn', zip: '96369'},
    {city: 'Weißenburg i. Bay.', zip: '91781'},
    {city: 'Weißendorf', zip: '07950'},
    {city: 'Weißenfels', zip: '06667'},
    {city: 'Weißenfels', zip: '06688'},
    {city: 'Weißenhorn', zip: '89264'},
    {city: 'Weißenohe', zip: '91367'},
    {city: 'Weißensberg', zip: '88138'},
    {city: 'Weißensee', zip: '99631'},
    {city: 'Weißenstadt', zip: '95163'},
    {city: 'Weißenthurm', zip: '56575'},
    {city: 'Weißkeißel', zip: '02957'},
    {city: 'Weißwasser/O.L.', zip: '02943'},
    {city: 'Weisweil', zip: '79367'},
    {city: 'Weitefeld', zip: '57586'},
    {city: 'Weitendorf', zip: '19412'},
    {city: 'Weitenhagen', zip: '18461'},
    {city: 'Weitenhagen', zip: '17498'},
    {city: 'Weitersbach', zip: '55624'},
    {city: 'Weitersborn', zip: '55629'},
    {city: 'Weitersburg', zip: '56191'},
    {city: 'Weiterstadt', zip: '64331'},
    {city: 'Weitersweiler', zip: '67808'},
    {city: 'Weitnau', zip: '87480'},
    {city: 'Weitramsdorf', zip: '96479'},
    {city: 'Welcherath', zip: '53539'},
    {city: 'Welchweiler', zip: '66887'},
    {city: 'Welden', zip: '86465'},
    {city: 'Welgesheim', zip: '55576'},
    {city: 'Welkenbach', zip: '57644'},
    {city: 'Welle', zip: '21261'},
    {city: 'Wellen', zip: '54441'},
    {city: 'Wellendingen', zip: '78669'},
    {city: 'Wellheim', zip: '91809'},
    {city: 'Welling', zip: '56753'},
    {city: 'Welmbüttel', zip: '25782'},
    {city: 'Welschbillig', zip: '54298'},
    {city: 'Welschenbach', zip: '56729'},
    {city: 'Welschneudorf', zip: '56412'},
    {city: 'Welt', zip: '25836'},
    {city: 'Welterod', zip: '56357'},
    {city: 'Weltersburg', zip: '56459'},
    {city: 'Welver', zip: '59514'},
    {city: 'Welzheim', zip: '73553'},
    {city: 'Welzheim', zip: '73635'},
    {city: 'Welzheim', zip: '73642'},
    {city: 'Welzheim', zip: '73667'},
    {city: 'Welzow', zip: '03119'},
    {city: 'Wembach', zip: '79677'},
    {city: 'Wemding', zip: '86650'},
    {city: 'Wendeburg', zip: '38176'},
    {city: 'Wendelsheim', zip: '55234'},
    {city: 'Wendelstein', zip: '90530'},
    {city: 'Wenden', zip: '57482'},
    {city: 'Wendisch Baggendorf', zip: '18513'},
    {city: 'Wendisch Evern', zip: '21403'},
    {city: 'Wendisch Rietz', zip: '15864'},
    {city: 'Wendlingen am Neckar', zip: '73240'},
    {city: 'Wendorf', zip: '18442'},
    {city: 'Wendtorf', zip: '24235'},
    {city: 'Weng', zip: '84187'},
    {city: 'Wennbüttel', zip: '25767'},
    {city: 'Wennigsen (Deister)', zip: '30974'},
    {city: 'Wenningstedt-Braderup (Sylt)', zip: '25996'},
    {city: 'Wensin', zip: '23827'},
    {city: 'Wentorf', zip: '23898'},
    {city: 'Wentorf bei Hamburg', zip: '21465'},
    {city: 'Wenzenbach', zip: '93173'},
    {city: 'Wenzendorf', zip: '21279'},
    {city: 'Wenzlow', zip: '14778'},
    {city: 'Werbach', zip: '97956'},
    {city: 'Werben', zip: '03096'},
    {city: 'Werben (Elbe)', zip: '39539'},
    {city: 'Werben (Elbe)', zip: '39615'},
    {city: 'Werda', zip: '08223'},
    {city: 'Werdau', zip: '08412'},
    {city: 'Werder', zip: '19386'},
    {city: 'Werder', zip: '17089'},
    {city: 'Werder (Havel)', zip: '14542'},
    {city: 'Werdohl', zip: '58791'},
    {city: 'Werdum', zip: '26427'},
    {city: 'Werkhausen', zip: '57635'},
    {city: 'Werl', zip: '59457'},
    {city: 'Werlte', zip: '49757'},
    {city: 'Wermelskirchen', zip: '42929'},
    {city: 'Wermsdorf', zip: '04779'},
    {city: 'Wernau (Neckar)', zip: '73249'},
    {city: 'Wernberg-Köblitz', zip: '92533'},
    {city: 'Wernburg', zip: '07381'},
    {city: 'Werne', zip: '59368'},
    {city: 'Werneck', zip: '97440'},
    {city: 'Wernersberg', zip: '76857'},
    {city: 'Werneuchen', zip: '16356'},
    {city: 'Wernigerode', zip: '38855'},
    {city: 'Wernigerode', zip: '38875'},
    {city: 'Wernigerode', zip: '38879'},
    {city: 'Werningshausen', zip: '99634'},
    {city: 'Weroth', zip: '56414'},
    {city: 'Werpeloh', zip: '49751'},
    {city: 'Werra-Suhl-Tal', zip: '99837'},
    {city: 'Wershofen', zip: '53520'},
    {city: 'Wertach', zip: '87497'},
    {city: 'Wertheim', zip: '97877'},
    {city: 'Werther', zip: '33824'},
    {city: 'Werther', zip: '99735'},
    {city: 'Wertingen', zip: '86637'},
    {city: 'Wesel', zip: '46483'},
    {city: 'Wesel', zip: '46485'},
    {city: 'Wesel', zip: '46487'},
    {city: 'Weselberg', zip: '66919'},
    {city: 'Wesenberg', zip: '23858'},
    {city: 'Wesenberg', zip: '17255'},
    {city: 'Wesendorf', zip: '29392'},
    {city: 'Wesertal', zip: '34399'},
    {city: 'Wesselburen', zip: '25764'},
    {city: 'Wesselburener Deichhausen', zip: '25764'},
    {city: 'Wesselburenerkoog', zip: '25764'},
    {city: 'Wesseling', zip: '50389'},
    {city: 'Wesseln', zip: '25746'},
    {city: 'Weßling', zip: '82234'},
    {city: 'Wessobrunn', zip: '82405'},
    {city: 'Weste', zip: '29599'},
    {city: 'Westendorf', zip: '86707'},
    {city: 'Westendorf', zip: '87679'},
    {city: 'Westensee', zip: '24259'},
    {city: 'Westerau', zip: '23847'},
    {city: 'Westerborstel', zip: '25782'},
    {city: 'Westerburg', zip: '56457'},
    {city: 'Westerdeichstrich', zip: '25761'},
    {city: 'Westergellersen', zip: '21394'},
    {city: 'Westerheim', zip: '87784'},
    {city: 'Westerheim', zip: '72589'},
    {city: 'Westerhever', zip: '25881'},
    {city: 'Westerholt', zip: '26556'},
    {city: 'Westerholz', zip: '24977'},
    {city: 'Westerhorn', zip: '25364'},
    {city: 'Westerkappeln', zip: '49492'},
    {city: 'Westermoor', zip: '25597'},
    {city: 'Westerngrund', zip: '63825'},
    {city: 'Westernohe', zip: '56479'},
    {city: 'Wester-Ohrstedt', zip: '25885'},
    {city: 'Westerrade', zip: '23815'},
    {city: 'Westerrönfeld', zip: '24784'},
    {city: 'Westerstede', zip: '26655'},
    {city: 'Westerstetten', zip: '89198'},
    {city: 'Westertimke', zip: '27412'},
    {city: 'Westerwalsede', zip: '27386'},
    {city: 'Westgreußen', zip: '99718'},
    {city: 'Westhausen', zip: '73463'},
    {city: 'Westhausen', zip: '98663'},
    {city: 'Westheide', zip: '39343'},
    {city: 'Westheide', zip: '39345'},
    {city: 'Westheim', zip: '91747'},
    {city: 'Westheim', zip: '67368'},
    {city: 'Westhofen', zip: '67593'},
    {city: 'Westoverledingen', zip: '26810'},
    {city: 'Westre', zip: '25926'},
    {city: 'Wethau', zip: '06618'},
    {city: 'Wetschen', zip: '49453'},
    {city: 'Wettenberg', zip: '35435'},
    {city: 'Wetter', zip: '35083'},
    {city: 'Wetter (Ruhr)', zip: '58300'},
    {city: 'Wetterzeube', zip: '06722'},
    {city: 'Wettin-Löbejün', zip: '06193'},
    {city: 'Wettlingen', zip: '54646'},
    {city: 'Wettringen', zip: '48493'},
    {city: 'Wettringen', zip: '91631'},
    {city: 'Wettrup', zip: '49838'},
    {city: 'Wettstetten', zip: '85139'},
    {city: 'Wetzlar', zip: '35576'},
    {city: 'Wetzlar', zip: '35578'},
    {city: 'Wetzlar', zip: '35579'},
    {city: 'Wetzlar', zip: '35580'},
    {city: 'Wetzlar', zip: '35581'},
    {city: 'Wetzlar', zip: '35582'},
    {city: 'Wetzlar', zip: '35583'},
    {city: 'Wetzlar', zip: '35584'},
    {city: 'Wetzlar', zip: '35585'},
    {city: 'Wetzlar', zip: '35586'},
    {city: 'Wewelsfleth', zip: '25599'},
    {city: 'Weyarn', zip: '83629'},
    {city: 'Weyer', zip: '56357'},
    {city: 'Weyerbusch', zip: '57635'},
    {city: 'Weyhausen', zip: '38554'},
    {city: 'Weyhe', zip: '28844'},
    {city: 'Weyher in der Pfalz', zip: '76835'},
    {city: 'Wichmar', zip: '07774'},
    {city: 'Wickede (Ruhr)', zip: '58739'},
    {city: 'Wickenrodt', zip: '55758'},
    {city: 'Widdern', zip: '74259'},
    {city: 'Wiebelsheim', zip: '56291'},
    {city: 'Wieck a. Darß', zip: '18375'},
    {city: 'Wied', zip: '57629'},
    {city: 'Wiedemar', zip: '04509'},
    {city: 'Wieden', zip: '79695'},
    {city: 'Wiedenborstel', zip: '24613'},
    {city: 'Wiedensahl', zip: '31719'},
    {city: 'Wiedergeltingen', zip: '86879'},
    {city: 'Wiefelstede', zip: '26215'},
    {city: 'Wiegendorf', zip: '99510'},
    {city: 'Wiehl', zip: '51674'},
    {city: 'Wiek', zip: '18556'},
    {city: 'Wielen', zip: '49847'},
    {city: 'Wielenbach', zip: '82407'},
    {city: 'Wiemersdorf', zip: '24649'},
    {city: 'Wiemerstedt', zip: '25779'},
    {city: 'Wiendorf', zip: '18258'},
    {city: 'Wienhausen', zip: '29342'},
    {city: 'Wiernsheim', zip: '75446'},
    {city: 'Wierschem', zip: '56294'},
    {city: 'Wiersdorf', zip: '54636'},
    {city: 'Wiershop', zip: '21502'},
    {city: 'Wiesau', zip: '95676'},
    {city: 'Wiesbach', zip: '66894'},
    {city: 'Wiesbaden', zip: '55246'},
    {city: 'Wiesbaden', zip: '55252'},
    {city: 'Wiesbaden', zip: '65183'},
    {city: 'Wiesbaden', zip: '65185'},
    {city: 'Wiesbaden', zip: '65187'},
    {city: 'Wiesbaden', zip: '65189'},
    {city: 'Wiesbaden', zip: '65191'},
    {city: 'Wiesbaden', zip: '65193'},
    {city: 'Wiesbaden', zip: '65195'},
    {city: 'Wiesbaden', zip: '65197'},
    {city: 'Wiesbaden', zip: '65199'},
    {city: 'Wiesbaden', zip: '65201'},
    {city: 'Wiesbaden', zip: '65203'},
    {city: 'Wiesbaden', zip: '65205'},
    {city: 'Wiesbaden', zip: '65207'},
    {city: 'Wiesbaum', zip: '54578'},
    {city: 'Wiesemscheid', zip: '53534'},
    {city: 'Wiesen', zip: '63831'},
    {city: 'Wiesenau', zip: '15295'},
    {city: 'Wiesenaue', zip: '14662'},
    {city: 'Wiesenbach', zip: '69257'},
    {city: 'Wiesenbach', zip: '86519'},
    {city: 'Wiesenbronn', zip: '97355'},
    {city: 'Wiesenburg/Mark', zip: '14827'},
    {city: 'Wiesenfeld', zip: '37308'},
    {city: 'Wiesenfelden', zip: '94344'},
    {city: 'Wiesengrund', zip: '03149'},
    {city: 'Wiesensteig', zip: '73349'},
    {city: 'Wiesent', zip: '93109'},
    {city: 'Wiesenthal', zip: '36466'},
    {city: 'Wiesenthau', zip: '91369'},
    {city: 'Wiesentheid', zip: '97353'},
    {city: 'Wiesenttal', zip: '91346'},
    {city: 'Wieseth', zip: '91632'},
    {city: 'Wiesloch', zip: '69168'},
    {city: 'Wiesmoor', zip: '26639'},
    {city: 'Wiesthal', zip: '97859'},
    {city: 'Wiesweiler', zip: '67744'},
    {city: 'Wietmarschen', zip: '49835'},
    {city: 'Wietze', zip: '29323'},
    {city: 'Wietzen', zip: '31613'},
    {city: 'Wietzendorf', zip: '29649'},
    {city: 'Wiggensbach', zip: '87487'},
    {city: 'Wilburgstetten', zip: '91634'},
    {city: 'Wildau', zip: '15745'},
    {city: 'Wildberg', zip: '72218'},
    {city: 'Wildberg', zip: '17091'},
    {city: 'Wildeck', zip: '36208'},
    {city: 'Wildenberg', zip: '93359'},
    {city: 'Wildenfels', zip: '08134'},
    {city: 'Wildeshausen', zip: '27793'},
    {city: 'Wildflecken', zip: '97772'},
    {city: 'Wildpoldsried', zip: '87499'},
    {city: 'Wildsteig', zip: '82409'},
    {city: 'Wilgartswiesen', zip: '76848'},
    {city: 'Wilhelmsburg', zip: '17379'},
    {city: 'Wilhelmsdorf', zip: '91489'},
    {city: 'Wilhelmsdorf', zip: '07389'},
    {city: 'Wilhelmsdorf', zip: '88271'},
    {city: 'Wilhelmsfeld', zip: '69259'},
    {city: 'Wilhelmshaven', zip: '26382'},
    {city: 'Wilhelmshaven', zip: '26384'},
    {city: 'Wilhelmshaven', zip: '26386'},
    {city: 'Wilhelmshaven', zip: '26388'},
    {city: 'Wilhelmshaven', zip: '26389'},
    {city: 'Wilhelmsthal', zip: '96352'},
    {city: 'Wilhermsdorf', zip: '91452'},
    {city: 'Wilkau-Haßlau', zip: '08112'},
    {city: 'Willanzheim', zip: '97348'},
    {city: 'Willebadessen', zip: '34439'},
    {city: 'Willenscharen', zip: '24616'},
    {city: 'Willich', zip: '47877'},
    {city: 'Willingen', zip: '56479'},
    {city: 'Willingen (Upland)', zip: '34508'},
    {city: 'Willingshausen', zip: '34628'},
    {city: 'Willmars', zip: '97647'},
    {city: 'Willmenrod', zip: '56459'},
    {city: 'Willmering', zip: '93497'},
    {city: 'Willroth', zip: '56594'},
    {city: 'Willstätt', zip: '77731'},
    {city: 'Willwerscheid', zip: '54533'},
    {city: 'Wilnsdorf', zip: '57234'},
    {city: 'Wilsdruff', zip: '01723'},
    {city: 'Wilsdruff', zip: '01737'},
    {city: 'Wilsecker', zip: '54655'},
    {city: 'Wilstedt', zip: '27412'},
    {city: 'Wilster', zip: '25554'},
    {city: 'Wilsum', zip: '49849'},
    {city: 'Wilthen', zip: '02681'},
    {city: 'Wiltingen', zip: '54459'},
    {city: 'Wilzenberg-Hußweiler', zip: '55767'},
    {city: 'Wimbach', zip: '53518'},
    {city: 'Wimmelburg', zip: '06313'},
    {city: 'Wimsheim', zip: '71299'},
    {city: 'Wincheringen', zip: '54457'},
    {city: 'Windach', zip: '86949'},
    {city: 'Windberg', zip: '94336'},
    {city: 'Windbergen', zip: '25729'},
    {city: 'Windeby', zip: '24340'},
    {city: 'Windeck', zip: '51570'},
    {city: 'Windelsbach', zip: '91635'},
    {city: 'Winden', zip: '56379'},
    {city: 'Winden', zip: '76872'},
    {city: 'Winden im Elztal', zip: '79297'},
    {city: 'Windesheim', zip: '55452'},
    {city: 'Windhagen', zip: '53578'},
    {city: 'Windischeschenbach', zip: '92670'},
    {city: 'Windischleuba', zip: '04603'},
    {city: 'Windorf', zip: '94575'},
    {city: 'Windsbach', zip: '91575'},
    {city: 'Wingerode', zip: '37327'},
    {city: 'Wingst', zip: '21789'},
    {city: 'Winhöring', zip: '84543'},
    {city: 'Winkelbach', zip: '57644'},
    {city: 'Winkel (Eifel)', zip: '54558'},
    {city: 'Winkelhaid', zip: '90610'},
    {city: 'Winkelsett', zip: '27243'},
    {city: 'Winklarn', zip: '92559'},
    {city: 'Winnemark', zip: '24398'},
    {city: 'Winnen', zip: '56459'},
    {city: 'Winnenden', zip: '71364'},
    {city: 'Winnerath', zip: '53520'},
    {city: 'Winnert', zip: '25887'},
    {city: 'Winnigstedt', zip: '38170'},
    {city: 'Winningen', zip: '56333'},
    {city: 'Winnweiler', zip: '67722'},
    {city: 'Winringen', zip: '54614'},
    {city: 'Winseldorf', zip: '25551'},
    {city: 'Winsen', zip: '24568'},
    {city: 'Winsen (Aller)', zip: '29229'},
    {city: 'Winsen (Aller)', zip: '29308'},
    {city: 'Winsen (Luhe)', zip: '21423'},
    {city: 'Winterbach', zip: '73650'},
    {city: 'Winterbach', zip: '89368'},
    {city: 'Winterbach', zip: '55595'},
    {city: 'Winterbach (Pfalz)', zip: '66484'},
    {city: 'Winterberg', zip: '59955'},
    {city: 'Winterborn', zip: '67822'},
    {city: 'Winterburg', zip: '55595'},
    {city: 'Winterhausen', zip: '97286'},
    {city: 'Winterlingen', zip: '72474'},
    {city: 'Winterrieden', zip: '87785'},
    {city: 'Winterscheid', zip: '54608'},
    {city: 'Wintersheim', zip: '67587'},
    {city: 'Winterspelt', zip: '54616'},
    {city: 'Winterwerb', zip: '56355'},
    {city: 'Wintrich', zip: '54487'},
    {city: 'Winzer', zip: '94577'},
    {city: 'Wipfeld', zip: '97537'},
    {city: 'Wipperfürth', zip: '51688'},
    {city: 'Wippingen', zip: '26892'},
    {city: 'Wirdum', zip: '26529'},
    {city: 'Wirft', zip: '53534'},
    {city: 'Wirfus', zip: '56814'},
    {city: 'Wirges', zip: '56422'},
    {city: 'Wirsberg', zip: '95339'},
    {city: 'Wirscheid', zip: '56237'},
    {city: 'Wirschweiler', zip: '55758'},
    {city: 'Wisch', zip: '24217'},
    {city: 'Wisch', zip: '25876'},
    {city: 'Wischhafen', zip: '21737'},
    {city: 'Wismar', zip: '23966'},
    {city: 'Wismar', zip: '23968'},
    {city: 'Wismar', zip: '23970'},
    {city: 'Wissen', zip: '57537'},
    {city: 'Wißmannsdorf', zip: '54636'},
    {city: 'Wistedt', zip: '21255'},
    {city: 'Witsum', zip: '25938'},
    {city: 'Wittbek', zip: '25872'},
    {city: 'Wittdün auf Amrum', zip: '25946'},
    {city: 'Wittelshofen', zip: '91749'},
    {city: 'Witten', zip: '58452'},
    {city: 'Witten', zip: '58453'},
    {city: 'Witten', zip: '58454'},
    {city: 'Witten', zip: '58455'},
    {city: 'Witten', zip: '58456'},
    {city: 'Wittenbeck', zip: '18209'},
    {city: 'Wittenberg', zip: '06886'},
    {city: 'Wittenberg', zip: '06888'},
    {city: 'Wittenberg', zip: '06889'},
    {city: 'Wittenberge', zip: '19322'},
    {city: 'Wittenbergen', zip: '25548'},
    {city: 'Wittenborn', zip: '23829'},
    {city: 'Wittenburg', zip: '19243'},
    {city: 'Wittendörp', zip: '19243'},
    {city: 'Wittenförden', zip: '19073'},
    {city: 'Wittenhagen', zip: '18510'},
    {city: 'Witterda', zip: '99189'},
    {city: 'Wittgert', zip: '56237'},
    {city: 'Wittibreut', zip: '84384'},
    {city: 'Wittichenau', zip: '02997'},
    {city: 'Wittighausen', zip: '97957'},
    {city: 'Wittingen', zip: '29378'},
    {city: 'Wittingen', zip: '29379'},
    {city: 'Wittislingen', zip: '89426'},
    {city: 'Wittlich', zip: '54516'},
    {city: 'Wittlingen', zip: '79599'},
    {city: 'Wittmar', zip: '38329'},
    {city: 'Wittmoldt', zip: '24306'},
    {city: 'Wittmund', zip: '26409'},
    {city: 'Wittnau', zip: '79299'},
    {city: 'Wittorf', zip: '21357'},
    {city: 'Wittstock/Dosse', zip: '16909'},
    {city: 'Witzeeze', zip: '21514'},
    {city: 'Witzenhausen', zip: '37213'},
    {city: 'Witzenhausen', zip: '37214'},
    {city: 'Witzenhausen', zip: '37215'},
    {city: 'Witzenhausen', zip: '37216'},
    {city: 'Witzenhausen', zip: '37217'},
    {city: 'Witzenhausen', zip: '37218'},
    {city: 'Witzhave', zip: '22969'},
    {city: 'Witzin', zip: '19406'},
    {city: 'Witzleben', zip: '99310'},
    {city: 'Witzmannsberg', zip: '94104'},
    {city: 'Witzwort', zip: '25889'},
    {city: 'Wöbbelin', zip: '19288'},
    {city: 'Wobbenbüll', zip: '25856'},
    {city: 'Woggersin', zip: '17039'},
    {city: 'Wohlde', zip: '24899'},
    {city: 'Wohltorf', zip: '21521'},
    {city: 'Wohnste', zip: '27419'},
    {city: 'Wohratal', zip: '35288'},
    {city: 'Wöhrden', zip: '25797'},
    {city: 'Wokuhl-Dabelow', zip: '17237'},
    {city: 'Wolde', zip: '17091'},
    {city: 'Woldegk', zip: '17348'},
    {city: 'Woldert', zip: '57614'},
    {city: 'Wolfach', zip: '77709'},
    {city: 'Wolfegg', zip: '88364'},
    {city: 'Wolfenbüttel', zip: '38300'},
    {city: 'Wolfenbüttel', zip: '38302'},
    {city: 'Wolfenbüttel', zip: '38304'},
    {city: 'Wölferlingen', zip: '56244'},
    {city: 'Wolferschwenda', zip: '99713'},
    {city: 'Wolfersdorf', zip: '85395'},
    {city: 'Wölfersheim', zip: '61200'},
    {city: 'Wolferstadt', zip: '86709'},
    {city: 'Wolfertschwenden', zip: '87787'},
    {city: 'Wolfhagen', zip: '34466'},
    {city: 'Wolframs-Eschenbach', zip: '91639'},
    {city: 'Wolfratshausen', zip: '82515'},
    {city: 'Wolfsburg', zip: '38440'},
    {city: 'Wolfsburg', zip: '38442'},
    {city: 'Wolfsburg', zip: '38444'},
    {city: 'Wolfsburg', zip: '38446'},
    {city: 'Wolfsburg', zip: '38448'},
    {city: 'Wolfschlugen', zip: '72649'},
    {city: 'Wolfsegg', zip: '93195'},
    {city: 'Wolfsheim', zip: '55578'},
    {city: 'Wolfstein', zip: '67752'},
    {city: 'Wolgast', zip: '17438'},
    {city: 'Wolken', zip: '56332'},
    {city: 'Wolkenstein', zip: '09429'},
    {city: 'Wollbach', zip: '97618'},
    {city: 'Wollbrandshausen', zip: '37434'},
    {city: 'Wollershausen', zip: '37434'},
    {city: 'Wollin', zip: '14778'},
    {city: 'Wollmerath', zip: '56826'},
    {city: 'Wöllstadt', zip: '61206'},
    {city: 'Wöllstein', zip: '55597'},
    {city: 'Wolmersdorf', zip: '25704'},
    {city: 'Wölmersen', zip: '57635'},
    {city: 'Wolmirsleben', zip: '39435'},
    {city: 'Wolmirstedt', zip: '39326'},
    {city: 'Wolnzach', zip: '85283'},
    {city: 'Wolpertshausen', zip: '74549'},
    {city: 'Wolpertswende', zip: '88284'},
    {city: 'Wölpinghausen', zip: '31556'},
    {city: 'Wolsdorf', zip: '38379'},
    {city: 'Wolsfeld', zip: '54636'},
    {city: 'Woltersdorf', zip: '15569'},
    {city: 'Woltersdorf', zip: '21516'},
    {city: 'Woltersdorf', zip: '29497'},
    {city: 'Womrath', zip: '55481'},
    {city: 'Wonfurt', zip: '97539'},
    {city: 'Wonneberg', zip: '83379'},
    {city: 'Wonsees', zip: '96197'},
    {city: 'Wonsheim', zip: '55599'},
    {city: 'Woppenroth', zip: '55490'},
    {city: 'Woringen', zip: '87789'},
    {city: 'Worms', zip: '67547'},
    {city: 'Worms', zip: '67549'},
    {city: 'Worms', zip: '67550'},
    {city: 'Worms', zip: '67551'},
    {city: 'Wörnersberg', zip: '72299'},
    {city: 'Wörnitz', zip: '91637'},
    {city: 'Worpswede', zip: '27726'},
    {city: 'Wörrstadt', zip: '55286'},
    {city: 'Wört', zip: '73499'},
    {city: 'Worth', zip: '21502'},
    {city: 'Wörth', zip: '85457'},
    {city: 'Wörth a.d. Isar', zip: '84109'},
    {city: 'Wörth am Main', zip: '63939'},
    {city: 'Wörth am Rhein', zip: '76744'},
    {city: 'Wörth an der Donau', zip: '93086'},
    {city: 'Wörthsee', zip: '82237'},
    {city: 'Wrangelsburg', zip: '17495'},
    {city: 'Wrestedt', zip: '29559'},
    {city: 'Wriedel', zip: '29565'},
    {city: 'Wriezen', zip: '16269'},
    {city: 'Wrist', zip: '25563'},
    {city: 'Wrixum', zip: '25938'},
    {city: 'Wrohm', zip: '25799'},
    {city: 'Wülfershausen an der Saale', zip: '97618'},
    {city: 'Wülfrath', zip: '42489'},
    {city: 'Wulfsen', zip: '21445'},
    {city: 'Wulfsmoor', zip: '25563'},
    {city: 'Wulften am Harz', zip: '37199'},
    {city: 'Wulkenzin', zip: '17039'},
    {city: 'Wülknitz', zip: '01609'},
    {city: 'Wundersleben', zip: '99610'},
    {city: 'Wünschendorf/Elster', zip: '07570'},
    {city: 'Wunsiedel', zip: '95632'},
    {city: 'Wunstorf', zip: '31515'},
    {city: 'Wuppertal', zip: '42103'},
    {city: 'Wuppertal', zip: '42105'},
    {city: 'Wuppertal', zip: '42107'},
    {city: 'Wuppertal', zip: '42109'},
    {city: 'Wuppertal', zip: '42111'},
    {city: 'Wuppertal', zip: '42113'},
    {city: 'Wuppertal', zip: '42115'},
    {city: 'Wuppertal', zip: '42117'},
    {city: 'Wuppertal', zip: '42119'},
    {city: 'Wuppertal', zip: '42275'},
    {city: 'Wuppertal', zip: '42277'},
    {city: 'Wuppertal', zip: '42279'},
    {city: 'Wuppertal', zip: '42281'},
    {city: 'Wuppertal', zip: '42283'},
    {city: 'Wuppertal', zip: '42285'},
    {city: 'Wuppertal', zip: '42287'},
    {city: 'Wuppertal', zip: '42289'},
    {city: 'Wuppertal', zip: '42327'},
    {city: 'Wuppertal', zip: '42329'},
    {city: 'Wuppertal', zip: '42349'},
    {city: 'Wuppertal', zip: '42369'},
    {city: 'Wuppertal', zip: '42389'},
    {city: 'Wuppertal', zip: '42399'},
    {city: 'Wurmannsquick', zip: '84329'},
    {city: 'Wurmberg', zip: '75449'},
    {city: 'Wurmlingen', zip: '78573'},
    {city: 'Wurmsham', zip: '84189'},
    {city: 'Würrich', zip: '56858'},
    {city: 'Würselen', zip: '52146'},
    {city: 'Wurster Nordseeküste', zip: '27639'},
    {city: 'Wurzbach', zip: '07343'},
    {city: 'Würzburg', zip: '97070'},
    {city: 'Würzburg', zip: '97072'},
    {city: 'Würzburg', zip: '97074'},
    {city: 'Würzburg', zip: '97076'},
    {city: 'Würzburg', zip: '97078'},
    {city: 'Würzburg', zip: '97080'},
    {city: 'Würzburg', zip: '97082'},
    {city: 'Würzburg', zip: '97084'},
    {city: 'Wurzen', zip: '04808'},
    {city: 'Würzweiler', zip: '67808'},
    {city: 'Wüschheim', zip: '55471'},
    {city: 'Wüstenrot', zip: '71543'},
    {city: 'Wusterhausen/Dosse', zip: '16845'},
    {city: 'Wusterhausen/Dosse', zip: '16866'},
    {city: 'Wusterhausen/Dosse', zip: '16868'},
    {city: 'Wusterhusen', zip: '17509'},
    {city: 'Wustermark', zip: '14641'},
    {city: 'Wusterwitz', zip: '14789'},
    {city: 'Wust-Fischbeck', zip: '39524'},
    {city: 'Wüstheuterode', zip: '37318'},
    {city: 'Wustrow', zip: '18347'},
    {city: 'Wustrow', zip: '17255'},
    {city: 'Wustrow', zip: '29462'},
    {city: 'Wutach', zip: '79879'},
    {city: 'Wutha-Farnroda', zip: '99848'},
    {city: 'Wutöschingen', zip: '79793'},
    {city: 'Wyhl', zip: '79369'},
    {city: 'Wyk auf Föhr', zip: '25938'},
    {city: 'Xanten', zip: '46509'},
    {city: 'Zaberfeld', zip: '74374'},
    {city: 'Zachenberg', zip: '94239'},
    {city: 'Zahna-Elster', zip: '06895'},
    {city: 'Zaisenhausen', zip: '75059'},
    {city: 'Zandt', zip: '93499'},
    {city: 'Zangberg', zip: '84539'},
    {city: 'Zapel', zip: '19089'},
    {city: 'Zapfendorf', zip: '96199'},
    {city: 'Zarnewanz', zip: '18195'},
    {city: 'Zarpen', zip: '23619'},
    {city: 'Zarrendorf', zip: '18510'},
    {city: 'Zarrentin am Schaalsee', zip: '19246'},
    {city: 'Zechin', zip: '15328'},
    {city: 'Zedlitz', zip: '07557'},
    {city: 'Zehdenick', zip: '16792'},
    {city: 'Zehna', zip: '18276'},
    {city: 'Zehnhausen bei Rennerod', zip: '56477'},
    {city: 'Zehnhausen bei Wallmerod', zip: '56414'},
    {city: 'Zehrental', zip: '39615'},
    {city: 'Zeil am Main', zip: '97475'},
    {city: 'Zeilarn', zip: '84367'},
    {city: 'Zeiskam', zip: '67378'},
    {city: 'Zeithain', zip: '01619'},
    {city: 'Zeitlarn', zip: '93197'},
    {city: 'Zeitlofs', zip: '97799'},
    {city: 'Zeitz', zip: '06711'},
    {city: 'Zeitz', zip: '06712'},
    {city: 'Zell', zip: '93199'},
    {city: 'Zella-Mehlis', zip: '98544'},
    {city: 'Zell am Harmersbach', zip: '77736'},
    {city: 'Zell am Main', zip: '97299'},
    {city: 'Zellertal', zip: '67308'},
    {city: 'Zell im Fichtelgebirge', zip: '95239'},
    {city: 'Zell im Wiesental', zip: '79669'},
    {city: 'Zellingen', zip: '97225'},
    {city: 'Zell (Mosel)', zip: '56856'},
    {city: 'Zell (Mosel)', zip: '56858'},
    {city: 'Zell (Mosel)', zip: '56859'},
    {city: 'Zell unter Aichelberg', zip: '73087'},
    {city: 'Zell unter Aichelberg', zip: '73119'},
    {city: 'Zeltingen-Rachtig', zip: '54492'},
    {city: 'Zeltingen-Rachtig', zip: '54539'},
    {city: 'Zemitz', zip: '17440'},
    {city: 'Zemmer', zip: '54313'},
    {city: 'Zempin', zip: '17459'},
    {city: 'Zendscheid', zip: '54655'},
    {city: 'Zenting', zip: '94579'},
    {city: 'Zepelin', zip: '18246'},
    {city: 'Zerbst/Anhalt', zip: '39261'},
    {city: 'Zerbst/Anhalt', zip: '39264'},
    {city: 'Zerf', zip: '54314'},
    {city: 'Zernien', zip: '29499'},
    {city: 'Zernitz-Lohm', zip: '16845'},
    {city: 'Zerrenthin', zip: '17309'},
    {city: 'Zeschdorf', zip: '15326'},
    {city: 'Zetel', zip: '26340'},
    {city: 'Zettemin', zip: '17153'},
    {city: 'Zettingen', zip: '56761'},
    {city: 'Zettlitz', zip: '09306'},
    {city: 'Zeulenroda-Triebes', zip: '07937'},
    {city: 'Zeulenroda-Triebes', zip: '07950'},
    {city: 'Zeuthen', zip: '15738'},
    {city: 'Zeven', zip: '27404'},
    {city: 'Zichow', zip: '16306'},
    {city: 'Zickhusen', zip: '19069'},
    {city: 'Ziegendorf', zip: '19372'},
    {city: 'Ziegenhain', zip: '57632'},
    {city: 'Ziegenrück', zip: '07924'},
    {city: 'Zielitz', zip: '39326'},
    {city: 'Ziemetshausen', zip: '86473'},
    {city: 'Zierenberg', zip: '34289'},
    {city: 'Zierow', zip: '23968'},
    {city: 'Ziertheim', zip: '89446'},
    {city: 'Zierzow', zip: '19300'},
    {city: 'Ziesar', zip: '14793'},
    {city: 'Ziesendorf', zip: '18059'},
    {city: 'Ziethen', zip: '16247'},
    {city: 'Ziethen', zip: '17390'},
    {city: 'Ziethen', zip: '23911'},
    {city: 'Zilshausen', zip: '56288'},
    {city: 'Ziltendorf', zip: '15295'},
    {city: 'Zimmern', zip: '07778'},
    {city: 'Zimmern ob Rottweil', zip: '78652'},
    {city: 'Zimmern ob Rottweil', zip: '78658'},
    {city: 'Zimmernsupra', zip: '99100'},
    {city: 'Zimmern unter der Burg', zip: '72369'},
    {city: 'Zimmerschied', zip: '56379'},
    {city: 'Zingst', zip: '18374'},
    {city: 'Zinnowitz', zip: '17454'},
    {city: 'Zirchow', zip: '17419'},
    {city: 'Zirkow', zip: '18528'},
    {city: 'Zirndorf', zip: '90513'},
    {city: 'Zirzow', zip: '17039'},
    {city: 'Zislow', zip: '17209'},
    {city: 'Zittau', zip: '02763'},
    {city: 'Zittau', zip: '02788'},
    {city: 'Zölkow', zip: '19374'},
    {city: 'Zolling', zip: '85406'},
    {city: 'Zöllnitz', zip: '07751'},
    {city: 'Zörbig', zip: '06780'},
    {city: 'Zorneding', zip: '85604'},
    {city: 'Zornheim', zip: '55270'},
    {city: 'Zöschingen', zip: '89447'},
    {city: 'Zossen', zip: '15806'},
    {city: 'Zotzenheim', zip: '55576'},
    {city: 'Zschaitz-Ottewig', zip: '04720'},
    {city: 'Zschepplin', zip: '04838'},
    {city: 'Zschopau', zip: '09405'},
    {city: 'Zschopau', zip: '09434'},
    {city: 'Zschorlau', zip: '08321'},
    {city: 'Zülow', zip: '19073'},
    {city: 'Zülpich', zip: '53909'},
    {city: 'Zurow', zip: '23992'},
    {city: 'Zusamaltheim', zip: '86637'},
    {city: 'Züsch', zip: '54422'},
    {city: 'Zusmarshausen', zip: '86441'},
    {city: 'Züsow', zip: '23992'},
    {city: 'Züssow', zip: '17495'},
    {city: 'Zuzenhausen', zip: '74939'},
    {city: 'Zweibrücken', zip: '66482'},
    {city: 'Zweifelscheid', zip: '54673'},
    {city: 'Zweiflingen', zip: '74639'},
    {city: 'Zwenkau', zip: '04442'},
    {city: 'Zwickau', zip: '08056'},
    {city: 'Zwickau', zip: '08058'},
    {city: 'Zwickau', zip: '08060'},
    {city: 'Zwickau', zip: '08062'},
    {city: 'Zwickau', zip: '08064'},
    {city: 'Zwickau', zip: '08066'},
    {city: 'Zwiefalten', zip: '88529'},
    {city: 'Zwiesel', zip: '94227'},
    {city: 'Zwingenberg', zip: '69439'},
    {city: 'Zwingenberg', zip: '64673'},
    {city: 'Zwönitz', zip: '08297'},
];