<template>
  <div>
    <json-name-editor
        v-model="element.name"
        v-if="!parentIsArray"
    />

    <json-priority-editor
        v-model="element.priority"
    />
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import JsonNameEditor
  from "@/ui/components/json-builder/use-cases/json-builder-editor/attribute-editor/JsonNameEditor.vue";
import JsonPriorityEditor
  from "@/ui/components/json-builder/use-cases/json-builder-editor/attribute-editor/JsonPriorityEditor.vue";

export default {
  components: {JsonPriorityEditor, JsonNameEditor},
  props: {
    element: {
      type: BuilderElement,
      required: true,
    },
    parentIsArray: {
      type: Boolean,
      required: true,
    }
  }
}
</script>
