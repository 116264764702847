import {serverApi} from "@/api/server/server-api.js";
import {BuilderElementRelation} from "@/models/builder-element-relations/builder-element-relation.js";

const mapActions = (builderElementRelation) =>
    builderElementRelation.builderElementActions.map((elemAction) => ({
        active_element_id: elemAction.active_element_id,
        passive_element_id: elemAction.passive_element_id,
        value: elemAction.action_value,
        type: elemAction.action,
        run_on: elemAction.run_on,
        data: elemAction.data,
    }))

const mapConditions = (builderElementRelation) =>
    builderElementRelation.builderElementConditions.map((elemCond) => ({
        active_element_id: elemCond.active_element_id,
        passive_element_id: elemCond.passive_element_id,
        value: elemCond.condition_value,
        type: elemCond.condition,
        group_code: elemCond.group_code,
        data: elemCond.data,
    }));

const mapConditionGroups = (builderElementRelation) =>
    builderElementRelation.builderElementConditionGroups.map((elemCond) => ({
        code: elemCond.code,
        name: elemCond.name,
    }));

export const builderRelationsModule = {
    namespaced: true,
    actions: {
        getBuilderElementRelations: async (context, {buildersId}) => {
            const {data} = await serverApi.admin.builderRelations.getElementRelations(buildersId)

            return data.builderElementRelations.map((item) => new BuilderElementRelation(item))
        },

        saveBuilderElementRelation: async (context, {builderElementRelation}) => {
            const actions = mapActions(builderElementRelation);

            const conditions = mapConditions(builderElementRelation);
            const conditionGroups = mapConditionGroups(builderElementRelation);

            const {data} = await serverApi.admin.builderRelations.saveElementRelation(
                builderElementRelation.builder_id,
                builderElementRelation.description,
                builderElementRelation.is_validation,
                builderElementRelation.show_validation_on,
                builderElementRelation.position,
                builderElementRelation.run_on_test,
                conditions,
                actions,
                conditionGroups
            )

            return data.success;
        },

        updateBuilderElementRelation: async (context, {builderElementRelation}) => {
            const actions = mapActions(builderElementRelation);

            const conditions = mapConditions(builderElementRelation);
            const conditionGroups = mapConditionGroups(builderElementRelation);

            const {data} = await serverApi.admin.builderRelations.updateElementRelation(
                builderElementRelation.id,
                builderElementRelation.builder_id,
                builderElementRelation.description,
                builderElementRelation.is_validation,
                builderElementRelation.show_validation_on,
                builderElementRelation.position,
                builderElementRelation.run_on_test,
                conditions,
                actions,
                conditionGroups
            )

            return data.success;
        },

        deleteBuilderElementRelation: async (context, {builderElementRelation}) => {
            const {data} = await serverApi.admin.builderRelations.deleteElementRelation(builderElementRelation.id)

            return data.success;
        }
    }
}