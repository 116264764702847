<template>
  <div class="app-bg-white">
      <toggle-block
          opened
          class="pl-2 pr-8 pt-5"
      >
        <template v-slot:header>
          <h5 class="finish-step-heading pl-4">Dokumente zum Antrag:</h5>
        </template>
        <div class="pb-5 px-3">
          <div
              v-for="(document, index) in documents"
              :key="index"
          >
            <v-row class="mt-5 pl-1">
              <v-col>
                <v-row>
                  <v-col cols="1" class="icon-block"><img width="25" src="/img/file-pdf-solid.svg" alt="Pdf Logo" title="pdf">
                  </v-col>
                  <v-col class="mt-2"><span>{{ document.name }}</span></v-col>
                </v-row>
              </v-col>
              <v-col cols="1" class="text-right pt-2">
                <a :href="document.url" target="_blank">
                  <img width="25" src="/img/download-solid.svg" alt="Download Logo" title="pdf">
                </a>
              </v-col>
            </v-row>
            <hr class="finish-step-hr my-5 ml-1">
          </div>
        </div>
      </toggle-block>
  </div>
</template>

<script>
import ToggleBlock from "@/ui/components/common/blocks/ToggleBlock.vue";
export default {
  components: { ToggleBlock},
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped>
.icon-block {
  max-width: 4%;
}
</style>