export const arrayService = {
    getArrayOfObjectKeys(arr, key) {
        if (!arr) {
            return [];
        }

        return arr.map((obj) => {
            return obj[key]
        })
    },

    findObjectByKey(arr, key) {
        if (!arr) {
            return [];
        }

        return arr.find(el => el.key === key);
    },

    findObjectByLabel(arr, key) {
        if (!arr) {
            return [];
        }

        return arr.find(el => el.label === key);
    },
}