import {InsurancePolice} from "@/models/insurance-police.js"
import {serverApi} from "@/api/server/server-api.js"

export const insurancePoliceModule = {
    namespaced: true,
    state: {
        insurancePolice: InsurancePolice.createEmpty(),
        lists: {},
        data: {},
        selectedCheckboxesInfo: {},
    },

    getters: {
        getInsurancePolice: (state) => state.insurancePolice,
        getLists: (state) => state.insurancePolice.lists,
        getData: (state) => state.insurancePolice.data,
        getSelectedCheckboxesInfo: (state) => state.selectedCheckboxesInfo,
    },

    mutations: {
        setInsurancePolice: (state, data) => {
            state.insurancePolice = new InsurancePolice(data)
        },
        setDataField: (state, {key, val}) => {
            state.insurancePolice.data[key] = val
        },
        setSelectedCheckboxesInfo: (state, data) => {
            state.selectedCheckboxesInfo = data
        },
    },

    actions: {
        getInsurancePoliceByInsuranceId: async (context, {insuranceId}) => {
            const {data} = await serverApi.insurancePolice.insurancePoliceByInsuranceId(insuranceId)

            context.commit('setInsurancePolice', data.insurancePolice)
            context.commit('setSelectedCheckboxesInfo', data.selectedCheckboxesInfo)
        },
        updateInsurancePolice: async (context, {insurancePoliceId, data}) => {
            const response = await serverApi.insurancePolice.update(insurancePoliceId, data)
            context.commit('setInsurancePolice', response.data.insurancePolice)
        },
        sendProposalEmail: async (context, {insurancePoliceId, email}) => {
            const {data} = await serverApi.insurancePolice.sendProposalEmail(insurancePoliceId, email)

            return data
        },
        sendInfoPackEmail: async (context, {insurancePoliceId, email}) => {
            const {data} = await serverApi.insurancePolice.sendInfoPackEmail(insurancePoliceId, email);

            return data
        },
        updateInsurancePoliceFile: async (context, {insurancePoliceId, files, fileKey}) => {
            await serverApi.insurancePolice.uploadFiles(insurancePoliceId, files, fileKey)
        },

        sendElectronicSignatureEmail: async (context, {insurancePoliceId}) => {
            const {data} = await serverApi.insurancePolice.sendElectronicSignatureEmail(insurancePoliceId);

            return data
        },
    },
}