<template>
  <div v-if="loaded">
    <div class="row">
      <div class="col-5 ">
        <select-field
            name="selectDate"
            width="300px"
            :items="selectOptions"
            :value="selectValue"
            @saveField="selectPeriodDate"
        />

        <div class="row pl-0">
          <div class="col-6">
            <span>Von</span>
            <datepicker-field
                width="276px"
                placeholder="TT.MM.JJ"
                name="from"
                :value="from"
                @saveField="onSelectDate"
            />
          </div>
          <div class="col-6 pl-6">
            <span>Bis</span>
            <datepicker-field
                width="276px"
                placeholder="TT.MM.JJ"
                name="to"
                :value="to"
                @saveField="onSelectDate"
            />
          </div>
          </div>
        <div class="col-12">
          <select-field
            name="insuranceType"
            width="300px"
            :items="insuranceTypeOptions"
            itemText="name"
            itemValue="key"
            @saveField="onSelectInsuranceType"
          />
        </div>
      </div>

      <div class="col-12">
        <apexchart
            ref="treemap"
            width="50%"
            type="donut"
            :options="{
                    labels: statusNames,
                    title: {
                        text: 'Alle Vorgänge: ' + moneyService.toMoneyFormat(this.total),
                        style: {
                            fontSize: '21px',
                            fontWeight: 400,
                        }
                    },
                    tooltip: {
                        enabled: true,
                        y:{
                          formatter: function (val) {
                            return moneyService.toMoneyFormat(val)
                          },
                        },
                      }
                }"
            :series="countInsurances"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SelectField from "@/ui/components/common/fields/SelectField.vue";
import DatepickerField from "@/ui/components/common/fields/DatepickerField.vue";
import {dateService} from "@/utils/services/date-service";
import {insuranceStatusEnum} from "@/enums/insurance-status-enum.js";
import {moneyService} from "@/utils/services/money-service.js"

export default {
  components: {DatepickerField, SelectField},
  props: {
    insuranceTypeOptions: {
      type: [],
      required: true,
      default: () => ([]),
    },
  },
  computed: {
    selectOptions() {
      return [
        {text: 'Gesamte Zeit', value: 'all'},
        {text: 'Dieses Jahr', value: 'year'},
        {text: 'Dieser Monat', value: 'month'},
        {text: 'Diese Woche', value: 'week'},
        {text: 'Heute', value: 'day'},
      ]
    },
    preselectDates() {
      return {
        'all': {
          from: '01.01.2023',
          to: dateService.getCurrentDate(),
        },
        'year': {
          from: dateService.getStartOfYearDate(),
          to: dateService.getEndOfYearDate()
        },
        'month': {
          from: dateService.getStartOfMonthDate(),
          to: dateService.getEndOfMonthDate(),
        },
        'week': {
          from: dateService.getStartOfWeekDate(),
          to: dateService.getEndOfWeekDate(),
        },
        'day': {
          from: dateService.getStartOfDayDate(),
          to: dateService.getEndOfDayDate(),
        },
      }
    },
    insuranceStatuses() {
      return [
        insuranceStatusEnum.FINISHED_RISK,
        insuranceStatusEnum.CUSTOMER_DATA,
        insuranceStatusEnum.POLICE_STEP,
        insuranceStatusEnum.INSURANCE_FINISHED
      ]
    },
    moneyService() {
      return moneyService
    }
  },
  data: () => ({
    loaded: false,
    statusNames: [],
    countInsurances: [],
    total: 0,
    selectValue: 'all',
    from: null,
    to: null,
    selectedInsuranceType: '',
  }),
  async created() {
    await this.selectPeriodDate(this.selectOptions[0].value)
    this.loaded = true
  },
  methods: {
    async getData() {
      const {items} = await this.$store.dispatch('admin/statisticModule/statsInsuranceStatuses', {
        insuranceType: this.selectedInsuranceType,
        from: this.from,
        to: this.to,
        statuses: this.insuranceStatuses,
      })

      this.statusNames = []
      this.countInsurances = []
      this.total = 0

      items.forEach(({status, count, sum_prices}) => {
        this.statusNames.push(`${status} - ${count} - ${this.moneyService.toMoneyFormat(sum_prices)}`);
        this.countInsurances.push(sum_prices);

        this.total += sum_prices;
      })
    },
    async selectPeriodDate(value) {
      this.selectValue = value;

      const {from, to} = this.getFromToPreselect(value);

      this.from = from;
      this.to = to;

      await this.getData();
    },
    async onSelectDate(value, key) {
      this[key] = value;

      if (value !== this.preselectDates[this.selectValue][key]) {
        this.selectValue = this.selectOptions[0].value;
      }

      await this.getData();
    },
    getFromToPreselect(key) {
      return this.preselectDates[key];
    },
    async onSelectInsuranceType(val) {
      this.selectedInsuranceType = val

      await this.getData()
    },
  }
}

</script>

<style scoped>
.row {
  background-color: var(--white-bg);
  padding: 20px;
}
</style>

