import {moneyService} from "@/utils/services/money-service.js"
import {dateService} from "@/utils/services/date-service.js"

export default {
    install(Vue) {
        Vue.filter('toMoneyFormat', moneyService.toMoneyFormat)
        Vue.filter('toDateTimeFormatFromISO', dateService.fromISOToFormat)
        Vue.filter('toDateTimeFormatFromSQL', dateService.fromSQLToFormat)
    },
}
