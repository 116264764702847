var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',[_c('v-row',{attrs:{"align":"stretch"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('select-field',{attrs:{"name":"selectDate","width":"300px","items":_vm.selectOptions,"value":_vm.selectValue},on:{"saveField":_vm.selectPeriodDate}})],1),_c('v-col',{staticClass:"relative-col",attrs:{"cols":"3"}},[_c('select-field',{attrs:{"name":"selectDate","width":"300px","placeholder":"Vertriebsweg","items":_vm.userApiRoleOptions,"multiple":true},on:{"saveField":_vm.onChangeApiRoles}})],1),_c('v-col',{staticClass:"relative-col",attrs:{"cols":"3"}},[_c('select-field',{attrs:{"name":"selectDate","width":"300px","placeholder":"Vertriebsdirektion","items":_vm.cityOptions,"multiple":true},on:{"saveField":_vm.onChangeCityOptions}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('span',[_vm._v("Von")]),_c('datepicker-field',{attrs:{"width":"276px","placeholder":"TT.MM.JJ","name":"from","value":_vm.from},on:{"saveField":_vm.onSelectDate}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('span',[_vm._v("Bis")]),_c('datepicker-field',{attrs:{"width":"276px","placeholder":"TT.MM.JJ","name":"to","value":_vm.to},on:{"saveField":_vm.onSelectDate}})],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('select-field',{attrs:{"name":"insuranceType","width":"300px","items":_vm.insuranceTypeOptions,"itemText":"name","itemValue":"key"},on:{"saveField":_vm.onSelectInsuranceType}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('apexchart',{ref:"treemap",attrs:{"width":"50%","type":"donut","options":{
                labels: _vm.statusNames,
                title: {
                    text: 'Alle Vorgänge: ' + this.total,
                    style: {
                        fontSize: '21px',
                        fontWeight: 400,
                    }
                }
            },"series":_vm.countInsurances}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }