<template>
  <div>
    <div class="mt-2 pt-3 app-bg-aqua">
      <app-block class="py-2 mb-10 app-container">
        <progress-bar
            :name="progressBarName"
            :selected-item="progressBarStep"
        />
      </app-block>

      <div class="mt-2 pb-16 pt-2 text-center header">
        <div class="mb-3">
          <h3>Vermögensschaden-Haftpflichtversicherung</h3>
        </div>
        <div class="pb-4">
          <h1>Antragsabschluss</h1>
        </div>
      </div>


    </div>
    <div class="app-container app-bg-light-smoke steps">
      <step1 v-if="isActiveStep1" @clickNext="clickNext"/>
      <step2 v-else-if="isActiveStep2" @clickPrev="clickPrev" @clickNext="clickNext"/>
      <step3 v-else-if="isActiveStep3" @clickPrev="clickPrev" @clickNext="clickNext"/>
      <div v-else>
        <v-btn height="40px" @click="runGeneratePdf('application')" style="margin: 10px auto;">
          Antrag
        </v-btn>

        <v-btn height="40px" @click="runGeneratePdf('explanations')" style="margin: 10px auto;">
          Erklärungen
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import {serverApi} from "@/api/server/server-api.js"
import Step1 from "@/ui/components/insurance/police-tab/steps/Step1.vue"
import Step2 from "@/ui/components/insurance/police-tab/steps/Step2.vue"
import Step3 from "@/ui/components/insurance/police-tab/steps/Step3.vue"
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue"
import ProgressBar from "@/ui/components/insurance/ProgressBar.vue"
import {progressBarNames} from "@/utils/data/progress-bar-names";
import {DATA_FIELD_KEYS} from "@/utils/fields/insurance-police-field-keys";
import {
  ELECTRONIC_SIGNATURE,
  ESTATE_AGENT,
} from "@/utils/fields/insurance-police-step-fields.js"

export default {
  components: {Step1, AppBlock, ProgressBar, Step2, Step3},
  computed: {
    isActiveStep1() {
      return this.activeStep === 1
    },
    isActiveStep2() {
      return this.activeStep === 2
    },
    isActiveStep3() {
      return this.activeStep === 3
    },
    insuranceId() {
      return this.$store.getters["insuranceModule/getInsuranceId"]
    },
    progressBarName() {
      if (this.isActiveStep1) {
        return progressBarNames.APPLICATION
      }

      if (this.isActiveStep2) {
        return progressBarNames.APPLICATION
      }

      if (this.isActiveStep3) {
        return progressBarNames.CONTRACT_CONCLUSION
      }

      return progressBarNames.APPLICATION
    },
    progressBarStep() {
      if (this.isActiveStep1) {
        return 4
      }

      if (this.isActiveStep2) {
        return 4
      }

      if (this.isActiveStep3) {
        return 4
      }

      return 4
    },
    insuranceFinishedAt() {
      return this.$store.getters["insuranceModule/getFinishedAt"]
    },
    paymentOption() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][DATA_FIELD_KEYS.paymentOption]
    },
    isOption2() {
      return this.paymentOption === ELECTRONIC_SIGNATURE
    },
    isEstateAgentOption() {
      return this.paymentOption === ESTATE_AGENT
    },
  },
  data: () => ({
    activeStep: 1,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      await this.getInsurancePolice(this.insuranceId)
    },
    async runGeneratePdf(pdf) {
      console.log(this.$route.params.insuranceId, 7777)
      let fileName = pdf == 'application' ? 'Antrag.pdf' : 'Erklärungen und Hinweise.pdf'
      const {data} = await serverApi.insurance.runGeneratePdf(this.$route.params.insuranceId, pdf)
      let fileURL = window.URL.createObjectURL(new Blob([data]));
      let fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    clickPrev() {
      this.activeStep--
    },
    async clickNext() {
      this.activeStep++

      if (this.activeStep > 3) {
        this.$store.commit('insuranceModule/showPreloader');

        await this.statusFinished();

        if (this.isOption2 || this.isEstateAgentOption) {
          await this.sendBigXml()
          await this.sendXml()
        }

        await this.statusPoliceStep()

        await this.getInsurance(this.insuranceId)

        this.$store.commit('insuranceModule/hidePreloader')
      }
    },
    async getInsurancePolice(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePoliceModule/getInsurancePoliceByInsuranceId', {
        insuranceId,
      })
    },
    async statusFinished() {
      await this.$store.dispatch('insuranceModule/statusInsuranceFinished')
    },

    getInsurance(insuranceId) {
      return this.$store.dispatch('insuranceModule/showInsurance', {
        insuranceId,
      });
    },

    async statusPoliceStep() {
      await this.$store.dispatch('insuranceModule/statusPoliceStep')
    },
    async sendBigXml() {
      await this.$store.dispatch('insuranceModule/sendBigXml')
    },
    sendXml() {
      this.$store.dispatch('insuranceModule/sendXml')
    },
  },
}
</script>
<style scoped>
.app-bg-aqua {
  padding-bottom: 80px;
}
.steps {
  margin-top: -100px;
}
</style>
