<template>
  <div>
    <div class="price-title">Zahlungsweise
      <question-tooltip v-if="tooltip" color="var(--light-blue)">
        <div>
          {{ tooltip }}
        </div>
      </question-tooltip>
    </div>

    <select-field
        :value="value"
        :items="items"
        :is-disabled="isDisabled"
        name="paymentPeriod"
        max-width="278px"
        @saveField="saveField"
    />
  </div>
</template>
<script>
import SelectField from "@/ui/components/common/fields/SelectField.vue";
import QuestionTooltip from "@/ui/components/common/tooltips/QuestionTooltip";
export default {
  components: {SelectField, QuestionTooltip},
  props: {
    items: {
      required: true,
      type: Array,
    },
    value: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  methods: {
    saveField(value) {
      this.$emit('changePaymentPeriod', value)
    },
  },
}
</script>

<style scoped>
.price-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.question-tooltip {
  border-radius: 50%;
  color: var(--light-blue);
  border: 1px solid white;
  background: #fff;
  padding: 2px;
  display: inline-flex;
}
</style>