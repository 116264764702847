import {dateService} from "@/utils/services/date-service.js";
import {durationYearValue} from "@/enums/prise-tab-enum";
export const setBuilderElementDateWithDateParamsAction = async ({passiveElement, activeElement, data, context}) => {
  if (!activeElement.value) {
    return
  }

  const duration = context.rootGetters["insuranceModule/insurancePriceModule/getPriceDataByKey"]('duration');
  //const years = data.dateParams?.years || 0
  const years = durationYearValue[duration] || 0;
  const months = data.dateParams?.months || 0
  const days = data.dateParams?.days || 0

  // At the moment we add period in future we can add sub period param
  let date = dateService.addPeriod(activeElement.value, 'years', years)
  date = dateService.addPeriod(date, 'months', months)
  date = dateService.addPeriod(date, 'days', days)

  passiveElement.value = date

  await context.dispatch('insuranceModule/builderElementsModule/saveElement', {
    builderElement: passiveElement
  }, {root: true});
}