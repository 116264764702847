<template>
  <div>
    <section-element-editor
        v-if="builderElement.type === builderElementEnum.SECTION"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <block-element-editor
        v-else-if="builderElement.type === builderElementEnum.BLOCK"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <row-element-editor
        v-else-if="builderElement.type === builderElementEnum.ROW"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <label-element-editor
        v-else-if="builderElement.type === builderElementEnum.LABEL"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <text-field-element-editor
        v-else-if="builderElement.type === builderElementEnum.TEXT_FIELD"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <number-field-element-editor
        v-else-if="builderElement.type === builderElementEnum.NUMBER_FIELD"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <select-field-element-editor
        v-else-if="builderElement.type === builderElementEnum.SELECT_FIELD"
        :builder-element="builderElement"
        :key="builderElement.id"
    />
    <searchable-select-field-element-editor
        v-else-if="builderElement.type === builderElementEnum.SEARCHABLE_SELECT"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <searchable-min-symbols-to-filter-field-editor
      v-else-if="builderElement.type === builderElementEnum.SEARCHABLE_MIN_SYMBOLS_FILTER"
      :builder-element="builderElement"
      :key="builderElement.id"
    />

    <radio-button-element-editor
        v-else-if="builderElement.type === builderElementEnum.RADIO_BUTTON"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <tooltip-element-editor
        v-else-if="builderElement.type === builderElementEnum.TOOLTIP"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <checkbox-element-editor
        v-else-if="builderElement.type === builderElementEnum.CHECKBOX"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <button-selector-element-editor
        v-else-if="builderElement.type === builderElementEnum.BUTTON_SELECTOR"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <duplicate-block-editor
        v-else-if="builderElement.type === builderElementEnum.DUPLICATE_BLOCK"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <text-area-field-element-editor
        v-else-if="builderElement.type === builderElementEnum.TEXT_AREA_FIELD"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <file-field-element-editor
        v-else-if="builderElement.type === builderElementEnum.FILE_FIELD"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <money-field-element-editor
        v-else-if="builderElement.type === builderElementEnum.MONEY_FIELD"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <tooltip-block-element-editor
        v-else-if="builderElement.type === builderElementEnum.TOOLTIP_BLOCK"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <date-field-editor
        v-else-if="builderElement.type === builderElementEnum.DATE_FIELD"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <validation-section-editor
        v-else-if="builderElement.type === builderElementEnum.VALIDATION_SECTION"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <button-element-editor
        v-else-if="builderElement.type === builderElementEnum.BUTTON"
        :builder-element="builderElement"
        :key="builderElement.id"
    />
    <link-button-element-editor
        v-else-if="builderElement.type === builderElementEnum.LINK_BUTTON"
        :builder-element="builderElement"
        :key="builderElement.id"
    />
    <repeated-block-element-editor
        v-else-if="builderElement.type === builderElementEnum.REPEATED_BLOCK"
        :builder-element="builderElement"
        :key="builderElement.id"
    />
    <simple-popup-editor
        v-else-if="builderElement.type === builderElementEnum.SIMPLE_POPUP"
        :builder-element="builderElement"
        :key="builderElement.id"
    />

    <div class="text-center app-text" v-else-if="builderElement.type !== ''">
      BuilderEditor for this element don't exist
    </div>
  </div>
</template>
<script>
import SectionElementEditor from "@/ui/components/builder/use-cases/builder-editor/element-editor/SectionElementEditor.vue";
import {BuilderElement} from "@/models/builder-element.js";
import {builderElementEnum} from "@/enums/builder-element-enum.js";
import BlockElementEditor from "@/ui/components/builder/use-cases/builder-editor/element-editor/BlockElementEditor.vue";
import RowElementEditor from "@/ui/components/builder/use-cases/builder-editor/element-editor/RowElementEditor.vue";
import LabelElementEditor from "@/ui/components/builder/use-cases/builder-editor/element-editor/LabelElementEditor.vue";
import TextFieldElementEditor from "@/ui/components/builder/use-cases/builder-editor/element-editor/TextFieldElementEditor.vue";
import SelectFieldElementEditor
  from "@/ui/components/builder/use-cases/builder-editor/element-editor/SelectFieldElementEditor.vue";
import RadioButtonElementEditor
  from "@/ui/components/builder/use-cases/builder-editor/element-editor/RadioButtonElementEditor.vue";
import TooltipElementEditor from "@/ui/components/builder/use-cases/builder-editor/element-editor/TooltipElementEditor.vue";
import CheckboxElementEditor from "@/ui/components/builder/use-cases/builder-editor/element-editor/CheckboxElementEditor.vue";
import ButtonSelectorElementEditor
  from "@/ui/components/builder/use-cases/builder-editor/element-editor/ButtonSelectorElementEditor.vue";
import DuplicateBlockEditor from "@/ui/components/builder/use-cases/builder-editor/element-editor/DuplicateBlockEditor.vue";
import TextAreaFieldElementEditor
  from "@/ui/components/builder/use-cases/builder-editor/element-editor/TextAreaFieldElementEditor.vue";
import FileFieldElementEditor from "@/ui/components/builder/use-cases/builder-editor/element-editor/FileFieldElementEditor.vue";
import MoneyFieldElementEditor from "@/ui/components/builder/use-cases/builder-editor/element-editor/MoneyFieldElementEditor.vue";
import TooltipBlockElementEditor from "@/ui/components/builder/use-cases/builder-editor/element-editor/TooltipBlockElementEditor.vue";
import DateFieldEditor from "@/ui/components/builder/use-cases/builder-editor/element-editor/DateFieldEditor.vue";
import NumberFieldElementEditor from "@/ui/components/builder/use-cases/builder-editor/element-editor/NumberFieldElementEditor.vue";
import ValidationSectionEditor from "@/ui/components/builder/use-cases/builder-editor/element-editor/ValidationSectionEditor.vue";
import ButtonElementEditor from "@/ui/components/builder/use-cases/builder-editor/element-editor/ButtonElementEditor.vue";
import LinkButtonElementEditor from "@/ui/components/builder/use-cases/builder-editor/element-editor/LinkButtonElementEditor.vue";
import RepeatedBlockElementEditor
  from "@/ui/components/builder/use-cases/builder-editor/element-editor/RepeatedBlockElementEditor";
import SearchableSelectFieldElementEditor
  from "@/ui/components/builder/use-cases/builder-editor/element-editor/SearchableSelectFieldElementEditor";
import SimplePopupEditor from "@/ui/components/builder/use-cases/builder-editor/element-editor/SimplePopupEditor";
import SearchableMinSymbolsToFilterFieldEditor
  from "@/ui/components/builder/use-cases/builder-editor/element-editor/SearchableMinSymbolsToFilterFieldEditor.vue";


export default {
  components: {
    SimplePopupEditor,
    SearchableSelectFieldElementEditor,
    RepeatedBlockElementEditor,
    NumberFieldElementEditor,
    DateFieldEditor,
    TooltipBlockElementEditor,
    MoneyFieldElementEditor,
    FileFieldElementEditor,
    TextAreaFieldElementEditor,
    DuplicateBlockEditor,
    ButtonSelectorElementEditor,
    CheckboxElementEditor,
    TooltipElementEditor,
    RadioButtonElementEditor,
    SelectFieldElementEditor,
    TextFieldElementEditor,
    LabelElementEditor,
    RowElementEditor,
    BlockElementEditor,
    SectionElementEditor,
    ValidationSectionEditor,
    ButtonElementEditor,
    LinkButtonElementEditor,
    SearchableMinSymbolsToFilterFieldEditor,
  },
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  },
  computed: {
    builderElementEnum() {
      return builderElementEnum
    }
  },
}
</script>
