<template>
  <app-block
    class="pl-12 py-8 app-container"
  >
    <v-row>
      <v-col cols="4">
        <div class="price-block__text price-tab-result-left-side">
          <div>
            <h3 v-html="header"></h3>
          </div>
          <div v-if="showDiscountBlock" class="price-tab-result-left-side-bottom">
            <div class="col-8 ">
              <label class="risk-percents-label">Risikobonus</label>
              <select-field
                  :items="riskPercents"
                  :value="discount"
                  @input="onChangeDiscount"
                  name="discount"
                  :is-disabled="isDisableAllInputs"
              />
              <label class="risk-percents-label">Zuschlag</label>
              <select-field
                  :items="surchargePercents"
                  :value="surcharge"
                  @input="onChangeSurcharge"
                  name="surcharge"
                  :is-disabled="isDisableAllInputs"
              />
            </div>
          </div>
        </div>

      </v-col>
      <v-col class="app-default-border">
        <company-closure-price-items-list
          v-if="isCompanyClosure"
          :price-items="priceItems"
        />
        <insurance-price-items-list
            v-else
            :price-items="priceItems"
        />

        <insurance-price-result
            :total-price="totalPrice"
            :tax-price="taxPrice"
            :final-price="finalPrice"
            :period="period"
            :is-other-specific-taxes="isOtherSpecificTaxes"
            @acceptSuggestion="acceptSuggestion"
        />
      </v-col>
    </v-row>
  </app-block>
</template>
<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import InsurancePriceItemsList
  from "@/ui/components/insurance/insurance-blocks/insurance-price/InsurancePriceItemsList.vue";
import InsurancePriceResult from "@/ui/components/insurance/insurance-blocks/insurance-price/InsurancePriceResult.vue";
import {INSURANCE_PRICE_PAYMENT_PERIOD_LABELS} from "@/utils/fields/insurance-price-list-field-names.js";
import SelectField from "@/ui/components/common/fields/SelectField.vue";
import {RADIO_BUTTON_58} from "@/enums/fields/bankruptcy-risk/field-names";
import {builderElementService} from "@/utils/services/builder-element-service";
import {insuranceTypeEnum} from "@/enums/insurance-type-enum.js"
import CompanyClosurePriceItemsList
  from "@/ui/components/insurance/insurance-blocks/insurance-price/CompanyClosurePriceItemsList.vue";

export default {
  components: {InsurancePriceResult, InsurancePriceItemsList, AppBlock, SelectField, CompanyClosurePriceItemsList},
  props: {
    header: {
      type: String,
      default: 'Ihr ausgewähltes <br> Versicherungspaket',
    },
    priceItems: {
      type: [Array, Object],
      required: true,
    },
    totalPrice: {
      type: Number,
      required: true,
    },
    period: {
      type: String,
      default: INSURANCE_PRICE_PAYMENT_PERIOD_LABELS.year,
      required: true,
    },
    taxPrice: {
      type: Number,
      required: true,
    },
    finalPrice: {
      type: Number,
      required: true,
    },
    discount: {
      type: Number,
      required: false,
      default: 0,
    },
    isDisableAllInputs: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOtherSpecificTaxes: {
      type: Boolean,
      required: false,
      default: false,
    },
    surcharge: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    riskPercents() {
      return [
        {
          text: '0%',
          value: 0,
        },
        {
          text: '5%',
          value: 0.05,
        },
        {
          text: '10%',
          value: 0.1,
        },
      ]
    },
    showDiscountBlock() {
      return !this.isSelectedRadioButton58 && !this.isEstateAgentAzam ;
    },
    isSelectedRadioButton58() {
      const [radioButton58] = builderElementService.findAllElementsByParameters(this.allBuilderElements, 'name', [RADIO_BUTTON_58]);

      return radioButton58?.value === 'Ja' || false;
    },
    isEstateAgentAzam() {
      return this.$store.getters.authUser.isEstateAgentAZAM();
    },
    allBuilderElements() {
      return this.$store.getters["insuranceModule/builderElementsModule/getAllBuilderElements"];
    },
    surchargePercents() {
      return [
        {
          text: '0%',
          value: 0,
        },
        {
          text: '5%',
          value: 0.05,
        },
        {
          text: '10%',
          value: 0.1,
        },
        {
          text: '15%',
          value: 0.15,
        },
        {
          text: '20%',
          value: 0.2,
        },
      ]
    },
    insuranceType() {
      return this.$store.getters['insuranceModule/getInsuranceType'];
    },
    isCompanyClosure() {
      return this.insuranceType.key === insuranceTypeEnum.COMPANY_CLOSURE
    },
  },
  methods: {
    acceptSuggestion() {
      this.$emit('acceptSuggestion')
    },
    onChangeDiscount(value) {
      this.$emit('changedDiscount', value)
    },
    onChangeSurcharge(value) {
      this.$emit('changedSurcharge', value)
    },
  }
}
</script>
<style scoped>
.price-block__text {
  height: 100%;
  position: relative;
  line-height: 1.5rem;
}

.price-tab-result-left-side-bottom {
  min-height: 226px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.risk-percents-container {
  margin-top: 10px;
}

.risk-percents-label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}
.price-tab-result-left-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>