<template>
<div>
  <div class="mt-2 pt-2 app-bg-aqua">
    <div class="mt-2 pb-2 pt-2 text-center header">
      <div class="mb-3">
        <h3>Vermögensschaden-Haftpflichtversicherung</h3>
      </div>
      <div class="pb-4">
        <h1>Antragsabschluss</h1>
      </div>
      <img class="bg-img" src="/img/man_sitting_on_vault_holding_money.png" alt="Allianz logo"/>
    </div>
  </div>

  <app-preloader v-if="loading"/>

  <div v-else class="app-container mt-16 app-bg-white pt-5 pb-10 px-5">
    <h3>Antragsabschluss</h3>
    <br>
    <div v-if="isTokenValid && this.isActivatedFirstTime">
      <p>
        Vielen Dank! Ihr Versicherungsantrag ist bei uns eingegangen und wird nun bearbeitet. Wir werden Ihnen in
        Kürze Ihre Versicherungsunterlagen zukommen lassen. Die Bearbeitung kann einige Tage in Anspruch nehmen.
        Wir danken Ihnen für Ihre Geduld.
      </p>
      <br>
      <p>Viele Grüße</p>
      <p>Ihre Allianz</p>
    </div>
    <div v-else>
      <p>
        Der Antrag wurde bereits erfolgreich gestellt. Wir bitten Sie um Geduld, da die Prüfung des Antrags einige Tage Zeit in Anspruch nehmen kann.
      </p>
      <br>
      <p>Viele Grüße</p>
      <p>Ihre Allianz</p>
    </div>
  </div>
</div>
</template>

<script>
import {serverApi} from "@/api/server/server-api.js"
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue"
export default {
  components: {AppPreloader},
  data: () => ({
    token: '',
    isTokenValid: false,
    isActivatedFirstTime: true,
    loading: true,
  }),
  async created() {
    this.initToken()
    await this.checkToken()
    await this.activateToken()
    await this.confirmInsurance()

    if (this.isActivatedFirstTime) {
      await this.sendEmailToManager()
      await this.sendEmailToCustomer()
    }

    this.loading = false
  },
  methods: {
    initToken() {
      this.token = this.$route.query.token;
    },
    async checkToken() {
      if (!this.token) {
        return
      }

      try {
        this.isTokenValid = await this.checkExpiringDate(this.token)
      } catch (err) {
        console.error(err)
      }
    },
    async activateToken() {
      if (!this.token) {
        return
      }

      if (this.isTokenValid) {
        await serverApi.expiringToken.activate(this.token)
      }
    },
    async sendEmailToManager() {
      const insuranceId = this.$route.query.id;

      if (insuranceId && this.isTokenValid) {
        await serverApi.insurance.sendEmailToManager(insuranceId, this.token);
      }
    },
    async confirmInsurance() {
      const insuranceId = this.$route.query.id;

      if (insuranceId && this.isTokenValid) {
        const result = await serverApi.insurance.setCustomerConfirmed(insuranceId, this.token);

        this.isActivatedFirstTime = result.data.needNotify;
      } else {
        this.isActivatedFirstTime = false;
      }
    },
    async checkExpiringDate(token) {
      return await this.$store.dispatch('insuranceModule/expiringTokenModule/checkExpiringDate', {
        token,
      })
    },
    async sendEmailToCustomer() {
      const insuranceId = this.$route.query.id;

      if (insuranceId && this.isTokenValid) {
        await serverApi.insurance.sendEmailToCustomer(insuranceId, this.token);
      }
    },
    sendBigXml() {
      this.$store.dispatch('insuranceModule/sendBigXml', this.$route.query.id)
    },
    sendXml() {
      this.$store.dispatch('insuranceModule/sendXml', this.$route.query.id)
    },
  },
}
</script>

<style scoped>
.bg-img {
  max-height: 195px;
  margin-bottom: -20px;
}
</style>
