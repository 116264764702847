<template>
  <div class="app-container app-bg-light-smoke steps pb-10">
    <div class="app-bg-white py-5 px-5">
      <div class="app-bg-white">
        <div class="mr-5 pb-5 px-5">
          <h5 class="finish-step-heading mt-2 mb-5 pl-2 pt-5">Status:</h5>
          <v-row>
            <v-col class="mt-2">
              <v-row>
                <v-col class="icon-block">
                  <square-check-icon color="var(--light-blue)"/>
                </v-col>
                <v-col cols="7" class="mt-2">
                    Die Anfrage wurde an Underwriting übergeben.
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="date" class="mt-2 text-right" cols="1">
              {{ date }} Uhr
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SquareCheckIcon from "@/ui/components/common/icons/SquareCheckIcon.vue"
import {dateService} from "@/utils/services/date-service.js"

export default {
  components: {
    SquareCheckIcon,
  },
  computed: {
    insurancePolice() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getInsurancePolice"]
    },
    insurance() {
      return this.$store.getters["insuranceModule/getInsurance"]
    },
    date() {
      if (this.insurance.finished_at) {
        return dateService.fromSQLToFormat(this.insurance.finished_at)
      }

      return dateService.getCurrentDateTime()
    },
  },
}
</script>

<style scoped>
.icon-block {
  max-width: 4%;
}
</style>