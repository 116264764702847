<template>
  <div :style="{padding, margin, width}">
    <number-field
        class="pr-1"
        :name="name"
        :width="width"
        :value="value"
        :validation="validation"
        :placeholder="placeholder"
        :is-integer="isInteger"
        :is-hide-spin-buttons="isHideSpinButtons"
        :disabled="disabledBuilderElement"
        :is-repeat-generator="isRepeatGenerator"
        @saveField="saveField"
    />
  </div>
</template>
<script>
import DefaultElement from "./mixins/DefaultElement.js";
import Padding from "./mixins/Padding.js";
import Placeholder from "./mixins/Placeholder.js";
import Size from "./mixins/Size.js";
import IsInteger from "./mixins/IsInteger.js";
import IsHideSpinButtons from "./mixins/IsHideSpinButtons.js";
import {builderElementsEvents} from "@/utils/events/builder-elements-events.js";
import NumberField from "@/ui/components/common/fields/NumberField.vue";
import Validation from "@/ui/components/builder/elements/mixins/Validation.js";
import IsRepeatGenerator from "./mixins/IsRepeatGenerator.js";
import {debounce} from "@/utils/functions.js";

export default {
  components: {NumberField},
  mixins: [DefaultElement, Padding, Placeholder, Size, Validation, IsInteger, IsRepeatGenerator, IsHideSpinButtons],
  props: {
    disabledBuilderElement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isSaved: false,
  }),
  beforeDestroy() {
    //When field has static value >= 1 and this value did not update we need run repeat block emit
    if (!this.isSaved && this.isRepeatGenerator && this.builderElement.value === '1') {
      builderElementsEvents.repeatByElement.emit(this.builderElement);
    }
  },
  methods: {
    saveField(value) {
      this.builderElement.value = value;

      builderElementsEvents.updateValue.emit(this.builderElement);

      if (this.builderElement.data.active_element_is_repeat) {
        debounce(
            async () => {
              builderElementsEvents.repeatByElement.emit(this.builderElement)
            },
            'repeat-by-element',
            1000,
        )
      }

      this.isSaved = true
    }
  }
}
</script>