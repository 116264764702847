<template>
  <v-data-table
      :headers="headers"
      :items="insuranceBuilders"
      class="elevation-10"
      loading-text="Loading... Please wait"
      :loading="loading"
  >
    <template v-slot:item.name="{item}">
      <div v-if="inEditModeId !== item.id"> {{ item.name }}</div>

      <text-field
          v-else
          class="mt-4"
          name="name"
          v-model="item.name"
      />
    </template>

    <template v-slot:item.step_type="{item}">
      <div v-if="inEditModeId !== item.id"> {{ item.step_type }}</div>

      <select-field
          v-else
          class="mt-4"
          name="step_type"
          :items="insuranceSteps"
          v-model="item.step_type"
      />
    </template>

    <template v-slot:item.priority="{item}">
      <div v-if="inEditModeId !== item.id"> {{ item.priority }}</div>

      <number-field
          v-else
          class="mt-4"
          name="priority"
          v-model="item.priority"
      />
    </template>

    <template v-slot:item.operation="{item}">
      <div v-if="inEditModeId !== item.id">
        <show-icon/>

        <show-query-icon size="24" @click="sqlClick(item)"/>

        <edit-icon
            size="24"
            color="var(--light-blue)"
            @click="editClick(item)"
        />

        <delete-icon @click="deleteClick(item)"/>


      </div>

      <div class="float-right d-flex" v-else>
        <orange-btn @click="builderEditorClick(item)" height="28px" class="mr-4 mt-1"> Builder Editor</orange-btn>
        <check-icon @click="updateClick(item)"/>
      </div>

    </template>

  </v-data-table>
</template>
<script>
import DeleteIcon from "@/ui/components/common/icons/DeleteIcon.vue";
import ShowIcon from "@/ui/components/common/icons/ShowIcon.vue";
import EditIcon from "@/ui/components/common/icons/EditIcon.vue";
import TextField from "@/ui/components/common/fields/TextField.vue";
import CheckIcon from "@/ui/components/common/icons/CheckIcon.vue";
import NumberField from "@/ui/components/common/fields/NumberField.vue";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import SelectField from "@/ui/components/common/fields/SelectField.vue";
import {insuranceStepService} from "@/utils/services/insurance-step-service.js";
import ShowQueryIcon from "@/ui/components/common/icons/ShowQueryIcon.vue";

export default {
  components: {ShowQueryIcon, SelectField, OrangeBtn, NumberField, CheckIcon, TextField, EditIcon, ShowIcon, DeleteIcon},
  props: {
    insuranceBuilders: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    insuranceSteps() {
        return insuranceStepService.typesToArray();
    },

    headers() {
      return [
        {
          text: 'ID',
          value: 'id',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: "Priority",
          value: 'priority',
        },
        {
          text: "Step Type",
          value: 'step_type',
        },
        {
          text: "Operations",
          align: 'right',
          value: "operation"
        }
      ]
    },
  },
  data: () => ({
    inEditModeId: null,
  }),
  methods: {
    editClick(insuranceBuilder) {
      this.inEditModeId = insuranceBuilder.id;
    },

    updateClick(insuranceBuilder) {
      this.$emit('updateClick', insuranceBuilder)

      this.inEditModeId = null;
    },

    builderEditorClick(insuranceBuilder) {
      this.$emit('builderEditorClick', insuranceBuilder);
    },

    deleteClick(insuranceBuilder) {
      this.$emit('deleteClick', insuranceBuilder)
    },

    sqlClick(insuranceBuilder) {
      this.$emit('sqlClick', insuranceBuilder)
    }
  }
}
</script>