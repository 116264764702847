<template>
  <div :class="cssClasses" :style="{width, padding, margin}">
    <div>
      <exclamation-tooltip :size="size + 'px'" v-if="isExclamationTooltip" :isHidePopup="isHidePopup">
        <div v-html="html"></div>
      </exclamation-tooltip>
      <question-tooltip :size="size + 'px'" v-else :isHidePopup="isHidePopup">
        <div v-html="html"></div>
      </question-tooltip>
    </div>

    <div class="pl-4 app-text__bold" v-html="builderElement.value"></div>
  </div>
</template>
<script>
import ExclamationTooltip from "@/ui/components/common/tooltips/ExclamationTooltip.vue";
import DefaultElement from "@/ui/components/builder/elements/mixins/DefaultElement.js";
import Padding from "@/ui/components/builder/elements/mixins/Padding.js";
import Size from "@/ui/components/builder/elements/mixins/Size.js";
import QuestionTooltip from "@/ui/components/common/tooltips/QuestionTooltip.vue";
import CssClass from "@/ui/components/builder/elements/mixins/CssClass.js";
import IsExclamationTooltip from "@/ui/components/builder/elements/mixins/IsExclamationTooltip.js";
import IsHidePopup from "@/ui/components/builder/elements/mixins/IsHidePopup";

export default {
  components: {QuestionTooltip, ExclamationTooltip},
  mixins: [DefaultElement, Padding, Size, CssClass, IsExclamationTooltip, IsHidePopup],
  computed: {
    html() {
      return this.builderElement.data?.html || '';
    },

    size() {
      return this.builderElement.data?.size || 16
    },

    cssClasses() {
      return 'd-flex' + ' ' + this.cssClass
    },
  }
}
</script>