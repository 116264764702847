import {builderElementsEvents} from "@/utils/events/builder-elements-events";

export const repeatByDuplicatedItemAction = async ({id, activeElement, passiveElement, context}) => {
    await context.dispatch('insuranceModule/builderElementsModule/repeatByDuplicatedElement', {
        copyFromElement: activeElement,
        copyToElement: passiveElement,
        actionId: id,
    }, {root: true})

    builderElementsEvents.reloadBuilders.emit();
}