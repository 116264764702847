export const routeNames = {

    insuranceList: 'insurance-list',
    insuranceCreate: 'insurance-create',
    insuranceShow: 'insurance-show',

    login: 'login',

    amsLogin: 'ams-login',

    admin: {
        dashboard: 'admin-dashboard',
        insuranceType: 'admin-insurance-type',
        xml: 'xml',
    },

    contractFinishPage: 'contract-finish-page',

    accessRestricted: 'access-restricted',
}
