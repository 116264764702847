<template>
  <div class="py-4">
    <v-row>
      <v-col cols="6" class="d-flex">
        <div>
          <p class="app-text__bold">New File</p>

          <file-field
              name="file"
              width="500px"
              placeholder="File"
              @saveFile="onSelectFile"
          />
        </div>
        <div class="mt-9">
          <orange-btn
              width="140"
              height="42"
              v-if="file"
              @click="onSaveFile"
          >
            Save
          </orange-btn>
        </div>

      </v-col>
      <v-col cols="6">
        <static-files-table
            :loading="loading"
            :files="files"
            @deleteClick="onDeleteFile"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {InsuranceType} from "@/models/insurance-type.js";
import FileField from "@/ui/components/common/fields/FileField.vue";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import StaticFilesTable from "@/ui/components/admin/insurance-type/static-files-section/StaticFilesTable.vue";

export default {
  components: {StaticFilesTable, OrangeBtn, FileField},
  props: {
    insuranceType: {
      type: InsuranceType,
      required: true,
    }
  },
  data: () => ({
    files: [],
    file: null,
    loading: false,
  }),
  async created() {
    this.loading = true;
    this.files = await this.getFiles(this.insuranceType);
    this.loading = false;
  },
  methods: {
    onSelectFile(file) {
      this.file = file;
    },

    async onSaveFile() {
      this.loading = true;
      await this.addFile(this.insuranceType, this.file)
      this.files = await this.getFiles(this.insuranceType)
      this.file = null;
      this.loading = false;
    },

    async onDeleteFile(appFile) {
        this.loading = true;
        await this.deleteFile(this.insuranceType, appFile)
        this.files = await this.getFiles(this.insuranceType)
        this.loading = false;
    },

    getFiles(insuranceType) {
      return this.$store.dispatch('admin/insuranceTypeModule/getFiles', {
        insuranceType,
      })
    },

    addFile(insuranceType, file) {
      return this.$store.dispatch('admin/insuranceTypeModule/createFile', {
        insuranceType,
        file,
      })
    },

    deleteFile(insuranceType, appFile) {
      return this.$store.dispatch('admin/insuranceTypeModule/deleteFile', {
        insuranceType,
        appFile,
      })
    }
  }
}
</script>