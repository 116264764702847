import store from "../../store/app-store.js";
import {routeNames} from "../route-names.js";
import adminModule from '../../store/admin-modules/admin-module.js'

export const isAdminMiddleware = (to, from, next) => {
    if(!store.getters.isUserAuthorized) {
        next({name: routeNames.login});
    }

    if(!store.getters.authUserIsAdmin && !store.getters.authUserIsStatistic){
        next({name: routeNames.insuranceCreate});
    }

    if(!store.hasModule('admin')) {
        store.registerModule('admin', adminModule);
    }

    next();
}