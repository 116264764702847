import {objectService} from "@/utils/services/object-service.js";

export class InsuranceStatus {
    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(InsuranceStatus.empty().toObject())
            : this.fillData(data)
    }

    fillData(data) {
        this.id = data.id;
        this.message = data.message;
        this.name = data.name;
        this.labelDE = data.labelDE;
    }

    static empty(data = {}) {
        return new InsuranceStatus({
            id: data.id || 0,
            message: data.message || '',
            name: data.name || '',
            labelDE: data.labelDE || '',
        })
    }

    toObject() {
        return {
            ...this
        }
    }
}