import {customBlur} from "@/utils/services/directives/custom-blur.js"

export default {
    install(Vue) {
        Vue.directive('custom-blur', {
            inserted: function (el, binding) {
                customBlur(el, binding)
            }
        })
    }
}