export const builderRelationsApi = (http) => ({
    getElementRelations: (buildersId) => http.get(`/api/admin/builder-relations/get`, {
        buildersId
    }),

    saveElementRelation: (builderId, description, isValidation, showValidationOn, position, runOnTest, conditions, actions, conditionGroups) =>
        http.post(`/api/admin/builder-relations/${builderId}/save`, {
            builderId,
            description,
            is_validation: isValidation,
            show_validation_on: showValidationOn,
            position,
            run_on_test: runOnTest,
            conditions,
            actions,
            conditionGroups
        }),

    updateElementRelation: (relationId, builderId, description, isValidation, showValidationOn, position, runOnTest, conditions, actions, conditionGroups) =>
        http.post(`/api/admin/builder-relations/${relationId}/update`, {
            builderId,
            description,
            is_validation: isValidation,
            show_validation_on: showValidationOn,
            position,
            run_on_test: runOnTest,
            actions,
            conditions,
            conditionGroups
        }),

    deleteElementRelation: (relationId) => http.post(`/api/admin/builder-relations/${relationId}/delete`),
})