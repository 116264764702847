
export class EditorLoadFileEvent {
    _name = 'editor-load-file'

    constructor(eventBus) {
        this.eventBus = eventBus
    }

    emit(file) {
        this.eventBus.$emit(this._name, {file})
    }

    on(callback) {
        this.eventBus.$on(this._name, callback);
    }

    off(callback) {
        this.eventBus.$off(this._name, callback);
    }
}