import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store/app-store.js'

import "./plugins/vue-notifications.js"
import './plugins/vuetify-money'

import vuetify from './plugins/vuetify'
import './ui/styles/main.scss'

import {isEnvProduction} from "./plugins/env.js"

Vue.config.productionTip = !isEnvProduction();
// Vue.config.devtools = !isProduction();

import filters from './utils/filters'
Vue.use(filters)

import directives from './utils/directives'
Vue.use(directives)

import './plugins/vue-apex-charts'

store.dispatch('checkUser').finally(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})

