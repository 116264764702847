<template>
  <div :style="{width}">
    <v-autocomplete
      :height="height"
      :items="itemsData"
      :item-text="itemText"
      :menu-props="{ bottom: true, offsetY: true }"
      :item-value="itemValue"
      :rules="validationRules"
      :value="value"
      append-icon="mdi-chevron-down"
      :placeholder="placeholder"
      :filter="customFilter"
      @input="saveField"
      @update:search-input="onTextChange"
      :clearable="clearable"
      outlined
      dense
      :hide-no-data="true"
      @click="onClick"
    />
  </div>
</template>
<script>
import {equalToElementValue, required} from "@/utils/validation-rules.js";

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true,
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
      required: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: 'auto',
    },
    minSymbolsToStartFilter: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    allBuilderElements() {
      return this.$store.getters["insuranceModule/builderElementsModule/getAllBuilderElements"];
    },
    validationRules() {
      const rules = [];

      if (this.validation.required && this.validation.required !== '0') {
        rules.push(required);
      }

      if (this.validation.equalTo && this.validation.equalTo.elementId) {
        rules.push(
            (val, message = this.validation.equalTo.message || 'Error') =>
                equalToElementValue(val, this.validation.equalTo.elementId, this.allBuilderElements, message)
        );
      }

      return rules
    },
  },
  watch: {
    items: {
      handler(items){
        if (this.minSymbolsToStartFilter > 0 && !this.value) {
          this.itemsData = []
        } else {
          this.itemsData = items
        }
      },
      deep: true,
      immediate: true
    }
  },
  data: () => ({
    itemsData: [],
    isFillItems: true,
  }),
  methods: {
    saveField(value) {
      this.$emit('saveField', value, this.name);
      this.$emit('input', value);
    },

    onTextChange(value) {
      if (value && value.length >= this.minSymbolsToStartFilter) {
        this.itemsData = this.items
      } else {
        this.itemsData = []
      }

      this.$emit('textChange', value);
    },
    customFilter(item, queryText, itemText) {
      if (queryText.length < this.minSymbolsToStartFilter) {
        return false
      }

      return itemText.toString()
        .toLowerCase()
        .indexOf(queryText.toString().toLowerCase()) > -1
    },
    onClick() {
      if (this.minSymbolsToStartFilter <= 0) {
        this.itemsData = this.items
      }
    },
  }
}
</script>
