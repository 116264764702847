<template>
    <div v-if="loaded">
      <v-row align="stretch">
        <v-col cols="3">
          <select-field
            name="selectDate"
            width="300px"
            :items="selectOptions"
            :value="selectValue"
            @saveField="selectPeriodDate"
          />
        </v-col>

        <v-col cols="3" class="relative-col">
          <select-field
            name="selectDate"
            width="300px"
            placeholder="Vertriebsweg"
            :items="userApiRoleOptions"
            :multiple="true"
            @saveField="onChangeApiRoles"
          />
        </v-col>

        <v-col cols="3" class="relative-col">
          <select-field
            name="selectDate"
            width="300px"
            placeholder="Vertriebsdirektion"
            :items="cityOptions"
            :multiple="true"
            @saveField="onChangeCityOptions"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <span>Von</span>
          <datepicker-field
            width="276px"
            placeholder="TT.MM.JJ"
            name="from"
            :value="from"
            @saveField="onSelectDate"
          />
        </v-col>

        <v-col cols="3">
          <span>Bis</span>
          <datepicker-field
            width="276px"
            placeholder="TT.MM.JJ"
            name="to"
            :value="to"
            @saveField="onSelectDate"
          />
        </v-col>

        <v-col class="mt-5" cols="12">
          <select-field
            name="insuranceType"
            width="300px"
            :items="insuranceTypeOptions"
            itemText="name"
            itemValue="key"
            @saveField="onSelectInsuranceType"
          />
        </v-col>

        <v-col cols="12">
          <apexchart
            ref="treemap"
            width="50%"
            type="donut"
            :options="{
                    labels: statusNames,
                    title: {
                        text: 'Alle Vorgänge: ' + this.total,
                        style: {
                            fontSize: '21px',
                            fontWeight: 400,
                        }
                    }
                }"
            :series="countInsurances"
          />
        </v-col>
      </v-row>
    </div>
</template>

<script>
import SelectField from "@/ui/components/common/fields/SelectField.vue";
import DatepickerField from "@/ui/components/common/fields/DatepickerField.vue";
import {dateService} from "@/utils/services/date-service";
import {userApiRoleEnum} from "@/enums/user-api-role-enum";
import {cities} from "@/utils/data/manager-number-countries";

export default {
  components: { DatepickerField, SelectField},
  props: {
    insuranceTypeOptions: {
      type: [],
      required: true,
      default: () => ([]),
    },
  },
  computed: {
    selectOptions() {
      return [
        {text: 'Gesamte Zeit', value: 'all'},
        {text: 'Dieses Jahr', value: 'year'},
        {text: 'Dieser Monat', value: 'month'},
        {text: 'Diese Woche', value: 'week'},
        {text: 'Heute', value: 'day'},
      ]
    },
    preselectDates() {
      return {
        'all': {
          from: '01.01.2023',
          to: dateService.getCurrentDate(),
        },
        'year': {
          from: dateService.getStartOfYearDate(),
          to: dateService.getEndOfYearDate()
        },
        'month': {
          from: dateService.getStartOfMonthDate(),
          to: dateService.getEndOfMonthDate(),
        },
        'week': {
          from: dateService.getStartOfWeekDate(),
          to: dateService.getEndOfWeekDate(),
        },
        'day': {
          from: dateService.getStartOfDayDate(),
          to: dateService.getEndOfDayDate(),
        },
      }
    },
    userApiRoleOptions() {
      return [
        {text: userApiRoleEnum.EMPLOYEE, value: userApiRoleEnum.EMPLOYEE},
        {text: userApiRoleEnum.ESTATE_AGENT, value: userApiRoleEnum.ESTATE_AGENT},
        {text: userApiRoleEnum.ESTATE_AGENT_AZAM, value: userApiRoleEnum.ESTATE_AGENT_AZAM},
      ]
    },
    cityOptions() {
      return cities
    },
  },
  data: () => ({
    loaded: false,
    statusNames: [],
    countInsurances: [],
    total: 0,
    selectValue: 'all',
    from: null,
    to: null,
    insuranceTypes: [],
    selectedInsuranceType: '',
    selectedApiRoles: [],
    selectedCities: [],
  }),
  async created() {
    await this.selectPeriodDate(this.selectOptions[0].value)
    this.loaded = true
  },
  methods: {
    async getData() {
      const {items} = await this.$store.dispatch('admin/statisticModule/statsInsuranceStatuses', {
        insuranceType: this.selectedInsuranceType,
        from: this.from,
        to: this.to,
        apiRoles: this.selectedApiRoles,
        cities: this.selectedCities,
      })

      this.statusNames = []
      this.countInsurances = []
      this.total = 0

      items.forEach(({status, count}) => {
        this.statusNames.push(status + ": " + count);
        this.countInsurances.push(count);

        this.total += count;
      })
    },
    async selectPeriodDate(value) {
      this.selectValue = value;

      const {from, to} = this.getFromToPreselect(value);

      this.from = from;
      this.to = to;

      await this.getData();
    },
    async onSelectDate(value, key) {
      this[key] = value;

      if (value !== this.preselectDates[this.selectValue][key]) {
        this.selectValue = this.selectOptions[0].value;
      }

      await this.getData();
    },
    getFromToPreselect(key) {
      return this.preselectDates[key];
    },
    async onSelectInsuranceType(val) {
      this.selectedInsuranceType = val

      await this.getData()
    },
    onChangeApiRoles(values) {
      this.selectedApiRoles = values

      this.getData()
    },
    onChangeCityOptions(values) {
      this.selectedCities = values

      this.getData()
    }
  }
}

</script>

<style scoped>
.row {
  background-color: var(--white-bg);
  padding: 20px;
}
</style>

