<template>
  <v-data-table
      :headers="headers"
      :items="insuranceElements"
      class="elevation-10"
      loading-text="Loading... Please wait"
      :items-per-page="8"
      :loading="loading"
  >
    <template v-slot:item.is_show="{item}">
      {{ visibilityLabel(item) }}
    </template>

    <template v-slot:item.operation="{item}">
      <div class="d-flex justify-lg-end">

        <div>
          <edit-icon @click="editClick(item)"/>
        </div>

        <div>
          <delete-icon @click="deleteClick(item)"/>
        </div>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import EditIcon from "@/ui/components/common/icons/EditIcon.vue";
import DeleteIcon from "@/ui/components/common/icons/DeleteIcon.vue";

export default {
  components: { DeleteIcon, EditIcon},
  props: {
    insuranceElements: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: "Priority",
          value: 'priority',
        },
        {
          text: "Visibility",
          value: 'is_show',
        },
        {
          text: "Operations",
          align: 'right',
          value: "operation"
        }
      ]
    }
  },
  methods: {
    visibilityLabel(insuranceElement) {
      return insuranceElement.isVisible() ? 'Visible' : 'Not Visible';
    },

    editClick(insuranceElement) {
      this.$emit('editClick', insuranceElement)
    },

    deleteClick(insuranceElement) {
      this.$emit('deleteClick', insuranceElement)
    },
  }
}
</script>