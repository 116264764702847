<template>
  <div >
  <insurance-price-packages-wrapper :color="selectedColor">
    <template v-slot:bestseller>
      <div v-if="isShowUserBestseller" class="app-text__bold" style="height: 30px">Unser Bestseller</div>
    </template>

    <div class="app-text__bold text-center">{{ pack.getName() }}</div>

    <div v-if="!isNpopt" class="app-text__bold text-center font-s-24 pt-2">{{ pack.getPrice() | toMoneyFormat }}</div>

    <div v-if="!isNpopt" class="text-center pt-1">{{ period }}</div>

    <div class="pt-7" >
      <div class="d-flex justify-end">
        <orange-btn v-if="isSelected" width="160px" :disabled="disabled" @click="clickSelected">
          Ausgewählt
        </orange-btn>

        <white-btn v-else width="160px" :disabled="disabled" @click="clickUnselected">
          Auswählen
        </white-btn>
      </div>
    </div>
  </insurance-price-packages-wrapper>
  </div>
</template>
<script>
import InsurancePricePackagesWrapper from "@/ui/components/insurance/insurance-wrappers/InsurancePricePackagesWrapper.vue";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import {InsurancePricePackage} from "@/models/insurance-price-package.js";
import {insurancePricePackagesEnum} from "@/enums/insurance-price-packages-enum.js";

export default {
  components: {
    InsurancePricePackagesWrapper,
    OrangeBtn,
    WhiteBtn,
  },
  props: {
    pack: {
      type: InsurancePricePackage,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    period: {
      type: String,
      default: '',
    },
    isNpopt: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isShowUserBestseller() {
      return insurancePricePackagesEnum.comfortCorporateGovernance === this.pack.getKey()
    },
    selectedColor() {
      return this.isSelected ? 'var(--light-smoke)' : ''
    },
  },
  methods: {
    clickSelected() {
    },
    clickUnselected() {
      this.$emit('selectPackage', this.pack.getKey())
    },
  },
}
</script>

<style scoped>

</style>