import store from "../../store/app-store.js"
import {routeNames} from "../route-names.js";
import {localStorageService} from "@/utils/services/local-storage-service.js";

export const guestMiddleware = (to, from, next) => {
    if (localStorageService.amsAuth.getIsUserAms()) {
        next()
    }
    else if(store.getters.isUserAuthorized) {
        next({name: routeNames.insuranceCreate})
    }

    next();
}