<template>
  <v-icon
      @click="$emit('click')"
      class="stop-sign-icon cursor-pointer rounded-circle"
      :color="activeColor"
      :size="size"
      v-ripple="true"
      v-on:mouseover="setActive"
      v-on:mouseleave="setActive"
  >
    mdi-minus-circle
  </v-icon>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: '24px',
    },
    color: {
      type: String,
      default: 'gray',
    }
  },
  computed: {
    activeColor() {
      if (this.active) {
        return 'red'
      }

      return this.color
    }
  },
  data: () => ({
    active: false,
  }),
  methods: {
    setActive() {
      this.active = !this.active
    }
  },
}
</script>