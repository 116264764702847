<template>
  <div class="app-container">
    <div class="text-center">
      <slot name="header"/>
    </div>

    <div>

      <slot/>

      <div class="my-4 d-flex justify-lg-space-between">
        <div>
          <prev-step-btn
              @click="prevButtonClick"
              :text="prevButtonText"
              v-if="showPrevButton"
          />
        </div>

        <div>
          <next-step-btn
              @click="nextButtonClick"
              v-if="showNextButton"
              :text="nextButtonText"
              :width="nextButtonWidth"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NextStepBtn from "@/ui/components/insurance/insurance-buttons/NextStepBtn.vue";
import PrevStepBtn from "@/ui/components/insurance/insurance-buttons/PrevStepBtn.vue";

export default {
  components: {PrevStepBtn, NextStepBtn},
  props: {
    showNextButton: {
      type: Boolean,
      default: true,
    },
    showPrevButton: {
      type: Boolean,
      default: true,
    },
    prevButtonText: {
      type: String,
      default: 'Zurück'
    },
    nextButtonText: {
      type: String,
      default: 'Speichern und weiter',
    },
    nextButtonWidth: {
      type: String,
      default: '250px',
    },
  },
  methods: {
    nextButtonClick() {
      this.addNextClick();

      this.$emit('nextButtonClick');
    },

    prevButtonClick() {
      this.addPrevClick()

      this.$emit('prevButtonClick');
    },

    addNextClick() {
      this.$store.commit('insuranceModule/insuranceNavigationModule/addNextClick');
    },

    addPrevClick() {
      this.$store.commit('insuranceModule/insuranceNavigationModule/addPrevClick');
    }
  }
}
</script>