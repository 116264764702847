<template>
  <div>
    <v-data-table
        dense
        :headers="headers"
        :items="insurancePriceAddonRelations"
        class="elevation-1 app-default-border"
    >

      <template v-slot:item.operation="{item}">
        <div class="d-flex">

          <edit-icon v-if="selectedAddon.id !== item.id" @click="onClickEdit(item)"/>

          <cancel-icon v-else @click="onClickCancel"/>

          <delete-icon @click="onClickDelete(item)"/>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import EditIcon from "@/ui/components/common/icons/EditIcon.vue";
import DeleteIcon from "@/ui/components/common/icons/DeleteIcon.vue";
import CancelIcon from "@/ui/components/common/icons/CancelIcon.vue";
import {InsurancePriceAaddonRelation} from "@/models/insurance-price-addon-relations/insurance-price-addon-relation.js";

export default {
  components: {CancelIcon, DeleteIcon, EditIcon},
  props: {
    insurancePriceAddonRelations: {
      type: Array,
      required: true,
    },
    selectedAddon: {
      type: InsurancePriceAaddonRelation,
      required: true,
    }
  },
  computed: {
    headers() {
      return [
        {text: 'Id', value: 'id'},
        {text: 'Description', value: 'description'},
        {text: 'Operation', value: 'operation'}
      ]
    },
  },
  methods: {
    onClickEdit(element) {
      this.$emit('clickEdit', element)
    },

    onClickCancel() {
      this.$emit('clickEdit', InsurancePriceAaddonRelation.empty())
    },

    onClickDelete(element) {
      this.$emit('clickDelete', element)
    },
  }
}
</script>