import {serverApi} from "@/api/server/server-api.js";
import {InsuranceElementRelation} from "@/models/insurance-element-relations/insurance-element-relation.js";

export const insuranceElementRelationsModule = {
    namespaced: true,
    actions: {

        getInsuranceElementRelations: async (context, {insuranceType}) => {
            const {data} = await serverApi
                .admin
                .insuranceElementRelations
                .getInsuranceElementRelations(insuranceType.id);

            return data.insuranceElementRelations
                .map((item) => new InsuranceElementRelation(item));
        },

        createInsuranceElementRelation: async(context, {insuranceType, insuranceElementRelation}) => {
            const {data} = await serverApi
                .admin
                .insuranceElementRelations
                .createInsuranceElementRelation(insuranceType.id, insuranceElementRelation.toObject())

            return data.success;
        },

        updateInsuranceElementRelation: async (context, {insuranceElementRelation}) => {
            const {data} = await serverApi
                .admin
                .insuranceElementRelations
                .updateInsuranceElementRelation(insuranceElementRelation.id, insuranceElementRelation.toObject())

            return data.success;
        },

        deleteInsuranceElementRelation: async(context, {insuranceElementRelation}) => {
            const {data} = await serverApi
                .admin
                .insuranceElementRelations
                .deleteInsuranceElementRelation(insuranceElementRelation.id)

            return data.success;
        },
    }
}