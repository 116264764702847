<template>
  <div class="app-text__bold">
    <checkbox
      text="Text Right"
      :value="value ? '1' : '0'"
      @input="saveField"
    />
  </div>
</template>
<script>
import Checkbox from "@/ui/components/common/fields/Checkbox.vue";
export default {
  components: {Checkbox},
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    saveField(value) {
      this.$emit('saveField', value === '1')
      this.$emit('input', value === '1');
    }
  }
}
</script>