<template>
  <div>
    <v-row class="builder-editor__section">
      <v-col cols="4 pl-8">
        <div>
          <p class="app-text__bold">Key</p>

          <text-field
              name="name"
              v-model="priceAddonData.key"
              :validation="{required: true}"
          />
        </div>
        <div>
          <p class="app-text__bold">Name</p>

          <text-field
              name="name"
              v-model="priceAddonData.name"
              :validation="{required: true}"
          />
        </div>
        <div>
          <p class="app-text__bold">Code VSU</p>

          <text-field
              name="code_vsu"
              v-model="priceAddonData.code_vsu"
              :validation="{required: true}"
          />
        </div>
        <div>
          <p class="app-text__bold">Price</p>

          <text-field
              name="name"
              v-model="priceAddonData.price"
              :validation="{required: true}"
          />
        </div>
        <div>
          <p class="app-text__bold">Is Visible</p>

          <switch-field
              name="is_show"
              v-model="priceAddonData.is_show"
          />
        </div>
        <div>
          <p class="app-text__bold">Is Active</p>

          <switch-field
              name="is_show"
              v-model="priceAddonData.is_active"
          />
        </div>

      </v-col>

      <v-col cols="4" class="px-8 pb-8">
        <div>
          <p class="app-text__bold">Tooltip</p>

          <text-field
              name="code_vsu"
              v-model="priceAddonData.details.tooltip"
          />
        </div>
        <div>
          <p class="app-text__bold">Popup text when selected</p>

          <text-field
              name="popup_text"
              v-model="priceAddonData.details.popupText"
          />
        </div>
        <div>
          <p class="app-text__bold">Popup button text</p>

          <text-field
              name="popup_button_text"
              v-model="priceAddonData.details.popupButtonText"
          />
        </div>
        <p class="app-text__bold">Details</p>
        <div class="addon-details-toolbar">
          <blue-btn @click="onClickAddDetailsItem">Add details item</blue-btn>
        </div>
        <div class="addon-details-item" v-for="(itm, index) in priceAddonData.details.info" :key="index">
          <text-field
              class="price-addon-details-item-text"
              name="name"
              v-model="itm.val"
              :validation="{required: true}"
          />
          <close-icon @click="onClickDeleteDetailsItem(index)"/>
        </div>
      </v-col>
    </v-row>
    <v-row class="builder-editor__section">
      <div class="pl-8">
        <blue-btn @click="onClickSave">
          Save
        </blue-btn>
      </div>
    </v-row>
  </div>
</template>

<script>
import TextField from "@/ui/components/common/fields/TextField";
import SwitchField from "@/ui/components/common/fields/SwitchField";
import {InsurancePriceAddon} from "@/models/insurance-price-addon";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn";
import CloseIcon from "@/ui/components/common/icons/CloseIcon";

export default {
  components: {TextField, SwitchField, BlueBtn, CloseIcon},
  props: {
    priceAddon: {
      type: InsurancePriceAddon,
      required: true,
    }
  },
  data() {
    return {
      priceAddonData: InsurancePriceAddon.empty()
    }
  },
  created() {
    this.importPriceAddons()
  },
  methods: {
    importPriceAddons() {
      this.priceAddonData = new InsurancePriceAddon(JSON.parse(JSON.stringify(this.priceAddon)));
      this.priceAddonData.details.info = this.priceAddon.details.info ?
          this.priceAddon.details.info.map( itm => {
            return {
              val: itm
            }
          }) : [];
      this.priceAddonData.details.tooltip = this.priceAddon.details.tooltip || '';
      this.priceAddonData.details.popupText = this.priceAddon.details.popupText || '';
      this.priceAddonData.details.popupButtonText = this.priceAddon.details.popupButtonText || '';
    },
    onClickSave() {
      this.$emit('save', new InsurancePriceAddon({
        ...this.priceAddonData,
        details: {
          info: this.priceAddonData.details.info.map(itm => itm.val),
          tooltip: this.priceAddonData.details.tooltip,
          popupText: this.priceAddonData.details.popupText,
          popupButtonText: this.priceAddonData.details.popupButtonText,
        }
      }))
    },
    onClickDeleteDetailsItem(index) {
      this.priceAddonData.details.info.splice(index, 1);
    },
    onClickAddDetailsItem() {
      this.priceAddonData.details.info.push({
        val: ''
      })
    }
  }
}
</script>

<style scoped>
.builder-editor__section {
  padding-top: 100px;
  padding-left: 15px;
}
.addon-details-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.addon-details-toolbar {
  margin-bottom: 15px;
  margin-top: 15px;
}
.price-addon-details-item-text {
  width: 90% !important;
}
</style>