<template>
  <div>
    <div class="text-center">
      <p class="app-text__bold font-s-20">{{ header }}</p>
    </div>

    <v-row>
      <v-col cols="6" class="pr-2">
        <p class="app-text__bold">Date to Compare</p>
        <select-field
            name="date_type"
            :items="dateValidationTypes"
            v-model="validation.type"
            @input="onChangeValidationType"
        />
      </v-col>

      <v-col cols="6">
        <div v-if="isValueFieldVisible">
          <p class="app-text__bold">Pick Custom Date</p>
          <datepicker-field
              name="date_value"
              width="100%"
              v-model="validation.value"
          />
        </div>
      </v-col>

      <v-col cols="6" class="pr-2">
        <p class="app-text__bold">Select Period</p>

        <select-field
            name="period"
            :items="datePeriods"
            v-model="validation.period"
        />
      </v-col>

      <v-col cols="6">
        <p class="app-text__bold">Amount</p>

        <number-field
            name="amount"
            v-model="validation.amount"
        />
      </v-col>
      <v-col v-if="editConditionType" cols="6">
        <p class="app-text__bold">Condition type</p>

        <select-field
            name="period"
            :items="dateConditions"
            v-model="validation.condition"
        />
      </v-col>
      <v-col v-if="hasValidationMessage" cols="12" class="pt-2">
        <p class="app-text__bold">Validation Message</p>

        <text-area-field
            name="message"
            v-model="validation.message"
        />

      </v-col>
      <v-col cols="12" class="pt-2">
        <checkbox
            text="use condition"
            :value="validation.useCondition ? '1' : '0'"
            @input="changeUseCondition"
        />

      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  DATE_CONDITION,
  DATE_FIELD_VALIDATION_TYPE,
  DATE_PERIOD,
  DateFieldValidation
} from "@/utils/types/date-field-validation.js";
import DatepickerField from "@/ui/components/common/fields/DatepickerField.vue";
import SelectField from "@/ui/components/common/fields/SelectField.vue";
import NumberField from "@/ui/components/common/fields/NumberField.vue";
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";
import Checkbox from "@/ui/components/common/fields/Checkbox.vue";

export default {
  components: {TextAreaField, NumberField, SelectField, DatepickerField, Checkbox},
  props: {
    validation: {
      type: DateFieldValidation,
      required: true,
    },
    header: {
      type: String,
      required: true,
    },
    hasValidationMessage: {
      type: Boolean,
      required: false,
      default: true,
    },
    editConditionType: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    datePeriods() {
      return Object.values(DATE_PERIOD);
    },

    dateValidationTypes() {
      return Object.values(DATE_FIELD_VALIDATION_TYPE);
    },

    dateConditions() {
      return Object.values(DATE_CONDITION);
    },
  },

  data: () => ({
    isValueFieldVisible: true,
  }),

  mounted() {
    this.onChangeValidationType(this.validation.type)
  },

  methods: {
    onChangeValidationType(value) {
      this.isValueFieldVisible =  DATE_FIELD_VALIDATION_TYPE.CUSTOM === value
    },
    changeUseCondition(value) {
      this.validation.useCondition = value === '1';
    }
  }
}
</script>