import {builderElementService} from "@/utils/services/builder-element-service.js";
import {builderElementRelationFactory} from "@/utils/factories/builder-relation/builder-element-relation-factory.js";
import {BuilderElement} from "@/models/builder-element.js";
import {RUN_ON_ENUM} from "@/enums/builder-element-relations-enum.js";
import {builderElementRelationActionsEnum} from "@/enums/builder-element-relations-enum.js";
import * as lodash from "lodash"
import {builderElementRelationConditionEnum} from "@/enums/builder-element-relations-enum";
import {isEnvTest} from "@/plugins/env";

export const builderElementRelationsModule = {
    namespaced: true,

    state: {
        allBuilderElementRelations: [],
    },

    getters: {
        getAllBuilderElementRelations: (state) => state.allBuilderElementRelations,
    },

    mutations: {
        setBuilderElementRelations: (state, relations) => {
            state.allBuilderElementRelations = relations;
        }
    },

    actions: {
        runRelations: async (context, { definedActions }) => {
            return await context.dispatch('runRelationsByType', {isValidation: false, definedActions})
        },

        runValidationRelations: async (context) => {
            return await context.dispatch('runRelationsByType', {isValidation: true, definedActions: null})
        },

        runRelationsByType: async (context, { isValidation, definedActions }) => {
            const relations = context.getters.getAllBuilderElementRelations.filter(itm => itm.is_validation === (isValidation ? 1 : 0));
            const allBuilderElements = context.rootGetters["insuranceModule/builderElementsModule/getAllBuilderElements"];

            const actionsToSkip = [
                builderElementRelationActionsEnum.SET_STATIC_VALUE,
                builderElementRelationActionsEnum.SUM,
            ];

            let generalConditionResult = true;

            relations.forEach((relation) => {
                let conditionsResult = checkConditions(relation.builderElementConditions, allBuilderElements, context);
                const filteredActions = relation.builderElementActions.filter((action) => !actionsToSkip.includes(action.action))

                if (relation.run_on_test) {
                    const user = context.rootGetters["authUser"]
                    if (!user.is_test_process || !isEnvTest()) {
                        return
                    }
                }

                generalConditionResult &&= Boolean(relation.show_validation_on) === conditionsResult;

                const actionsToRun = definedActions ? filteredActions.filter((itm) => definedActions.includes(itm.action)) : filteredActions;

                runActions(relation, actionsToRun, allBuilderElements, context, conditionsResult);
            })

            return isValidation && relations.length > 0 ? generalConditionResult : null;
        },

        runRelationsByField: async (context, {builderElement}) => {
            const allBuilderElements = context.rootGetters["insuranceModule/builderElementsModule/getAllBuilderElements"];
            //sort relations by given builderElement
            const relations = context.getters.getAllBuilderElementRelations.filter((rel) => {
                //if relation have condition related to given builderElement
                return rel.builderElementConditions.some((condition) => {
                    const resultCondition = condition.passive_element_id === builderElement.id || condition.active_element_id === builderElement.id

                    if (resultCondition) {
                        return true
                    }

                    if (condition.condition !== builderElementRelationConditionEnum.SUM_BY_FIELDS_MORE_THAN) {
                        return false
                    }

                    // trigger on duplicated elem
                    const duplicateBlock = builderElementService.findParentElement(allBuilderElements, builderElement, 'duplicate_block')
                    builderElementService.findElementById(allBuilderElements, condition.passive_element_id)

                    if (!duplicateBlock) {
                        return false
                    }

                    const originalElem = builderElementService.findElementById(allBuilderElements, condition.passive_element_id)

                    if (originalElem && originalElem.name === builderElement.name) {
                        return true
                    }
                })
            })

            relations.forEach((relation) => {
                const conditionsResult = checkConditions(relation.builderElementConditions, allBuilderElements, context);

                runActions(relation, relation.builderElementActions, allBuilderElements, context, conditionsResult, builderElement);
            })
        }

    }
}

const runActions = (relation, actions, allBuilderElements, context, conditionsResult, changedBuilderElement) => {
    const emptyElement = BuilderElement.empty();

    actions.forEach(({id, run_on, action_value, action, active_element_id, passive_element_id, data}) => {
        if(
            (conditionsResult && run_on === RUN_ON_ENUM.TRUE)
            || (!conditionsResult && run_on === RUN_ON_ENUM.FALSE)
        ) {
            const func = builderElementRelationFactory.getAction(action);

            let activeElement = builderElementService.findElementById(
                allBuilderElements,
                active_element_id,
            );

            let passiveElement = builderElementService.findElementById(
                allBuilderElements,
                passive_element_id,
            )

            //to prevent errors because fields can be equal to null
            passiveElement = passiveElement || emptyElement
            activeElement = activeElement || emptyElement

            func({id, relation, action_value, activeElement, passiveElement, context, data, allBuilderElements, changedBuilderElement})
        }
    })
}

const checkConditions = (conditions, allBuilderElements, context) => {
    const groups = lodash.groupBy(conditions, 'group_code');
    const conditionResult = [];

    for (const groupCode in groups) {
        conditionResult.push(checkGroupConditions(groups[groupCode], allBuilderElements, context))
    }

    return conditionResult.some(groupResult => groupResult)
}

const checkGroupConditions = (conditions, allBuilderElements, context) => {
    const emptyElement = BuilderElement.empty();

    return conditions.every(({condition_value, condition, passive_element_id, active_element_id, data}) => {
        const func = builderElementRelationFactory.getCondition(condition);

        let activeElement = builderElementService.findElementById(
            allBuilderElements,
            active_element_id
        );

        let passiveElement = builderElementService.findElementById(
            allBuilderElements,
            passive_element_id
        )

        //to prevent errors because fields can be equal to null
        passiveElement = passiveElement || emptyElement
        activeElement = activeElement || emptyElement

        return func({condition_value, activeElement, passiveElement, context, allBuilderElements, data})
    })
}