<template>
  <div>
    <div class="text-center">
      <h3>Searchable Min Quantity Symbols To Start Filter</h3>
    </div>

    <v-row>
      <v-col cols="12">
        <name-editor
          v-model="builderElement.name"
        />
      </v-col>

      <v-col cols="6">
        <priority-editor
          v-model.number="builderElement.priority"
        />
      </v-col>

      <v-col class="pl-4" cols="6">
        <width-editor
          v-model="builderElement.data.width"
        />
      </v-col>

      <v-col cols="9">
        <p class="app-text__bold">Add Value
          <question-tooltip>To set different key and value use pattern "text:value"</question-tooltip>
        </p>
        <text-field
          name="value"
          v-model="addValue"
        />
      </v-col>

      <v-col>
        <min-symbols-to-start-filter-editor
          v-model="builderElement.data.minSymbolsToStartFilter"
        />
      </v-col>

      <v-col cols="3" class="pt-11 px-4">
        <blue-btn height="30px" @click="addToOptions">Add</blue-btn>
      </v-col>

      <v-col cols="12" class="pl-2" v-for="option in options" :key="option[itemTextKey]">
        <div class="d-flex">
          <div class="app-text">
            {{ option[itemTextKey] }}
          </div>

          <div class="mr-4">
            <close-icon @click="onDeleteOptionClick(option[itemTextKey])"/>
          </div>
        </div>

      </v-col>

      <v-col cols="12">
        <blue-btn
          @click="isShowEditOptions = true"
        >
          Edit Options
        </blue-btn>
        <edit-select-options-popup
          v-if="isShowEditOptions"
          :options="options"
          @updateOptions="updateOptions"
          @close="isShowEditOptions = false"
        />
      </v-col>

      <v-col cols="6">
        <p class="app-text__bold">Default Value</p>

        <select-field
          name="options"
          :items="builderElement.data.options"
          :value="builderElement.value"
          clearable
        />
      </v-col>

      <v-col cols="6" class="pl-2">
        <placeholder-editor
          v-model="builderElement.data.placeholder"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="pl-4">
        <is-required
            v-model="builderElement.validation.required"
        />
      </v-col>
      <v-col cols="8" class="pl-4">
        <validation-message-editor
            v-model="builderElement.validation.message"
        />
      </v-col>
    </v-row>
    <v-col>
      <is-disabled
        v-model="builderElement.data.isDisabled"
      />
    </v-col>
    <margin-editor
      v-model="builderElement.data.margin"
    />
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import TextField from "@/ui/components/common/fields/TextField.vue";
import WidthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/WidthEditor.vue";
import PlaceholderEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PlaceholderEditor.vue";
import MarginEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MarginEditor.vue";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import SelectField from "@/ui/components/common/fields/SelectField.vue";
import QuestionTooltip from "@/ui/components/common/tooltips/QuestionTooltip.vue";
import CloseIcon from "@/ui/components/common/icons/CloseIcon.vue";
import IsDisabled from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/IsDisabled.vue";
import MinSymbolsToStartFilterEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MinSymbolsToStartFilterEditor.vue";
import IsRequired from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/isRequired.vue";
import ValidationMessageEditor
  from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/ValidationMessageEditor.vue";
import EditSelectOptionsPopup from "@/ui/components/builder/popups/EditSelectOptionsPopup.vue";

export default {
  components: {
    ValidationMessageEditor, IsRequired,
    CloseIcon,
    QuestionTooltip,
    SelectField, BlueBtn, MarginEditor, PlaceholderEditor, TextField, WidthEditor, PriorityEditor, NameEditor,
    IsDisabled,
    MinSymbolsToStartFilterEditor,
    EditSelectOptionsPopup,
  },
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    },
  },
  computed: {
    options() {
      return this.builderElement.data?.options || [];
    },

    itemTextKey() {
      return this.builderElement.data?.itemTextKey || 'text';
    },
  },
  data: () => ({
    addValue: '',
    isShowEditOptions: false,
  }),
  created() {
    this.builderElement.data.options = this.builderElement.data?.options || [];
  },
  methods: {
    onDeleteOptionClick(text) {
      this.builderElement.data.options = this.options.filter((item) => item[this.itemTextKey] !== text);
    },

    addToOptions() {
      this.builderElement.data.options = this.builderElement.data.options || [];

      if (this.addValue) {
        const arr = this.addValue.split(':');

        const text = arr[0];
        const value = arr[1] || arr[0];

        this.builderElement.data.options.push({text: text, value: value});
      }

      this.addValue = '';
    },

    updateOptions(options) {
      this.builderElement.data.options = options
    },
  }
}
</script>