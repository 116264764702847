<template>
  <app-block :width="width"  :style="{margin}" class="validation-app-block" color="#FD7E02">
    <div :id="id" class="d-flex">
      <div class="rounded-circle mr-4 app-bg-white icon-validation-block">
        <v-icon
          class=" cursor-pointer"
          color="black"
          :style="{ fontSize: '24px' }"
        >
        mdi-exclamation
      </v-icon>
      </div>
      <div :style="{ fontSize: '16px', fontWeight: '700', color: '#fff' }" v-html="value"></div>
    </div>
  </app-block>
</template>
<script>
import AppBlock from "../../common/blocks/AppBlock.vue";
import Padding from "./mixins/Padding.js"
import ParentElement from "./mixins/ParentElement.js";
import DefaultElement from "./mixins/DefaultElement.js";
import Size from "./mixins/Size.js";
import CanGoNextElement from "./mixins/CanGoNextElement.js";

export default {
  components: {
    AppBlock
  },
  mixins: [DefaultElement, Padding, ParentElement, Size, CanGoNextElement],
  computed: {
    id() {
      if (this.canGoNext) {
        return ''
      }

      return 'validation-message'
    }
  },
}
</script>

<style scoped>
  .validation-app-block {
    padding: 15px;
  }
  .icon-validation-block {
    width: 24px;
    height: 24px;
  }
</style>