<template>
  <div :style="{height, width, padding, margin, background}">
    <div class="d-flex justify-space-between" data-type-builder="duplicate">
      <builder-elements
          class="pt-1"
          :elements="children"
          :builder-element-id="builderElementId"
          :disabled-all-inputs="disabledBuilderElement"
          :admin-edit-mode="adminEditMode"
      />

      <delete-icon v-if="isDuplicate" @click="onClickRemoveDuplicate" class="duplicate-btn" size="40px"/>
      <add-icon v-else-if="isShowAddIcon" @click="onClickDuplicate" :disabled="disabledBuilderElement" class="duplicate-btn" size="40px"/>
    </div>

  </div>
</template>
<script>
import DefaultElement from "./mixins/DefaultElement.js";
import ParentElement from "./mixins/ParentElement.js";
import Size from './mixins/Size.js'
import Padding from "./mixins/Padding.js";
import Background from "@/ui/components/builder/elements/mixins/Background.js";
import AddIcon from "@/ui/components/common/icons/AddIcon.vue";
import {builderElementsEvents} from "@/utils/events/builder-elements-events.js";
import DeleteIcon from "@/ui/components/common/icons/DeleteIcon";
import isHideDuplicateButton from "./mixins/IsHideDuplicateButton.js";

export default {
  components: {DeleteIcon, AddIcon},
  mixins: [DefaultElement, ParentElement, Size, Background, Padding, isHideDuplicateButton],
  props: {
    builderElementId: {
      type: String,
      required: false,
    },
    disabledBuilderElement: {
      type: Boolean,
      required: false,
      default: false,
    },
    adminEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
      isDuplicate() {
        return this.builderElement.data.is_duplicate || false;
      },
      isShowAddIcon() {
        return !this.isDuplicate && !this.isHideDuplicateButton
      },
  },
  methods: {
    onClickDuplicate() {
      builderElementsEvents.clickDuplicate.emit(this.builderElement.id)
    },

    onClickRemoveDuplicate() {
        builderElementsEvents.removeDuplicate.emit(this.builderElement.id)
    }
  }
}
</script>
<style scoped>
.duplicate-btn {
}
</style>