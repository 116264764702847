import {objectService} from "@/utils/services/object-service.js";

const IS_VISIBLE = 1;
const NOT_VISIBLE = 0;

const IS_ACTIVE = 1;
const NOT_ACTIVE = 0;

export class InsurancePriceAddon {
    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(InsurancePriceAddon.empty().toObject())
            : this.fillData(data);
    }

    fillData(data) {
        this.id = data.id
        this.name = data.name;
        this.code_vsu = data.code_vsu;
        this.key = data.key;
        this.price = data.price;
        this.is_show = data.is_show;
        this.is_active = data.is_active;
        this.details = data.details;
        this.price_id = data.price_id;
    }

    static empty(data = {}) {
        return new InsurancePriceAddon({
            id: 0,
            name: data.name || '',
            code_vsu: data.code_vsu || '',
            key: data.key || '',
            price: data.price || 0,
            is_show: data.is_show === IS_VISIBLE ? IS_VISIBLE : NOT_VISIBLE,
            is_active: data.is_active === IS_ACTIVE ? IS_ACTIVE : NOT_ACTIVE,
            details: data.details || {},
            price_id: data.price_id || null,

        })
    }

    toObject() {
        return {
            ...this
        }
    }

    static collection(array) {
        return array.map(item => new InsurancePriceAddon(item));
    }
}