import {BuilderElement} from "@/models/builder-element.js";

export const builderElementService = {
    getBuildersId(elements) {
        const buildersId = [];

        elements.forEach((elem) => {
            if(!buildersId.includes(elem.builder_id)) {
                buildersId.push(elem.builder_id);
            }
        })

        return buildersId;
    },

    findElementById(elements, id) {
        for (const element of elements) {
            if (element.id + "" === id + "") {
                return element
            }

            const childElement = this.findElementById(element.children, id);

            if (childElement) {
                return childElement;
            }
        }

        return null;
    },

    findAllElementsByParameters(elements, paramName, paramValues) {
        let result = [];

        for (const element of elements) {
            if (paramValues.includes(element[paramName] + "")) {
                result.push(element);
            }

            const childElements = this.findAllElementsByParameters(element.children, paramName, paramValues);

            result = result.concat(childElements);
        }

        return result;
    },

    findAllElementsByDataParameters(elements, paramName, paramValues) {
        let result = [];

        for (const element of elements) {
            if (paramValues.includes(element.data[paramName] + "")) {
                result.push(element);
            }

            const childElements = this.findAllElementsByDataParameters(element.children, paramName, paramValues);

            result = result.concat(childElements);
        }

        return result;
    },

    checkOnEmptyBuilder(elements) {
        return elements.filter((elem) => elem.isVisible()).length < 1;
    },

    findParentElement(elements, element, parentType) {
        const parentElement = this.findElementById(elements, element.parent_id)

        if(parentElement) {
            if(parentElement.type === parentType) {
                return parentElement;
            }

            return this.findParentElement(elements, parentElement, parentType)
        }

        return null;
    },

    findOnlyParents(elements) {
        let array = [];

        for (const element of elements) {
            if (element.isParentElement()) {
                array.push(element);
            }

            if (element.children.length) {
                array = [...array, ...this.findOnlyParents(element.children)]
            }
        }

        return array;
    },

    elementsToSingleArray(elements) {
        let array = [];

        for (const element of elements) {
            array.push(element);

            if (element.children.length) {
                array = [...array, ...this.elementsToSingleArray(element.children)]
            }
        }

        return array;
    },

    removeElementById(elements, id) {
        return elements.filter((elem) => {
            if (elem.children.length) {
                elem.children = this.removeElementById(elem.children, id);
            }

            return elem.id + "" !== id + ""
        });
    },

    sortByPriority(elements) {
        elements.forEach((elem) => {
           if(elem.children.length) {
               elem.children = this.sortByPriority(elem.children);
           }
        });

        return elements.sort((a, b) => a.priority - b.priority);
    },

    addElement(elements, element) {
        const parent = this.findElementById(elements, element.parent_id)

        if(!elements.length || !parent) {
            elements.push(element);

            return elements;
        }

        parent.children.push(element);

        return elements;
    },

    generateName() {
        return Math.random() + '';
    },

    copyElement(element) {
        return new BuilderElement(
            JSON.parse(
                JSON.stringify(element.toObject())
            )
        );
    },

    elementsToBuilderElements(elements) {
        return elements.map((elem) => {
            if(elem.children.length) {
                elem.children = this.elementsToBuilderElements(elem.children);
            }

            return new BuilderElement(elem)
        })
    }
}