import {objectService} from "@/utils/services/object-service.js";

export class BuilderElementRelationCondition {

    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(BuilderElementRelationCondition.empty().toObject())
            : this.fillData(data);
    }

    fillData(data) {
        this.id = data.id;
        this.relation_id = data.relation_id;
        this.active_element_id = data.active_element_id;
        this.passive_element_id = data.passive_element_id;
        this.condition = data.condition;
        this.condition_value = data.condition_value;
        this.group_code = data.group_code ? data.group_code : null;
        this.data = data.data ? data.data : null;
    }

    static empty(data = {}) {
        return new BuilderElementRelationCondition({
            id: data.id || 0,
            relation_id: data.relation_id || 0,
            active_element_id: data.active_element_id || null,
            passive_element_id: data.passive_element_id || null,
            condition: data.condition || '',
            condition_value: data.condition_value,
            group_code: data.group_code ? data.group_code : null,
            data: data.data ? data.data : null
        })
    }

    toObject() {
        return {
            ...this
        }
    }

}