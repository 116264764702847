<template>
  <v-card class="app-default-border">
    <v-card-title class="pt-0">
      <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          @input="onSearch"
      ></v-text-field>
    </v-card-title>

    <v-data-table
        :headers="headers"
        :items="elements"
    >

      <template v-slot:item.operation="{item}">
        <div class="d-flex">

          <edit-icon v-if="selectedElement.id !== item.id" @click="onClickEdit(item)" />

          <cancel-icon v-else @click="onClickCancel" />

          <delete-icon @click="onClickDelete(item)" />
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import EditIcon from "@/ui/components/common/icons/EditIcon.vue";
import DeleteIcon from "@/ui/components/common/icons/DeleteIcon.vue";
import {BuilderElementRelation} from "@/models/builder-element-relations/builder-element-relation.js";
import CancelIcon from "@/ui/components/common/icons/CancelIcon.vue";

export default {
  components: { CancelIcon, DeleteIcon, EditIcon},
  props: {
    selectedElement: {
        type: BuilderElementRelation,
        required: true,
    },
    builderElementRelations: {
      type: Array,
      required: true,
    }
  },
  computed: {
    headers() {
      return [
        { text: 'Id', value: 'id'},
        { text: 'Pos', value: 'position'},
        { text: 'Description', value: 'description' },
        { text: 'Operation', value: 'operation'}
      ]
    },
  },
  data: () => ({
      elements: [],
  }),
  created() {
    this.elements = this.builderElementRelations;
  },
  methods: {
    onClickEdit(element) {
      this.$emit('clickEdit', element)
    },

    onClickCancel() {
      this.$emit('clickEdit', BuilderElementRelation.empty())
    },

    onClickDelete(element) {
      this.$emit('clickDelete', element)
    },

    onSearch(text) {
      if(text) {
        this.elements = this.builderElementRelations.filter(
            (elem) => elem.description.toString().toLowerCase().includes(text.toString().toLocaleLowerCase())
        )
      } else {
        this.elements = this.builderElementRelations;
      }
    }
  }
}
</script>