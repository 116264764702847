<template>
  <div :style="{padding, margin, width}">
    <text-field
        class="pr-1"
        :name="name"
        :width="width"
        :value="value"
        :placeholder="placeholder"
        :validation="validation"
        :disabled="disabled"
        :mask="mask"
        @input="saveField"
    />
  </div>
</template>
<script>
import TextField from "../../common/fields/TextField.vue";
import DefaultElement from "./mixins/DefaultElement.js";
import Padding from "./mixins/Padding.js";
import Placeholder from "./mixins/Placeholder.js";
import Size from "./mixins/Size.js";
import {builderElementsEvents} from "@/utils/events/builder-elements-events.js";
import {fieldService} from "@/utils/services/field-service.js";
import Validation from "@/ui/components/builder/elements/mixins/Validation";
import Mask from "@/ui/components/builder/elements/mixins/Mask";
import {findCityByPostalCode} from "@/utils/helpers";

export default {
  components: {TextField},
  mixins: [DefaultElement, Padding, Placeholder, Size, Validation, Mask],
  props: {
    disabledBuilderElement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    disabled() {
      return fieldService.isFieldSelected(this.builderElement.data.disabled) || this.disabledBuilderElement
    },
  },
  methods: {
    findAndSetCity() {
      const city = findCityByPostalCode(this.builderElement.value);

      if (city) {
        this.builderElement.data.targetBuilderElement.value = city.city;

        builderElementsEvents.updateValue.emit(this.builderElement.data.targetBuilderElement)
      }
    },
    saveField(value) {
      this.builderElement.value = value;

      if (this.builderElement.data.isFindCity && this.builderElement.data.targetBuilderElement) {
        this.findAndSetCity();
      }

      if (this.builderElement.data.active_element_is_repeat) {
        builderElementsEvents.repeatByElement.emit(this.builderElement);
      }

      builderElementsEvents.updateValue.emit(this.builderElement)
    }
  }
}
</script>