<template>
  <div>
    <json-name-editor
        v-model="element.name"
        v-if="!parentIsArray"
    />

    <div>
      <p class="app-text__bold">Default Value</p>

      <text-field
          name="value"
          v-model="element.value"
      />
    </div>

    <json-priority-editor
        v-model="element.priority"
    />
  </div>
</template>
<script>
import TextField from "@/ui/components/common/fields/TextField.vue";
import {BuilderElement} from "@/models/builder-element.js";
import JsonNameEditor
  from "@/ui/components/json-builder/use-cases/json-builder-editor/attribute-editor/JsonNameEditor.vue";
import JsonPriorityEditor
  from "@/ui/components/json-builder/use-cases/json-builder-editor/attribute-editor/JsonPriorityEditor.vue";

export default {
  components: {JsonPriorityEditor, JsonNameEditor, TextField},
  props: {
    element: {
      type: BuilderElement,
      required: true,
    },
    parentIsArray: {
      type: Boolean,
      required: true,
    }
  }
}
</script>