<template>
<div>
  <div class="d-flex justify-space-between">
    <h2>Price Addons</h2>

    <div class="d-flex">

      <orange-btn
          class="ml-2"
          @click="onClickRelations"
      >
        Addon Relations
      </orange-btn>

      <blue-btn
          class="ml-2"
          @click="onCreateClick"
      >
        Create New Addon
      </blue-btn>
    </div>
  </div>
  <price-addon-list
    class="my-12"
    :addons="addons"
    :loading="loading"
    @editPriceAddonClick="onPriceAddonEditClick"
    @deletePriceAddonClick="onDeletePriceAddonClick"
  />
  <price-addon-editor-popup
      v-if="showEditor"
      :price-addon="priceAddon"
      @updatePriceAddon="onUpdatePriceAddonClick"
      @close="onCloseEditor"
  />
  <price-addon-creator
      v-if="showCreator"
      :price-addon="priceAddon"
      @onCreatePriceAddon="onCreatePriceAddon"
      @close="onCloseCreator"
  />
  <insurance-price-addon-relations-popup
      v-if="showRelationsPopup"
      :insurance-type="insuranceType"
      :builder-elements="builderElements"
      :addons="addons"
      @close="onCloseRelationsPopup"
  />

</div>
</template>

<script>
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import InsurancePriceAddonRelationsPopup from "@/ui/components/admin/insurance-price-addon-relations/InsurancePriceAddonRelationsPopup.vue";
import {InsuranceType} from "@/models/insurance-type.js";
import {builderElementService} from "@/utils/services/builder-element-service.js";
import PriceAddonList from "@/ui/components/admin/insurance-type/price-addons-section/PriceAddonList";
import PriceAddonEditorPopup from "@/ui/components/builder/use-cases/builder-editor/sections/PriceAddonEditorPopup";
import {InsurancePriceAddon} from "@/models/insurance-price-addon";
import PriceAddonCreator from "@/ui/components/builder/use-cases/builder-creator/sections/PriceAddonCreator";
import {notificationService} from "@/utils/services/notification-service";
import {confirmService} from "@/utils/services/confirm-service";

export default {
  components: {
    PriceAddonCreator,
    PriceAddonEditorPopup,
    PriceAddonList,
    OrangeBtn,
    BlueBtn,
    InsurancePriceAddonRelationsPopup,
  },
  props: {
    insuranceType: {
      type: InsuranceType,
      required: true,
    }
  },
  data: () => ({
    showRelationsPopup: false,
    showCreator: false,
    showEditor: false,
    builderElements: [],
    addons: [],
    loading: false,
    priceAddon: InsurancePriceAddon.empty()
  }),
  async created() {
    this.addons = await this.getAddons(this.insuranceType)
    this.builderElements = await this.getBuilderElements(this.insuranceType);
  },
  methods: {
    onCloseCreator() {
      this.showCreator = false;
    },
    async onCreatePriceAddon(priceAddon) {
      this.showCreator = false;

      await this.createPriceAddon(this.insuranceType, priceAddon.toObject());

      this.loading = true;
      this.addons = await this.getAddons(this.insuranceType);
      this.loading = false;
    },
    getAddons(insuranceType) {
      return this.$store.dispatch('admin/insuranceTypeModule/addonsByInsuranceType', {
        insuranceType
      })
    },
    async getBuilderElements() {
      const insuranceBuilders = await this.getInsuranceBuilders(this.insuranceType);

      let parentElements = [];

      insuranceBuilders.forEach(({builder}) => {
        parentElements = [...parentElements, ...builder.elements]
      });

      return builderElementService.elementsToSingleArray(parentElements);
    },
    getInsuranceBuilders(insuranceType) {
      return this.$store.dispatch('admin/insuranceTypeModule/buildersByInsuranceType', {
        insuranceType
      });
    },
    onClickRelations() {
      this.showRelationsPopup = true;
    },
    onCreateClick() {
      this.priceAddon = InsurancePriceAddon.empty({
        details: {
          info: [],
          tooltip: 'tooltip'
        }
      })

      this.showCreator = true;
    },
    onCloseRelationsPopup() {
      this.showRelationsPopup = false
    },
    async onPriceAddonEditClick(priceAddon) {
      this.priceAddon = priceAddon;

      this.showEditor = true;
    },
    async onDeletePriceAddonClick(priceAddon) {
      if (!confirmService.simpleConfirm("Are you really want to delete this Price addon?")) {
        return;
      }

      this.loading = true;
      await this.deletePriceAddon(this.insuranceType, priceAddon)
      this.addons = await this.getAddons(this.insuranceType);
      this.loading = false;

      notificationService.success("Price addon deleted");
    },
    async onUpdatePriceAddonClick(priceAddon) {
      this.loading = true;

      await this.updatePriceAddon(this.insuranceType, priceAddon, priceAddon.toObject());
      this.addons = await this.getAddons(this.insuranceType);
      this.showEditor = false;

      this.loading = false;

      notificationService.success("Price addon info updated")
    },
    async onCloseEditor() {
      this.showEditor = false;

      this.loading = false;
    },

    async createPriceAddon(insuranceType, addonData) {
      return this.$store.dispatch('admin/insuranceTypeModule/createInsurancePriceAddon', {
        insuranceType,
        addonData
      })
    },

    async updatePriceAddon(insuranceType, priceAddon, addonData) {
      return this.$store.dispatch('admin/insuranceTypeModule/updateInsurancePriceAddon', {
        insuranceType,
        priceAddon,
        addonData
      })
    },

    async deletePriceAddon(insuranceType, priceAddon) {
      return this.$store.dispatch('admin/insuranceTypeModule/deleteInsurancePriceAddon', {
        insuranceType,
        priceAddon,
      })
    },
  },
}
</script>
