<template>
  <insurance-addons-wrapper class="mt-4" v-if="addonsCount" :header="header">
    <div
        v-for="(addon, index) in addons"
        :key="index"
    >
      <first-addon class="mr-16" :addon="addon" :period="period" :show-price="showPrice" :disabled="isDisableAllInputs || isNotAvailableAddon(addon.key)" @changeAddon="onChangeAddon"/>
    </div>
  </insurance-addons-wrapper>
</template>

<script>
import InsuranceAddonsWrapper from "@/ui/components/insurance/insurance-wrappers/InsuranceAddonsWrapper.vue";
import FirstAddon from "@/ui/components/insurance/insurance-addons/FirstAddon.vue";
import {INSURANCE_PRICE_PAYMENT_PERIOD_LABELS} from "@/utils/fields/insurance-price-list-field-names.js";

export default {
  components: {
    InsuranceAddonsWrapper,
    FirstAddon,
  },
  props: {
    addons: {
      type: Array,
      required: true,
    },
    existingAddons: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    insuranceTypeId: {
      type: Number,
      required: true,
    },
    period: {
      type: String,
      default: INSURANCE_PRICE_PAYMENT_PERIOD_LABELS.year,
      required: true,
    },
    showPrice: {
      type: Boolean,
      default: true,
      required: false
    },
    header: {
      type: String,
      default: 'Wünschen Sie Zusatzoptionen?',
    },
    isDisableAllInputs: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    addonsCount() {
      return this.addons.length
    },
  },
  methods: {
    isNotAvailableAddon(key) {
      const keys = this.existingAddons.map(itm => itm.key);

      return keys.includes(key);
    },
    onChangeAddon(){
      this.$emit('changeAddon')
    },
  }
}
</script>