<template>
  <simple-popup width="80%" header="JSON EDITOR" @close="onClose">
    <v-row>
      <v-col cols="6">
        <app-block color="blue lighten-5" class="rounded">
          <json-builder-selector
              :builder="builder"
          />
        </app-block>
      </v-col>
      <v-col cols="6" class="pl-4">
        <edit-json-element
            v-if="selectedElement"
            :builder-element="selectedElement"
            :elements="builder.elements"
        />

        <create-json-element
            v-else
            :elements="builder.elements"
        />
      </v-col>
    </v-row>
  </simple-popup>
</template>
<script>
import SimplePopup from "@/ui/components/common/popup/SimplePopup.vue";
import JsonBuilderSelector from "@/ui/components/json-builder/use-cases/json-builder-selector/JsonBuilderSelector.vue";
import {Builder} from "@/models/builder.js";
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import EditJsonElement from "@/ui/components/json-builder/use-cases/json-builder-editor/section/EditJsonElement.vue";
import CreateJsonElement
  from "@/ui/components/json-builder/use-cases/json-builder-editor/section/CreateJsonElement.vue";
import {notificationService} from "@/utils/services/notification-service.js";
import {confirmService} from "@/utils/services/confirm-service.js";
import {jsonElementService} from "@/utils/services/json-element-service.js";
import {builderEditorEvents} from "@/utils/events/builder-editor-events.js";

export default {
  components: {
    CreateJsonElement,
    EditJsonElement, AppBlock, JsonBuilderSelector, SimplePopup
  },
  props: {
    builder: {
      type: Builder,
      required: true,
    },
  },

  data: () => ({
    selectedElement: null,
  }),

  created() {
    builderEditorEvents.createElement.on(this.onCreateElement);
    builderEditorEvents.deleteElement.on(this.onDeleteElement);
    builderEditorEvents.updateElement.on(this.onUpdateElement);
    builderEditorEvents.selectElement.on(this.onSelectElement);
    builderEditorEvents.unselectElement.on(this.onUnselectElement);
  },

  beforeDestroy() {
    builderEditorEvents.createElement.off(this.onCreateElement);
    builderEditorEvents.deleteElement.off(this.onDeleteElement);
    builderEditorEvents.updateElement.off(this.onUpdateElement);
    builderEditorEvents.selectElement.off(this.onSelectElement);
    builderEditorEvents.unselectElement.off(this.onUnselectElement);
  },

  methods: {
    onClose() {
      this.$emit('close')
    },

    onSelectElement(id) {
      this.selectedElement = jsonElementService.findElementById(this.builder.elements, id);
    },

    onUnselectElement() {
      this.selectedElement = null;
    },

    async onCreateElement(builderElement) {
        const element = await this.createJsonElement(this.builder.id, builderElement.toObject());

        if(element) {
          notificationService.success('Element was created');

          builderEditorEvents.unselectElement.emit();

          jsonElementService.addElement(this.builder.elements, element);

          setTimeout(() => builderEditorEvents.selectElement.emit(element.id), 150);
        }
    },

    async onUpdateElement({id, data}) {
      const element = await this.updateJsonElement(id, data)

      if (element) {
        notificationService.success('Element was updated');

        builderEditorEvents.unselectElement.emit()
      }
    },

    async onDeleteElement(builderElementId) {
      if (!confirmService.simpleConfirm("Are you really want to delete this element?")) {
        return;
      }

      const success = await this.deleteJsonElement(builderElementId);

      if (success) {
        notificationService.success('Element was deleted')

        this.builder.elements = jsonElementService.removeElementById(this.builder.elements, builderElementId);
        builderEditorEvents.unselectElement.emit();
      }
    },

    createJsonElement(builderId, data) {
      return this.$store.dispatch('admin/jsonBuilderEditorModule/createElement', {
        builderId,
        data,
      })
    },

    updateJsonElement(builderElementId, data) {
      return this.$store.dispatch('admin/jsonBuilderEditorModule/updateElement', {
        builderElementId,
        data,
      })
    },

    deleteJsonElement(builderElementId) {
      return this.$store.dispatch('admin/jsonBuilderEditorModule/deleteElement', {
        builderElementId,
      })
    }
  }
}
</script>