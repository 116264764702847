<template>
  <builder-section
      v-if="type === builderElementEnum.SECTION"
      :builder-element="builderElement"
      :builder-element-id="builderElementId"
      :disabled-all-inputs="disabledAllInputs"
      :admin-edit-mode="adminEditMode"
  />

  <builder-block
      v-else-if="type === builderElementEnum.BLOCK"
      :builder-element="builderElement"
      :builder-element-id="builderElementId"
      :disabled-all-inputs="disabledAllInputs"
      :admin-edit-mode="adminEditMode"
  />

  <builder-row
      v-else-if="type === builderElementEnum.ROW"
      :builder-element="builderElement"
      :builder-element-id="builderElementId"
      :disabled-all-inputs="disabledAllInputs"
      :admin-edit-mode="adminEditMode"
  />

  <builder-label
      v-else-if="type === builderElementEnum.LABEL"
      :builder-element="builderElement"
  />

  <builder-text-field
      v-else-if="type === builderElementEnum.TEXT_FIELD"
      :builder-element="builderElement"
      :disabled-builder-element="disabledAllInputs"
  />

  <builder-number-field
     v-else-if="type === builderElementEnum.NUMBER_FIELD"
     :builder-element="builderElement"
     :disabled-builder-element="disabledAllInputs"
  />

  <builder-radio-button
      v-else-if="type === builderElementEnum.RADIO_BUTTON"
      :builder-element="builderElement"
      :disabled-builder-element="disabledAllInputs"
  />

  <builder-select-field
      v-else-if="type === builderElementEnum.SELECT_FIELD"
      :builder-element="builderElement"
      :disabled-builder-element="disabledAllInputs"
  />

  <builder-searchable-field
      v-else-if="type === builderElementEnum.SEARCHABLE_SELECT"
      :builder-element="builderElement"
      :disabled-builder-element="disabledAllInputs"
  />

  <builder-searchable-min-symbols-filter-field
      v-else-if="type === builderElementEnum.SEARCHABLE_MIN_SYMBOLS_FILTER"
      :builder-element="builderElement"
      :disabled-builder-element="disabledAllInputs"
  />

  <builder-tooltip
      v-else-if="type === builderElementEnum.TOOLTIP"
      :builder-element="builderElement"
  />

  <builder-checkbox
      v-else-if="type === builderElementEnum.CHECKBOX"
      :builder-element="builderElement"
      :disabled-builder-element="disabledAllInputs"
  />

  <builder-button-selector
      v-else-if="type === builderElementEnum.BUTTON_SELECTOR"
      :builder-element="builderElement"
      :disabled-builder-element="disabledAllInputs"
  />

  <builder-duplicate-block
      v-else-if="type === builderElementEnum.DUPLICATE_BLOCK"
      :builder-element="builderElement"
      :builder-element-id="builderElementId"
      :disabled-builder-element="disabledAllInputs"
      :admin-edit-mode="adminEditMode"
  />

  <builder-date-field
      v-else-if="type === builderElementEnum.DATE_FIELD"
      :builder-element="builderElement"
      :disabled-builder-element="disabledAllInputs"
  />

  <builder-text-area-field
      v-else-if="type === builderElementEnum.TEXT_AREA_FIELD"
      :builder-element="builderElement"
      :disabled-builder-element="disabledAllInputs"
  />

  <builder-file-field
      v-else-if="type === builderElementEnum.FILE_FIELD"
      :builder-element="builderElement"
      :disabled-builder-element="disabledAllInputs"
  />

  <builder-tooltip-block
      v-else-if="type === builderElementEnum.TOOLTIP_BLOCK"
      :builder-element="builderElement"
      :builder-element-id="builderElementId"
      :admin-edit-mode="adminEditMode"
  />

  <builder-money-field
      v-else-if="type === builderElementEnum.MONEY_FIELD"
      :builder-element="builderElement"
      :disabled-builder-element="disabledAllInputs"
  />

  <builder-validation-section
      v-else-if="type === builderElementEnum.VALIDATION_SECTION"
      :builder-element="builderElement"
  />

  <builder-button
      v-else-if="type === builderElementEnum.BUTTON"
      :builder-element="builderElement"
      :disabled-builder-element="disabledAllInputs"
  />
  <builder-link-button
      v-else-if="type === builderElementEnum.LINK_BUTTON"
      :builder-element="builderElement"
  />
  <builder-block-repeated
      v-else-if="type === builderElementEnum.REPEATED_BLOCK"
      :builder-element="builderElement"
      :builder-element-id="builderElementId"
      :disabled-all-inputs="disabledAllInputs"
      :admin-edit-mode="adminEditMode"
  />
  <builder-simple-popup
      v-else-if="type === builderElementEnum.SIMPLE_POPUP"
      :builder-element="builderElement"
      :admin-edit-mode="adminEditMode"
  />
</template>
<script>
import BuilderSection from "./elements/BuilderSection.vue";
import {builderElementEnum} from "@/enums/builder-element-enum.js";
import BuilderLabel from "./elements/BuilderLabel.vue";
import {BuilderElement} from "@/models/builder-element.js";
import BuilderBlock from "./elements/BuilderBlock.vue";
import BuilderTextField from "./elements/BuilderTextField.vue";
import BuilderRow from "./elements/BuilderRow.vue";
import BuilderRadioButton from "./elements/BuilderRadioButton.vue";
import BuilderSelectField from "./elements/BuilderSelectField.vue";
import BuilderTooltip from "@/ui/components/builder/elements/BuilderTooltip.vue";
import BuilderCheckbox from "@/ui/components/builder/elements/BuilderCheckbox.vue";
import BuilderButtonSelector from "@/ui/components/builder/elements/BuilderButtonSelector.vue";
import BuilderDuplicateBlock from "@/ui/components/builder/elements/BuilderDuplicateBlock.vue";
import BuilderDateField from "@/ui/components/builder/elements/BuilderDateField.vue";
import BuilderTextAreaField from "@/ui/components/builder/elements/BuilderTextAreaField.vue";
import BuilderFileField from "@/ui/components/builder/elements/BuilderFileField.vue";
import BuilderTooltipBlock from "@/ui/components/builder/elements/BuilderTooltipBlock.vue";
import BuilderMoneyField from "@/ui/components/builder/elements/BuilderMoneyField.vue";
import BuilderNumberField from "@/ui/components/builder/elements/BuilderNumberField.vue";
import BuilderValidationSection from "@/ui/components/builder/elements/BuilderValidationSection.vue";
import BuilderButton from "@/ui/components/builder/elements/BuilderButton.vue";
import BuilderLinkButton from "@/ui/components/builder/elements/BuilderLinkButton.vue";
import BuilderBlockRepeated from "@/ui/components/builder/elements/BuilderBlockRepeated";
import BuilderSearchableField from "@/ui/components/builder/elements/BuilderSearchableField";
import BuilderSimplePopup from "@/ui/components/builder/elements/BuilderSimplePopup";
import BuilderSearchableMinSymbolsFilterField from "@/ui/components/builder/elements/BuilderSearchableMinSymbolsFilterField.vue";

export default {
  components: {
    BuilderSimplePopup,
    BuilderSearchableField,
    BuilderBlockRepeated,
    BuilderNumberField,
    BuilderMoneyField,
    BuilderTooltipBlock,
    BuilderFileField,
    BuilderTextAreaField,
    BuilderDateField,
    BuilderDuplicateBlock,
    BuilderButtonSelector,
    BuilderCheckbox,
    BuilderTooltip,
    BuilderSelectField,
    BuilderRadioButton,
    BuilderRow,
    BuilderTextField,
    BuilderBlock,
    BuilderLabel,
    BuilderSection,
    BuilderValidationSection,
    BuilderButton,
    BuilderLinkButton,
    BuilderSearchableMinSymbolsFilterField,
  },
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    },
    builderElementId: {
      type: String,
      required: false,
    },
    disabledAllInputs: {
      type: Boolean,
      required: false,
      default: false,
    },
    adminEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    type() {
      return this.builderElement.type;
    },

    builderElementEnum() {
      return builderElementEnum;
    },
  },
}
</script>