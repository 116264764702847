import InfoLayout from "../../ui/layouts/info/InfoLayout.vue";
import {routeNames} from "../route-names";
import ContractFinishPage from "@/ui/pages/info/ContractFinishPage.vue";
import AccessRestricted from "@/ui/pages/info/AccessRestricted.vue";

export const infoRoutes = [
    {
        path: '/',
        component: InfoLayout,
        children: [
            {
                path: '/contract-finish-page',
                name: routeNames.contractFinishPage,
                component: ContractFinishPage,
            },
            {
                path: '/access-restricted',
                name: routeNames.accessRestricted,
                component: AccessRestricted,
            },
        ],
    }
]