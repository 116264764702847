<template>
  <v-row>
    <v-col cols="6" class="pa-4" v-for="builder in builders" :key="builder.id">
      <white-btn
          width="100%"
          height="125px"
          :selected="selectedBuilder.id === builder.id"
          @click="onSelectBuilder(builder)"
      >
        {{ builder.name }}
      </white-btn>
    </v-col>
  </v-row>
</template>
<script>
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import {Builder} from "@/models/builder.js";
import {builderCreatorEvents} from "@/utils/events/builder-creator-events.js";

export default  {
  components: {WhiteBtn},
  props: {
    builders: {
      type: Array,
      required: true,
    }
  },
  data: () => ({
    selectedBuilder: Builder.empty(),
  }),
  mounted() {
    this.selectFirstElement();
  },
  methods: {
    onSelectBuilder(builder) {
      if (builder && this.selectedBuilder.id !== builder?.id) {
        this.selectedBuilder = new Builder(builder.toObject())

        builderCreatorEvents.selectBuilderTemplate.emit(this.selectedBuilder);
      }
    },

    selectFirstElement() {
      setTimeout(() => this.onSelectBuilder(this.builders[0]), 200);
    }
  }
}
</script>