<template>
  <div>
    <div class="d-flex justify-space-between">
      <h2>Insurance Elements</h2>

      <div class="d-flex">
        <orange-btn
          @click="onClickRelations"
        >
          Insurance Relations
        </orange-btn>

        <blue-btn
            class="ml-2"
            @click="onClickNewElement"
        >
          Add New Element
        </blue-btn>
      </div>
    </div>

    <elements-list
        class="my-12"
        :insurance-elements="insuranceElements"
        @editClick="onEditElementClick"
        @deleteClick="onDeleteElementClick"
        @relationsClick="onClickRelations"
        :loading="loading"
    />

    <insurance-element-create-popup
        v-if="showCreatePopup"
        @close="onCloseCreatePopup"
        @createElement="onCreateNewElement"
    />

    <insurance-element-update-popup
        v-if="showUpdatePopup"
        :insurance-element="insuranceElement"
        @close="onCloseUpdatePopup"
        @updateElement="onUpdateElement"
    />

    <insurance-element-relations-popup
        v-if="showRelationsPopup"
        :builder-elements="builderElements"
        :insurance-elements="insuranceElements"
        :insurance-type="insuranceType"
        @close="onCloseRelationsPopup"
    />
  </div>
</template>
<script>
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import {InsuranceType} from "@/models/insurance-type.js";
import ElementsList from "@/ui/components/admin/insurance-type/elements-section/ElementsList.vue";
import InsuranceElementCreatePopup from "@/ui/components/admin/insurance-element/InsuranceElementCreatePopup.vue";
import InsuranceElementUpdatePopup from "@/ui/components/admin/insurance-element/InsuranceElementUpdatePopup.vue";
import {notificationService} from "@/utils/services/notification-service.js";
import InsuranceElementRelationsPopup from "@/ui/components/admin/insurance-element-relations/InsuranceElementRelationsPopup.vue";
import {builderElementService} from "@/utils/services/builder-element-service.js";
import {confirmService} from "@/utils/services/confirm-service.js";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";

export default {
  components: {
    OrangeBtn,
    InsuranceElementRelationsPopup,
    InsuranceElementUpdatePopup,
    InsuranceElementCreatePopup,
    ElementsList,
    BlueBtn
  },
  props: {
    insuranceType: {
      type: InsuranceType,
      required: true,
    }
  },
  data: () => ({
    insuranceElements: [],
    builderElements: [],
    insuranceElement: null,
    loading: false,

    showCreatePopup: false,
    showUpdatePopup: false,
    showRelationsPopup: false,
  }),
  async created() {
    this.loaded = true;

    this.insuranceElements = await this.getElements(this.insuranceType);
    this.builderElements = await this.getBuilderElements(this.insuranceType);

    this.loaded = false;
  },
  methods: {
    onClickNewElement() {
      this.showCreatePopup = true;
    },

    onClickRelations() {
      this.showRelationsPopup = true;
    },

    onCloseCreatePopup() {
      this.showCreatePopup = false;
    },

    onCloseUpdatePopup() {
      this.showUpdatePopup = false;
    },

    onCloseRelationsPopup() {
      this.showRelationsPopup = false
    },

    onEditElementClick(insuranceElement) {
      this.insuranceElement = insuranceElement;

      this.showUpdatePopup = true;
    },

    async onDeleteElementClick(insuranceElement) {
      if(!confirmService.simpleConfirm('Are you really want to delete this InsuranceElement?')) {
        return;
      }

      this.loading = true;
      this.insuranceElements = await this.deleteElement(
          insuranceElement,
          this.insuranceType
      )
      this.loading = false;

      notificationService.success('Element deleted')
    },

    async onCreateNewElement(insuranceElement) {
      this.loading = true;
      this.insuranceElements = await this.createElement(this.insuranceType, insuranceElement.toObject());
      this.loading = false;

      notificationService.success('Element created')
    },

    async onUpdateElement(insuranceElement) {
      this.loading = true;
      this.insuranceElements = await this.updateElement(
          insuranceElement,
          this.insuranceType,
          insuranceElement.toObject()
      );
      this.loading = false;

      notificationService.success('Element updated')
    },

    async getBuilderElements() {
      const insuranceBuilders = await this.getInsuranceBuilders(this.insuranceType);

      let parentElements = [];

      insuranceBuilders.forEach(({builder}) => {
        parentElements = [...parentElements, ...builder.elements]
      });

      return builderElementService.elementsToSingleArray(parentElements);
    },

    deleteElement(insuranceElement, insuranceType) {
      return this.$store.dispatch('admin/insuranceTypeModule/deleteInsuranceElement', {
        insuranceElement,
        insuranceType,
      })
    },

    updateElement(insuranceElement, insuranceType, elementData) {
      return this.$store.dispatch('admin/insuranceTypeModule/updateInsuranceElement', {
        insuranceElement,
        insuranceType,
        elementData,
      })
    },

    createElement(insuranceType, elementData) {
      return this.$store.dispatch('admin/insuranceTypeModule/createInsuranceElement', {
        insuranceType,
        elementData
      })
    },

    getElements(insuranceType) {
      return this.$store.dispatch('admin/insuranceTypeModule/elementsByInsuranceType', {
        insuranceType
      })
    },

    getInsuranceBuilders(insuranceType) {
      return this.$store.dispatch('admin/insuranceTypeModule/buildersByInsuranceType', {
        insuranceType
      });
    },
  }
}
</script>