<template>
  <v-icon
      @click="$emit('click')"
      class="cursor-pointer rounded-circle"
      v-ripple="true"
      :color="color"
      :size="size"
  >
    mdi-pencil
  </v-icon>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'gray',
    },
    size: {
      type: String,
      default: '24px',
    },
  }
}
</script>