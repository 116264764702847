<template>
  <div>
    <div class="mt-2 pt-3 app-bg-aqua">
<!--      <app-block class="py-2 mb-4 app-container">-->
<!--        <progress-bar-->
<!--            name="Risikoerfassung"-->
<!--            :selected-item="1"-->
<!--        />-->
<!--      </app-block>-->

      <div class="mt-2 mb-4 pt-2 text-center header">
        <div class="mb-3">
          <h3>Vermögensschaden-Haftpflichtversicherung</h3>
        </div>
        <div>
          <h1>Erstellen Sie ein neues Angebot</h1>
        </div>
        <div>
          <img class="bg-img" src="/img/man_sitting_on_vault_holding_money.png" alt="Allianz logo"/>
        </div>
      </div>
    </div>
    <v-form
        :ref="formName"
        v-if="loaded"
    >
      <div class="app-container">
        <app-block class="pt-4 pb-2 mb-4">
          <div>
            <p class="app-text pt-1 pb-2 default-headline">Bitte vergeben Sie einen Vorgangsnamen</p>
          </div>
          <div>
            <text-field
              name="name"
              width="400px"
              placeholder="Hier eintragen"
              :validation="{required: true}"
              :value="insuranceName"
              @saveField="onSetName"
            />
          </div>
        </app-block>

        <app-block class="px-8 pt-4 pb-8 mb-4">
          <div>
            <p class="app-text pt-1 default-headline">Für welche Branche möchten Sie ein Angebot erstellen?</p>
          </div>
          <insurance-type-select
            :static-insurance-types="insuranceTypes"
            :insurance-type="insuranceType"
            @setInsuranceType="onSetInsuranceType"
          />
        </app-block>

        <div class="float-right">
          <next-step-btn
            @click="onClickNext"
          />
        </div>
      </div>
    </v-form>
    <app-preloader v-else class="mt-lg-12"/>
  </div>
</template>
<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue";

import {insuranceStepEnum} from "@/enums/insurance-step-enum.js";
import InsuranceTypeSelect from "@/ui/components/insurance/InsuranceTypeSelect.vue";
import TextField from "@/ui/components/common/fields/TextField.vue";
import NextStepBtn from "@/ui/components/insurance/insurance-buttons/NextStepBtn.vue";
import {routeNames} from "@/router/route-names.js";
import {localStorageService} from "@/utils/services/local-storage-service.js";

export default {
  components: {NextStepBtn, TextField, InsuranceTypeSelect,  AppPreloader, AppBlock},
  computed: {
    riskStep() {
      return insuranceStepEnum.RISK;
    },

    insuranceType() {
      return this.$store.getters["insuranceModule/getInsuranceType"]
    },

    insuranceName() {
      return this.$store.getters["insuranceModule/getInsuranceName"]
    },

    formName() {
      return 'createInsurance';
    },

    form() {
      return this.$refs[this.formName];
    },

    authUserId() {
      return this.$store.getters.authUserId
    },
  },
  data: () => ({
    insuranceTypes: [],
    loaded: false,
  }),
  async created() {
    await this.setInsuranceTypes();

    await this.setInsuranceName();
  },
  methods: {
    async setInsuranceTypes() {
      this.loaded = false;
      this.insuranceTypes = await this.getInsuranceTypes();
      this.loaded = true;
    },

    onSetInsuranceType(insuranceType) {
      this.$store.commit('insuranceModule/setInsuranceType', insuranceType)
    },

    onSetName(insuranceName) {
      this.$store.commit('insuranceModule/setInsuranceName', insuranceName);
    },

    async onClickNext() {
      if (this.form.validate()) {
        this.loaded = false;

        const {insuranceId, success} = await this.createInsurance(
            this.insuranceType,
            this.insuranceName,
        )

        if(success) {
          await this.$router.push({name: routeNames.insuranceShow, params: {insuranceId,}})
        }
      }
    },

    getInsuranceTypes() {
      return this.$store.dispatch('insuranceModule/getInsuranceTypes')
    },

    createInsurance(insuranceType, insuranceName) {
      return this.$store.dispatch('insuranceModule/createInsurance', {
        insuranceType,
        insuranceName
      })
    },

    async setInsuranceName() {
      if (!localStorageService.amsAuth.getIsUserAms()) {
        return
      }

      const apiData = await this.getApiData(this.authUserId);

      this.onSetName(apiData.customer_data.company_name)
    },

    async getApiData(userId) {
      return await this.$store.dispatch('insuranceModule/apiDataModule/getApiDataByUser', {
        userId,
      })
    },
  }
}
</script>
<style scoped>
.bg-img {
  max-height: 195px;
  margin-bottom: -12px;
}
</style>