<template>
  <div class="pb-4 pt-2">
    <h3 class="text-center">XML</h3>

    <hr class="my-3">
    <center-block class="mb-12 mt-16" v-if="loading">
      <app-preloader/>
    </center-block>
    <v-row class="mt-4" v-else>
      <v-col cols="12" class="pr-2">
        <number-field
          name="insuranceId"
          placeholder="Insurance Id"
          v-model="insuranceId"
          :is-integer="true"
        />
        <blue-btn class="mt-2" @click="onGetInsurance">
          Change
        </blue-btn>
      </v-col>
    </v-row>

    <div v-if="insuranceStatus">
      <v-row class="mt-4">
        <v-col cols="12" class="mt-5">
          <label>Token</label>
          <text-field
            class="mt-2"
            name="token"
            v-model="token"
            :readonly="true"
          />
        </v-col>

        <v-col cols="12" class="mt-5">
          <label>Insurance Status (vorgangsTyp)</label>
          <text-field
            class="mt-2"
            name="insuranceStatus"
            v-model="insuranceStatus"
            :readonly="true"
          />
        </v-col>

        <v-col cols="12" class="mt-5">
          <label>dataXml</label>
          <text-area-field
            class="mt-2"
            name="dataXml"
            v-model="dataXml"
            :readonly="true"
            :rows=8
          />
        </v-col>

        <v-col cols="12" class="mt-5">
          <label>dataBigXml</label>
          <text-area-field
            class="mt-2"
            name="dataBigXml"
            v-model="dataBigXml"
            :readonly="true"
            :rows=15
          />
        </v-col>

        <v-col cols="12" class="mt-5" v-if="amsResponse">
          <label>AMS Response</label>
          <text-field
            class="mt-2"
            name="amsResponse"
            v-model="amsResponse"
            :readonly="true"
          />
        </v-col>

        <v-col cols="12" class="mt-5" v-if="jwt">
          <label>JWT</label>
          <text-field
            class="mt-2"
            name="jwt"
            v-model="jwt"
            :readonly="true"
          />
        </v-col>

        <v-col cols="2">
          <blue-btn class="mt-2" @click="connectAms">
            Connect to AMS
          </blue-btn>
        </v-col>

        <v-col cols="2">
          <blue-btn class="mt-2" @click="downloadXml">
            Get XML
          </blue-btn>
        </v-col>

        <v-col cols="4">
          <blue-btn class="mt-2" @click="connectToAmsBigXml">
            Connect to AMS(Big XML)
          </blue-btn>
        </v-col>

        <v-col cols="2">
          <blue-btn class="mt-2" @click="downloadBigXml">
            Get Big XML
          </blue-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import CenterBlock from "@/ui/components/common/blocks/CenterBlock.vue";
import AppPreloader from "@/ui/components/common/preloaders/AppPreloader.vue";
import NumberField from "@/ui/components/common/fields/NumberField.vue";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import TextField from "@/ui/components/common/fields/TextField.vue";
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";
import {generateDownloadLink} from "@/utils/services/file-service";

export default {
  components: {
    BlueBtn,
    AppPreloader,
    CenterBlock,
    NumberField,
    TextField,
    TextAreaField,
  },

  data: () => ({
    loading: true,
    insuranceId: null,

    insuranceStatus: '',
    token: '',
    dataXml: '',
    dataBigXml: '',
    jwt: '',
    amsResponse: '',
  }),

  async created() {
    this.loading = false
  },

  methods: {
    async onGetInsurance() {
      try {
        this.loading = true

        const {
          status,
          token,
          dataXml,
          dataBigXml
        } = await this.$store.dispatch('admin/insuranceApiModule/adminAmsGetInsurance', {
          insuranceId: this.insuranceId,
        })

        this.insuranceStatus = status
        this.token = token
        this.dataXml = dataXml
        this.dataBigXml = dataBigXml
      } catch (err) {
        this.insuranceStatus = ''
        this.token = ''
        this.dataXml = ''
        this.dataBigXml = ''
      } finally {
        this.loading = false
      }
    },

    downloadXml() {
      generateDownloadLink(this.dataXml, `order_${this.insuranceId}.xml`)
    },

    downloadBigXml() {
      generateDownloadLink(this.dataBigXml, `order_${this.insuranceId}_big.xml`)
    },

    async connectAms() {
      const {jwt, response} = await this.$store.dispatch('admin/insuranceApiModule/adminAmsConnectAmsXml', {
        insuranceId: this.insuranceId,
      })

      this.jwt = jwt;
      this.amsResponse = response;
    },

    async connectToAmsBigXml() {
      const {jwt, response} = await this.$store.dispatch('admin/insuranceApiModule/adminAmsConnectAmsBigXml', {
        insuranceId: this.insuranceId,
      })

      this.jwt = jwt;
      this.amsResponse = response;
    },
  }
}
</script>
