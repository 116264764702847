<template>
  <app-block height="750px" class="overflow-y-auto">
    <v-form :ref="formName">
      <div class="d-flex justify-space-between">
        <div>
          <p class="app-text__bold font-s-24">Description</p>

          <text-area-field
              name="Test"
              width="600px"
              v-model="insurancePriceAddonRelation.description"
              :validation="{required: true}"
          />
        </div>
        <div class="pt-16">
          <orange-btn class="mt-8" @click="onClickSaveRelation">
            {{saveBtnText()}}
          </orange-btn>
        </div>
      </div>

      <div class="mt-6">
        <p class="app-text__bold font-s-24">Conditions</p>

        <app-block
            class="pb-2"
            color="var(--smoke)"
        >
          <div
              v-for="(condition, index) in conditionsWithoutGroup"
              :key="condition.id"
          >
            <div class="d-flex justify-lg-end">
              <insurance-price-addon-relation-condition
                  :conditions="conditionsToSelectField"
                  :insurance-price-addon-condition="condition"
                  :builder-elements="builderElements"
              />

              <div v-show="index">
                <close-icon @click="onClickDeleteCondition(condition)"/>
              </div>
            </div>

            <hr class="my-4">
          </div>


          <div class="mt-4 d-flex justify-lg-end">
            <blue-btn @click="onClickAddCondition">Add Condition</blue-btn>
          </div>

          <div
              v-for="(conditionGroup, groupIndex) in conditionGroups"
              :key="conditionGroup.id"
          >
            <p class="app-text__bold mt-2">Group name</p>
            <div class="d-flex justify-lg-space-between">
              <text-field
                  class="mt-4 col-6"
                  name="name"
                  placeholder="Group name"
                  :validation="{required: true}"
                  v-model="insurancePriceAddonRelation.insurancePriceAddonConditionGroups[groupIndex].name"
              />
              <div>
                <close-icon @click="onClickDeleteConditionGroup(conditionGroup)"/>
              </div>
            </div>

            <div
                v-for="(condition, index) in conditionGroup.conditions"
                :key="condition.id"
                class="d-flex justify-lg-end">
              <insurance-price-addon-relation-condition
                  :conditions="conditionsToSelectField"
                  :insurance-price-addon-condition="condition"
                  :builder-elements="builderElements"
              />

              <div v-show="index">
                <close-icon @click="onClickDeleteCondition(condition)"/>
              </div>
            </div>
            <div class="mt-4 d-flex justify-lg-start">
              <blue-btn @click="onClickAddCondition(conditionGroup.code)">Add Condition to group</blue-btn>
            </div>
            <hr class="my-4">
          </div>

          <div class="mt-4 d-flex justify-lg-end">
            <blue-btn @click="onClickAddGroup">Add group</blue-btn>
          </div>
        </app-block>

      </div>

      <div class="mt-6">
        <p class="app-text__bold font-s-24">Actions</p>
        <app-block
            class="pb-2"
            color="var(--smoke)"
        >
          <div
              v-for="(action, index) in insurancePriceAddonRelation.insurancePriceAddonActions"
              :key="action.id"
          >
            <div class="d-flex justify-lg-end">
              <insurance-price-addon-relation-action
                  :actions="actionsToSelectField"
                  :insurance-price-addon-action="action"
                  :builder-elements="builderElements"
                  :addons="addons"
              />

              <div v-show="index">
                <close-icon @click="onClickDeleteAction(action)"/>
              </div>
            </div>

            <hr class="my-4">
          </div>

          <div class="mt-4 d-flex justify-lg-end">
            <blue-btn @click="onClickAddAction">Add Action</blue-btn>
          </div>
        </app-block>
      </div>
    </v-form>
  </app-block>
</template>

<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";
import {insurancePriceAddonRelationEvents} from "@/utils/events/insurance-price-addon-relation-events.js";
import {InsurancePriceAddonRelation} from "@/models/insurance-price-addon-relations/insurance-price-addon-relation.js";
import {InsurancePriceAddonAction} from "@/models/insurance-price-addon-relations/insurance-price-addon-action.js";
import {InsurancePriceAddonCondition} from "@/models/insurance-price-addon-relations/insurance-price-addon-condition.js";
import InsurancePriceAddonRelationAction
  from "@/ui/components/admin/insurance-price-addon-relations/fields/InsurancePriceAddonRelationAction.vue";
import CloseIcon from "@/ui/components/common/icons/CloseIcon.vue";
import {insurancePriceAddonRelationService} from "@/utils/services/insurance-price-addon-relation-service.js";
import InsurancePriceAddonRelationCondition
  from "@/ui/components/admin/insurance-price-addon-relations/fields/InsurancePriceAddonRelationCondition.vue";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import {notificationService} from "@/utils/services/notification-service.js";
import {InsuranceType} from "@/models/insurance-type.js";
import TextField from "@/ui/components/common/fields/TextField";
import {InsurancePriceAddonRelationConditionGroup} from "@/models/insurance-price-addon-relations/insurance-price-addon-relation-condition-group.js";

export default {
  components: {
    AppBlock,
    TextAreaField,
    InsurancePriceAddonRelationAction,
    CloseIcon,
    InsurancePriceAddonRelationCondition,
    BlueBtn,
    OrangeBtn,
    TextField,
  },
  props: {
    builderElements: {
      type: Array,
      required: true,
    },
    addons: {
      type: Array,
      required: true,
    },
    insuranceType: {
      type: InsuranceType,
      required: true,
    }
  },
  computed: {
    formName() {
      return 'form';
    },

    form() {
      return this.$refs[this.formName];
    },

    conditionsToSelectField() {
      return insurancePriceAddonRelationService.conditionsArray()
    },

    actionsToSelectField() {
      return insurancePriceAddonRelationService.actionsArray();
    },

    conditionsWithoutGroup() {
      return this.insurancePriceAddonRelation.insurancePriceAddonConditions.filter(itm => itm.group_code === null);
    },

    conditionGroups() {
      return this.insurancePriceAddonRelation.insurancePriceAddonConditionGroups.map(item => {
        return {
          ...item,
          conditions: this.insurancePriceAddonRelation.insurancePriceAddonConditions.filter(itm => itm.group_code === item.code)
        }
      })
    }
  },
  data: () => ({
    insurancePriceAddonRelation: InsurancePriceAddonRelation.empty(),

    operationType: 'create',
  }),
  created() {
    insurancePriceAddonRelationEvents.selectRelation.on(this.onSelectInsurancePriceAddonRelation)

    this.onSelectInsurancePriceAddonRelation(this.insurancePriceAddonRelation);
  },
  destroyed() {
    insurancePriceAddonRelationEvents.selectRelation.off(this.onSelectInsurancePriceAddonRelation)
  },
  methods: {
    async onClickSaveRelation() {

      if (this.form.validate()) {
        const result = this.operationType === 'create'
            ? await this.saveRelation(this.insuranceType, this.insurancePriceAddonRelation)
            : await this.updateRelation(this.insurancePriceAddonRelation)

        if (result) {
          notificationService.success('Relation saved');

          this.resetRelationsData()

          insurancePriceAddonRelationEvents.relationSaved.emit(this.insurancePriceAddonRelation);
        }
      }
    },

    onClickAddAction() {
      this.insurancePriceAddonRelation.insurancePriceAddonActions.push(this.defaultAction());
    },

    onClickAddCondition(groupCode = null) {
      this.insurancePriceAddonRelation.insurancePriceAddonConditions.push(this.defaultCondition(groupCode));
    },

    onClickDeleteAction(actionElement) {
      this.insurancePriceAddonRelation.insurancePriceAddonActions = this.insurancePriceAddonRelation
          .insurancePriceAddonActions
          .filter((action) => action.id !== actionElement.id);
    },

    onClickDeleteCondition(conditionElement) {
      this.insurancePriceAddonRelation.insurancePriceAddonConditions = this.insurancePriceAddonRelation
          .insurancePriceAddonConditions
          .filter((condition) => condition.id !== conditionElement.id);
    },

    onSelectInsurancePriceAddonRelation(elementRelation) {
      this.insurancePriceAddonRelation = elementRelation;

      if(elementRelation.isEmpty()) {
        this.operationType = 'create';

        this.insurancePriceAddonRelation.insurancePriceAddonActions.push(this.defaultAction());
        this.insurancePriceAddonRelation.insurancePriceAddonConditions.push(this.defaultCondition());
      } else {
        this.operationType = 'edit';
      }
    },

    saveBtnText() {
      return this.operationType === 'create' ? 'Save Relation' : 'Update Relation'
    },

    defaultAction() {
      return InsurancePriceAddonAction.empty({
        id: Math.random(),
        action: '',
        action_value: '',
      })
    },

    defaultCondition(groupCode) {
      return InsurancePriceAddonCondition.empty({
        id: Math.random(),
        condition: '',
        condition_value: '',
        group_code: groupCode,
      })
    },

    resetRelationsData() {
      this.setDefaultData();
    },

    setDefaultData() {
      this.insurancePriceAddonRelation = InsurancePriceAddonRelation.empty();

      this.insurancePriceAddonRelation.insurancePriceAddonConditions.push(this.defaultCondition());
      this.insurancePriceAddonRelation.insurancePriceAddonActions.push(this.defaultAction());
    },

    saveRelation(insuranceType, insurancePriceAddonRelation) {
      return this.$store.dispatch('admin/insurancePriceAddonRelationsModule/createInsurancePriceAddonRelation', {
        insuranceType,
        insurancePriceAddonRelation,
      })
    },

    updateRelation(insurancePriceAddonRelation) {
      return this.$store.dispatch('admin/insurancePriceAddonRelationsModule/updateInsurancePriceAddonRelation', {
        insurancePriceAddonRelation,
      })
    },

    onClickAddGroup() {
      const newEmptyGroup = this.addNewEmptyGroup();

      this.onClickAddCondition(newEmptyGroup.code);

      this.insurancePriceAddonRelation.insurancePriceAddonConditionGroups.push(newEmptyGroup)
    },

    onClickDeleteConditionGroup(conditionGroupElement) {
      this.insurancePriceAddonRelation.insurancePriceAddonConditionGroups = this.insurancePriceAddonRelation.insurancePriceAddonConditionGroups
          .filter((item) => item.id !== conditionGroupElement.id)

      this.insurancePriceAddonRelation.insurancePriceAddonConditions = this.insurancePriceAddonRelation.insurancePriceAddonConditions
          .filter((item) => item.group_code !== conditionGroupElement.code)
    },

    addNewEmptyGroup() {
      return InsurancePriceAddonRelationConditionGroup.empty({
        id: Math.random() + "",
        code: 'group' + Math.random(),
        name: ''
      })
    },
  }
}
</script>
