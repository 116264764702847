<template>
<div>
  <div class="pl-5 pr-5 app-bg-white ">
    <h3 class="app-text__bold pt-7 pb-5" >Kunden-Kommunikationsdaten</h3>
    <div class="pb-5">Der Kunde erhält eine Email mit Infopaket und Antrag als Anhang.</div>
    <v-form
        :ref="formName"
    >
      <v-row class="pb-2 pt-5">
        <v-col class="pt-2" cols="3">
          <div class="app-text__bold">Kunden E-Mail-Adresse für den Versand:</div>
        </v-col>
        <v-col class="pt-2 pl-5" >
          <text-field
              width="276px"
              placeholder="Bitte eintragen"
              :name="dataFieldKeys.customerEmail"
              :validation="{required: true, email: true}"
              v-model="customerEmail"
              :disabled="isDisableAllInputs"
              @saveField="saveField"
          />
        </v-col>
      </v-row>
      <v-row class="pb-2">
        <v-col class="pt-2" cols="3">
          <div class="app-text__bold">Wiederholung E-Mail-Adresse:</div>
        </v-col>
        <v-col class="pt-2 pl-5" >
          <text-field
              name="duplicate-email"
              width="276px"
              placeholder="Bitte eintragen"
              :validation="{required: true, duplicateValue: {compareTo: this.customerEmail, message: 'Wiederholung E-Mail-Adresse!'}}"
              v-model="duplicateEmail"
              :disabled="isDisableAllInputs"
          />
        </v-col>
      </v-row>
      <v-row class="pb-2">
        <checkbox
            :validation="checkboxValidation"
            :value="sendingConfirm"
            text="Der Kunde ist mit dem Versand an die oben stehende E-Mail-Adresse einverstanden. Die Kommunikationsdaten wurden nochmals überprüft."
            :disabled="isDisableAllInputs"
        />
      </v-row>
      <v-row class="pb-2">
        <v-col class="pt-2" cols="3">
          <div class="app-text__bold">Vermittler E-Mail-Adresse:</div>
        </v-col>
        <v-col class="pt-2 pl-5 pb-5" >
          <text-field
              width="276px"
              placeholder="Bitte eintragen"
              :name="dataFieldKeys.managerEmail"
              :value="managerEmail"
              :validation="{required: true, email: true}"
              :disabled="isDisableAllInputs"
              @saveField="saveField"
          />
        </v-col>
      </v-row>
      <v-row class="pb-2">
        <v-col class="pt-2">
          <toggle-block
              header="Notizen für den Innendienst"
              headerCssClasses="app-text__bold"
          >
            <div class="px-4 pb-4">
              Sie können dieses Feld nutzen, sofern Sie noch Anmerkungen für den Innendienst zum Antrag haben.<br><br>
              <b>Bitte beachten Sie:</b><br>
              Abweichungen in der Deckung oder dem Preis der Produkte sind nicht möglich.<br>
              Die individuelle manuelle Bearbeitung Ihres Anliegens kann einige Zeit in Anspruch nehmen.
              <v-row>
                <v-col class="pt-5 pb-5">
                  <text-area-field
                      placeholder="Bitte eintragen"
                      :name="dataFieldKeys.notes"
                      :value="notes"
                      :disabled="isDisableAllInputs"
                      @saveField="saveField"
                  />
                </v-col>
              </v-row>
            </div>
          </toggle-block>
        </v-col>
      </v-row>
    </v-form>
  </div>
  <insurance-step-wrapper
      @prevButtonClick="onClickPrev"
      @nextButtonClick="onClickNext"
      :next-button-text="nextBtnName"
  >
  </insurance-step-wrapper>
</div>
</template>

<script>
import TextField from "@/ui/components/common/fields/TextField.vue"
import Checkbox from "@/ui/components/common/fields/Checkbox.vue"
import {DATA_FIELD_KEYS} from "@/utils/fields/insurance-police-field-keys.js"
import InsuranceStepWrapper from "@/ui/components/insurance/insurance-wrappers/InsuranceStepWrapper.vue"
import {STEP3_OPTIONS_BUTTON_NAMES} from "@/utils/fields/insurance-police-step-fields.js"
import {notificationService} from "@/utils/services/notification-service.js"
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue"
import ToggleBlock from "@/ui/components/common/blocks/ToggleBlock.vue";
export default {
  name: "Option1",
  components: {
    TextField,
    Checkbox,
    InsuranceStepWrapper,
    TextAreaField,
    ToggleBlock,
  },
  computed: {
    formName() {
      return 'policeStep3Option1';
    },
    form() {
      return this.$refs[this.formName];
    },
    dataFieldKeys() {
      return DATA_FIELD_KEYS
    },
    customerEmailValue() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.customerEmail]
    },
    managerEmail() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.managerEmail]
    },
    insurancePolice() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getInsurancePolice"]
    },
    isDisableAllInputs() {
      return this.isInsuranceFinishedAt
    },
    notes() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getData"][this.dataFieldKeys.notes]
    },
    isInsuranceFinishedAt() {
      return Boolean(this.$store.getters["insuranceModule/getFinishedAt"])
    },
  },
  data: () => ({
    customerEmail: '',
    duplicateEmail: '',
    isDisableNextBtn: false,
    checkboxValidation: {
      required: true
    },

    nextBtnName: STEP3_OPTIONS_BUTTON_NAMES.deliveredToCustomers,
    sendingConfirm: '0',
  }),
  created() {
    this.customerEmail = this.customerEmailValue

    this.changeElementsForFinishedInsurance()
  },
  methods: {
    saveField(val, key) {
      this.$store.commit('insuranceModule/insurancePoliceModule/setDataField', {key, val});
    },
    onClickPrev() {
      this.$emit('clickPrev')
    },
    async onClickNext() {
      if (!this.form.validate() || this.isDisableNextBtn) {
        return
      }

      this.isDisableNextBtn = true

      await this.updatePolice(this.insurancePolice.id, this.insurancePolice)

      if (!this.isInsuranceFinishedAt) {
        await this.sendProposalEmail(this.insurancePolice.id, this.customerEmail)
      }

      this.$emit('clickNext')
    },

    async updatePolice(insurancePoliceId, data) {
      await this.$store.dispatch('insuranceModule/insurancePoliceModule/updateInsurancePolice', {
            insurancePoliceId, data
          }
      );
    },

    async sendProposalEmail(insurancePoliceId, email) {
      const response = await this.$store.dispatch('insuranceModule/insurancePoliceModule/sendProposalEmail', {
            insurancePoliceId, email
          }
      );

      if (response.success) {
        notificationService.success('Email sent successfully')
      } else {
        notificationService.error(response.message)
      }
    },
    changeElementsForFinishedInsurance() {
      if (!this.isInsuranceFinishedAt) {
        return;
      }

      this.nextBtnName = STEP3_OPTIONS_BUTTON_NAMES.next

      this.checkboxValidation = {}
      this.duplicateEmail = this.customerEmailValue
      this.sendingConfirm = '1'
    },
  },
}
</script>
