export const setValidationBySumMoreAction = ({passiveElement, action_value, context}) => {
  const arr = action_value.split('|');

  const fieldNames = arr[0] ? arr[0].split(',') : [];
  const isStrongCondition = (arr[1] === 'true') || false;
  const targetValue = arr[2] || 0;
  const message = arr[3] || '';

  let operation = '>=';

  if (isStrongCondition) {
    operation = '>'
  }

  passiveElement.validation = {
    ...passiveElement.validation,
    bySumMore: {
      fieldNames,
      operation,
      targetValue,
      passiveElement,
      message
    }
  }

  context.dispatch('insuranceModule/builderElementsModule/saveElement', {
    builderElement: passiveElement
  }, {root: true})
}