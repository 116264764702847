
const NEXT_CLICK = 'next';
const PREV_CLICK = 'prev';

export const insuranceNavigationModule = {
    namespaced: true,

    state: {
        clickStory: [],
    },

    getters: {
        lastWasPrevClick(state) {
            return state.clickStory[state.clickStory.length - 1] === PREV_CLICK;
        }
    },

    mutations: {
        addNextClick(state) {
            state.clickStory.push(NEXT_CLICK)
        },

        addPrevClick(state) {
            state.clickStory.push(PREV_CLICK);
        }
    },

}