
export class PerPeriodPrice {
    constructor({insurancePriceId, perPeriodPrice }) {
        this.insurancePriceId = insurancePriceId;
        this.perPeriodPrice = perPeriodPrice;
    }

    getPerPeriodPrice() {
        return this.perPeriodPrice;
    }

    setPerPeriodPrice(price) {
        this.perPeriodPrice = price;
    }

    getInsurancePriceId() {
        return this.insurancePriceId;
    }
}