export const insuranceStatusEnum = {
     DRAFT : 'draft',
     STARTED_RISK : 'risk_started',
     FINISHED_RISK : 'risk_finished',
     SELECTED_PRICE : 'selected_price',
     EMPTY_PRICE : 'empty_price',
     CUSTOMER_DATA : 'customer_data',
     FIRST_MAIL : 'first_mail',
     POLICE_STEP : 'police_step',
     CLICK_2_CONTRACT_MAIL_SENT : 'click_2_contract_mail_sent',
     CLICK_2_CONTRACT_MAIL_EXPIRED : 'click_2_contract_mail_expired',
     APPLICATION_SUBMITTED : 'application_submitted',
     INSURANCE_FINISHED : 'insurance_finished',
}