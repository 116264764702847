<template>
  <json-text
      v-if="elementEnum.TEXT === builderElement.type"
      :builder-element="builderElement"
      :comma="comma"
      :is-array="isArray"
  />

  <json-number
      v-else-if="elementEnum.NUMBER === builderElement.type"
      :builder-element="builderElement"
      :comma="comma"
      :is-array="isArray"
  />

  <json-array
      v-else-if="elementEnum.ARRAY === builderElement.type"
      :builder-element="builderElement"
      :comma="comma"
      :is-array="isArray"
  />

  <json-object
      v-else-if="elementEnum.OBJECT === builderElement.type"
      :builder-element="builderElement"
      :comma="comma"
      :is-array="isArray"
  />
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import {jsonElementEnum} from "@/enums/json-element-enum.js";
import JsonText from "@/ui/components/json-builder/elements/JsonText.vue";
import JsonNumber from "@/ui/components/json-builder/elements/JsonNumber.vue";
import JsonArray from "@/ui/components/json-builder/elements/JsonArray.vue";
import JsonObject from "@/ui/components/json-builder/elements/JsonObject.vue";

export default {
  components: {JsonObject, JsonArray, JsonNumber, JsonText},
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    },
    comma: {
      type: Boolean,
      default: false,
    },
    isArray: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    elementEnum() {
      return jsonElementEnum;
    }
  }
}
</script>