import Vue from "vue";
import {SelectBuilderTemplateEvent} from "@/utils/events/events/select-builder-template-event.js";

const eventBus = new Vue;

export const builderCreatorEvents = {

    selectBuilderTemplate: new SelectBuilderTemplateEvent(eventBus),
}

