import {InsuranceType} from "@/models/insurance-type.js";
import {objectService} from "@/utils/services/object-service.js";
import {InsuranceStatus} from "@/models/insurance-status.js";
import {insuranceStatusEnum} from "@/enums/insurance-status-enum.js";
import {AuthUser} from "@/models/auth-user";

export class Insurance {
    constructor(data) {
        objectService.isEmptyObject(data)
            ? this.fillData(Insurance.empty().toObject())
            : this.fillData(data);
    }

    fillData(data) {
        this.id = data.id
        this.name = data.name
        this.is_got_price = data.is_got_price
        this.is_customer_confirmed = data.is_customer_confirmed
        this.type = new InsuranceType(data.type);
        this.status = new InsuranceStatus(data.status);
        this.finished_at = data.finished_at;
        this.updated_at = data.updated_at;
        this.formal_status = data.formal_status;
        this.user = new AuthUser(data.user);
    }

    isDraft() {
        return this.status.name === insuranceStatusEnum.DRAFT;
    }

    isRiskFinished() {
        return this.status.name === insuranceStatusEnum.FINISHED_RISK;
    }

    isRiskStarted() {
        return this.status.name === insuranceStatusEnum.STARTED_RISK;
    }

    isEmptyPrice() {
        return this.status.name === insuranceStatusEnum.EMPTY_PRICE;
    }

    isCustomerData() {
        return this.status.name === insuranceStatusEnum.CUSTOMER_DATA;
    }

    isPolicyStep() {
        return this.status.name === insuranceStatusEnum.POLICE_STEP;
    }

    isFinishStep() {
        return this.status.name === insuranceStatusEnum.INSURANCE_FINISHED;
    }

    static empty(data = {}) {
        return new Insurance({
            id: data.id || 0,
            name: data.name || '',
            is_got_price: data.is_got_price || false,
            is_customer_confirmed: data.is_customer_confirmed || false,
            type : data.type || InsuranceType.empty().toObject(),
            status: data.status || InsuranceStatus.empty().toObject(),
            user: data.user || AuthUser.empty().toObject(),
        })
    }

    toObject() {
        return {
            ...this
        }
    }
}