import {userRoleEnum} from "../enums/user-role-enum.js";
import {userApiRoleEnum} from "../enums/user-api-role-enum.js";

export class AuthUser {
    constructor(data) {
        this.fillData(data);
    }

    static empty() {
        return new AuthUser({
            first_name: '',
            last_name: '',
            role: '',
            api_role: '',
            id: '',
            is_test: '',
        })
    }

    fillData(data) {
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.role = data.role
        this.api_role = data.api_role
        this.id = data.id
        this.is_test_process = data.is_test_process
    }

    getFullName() {
        return this.first_name + " " + this.last_name;
    }

    getFirstName() {
        return this.first_name;
    }

    getRoleLabel() {
        if(this.role === userRoleEnum.ADMIN) {
            return 'Admin';
        }

        return 'Vermittler';
    }

    isAdmin() {
        return this.role === userRoleEnum.ADMIN;
    }

    isStatisticUser() {
        return this.role === userRoleEnum.STATISTIC;
    }

    isEstateAgent() {
        return this.api_role === userApiRoleEnum.ESTATE_AGENT;
    }

    isEstateAgentAZAM() {
        return this.api_role === userApiRoleEnum.ESTATE_AGENT_AZAM;
    }

    getApiRole() {
        return this.api_role
    }

    getId() {
        return this.id
    }

    toObject() {
        return {
            ...this
        }
    }

    isTestProcess() {
        return this.is_test_process
    }
}