import {dateService} from "@/utils/services/date-service.js";

export const DATE_FIELD_VALIDATION_TYPE = {
    CUSTOM: 'custom',
    NOW: 'now',
    FIELD: 'field',
}

export const DATE_CONDITION = {
    MORE: 'more',
    LESS: 'less',
};

export const DATE_PERIOD = {
    DAYS: 'days',
    MONTHS: 'months',
    YEARS: 'years',
};

export class DateFieldValidation {
    constructor({type, value, condition, period, amount, message, useCondition}) {
        this.type = type;
        this.value = value;
        this.condition = condition;
        this.period = period;
        this.amount = amount;
        this.message = message;
        this.useCondition = useCondition === undefined ? true : useCondition;
    }

    toObject() {
        return {
            ...this,
        }
    }

    isCustomValue() {
        return this.type === DATE_FIELD_VALIDATION_TYPE.CUSTOM
    }

    isValidationByField() {
        return this.type === DATE_FIELD_VALIDATION_TYPE.FIELD
    }

    isAddCondition() {
        return this.condition === DATE_CONDITION.MORE
    }

    static default(condition) {
        return new DateFieldValidation({
                type: DATE_FIELD_VALIDATION_TYPE.CUSTOM,
                value: dateService.getCurrentDate(),
                condition: condition,
                period: DATE_PERIOD.DAYS,
                amount: 1,
                message: '',
                useCondition: true
            }
        )
    }
}