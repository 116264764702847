
export default {
    computed: {
        options() {
            return this.builderElement.data.options;
        },
        tooltips() {
            return this.builderElement.data.tooltips;
        },
        existedIndexes() {
            return this.tooltips.map((itm) => itm.optionIndex);
        },
        selectOptions() {
            const filtered = this.options.filter((itm, ind) => {
                return !this.existedIndexes.includes(ind);
            })

            return filtered.map((item) => {
                return {
                    value: item.text,
                    text: item.text
                }
            })
        },
    },
    data:() => ({
        tooltipToAdd: {
            text: '',
            optionIndex: 0
        },
        optionTextForTooltip: '',
    }),
    methods: {
        initOptions() {
            this.builderElement.data.options = this.builderElement.data?.options || [];
        },
        initTooltips() {
            this.builderElement.data.tooltips = this.builderElement.data?.tooltips || [];
        },
        addTooltip() {
            this.builderElement.data.tooltips.push(this.tooltipToAdd);

            this.builderElement.setDataItem('tooltips', this.builderElement.data.tooltips);

            this.tooltipToAdd = {
                text: '',
                optionIndex: 0
            };
            this.optionTextForTooltip = '';
        },
        onSelectOption(value) {
            this.tooltipToAdd.optionIndex = this.options.findIndex((itm) => itm.text === value);
        },
        onDeleteTooltipClick(index) {
            this.builderElement.data.tooltips.splice(index, 1);
        },
        onDeleteOption(value) {
            const tooltipIndex = this.builderElement.data.tooltips.findIndex((itm) => itm.optionIndex === value);

            if (tooltipIndex !== -1) {
                this.builderElement.data.tooltips.splice(tooltipIndex, 1);
            }
        },
    }
}