<template>
  <div>
    <h1 class="mb-4 text-center">Toolbar</h1>
    <v-tabs
        @change="onChangeSection"
        centered
    >
      <v-tab id="template">
        Template
      </v-tab>
      <v-tab id="info">
        Info
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="section">
      <v-tab-item id="template">
        <v-card width="100%" height="100%" class="pa-4">
          <v-card color="var(--smoke)" outlined>
            <builder-creator-template-section
                :builders="builders"
            />
          </v-card>
        </v-card>
      </v-tab-item>
      <v-tab-item id="info">
        <v-card width="100%" height="100%" class="pa-8">
          <builder-creator-info-section
              :builder="builder"
              :additional-data="additionalData"
          />
        </v-card>
      </v-tab-item>
    </v-tabs-items>

  </div>
</template>
<script>
import BuilderCreatorTemplateSection
  from "@/ui/components/builder/use-cases/builder-creator/sections/BuilderCreatorTemplateSection.vue";
import {builderCreatorEvents} from "@/utils/events/builder-creator-events.js";
import BuilderCreatorInfoSection from "@/ui/components/builder/use-cases/builder-creator/sections/BuilderCreatorInfoSection.vue";
import {Builder} from "@/models/builder.js";
import {insuranceStepEnum} from "@/enums/insurance-step-enum.js";

export default {
  components: {BuilderCreatorInfoSection, BuilderCreatorTemplateSection},
  props: {
    builders: {
      type: Array,
      required: true,
    }
  },
  data: () => ({
    section: 'template',

    sections: ['template', 'info'],
    builder: Builder.empty(),
    additionalData: {
      name: null,
      priority: 1,
      step_type: insuranceStepEnum.RISK
    }
  }),
  created() {
    builderCreatorEvents.selectBuilderTemplate.on(this.onSelectTemplate)
  },
  destroyed() {
    builderCreatorEvents.selectBuilderTemplate.off(this.onSelectTemplate);
  },
  methods: {
    onChangeSection(index) {
      this.section = this.sections[index];
    },

    onSelectTemplate(builder) {
      this.builder = builder;

      this.additionalData.name = this.additionalData.name || builder.name

      this.$emit('onChangeBuilder', {
        builder: builder,
        additionalData: this.additionalData,
      })
    },
  }
}
</script>
