import {dateService} from "@/utils/services/date-service.js";

export class ExpiringToken {
    constructor({id, token, police_id, expires_at, activated_at, created_at}) {
        this.id = id;
        this.token = token;
        this.police_id = police_id;
        this.expires_at = expires_at;
        this.activated_at = activated_at;
        this.created_at = created_at;
    }

    static createEmpty() {
        return new ExpiringToken({
            id: null,
            token: '',
            police_id: null,
            expires_at: null,
            activated_at: null,
            created_at: null,
        })
    }

    isValid() {
        return  this.activated_at || dateService.getNowDate() < dateService.fromSQLToDateTime(this.expires_at)
    }
}