<template>
  <div class="addon-header text-left pl-1">
    <p class="app-text__bold addon-title">{{ title }}</p>
    <div class="addon-tooltip" v-if="tooltip">
      <question-tooltip>
        <div v-html="tooltip"></div>
      </question-tooltip>
    </div>
  </div>
</template>
<script>
import QuestionTooltip from "@/ui/components/common/tooltips/QuestionTooltip.vue";

export default {
  components: {QuestionTooltip},
  props: {
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
}
</script>