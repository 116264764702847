<template>
  <div :style="{width: '100%'}">
    <v-row>
      <v-col cols="3" class="pr-2 pl-4">
        <p class="app-text__bold mt-2">Element to change</p>

        <select-builder-element-popup-field
            class="width-100"
            :builder-elements="builderElements"
            :validation="{required: true}"
            v-model="builderElementAction.passive_element_id"
        />

      </v-col>
      <v-col cols="3" class="pr-2 pl-4">
        <p class="app-text__bold mt-2">Action</p>
        <searchable-select-field
            name="condition_type"
            :items="actions"
            :validation="{required: true}"
            v-model="builderElementAction.action"
            @saveField="onChangeAction"
        />
      </v-col>
      <v-col cols="3" class="pr-2 pl-4" v-if="isBuilderElementAction">
        <div>
          <p class="app-text__bold mt-2">{{ activeBuilderElementFieldTitle}}</p>

          <select-builder-element-popup-field
              class="width-100"
              :builder-elements="builderElements"
              :validation="{required: true}"
              v-model="builderElementAction.active_element_id"
          />
        </div>
      </v-col>
      <v-col cols="3" class="pr-2 pl-4" v-if="isStaticAction">
        <div>
          <p class="app-text__bold mt-2">{{ staticFieldTitle }}</p>
          <text-field
              name="value"
              v-model="builderElementAction.action_value"
          />
          <p v-if="valueHint">Format:</p>
          <p class="value-hint">{{ valueHint}}</p>
          <p v-if="valueHint">Example:</p>
          <p class="value-hint">{{ valueExample}}</p>
        </div>
      </v-col>
      <v-col cols="3" class="pr-2 pl-4" v-if="isPriceDataCondition">
        <div>
          <p class="app-text__bold mt-2">Price data</p>
          <searchable-select-field
              name="price_data_key"
              :items="priceDataKeys"
              :validation="{required: true}"
              v-model="builderElementAction.action_value"
          />
        </div>
      </v-col>
      <v-col cols="3" class="pr-2 pl-4" v-if="isCheckOnRepeatByCheckboxesAction">
        <div>
          <p class="app-text__bold mt-2">Select several checkboxes</p>
          <div class="d-flex">
            <v-col cols="9" >
              <select-builder-element-popup-field
                  class="width-100"
                  :builder-elements="builderElements"
                  v-model="addElementId"
              />
            </v-col>
            <v-col cols="3" class="pt-1 px-4">
              <blue-btn height="30px" @click="addCheckboxId">Add</blue-btn>
            </v-col>
          </div>

          <div v-if="builderElementActionData.data.checkboxes">
            <div :key="index" v-for="(element, index) in builderElementActionData.data.checkboxes">
              <div class="d-flex">
                <div class="app-text">
                  {{ element.name }}
                </div>

                <div class="mr-4">
                  <close-icon @click="onDeleteCheckboxIdClick(element.id)"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="3" v-if="isOptionsAction">
        <div>
          <p class="app-text__bold mt-2">{{ setOptionsTitle }}</p>
          <v-row>
            <v-col cols="9">
              <p class="app-text__bold">Add Value
                <question-tooltip>To set different key and value use pattern "text:value"</question-tooltip>
              </p>
              <text-field
                  name="value"
                  v-model="addOptionValue"
              />
            </v-col>

            <v-col cols="3" class="pt-11 px-4">
              <blue-btn :disabled="!addOptionValue" height="30px" @click="addToOptions">Add</blue-btn>
            </v-col>
          </v-row>
        </div>
        <div v-if="builderElementActionData.data.options && builderElementActionData.data.options.length > 0">
          <v-col cols="12" class="pl-2" v-for="(option, index) in builderElementActionData.data.options" :key="option[itemTextKey]">
            <div class="d-flex">
              <div class="app-text">
                {{ option[itemTextKey] }}
              </div>

              <div class="mr-4">
                <close-icon @click="onDeleteOptionClick(index)"/>
              </div>
            </div>

          </v-col>
        </div>
      </v-col>

      <v-col cols="3" class="pr-2 pl-4" v-if="isBuilderElementDateWithDateParamsCondition">
        <div>
          <p class="app-text__bold mt-2">Select date params: years, months, days</p>
          <div class="d-flex" >
            <v-col cols="3" >
              <number-field
                name="quantityYears"
                :value="quantityYears"
                :is-integer="true"
                @saveField="onChangeQuantityYears"
              />
            </v-col>
            <v-col cols="3" >
              <number-field
                name="quantityMonths"
                :value="quantityMonths"
                :is-integer="true"
                @saveField="onChangeQuantityMonths"
              />
            </v-col>
            <v-col cols="3" >
              <number-field
                name="quantityDays"
                :value="quantityDays"
                :is-integer="true"
                @saveField="onChangeQuantityDays"
              />
            </v-col>
          </div>

        </div>
      </v-col>

      <v-col cols="2" class="pl-4 mt-11 app-text__bold" >
        <checkbox
            text="RUN ON TRUE"
            :value="builderElementAction.run_on+''"
            @saveField="onChangeRunOn"
        />
      </v-col>
    </v-row>
    <div v-if="isMultipleDuplicate">
      <div class="mt-7">
        <v-row>
          <v-col cols="6" class="pr-2 pl-4">
            <p class="app-text__bold mt-2">Builder element name for repeat count number</p>
            <text-field
                name="value"
                v-model="builderElementActionData.data.repeatCountElementName"
            />
          </v-col>
          <v-col cols="6" class="pr-2 pl-4">
            <p class="app-text__bold mt-2">Builder element name for value to copy</p>
            <text-field
                name="value"
                v-model="builderElementActionData.data.valueFromElementName"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="pr-2 pl-4">
            <p class="app-text__bold mt-2">Builder element name for value to insert</p>
            <text-field
                name="value"
                v-model="builderElementActionData.data.valueToElementName"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-if="isSpecialDuplicateWithCopyAction || isSpecialDuplicateDataAction">
      <div class="mt-7">
        <v-row v-if="isSpecialDuplicateWithCopyAction">
          <v-col cols="6" class="pr-2 pl-4">
            <p class="app-text__bold mt-2">Title builder element name</p>
            <text-field
                name="value"
                v-model="builderElementActionData.data.titleFieldName"
            />
          </v-col>
          <v-col cols="6" class="pr-2 pl-4">
            <p class="app-text__bold mt-2">Title builder element value</p>
            <text-field
                name="value"
                v-model="builderElementActionData.data.titleFieldValue"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="pr-2 pl-4">
            <p class="app-text__bold mt-2">Builder element name (repeated block from origin section)</p>
            <text-field
                name="value"
                v-model="builderElementActionData.data.repeatedBlockFromName"
            />
          </v-col>
          <v-col cols="6" class="pr-2 pl-4">
            <p class="app-text__bold mt-2">Builder element name (repeated block from second section)</p>
            <text-field
                name="value"
                v-model="builderElementActionData.data.repeatedBlockToName"
            />
          </v-col>
        </v-row>
      </div>
      <div class="mt-7">
        <v-row>
          <v-col cols="3" class="pr-2 pl-4">
            <p class="app-text__bold mt-2">Builder elements mapping</p>
          </v-col>
          <v-col cols="2" class="pr-2 pl-4">
            <blue-btn height="30px" @click="addMappingItem()">Add</blue-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="pr-2 pl-4">
            <p class="app-text__bold mt-2">Builder element name from</p>
          </v-col>
          <v-col cols="5" class="pr-2 pl-4">
            <p class="app-text__bold mt-2">Builder element name to</p>
          </v-col>
        </v-row>
        <div :key="'mapping_item_' + index" v-for="(mappingItem, index) in builderElementActionData.data.fieldsMapping">
          <v-row>
            <v-col cols="5" class="pr-2 pl-4">
              <text-field
                  name="value"
                  v-model="mappingItem.nameFrom"
              />
            </v-col>
            <v-col cols="5" class="pr-2 pl-4">
              <text-field
                  name="value"
                  v-model="mappingItem.nameTo"
              />
            </v-col>
            <v-col cols="2" class="pr-2 pl-4">
              <delete-icon @click="deleteMappingItem(index)"/>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DeleteIcon from "@/ui/components/common/icons/DeleteIcon.vue";
import SearchableSelectField from "@/ui/components/common/fields/SearchableSelectField.vue";
import {builderElementRelationsService} from "@/utils/services/builder-element-relations-service.js";
import TextField from "@/ui/components/common/fields/TextField.vue";
import NumberField from "@/ui/components/common/fields/NumberField.vue";
import {BuilderElementRelationAction} from "@/models/builder-element-relations/builder-element-relation-action.js";
import SelectBuilderElementPopupField
  from "@/ui/components/builder/use-cases/builder-relations/SelectBuilderElementPopupField.vue";
import Checkbox from "@/ui/components/common/fields/Checkbox.vue";
import {
  builderElementRelationActionsEnum,
} from "@/enums/builder-element-relations-enum";
import QuestionTooltip from "@/ui/components/common/tooltips/QuestionTooltip.vue";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import CloseIcon from "@/ui/components/common/icons/CloseIcon";
import {INSURANCE_PRICE_LIST_FIELD_NAMES} from "@/utils/fields/insurance-price-list-field-names";

export default {
  components: {
    Checkbox, SelectBuilderElementPopupField, TextField, SearchableSelectField, QuestionTooltip, BlueBtn, CloseIcon, NumberField, DeleteIcon
  },
  props: {
    builderElementAction: {
      type: BuilderElementRelationAction,
      required: true,
    },
    actions: {
      type: Array,
      required: true,
    },
    builderElements: {
      type: Array,
      required: true,
    }
  },
  computed: {
    valueHint() {
      switch (this.builderElementAction.action) {
        case builderElementRelationActionsEnum.SET_VALIDATION_BY_SUM: return 'field1,field2,field3|operator|target value|error message';
        case builderElementRelationActionsEnum.SET_SUM_BY_FIELDS: return 'field1;field2;field3';
        case builderElementRelationActionsEnum.SET_VALIDATION_BY_SUM_MORE: return 'field1,field2,field3|is strong condition|target value|error message';
        case builderElementRelationActionsEnum.MULTIPLE_DUPLICATE: return 'Field name|Field title value|Max repeated blocks';
        case builderElementRelationActionsEnum.SET_IBAN_DATA: return 'swift code field;name of bank field';
      }

      return '';
    },
    valueExample() {
      switch (this.builderElementAction.action) {
        case builderElementRelationActionsEnum.SET_VALIDATION_BY_SUM: return '58 text_field,101 number_field,107 text_field|>=|5|Sum should be greater or equal than 5';
        case builderElementRelationActionsEnum.SET_SUM_BY_FIELDS: return '58 text_field;101 number_field;107 text_field';
        case builderElementRelationActionsEnum.SET_VALIDATION_BY_SUM_MORE: return '58 text_field,101 number_field,107 text_field|true|5|Sum should be greater or equal than 5';
        case builderElementRelationActionsEnum.MULTIPLE_DUPLICATE: return 'BS-80+81+82+83+84+85 label Betriebsstätte 1|Betriebsstätte|10';
        case builderElementRelationActionsEnum.SET_IBAN_DATA: return '250.2 Swift Code / BIC text_field;250.2 Name der Bank text_field';
        case builderElementRelationActionsEnum.ADD_SPECIAL_SYMBOLS_VALIDATION: return 'Example: /;&;#;';
      }

      return '';
    },
    options() {
      return this.builderElementActionData.data?.options || [];
    },
    itemTextKey() {
      return this.builderElementActionData.data?.itemTextKey || 'text';
    },
    activeBuilderElementFieldTitle() {
      if (this.isCheckOnRepeatByCheckboxesAction) {
        return 'Change title Element';
      }

      return 'Change by Element';
    },
    isPriceDataCondition() {
      return this.builderElementAction.action === builderElementRelationActionsEnum.SET_PRICE_TAB_VALUE;
    },
    isBuilderElementDateWithDateParamsCondition() {
      return this.builderElementAction.action === builderElementRelationActionsEnum.SET_BUILDER_ELEMENT_DATE_WITH_DATE_PARAMS ||
          this.builderElementAction.action === builderElementRelationActionsEnum.SET_CURRENT_DATE
    },
    quantityYears() {
      return this.builderElementActionData.data?.dateParams?.years || 0;
    },
    quantityMonths() {
      return this.builderElementActionData.data?.dateParams?.months || 0;
    },
    quantityDays() {
      return this.builderElementActionData.data?.dateParams?.days || 0;
    },
  },
  data: () => ({
    isStaticAction: false,
    isOptionsAction: false,
    isBuilderElementAction: false,
    isBuilderElementDateWithDateParamsAction: false,
    isCheckOnRepeatByCheckboxesAction: false,
    isSpecialDuplicateWithCopyAction: false,
    isSpecialDuplicateDataAction: false,
    isMultipleDuplicate: false,
    staticFieldTitle: 'Value',
    setOptionsTitle: 'Set options for select field',
    addOptionValue: '',
    addElementId: '',
    addValue: '',
    builderElementActionData: {},
    priceDataKeys: [
      INSURANCE_PRICE_LIST_FIELD_NAMES.sumInsured,
      INSURANCE_PRICE_LIST_FIELD_NAMES.deductible,
      INSURANCE_PRICE_LIST_FIELD_NAMES.duration,
      INSURANCE_PRICE_LIST_FIELD_NAMES.paymentPeriod,
      INSURANCE_PRICE_LIST_FIELD_NAMES.maxSumInsured,
    ],
  }),
  created() {
    this.initBuilderElementActionData()
    this.initSelectOptions()
    this.onChangeAction(this.builderElementActionData.action)
  },
  methods: {
    onChangeAction(actionType) {
      this.isStaticAction = this.checkOnStaticAction(actionType);
      this.isOptionsAction = this.checkOnSetOptionsAction(actionType);
      this.isBuilderElementAction = this.checkOnBuilderElementAction(actionType);
      this.isBuilderElementDateWithDateParamsAction = this.checkOnBuilderElementAction(actionType);
      this.isCheckOnRepeatByCheckboxesAction = this.checkOnRepeatByCheckboxesAction(actionType);
      this.isSpecialDuplicateWithCopyAction = this.checkOnSpecialDuplicateWithCopyAction(actionType);
      this.isMultipleDuplicate = this.checkOnMultipleDuplicateAction(actionType);
      this.isSpecialDuplicateDataAction = this.checkOnSpecialCopyDataAction(actionType);
      this.staticFieldTitle = this.getStaticFieldTitle(actionType);
    },

    initSelectOptions() {
      if (!this.builderElementActionData.data) {
        this.builderElementActionData.data = {};
      }

      if (!this.builderElementActionData.data.options) {
        this.builderElementActionData.data.options = [];
      }

      if (!this.builderElementActionData.data.dateParams) {
        this.builderElementActionData.data.dateParams = {};
      }

      if (!this.builderElementActionData.data.titleFieldName) {
        this.builderElementActionData.data.titleFieldName = ''
      }

      if (!this.builderElementActionData.data.titleFieldValue) {
        this.builderElementActionData.data.titleFieldValue = ''
      }

      if (!this.builderElementActionData.data.repeatCountElementName) {
        this.builderElementActionData.data.repeatCountElementName = ''
      }

      if (!this.builderElementActionData.data.valueFromElementName) {
        this.builderElementActionData.data.valueFromElementName = ''
      }

      if (!this.builderElementActionData.data.valueToElementName) {
        this.builderElementActionData.data.valueToElementName = ''
      }

      if (!this.builderElementActionData.data.repeatedBlockToName) {
        this.builderElementActionData.data.repeatedBlockToName = ''
      }

      if (!this.builderElementActionData.data.repeatedBlockFromName) {
        this.builderElementActionData.data.repeatedBlockFromName = ''
      }

      if (!this.builderElementActionData.data.fieldsMapping) {
        this.builderElementActionData.data.fieldsMapping = Array();

        this.builderElementActionData.data.fieldsMapping.push({
          nameFrom: '',
          nameTo: '',
        });
      }
    },

    getStaticFieldTitle(actionType) {
      return builderElementRelationsService.getStaticFieldTitle(actionType);
    },

    checkOnStaticAction(actionType) {
      return builderElementRelationsService.getStaticActions().includes(actionType);
    },

    checkOnSetOptionsAction(actionType) {
      return actionType === builderElementRelationActionsEnum.SET_SELECT_OPTIONS;
    },

    checkOnBuilderElementAction(actionType) {
      return builderElementRelationsService.getBuilderElementActions().includes(actionType);
    },

    checkOnRepeatByCheckboxesAction(actionType) {
      return builderElementRelationsService.getMultiBuilderElementActions().includes(actionType);
    },

    checkOnSpecialDuplicateWithCopyAction(actionType) {
      return builderElementRelationsService.getSpecialDuplicateWithCopyActions().includes(actionType);
    },

    checkOnMultipleDuplicateAction(actionType) {
      return builderElementRelationsService.getRepeatByDuplicateActions().includes(actionType);
    },

    checkOnSpecialCopyDataAction(actionType) {
      return builderElementRelationsService.getSpecialCopyDataActions().includes(actionType);
    },

    onChangeRunOn(value) {
      this.builderElementActionData.run_on = value;
    },

    addToOptions() {
      if (this.addOptionValue) {
        const arr = this.addOptionValue.split(':');

        const text = arr[0];
        const value = arr[1] || arr[0];

        this.builderElementActionData.data.options.push({text: text, value: value});
      }

      this.addOptionValue = '';
    },

    addCheckboxId() {
      if (this.addElementId) {
        this.builderElementActionData.data.checkboxes = this.builderElementActionData.data.checkboxes || [];

        const name = this.builderElements.find(itm => itm.id === this.addElementId)?.name || '';

        this.builderElementActionData.data.checkboxes.push({id: this.addElementId, name});
      }

      this.addElementId = null;
    },

    addMappingItem() {
      // todo: fix add and delete button click on create relation action
      this.builderElementActionData.data.fieldsMapping.push({nameFrom: "",nameTo: ""});
    },
    deleteMappingItem(index) {
      this.builderElementActionData.data.fieldsMapping.splice(index, 1);
    },

    onDeleteOptionClick(index) {
      this.builderElementActionData.data.options.splice(index, 1);
    },
    onDeleteCheckboxIdClick(index) {
      this.builderElementActionData.data.checkboxes.splice(index, 1);
    },
    initBuilderElementActionData() {
      this.builderElementActionData = this.builderElementAction
    },
    onChangeQuantityYears(val) {
      this.builderElementActionData.data.dateParams.years = val
    },
    onChangeQuantityMonths(val) {
      this.builderElementActionData.data.dateParams.months = val
    },
    onChangeQuantityDays(val) {
      this.builderElementActionData.data.dateParams.days = val
    },
  },
}
</script>
<style scoped>
.value-hint {
  color: #003787;
  margin-top: 10px;
}
</style>