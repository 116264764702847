<template>
  <v-radio-group
      class="app-radio__group"
      :value="value"
      :column="false"
      :rules="validationRules"
      @change="saveField"
  >
    <v-row>
      <v-col v-for="i in columnsArray" :key="i" :cols="12 / columns">
        <div v-for="(btn, index) in buttons" :key="index" class="d-flex">
          <v-radio
              v-if="index >= i * itemsPerColumn && index < (i+1) * itemsPerColumn"
              color="var(--light-blue)"
              :class="{'pt-4': !isFirstItemInColumn(index)}"
              :disabled="btn.disabled || disabled || false"
              :name="btn[itemValueKey]"
              :value="btn[itemValueKey]"
              :label="btn[itemTextKey] || ''"
              :ripple="false"
          />
          <div class="checkbox-tooltip ml-2" :class="{'pt-4': !isFirstItemInColumn(index)}" v-if="tooltipTexts[index]">
            <div class="tooltip-wrap">
              <exclamation-tooltip :font-size="'20px'">
                <div v-html="tooltipTexts[index]"></div>
              </exclamation-tooltip>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

  </v-radio-group>
</template>
<script>
import {required} from "@/utils/validation-rules";
import ExclamationTooltip from "@/ui/components/common/tooltips/ExclamationTooltip.vue";

export default {
  components: {ExclamationTooltip},
  props: {
    buttons: {
      required: true,
      type: Array,
    },
    value: {
      default: null,
    },
    columns: {
      type: Number,
      default: 1,
    },
    perColumn: {
      type: Number,
    },
    itemTextKey: {
      type: String,
      default: 'text',
    },
    itemValueKey: {
      type: String,
      default: 'value',
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    tooltips: {
      type: Array,
      default: () => ([]),
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    columnsArray() {
      return Array.from(Array(this.columns).keys())
    },

    itemsPerColumn() {
      return this.perColumn || this.buttons.length / this.columns;
    },

    validationRules() {
      const rules = [];

      if (this.validation.required && this.validation.required !== '0') {
        rules.push((val, message = this.validation.message || 'Pflichtfeld') => required(val, message));
      }

      return rules;
    },
    tooltipTexts() {
      return this.buttons.map((itm, index) => this.getTooltipText(index));
    }
  },

  methods: {
    isFirstItemInColumn(index) {
      return (index % this.itemsPerColumn) === 0;
    },

    saveField(value) {
      this.$emit('saveField', value, this.name);
      this.$emit('input', value);
    },

    getTooltipText(index) {
      const tooltipItem = this.tooltips.find((itm) => itm.optionIndex === index);

      return tooltipItem?.text || null
    },
  }
}
</script>