import {serverApi} from "@/api/server/server-api.js";
import {BuilderElement} from "@/models/builder-element.js";

export const builderEditorModule = {
    namespaced: true,
    actions: {
        getBuilderElement: async (context, {id}) => {
            const {data} = await serverApi.admin.builderEditor.getBuilderElement(id)

            return new BuilderElement(data.builderElement)
        },

        getBuilderElementSql: async (context, {builderElementId, newBuilderId}) => {
            const {data} = await serverApi.admin.builderEditor.getBuilderElementSql(builderElementId, newBuilderId)

            return data.sql;
        },

        createBuilderElement: async (context, {builderId, builderElement}) => {
            const {data} = await serverApi.admin.builderEditor.createBuilderElement(
                builderId,
                builderElement.toObject()
            );

            return new BuilderElement(data.builderElement);
        },

        copyBuilderElement: async (context, {id, name, priority, data}) => {
            const result = await serverApi.admin.builderEditor.copyBuilderElement(
                id,
                name,
                priority,
                data,
            )

            return new BuilderElement(result.data.builderElement);
        },

        updateBuilderElement: async (context, {id, data}) => {
            const result = await serverApi.admin.builderEditor.updateBuilderElement(id, data);

            return new BuilderElement(result.data.builderElement);
        },

        deleteBuilderElement: async (context, {id}) => {
            await serverApi.admin.builderEditor.deleteBuilderElement(id);
        },

        getBuilderElementTemplates: async () => {
            const {data} = await serverApi.admin.builderEditor.getBuilderElementsTemplates()

            return data.builderElements.map((elem) => new BuilderElement(elem));
        },

        copyBuilderElementTemplate: async (context, {builderId, builderElementId, additionalData}) => {
            const {data} = await serverApi.admin.builderEditor.copyBuilderElementTemplate(builderId, builderElementId, additionalData)

            return new BuilderElement(data.builderElement);
        },

        uploadFile: async (context, {builderElementId, file}) => {
            const {data} = await serverApi.admin.builderEditor.uploadFile(builderElementId, file)

            return data;
        },
    }
}