<template>
  <div class="px-4 pt-8 create-element__section">
    <v-form
        v-model="formValidation"
        lazy-validation
    >

      <div class="inputs-section">
        <p class="app-text__bold">Parent Block</p>

        <searchable-select-field
            name="parentElements"
            :items="parentElements"
            :value="element.parent_id"
            @saveField="onSelectParentElement"
        />

        <p class="app-text__bold">Select Type</p>

        <searchable-select-field
            name="parentsTypes"
            :items="elementTypes"
            :value="element.type"
            @saveField="onSelectElementType"
        />

        <builder-element-editor
            :builder-element="element"
        />
      </div>

    </v-form>

    <div class="button-section">
      <blue-btn
          v-if="element.type"
          class="float-right"
          @click="onCreateClick"
      >
        Create
      </blue-btn>

      <orange-btn
          v-if="element.type"
          class="float-right mr-2"
          @click="onCancelClick"
      >
        Cancel
      </orange-btn>
    </div>
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import BuilderElementEditor from "@/ui/components/builder/use-cases/builder-editor/BuilderElementEditorFactory.vue";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import {Builder} from "@/models/builder.js";
import {builderElementService} from "@/utils/services/builder-element-service.js";
import {builderEditorEvents} from "@/utils/events/builder-editor-events.js";
import {builderElementsLabels} from "@/enums/builder-element-enum.js";
import OrangeBtn from "@/ui/components/common/buttons/OrangeBtn.vue";
import {notificationService} from "@/utils/services/notification-service.js";
import SearchableSelectField from "@/ui/components/common/fields/SearchableSelectField.vue";
import {builderElementsEvents} from "@/utils/events/builder-elements-events";

export default {
  components: {SearchableSelectField, OrangeBtn, BlueBtn, BuilderElementEditor},
  props: {
    builder: {
      type: Builder,
      required: true,
    },
  },
  computed: {
    parentElements() {
      return builderElementService.findOnlyParents(this.builder.elements).map((elem) => ({
        text: elem.name,
        value: elem.id
      }))
    },

    elementTypes() {
      return builderElementsLabels
    },
  },
  data: () => ({
    element: BuilderElement.empty({
      id: Math.random(),
      parent_id: null,
    }),

    uploadedFile: null,

    formValidation: false,

    parent: null,
  }),
  created() {
    this.resetElement()
  },
  mounted() {
    builderElementsEvents.editorLoadFile.on(this.onLoadFile)
  },
  async beforeDestroy() {
    this.onCancelClick();

    builderElementsEvents.editorLoadFile.off(this.onLoadFile)
  },
  methods: {
    onSelectParentElement(parentId) {
      this.removePreviousElement();

      this.parent = builderElementService.findElementById(this.builder.elements, parentId)

      this.resetElement();
    },

    resetElement() {
      this.element = BuilderElement.empty({
        id: builderElementService.generateName(), //generate random id
        is_show: 1,
        parent_id: this.parent ? this.parent.id : null,
      });

      this.uploadedFile = null;
    },

    onSelectElementType(type) {
      this.removePreviousElement();

      this.resetElement();

      this.element.type = type;
      this.element.name = builderElementService.generateName();

      builderEditorEvents.addElementToList.emit(this.element);
      //set timeout is required, element selection not working without that
      setTimeout(() => builderEditorEvents.selectElement.emit(this.element.id), 150);
    },

    removePreviousElement() {
      builderEditorEvents.removeElementFromList.emit(this.element.id)
    },

    unselectParentElement() {
      this.parent = null
    },

    onCancelClick() {
      this.removePreviousElement();
      this.unselectParentElement();
      this.resetElement();

      builderEditorEvents.unselectElement.emit();
    },

    async onCreateClick() {
      if (this.formValidation) {
        const createdElement = await this.performCreate(this.builder, this.element);

        if (this.uploadedFile !== null) {
          createdElement.data.link = await this.uploadFile(this.uploadedFile, createdElement);

          await this.updateElement(createdElement.id, createdElement);
        }

        this.unselectParentElement();
        this.resetElement();

        notificationService.success("Element created")
      }
    },

    async performCreate(builder, element) {
      const builderElement = await this.createElement(builder.id, element);

      builderEditorEvents.removeElementFromList.emit(element.id)
      builderEditorEvents.addElementToList.emit(builderElement);

      builderEditorEvents.elementSaved.emit(builderElement);

      return builderElement;
    },

    createElement(builderId, builderElement) {
      return this.$store.dispatch('admin/builderEditorModule/createBuilderElement', {
        builderId,
        builderElement,
      });
    },

    onLoadFile({file}) {
      this.uploadedFile = file;
    },

    async uploadFile(file, builderElement) {
      const {url} = await this.$store.dispatch('admin/builderEditorModule/uploadFile', {
        builderElementId: builderElement.id,
        file,
      });

      return url;
    },

    updateElement(id, data) {
      return this.$store.dispatch('admin/builderEditorModule/updateBuilderElement', {
        id,
        data
      })
    },
  },
}
</script>
<style scoped>
.inputs-section {
  overflow-y: auto;
  height: 550px;
}

.button-section {
  position: absolute;
  right: 2rem;
  bottom: 25px;
  background: white;
}
</style>