export const setValidationBySumAction = ({passiveElement, action_value, context}) => {
    const arr = action_value.split('|');

    const fieldNames = arr[0] ? arr[0].split(',') : [];
    const operation = arr[1] || '';
    const targetValue = arr[2] || 0;
    const message = arr[3] || '';

    passiveElement.validation = {
        ...passiveElement.validation,
        bySum: {
            fieldNames,
            operation,
            targetValue,
            passiveElement,
            message
        }
    }

    context.dispatch('insuranceModule/builderElementsModule/saveElement', {
        builderElement: passiveElement
    }, {root: true})
}