<template>
  <addon-wrapper>
    <addon-header :title="addon.name" :tooltip="addon.details.tooltip"/>

    <addon-checked-text
        class="mt-4"
        v-for="(info, index) in addon.details.info"
        :key="index"
    >
      {{ info }}
    </addon-checked-text>

    <addon-price
        v-if="showPrice"
        :price="price"
        :period="period"
    />

    <addon-button
        class="mt-4"
        :addon-id="addon.id"
        :selected="isActive"
        :disabled="isDisabled"
        @clickUnselectedBtn="clickUnselectedBtn"
        @clickSelectedBtn="clickSelectedBtn"
    />

    <simple-popup
        v-if="popupText && popupButtonText && showPopup"
        :header="''"
        :is-show-close-button="false"
        @close="onClose">
      <v-row>
        <v-col cols="12">
          <p>{{ popupText }}</p>
        </v-col>
        <div class="d-flex">
          <white-btn class="mr-2" @click="onClose">{{ popupButtonText}}</white-btn>
        </div>
      </v-row>
    </simple-popup>
  </addon-wrapper>
</template>
<script>
import AddonHeader from "@/ui/components/insurance/insurance-addons/elements/AddonHeader.vue";
import AddonWrapper from "@/ui/components/insurance/insurance-addons/elements/AddonWrapper.vue";
import AddonCheckedText from "@/ui/components/insurance/insurance-addons/elements/AddonCheckedText.vue";
import AddonPrice from "@/ui/components/insurance/insurance-addons/elements/AddonPrice.vue";
import AddonButton from "@/ui/components/insurance/insurance-addons/elements/AddonButton.vue";
import {InsurancePriceAddon} from "@/models/insurance-price-addon.js";
import WhiteBtn from "@/ui/components/common/buttons/WhiteBtn.vue";
import SimplePopup from "@/ui/components/common/popup/SimplePopup.vue";

export default {
  components: {SimplePopup, WhiteBtn, AddonButton, AddonPrice, AddonCheckedText, AddonWrapper, AddonHeader},
  props: {
    addon: {
      type: InsurancePriceAddon,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
    showPrice: {
      type: Boolean,
      default: true,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  computed: {
    isActive() {
      return Boolean(this.addon.is_active)
    },
    popupText() {
      return this.addon.details?.popupText || '';
    },
    popupButtonText() {
      return this.addon.details?.popupButtonText || '';
    },
    price() {
      return this.addon.price.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'})
    },
    isDisabled() {
      return this.disabled || Boolean(this.addon.details.isDisabled)
    },
  },
  data() {
    return {
      showPopup: false,
    }
  },
  methods: {
    onClose() {
      this.showPopup = false;
    },
    async clickUnselectedBtn(addonId) {
      await this.selectAddon(addonId)

      this.showPopup = true;

      this.$emit('changeAddon')
    },
    async clickSelectedBtn(addonId) {
      await this.unselectAddon(addonId)

      this.$emit('changeAddon')
    },
    async selectAddon(insurancePriceAddonId) {
      await this.$store.dispatch('insuranceModule/insurancePriceAddonModule/selectAddon', {
        insurancePriceAddonId
      })
    },
    async unselectAddon(insurancePriceAddonId) {
      await this.$store.dispatch('insuranceModule/insurancePriceAddonModule/unselectAddon', {
        insurancePriceAddonId
      })
    },
  },
}
</script>