<template>
  <div>
    <div>
      <p class="app-text__bold">{{ title }}</p>

      <text-field
          name="name"
          :value="value"
          @saveField="saveValidationMessage"
      />
    </div>
  </div>
</template>
<script>
import TextField from "@/ui/components/common/fields/TextField.vue";
export default {
  components: {TextField},
  props: {
    title: {
      type: String,
      default: 'Validation Message',
    },
    value: {
      type: String,
      default: 'var(--white-bg)',
    },
  },
  methods: {
    saveValidationMessage(value) {
      this.$emit('input', value)
      this.$emit('saveValidationMessage', value)
    }
  }
}
</script>