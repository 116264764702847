<template>
  <insurance-packages-wrapper>
    <template slot="left-side">
      <insurance-value-field
          :value="sumInsured"
          :items="sumInsuredLabelList"
          :is-disabled="isDisableInsuranceValueField"
          :is-show-question-tooltip="isShowInsuranceValueQuestionTooltip"
          :question-tooltip="insuranceValueQuestionTooltip"
          :use-wrap-css-class="!isShowNoticeLimitationLiability"
          :labelName="sumInsuredLabelName"
          @changeSumInsured="changeSumInsured"
      />
      <span v-if="isShowNoticeLimitationLiability" class="white--text app-text font-s-12">
        <check-icon v-if="isNoticeLimitationLiability.value" size="15"/>
        <close-icon v-if="isNotNoticeLimitationLiability" size="15"/>
        {{ noticeLimitationLiabilityTitle }}
      </span>

      <insurance-max-value-field
          v-if="!isBabyContract && !isCompanyClosure"
          class="pb-6"
          :value="maxSumInsured"
          :items="maxSumInsuredLabelList"
          :is-disabled-max-sum-insured="isDisableMaxSumInsuranceField"
          :is-show-question-tooltip="isShowMaxSumInsuredQuestionTooltip"
          @changeMaxSumInsured="changeMaxSumInsured"
      />

      <is-higher-maximization-field
          v-if="isHigherMaximization.isShow"
          :value="isHigherMaximization.value"
          @changeIsHigherMaximization="changeIsHigherMaximization"
      />

      <higher-maximization-field
          v-if="higherMaximization.isShow"
          :value="higherMaximization.value"
          :disabled="isDisableHigherMaximizationField"
          @changeHigherMaximization="changeHigherMaximization"
      />
    </template>

    <template slot="center">
      <single-package
          per-year="2.200,50"
          :per-period-price="perPeriodPrice"
          :period="paymentPeriod"
          :insurance-price="insurancePrice.getPrice()"
          :is-npopt="insurancePrice.getIsNpopt()"
          :insurance-full-name="insuranceFullName"
      />
    </template>

    <template slot="right-side">
      <deductible-field
        v-if="!isCompanyClosure"
          class="pb-6"
          :value="deductible"
          :items="deductibleLabelList"
          :is-disabled="isDisableDeductibleField"
          :tooltip="deductibleTooltip.value"
          :is-show-tooltip="deductibleTooltip.isShow"
          @changeDeductible="changeDeductible"
      />

      <duration-field
          class="pb-6"
          :value="duration"
          :items="durationLabelList"
          :is-disabled="isDisableDurationField"
          @changeDuration="changeDuration"
      />

      <payment-period-field
          :value="paymentPeriod"
          :items="paymentPeriodLabelList"
          :is-disabled="isDisablePaymentPeriodField"
          :tooltip="paymentPeriodTooltip"
          @changePaymentPeriod="changePaymentPeriod"
      />
      <notice-limitation-liability-editor
          v-if="showNoticeLimitationPopup"
          :content="noticeLimitationContent"
          @confirmNoticeLimitation="onConfirmNoticeLimitationPopup"
          @close="onCloseNoticeLimitationPopup"
      />
    </template>
  </insurance-packages-wrapper>
</template>

<script>
import InsurancePackagesWrapper from "@/ui/components/insurance/insurance-wrappers/InsurancePackagesWrapper.vue";
import InsuranceValueField from "@/ui/components/insurance/insurance-fields/InsuranceValueField.vue";
import CheckIcon from "@/ui/components/common/icons/CheckIcon.vue";
import InsuranceMaxValueField from "@/ui/components/insurance/insurance-fields/InsuranceMaxValueField.vue";
import SinglePackage from "@/ui/components/insurance/insurance-packages/SinglePackage.vue";
import DeductibleField from "@/ui/components/insurance/insurance-fields/DeductibleField.vue";
import DurationField from "@/ui/components/insurance/insurance-fields/DurationField.vue";
import PaymentPeriodField from "@/ui/components/insurance/insurance-fields/PaymentPeriodField.vue";
import {arrayService} from "@/utils/services/array-service.js";
import {INSURANCE_PRICE_LIST_FIELD_NAMES, INSURANCE_PRICE_DATA_KEYS, INSURANCE_PRICE_PAYMENT_PERIOD_LABELS, PERIOD_VALUES} from "@/utils/fields/insurance-price-list-field-names.js";
import CloseIcon from "@/ui/components/common/icons/CloseIcon.vue";
import NoticeLimitationLiabilityEditor from "@/ui/components/insurance/insurance-prices/blocks/NoticeLimitationLiabilityEditor.vue";
import HigherMaximizationField from "@/ui/components/insurance/insurance-fields/HigherMaximizationField.vue";
import IsHigherMaximizationField from "@/ui/components/insurance/insurance-fields/IsHigherMaximizationField.vue";
import {insuranceStepService} from "@/utils/services/insurance-step-service.js";
import {insuranceStepEnum} from "@/enums/insurance-step-enum.js";
import {maxValPaymentPeriod} from "@/utils/data/insurance-price.js";
import {capitalizeFirstLetter, getBooleanValue, prepareInsuranceFullName} from "@/utils/helpers.js";
import {moneyService} from "@/utils/services/money-service.js";
import {insuranceTypeEnum} from "@/enums/insurance-type-enum.js"
import {defaultInsuranceValueTooltip, corporateGovernanceInsuranceValueTooltip} from "@/utils/data/insurance-price-data-tooltip.js"
import {builderElementService} from "@/utils/services/builder-element-service";
import {
  NACHLASSRISIKEN,
  VORMUNDSCHAFT,
  ZUSAMMENSCHLUSSGESELLSCHAFT,
} from "@/enums/fields/bankruptcy-risk/field-names";

export default {
  components: {
    InsurancePackagesWrapper,
    InsuranceValueField,
    CheckIcon,
    InsuranceMaxValueField,
    SinglePackage,
    DeductibleField,
    DurationField,
    PaymentPeriodField,
    CloseIcon,
    NoticeLimitationLiabilityEditor,
    HigherMaximizationField,
    IsHigherMaximizationField,
  },
  props: {
    insuranceId: {
      type: Number,
      required: true,
    },
    isDisableAllInputs: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    allBuilderElements() {
      return this.$store.getters["insuranceModule/builderElementsModule/getAllBuilderElements"];
    },
    isSelectedCheckboxes() {
      const checkboxes = builderElementService.findAllElementsByParameters(
        this.allBuilderElements,
        'name',
        [
          VORMUNDSCHAFT,
          NACHLASSRISIKEN,
          ZUSAMMENSCHLUSSGESELLSCHAFT,
        ]
      );

      const selectedCheckboxes = checkboxes.filter(item => getBooleanValue(item.value));

      return selectedCheckboxes.length > 0;
    },
    insurancePrice() {
      return this.$store.getters['insuranceModule/insurancePriceModule/getInsurancePrice'];
    },
    durationList() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getPriceListsByKey"](INSURANCE_PRICE_LIST_FIELD_NAMES.duration);
    },
    deductibleList() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getPriceListsByKey"](INSURANCE_PRICE_LIST_FIELD_NAMES.deductible);
    },
    sumInsuredList() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getPriceListsByKey"](INSURANCE_PRICE_LIST_FIELD_NAMES.sumInsured);
    },
    maxSumInsuredList() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getPriceListsByKey"](INSURANCE_PRICE_LIST_FIELD_NAMES.maxSumInsured);
    },
    paymentPeriodList() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getPriceListsByKey"](INSURANCE_PRICE_LIST_FIELD_NAMES.paymentPeriod);
    },
    durationLabelList() {
      return this.durationList;
    },
    deductibleLabelList() {
      return moneyService.optionsToIntegerMoneyFormat(this.deductibleList);
    },
    sumInsuredLabelList() {
      return moneyService.optionsToMoneyFormat(this.sumInsuredList);
    },
    maxSumInsuredLabelList() {
      return this.maxSumInsuredList;
    },
    paymentPeriodLabelList() {
      return this.getOptionsForPaymentPeriod()
    },
    isNotNoticeLimitationLiability() {
      return this.isNoticeLimitationLiability.value === false
    },
    perPeriodPrice() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getPerPeriodPrice"];
    },
    higherMaximizationList() {
      return this.$store.getters["insuranceModule/insurancePriceModule/getPriceListsByKey"](INSURANCE_PRICE_LIST_FIELD_NAMES.higherMaximization);
    },
    stepType() {
      return insuranceStepEnum.CUSTOMER
    },
    insuranceBuilders() {
      return this.$store.getters["insuranceModule/insuranceBuildersModule/getInsuranceStepBuilders"](this.stepType);
    },
    activeInsuranceBuilder() {
      return insuranceStepService.getActiveInsuranceBuilder(this.insuranceBuilders)
    },
    isBabyContract() {
      return Boolean(this.$store.getters["insuranceModule/insurancePriceModule/getIsBabyContract"]);
    },
    insuranceFullName() {
      return prepareInsuranceFullName(this.$store.getters["insuranceModule/insurancePriceModule/getInsuranceFullName"].replace(/,/g, ',<br>'))
    },
    insuranceType() {
      return this.$store.getters['insuranceModule/getInsuranceType'];
    },
    isDisableDurationField() {
      if (this.is58RadioButton && !this.isDisableAllInputs) {
        return false;
      }

      return this.isBabyContract || this.isDisableAllInputs
    },

    isDisableInsuranceValueField() {
      return this.isDisableAllInputs || this.isBabyContract
    },

    isDisableMaxSumInsuranceField() {
      return this.isDisableAllInputs || this.maxSumInsuredDisabled
    },

    isDisableHigherMaximizationField() {
      return this.isDisableAllInputs
    },

    isDisableDeductibleField() {
      return this.isDisableAllInputs || this.isBabyContract
    },

    isDisablePaymentPeriodField() {
      return this.isDisableAllInputs || this.isBabyContract
    },

    isShowInsuranceValueQuestionTooltip() {
      return this.insuranceType.key !== insuranceTypeEnum.FINANCIAL_INVESTMENTS &&
          this.insuranceType.key !== insuranceTypeEnum.NON_PROFIT_ORGANIZATIONS &&
          this.insuranceType.key !== insuranceTypeEnum.MEDIA_PROFESSIONS &&
          this.insuranceType.key !== insuranceTypeEnum.REAL_ESTATE_SERVICES &&
          this.insuranceType.key !== insuranceTypeEnum.SERVICE_COMPANY &&
          this.insuranceType.key !== insuranceTypeEnum.COMPANY_CLOSURE &&
          !this.isSelectedCheckboxes;
    },
    isShowMaxSumInsuredQuestionTooltip() {
      return this.insuranceType.key === insuranceTypeEnum.CORPORATE_GOVERNANCE
    },
    insuranceValueQuestionTooltip() {
      if (this.insuranceType.key === insuranceTypeEnum.CORPORATE_GOVERNANCE) {
        return corporateGovernanceInsuranceValueTooltip
      }

      return defaultInsuranceValueTooltip
    },
    noticeLimitationContent() {
      if (this.insuranceType.key === insuranceTypeEnum.CORPORATE_GOVERNANCE) {
        return 'Achtung! Die gewünschte Versicherungssumme darf nicht höher sein als die Versicherungssumme der Unternehmens-D&O.'
      }

      return `Wenn mit Haftungsbeschränkungen im Rahmen von vorformulierten Vertragsbedingungen z.B.
                  Allgemeinen Auftragsbedingungen gearbeitet wird, empfehlen wir für die Wirksamkeit die 4-fache gesetzlich
                  vorgeschriebene Mindestversicherungssumme.`
    },
    noticeLimitationLiabilityTitle() {
      if (this.insuranceType.key === insuranceTypeEnum.CORPORATE_GOVERNANCE) {
        return 'Hinweis zur Kenntnis genommen'
      }

      return 'Hinweis Haftungsbeschränkung'
    },
    isShowNoticeLimitationLiability() {
      return !this.isBabyContract &&
        this.isNoticeLimitationLiability.isShow &&
        this.insuranceType.key !== insuranceTypeEnum.REAL_ESTATE_SERVICES &&
        this.insuranceType.key !== insuranceTypeEnum.NON_PROFIT_ORGANIZATIONS &&
        this.insuranceType.key !== insuranceTypeEnum.MEDIA_PROFESSIONS &&
        this.insuranceType.key !== insuranceTypeEnum.SERVICE_COMPANY &&
        this.insuranceType.key !== insuranceTypeEnum.FINANCIAL_INVESTMENTS &&
        this.insuranceType.key !== insuranceTypeEnum.COMPANY_CLOSURE &&
        !this.isSelectedCheckboxes
    },
    isCompanyClosure() {
      return this.insuranceType.key === insuranceTypeEnum.COMPANY_CLOSURE;
    },
    sumInsuredLabelName() {
      return this.insuranceType.key === insuranceTypeEnum.COMPANY_CLOSURE ? 'Versicherte Anzahl Schließungstage' : 'Versicherungssumme'
    },
  },
  data: () => ({
    paymentPeriod: '',
    duration: '',
    deductible: '',
    sumInsured: '',
    priceData: {
      data: {},
    },
    maxSumInsured: '',
    isNoticeLimitationLiability: {value: null, isShow: true},
    showNoticeLimitationPopup: false,
    higherMaximization: {},
    isHigherMaximization: {},
    maxSumInsuredDisabled: false,
    paymentPeriodTooltip: '',
    is58RadioButton: false,
    deductibleTooltip: {value: '', isShow: true},
  }),
  async created() {
    this.showProcessPreloader();
    await this.getInsurancePrice(this.insuranceId)
    this.fillData();
    this.hideProcessPreloader();
  },
  methods: {
    showProcessPreloader() {
      this.$store.commit('insuranceModule/showProcessPreloader')
    },
    hideProcessPreloader() {
      this.$store.commit('insuranceModule/hideProcessPreloader')
    },
    fillData() {
      this.duration = this.insurancePrice.getDurationLabel()
      this.deductible = this.insurancePrice.getDeductibleLabel()
      this.sumInsured = this.insurancePrice.getSumInsuredLabel()
      this.maxSumInsured = this.insurancePrice.getMaxSumInsuredLabel()
      this.paymentPeriod = this.insurancePrice.getPaymentPeriodLabel()
      this.priceData.data = this.insurancePrice.getData()
      this.isNoticeLimitationLiability = this.insurancePrice.getIsNoticeLimitationLiability() ?? {value: null, isShow: false}
      this.is58RadioButton = this.priceData.data.is58RadioButton

      this.higherMaximization = this.insurancePrice.getHigherMaximization() ?? {}

      this.isHigherMaximization = this.insurancePrice.getIsHigherMaximization() ?? {}
      this.maxSumInsuredDisabled = !this.insurancePrice.getEditableByFieldKey(INSURANCE_PRICE_LIST_FIELD_NAMES.maxSumInsured)

      this.deductibleTooltip.value = this.insurancePrice.getDeductibleTooltip()?.value
      this.deductibleTooltip.isShow = this.insurancePrice.getDeductibleTooltip()?.isShow

      this.fillPaymentPeriod()

      this.paymentPeriodTooltip = 'Im Falle einer gewünschten unterjährigen Zahlungsweise kann nur das SEPA-Lastschriftmandat als Zahlungsart angeboten werden.';

    },
    async getInsurancePrice(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/getInsurancePriceByInsuranceId', {
        insuranceId,
      })
    },
    async changeSumInsured(val) {
      this.showProcessPreloader();
      this.setPriceDataElem(INSURANCE_PRICE_LIST_FIELD_NAMES.sumInsured, val)

      await this.updateInsurancePrice(this.insurancePrice.getId(), this.priceData, INSURANCE_PRICE_LIST_FIELD_NAMES.sumInsured);

      if (
        this.isNoticeLimitationLiability.value === null &&
        this.isNoticeLimitationLiability.isShow &&
        this.insuranceType.key !== insuranceTypeEnum.REAL_ESTATE_SERVICES &&
        this.insuranceType.key !== insuranceTypeEnum.NON_PROFIT_ORGANIZATIONS &&
        this.insuranceType.key !== insuranceTypeEnum.MEDIA_PROFESSIONS &&
        this.insuranceType.key !== insuranceTypeEnum.SERVICE_COMPANY &&
        this.insuranceType.key !== insuranceTypeEnum.FINANCIAL_INVESTMENTS &&
        !this.isSelectedCheckboxes
      ) {
        this.showNoticeLimitationPopup = true
      }

      this.maxSumInsured = this.insurancePrice.getMaxSumInsuredLabel()

      await this.calculatePrice(this.insuranceId)

      this.setPriceDataElem(INSURANCE_PRICE_LIST_FIELD_NAMES.maxSumInsured, this.maxSumInsured)
      this.hideProcessPreloader();
    },
    async changeDuration(val) {
      this.showProcessPreloader();

      this.setPriceDataElem(INSURANCE_PRICE_LIST_FIELD_NAMES.duration, val)
      await this.updateInsurancePrice(this.insurancePrice.getId(), this.priceData)

      await this.calculatePrice(this.insuranceId)

      this.fillPaymentPeriod()

      //try to use builder relation instead it
      //await this.updateDurationBuilderElement(this.insuranceId, val)

      this.hideProcessPreloader();
    },
    async changeDeductible(val) {
      this.showProcessPreloader();
      this.setPriceDataElem(INSURANCE_PRICE_LIST_FIELD_NAMES.deductible, val)
      await this.updateInsurancePrice(this.insurancePrice.getId(), this.priceData)

      await this.calculatePrice(this.insuranceId)
      this.hideProcessPreloader();
    },
    async changePaymentPeriod(val) {
      this.showProcessPreloader();

      this.setPriceDataElem(INSURANCE_PRICE_LIST_FIELD_NAMES.paymentPeriod, val)
      await this.updateInsurancePrice(this.insurancePrice.getId(), this.priceData)
      this.paymentPeriod = val

      await this.calculatePrice(this.insuranceId)

      await this.updatePaymentPeriodBuilderElement(this.insuranceId, val)

      this.hideProcessPreloader();
    },
    async changeMaxSumInsured(val) {
      this.showProcessPreloader();
      this.setPriceDataElem(INSURANCE_PRICE_LIST_FIELD_NAMES.maxSumInsured, val)
      await this.updateInsurancePrice(this.insurancePrice.getId(), this.priceData)
      this.maxSumInsured = val
      await this.calculatePrice(this.insuranceId)
      this.hideProcessPreloader();
    },
    async changeHigherMaximization(val) {
      this.showProcessPreloader();

      this.setPriceDataElem(INSURANCE_PRICE_LIST_FIELD_NAMES.higherMaximization, {value: val, isShow: true})
      await this.updateInsurancePrice(this.insurancePrice.getId(), this.priceData)

      this.hideProcessPreloader();
    },
    async changeIsHigherMaximization(val) {
      this.showProcessPreloader();
      this.setPriceDataElem(INSURANCE_PRICE_LIST_FIELD_NAMES.isHigherMaximization, {value: val, isShow: true})
      await this.updateInsurancePrice(this.insurancePrice.getId(), this.priceData)
      this.hideProcessPreloader();
    },
    findFieldByValue(arr, val) {
      return arrayService.findObjectByLabel(arr, val)
    },
    async updateInsurancePrice(insurancePriceId, requestData, changedElement){
      await this.$store.dispatch('insuranceModule/insurancePriceModule/updatePrice', {
        insurancePriceId,
        requestData,
        changedElement
      })
    },
    setPriceDataElem(key, val) {
      this.priceData.data[key] = val
    },
    onCloseNoticeLimitationPopup() {
      this.setPriceDataElem(INSURANCE_PRICE_DATA_KEYS.isNoticeLimitationLiability, {value: false, isShow: this.isNoticeLimitationLiability.isShow})

      this.updateInsurancePrice(this.insurancePrice.getId(), this.priceData)
      this.showNoticeLimitationPopup = false

      this.isNoticeLimitationLiability.value = false
    },
    onConfirmNoticeLimitationPopup() {
      this.setPriceDataElem(INSURANCE_PRICE_DATA_KEYS.isNoticeLimitationLiability, {value: true, isShow: this.isNoticeLimitationLiability.isShow})

      this.updateInsurancePrice(this.insurancePrice.getId(), this.priceData)

      this.showNoticeLimitationPopup = false

      this.isNoticeLimitationLiability.value = true
    },
    async calculatePrice(insuranceId) {
      await this.$store.dispatch('insuranceModule/insurancePriceModule/calculatePrice', {
        insuranceId,
      })
    },
    async updatePaymentPeriodBuilderElement(insuranceId, value) {
      await this.$store.dispatch('insuranceModule/builderElementsModule/updatePaymentPeriodBuilderElement', {
        insuranceId,
        value,
      })

      await this.resetBuilder()
    },
    async updateDurationBuilderElement(insuranceId, value) {
      await this.$store.dispatch('insuranceModule/builderElementsModule/updateDurationBuilderElement', {
        insuranceId,
        value,
      })

      await this.resetBuilder()
    },
    async resetBuilder() {
      await this.$store.dispatch('insuranceModule/insuranceBuildersModule/resetInsuranceBuilders', {
        insuranceBuilder: this.activeInsuranceBuilder,
      })
    },
    getOptionsForPaymentPeriod() {
      const price = this.insurancePrice.getPrice()

      const periods = []

      this.paymentPeriodList.forEach(period => {
        const newPeriodObj = {text: capitalizeFirstLetter(period), value: period, disabled: false}

        const periodVal = PERIOD_VALUES[period]

        if (price / periodVal < maxValPaymentPeriod && this.insuranceType.key !== insuranceTypeEnum.CORPORATE_GOVERNANCE) {
          newPeriodObj.disabled = true
        }

        periods.push(newPeriodObj)
      })

      return periods
    },
    fillPaymentPeriod() {
      if (!this.insurancePrice.getPrice()) {
        return
      }

      if (this.perPeriodPrice < maxValPaymentPeriod) {
        this.changePaymentPeriod(INSURANCE_PRICE_PAYMENT_PERIOD_LABELS.year)
      }
    },
  },
}
</script>