<template>
<div>
  <div class="text-center">
    <h3>Button</h3>
  </div>

  <v-row>
    <v-col cols="12">
      <name-editor
          v-model="builderElement.name"
      />
    </v-col>

    <v-col cols="4">
      <priority-editor
          v-model.number="builderElement.priority"
      />
    </v-col>

    <v-col class="pl-4" cols="3">
      <width-editor
          v-model="builderElement.data.width"
      />
    </v-col>

    <v-col class="pl-4" cols="3">
      <height-editor
          v-model="builderElement.data.height"
      />
    </v-col>
  </v-row>

  <div class="pb-2 row">
    <div class="col-4">
      <p class="app-text__bold">Title</p>
      <text-field
          name="title"
          v-model="builderElement.data.title"
      />
    </div>
    <div class="pl-3 col-4">
      <p class="app-text__bold">Value</p>
      <text-field
          name="value"
          v-model="builderElement.value"
      />
    </div>

  </div>

  <margin-editor
      v-model="builderElement.data.margin"
  />

  <padding-editor
      class="mt-4"
      v-model="builderElement.data.padding"
  />

  <id-editor
      class="mt-6"
      v-model="builderElement.data.id"
  />

  <css-class-editor
      class="mt-6"
      v-model="builderElement.data.css_class"
  />
</div>
</template>

<script>
import {BuilderElement} from "@/models/builder-element.js";
import WidthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/WidthEditor.vue";
import HeightEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/HeightEditor.vue";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import MarginEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MarginEditor.vue";
import PaddingEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PaddingEditor.vue";
import IdEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/IdEditor.vue";
import CssClassEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/CssClassEditor.vue";
import TextField from "@/ui/components/common/fields/TextField.vue";

export default {
  name: "ButtonElementEditor",
  components: {PaddingEditor, MarginEditor, PriorityEditor, NameEditor, WidthEditor, IdEditor, CssClassEditor, TextField, HeightEditor},
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  },
}
</script>
