<template>
  <app-block>
    <div class="app-container">
      <h3 class="ml-4 mt-4">{{ header }}</h3>

      <div class="price-wrapper__margin">
        <app-block
          class="price-content__margin"
          color="var(--light-blue)"
        >
          <v-row>
            <v-col class="pr-8 app-text white--text">
              <slot name="left-side"/>
            </v-col>
            <v-col cols="5">
              <slot name="center"/>
            </v-col>
            <v-col class="pl-8 app-text white--text right-side-col">
              <slot name="right-side"/>
            </v-col>
          </v-row>

        </app-block>
      </div>
    </div>
  </app-block>

</template>

<script>
import AppBlock from "@/ui/components/common/blocks/AppBlock.vue";

export default {
  components: {
    AppBlock,
  },
  props: {
    header: {
      type: String,
      default: 'Wählen Sie Ihre Optionen',
    }
  }
}
</script>

<style scoped>
.price-content__margin {
  padding-top: 22px;
  padding-bottom: 60px;
}

.price-wrapper__margin {
  margin-top: 75px;
  padding-bottom: 150px;
}

.right-side-col {
  padding-right: 20px;
}

</style>