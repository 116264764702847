export const CLASSIC_RADIO_BUTTONS = [
    {'text': 'Ja', 'value': 'Ja'}, 
    {'text': 'Nein', 'value': 'Nein'},
]

export const POLICE_RADIO_BUTTONS = [
    {'text': 'E-Mail', 'value': 'E-Mail'},
    {'text': 'Post', 'value': 'Post'},
]

export const INVOICE_RADIO_BUTTONS = [
    {'text': 'E-Mail', 'value': 'E-Mail'},
    {'text': 'Post', 'value': 'Post'},
]

export const INSURANCE_CONFIRMATION_BUTTONS = [
    {'text': 'E-Mail', 'value': 'E-Mail'},
    {'text': 'Post', 'value': 'Post'},
    {'text': 'Berufskammer', 'value': 'Berufskammer'},
]

export const CONFIRMATION_CLICK = 'Bestätigungsklick durch Kunden (Click-2-Contract)'
export const ELECTRONIC_SIGNATURE = 'Unterschrift auf Papier'
export const TELEPHONE_SALE = 'Telefonverkauf'
export const ESTATE_AGENT = 'Abschluss über Makler'

export const CONTRACT_FORMATION = [
    {'text': CONFIRMATION_CLICK, 'value': CONFIRMATION_CLICK},
    {'text': ELECTRONIC_SIGNATURE, 'value': ELECTRONIC_SIGNATURE},
]

export const STEP3_OPTIONS_BUTTON_NAMES = {
    deliveredToCustomers: 'An Kunden übergeben',
    lock: 'Antrag abschicken',
    next: 'Weiter',
}

