<template>
  <div :style="{width}" class="date-picker-wrapper">
    <v-text-field
        class="date-text-editor"
        v-model="visibleValue"
        :placeholder="placeholder"
        :rules="validationRules"
        @change="changed"
        @input="onInput"
        outlined
        dense
        :class="{rounded: rounded}"
        :disabled="isDisabled"
    />
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        :min-width="minWidth"
        offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-if="!isDisabled" class="calendar-picker-btn" v-bind="attrs" v-on="on" >
          <img class="calendar-picker-btn-img" src="/img/calendar.svg" alt="Choose the date">
        </div>
      </template>
      <v-date-picker
          v-model="date"
          :first-day-of-week="1"
          locale="de-de"
          no-title
          scrollable
          :allowed-dates="allowedDates"
          @dblclick:date="dblclick"
      >
      </v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import {dateService} from "@/utils/services/date-service.js";
import {
  checkDateFieldRangeValidation,
  checkDateFieldValidation,
  equalToElementValue
} from "@/utils/validation-rules.js";
import {DateFieldValidation} from "@/utils/types/date-field-validation.js";
import {required} from "@/utils/validation-rules.js";

export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '175px',
    },
    validation: {
      type: Object,
      default: () => ({})
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    allowedDates: {
      type: Function,
      default: undefined,
    },
    name: {
      type: String,
      required: false,
    },
    minWidth: {
      type: String,
      default: 'auto',
    },
  },
  computed: {
    allBuilderElements() {
      return this.$store.getters["insuranceModule/builderElementsModule/getAllBuilderElements"];
    },
  },
  watch: {
    date(value) {
      this.setVisibleValue(value, 'yyyy-LL-dd');

      this.saveField(this.visibleValue);
    },

    value(newVal) {
      this.setVisibleValue(newVal)

      this.changed(newVal)
    },

    validation(newVal) {
      this.setValidationRules(newVal);
    },
  },
  data: () => ({
    date: null,
    visibleValue: '',
    menu: false,

    validationRules: [],
  }),
  created() {
    this.setVisibleValue(this.value, dateService.getDateFormat());

    this.setValidationRules(this.validation)
  },
  methods: {
    setValidationRules(validation) {
      this.validationRules = [];

      if (validation.range && validation.range.isRangeValidation) {

        this.validationRules.push((val) => checkDateFieldRangeValidation(val, validation.range, this.allBuilderElements))
      } else {
        if (validation.maxDate) {
          const maxValidation = new DateFieldValidation(validation.maxDate);

          if (maxValidation.useCondition) {
            this.validationRules.push((val) => checkDateFieldValidation(val, maxValidation, this.allBuilderElements))
          }
        }

        if (validation.minDate) {
          const minValidation = new DateFieldValidation(validation.minDate);

          if (minValidation.useCondition) {
            this.validationRules.push((val) => checkDateFieldValidation(val, minValidation, this.allBuilderElements))
          }
        }
      }

      if (validation.required) {
        this.validationRules.push((val, message = this.validation.message || 'Pflichtfeld') => required(val, message))
      }

      if (validation.equalTo && validation.equalTo.elementId) {
        this.validationRules.push(
            (val, message = validation.equalTo.message || 'Error') => {
              return equalToElementValue(val, validation.equalTo.elementId, this.allBuilderElements, message)
            }
        );
      }
    },

    setVisibleValue(value, format=null) {
      if (value && dateService.checkDate(value, format)) {
        this.visibleValue = dateService.toFormat(value, format, dateService.getDateFormat());
      } else {
        this.visibleValue = '';
      }
    },

    changed(value) {
      if (value && dateService.checkDate(value, dateService.getDateFormat())) {
        this.date = dateService.toFormat(value, dateService.getDateFormat(), 'yyyy-LL-dd');
      }
    },

    saveField(value) {
      this.$emit('saveField', value, this.name)
      this.$emit('input', value);
    },

    dblclick() {
      this.menu = false
    },
    onInput(value) {
      const patternWrongDate1 = /^[0-9]{2}\.[0-9]{6}$/;
      const patternWrongDate2 = /^[0-9]{6}\.[0-9]{2}$/;

      if (patternWrongDate1.test(value) || patternWrongDate2.test(value)) {
        value = value.replace('.', '');
      }

      const patternWithoutDots = /^[0-9]{8}$/;

      if (patternWithoutDots.test(value)) {
        this.visibleValue = value.slice(0, 2) + '.' + value.slice(2, 4) + '.' + value.slice(4);
      }
    },
  }
}
</script>
<style scoped>
.date-text-editor {
  max-width: 78% !important;
}
.date-picker-wrapper {
  display: flex;
}
.calendar-picker-btn {
  display: block;
  height: 42px;
  min-width: 42px;
  margin-left: 10px;
  padding-bottom: 4px;
}
.calendar-picker-btn-img {
  min-height: 100%;
}
</style>