<template>
  <div class="app-bg-white">
    <div class="mr-5 pb-5 px-5">
      <h5 class="finish-step-heading mt-10 mb-5 pl-2 pt-5">Status:</h5>
      <v-row>
        <v-col class="mt-2">
          <v-row>
            <v-col class="icon-block">
              <square-check-icon color="var(--light-blue)"/>
            </v-col>
            <v-col cols="7" class="mt-2">
              Antrag wurde zur Policierung eingereicht
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="dateOfSubmit" class="mt-2 text-right" cols="1">
          {{ dateOfSubmit | toDateTimeFormatFromSQL}}  Uhr
        </v-col>
      </v-row>
      <hr class="finish-step-hr my-5 ml-2">
    </div>
  </div>
</template>

<script>
import SquareCheckIcon from "@/ui/components/common/icons/SquareCheckIcon.vue"
import {DATA_FIELD_KEYS} from "@/utils/fields/insurance-police-field-keys.js"
export default {
  components: {SquareCheckIcon},
  computed: {
    insurancePolice() {
      return this.$store.getters["insuranceModule/insurancePoliceModule/getInsurancePolice"]
    },
    dateOfSubmit() {
      return this.insurancePolice.data[DATA_FIELD_KEYS.electronicSignatureSubmittedAt]
    },
  },
  async created() {
    await this.sendXml();
  },
  methods: {
    sendXml() {
      this.$store.dispatch('insuranceModule/sendXml')
    },
  },
}
</script>

<style scoped>
.icon-block {
  max-width: 4%;
}
</style>