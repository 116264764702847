<template>
  <v-app>
    <v-main class="app-bg-light-smoke">
      <app-header :show-navigation="false" :show-user-info="false" />
      <div class="py-8 app-container">
          <router-view/>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import AppHeader from "../app/AppHeader.vue";
export default {
  components: {AppHeader}
}
</script>