<template>
  <div>
    <div class="text-center">
      <h3>Block</h3>
    </div>

    <v-row>

      <v-col cols="4">
        <name-editor
            v-model="builderElement.name"
        />
      </v-col>

      <v-col class="pl-4" cols="4">
        <priority-editor
            v-model.number="builderElement.priority"
        />
      </v-col>

      <v-col class="pl-4" cols="4">
        <background-editor
            v-model="builderElement.data.background"
        />
      </v-col>

      <v-col cols="6">
        <height-editor
            v-model="builderElement.data.height"
        />
      </v-col>

      <v-col cols="6" class="pl-4">
        <width-editor
            v-model="builderElement.data.width"
        />
      </v-col>

      <v-col cols="6">
        <height-editor
            label="Item Height"
            v-model="builderElement.data.element_height"
        />
      </v-col>

      <v-col cols="6" class="pl-4">
        <width-editor
            label="Item Width"
            v-model="builderElement.data.element_width"
        />
      </v-col>

      <v-col cols="9">
        <p class="app-text__bold">Add Value
          <question-tooltip>To set different key and value use pattern "text:value"</question-tooltip>
        </p>
        <text-field
            name="value"
            v-model="addValue"
        />
      </v-col>

      <v-col cols="3" class="pt-10 pl-4">
        <blue-btn height="30px" @click="addToOptions">Add</blue-btn>
      </v-col>

      <v-col cols="12">
        <p class="app-text__bold">Options</p>

        <radio-buttons-vertical
            name="options"
            :buttons="options"
            v-model="builderElement.value"
            @deleteOption="onDeleteOption"
            clearable
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p class="app-text__bold">Add tooltip</p>
        <select-field
            name="option-item"
            :value="optionTextForTooltip"
            :items="selectOptions"
            @input="onSelectOption"
        />
        <text-area-field
            name="label"
            v-model="tooltipToAdd.text"
        />
      </v-col>
      <v-col cols="12">
        <blue-btn :disabled="tooltipToAdd.text === ''" height="30px" @click="addTooltip">Add</blue-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p class="app-text__bold">Tooltips</p>
        <v-col cols="12" class="pl-2" v-for="(tooltip, index) in tooltips" :key="`tooltip_${index}`">
          <div>
            <div>
              <p><b>Option: </b>{{ options[tooltip.optionIndex].text }}</p>
            </div>
            <div class="d-flex">
              <div class="app-text">
                {{ tooltip.text }}
              </div>
              <div class="mr-4">
                <close-icon @click="onDeleteTooltipClick(index)"/>
              </div>
            </div>
          </div>
        </v-col>
      </v-col>
    </v-row>

    <margin-editor
        class="mt-2"
        v-model="builderElement.data.margin"
    />

    <padding-editor
        class="mt-6"
        v-model="builderElement.data.padding"
    />
  </div>
</template>
<script>
import {BuilderElement} from "@/models/builder-element.js";
import PaddingEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PaddingEditor.vue";
import MarginEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/MarginEditor.vue";
import BackgroundEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/BackgroundEditor.vue";
import PriorityEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/PriorityEditor.vue";
import NameEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/NameEditor.vue";
import HeightEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/HeightEditor.vue";
import WidthEditor from "@/ui/components/builder/use-cases/builder-editor/attribute-editor/WidthEditor.vue";
import TextField from "@/ui/components/common/fields/TextField.vue";
import BlueBtn from "@/ui/components/common/buttons/BlueBtn.vue";
import QuestionTooltip from "@/ui/components/common/tooltips/QuestionTooltip.vue";
import RadioButtonsVertical from "@/ui/components/common/fields/radio/RadioButtonsVertical.vue";
import CloseIcon from "@/ui/components/common/icons/CloseIcon.vue";
import TextAreaField from "@/ui/components/common/fields/TextAreaField.vue";
import SelectField from "@/ui/components/common/fields/SelectField.vue";
import AdditionalTooltipsEditor from "@/ui/components/builder/elements/mixins/AdditionalTooltipsEditor";

export default {
  components: {
    SelectField,
    TextAreaField,
    CloseIcon,
    RadioButtonsVertical,
    QuestionTooltip,
    BlueBtn,
    TextField,
    WidthEditor, HeightEditor, NameEditor, PriorityEditor, BackgroundEditor, MarginEditor, PaddingEditor},
  mixins: [AdditionalTooltipsEditor],
  props: {
    builderElement: {
      type: BuilderElement,
      required: true,
    }
  },
  data:() => ({
      addValue: '',
  }),
  created() {
    this.initOptions();
    this.initTooltips();
  },
  methods: {
    addToOptions() {
      this.builderElement.data.options = this.builderElement.data.options || [];

      if (this.addValue) {
        const arr = this.addValue.split(':');

        const text = arr[0];
        const value = arr[1] || arr[0];

        const newOptions = this.builderElement.data.options.filter((option) => option.value !== value)
        //TODO: use itemKeyValue, itemKeyText
        newOptions.push({text, value})

        this.builderElement.setDataItem('options', newOptions);
      }

      this.addValue = '';
    }
  }
}
</script>