<template>
  <div>
    <v-row>
      <v-col cols="12"><h1>Admin Dashboard</h1></v-col>
      <v-col v-if="isShowStagingUpdatedAt" class="mt-2" cols="4"><staging-updated-at-editor/></v-col>
      <v-col v-if="isShowProdUpdatedAt" class="mt-2" cols="4"><prod-updated-at-editor/></v-col>
    </v-row>

    <toggle-block
      class="my-2 pb-2"
      header="Grund Aussteuerung"
    >
      <insurance-npopt-reasons-chart
        :insurance-type-options="insuranceTypeOptions"
      />
    </toggle-block>

    <toggle-block
      class="my-2 pb-2"
      header="Grund Sofortaussteuerung"
    >
      <insurance-empty-price-reasons-chart
        :insurance-type-options="insuranceTypeOptions"
      />
    </toggle-block>

    <toggle-block
      class="my-2 pb-2"
      header="Status"
    >
      <insurance-status-chart
        class="mt-2"
        :insurance-type-options="insuranceTypeOptions"
      />
    </toggle-block>

    <toggle-block
      class="my-2 pb-2"
      header="Wert nach Status"
    >
      <insurance-status-sum-price-chart
        :insurance-type-options="insuranceTypeOptions"
        class="mt-2"
      />
    </toggle-block>
  </div>
</template>
<script>
import InsuranceStatusChart from "@/ui/components/admin/charts/InsuranceStatusChart";
import InsuranceEmptyPriceReasonsChart from "@/ui/components/admin/charts/InsuranceEmptyPriceReasonsChart.vue";
import InsuranceStatusSumPriceChart from "@/ui/components/admin/charts/InsuranceStatusSumPriceChart.vue";
import InsuranceNpoptReasonsChart from "@/ui/components/admin/charts/InsuranceNpoptReasonsChart.vue";
import StagingUpdatedAtEditor from "@/ui/components/admin/dashboard-info/StagingUpdatedAtEditor.vue";
import ProdUpdatedAtEditor from "@/ui/components/admin/dashboard-info/ProdUpdatedAtEditor.vue";
import ToggleBlock from "@/ui/components/common/blocks/ToggleBlock.vue";
import {isEnvStaging, isEnvProduction} from "@/plugins/env";
export default {
  components: {InsuranceStatusChart, InsuranceEmptyPriceReasonsChart, InsuranceStatusSumPriceChart, InsuranceNpoptReasonsChart, StagingUpdatedAtEditor, ToggleBlock, ProdUpdatedAtEditor},
  computed: {
    isShowStagingUpdatedAt() {
      return isEnvStaging()
    },
    isShowProdUpdatedAt() {
      return isEnvProduction()
    },
  },
  data: () => ({
    insuranceTypeOptions: [],
  }),
  async created() {
    await this.getInsuranceTypes()
  },
  methods: {
    getInsuranceOriginalTypes() {
      return this.$store.dispatch('admin/insuranceTypeModule/getOriginalTypes')
    },
    async getInsuranceTypes() {
      this.insuranceTypeOptions = await this.getInsuranceOriginalTypes()
      this.insuranceTypeOptions.unshift({name: 'All', key: ''})
    },
  },
}
</script>