export default {
    computed: {
        padding() {
            return this.builderElement.data?.padding || 'initial';
        },

        margin() {
            return this.builderElement.data?.margin || 'initial';
        }
    }
}